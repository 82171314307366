import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import trackerLogo from '../../assets/img/tracker_logo.png';
import Footer from '../Footer/Footer.jsx'

class SmsTermsAndConditions extends Component {
  render() {
    return <Container style={{ textAlign: "justify", padding: "20px" }}>
      <Grid style={{ paddingTop: '20px', textAlign: 'center' }}>
        <h1 style={{ fontSize: '24px' }}>TERMS & CONDITIONS</h1></Grid>
      <Grid style={{ padding: '20px', paddingTop: '0px' }}>
        <p><span>PLEASE REVIEW THESE TERMS
          CAREFULLY. ONCE ACCEPTED, THESE TERMS BECOME A BINDING LEGAL COMMITMENT BETWEEN
          YOU AND EVFY. ONLY THE TERMS IN THIS RIGHT COLUMN ARE LEGALLY BINDING. THE
          EXPLANATIONS IN THE LEFT COLUMN ARE FOR INFORMATIONAL PURPOSES ONLY. IF YOU DO
          NOT AGREE TO BE BOUND BY THESE TERMS, YOU SHOULD NOT ACCEPT THESE TERMS, CREATE
          AN ACCOUNT, OR USE THE SERVICES (AS DEFINED BELOW).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>THE SERVICES ARE INTENDED FOR
          BUSINESS USE OR USE IN CONNECTION WITH AN INDIVIDUAL&apos;S TRADE, CRAFT, OR
          PROFESSION ONLY.</span></p>
        <p>IMPORTANT NOTES:</p>

        <ul type="disc">
          <li><span
          >&nbsp;THESE
            TERMS LIMIT OUR LIABILITY TO YOU. For more details, go to Section 10.</span></li>
        </ul>

        <ul type="disc">
          <li><span
          >IN
            ADDITION, DISPUTES RELATED TO THESE TERMS OR THE SERVICES GENERALLY MUST
            BE RESOLVED BY A DISPUTE RESOLUTION PROCESS WHICH MAY LEAD TO BINDING
            ARBITRATION. For more details, go to Section 13.8.</span></li>
        </ul>

        <p><span>&nbsp;</span></p>

        <p><span>In these Terms of Service
          (referred to as these “Terms” or this “Agreement”), the terms “you”, “your”, or
          “Customer” refer to you. If you are creating an account in order to use the
          Services on behalf of an organization, then you are agreeing to these Terms for
          that organization and promising to us that you have the authority to bind that
          organization to these Terms (and, in which case, the terms “you”, “your”, or
          “Customer” refer to that organization). The exception to this is if that
          organization has a separate written agreement with us covering the use of the
          Services, in which case that agreement will govern such use. The terms we”,
          “us,” “our” or “Evfy” refer to Evfy Pte Ltd, a company registered in Singapore,
          with a place of business at 84 Genting Lane, #02-02, Singapore 349584. Evfy or
          Customer may also be referred to individually as “party” and together as
          “parties” in these Terms. To be eligible to create an account in order to use
          the Services, you must review and accept these Terms.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>When we refer to the “Services”
          in these Terms, we mean all products and services provided by us or our
          Affiliates, as applicable, that are (a) used by you, including, without
          limitation, products and services that are on a trial basis or otherwise free
          of charge or (b) ordered by you under an Order Form (as defined below). The
          Services may include products and services that provide both (x) platform
          services, including access to any application programming interface (“Evfy
          API”) and (y) where applicable, communications services used in connection with
          the Evfy APIs.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>When we refer to an “Affiliate”
          in these Terms, we mean any entity that directly or indirectly controls or is
          controlled by, or is under common control with, the party specified. For
          purposes of this definition, “control” means direct or indirect ownership of
          more than fifty percent (50%) of the voting interests of the subject entity.</span></p>

        <p><span
        >1. Changes to These Terms</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>We may update these Terms from
          time to time. We will provide you with prior written notice of any material
          updates at least thirty (30) days in advance of the effective date; provided,
          however, we may not be able to provide at least thirty (30) days prior written
          notice of material updates to these Terms that result from changes in laws,
          regulations, or requirements from telecommunications providers. The updated
          version of these Terms will be available at </span><span><a
            href="https://www.twilio.com/legal/tos"><span >https://www.twilio.com/legal/tos</span></a></span>.</p>

        <p><span>&nbsp;</span></p>

        <p><span>Notices for material updates to
          these Terms will be given in accordance with Section 13.5 (Notices). Except as
          otherwise specified by us, updates will be effective and binding upon the date
          indicated at the top of these Terms. The updated version of these Terms will
          supersede all prior versions.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>Following such notice, your
          continued use of the Services on or after the date the updated version of these
          Terms is effective and binding constitutes your acceptance of such updated
          Terms. If you do not agree to the updated version of these Terms, you must stop
          using the Services immediately.</span></p>

        <p><span
        >2. Account Creation and Information</span></p>

        <p><span>To use the Services, you will be
          asked to create an account. As part of the account creation process, you&apos;ll be
          asked to provide your email address, create a password, and verify that you are
          a human being by providing a telephone number to which we will send you a
          verification code to enter into an online form. When creating an account, you
          must provide true, accurate, current, and complete information about yourself
          as requested during the account creation process. You must keep that
          information true, accurate, current, and complete after you create each
          account. If you breach these Terms, including, without limitation, your payment
          obligations in Section 6 (Fees and Payment Terms), you are strictly prohibited
          from creating new accounts until you remedy such breach in full.</span></p>

        <p><span
        >3. Provision of the Services</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>3.1 Our Responsibilities.&nbsp; We
          will (a) make the Services available to you in accordance with these Terms, our
          documentation, including any usage guides and policies for the Services
          contained in such documentation (“Documentation”), and any applicable ordering
          document between the parties that specifies mutually agreed upon rates for
          certain Services and other commercial terms, including any applicable minimum
          spend commitments (“Order Form”); (b) comply with our Service Level Agreement
          for the Services (“SLA”) and our Security Overview for the Services, each of
          which may be updated from time to time; (c) provide the Services in accordance
          with laws applicable to our provision of the Services to our customers
          generally (i.e. without regard for your particular use of the Services), subject
          to your&nbsp; use of the Services in accordance with these Terms, the
          applicable Documentation, and any applicable Order Form(s); (d) make
          commercially reasonable efforts to use industry standard measures designed to
          scan, detect, and delete code, files, scripts, agents, or programs intended to
          do harm, including, for example, viruses, worms, time bombs and Trojan horses;
          (e) if applicable, use trained, qualified personnel to provide the Services;
          and (f) use commercially reasonable efforts to provide you with applicable
          support for the Services as described in our Support Terms.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>3.2 Beta Offerings.&nbsp; From
          time to time, we may make Services that are identified as alpha, beta, not
          generally available, limited release, developer preview, or any similar Services
          offered by us (collectively, “Beta Offerings”) available to you. You may choose
          to use a Beta Offering at your sole discretion. We may discontinue a Beta
          Offering at any time, in our sole discretion, or decide not to make a Beta
          Offering generally available.</span></p>

        <p><span
        >&nbsp;</span></p>

        <p><span>3.3 Suspension of Services.&nbsp;
          We may suspend the Services immediately upon notice to you for cause if, we in
          good faith, determine: (a) that you or an End User (as defined below)
          materially breaches (or we, in good faith, believe that you or an End User has
          materially breached) any provision of these Terms or our Acceptable Use Policy,
          including our Service and Country Specific Requirements therein; (b) there is
          an unusual and material spike or increase in your use of the Services and that
          such traffic or use is fraudulent or materially and negatively impacting the
          operating capability of the Services; (c) that our provision of the Services is
          prohibited by applicable law or regulation; (d) there is any use of the
          Services by you or an End User that in our judgment threatens the security,
          integrity, or availability of the Services; or (e) that information in your
          account is untrue, inaccurate, or incomplete. You remain responsible for the
          Fees (as defined below).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>If we suspend the Services
          pursuant to this Section 3.3 or Section 6.3 (Fees and Payment Terms), we will
          have no liability for any damage, liabilities, losses (including any loss of
          data or profits), or any other consequences that you may incur in connection
          with any such suspension.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>3.4 Changes to the
          Services.&nbsp; The features and functions of the Services, including the Evfy
          APIs and our SLA, may change over time; provided, however, we will not
          materially decrease the overall functionality of the Services. It is your
          responsibility to ensure each Customer Application (as defined below) is
          compatible with the then-current Services. Although we try to avoid making
          changes to the Services that are not backwards-compatible, if any such changes
          become necessary, we will use reasonable efforts to let you know at least
          thirty (30) days prior to implementation. In the event we make a non-backwards
          compatible change to an Evfy API and such change materially and negatively
          impacts your use of the Services (“Adverse API Change”), (a) you will notify us
          of the Adverse API Change and (b) we may agree to work with you, in our sole
          discretion, to resolve or otherwise address the Adverse API Change, except
          where we, in our sole discretion, have determined that an Adverse API Change is
          required for security reasons, by telecommunications providers, or to comply
          with applicable law or regulation.</span></p>

        <p><span
        >4. Your Responsibilities</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>You will: (a) be solely
          responsible for all use of the Services and Documentation under your account,
          including the quality and integrity of any data and other information made
          available to us by or for you through the use of the Services under these Terms
          (“Customer Data”) and each software application or service that you make
          available to End Users that interfaces with the Services (each, a “Customer
          Application”); (b) not transfer, resell, lease, license, or otherwise make
          available the Services to third parties (except to make the Services available
          to End Users in connection with the use of each Customer Application as
          permitted under these Terms) or offer them on a standalone basis; (c) use the
          Services only in accordance with these Terms, our Acceptable Use Policy,
          including our Service and Country Specific Requirements therein, the applicable
          Documentation, any applicable Order Form(s), and applicable law or regulation;
          (d) be solely responsible for all acts, omissions, and activities of anyone who
          accesses or otherwise uses any Customer Application (“End User”), including End
          Users&apos; compliance with these Terms, our Acceptable Use Policy, including our
          Service and Country Specific Requirements therein, the applicable
          Documentation, any applicable Order Form(s), and applicable law or regulation;
          (e) do your best to prevent unauthorized access to or use of the Services and
          notify us promptly of any such unauthorized access or use; (f) provide
          reasonable cooperation regarding information requests from law enforcement,
          regulators, or telecommunications providers; and (g) comply with the
          representations and warranties you make in Section 8 (Representations,
          Warranties, and Disclaimer).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>We will not be liable for any
          loss or damage arising from unauthorized use of your account.</span></p>

        <p><span>If you are the party that
          accepted these Terms and you re-assign your account to a third-party reseller
          for administration purposes, such account re-assignment will not excuse your
          obligations under these Terms. Your use of the Services will continue to be
          subject to these Terms.</span></p>

        <p><span
        >5. Affiliates</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>5.1 Your Affiliates.&nbsp; Your
          Affiliates are not permitted to use the Services under these Terms that you
          accepted. Each of your Affiliates that wants to use the Services must accept
          these Terms individually and create its own account.</span></p>

        <p><span>Our affiliates may provide you
          with our services or bill you on behalf of us or another Evfy entity providing
          our services.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>5.2 Our Affiliates.&nbsp; Our
          Affiliates may provide the Services, or a portion thereof, to you in accordance
          with these Terms and any applicable Order Form(s) with such Affiliates. We will
          (a) be responsible for the Services our Affiliates provide and (b) not be
          relieved of our obligations under these Terms if our Affiliates provide the
          Services or a portion thereof. We will enforce these Terms relating to the
          Services our Affiliates provide. Notwithstanding anything to the contrary in these
          Terms, our Affiliates may directly bill you (x) for the Services they provide
          or (y) solely as a billing agent for us or another Affiliate of ours providing
          the Services, as applicable.</span></p>

        <p><span
        >6. Fees and Payment Terms</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.1 Fees.&nbsp; You agree to pay
          fees in accordance with the then-current applicable rates agreed upon in the
          applicable Order Form(s). These fees are typically, but not always, due at the
          end of the calendar month.&nbsp;</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>Additionally, we will charge you,
          and you will pay, in accordance with Section 6.3 (Fees and Payment Terms), any
          and all additional costs, fines, or penalties we incur from a governmental or
          regulatory body or telecommunications provider as a result of your use of the
          Services.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.2 Taxes and Communications
          Surcharges</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.2.1 Taxes.&nbsp; All fees are
          exclusive of any applicable taxes, levies, duties, or other similar exactions
          imposed by a legal, governmental, or regulatory authority in any applicable
          jurisdiction, including, without limitation, sales, use, value-added,
          consumption, communications, or withholding taxes (collectively, “Taxes”). You
          will pay all Taxes associated with these Terms, excluding any taxes based on
          our net income, property, or employees. If you are required by applicable law
          to withhold any Taxes from payments owed to us, you will reduce or eliminate
          such withheld Taxes upon receipt of the appropriate tax certificate or document
          provided by us. You will provide us with proof of payment of any withheld Taxes
          to the appropriate authority. Taxes will be shown as a separate line item on an
          invoice.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.2.2 Communications
          Surcharges.&nbsp; All fees are exclusive of any applicable communications
          service or telecommunication provider (e.g., carrier) fees or surcharges
          (collectively, “Communications Surcharges”). You will pay all Communications
          Surcharges associated with your use of the Services. Communications Surcharges
          will be shown as a separate line item on an invoice.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.2.3 Exemption.&nbsp; If you are
          exempt from paying certain Taxes or Communications Surcharges, you will provide
          the necessary exemption information as requested by us or a valid exemption
          certificate issued by the appropriate authority via e-mail to enquiry@evfy.sg.
          You will be exempt on a going-forward basis once we approve your exemption
          request. If the appropriate authority determines, at any time, that you are not
          exempt from paying any Taxes or Communications Surcharges, you will promptly
          pay such Taxes or Communications Surcharges to us, plus any applicable interest
          or penalties.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.3 Payment Terms.&nbsp; Payment
          obligations are non-cancelable and fees, Taxes, and Communications Surcharges
          (collectively, &quot;Fees&quot;) once paid, are non-refundable. Except as
          otherwise set forth in the applicable Order Form(s) and subject to Section 6.4
          (Payment Disputes), you will pay the Fees due under these Terms in accordance
          with the following applicable payment method:</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.3.1 Credit Card Payment
          Terms.&nbsp; If you elect to add funds to your account by credit card and use
          such funds to pay the Fees due, you are responsible for ensuring that such
          funds cover the Fees due. If your account does not have sufficient funds or
          your credit card declines a charge for the Fees due, we reserve the right to suspend
          the Services to all of your accounts until the Fees due are paid in full.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.3.2 Invoicing Payment
          Terms.&nbsp; If you elect to receive invoices and we approve you for the same,
          invoices will be sent to you each month via email to the email address(es) you
          designate in your account. You will pay the Fees due within thirty (30) days of
          the date of the invoice. Except as otherwise set forth in the applicable Order
          Form(s) or an invoice to the extent you procure the Services without an Order
          Form, the Fees are payable in United States dollars. If you fail to pay the
          Fees and remedy such failure within fifteen (15) days of the date we provide
          you with written notice of the same, we may (a) assess, and you will pay, a
          late fee of the lesser of 1.5% per month or the maximum amount allowable by
          applicable law and (b) suspend the Services to all of your accounts until the
          Fees are paid in full.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>6.4 Payment Disputes.&nbsp; You
          will notify us in writing within sixty (60) days of the date we bill you for
          any Fees that you wish to dispute. You may withhold the disputed Fees until the
          dispute is resolved. Where you are disputing any Fees, you must act reasonably
          and in good faith and will cooperate diligently with us to resolve the dispute.
          We will not charge you a late fee or suspend the provision of the Services for
          unpaid Fees that are in dispute, unless you fail to cooperate diligently with
          us or we determine your dispute is not reasonable or brought in good
          faith.&nbsp;&nbsp;</span></p>

        <p><span
        >7. Ownership, Customer Data, and Confidentiality</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.1 Ownership.&nbsp; As between
          the parties, we exclusively own and reserve all right, title, and interest in
          and to the Services, the Documentation, our Confidential Information (as
          defined below), and any data that is derived from the use of the Services that
          does not directly or indirectly identify you, End Users, or any natural person
          and includes (a) data such as volumes, frequencies, bounce rates and Service
          performance data and (b) subject to any restrictions under applicable laws,
          data that is anonymized, de-identified, and/or aggregated such that it could no
          longer directly or indirectly identify you, End Users, or any natural person,
          and any feedback or suggestions provided by you or an End User regarding the
          Services. As between the parties, you exclusively own and reserve all right,
          title, and interest in and to each Customer Application, your Confidential
          Information, and Customer Data, subject to our rights to process Customer Data
          in accordance with these Terms.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.2 Customer Data.&nbsp; You
          grant us and our Affiliates the right to process Customer Data as necessary to
          provide the Services in a manner consistent with these Terms, our Data
          Protection Addendum, and our Privacy Notice. If you do not agree with the terms
          of our Data Protection Addendum or our Privacy Notice, you must stop using the
          Services immediately.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.3 Confidentiality</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.3.1 Definition.&nbsp;
          “Confidential Information” means any information or data, regardless of whether
          it is in tangible form, disclosed by either party (“Disclosing Party”) to the
          other party (“Receiving Party”) that is marked or otherwise designated as
          confidential or proprietary or that should otherwise be reasonably understood
          to be confidential given the nature of the information and the circumstances
          surrounding disclosure, including, without limitation, Order Form(s), Customer
          Data, security reports and attestations, audit reports, customer lists,
          pricing, concepts, processes, plans, designs and other strategies, “know how”,
          financial, and other business and/or technical information and materials of
          Disclosing Party and its Affiliates. Confidential Information does not include
          any information which: (a) is publicly available through no breach of these
          Terms or fault of Receiving Party; (b) was properly known by Receiving Party,
          and to its knowledge, without any restriction, prior to disclosure by
          Disclosing Party; (c) was properly disclosed to Receiving Party, and to its
          knowledge, without any restriction, by another person without breach of
          Disclosing Party&apos;s rights; or (d) is independently developed by Receiving Party
          without use of or reference to the Confidential Information of Disclosing
          Party.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.3.2 Use and Disclosure.&nbsp;
          Except as otherwise authorized by Disclosing Party in writing, Receiving Party
          will not (a) use any Confidential Information of Disclosing Party for any
          purpose outside of exercising Receiving Party&apos;s rights or fulfilling its
          obligations under these Terms and (b) disclose or make Confidential Information
          of Disclosing Party available to any party, except to its, its Affiliates&apos;, and
          their respective employees, legal counsel, accountants, contractors, and in our
          case, subcontractors (collectively, “Representatives”) who have a “need to
          know'' as necessary for Receiving Party to exercise its rights or fulfill its
          obligations under these Terms. Receiving Party is responsible for its
          Representatives&apos; compliance with this Section 7.3. Representatives will be
          legally bound to protect Confidential Information of Disclosing Party under
          terms of confidentiality that are at least as protective as the terms of this
          Section 7.3. Receiving Party will protect the confidentiality of Confidential
          Information of Disclosing Party using the same degree of care that it uses to
          protect the confidentiality of its own confidential information but in no event
          less than reasonable care. Notwithstanding the foregoing, you may disclose our
          SOC2 or similar report, which will constitute our Confidential Information,
          only to an End User or an End User&apos;s employee or contract worker who has a
          “need to know'' for such SOC2 or similar report and is legally bound to terms
          of confidentiality that are at least as protective as the terms of this Section
          7.3.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.3.3 Compelled Disclosure.
          Receiving Party may disclose Confidential Information of Disclosing Party if so
          required pursuant to a regulation, law, subpoena, or court order (collectively,
          “Compelled Disclosures”), provided Receiving Party gives Disclosing Party
          notice of a Compelled Disclosure (to the extent legally permitted). Receiving
          Party will provide reasonable cooperation to Disclosing Party in connection
          with a Compelled Disclosure at Disclosing Party&apos;s sole expense.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>7.3.4 Injunctive Relief.&nbsp;
          The parties expressly acknowledge and agree that no adequate remedy may exist
          at law for an actual or threatened breach of this Section 7.3 and that, in the
          event of an actual or threatened breach of the provisions of this Section 7.3,
          the non-breaching party will be entitled to seek immediate injunctive and other
          equitable relief, without waiving any other rights or remedies available to it.</span></p>

        <p><span
        >8. Representations, Warranties, and Disclaimer</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>8.1 Customer Data.&nbsp; You
          represent and warrant that you have provided, and will continue to provide,
          adequate notices and have obtained, and will continue to obtain, the necessary
          permissions and consents to provide Customer Data to us for use and disclosure
          pursuant to Section 7.2 (Customer Data).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>8.2 Services.&nbsp; We represent
          and warrant that the Services perform materially in accordance with the
          applicable Documentation. Our sole obligation, and your sole and exclusive
          remedy, in the event of any failure by us to comply with this Section 8.2 will
          be for us to, at our option, (a) remediate any material non-conformity or (b)
          refund to you the Fees you actually paid for the time period during which the
          affected Services do not comply with this Section 8.2.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>Each party swears to follow
          anti-corruption, anti-money laundering, economic and trade sanctions, export
          controls, and other international trade laws, regulations, and government
          orders. Each party also swears that it, or its organization, is not on any
          government sanctions or restricted party lists of people and organizations that
          companies like Evfy are not allowed to do business with.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>8.3 Anti-Corruption and
          International Trade Laws.&nbsp; Each party (a) warrants that it will conduct
          business in accordance with all applicable anti-corruption, anti-money
          laundering, economic and trade sanctions, export controls, and other
          international trade laws, regulations, and governmental orders (collectively,
          “Anti-Corruption and Trade Laws”) in the jurisdictions that apply directly or
          indirectly to the Services, including, without limitation, the United States
          and (b) represents that it has not made, offered, promised to make, or
          authorized any payment or anything of value in violation of Anti-Corruption and
          Trade Laws. You will promptly notify us in writing of any actual or potential
          violation of Anti-Corruption and Trade Laws in connection with the use of the
          Services and take all appropriate steps to remedy or resolve such violations,
          including any steps requested by us. You represent that you have obtained and
          warrant that you will continue to obtain all licenses or other authorizations
          required to export, re-export, or transfer the Services. Each party represents
          that it (and, in your case, also End Users) is not on any government
          prohibited, denied, unverified-party, sanctions, debarment, or exclusion list
          or export-controlled related restricted party list (collectively, “Sanctions
          Lists”). You will (a) immediately discontinue your use of the Services if you
          are placed on any Sanctions List and (b) remove an End User&apos;s access to the
          Services if such End User becomes placed on any Sanctions List. You represent
          that you have not and warrant that you will not export, re-export, or transfer
          the Services to an entity on any Sanctions List without prior authorization
          from the applicable governmental authority. Notwithstanding anything to the
          contrary in this Agreement, either party may terminate this Agreement
          immediately upon written notice to the other party if the other party is in
          breach of its obligations in this Section 8.3. If your account is blocked
          because it is operating in a country or region prohibited under this Section
          8.3, you will receive notice of your account being inoperable when you attempt
          to log into your account in such prohibited country or region.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>8.4 DISCLAIMER.&nbsp; WITHOUT
          LIMITING A PARTY&apos;S&nbsp; EXPRESS WARRANTIES AND OBLIGATIONS UNDER THESE TERMS,
          AND EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION 8, THE SERVICES ARE PROVIDED
          “AS IS,” AND WE MAKE NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED,
          STATUTORY, OR OTHERWISE, AND WE SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES,
          INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR NON-INFRINGEMENT TO THE FULLEST EXTENT PERMITTED BY LAW. WE
          ADDITIONALLY DISCLAIM ALL WARRANTIES RELATED TO THIRD PARTY TELECOMMUNICATIONS
          PROVIDERS. YOU ACKNOWLEDGE THE INTERNET AND TELECOMMUNICATIONS PROVIDERS&apos;
          NETWORKS ARE INHERENTLY INSECURE. ACCORDINGLY, YOU AGREE WE ARE NOT LIABLE FOR
          ANY CHANGES TO, INTERCEPTION OF, OR LOSS OF CUSTOMER DATA WHILE IN TRANSIT VIA
          THE INTERNET OR A TELECOMMUNICATIONS PROVIDER&apos;S NETWORK. BETA OFFERINGS ARE
          PROVIDED “AS IS” AND “AS AVAILABLE” WITH NO WARRANTIES WHATSOEVER, AND WE WILL
          HAVE NO LIABILITY AND NO OBLIGATION TO INDEMNIFY FOR ANY BETA OFFERING
          WHATSOEVER.&nbsp;</span></p>

        <p><span
        >9. Mutual Indemnification</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.1 Indemnification by
          Us.&nbsp;&nbsp;</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.1.1 Scope of
          Indemnification.&nbsp; We will defend you from and against any claim, demand,
          suit, or proceeding made or brought against you by a third party alleging that
          our provision of the Services infringes or misappropriates such third party&apos;s
          intellectual property rights (“Infringement Claim”). We will indemnify you from
          any damages, fines or penalties imposed by a government or regulatory body,
          attorneys&apos; fees, and costs awarded against you or for settlement amounts
          approved by us for an Infringement Claim.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.1.2 Infringement Options.&nbsp;
          If our provision of the Services has become, or in our opinion is likely to
          become, the subject of any Infringement Claim, we may at our option and
          expense: (a) procure the right to continue providing the Services as set forth
          in these Terms; (b) modify the Services to make them non-infringing; or (c) if
          the foregoing options are not reasonably practicable, terminate these Terms,
          or, if applicable, terminate the Services that are the subject of any
          Infringement Claim, and refund you any unused pre-paid fees.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.2 Indemnification by You.&nbsp;
          You will defend us, our officers, directors, employees, and Affiliates
          (collectively, “Evfy Indemnified Parties”) from and against any claim, demand,
          suit, or proceeding made or brought against a Evfy Indemnified Party by a&nbsp;
          third party&nbsp; alleging or arising out of (a) your or any End Users&apos; breach
          of Section 4 (Your Responsibilities) or (b) a Customer Application, including,
          without limitation, any claims that a Customer Application, or your or any End
          Users' use of a Customer Application, infringes or misappropriates such third
          party&apos;s intellectual property rights (collectively, “Customer Indemnifiable
          Claims”). You will indemnify us from any damages, fines or penalties imposed by
          a government or regulatory body, attorneys&apos; fees, and costs awarded against a
          Evfy Indemnified Party or for settlement amounts approved by you for a Customer
          Indemnifiable Claim.&nbsp;</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.3 Conditions of
          Indemnification.&nbsp; As a condition of the foregoing indemnification
          obligations: (a) the indemnified party (“Indemnified Party”) will promptly
          notify the indemnifying party (“Indemnifying Party”) of any Infringement Claim
          or Customer Indemnifiable Claim (individually and collectively referred to as a
          “Claim”); provided, however, any failure to give such prompt notice will not
          relieve Indemnifying Party of its obligations under this Section 9 except to
          the extent that&nbsp; Indemnifying Party was actually and materially prejudiced
          by such failure; (b) Indemnifying Party will have the sole and exclusive
          authority to defend or settle any Claim; and (c) Indemnified Party will
          reasonably cooperate with Indemnifying Party in connection with Indemnifying
          Party&apos;s activities under this Section 9 at Indemnifying Party&apos;s expense.
          Indemnified Party reserves the right, at its own expense, to participate in the
          defense of any Claim. Notwithstanding anything to the contrary in this Section
          9, Indemnifying Party will not settle any Claims for which it has an obligation
          to indemnify pursuant to this Section 9 admitting liability or fault on behalf
          of Indemnified Party, nor create any obligation on behalf of Indemnified Party,
          without Indemnified Party&apos;s prior written consent, which will not be
          unreasonably withheld, conditioned, or delayed.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>9.4 Exclusive Remedy.&nbsp; This
          Section 9 states Indemnifying Party&apos;s sole liability to, and Indemnified
          Party&apos;s exclusive remedy against, the other party for any third-party claims.</span></p>

        <p><span
        >10. Limitation of Liability</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>10.1 LIMITATION ON INDIRECT,
          CONSEQUENTIAL, AND RELATED DAMAGES.&nbsp; IN NO EVENT WILL EITHER PARTY HAVE
          ANY LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS FOR ANY LOST PROFITS,
          REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER,
          LOST DATA, BUSINESS INTERRUPTION, OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN
          CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF A PARTY
          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY&apos;S REMEDY
          OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT
          APPLY TO THE EXTENT PROHIBITED BY LAW.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>10.2 LIMITATION OF
          LIABILITY.&nbsp; IN NO EVENT WILL THE AGGREGATE LIABILITY OF EITHER PARTY
          ARISING OUT OF OR RELATED TO THESE TERMS EXCEED THE AMOUNTS PAID OR PAYABLE BY
          YOU UNDER THESE TERMS FOR THE SERVICES GIVING RISE TO THE LIABILITY DURING THE
          TWELVE (12) MONTH PERIOD PRECEDING THE FIRST INCIDENT OUT OF WHICH THE
          LIABILITY AROSE. THE FOREGOING LIMITATION WILL APPLY WHETHER AN ACTION IS IN
          CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>10.3 EXCEPTIONS TO THE LIMITATION
          OF LIABILITY.&nbsp; NOTWITHSTANDING ANYTHING TO THE CONTRARY IN SECTION 10.1
          (LIMITATION ON INDIRECT, CONSEQUENTIAL, AND RELATED DAMAGES) AND SECTION 10.2
          (LIMITATION OF LIABILITY), THE LIMITATIONS IN SECTION 10.1 AND SECTION 10.2 DO
          NOT APPLY TO (a) YOUR BREACH OF SECTION 4 (YOUR RESPONSIBILITIES); (b) YOUR
          BREACH OF SECTION 6 (FEES AND PAYMENT TERMS); OR (c) AMOUNTS PAYABLE PURSUANT
          TO A PARTY&apos;S INDEMNIFICATION OBLIGATIONS UNDER SECTION 9 (MUTUAL
          INDEMNIFICATION).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>THE PROVISIONS OF THIS SECTION 10
          ALLOCATE THE RISKS PURSUANT TO THESE TERMS BETWEEN THE PARTIES, AND THE PARTIES
          HAVE RELIED ON THE LIMITATIONS SET FORTH IN THIS SECTION 10 IN DETERMINING
          WHETHER TO ENTER INTO OR OTHERWISE ACCEPT THESE TERMS.</span></p>

        <p><span
        >11. Use of Marks</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>You grant us the right to use
          your name, logo, and a description of your use case to refer to you on our
          website, earnings releases and calls, or marketing or promotional materials,
          subject to your standard trademark usage guidelines that you expressly provide
          to us.</span></p>

        <p><span
        >12. Term, Termination, and Survival</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.1 Term.&nbsp; These Terms, as
          may be updated from time to time, will commence on the date they are accepted
          by you and continue until terminated in accordance with Section 12.2
          (Termination) (“Term”).</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.2 Termination.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.2.1 For Convenience.&nbsp;
          Either party may terminate these Terms and close all of your accounts for any
          reason upon thirty (30) days written notice to the other party. Notwithstanding
          the preceding sentence, if there is an Order Form(s) in effect, Customer may
          not terminate these Terms until such Order Form(s) has expired or been
          terminated in accordance with its terms.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.2.2 Material Breach.&nbsp; We
          may terminate these Terms (including all Order Form(s)) and close all of your
          accounts in the event you commit any material breach of these Terms and fail to
          remedy such material breach within fifteen (15) days of the date we provide
          written notice of such material breach to you. For the avoidance of doubt, your
          breach of our Acceptable Use Policy, including our Service and Country Specific
          Requirements therein, will be considered a material breach of these Terms. You
          may also terminate these Terms (including all Order Form(s)) in the event we
          commit a material breach of these Terms and fail to remedy such material breach
          within fifteen (15) days of the date you provide written notice of such
          material breach to us.&nbsp;</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.2.3 Insolvency.&nbsp; Subject
          to applicable law, either party may terminate these Terms immediately and close
          all of your accounts by providing written notice in the event of the other
          party&apos;s liquidation, commencement of dissolution proceedings, or any other
          proceeding relating to a receivership, failure to continue business, assignment
          for the benefit of creditors, or becoming the subject of bankruptcy.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>12.3 Survival.&nbsp; Upon
          termination of these Terms, the terms of this Section 12.3, and the terms of
          the following Sections will survive (i.e. still apply): Section 3.1(b)
          (regarding our Security Overview), Section 6 (Fees and Payment Terms), Section
          7 (Ownership, Customer Data, and Confidentiality), Section 8.4 (Disclaimer),
          Section 9 (Mutual Indemnification), Section 10 (Limitation of Liability), and
          Section 13 (General).</span></p>

        <p><span
        >13. General</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.1 No Waiver and Order of
          Precedence.&nbsp; No failure or delay by either party in exercising any right
          or enforcing any provision under these Terms will constitute a waiver of that
          right, provision, or any other provision. Any waiver must be in writing and
          signed by each party to be legally binding. Titles and headings of sections of
          these Terms are for convenience only and will not affect the construction of
          any provision of these Terms. In the event of any conflict or inconsistency
          among the following documents, the order of precedence will be: (1) the
          applicable Order Form, (2) these Terms, (3) our Acceptable Use Policy,
          including our Service and Country Specific Requirements therein, (4) any other
          terms incorporated by reference herein, and (5) the applicable Documentation.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.2 Assignment.&nbsp; You will
          not assign, delegate, or otherwise transfer these Terms or any applicable Order
          Form(s), in whole or in part, without our prior written consent. Any attempt by
          you to assign, delegate, or transfer these Terms or any applicable Order
          Form(s) without our consent will be null and void. We may assign, delegate, or
          otherwise transfer these Terms or any applicable Order Form(s), in whole or in
          part, without your consent. Subject to this Section 13.2, these Terms and any
          applicable Order Form(s) will be binding on each party and each party&apos;s
          successors and assigns.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.3 Relationship.&nbsp; Each
          party is an independent contractor in the performance of each and every part of
          these Terms. Nothing in these Terms is intended to create or will be construed
          as creating an employer-employee relationship or a partnership, agency, joint venture,
          or franchise. Each party will be solely responsible for all of its respective
          employees and agents and its respective labor costs and expenses arising in
          connection with its respective employees and agents. Each party will also be
          solely responsible for any and all claims, liabilities, damages, or debts of
          any type that may arise on account of each of its respective activities, or
          those of its respective employees and agents, in the performance of these
          Terms. Neither party has the authority to commit the other party in any way and
          will not attempt to do so or imply that it has the right to do so.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.4 Severability.&nbsp; If any
          provision of these Terms is held by a court or other tribunal of competent
          jurisdiction to be unenforceable, that provision will be limited or eliminated
          to the minimum extent necessary to make it enforceable and, in any event, the
          rest of these Terms will continue in full force and effect.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.5 Notices.&nbsp; Notices to us
          under these Terms will be provided via email to enquiry@evfy.sg. Notices to you
          under these Terms will be provided via (a) email to the email address you
          designate in your account or (b) your account portal.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.6 Force Majeure.&nbsp; No
          failure, delay, or default in performance of any obligation of a party will
          constitute an event of default or breach of these Terms to the extent that such
          failure to perform, delay, or default arises out of a cause, existing or
          future, that is beyond the control and without negligence of such party, including
          action or inaction of governmental, civil, or military authority, fire, strike,
          lockout, or other labor dispute, flood, terrorist act, war, riot, theft,
          earthquake, or other natural disaster. The party affected by such a cause will
          take all reasonable actions to minimize the consequences of such a cause.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.7 Government Terms.&nbsp; We
          provide the Services, including any related software and technology, for
          ultimate federal government end use solely in accordance with these Terms. If
          you (or any End Users) are an agency, department, or other entity of any
          government, the use, duplication, reproduction, release, modification,
          disclosure, or transfer of the Services, or any related documentation of any
          kind, including technical data, software, and manuals, is restricted by these
          Terms. All other use is prohibited and no rights other than those provided in
          these Terms are conferred. The Services were developed fully at private
          expense.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.8 Dispute Resolution.&nbsp; In
          the event of a dispute, claim, or controversy arising out of or in connection
          with these Terms or the breach, termination, enforcement, interpretation, or
          validity thereof (other than for disputes, claims, or controversies related to
          the intellectual property of a party) (collectively, “Disputes”), each party&apos;s
          senior representatives will engage in good faith negotiations with the other
          party&apos;s senior representatives to amicably resolve a Dispute. If parties are
          unable to resolve a Dispute within thirty (30) days after the first request to
          engage in good faith negotiations or within such other time period as the
          parties may agree to in writing, then either party may commence binding
          arbitration under JAMS&apos; Comprehensive Arbitration Rules and Procedures. The
          parties will share equally the fees and expenses of the JAMS arbitrator. The
          arbitration will be conducted by a sole arbitrator chosen by the mutual
          agreement of the parties or, failing that, by JAMS under its then prevailing
          rules. Judgment on the award rendered by the arbitrator may be entered in any
          court of competent jurisdiction. The arbitrator will have the authority to
          grant specific performance or any other equitable or legal remedy, including
          provisional remedies. Each party will be responsible for its own incurred
          expenses arising out of any dispute resolution procedure. Any arbitration
          proceedings will take place in Singapore.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.9 Governing Law and
          Venue.&nbsp; Unless unenforceable under applicable law, these Terms will be
          governed by and interpreted in accordance with the laws of the applicable state
          or country identified below, without regard to conflicts of laws and principles
          that would cause the laws of another jurisdiction to apply. These Terms will
          not be governed by the United Nations Convention on Contracts for the
          International Sale of Goods. Except as provided in Section 13.8 (Dispute
          Resolution), any legal suit, action, or proceeding arising out of or related to
          these Terms or the Services will be instituted in the applicable courts
          identified below, and each party consents to the personal jurisdiction of these
          courts.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.10 Class Action Waiver.&nbsp;
          Each party agrees that any Disputes between the parties must be brought against
          each other on an individual basis only. That means neither party can bring a
          Dispute as a plaintiff or class member in a class action, consolidated action,
          or representative action. An arbitrator cannot combine more than one person&apos;s
          or entity&apos;s Disputes into a single case and cannot preside over any
          consolidated class or representative proceeding. Each party agrees the
          arbitrator&apos;s decision or award in one person&apos;s or entity&apos;s case can only impact
          the person or entity that brought a Dispute and cannot impact or otherwise be
          used to decide Disputes with other people or entities, including other Evfy
          customers. If a court decides that this Section 13.10 is not enforceable or
          valid, then this Section 13.10 will be null and void. But, the rest of these
          Terms will still apply.</span></p>

        <p><span>&nbsp;</span></p>

        <p><span>13.11 Entire Agreement.&nbsp; Except
          as provided in these Terms and any exhibits or attachments, applicable Order
          Form(s), or other terms incorporated by reference into these Terms, these Terms
          supersede all prior and contemporaneous proposals, statements, sales materials,
          presentations, or agreements, oral and written. The parties agree that Section
          7.3 (Confidentiality) hereby supersedes and prevails over all prior,
          contemporaneous, and future non-disclosure or confidentiality agreements
          between the parties in their entirety. No oral or written information or advice
          given by us, our agents, or our employees will create a warranty or in any way
          increase the scope of the warranties or obligations under these Terms. Any term
          or condition stated in your vendor registration form or registration portal or
          in any purchase order document or similar document provided by you will be
          construed solely as evidence of your internal business processes, and the terms
          and conditions contained thereon will be null and void and have no effect with
          regard to these Terms between the parties and be non-binding against us even if
          accepted or signed by us after the date you accept these Terms.</span></p>

        <p><span
        >15. Additional Terms</span></p>

        <p><span
        >15.1 If you are domiciled in the European Economic Area (EEA),
          the United Kingdom, or Switzerland, nothing in these Terms will exclude or
          limit the liability of either party for (i) gross negligence or intentional
          misconduct of such party; (ii) death or personal injury caused by such party's
          negligence; (iii) fraud or fraudulent misrepresentation; or (iv) any other
          liability to the extent that the same may not be excluded or limited as a
          matter of applicable law.</span></p>

        <p><span
        >15.2 If you are domiciled in Germany, we warrant that the
          Services will operate in accordance with the applicable Documentation and will
          materially comply with any specifications contained in the applicable
          Documentation. The parties agree that to the extent you are entitled to any
          statutory warranty rights, the applicable statutory warranty period is hereby
          reduced to twelve (12) months and any and all further warranties are excluded.</span></p>

        <p><span
        >15.3 If you are a microenterprise, small enterprise, or not for
          profit organisation and we provide you with the Services within the European
          Economic Area or United Kingdom, you have read and agreed to the European
          Electronic Communications Code Rights Waiver.</span></p>
      </Grid>
    </Container>
  }
}

export default SmsTermsAndConditions;