import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { 
  Box,
  Grid,
  Tooltip,
  Hidden,
  Divider,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';

export class AutoAssignForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    let data = (props.data) ? props.data : null;
    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);

    this.state = {
        isLoading: isLoading,
        data : data
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
  }

  /* WORKER API */

  /* END WORKER API */

  /* FORM */

  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'right'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>

            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Retry
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }

  setHeader = () => {
    return <Box pt={1} pb={1} className={'custom-grid-header'}>
        <Grid container alignItems={'center'}>
            <Box clone pl={{ xs: 0, md: 2}}>
                <Grid item xs={12} md={3}>
                    <CustomLabel
                        label={'Job Number'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={4}>
                    <CustomLabel
                        label={'Job Description'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={5}>
                    <CustomLabel
                        label={'Job Address'}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }

  customTable = () => {

    return <Box>
        <Grid container>
            <Grid item xs={12}>
                <Hidden xsDown implementation="css">
                    {this.setHeader()}
                </Hidden>
                <Box>
                    {
                        (this.state.data && this.state.data.length > 0 )
                        ?
                        this.state.data.map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={'center'}>
                                    <Box clone pl={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={3}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'DO Number'} placement={'top'}>
                                                        <Box>{item.order_number}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={4}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Job Description'} placement={'top'}>
                                                        <Box>{item.drop_off_description}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={5}>
                                            <CustomInput
                                                placeholder={'Job Address'}
                                                value={item.drop_off_address}
                                                required={true}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    item.drop_off_address = e.target.value;
                                                    this.onChange(item);
                                                }}
                                            />

                                           
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Divider light />
                            </Box>
                        })
                        :
                        <Grid container>
                            <Box clone pt={3} textAlign={'center'}>
                                <Grid item xs={12}>
                                    <CustomLabel
                                        label={'No Results!'}
                                        weight={'bold'}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    }                   

                </Box>
            </Grid>
        </Grid>
    </Box>
  }
  /* END FORM */

  /* ON CHANGE */

  /* SUBMIT */
  onChange = (e) => {
    let currentData = this.state.data;
    currentData.filter(x => x.id == e.id).map((v,k)=>{
      v.drop_off_address = e.drop_off_address;
    });

    this.setState({data : currentData});
  }
  /*END ON CHANGE */

  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;
    
    if(this.props.onSave){
        this.props.onSave(this.state.data);
    }
    
  }

  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.customTable()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default AutoAssignForm;