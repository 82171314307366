import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";

import { 
  Box,
  Grid,
  Select
} from '@material-ui/core';
import {RoleAccessService} from '../../../data/role-access'; 
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/information.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/calendar.svg';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import { Container, Row, Col, Button, Card, ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import wmsApi from '../../../api/wmsApi.jsx';
import ldsApi from '../../../api/ldsApi.jsx';
import moment from "moment";
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';

export class BulkUpdateForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    let grantedAccess = new RoleAccessService();
    this.state = {
        isLoading: isLoading,
        disableSubmit: false,
        workerItems: [],
        grantedAccess : grantedAccess,
        currentRole: window.localStorage.getItem('current_role') || null,
        orderStatusesIsLoading: false,
        orderStatuses: [],
        order_status_id: null,
        do_numbers: '',
        workers: [],
        status: [],
        customers: [],
        customer_rate: [],
        job_type: '',
        user: JSON.parse(localStorage.getItem('user')),
        job_type_validation: false,
        drop_off_date_validation: false,
        period_of_delivery_validation: false,
        drop_off_postal_code_validation: false,
        workerId: null,
        customerId: '',
        drop_off_address: '',
        drop_off_worker_id: '',
        customerName: '',
        drop_off_name: '',
        drop_off_postal_code: '',
        notification_email: '',
        drop_off_contact_no: '',
        drop_off_date: '',
        period_of_delivery: '',
        drop_off_time_planned: '',
        drop_off_description: '',
        xs_package: '',
        s_package: '',
        m_package: '',
        l_package: '',
        oversized_package:'',
        departmentId: '',
        pallets: '',
        boxes: '',
        document: '',
        cartons: '',
        depalletization_request: '',
        selected_package: [],
        size_l_w_h: '',
        waiting_time: '',
        weight: '',
        no_of_movers: '',
        amount: null,
        driver_payout: null,
        driver_notes: '',
        longitude: '',
        latitude: '',
        selectedDoNumberValidated: false,
        drop_off_postal_codeValidated: false,
        drop_off_contact_noValidated: false,
        notification_emailValidated: false,
        vendor_payout: null,

        collection_from_time: "",
        collection_to_time: "",
        collection_timeValidated: false,
  
        delivery_from_time: "",
        delivery_to_time: "",
        delivery_timeValidated: false,

        company: '',
        drop_off_alternate_contact : '',
        drop_off_alt_contact_noValidated: false,
        oldCustomerItems: [],
        customerItems: [],
        oldDriverItems: [],
        time_window: '',
        unAssignDriver: false
    }
  }
  
    componentDidMount() {
        // this.callWorkerDriverReadApi();
        let driver = [];
        this.callVendorReadApi();
        this.callOrderStatusesApi()
        this.callWorkerDriverReadApi('', (arr) => {
          driver.push(arr);
          this.callVendorReadApi('', (arr) => {
            driver.push(arr);
            var result = [].concat.apply([],Object.values(driver));
            this.setState({
              workerItems: result.sort((a, b) => {
                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
            });
          });
        });
        this.callCustomerReadApi(this.state.customerName, (arr) => {
          this.setState({
            customerItems: arr.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            }),
            oldCustomerItems: arr.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            })
          });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.item !== this.state.item) {
            let item = (nextProps.item) ? nextProps.item : null;
            this.setState({
                item: item
            });
        }
    }

  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              // this.setState({
              //   vendors: arr,
              // }, () => {
                if(callback){
                  callback(arr);
                }
              // });
            } else {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
  }
  /* END VENDOR API */

    /* ORDER STATUSES API */
    callOrderStatusesApi = (callback = null) => {
      const { accessToken } = this.context;
  
      this.setState({
        orderStatusesIsLoading: true,
        orderStatuses: [],
      }, () => {
        ldsApi.read('order_statuses', null, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let arr = [];
              if(data && data.length > 0){
                arr = data.map((item, i) => {
                  let value = item.id;
                  let label = item.status;
  
                  if(arr.findIndex(x => x.value === value) === -1){
                    return {
                      value: value,
                      label: label,
                      item: item
                    }
                  } else {
                    return null;
                  }
                });
        
                this.setState({
                  orderStatusesIsLoading: false,
                  orderStatuses: arr,
                }, () => {
                  if(callback){
                    callback(arr);
                  }
                });
              } else {
                this.setState({
                  orderStatusesIsLoading: false,
                  orderStatuses: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
      });
    }

  /* API */
  callCreateApi = (data, callback = null) => {
    const { accessToken } = this.context;

    let row = {}
    row.data = []


    row.data.push(data)

    this.setState({
      isLoading: true
    }, () => {
        ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            apiUtil.toast(data.message != null ? data.message : "Successfully Updated", 'warning', 'error');
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
                apiUtil.toast(error.email[0], 'warning', 'error');
            } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
            }
            
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */

  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
            this.setState({
              workers: arr.sort((a, b) => {
                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
            })
            if(callback){
              callback(arr);
            }
            
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callCustomerReadApi = (search = '', callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState({
      customerItemsIsLoading: true,
      customerItems: [],
    }, () => {
        let param = {
          page: 1,
          // take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          light_data_customer_rate: true
        };
  
        wmsApi.read('customers', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              if(result && result.length > 0){
                for(let i = 0; i < result.length; i++){
                  let item = result[i];
                  
                  let value = item.id;
                  let label = item.company_name;
  
                  if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                      value: value,
                      label: label,
                      item: item
                    });
                  }
                }
                
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: arr,
                }, () => {
                  if(callback){
                    callback(arr);
                  }
                });
              } else {
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
      
    });
  }
  
  callCustomerDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      customerDetailsIsLoading: true,
      customerDetails: null,
    }, () => {
      wmsApi.details('customers', id, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data && data.customer) ? data.customer : null;

            if(result){
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: result,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: null,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              customerDetailsIsLoading: false,
              customerDetails: null,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  
  getTimeWindow = ( s_time = '', e_time = '' ) => {
    let start_time = moment(s_time,'hh:mm');
    let end_time = moment(e_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    let timeslot = '';
    if(diff_time > 0) {
      if(diff_time < 3){
        timeslot = 'express (2h slot)'; 
      } else if(diff_time >= 3 && diff_time <= 5) {
        timeslot = 'next day (3h slot)'; 
      }
    }      
    return timeslot;
  }


  addNewCustomerDetails = (data) => { 
    if(data && data.id){
        this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
            let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
            let branchItems = customerAddresses.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
                item: item,
            }
            });

            let departmentItems = [];
            if(customerData && customerData.departments && customerData.departments.length > 0){
            departmentItems = customerData.departments.map((item, i) => {
                return {
                value: item.id,
                label: item.name,
                item: item,
                }
            });
            }

            this.setState({
            branchItems: branchItems,
            departmentItems: departmentItems,
            }, () => {
            let drop_off_address = '';
            let drop_off_postal_code = '';
            let order_branch_id = (data && data.customer_addresses && data.customer_addresses[0] && data.customer_addresses[0].id) ? data.customer_addresses[0].id : '';
            let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
            if(drop_off_addressItem && drop_off_addressItem.length > 0){
                drop_off_address = drop_off_addressItem[0].item.address_line1;
                drop_off_postal_code = drop_off_addressItem[0].item.postal_code;
            }
            this.setState({
                // order_branch_id: order_branch_id,
                // drop_off_address: drop_off_address,
                // drop_off_postal_code: drop_off_postal_code
            });
            
            let departmentItem = null;
            let department_id = (this.state.departmentItems && this.state.departmentItems[0] && this.state.departmentItems[0].value) ? this.state.departmentItems[0].value : '';
            let departmentArray = (this.state.departmentItems && this.state.departmentItems.length > 0) ? this.state.departmentItems.filter(x => x.value === department_id) : [];
            if(departmentArray && departmentArray.length > 0){
                departmentItem = departmentArray[0];
            }
            this.setState({
                // department_id: department_id,
                // departmentItem: departmentItem,
            });
            
            if(departmentItem && departmentItem.item && departmentItem.item.contact_people && departmentItem.item.contact_people.length > 0){
                for(let x = 0; x < departmentItem.item.contact_people.length; x++){
                let item = departmentItem.item.contact_people[x];

                this.setState({
                    // notification_email: item.email,
                    // drop_off_name: item.first_name + item.last_name,
                    // drop_off_contact_name: item.first_name,
                    // drop_off_contact_no: item.contact_no,
                });
                }
            }

            this.callGeoCoderAddressApi(drop_off_address, (data) => {
                if(data){
                    this.setState({
                    latitude: data.lat,
                    longitude: data.lng
                    })
                }
            });
            /* END STEPS SECTION */
            });
        });
        }
    }

  deliveryAddressOnChangeHandler = (dropoff_address) => {
  
      this.callGeoCoderAddressApi(dropoff_address, (data) => {
          if(data){
              this.setState({
              latitude: data.lat,
              longitude: data.lng
              })
          }
      })
  }
  
  checkAndSetPackage = () => {
    this.setState({
      // xs_package: this.state.xs_package != '' ? this.state.xs_package : 0,
      // s_package: this.state.s_package != '' ? this.state.s_package : 0,
      // m_package: this.state.m_package != '' ? this.state.m_package : 0,
      // l_package: this.state.l_package != '' ? this.state.l_package : 0,
      // oversized_package: this.state.oversized_package != '' ? this.state.oversized_package : 0,
      // pallets: this.state.pallets != '' ? this.state.pallets : 0,
    });        
  }

  callGeoCoderAddressApi = (address = '', callback = null) => {
      const { accessToken } = this.context;
  
      this.setState({
        geocoderAddressIsLoading: true,
      }, () => {
        let params = {
          address: address,
        };
  
        ldsApi.create('geo_functions/geocoder_address', params, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              if(data && data.result){
                if(callback){
                  callback(data.result);
                }
              } else {
                if(callback){
                  callback(null);
                }
              }
            }, (error, type) => {
              if(callback){
                callback(null);
              }
            });
        });
      });
    }
  /* FORM */
  setForm = () => {
    return <Container fluid>
    <Row className="p-4">
    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) && <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900">
      <div className="max-xs-width-control-900 w-25 px-3 text-end">D.O Number *</div>
      <div className="w-75 max-xs-width-control-900">
      <textarea className='form-control w-100' rows="5"
      value={this.state.do_numbers}
      onChange={e => {
        this.setState({
            do_numbers: e.target.value.replace(/[,]/g, ""),
        })
      }}
      />
      {this.state.selectedDoNumberValidated && <span style={{color:"red"}}>Do Number(s) are required<br/><br/></span>}
      <span>Copy and paste / enter / DO number into the box. One D.O. No. per line.</span>
      </div>
      </Col>}
    </Row>
    <Row className="fs-5 font-medium">Details to Update:</Row>
    <Row className="p-4">
        {/*<Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900">
         <div className="text-end w-50 max-xs-width-control-900">Department</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><input type="text" className="form-control w-100" /></div> 
        </Col>*/}
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
          <div className="text-end w-50 max-xs-width-control-900">Client Company</div>
          <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomAutosuggest 
            placeholder={'Enter any keyword to search for customers'}
            labelComponent={<CustomTooltip title={'Select from your list of customers. Create one if u dont have them in your list.'} />}
            items={this.state.customerItems}
            isLoading={this.state.customerItemsIsLoading}
            value={this.state.customerName}
            onChange={(value) => {
              if(value){
                if(this.state.customerId > 0){
                  this.setState({
                    //customerNameValidated: false,
                    customerName: value.label,
                    customerId: value.value,
                    customerItem: value.item,
                    departmentId: '',
                    customer_rate: value.item.customer_rates && value.item.customer_rates.length > 0 ? value.item.customer_rates : [],
                    isFormChanged: true,
                  }, () => {
                    // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                    this.setState({
                      openDialog: true,
                      openDialogItem: value.item,
                      // amount: client,
                      // driver_payout: payout,
                    });
                  });
                } else {
                  this.setState({
                    //customerNameValidated: false,
                    customerName: value.label,
                    customerId: value.value,
                    customerItem: value.item,
                    departmentId: '',
                    customer_rate: value.item.customer_rates && value.item.customer_rates.length > 0 ? value.item.customer_rates : [],
                    isFormChanged: true,
                  }, () => {
                    // this.addNewCustomerDetails(value.item);
                    // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                    // this.setState({
                    //   amount: client,
                    //   driver_payout: payout
                    // })
                  });
                }
              } else {
                this.setState({
                  //customerNameValidated: true,
                  customerName: '',
                  customerId: '',
                  departmentId: '',
                  customerItem: null,
                  customer_rate: [],
                  isFormChanged: true,
                }, () => {
                  this.setState({
                    branchItems: [],
                    departmentItems: [],
                    // amount: null,
                    // driver_payout: null
                  });
                });
              }
            }}
            onSearch={(value, e) => {
              this.setState({
                customerName: value,
                customerId: value != '' ? this.state.customerId : '',
                isFormChanged: true,
              }, () => {
                e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
              });
            }}
            onClick={(value, e) => {
              e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
            }}
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
          <div className="text-end w-50 max-xs-width-control-900">Trip Date</div>
          <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomInput
            placeholder={'DD/MM/YYYY'}
            color={'secondary'}
            type={"date"}
            endAdornment={(obj) => {
              return <Box
                  onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                  }}
              >
                  <CalendarIcon />
              </Box>
            }}
            value={this.state.drop_off_date}
            error={this.state.drop_off_date_validation}
            onChange={(e) => {
                this.setState({
                  drop_off_date: e.target.value,
                  drop_off_dateValidated: (e.target.value !== '') ? false : true,
                  isFormChanged: true,
                }, ()=>{
                  // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                  // this.setState({
                  //   amount: client,
                  //   driver_payout: payout
                  // })
                });
            }}
          />
          
          </div>
        </Col>
        <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
          <div className="max-xs-width-control-900 text-end w-50">Department</div>
          <div className="max-xs-width-control-900 ps-2 w-50">
          <Select className="max-xs-width-control-900 custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
            placeholder={'Select Department here...'}
            value={this.state.departmentId}
            native
            disableUnderline
            onChange={(e) => {
              this.setState({
                  departmentId: e.target.value,
                  isFormChanged: true,
              })
            }}
          >
            <option value=""> Select Department </option>
            {this.state.customerItem && this.state.customerItem.departments.map((dd, index)=> 
              <option value={dd.id}>{dd.name}</option>
            )}
          </Select>
          </div>
        </Col>
        <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
            <div className="max-xs-width-control-900 text-end-style w-50">
              Time Window
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50 d-flex text-end-style">
              <Select
                className="select-width-control-100 custom-input-component padding-right-select"
                error={this.state.collection_timeValidated}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.collection_from_time}
                native
                disableUnderline
                onChange={(e) => {
                  this.setState(
                    {
                      collection_from_time: e.target.value,
                      isFormChanged: true,
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.collection_from_time,this.state.collection_to_time);
                      this.setState({
                        time_window: timeslot
                      });
                    }
                  );
                  if (this.state.job_steps && this.state.job_steps.length > 0) {
                    let step = this.state.job_steps[0];

                    step.job_step_name =
                      e.target.value !== "" ? e.target.value : "Delivery";
                    let job_steps = [step];
                    this.setState({
                      job_steps,
                    });
                  }
                }}
              >
                <option value="">Select From</option>
                <option value="0:00"> 0:00 </option>
                <option value="0:30"> 0:30 </option>
                <option value="1:00"> 1:00 </option>
                <option value="1:30"> 1:30 </option>
                <option value="2:00"> 2:00 </option>
                <option value="2:30"> 2:30 </option>
                <option value="3:00"> 3:00 </option>
                <option value="3:30"> 3:30 </option>
                <option value="4:00"> 4:00 </option>
                <option value="4:30"> 4:30 </option>
                <option value="5:00"> 5:00 </option>
                <option value="5:30"> 5:30 </option>
                <option value="6:00"> 6:00 </option>
                <option value="6:30"> 6:30 </option>
                <option value="7:00"> 7:00 </option>
                <option value="7:30"> 7:30 </option>
                <option value="8:00"> 8:00 </option>
                <option value="8:30"> 8:30 </option>
                <option value="9:00"> 9:00 </option>
                <option value="9:30"> 9:30</option>
                <option value="10:00"> 10:00 </option>
                <option value="10:30"> 10:30 </option>
                <option value="11:00"> 11:00 </option>
                <option value="11:30"> 11:30 </option>
                <option value="12:00"> 12:00 </option>
                <option value="12:30"> 12:30 </option>
                <option value="13:00"> 13:00 </option>
                <option value="13:30"> 13:30 </option>
                <option value="14:00"> 14:00 </option>
                <option value="14:30"> 14:30 </option>
                <option value="15:00"> 15:00 </option>
                <option value="15:30"> 15:30 </option>
                <option value="16:00"> 16:00 </option>
                <option value="16:30"> 16:30 </option>
                <option value="17:00"> 17:00 </option>
                <option value="17:30"> 17:30 </option>
                <option value="18:00"> 18:00 </option>
                <option value="18:30"> 18:30 </option>
                <option value="19:00"> 19:00 </option>
                <option value="19:30"> 19:30 </option>
                <option value="20:00"> 20:00 </option>
                <option value="20:30"> 20:30 </option>
                <option value="21:00"> 21:00 </option>
                <option value="21:30"> 21:30 </option>
                <option value="22:00"> 22:00 </option>
                <option value="22:30"> 22:30 </option>
                <option value="23:00"> 23:00 </option>
                <option value="23:30"> 23:30 </option>
              </Select>
              <div className="text-end-style m-2">To</div>
              <Select
                className="select-width-control-100 custom-input-component padding-right-select"
                error={this.state.collection_timeValidated}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.collection_to_time}
                native
                disableUnderline
                onChange={(e) => {
                  this.setState(
                    {
                      collection_to_time: e.target.value,
                      isFormChanged: true,
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.collection_from_time,this.state.collection_to_time);
                      this.setState({
                        time_window: timeslot
                      });
                    }
                  );
                  if (this.state.job_steps && this.state.job_steps.length > 0) {
                    let step = this.state.job_steps[0];

                    step.job_step_name =
                      e.target.value !== "" ? e.target.value : "Delivery";
                    let job_steps = [step];
                    this.setState({
                      job_steps,
                    });
                  }
                }}
              >
                <option value="">Select To</option>
                <option value="0:00"> 0:00 </option>
                <option value="0:30"> 0:30 </option>
                <option value="1:00"> 1:00 </option>
                <option value="1:30"> 1:30 </option>
                <option value="2:00"> 2:00 </option>
                <option value="2:30"> 2:30 </option>
                <option value="3:00"> 3:00 </option>
                <option value="3:30"> 3:30 </option>
                <option value="4:00"> 4:00 </option>
                <option value="4:30"> 4:30 </option>
                <option value="5:00"> 5:00 </option>
                <option value="5:30"> 5:30 </option>
                <option value="6:00"> 6:00 </option>
                <option value="6:30"> 6:30 </option>
                <option value="7:00"> 7:00 </option>
                <option value="7:30"> 7:30 </option>
                <option value="8:00"> 8:00 </option>
                <option value="8:30"> 8:30 </option>
                <option value="9:00"> 9:00 </option>
                <option value="9:30"> 9:30</option>
                <option value="10:00"> 10:00 </option>
                <option value="10:30"> 10:30 </option>
                <option value="11:00"> 11:00 </option>
                <option value="11:30"> 11:30 </option>
                <option value="12:00"> 12:00 </option>
                <option value="12:30"> 12:30 </option>
                <option value="13:00"> 13:00 </option>
                <option value="13:30"> 13:30 </option>
                <option value="14:00"> 14:00 </option>
                <option value="14:30"> 14:30 </option>
                <option value="15:00"> 15:00 </option>
                <option value="15:30"> 15:30 </option>
                <option value="16:00"> 16:00 </option>
                <option value="16:30"> 16:30 </option>
                <option value="17:00"> 17:00 </option>
                <option value="17:30"> 17:30 </option>
                <option value="18:00"> 18:00 </option>
                <option value="18:30"> 18:30 </option>
                <option value="19:00"> 19:00 </option>
                <option value="19:30"> 19:30 </option>
                <option value="20:00"> 20:00 </option>
                <option value="20:30"> 20:30 </option>
                <option value="21:00"> 21:00 </option>
                <option value="21:30"> 21:30 </option>
                <option value="22:00"> 22:00 </option>
                <option value="22:30"> 22:30 </option>
                <option value="23:00"> 23:00 </option>
                <option value="23:30"> 23:30 </option>
              </Select>
            </div>
          </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
          <div className="text-end w-50 max-xs-width-control-900">Rate</div>
          <div className="ps-2 w-50 max-xs-width-control-900">
          <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
            placeholder={'Enter Job Type here...'}
            value={this.state.job_type}
            native
            error={this.state.job_type_validation}
            disableUnderline
            onChange={(e) => {
              this.setState({
                  job_type: e.target.value,
                  isFormChanged: true,
                  delivery_typeValidated: (e.target.value === '') ? true: false
              },() =>{
                  // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                  // console.log(client, payout , "cleintpayout")
                  // this.setState({
                  // amount: client,
                  // driver_payout: payout
                  // })
              });
          }}
          >
            <option value=""> Select Job Type </option>
            <option value={'Standard'}>Standard </option>
            <option value={'Project'}>Project</option>
          </Select>
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
          <div className="text-end w-50 max-xs-width-control-900">Category</div>
          <div className="ps-2 w-50 max-xs-width-control-900">
          <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
            placeholder={'Enter Job Type here...'}
            value={this.state.period_of_delivery}
            native
            error={this.state.period_of_delivery_validation}
            disableUnderline
            onChange={(e) => {
              let peroid = e.target.value
        
              this.setState({
                  period_of_delivery: e.target.value,
                  isFormChanged: true,
                  period_of_deliveryValidated: (e.target.value === '') ? true: false
              },() =>{
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                //   this.setState({
                //   amount: client,
                //   driver_payout: payout
                //   })
              });
          }}
          >
            <option value=""> Select Period of Delivery </option>
            <option value={'Same Day'}>Same Day</option>
            <option value={'Next Day'}>Next Day</option>
            
          </Select>
          </div>
        </Col>
    </Row>
    <Row className="p-4">
    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) &&  <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end col-3 max-xs-width-control-900 me-1">Address</div>
        <div className="col-9 max-xs-width-control-900">
        <CustomGoogleMapSearch
        className="w-100"
            value={this.state.drop_off_address}
            // error={this.state.drop_off_addressValidated}
            onBlur={() => {
              this.deliveryAddressOnChangeHandler(this.state.drop_off_address)   
            }}
            onChange={(e) => {                                            
                this.setState({
                    drop_off_address: e.target.value.trim().length === 0 ? '' : e.target.value,
                    latitude: '',
                    longitude: '',
                    isFormChanged: true,
                });
            }}
            onPlacesChanged={(place, e) => {
            // this.deliveryAddressOnChangeHandler(place)
            let address = apiUtil.getGmapAddress(place);
                this.setState({
                drop_off_address: address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                isFormChanged: true,
                });
                this.deliveryAddressOnChangeHandler(address)
            }}
        />
        </div>
        </Col>}
        <Col xs={12} md={6} className="py-2 max-xs-width-control-900">
          <div className="d-flex col-xs-12 max-xs-width-control-900 align-items-center">
            <div className="text-end w-50 max-xs-width-control-900">Driver-Partner</div>
            <div className="ps-2 w-50 max-xs-width-control-900">
            <CustomAutosuggest
              placeholder={'Enter any keyword to search for Driver'}
              items={this.state.workerItems}
              disabled={this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Edit') ? false : true}
              isLoading={this.state.workerItemsIsLoading}
              value={this.state.workerName? this.state.workerName : this.state.vendorName ? this.state.vendorName : ''}
              onChange={(value) => {
                if (value) {
                  let check_driver = this.state.workers.filter(dd => dd.value == value.value && dd.label == value.label);
                  if(check_driver.length > 0) {
                    this.setState({
                      workerName: value.label,
                      workerId: value.value,
                      workerItem: value.item,
                      vendorName : '',
                      vendorId : null,
                      vendorItem : null,
                      isFormChanged: true,
                    }, () => {
                      let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                      if(orderStatusItem){
                        this.setState({
                          order_status: orderStatusItem.item,
                          //order_status_id: orderStatusItem.value,
                        });
                      }
                    });
                  } else {
                    this.setState({
                      assign_type: false,
                      vendorName: value.label,
                      vendorId: value.value,
                      vendorItem: value.item,
                      workerName: '',
                      workerId: '',
                      workerItem: null,
                      isFormChanged: true,
                    });
                  }
                } else {
                  this.setState({
                    assign_type: true,
                    workerName: '',
                    workerId: '',
                    workerItem: null,
                    isFormChanged: false,
            
                    vendorName : '',
                    vendorId : null,
                    vendorItem : null,
                    orderStatusItem: null,
                    order_status_id: null,
                  }, () => {
                    // let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                    // if(orderStatusItem){
                    //   this.setState({
                    //     order_status: orderStatusItem.item,
                    //     order_status_id: orderStatusItem.value,
                    //   });
                    // }
                  });
                }
              }}
              onSearch={(value, e) => {
                this.setState({
                  disableSubmit: true,
                  workerName : value,
                  vendorName : value,
                  vendorId: value != '' ? this.state.vendorId : '',
                  workerId: value != '' ? this.state.workerId : '',
                  //order_status_id: value == '' ? null : this.state.order_status_id,
                  orderStatusItem: value == '' ? null : this.state.orderStatusItem
                }, ()=> {
                   this.setState({
                      disableSubmit: false
                    });
                })
                e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
              }}
              onClick={(value, e) => {
                e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
              }}
              onInit={() => {
                // let workerItems = []
                // this.callWorkerDriverReadApi('', (arr) => {
                //    workerItems.push(arr);
                //    this.callVendorReadApi('', (vendor) => {
                //      workerItems.push(vendor);
                //      var result = [].concat.apply([],Object.values(workerItems));
                //      this.setState({
                //        workerItems: result,
                //        oldDriverItems: result
                //      });
            
                //    });                
                // });
              }}
            />        
            </div>
          </div>
          <div className="d-flex px-2 col-xs-12 max-xs-width-control-900">
            <div className="text-end w-50"></div>
            <div className="col-xs-12">
                <CustomCheckbox 
                value={this.state.unAssignDriver}
                onChange={e => {
                  let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                  this.setState({
                    unAssignDriver: !this.state.unAssignDriver,
                  },() =>{
                    this.setState({
                      order_status_id: e ? orderStatusItem && orderStatusItem.value : null,    
                    }); 
                  });
                }}
                label="Unassign existing driver(s)"
                />               
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Postal Code</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'Postal Code'}
            value ={this.state.drop_off_postal_code}
            error ={this.state.drop_off_postal_codeValidated}
            type={'number'}
            labelComponent={<CustomTooltip title={'Postal Code'} />}
            onPaste = {(e) => {
              if(/^[0-9\b]+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }               
            }}
            onInput = {(e) =>{
                e.target.value = e.target.value.toString().slice(0,6)
            }}
            onChange={(e) => {
              if(/^[0-9\b]+$/.test(e.target.value)) {
                this.setState({
                    drop_off_postal_code: e.target.value,
                    isFormChanged: true,
                    drop_off_postal_codeValidated: (e.target.value === '') ? true : false,
                });
              } else if (this.state.drop_off_postal_code != null && e.target.value == '') {
                this.setState({
                  drop_off_postal_code: e.target.value,
                })
              }
            }}
        />
        </div>
      </Col>
    </Row>
    <Row className="p-4">
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
          <div className="text-end w-50 max-xs-width-control-900">Recipient Name</div>
          <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput
          value={this.state.drop_off_name}
          onChange={e => {
            this.setState({
              drop_off_name: e.target.value,
            })
          }}
          />
          </div>
        </Col>
        <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
            <div className="max-xs-width-control-900 text-end w-50">
              Recipient Company
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50">
              <CustomInput
                placeholder={"Company"}
                type={"text"}
                value={this.state.company}
                onChange={(e) => {
                  this.setState({
                    company: e.target.value,
                    isFormChanged: true,
                  });
                }}
              />
            </div>
        </Col>

        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Recipient Contact</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'Recipient Contact'}
            value ={this.state.drop_off_contact_no}
            error ={this.state.drop_off_contact_noValidated}
            onChange={(e) => {
                this.setState({
                  drop_off_contact_no: e.target.value,
                  isFormChanged: true,
                    
                });
            }}
        /></div>
        </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Recipient Email</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'Recipient Email'}
            type={'email'}
            value ={this.state.notification_email}
            error ={this.state.notification_emailValidated}
            onChange={(e) => {
                this.setState({
                  notification_email: e.target.value,
                  isFormChanged: true,
                    
                });
            }}
        /></div>
        </Col>
        <Col xs={12} md={6} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Alternative Contact</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'Recipient Contact'}
            value ={this.state.drop_off_alternate_contact}
            error ={this.state.drop_off_alt_contact_noValidated}
            onChange={(e) => {
                this.setState({
                  drop_off_alternate_contact: e.target.value,
                  isFormChanged: true,
                    
                });
            }}
        /></div>
        </Col>
    </Row>
    <Row className="p-4">
    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) && <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900">
        <div className="text-end w-25 py-2 max-xs-width-control-900">Item Description</div>
        <div className="ps-2 w-75 max-xs-width-control-900">
          <textarea className='form-control' rows="5"
          value={this.state.drop_off_description}
          onChange={e => {
            this.setState({
              drop_off_description: e.target.value,
              isFormChanged: true,
            })
          }}
          />
        </div>
        </Col>}
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">
          <span data-tooltip="Size limit (L+W+H) <80cm / 1KG">
            XS Packages <InfoIcon />
          </span>
        </div>
        <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomInput
              placeholder={'5'}
              type={'number'}
              value={this.state.xs_package}
              inputProps={{min: 0}}
              onPaste={(e) => {
                if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                  e.preventDefault()
                  return false;
                }
              }}
              onKeyDown={(e) => {
                if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let selected_package = this.state.selected_package
                let index = selected_package.findIndex(dd => dd.package_id === 1);
                if (e.target.value >= 1 && e.target.value !== '') {
                   if(index === -1) {
                    let pack = {
                      package_id: 1,
                      name: 'XS',
                      package_quantity: e.target.value > 0 ? e.target.value : ''
                    }
                    selected_package.push(pack)
                   } else {
                    selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                    delete selected_package[index]["delete"] 
                   }
                } else {
                  if (index !== -1 && selected_package[index]["id"] > 0 ) {
                    selected_package[index]["delete"] = true
                  } else {
                    selected_package = selected_package.filter(pp => pp.package_id !== 1)
                  }
                }
                  this.setState({
                    xs_package: e.target.value > 0 ? e.target.value : '',
                    selected_package: selected_package,
                    isFormChanged: true,
                  }, () => {
                    this.checkAndSetPackage();
                    // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                    // this.setState({
                    //   amount: client,
                    //   driver_payout: payout
                    // })
                  });
              }}
          />
        </div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">
          <span data-tooltip="Size limit (L+W+H) <120cm / 5KG">
            S Packages <InfoIcon />
          </span>
        </div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'5'}
            type={'number'}
            inputProps={{min: 0}}
            value={this.state.s_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 2);
              if (e.target.value >= 1 && e.target.value !== '') {
                if(index === -1) {
                  let pack = {
                    package_id: 2,
                    name: 'S',
                    package_quantity: e.target.value > 0 ? e.target.value : ''
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 2)
                }
              }
                
              this.setState({
                s_package: e.target.value > 0 ? e.target.value : '',
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                this.checkAndSetPackage();
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                // this.setState({
                //   amount: client,
                //   driver_payout: payout
                // })
              });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">
          <span data-tooltip="Size limit (L+W+H) <150cm / 15KG">
            M Packages <InfoIcon />
          </span>
        </div>
        <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            value={this.state.m_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 3);
              if (e.target.value >= 1 && e.target.value !==  '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 3,
                    name: 'M',
                    package_quantity: e.target.value > 0 ? e.target.value : ''
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 3)
                }
              }
                
              this.setState({
                m_package: e.target.value > 0 ? e.target.value : '',
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                this.checkAndSetPackage();
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                // this.setState({
                //   amount: client,
                //   driver_payout: payout
                // })
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">
          <span data-tooltip="Size limit (L+W+H) <180cm / 25KG">
            L Packages <InfoIcon />
          </span>
        </div>
        <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            value={this.state.l_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 4);
              if (e.target.value >= 1 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 4,
                    name: 'L',
                    package_quantity: e.target.value > 0 ? e.target.value : ''
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 4)
                } 
              }
                
              this.setState({
                l_package: e.target.value > 0 ? e.target.value : '',
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                this.checkAndSetPackage();
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                // this.setState({
                //   amount: client,
                //   driver_payout: payout
                // })
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">
          <span data-tooltip="Please check with Ops">
            Oversized Packages <InfoIcon />
          </span>
        </div>
        <div className="ps-2 w-50 max-xs-width-control-900">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            value={this.state.oversized_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 6);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 6,
                    name: 'Oversized Package',
                    package_quantity: e.target.value > 0 ? e.target.value : ''
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 6)
                }
                 
              }
                
              this.setState({
                oversized_package: e.target.value > 0 ? e.target.value : '',
                // selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                this.checkAndSetPackage(); 
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.job_type, this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code)
                // this.setState({
                //   amount: client,
                //   driver_payout: payout
                // })
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="LxWxH (1.1M x 1.2M x 1.5M) / 100KG">
            Pallets <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            value={this.state.pallets}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E' || e.key == '-' || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 5);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 5,
                    name: 'Pallets',
                    package_quantity: e.target.value > 0 ? e.target.value : ''
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value > 0 ? e.target.value : ''
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 5)
                }
                 
              }
                
              this.setState({
                pallets: e.target.value > 0 ? e.target.value : '',
                selected_package: selected_package,
              },() => {
                this.checkAndSetPackage(); 
              });
            }}
        />
        </div>
      </Col>
      {/* <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900">
        <div className="text-end w-50 max-xs-width-control-900">Boxes</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'5'}
            type={'number'}
            value={this.state.boxes}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    boxes: e.target.value,
                    isFormChanged: true,
                });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900">
        <div className="text-end w-50 max-xs-width-control-900">Documents</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'Document'}
            type={'number'}
            value={this.state.document}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    document: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900">
        <div className="text-end w-50 max-xs-width-control-900">Cartons</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <CustomInput
            placeholder={'3'}
            type={'number'}
            value={this.state.cartons}
            onKeyDown={(e) => {
              if(e.key == 'e' || e.key == 'E') {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    cartons: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col> */}
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Depalletization Request</div>
        <div className="ps-2 w-50 max-xs-width-control-900">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Depalletization Request'}
          value={this.state.depalletization_request}
          native
          disableUnderline
          onChange={(e) => {
            this.setState({
                depalletization_request: e.target.value,
                isFormChanged: true,
            });
        }}
        >
          <option value=""> Select Depalletization Request </option>
          <option value={'Y'}>Yes</option>
          <option value={'N'}>No</option>
          
        </Select>
        </div>
        </Col>
        <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Size in cm<br />(L + W + H)</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="text"
        value={this.state.size_l_w_h}
        // onPaste={(e) => {
        //   if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
        //     e.preventDefault()
        //     return false;
        //   }
        // }}
        // onKeyDown={(e) => {
        //   if(e.key == 'e' || e.key == 'E' || e.key == '-') {
        //     e.preventDefault();
        //   }
        // }}
        onChange={e => {
          this.setState({
            size_l_w_h: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
        <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Weight (KG)</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="text"
        value={this.state.weight}
        onPaste={(e) => {
          if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
            e.preventDefault()
            return false;
          }
        }}
        onKeyDown={(e) => {
          if(e.key == 'e' || e.key == 'E' || e.key == '-') {
            e.preventDefault();
          }
        }}
        onChange={e => {
          this.setState({
            weight: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
        <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Waiting Time</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="text"
        value={this.state.waiting_time}
        onChange={e => {
          this.setState({
            waiting_time: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
        <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Additional Manpower</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="number"
        value={this.state.no_of_movers}
        onPaste={(e) => {
          if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
            e.preventDefault()
            return false;
          }
        }}
        onKeyDown={(e) => {
          if(e.key == 'e' || e.key == 'E' || e.key == '-') {
            e.preventDefault();
          }
        }}
        onChange={e => {
          this.setState({
            no_of_movers: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Price</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="number"
        value={this.state.amount}
        onPaste={(e) => {
          if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
            e.preventDefault()
            return false;
          }
        }}
        onKeyDown={(e) => {
          if(e.key == 'e' || e.key == 'E' || e.key == '-') {
            e.preventDefault();
          }
        }}
        onChange={e => {
          this.setState({
            amount: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
        {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) && <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Driver Payout</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="number"
        min={0}
        value={this.state.driver_payout}
        onPaste={(e) => {
          if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
            e.preventDefault()
            return false;
          }
        }}
        onKeyDown={(e) => {
          if(e.key == 'e' || e.key == 'E' || e.key == '-') {
            e.preventDefault();
          }
        }}
        onChange={e => {
          this.setState({
            driver_payout: e.target.value >= 0 ? e.target.value : 0,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>}
        <Col xs={12} md={4} className="d-flex py-2 max-xs-width-control-900 align-items-center">
        <div className="text-end w-50 max-xs-width-control-900">Vendor Payout</div>
        <div className="ps-2 w-50 max-xs-width-control-900"><CustomInput type="number" 
        value={this.state.vendor_payout}
        onPaste={(e) => {
          if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
            e.preventDefault()
            return false;
          }
        }}
        onKeyDown={(e) => {
          if(e.key == 'e' || e.key == 'E' || e.key == '-') {
            e.preventDefault();
          }
        }}
        onChange={e => {
          this.setState({
            vendor_payout: e.target.value,
            isFormChanged: true,
          })
        }}
        /></div>
        </Col>
    </Row>
    <Row className="p-4">
        <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900">
        <div className="text-end w-25 py-2 max-xs-width-control-900">Notes to Driver-Partner</div>
        <div className="ps-2 w-75 max-xs-width-control-900">
          <textarea className='form-control' rows="5"
          value={this.state.driver_notes}
          onChange={e => {
            this.setState({
              driver_notes: e.target.value,
              isFormChanged: true,
            })
          }}
          />
        </div>
        </Col>
    </Row>
</Container>
  }
  
  setButtons = () => {
    return <Box>
        
        <Row>
            <Col  xs={12} className="d-flex justify-content-center align-items-center">
            <Button className="upload-style-cancel"
              onClick={() => {
                    if(this.props.onClose){
                      this.props.onClose();
                  }} }
            > {"Cancel"} </Button>
            <Button className="upload-style"
                type='submit'
                isLoading={this.state.isLoading}
                disabled={this.state.disableSubmit}
              >{this.state.isLoading? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}</Button> 
            </Col>
        </Row>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: false,
        drop_off_postal_codeValidated : false,
        drop_off_contact_noValidated: false,
        notification_emailValidated: false,
        selectedDoNumberValidated : false
    });

    const form = e.currentTarget;
    let doNumberValidated = true
    if(this.state.do_numbers == "" || this.state.do_numbers == null) {
      doNumberValidated = false
      this.setState({
        selectedDoNumberValidated : true
      })

    }

    let isFormEdited = false

    if(this.state.customerId || this.state.period_of_delivery || this.state.drop_off_address || this.state.workerId || this.state.vendorId || this.state.order_status_id || this.state.drop_off_date || this.state.drop_off_description || this.state.collection_from_time || this.state.collection_to_time || this.state.company || this.state.size_l_w_h || this.state.drop_off_alternate_contact || this.state.driver_payout || this.state.driver_notes || this.state.drop_off_postal_code || this.state.job_type || this.state.customerName || this.state.drop_off_contact_no || this.state.drop_off_name || this.state.notification_email || this.state.xs_package || this.state.s_package || this.state.m_package || this.state.l_package || this.state.oversized_package || this.state.vendor_payout || this.state.depalletization_request || this.state.weight || this.state.size || this.state.waiting_time || this.state.no_of_movers || this.state.amount || this.state.latitude || this.state.longitude || this.state.pallets || this.state.unAssignDriver) {
      isFormEdited = true
    }

  
    let postal_code_validation = true
    if(this.state.drop_off_postal_code  && (this.state.drop_off_postal_code < 0 || this.state.drop_off_postal_code.length < 2 || this.state.drop_off_postal_code.length > 6)){
      postal_code_validation = false
      this.setState({
        drop_off_postal_codeValidated: true,
      });
    }

    let emailValidated = true
    if(this.state.notification_email && !apiUtil.emailValidation(this.state.notification_email)) {
      this.setState({
        notification_emailValidated: true
      })
      emailValidated = false
    }

    let phoneValidation = true 
    if (this.state.drop_off_contact_no && !apiUtil.phoneValidationWithPlus(this.state.drop_off_contact_no)) {
      this.setState({
        drop_off_contact_noValidated: true
      })
      phoneValidation = false
    }

    let alt_contact_validated = true
    if(this.state.drop_off_alternate_contact && !apiUtil.phoneValidationWithPlus(this.state.drop_off_alternate_contact) ){
      alt_contact_validated = false
      this.setState({
        drop_off_alt_contact_noValidated: true,
      });
    } 
  
    let start_time = moment(this.state.collection_from_time,'hh:mm');
    let end_time = moment(this.state.collection_to_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);  
    let collection_timeValidate =  true; 

    if ((this.state.collection_from_time != '' && this.state.collection_to_time != '' && (this.state.collection_from_time == this.state.collection_to_time || diff_time < 0)) || ((this.state.collection_from_time != '' || this.state.collection_to_time != '') && isNaN(diff_time))) {
      collection_timeValidate =  false;
      this.setState({
        collection_timeValidated: true,
        isLoading: false,
      });
    } else {
      this.setState({
        collection_timeValidated: false,
      });
    }

    if (form.checkValidity() === true && doNumberValidated && isFormEdited && emailValidated && phoneValidation && postal_code_validation && collection_timeValidate && alt_contact_validated ) {
      this.getForm()
        this.callCreateApi(this.getForm(), (data) => {
            if(data){
                if(this.props.onSave){
                    this.props.onSave(data);
                }
            }
        });
    } else {
        if(isFormEdited == false) {
          apiUtil.toast("Nothing is changed at form", 'warning', 'error');
        }
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let selected_do_number = this.state.do_numbers.replace(/\n|\r|,/g, ",")

    let drop_off_contact_no = '';
    let drop_off_alternate_contact = '';
    drop_off_contact_no = this.state.drop_off_contact_no.length === 8 ? '+65' +this.state.drop_off_contact_no : this.state.drop_off_contact_no ;
    drop_off_alternate_contact = this.state.drop_off_alternate_contact.length === 8 ? '+65' +this.state.drop_off_alternate_contact : this.state.drop_off_alternate_contact ;
    
    selected_do_number = selected_do_number.split(",").map(str => str.trim()).join(",") // trim individual words and join with commas
    let data = {
        customer_id: this.state.customerId,
        selected_do_number: selected_do_number,
        period_of_delivery: this.state.period_of_delivery,
        drop_off_address: this.state.drop_off_address,
        drop_off_date: this.state.drop_off_date,
        drop_off_description: this.state.drop_off_description,
        drop_off_alternate_contact: drop_off_alternate_contact ? drop_off_alternate_contact : null,
        drop_off_time_planned: this.state.collection_from_time && this.state.collection_to_time ? this.state.collection_from_time + ' - ' + this.state.collection_to_time : null,
        driver_payout: this.state.driver_payout,
        driver_notes: this.state.driver_notes,
        drop_off_postal_code: this.state.drop_off_postal_code,
        is_project: this.state.job_type == "Project" ? true : this.state.job_type == "Standard" ? false : null,
        department_id: this.state.departmentId,
        drop_off_contact_no: drop_off_contact_no,
        drop_off_contact_name: this.state.drop_off_name,
        drop_off_name: this.state.company,
        notification_email: this.state.notification_email,
        xs_package:this.state.xs_package != '' ? Math.abs(this.state.xs_package) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        s_package:this.state.s_package != '' ?  Math.abs(this.state.s_package) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        m_package:this.state.m_package != '' ?  Math.abs(this.state.m_package) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        l_package:this.state.l_package != '' ?  Math.abs(this.state.l_package) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        oversized_package: this.state.oversized_package != '' ? Math.abs(this.state.oversized_package) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        // boxes: this.state.boxes,
        pallets: this.state.pallets != '' ? Math.abs(this.state.pallets) : this.state.xs_package != '' || this.state.s_package != '' || this.state.m_package || this.state.l_package != '' || this.state.oversized_package != '' || this.state.pallets != '' ? 0 : null,
        // document: this.state.document,
        // cartons: this.state.cartons,
        depalletization_request: this.state.depalletization_request,
        weight: this.state.weight,
        size_l_w_h: this.state.size_l_w_h,
        waiting_time: this.state.waiting_time,
        no_of_movers: this.state.no_of_movers ? Math.abs(this.state.no_of_movers) : null,
        invoice_amount: this.state.amount,
        drop_off_latitude: this.state.latitude,
        drop_off_longitude: this.state.longitude,
        vendor_payout: this.state.vendor_payout,
        drop_off_from_time: this.state.collection_from_time,
        drop_off_to_time: this.state.collection_to_time,
        unassign_driver: this.state.unAssignDriver
      }
      
      
      if(this.state.unAssignDriver) {
        data.drop_off_worker_id = null;
        data.vendor_id = null;
        data.order_status_id =  apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned')?.value;
      } else if(this.state.workerId != null && this.state.workerId != '' && !isNaN(this.state.workerId)) {
        data.drop_off_worker_id = this.state.workerId? this.state.workerId : null;
        data.vendor_id = null
        if (this.state.order_status_id != null && this.order_status_id != '' ) {
          data.order_status_id = this.state.order_status_id;
        }  
      } else if (this.state.vendorId != null && this.state.vendorId != '' && !isNaN(this.state.vendorId)) {
        data.assign_type = "vendor";
        data.vendor_id = this.state.vendorId? this.state.vendorId : null;
        if (this.state.order_status_id != null && this.order_status_id != '' ) {
          data.order_status_id = this.state.order_status_id;
        }  
      }
    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <Box clone pt={2} pb={2}>
            <Grid item xs={12}>
                {this.setForm()}
            </Grid>
        </Box>
        <Box clone>
            <Grid item xs={12}>
                {this.setButtons()}
            </Grid>
        </Box>
        </form>
    </Box>
  }
}

export default BulkUpdateForm;