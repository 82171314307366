import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#3CAFA4', '#FF994F', '#FF3E3E', '#5388D8'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabelStatus = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, status, total }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {status + '(' + (percent * 100).toFixed(2) + '%)'} 
    </text>
  );
};

const renderCustomizedLabelName = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, total }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {name + '(' + (percent * 100).toFixed(2) + '%)'} 
    </text>
  );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, total }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {(percent * 100).toFixed(2) + '%'} 
    </text>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, name, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  // const textAnchor = cos >= 0 ? 'start' : 'end';
  // Set a fixed x-coordinate for the text
  const textX = cx + outerRadius + 20;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        <strong>Total</strong> <br />{payload.status_total}
      </text>
      <text x={textX} y={cy} dy={20} textAnchor={'start'} fill="#000000"  className="px-1 bg-white fw-bold" style={{zIndex: 4000}}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
     <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text x={mx} y={ey} textAnchor={textAnchor} fill="#333"  className="px-1 bg-white" style={{zIndex: 4000}}>{`${name} ${value}`}</text> */}
      {/* <text x={mx} y={ey} dy={18} textAnchor={textAnchor} fill="#999"  className="px-1 bg-white" style={{zIndex: 4000}}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>  */}
    </g>
  );
};

const CustomTooltip = ({ active, payload, total, is_reason, is_size }) => {
  if (active && payload && payload.length) {
    const entry = payload[0].payload; // Assuming you have only one entry in the payload

    const value = entry.value ? entry.value : entry.total; // You need to get the total from your data
    const name = entry.name;
    const percent = (parseInt(value) / parseInt(total)) * 100;
    return (
      <div className="custom-tooltip">
        {is_reason && <p>{name}</p>}
        <p>{percent.toFixed(2) + '%'} {is_size || is_reason ? " ("+ value + ")" : ''} </p>
      </div>
    );
  }

  return null;
};

export default class CustomPieChart extends PureComponent {
  // data = [
  //   {
  //       "name": "Electric Vehicle",
  //       "value": 4
  //   },
  //   {
  //       "name": "Hybrid Vehicle",
  //       "value": 0
  //   },
  //   {
  //       "name": "ICE Vehicle",
  //       "value": 3
  //   }
  // ]
  constructor(props){
    super(props);
    let data = props.data? props.data : [];
    // let data = this.data;
    let dataKey = props.dataKey? props.dataKey : '';
    let key = props.key? props.key : '';
    let custom = props.custom? props.custom : false;
    let value1 = props.value1? props.value1 : '';
    let value2 = props.value2? props.value2 : '';
    let class_name = props.class_name? props.class_name : '';
    let status_total = props.status_total? props.status_total : '';
    let inner_radius = props.inner_radius? props.inner_radius : 0;
    let outer_radius = props.outer_radius? props.outer_radius : 80;
    let vertical_align = props.vertical_align? props.vertical_align : "top";
    let align = props.align? props.align : "right";
    let reason = props.reason? props.reason : false;
    let is_size = props.is_size? props.is_size : false;
    let lengend_className = props.lengend_className? props.lengend_className : '';
    let total = 0;
    data.forEach(dd => {
      if (dd.value) {
        total += parseInt(dd.value) || 0;
      } else {
        total += parseInt(dd.total) || 0;
      }
    });

    this.state = {
      data : data,
      dataKey : dataKey,
      custom: custom,
      total:total,
      lengend_className: lengend_className,
      key : key,
      mobileOpen : false,
      value1 : value1,
      is_size:is_size,
      status_total:status_total,
      outer_radius: outer_radius,
      inner_radius: inner_radius,
      align: align,
      reason: reason,
      vertical_align:vertical_align,
      class_name:class_name,
      value2 : value2,
    }
  }
   
  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };
  
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    if(this.props.showLable) {   
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + 10; // Adjust the label distance from the outer radius
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
      // Calculate the percentage
      const total = this.state.data.reduce((acc, entry) => typeof(entry.total) != 'undefined' ? acc + entry.total : acc + entry.value, 0);
      const percentage = typeof(this.state.data[index].total) != 'undefined' ? ((this.state.data[index].total / total) * 100).toFixed(2) : ((this.state.data[index].value / total) * 100).toFixed(2);
      
      if (typeof(this.state.data[index].total) != 'undefined' && this.state.data[index].total === 0) {
        return null;
      } else if (typeof(this.state.data[index].value) != 'undefined' && this.state.data[index].value === 0) {
        return null;
      }
      
      return (
        <text x={x} y={y} fill="black" style={{fontSize: 12,fontWeight: 'bold'}} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${percentage}%`}
        </text>
      );
    }
  };
  


  render() {
    return (
        <ResponsiveContainer>
          {this.state.status_total > 0 ?
            <PieChart width={'100%'} height={'100%'} className={this.state.class_name + " "}>
           {this.state.reason == false ? (this.state.custom == true ? <Legend         
              className = {`mt-3 ${this.state.lengend_className}`}
              payload={
                this.state.data.map(
                  (item, index) => ({
                    id: item.name,
                    type: "square",
                    value: <span dangerouslySetInnerHTML={{ __html: `${this.state.value1 != '' ? `<span class="fs-6 text-muted w-100">${item[this.state.value1]} ${this.state.value1 != '' ? `${item[this.state.value2]}` : ''}</span>` : ' ' }` }} /> ,
                    color:  item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                            item && item[this.state.value1].replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                            item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : COLORS[index % COLORS.length]
                            
                  })
                )
              }
              verticalAlign="bottom" 
              align="center" 
              iconType='square'
              /> :
              <Legend    
                className={this.state.lengend_className}
                layout={this.state.mobileOpen? "" :"horizontal"}
                payload={
                  this.state.data.map(
                    (item, index) => ({
                      id: item.name,
                      type: "square",
                      value: this.state.reason ? <span dangerouslySetInnerHTML={{ __html: `${this.state.value1 != '' ? `<span class="fs-6 text-muted w-100">${item[this.state.value1]} ${Number(item[this.state.value2]/this.state.status_total) * 100 == 100 ? (Number(item[this.state.value2]/this.state.status_total) * 100).toFixed(0) : (Number(item[this.state.value2]/this.state.status_total) * 100).toFixed(2) }%</span>` : ' ' }` }} /> : item[this.state.value1],
                      color: this.state.reason ?  COLORS[index % COLORS.length] : item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                      item && item[this.state.value1].replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                      item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : COLORS[index % COLORS.length]
                    })
                  )}
                verticalAlign={this.state.mobileOpen? 'top' : this.state.vertical_align} 
                align={this.state.mobileOpen? 'center' : this.state.align}
                iconType='square'
              />) : <Legend  
              wrapperStyle={{bottom: this.state.data.length > 4 ? 0 : 10}}  
              className={this.state.lengend_className}
              layout={"horizontal"}
              payload={
                this.state.data.map(
                  (item, index) => ({
                    id: item.name,
                    type: "square",
                    value: this.state.reason ? <span dangerouslySetInnerHTML={{ __html: `${this.state.value1 != '' ? `<span class="fs-6 text-muted w-100">${item[this.state.value1]} ${Number(item[this.state.value2]/this.state.status_total) * 100 == 100 ? (Number(item[this.state.value2]/this.state.status_total) * 100).toFixed(0) : (Number(item[this.state.value2]/this.state.status_total) * 100).toFixed(2) }%</span>` : ' ' }` }} /> : item[this.state.value1],
                    color: this.state.reason ?  COLORS[index % COLORS.length] : item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                    item && item[this.state.value1].replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                    item && item[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : COLORS[index % COLORS.length]
                  })
                )}
              verticalAlign={'bottom'} 
              align={this.state.mobileOpen? 'center' : this.state.align}
              iconType='square'
            />}
            <Pie
                activeIndex={this.state.activeIndex}
                data={this.state.data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={this.state.outer_radius}
                innerRadius={this.state.inner_radius}
                fill="#8884d8"
                dataKey={this.state.dataKey}
                nameKey={this.state.value1}
                onMouseEnter={this.onPieEnter}
                label={this.renderCustomizedLabel}
            >
                {this.state.data.map((entry, index) => (
                <Cell 
                  key={entry[this.state.key]} 
                  fill={  entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor" ? '#F9CD4D ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                          entry[this.state.value1] && entry[this.state.value1].replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : COLORS[index % COLORS.length]} 
                />
                ))}
                  {/* {this.state.reason ? <Label width={30} position="center">
                  { `Total ${this.state.status_total}` }
                </Label> : null} */}
              </Pie>
              <Tooltip content={<CustomTooltip total={this.state.status_total ? this.state.status_total : this.state.total} is_reason={this.state.reason} is_size={this.state.is_size}/>} />
            </PieChart>
            :
            <div className="d-flex justify-content-center align-items-center">There is no status for date range !!</div>}
        </ResponsiveContainer>
        // </div>
    );
  }
}
