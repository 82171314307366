import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import {RoleAccessService} from '../../data/role-access'; 

import { 
    Box,
    Grid,
    IconButton,
    Container,
    Divider,
    Select,
    CircularProgress,
    Typography,
  } from '@material-ui/core';

  
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import HtmlParser from 'react-html-parser';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import Settingimg from '../../assets/img/icons/setting-balck.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';

import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import 'suneditor/dist/css/suneditor.min.css';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';

const data = [
    { 
        job_type:'standard',
        do_number:'do-234234',
        driver:'Jet Jet',
        date:'18-01-2022',
        status: 'Assigned',
    },
    { 
        job_type:'standard',
        do_number:'do-234234',
        driver:'Jet Jet',
        date:'18-01-2022',
        status: 'Error',
    }]

class AutoAssignPreview extends Component {
  static contextType = AuthContext;
  
  constructor(props){
    super(props);

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;
    this.isCustomerRequired = false;
    let grantedAccess = new RoleAccessService();
    this.state = {
      orders_data: this.props.location.orders_data
    }
  }

  componentDidMount() {
    
}
  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        this.props.history.push('/dashboards/allocation');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={isOnBoarding ? 'auto' : true}>
            <CustomLabel
                className={'no-margin'}
                label={'Auto Assign Preview'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
       
            {/* <CustomButton 
              className={'create-job-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit job</Box>
            </CustomButton> */}
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

    getPackageType = (id) => {
      let package_id = parseInt(id)
      switch (package_id) {
        case 1:
          return 'XS';
          break;
        case 2:
          return 'S';
          break;
        case 3:
          return 'M';
          break;
        case 4:
          return 'L';
          break;
        case 5:
          return 'Pallet';
          break;
        default:
          return package_id;
          break;
      }

    }
      customTable = () => {
        if(!this.state.isLoading) {
    
          return <Table 
          ref={this.refTable}
          className={'job-table-list' }  
          isDraggable={false}
          isLoading={this.state.isLoading}
          grouping={false}
          limit={this.limits}
          page={this.pages}
          total={this.state.total}
          data={this.state.orders_data.assigned_orders}
          defaultExpanded={true}
        //   selection={true}
          onPageChange={(page,limit) => {
              
          }}
          onSelectionChange={(rows, column)=>{
            this.rowData = rows;
          }}
        //   Toolbar={this.customToolbar}
          // Row={this.customRow}
          columns={[
            { 
              title: "Tracker Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150,
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <span style={{ width:145, overflowWrap: 'break-word', display:'inline-block'}}> {row.order_number? row.order_number : ''} </span>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Type", 
              field: "job_type",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.job_type? row.job_type : '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Package Type", 
            //   field: "package_type_id",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       if(row.order_packages) {
            //         row.order_packages.map((dd) => {
            //           if(dd.package_id == 1) {
            //             return dd.package_quantity + ' XS' 
            //           }
            //           if(dd.package_id == 2) {
            //             return dd.package_quantity + ' S' 
            //           }
            //           if(dd.package_id == 3) {
            //             return dd.package_quantity + ' M' 
            //           }
            //           if(dd.package_id == 4) {
            //             return dd.package_quantity + ' L' 
            //           }
            //         })
            //       }
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "XS Packages", 
              field: "xs_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "S Packages", 
              field: "s_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "M Packages", 
              field: "m_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "L Packages", 
              field: "l_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Pallets", 
              field: "pallets",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Oversized Package", 
            //   field: "oversized_packages",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            //       return (pack.length > 0) ? pack[0].package_quantity: '';
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "Do Number", 
              field: "do_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.do_number? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span>:'';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Date", 
              field: "drop_off_date",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.drop_off_date? row.drop_off_date.split(" ")[0] : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Order Status", 
              field: "job_status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
              render: (row, type) => {
                if(type === 'row'){
                  return row.order_status? row.order_status.status : '';
                } else {
                  return row;
                }
              }
            },
              { 
                title: "Assign To", 
                field: "dorp_off_worker",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_worker? `${(row.drop_off_worker.first_name)? row.drop_off_worker.first_name :''} ${(row.drop_off_worker.last_name)? row.drop_off_worker.last_name :''}` : '';
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
    
        }else {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        }
    
        
      }
      customNoZoneTable = () => {
        if(!this.state.isLoading) {
    
          return <Table 
          ref={this.refTable}
          className={'job-table-list' }  
          isLoading={this.state.isLoading}
          isDraggable={false}
          grouping={false}
          limit={this.limits}
          page={this.pages}
          total={this.state.total}
          data={this.state.orders_data.unassigned_orders_no_zone}
          defaultExpanded={true}
        //   selection={true}
          onPageChange={(page,limit) => {
          }}
          onSelectionChange={(rows, column)=>{
            // console.log("columns", column);
            this.rowData = rows;
          }}
        //   Toolbar={this.customToolbar}
          // Row={this.customRow}
          columns={[
            { 
              title: "Tracker Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150,
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <span style={{ width:145, overflowWrap: 'break-word', display:'inline-block'}}> {row.order_number? row.order_number : ''} </span>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Type", 
              field: "job_type",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.job_type? row.job_type : '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Package Type", 
            //   field: "order_packages",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       if(row.order_packages) {
            //         return row.order_packages.map((dd)=> dd.package_quantity + ' ' + this.getPackageType(dd.package_id)) + ", ";
            //       }
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "XS Packages", 
              field: "xs_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "S Packages", 
              field: "s_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "M Packages", 
              field: "m_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "L Packages", 
              field: "l_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Pallets", 
              field: "pallets",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Oversized Package", 
            //   field: "oversized_packages",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            //       return (pack.length > 0) ? pack[0].package_quantity: '';
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "Do Number", 
              field: "do_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.do_number? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span>:'';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Date", 
              field: "drop_off_date",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.drop_off_date? row.drop_off_date.split(" ")[0] : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Order Status", 
              field: "job_status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
              render: (row, type) => {
                if(type === 'row'){
                  return row.order_status? row.order_status.status : '';
                } else {
                  return row;
                }
              }
            },
              { 
                title: "Assign To", 
                field: "do_number",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_worker? `${(row.drop_off_worker.first_name)? row.drop_off_worker.first_name :''} ${(row.drop_off_worker.last_name)? row.drop_off_worker.last_name :''}` : '';
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
    
        }else {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        }
    
        
      }
      customNoCapacityTable = () => {
        if(!this.state.isLoading) {
    
          return <Table 
          ref={this.refTable}
          className={'job-table-list' }  
          isDraggable={false}
          isLoading={this.state.isLoading}
          grouping={false}
          limit={this.limits}
          page={this.pages}
          total={this.state.total}
          data={this.state.orders_data.unassigned_orders_no_capacity}
          defaultExpanded={true}
        //   selection={true}
          onPageChange={(page,limit) => {
          }}
          onSelectionChange={(rows, column)=>{
            // console.log("columns", column);
            this.rowData = rows;
          }}
        //   Toolbar={this.customToolbar}
          // Row={this.customRow}
          columns={[
            { 
              title: "Tracker Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150,
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <span style={{ width:145, overflowWrap: 'break-word', display:'inline-block'}}> {row.order_number? row.order_number : ''} </span>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Type", 
              field: "job_type",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.job_type? row.job_type : '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Package Type", 
            //   field: "package_type_id",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       if(row.order_packages) {
            //         row.order_packages.map((dd) => {
            //           if(dd.package_id == 1) {
            //             return dd.package_quantity + ' XS' 
            //           }
            //           if(dd.package_id == 2) {
            //             return dd.package_quantity + ' S' 
            //           }
            //           if(dd.package_id == 3) {
            //             return dd.package_quantity + ' M' 
            //           }
            //           if(dd.package_id == 4) {
            //             return dd.package_quantity + ' L' 
            //           }
            //         })
            //       }
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "Do Number", 
              field: "do_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.do_number? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span>:'';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Date", 
              field: "drop_off_date",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.drop_off_date? row.drop_off_date.split(" ")[0] : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Order Status", 
              field: "job_status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
              render: (row, type) => {
                if(type === 'row'){
                  return row.order_status? row.order_status.status : '';
                } else {
                  return row;
                }
              }
            },
              { 
                title: "Assign To", 
                field: "do_number",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_worker? `${(row.drop_off_worker.first_name)? row.drop_off_worker.first_name :''} ${(row.drop_off_worker.last_name)? row.drop_off_worker.last_name :''}` : '';
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
    
        }else {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        }
    
        
      }
      
      customInvalidTable = () => {
        if(!this.state.isLoading) {
    
          return <Table 
          ref={this.refTable}
          className={'job-table-list' }  
          isDraggable={false}
          isLoading={this.state.isLoading}
          grouping={false}
          limit={this.limits}
          page={this.pages}
          total={this.state.total}
          data={this.state.orders_data.invalid_orders}
          defaultExpanded={true}
        //   selection={true}
          onPageChange={(page,limit) => {
          }}
          onSelectionChange={(rows, column)=>{
            // console.log("columns", column);
            this.rowData = rows;
          }}
          columns={[
            { 
              title: "Tracker Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150,
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <span style={{ width:145, overflowWrap: 'break-word', display:'inline-block'}}> {row.order_number? row.order_number : ''} </span>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Type", 
              field: "job_type",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.job_type? row.job_type : '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Package Type", 
            //   field: "package_type_id",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       if(row.order_packages) {
            //         row.order_packages.map((dd) => {
            //           if(dd.package_id == 1) {
            //             return dd.package_quantity + ' XS' 
            //           }
            //           if(dd.package_id == 2) {
            //             return dd.package_quantity + ' S' 
            //           }
            //           if(dd.package_id == 3) {
            //             return dd.package_quantity + ' M' 
            //           }
            //           if(dd.package_id == 4) {
            //             return dd.package_quantity + ' L' 
            //           }
            //         })
            //       }
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "XS Packages", 
              field: "xs_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "S Packages", 
              field: "s_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "M Packages", 
              field: "m_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "L Packages", 
              field: "l_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Oversized Package", 
            //   field: "oversized_packages",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            //       return (pack.length > 0) ? pack[0].package_quantity: '';
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "Pallets", 
              field: "pallets",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Do Number", 
              field: "do_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.do_number? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span>:'';
                } else {
                  return row;
                }
              }
            },
            { 
                title: "Date", 
                field: "drop_off_date",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_date? row.drop_off_date.split(" ")[0] : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Order Status", 
                field: "job_status",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.order_status? row.order_status.status : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Assign To", 
                field: "do_number",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
              //   hidden: this.state.hiddenColumns.includes('pallets'),
                customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_worker? `${(row.drop_off_worker.first_name)? row.drop_off_worker.first_name :''} ${(row.drop_off_worker.last_name)? row.drop_off_worker.last_name :''}` : '';
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
    
        }else {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        }
    
        
      }
      customNoLatLngTable = () => {
        if(!this.state.isLoading) {
    
          return <Table 
          ref={this.refTable}
          className={'job-table-list' }  
          isDraggable={false}
          isLoading={this.state.isLoading}
          grouping={false}
          limit={this.limits}
          page={this.pages}
          total={this.state.total}
          data={this.state.orders_data.no_latlng_orders}
          defaultExpanded={true}
        //   selection={true}
          onPageChange={(page,limit) => {
          }}
          onSelectionChange={(rows, column)=>{
            // console.log("columns", column);
            this.rowData = rows;
          }}
          columns={[
            { 
              title: "Tracker Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150,
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <span style={{ width:145, overflowWrap: 'break-word', display:'inline-block'}}> {row.order_number? row.order_number : ''} </span>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Type", 
              field: "job_type",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.job_type? row.job_type : '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Package Type", 
            //   field: "package_type_id",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       if(row.order_packages) {
            //         row.order_packages.map((dd) => {
            //           if(dd.package_id == 1) {
            //             return dd.package_quantity + ' XS' 
            //           }
            //           if(dd.package_id == 2) {
            //             return dd.package_quantity + ' S' 
            //           }
            //           if(dd.package_id == 3) {
            //             return dd.package_quantity + ' M' 
            //           }
            //           if(dd.package_id == 4) {
            //             return dd.package_quantity + ' L' 
            //           }
            //         })
            //       }
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "XS Packages", 
              field: "xs_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "S Packages", 
              field: "s_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "M Packages", 
              field: "m_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "L Packages", 
              field: "l_packages",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Pallets", 
              field: "pallets",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
              render: (row, type) => {
                if(type === 'row'){
                  let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
                  return (pack.length > 0) ? pack[0].package_quantity: '';
                } else {
                  return row;
                }
              }
            },
            // { 
            //   title: "Oversized Package", 
            //   field: "oversized_packages",
            //   cellStyle: {
            //     minWidth: 150,
            //     maxWidth: 150
            //   },
            //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
            //   render: (row, type) => {
            //     if(type === 'row'){
            //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
            //       return (pack.length > 0) ? pack[0].package_quantity: '';
            //     } else {
            //       return row;
            //     }
            //   }
            // },
            { 
              title: "Do Number", 
              field: "do_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.do_number? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span>:'';
                } else {
                  return row;
                }
              }
            },
            { 
                title: "Date", 
                field: "drop_off_date",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_date? row.drop_off_date.split(" ")[0] : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Order Status", 
                field: "job_status",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
                customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.order_status? row.order_status.status : '';
                  } else {
                    return row;
                  }
                }
              },
              { 
                title: "Assign To", 
                field: "do_number",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150
                },
              //   hidden: this.state.hiddenColumns.includes('pallets'),
                customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
                render: (row, type) => {
                  if(type === 'row'){
                    return row.drop_off_worker? `${(row.drop_off_worker.first_name)? row.drop_off_worker.first_name :''} ${(row.drop_off_worker.last_name)? row.drop_off_worker.last_name :''}` : '';
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
    
        }else {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
            <CircularProgress size={35} />
          </Box>
        }
    
        
      }

  render() {
    return <Box className="job-form-page" >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <StickyPageHeader isSmall={true}>
                {this.customStickyPageHeader()}
            </StickyPageHeader>
            
            <Container maxWidth="lg">
              {this.state.orders_data && this.state.orders_data.assigned_orders.length > 0 && 
                <Box mt={3}>
                  <Typography>Total number of assigned orders : {this.state.orders_data.assigned_orders.length}</Typography>
                  {this.customTable()}
                </Box>
              }
              {this.state.orders_data && this.state.orders_data.unassigned_orders_no_zone.length > 0 && 
                <Box mt={3}>
                  <Typography>Total number of out of zone orders : {this.state.orders_data.unassigned_orders_no_zone.length}</Typography>
                  {this.customNoZoneTable()}
                </Box>
              }
              {this.state.orders_data && this.state.orders_data.unassigned_orders_no_capacity.length > 0 && 
                <Box mt={3}>
                  <Typography>Total number of out of capacity orders : {this.state.orders_data.unassigned_orders_no_capacity.length}</Typography>
                  {this.customNoCapacityTable()}
                </Box>
              }
              {this.state.orders_data && this.state.orders_data.no_latlng_orders.length > 0 && 
                <Box mt={3}>
                  <Typography>Total number of not lat and lang orders : {this.state.orders_data.no_latlng_orders.length}</Typography>
                  {this.customNoLatLngTable()}
                </Box>
              }
              {this.state.orders_data && this.state.orders_data.invalid_orders.length > 0 && 
                <Box mt={3}>
                  <Typography>Total number of invalid status orders : {this.state.orders_data.invalid_orders.length}</Typography>
                  {this.customInvalidTable()}
                </Box>
              }
            </Container>
            
        </form>
    </Box>
  }
}

export default AutoAssignPreview;