import React, { Component } from 'react';
import { retry } from "rxjs-compat/operator/retry";
import ldsApi from "../api/ldsApi";
import apiUtil from "../api/apiUtil.jsx";


export class RoleAccessService {
  
    constructor() { }
    
    getCustomerTemplateRoleMenuList(){
      return this.customer_template_role_menu_list;
    }
    getCustomerTemplateRoleJobAccessList(){
      return this.customer_template_role_menu_list;
    }
    
    //  Get Uqique role permission  list
    getUniqueRolePermissionList(type) {
      let uniqueRolePermissionsRaw = window.localStorage.getItem('uniqueRolePermissions');
      uniqueRolePermissionsRaw = JSON.parse(uniqueRolePermissionsRaw)
      // [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]      
      let uniqueRolePermissionList = uniqueRolePermissionsRaw ? [...new Set(uniqueRolePermissionsRaw.map(item => item[type]))] : [];
      return uniqueRolePermissionList;
    }


    //Action of permission for New role 
    getUniqueRolePermissionAction() {
      let uniqueRolePermissionsAction = this.getUniqueRolePermissionList('name')
      return uniqueRolePermissionsAction;
    }
    
    //
    isPermissionActionAllowed(role, action) {
      const permissionAction = this.getUniqueRolePermissionAction();
      if(!this.admin_roles.includes(role) && permissionAction.includes(action)) {
        return true;
      } else if (this.admin_roles.includes(role)) {
        return true;
      }
      return false;
    }

    // Permissions enchancement for new role
    getUniqueRolePermission() {
            
      let uniqueRolePermissions = this.getUniqueRolePermissionList('group');
      
      if(uniqueRolePermissions) {
        uniqueRolePermissions = uniqueRolePermissions.map(x=> {          
          switch (x) {
            case 'Dashboard':
              return 'dashboard';
              break;
            case 'Application Settings':
                return 'application_settings';
              break;
            case 'Jobs':
              return 'jobs';
              break;
            case 'Customers':
              return 'customers';
              break;
            case 'Reports':
              // return 'report_transport_operation';
              return 'report';
              break;
            case 'Vendors':
              return 'vendor';
              break;
            case 'Vehicles':
              return 'resources_vehicle';
              break;
            case 'Zone':
              return 'zones';
              break;
            case 'Driver & Manpower':
              return 'transport_operator';
              break;
            case 'Client Driver Invoice':
              return 'invoice';
              break;
            default:
              return x;
          }
        });

      }     

      uniqueRolePermissions.push("user_profile");
      uniqueRolePermissions.push("password");
      uniqueRolePermissions.push("settings");
      uniqueRolePermissions.push("on_boarding");
      uniqueRolePermissions.push("live_tracking");
      uniqueRolePermissions.push("job_schedule");
      uniqueRolePermissions.push("job_schedule_list");

      
      if( uniqueRolePermissions.includes('customers') && this.getUniqueRolePermissionAction().includes('Customer Add')) {
        uniqueRolePermissions.push("customer_form");
      }
      
      if( uniqueRolePermissions.includes('jobs') && this.getUniqueRolePermissionAction().includes('Job Add')) {
        uniqueRolePermissions.push("job_form");
        uniqueRolePermissions.push("vendor_list");
      }

      if( uniqueRolePermissions.includes('transport_operator')) {
        uniqueRolePermissions.push('resources_logistic_assistance');
        if(this.getUniqueRolePermissionAction().includes('Driver & Manpower Add')) {
          uniqueRolePermissions.push('resources_logistic_assistance_form');
        }
        
      }

      // 'Transport operator', 'Logistic assistance', 'Vehicle'
      if(uniqueRolePermissions.includes('transport_operator') || uniqueRolePermissions.includes('resources_vehicle')) {
        uniqueRolePermissions.push('Resources');

        if(uniqueRolePermissions.includes('transport_operator') && this.getUniqueRolePermissionAction().includes('Driver & Manpower Add')) {
          uniqueRolePermissions.push('transport_operator_form');
        }
        if(uniqueRolePermissions.includes('resources_vehicle') && this.getUniqueRolePermissionAction().includes('Create Vehicle')) {
          uniqueRolePermissions.push('resources_vehicle_form');
        }       
      }

      // console.log("unique role", uniqueRolePermissions);
      return uniqueRolePermissions;

      
    }

    customer_template_role_menu_list = ["jobs", "user_profile", "job_form","job_schedule","job_schedule_list", "password" ];
    customer_template_role_job_denied_list = ["job_status", "job_assign", "dashboard", "job_date", "job_time", "step_status", "step_address", "add_more_job_step", "password"  ];
    admin_roles = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role' , "password" ];    
    admin_roles_with_customer = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role','Customer Template Role', "password" ];    
    
    isShouldShowMenu(currentRole, menu_name) { 
      const uniqueRolePermissions = this.getUniqueRolePermission();
      // if(currentRole === 'Customer Template Role' && this.customer_template_role_menu_list.includes(menu_name)) {
        
      //   return true;
      // }

      if(currentRole !== 'Customer Template Role' && uniqueRolePermissions.includes(menu_name)) {
        
        return true;
      }
      // if(!this.admin_roles_with_customer.includes(currentRole) && uniqueRolePermissions.includes(menu_name)) {
      //   return true;
      // // } else if(currentRole !== 'Customer Template Role') {
      // } 
      else if(this.admin_roles_with_customer.includes(currentRole)) {
        return true;
      }
  
      return false;
    }  

    isShouldShowJobAccess (currentRole, section_name) {
      const uniqueRolePermissions = this.getUniqueRolePermission();
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      // if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
      //   return false;
      // } else if(currentRole !== 'Customer Template Role') {
      //   return true;
      // }
      return true;
      // if(currentRole === 'Customer Template Role' && uniqueRolePermissions.includes(section_name)) {
      //   return true;
      // } else if(currentRole !== 'Customer Template Role') {
      //   return true;
      // }
      // return true;
    }
    isShouldReadyOnlyAccess (currentRole, section_name) {
      const uniqueRolePermissions = this.getUniqueRolePermission();
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      // if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
      //   return true;
      // } else if(currentRole !== 'Customer Template Role') {
      //   return false;
      // }
      // return true;
      // if(currentRole === 'Customer Template Role' && uniqueRolePermissions.includes(section_name)) {
      //   return false;
      // } else if(currentRole !== 'Customer Template Role') {
      //   return false;
      // }
      return true;
    }

    isShouldShowEditButton (currentRole, section_name, worker_id) {
      const uniqueRolePermissions = this.getUniqueRolePermission();
      if(currentRole === 'Customer Template Role' && uniqueRolePermissions.includes(section_name)) {
        return true;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
        return true;
    }

    // driver_payout_rate = { xs_next_day: 3.85, xs_same_day:3.85, xs_next_day_3h:5.78, xs_express_2h: 6.93, s_next_day:7.00, s_same_day:7.00, s_next_day_3h:10.50, s_express_2h: 12.60, m_next_day:12.60, m_same_day:12.60, m_next_day_3h:18.90, m_express_2h: 22.68, l_next_day:16.80, l_same_day:16.80, l_next_day_3h:25.20, l_express_2h: 30.24}
    driver_payout_rate = {}
    customer_price_rate = {}
    subcon_price_rate = {}

    // Function to convert and handle empty strings
    toFloatOrZero = (value) => (value ? parseFloat(value) : 0);

      /* GEO FUNCTIONS API */
    callGeoCoderAddressApi = (address = "", callback = null) => {
      const accessToken = window.localStorage.getItem('access_token') || null;
          let params = {
            address: address,
          };

          ldsApi
            .create("geo_functions/geocoder_address", params, accessToken)
            .then((result) => {
              apiUtil.parseResult(
                result,
                (data) => {
                  if (data && data.result) {
                    if (callback) {
                      callback(data.result);
                    }
                  } else {
                    if (callback) {
                      callback(null);
                    }
                  }
                },
                (error, type) => {
                  if (callback) {
                    callback(null);
                  }
                }
              );
            });
    };

    getPriceAndDriverPayout (selected_package, customer_rate, delivery_type, period, date, postal,job_type, drop_off_address, addresses, uploader_id = null,skip_disable_collection_calculate=false, lat = null, lng = null, additional_manpower = null, zip_from_address = null,used_call_geo=true) {
      let check_address = [];
      let user = JSON.parse(window.localStorage.getItem('user'))
      let currentRole = window.localStorage.getItem('current_role')
      let address_zip = ''
      console.log('used_call_geo',used_call_geo);
      if(used_call_geo === true) {
        this.callGeoCoderAddressApi(drop_off_address, (data) => {
          // console.log('data', data)
          if(data){
            address_zip = data.zip
          } 
        });
      }

      if (currentRole == "Super Admin Role") {
        check_address = addresses ? addresses.filter(dd => (dd.address?.trim()?.toLowerCase() == drop_off_address?.trim()?.toLowerCase() || ((dd.address +  ', ' + dd.building_no)?.trim()?.toLowerCase() == drop_off_address?.trim()?.toLowerCase()) || (postal == dd.postal_code || zip_from_address == dd.postal_code || address_zip == dd.postal_code) || (lat == dd.latitude && lng == dd.longitude)) && (uploader_id == dd.customer_id || dd.customer_id == null)) : null
      } else {
        check_address = addresses ? addresses.filter(dd => (dd.address?.trim()?.toLowerCase() == drop_off_address?.trim()?.toLowerCase() || ((dd.address +  ', ' + dd.building_no)?.trim()?.toLowerCase() == drop_off_address?.trim()?.toLowerCase()) || (postal == dd.postal_code || zip_from_address == dd.postal_code || address_zip == dd.postal_code) || (lat == dd.latitude && lng == dd.longitude)) && dd.customer_id == null) : null

      }
      console.log("-----------------------------------------------------------")
      // console.log(
      //   "check selected_package", selected_package,
      //   "check customer_rate",  customer_rate,
      //   "check delivery_type",  delivery_type,
      //   "check period",  period,
      //   "check date",  date,
      //   "check postal",  postal,
      //   "check job_type,", job_type, 
      //   "check drop_off_address,", drop_off_address, 
      //   "check addresses,", addresses, 
      //   "check uploader_id", uploader_id,
      //   "check skip_disable_collection_calculate,", skip_disable_collection_calculate, 
      //   "check lat,", lat, 
      //   "check lng,", lng, 
      //   "check additional_manpower,", additional_manpower, 
      //   "check zip_from_address", zip_from_address)

      // console.log("check_address", check_address)
      console.log("-----------------------------------------------------------")
      let client = 0
      let other_surcharge = {}
      let payout = 0
      let subcon = 0
      let normal_charge = {}
      let base_charge = {}
      let surcharge = {}
      // let driver_srucharge = 0

      if (delivery_type == true || delivery_type == 'true') {
        delivery_type = "Project"
      } else if (delivery_type == false || delivery_type == 'false') {
        delivery_type = "Standard"
      }
      // console.log("customer_rate", customer_rate)
      this.driver_payout_rate = {}
      this.customer_price_rate = {}
      this.subcon_price_rate = {}
      customer_rate = customer_rate.filter(cr => cr.rate_type == 'driver' || cr.rate_type == 'customer' || cr.rate_type == 'subcon' )
      let disable_collection_calculate = false;
      let service_surcharge = '';
      if (customer_rate.length > 1) {
        customer_rate.forEach(data => {
          if(data.rate_type == 'driver') {
            this.driver_payout_rate = data
          } else if (data.rate_type == 'customer') {
            service_surcharge = JSON.parse(data.service_surcharge);
            disable_collection_calculate = service_surcharge.disable_client_payout && job_type == 'Collection' ? service_surcharge.disable_client_payout : false;
            this.customer_price_rate = data
          } else if (data.rate_type == 'subcon') {
            this.subcon_price_rate = data
          }
        })
        if(selected_package && selected_package.length > 0 ) {
          selected_package = selected_package.filter(pt => pt.delete != true && pt.package_quantity > 0)
          if (delivery_type == 'Project') {
            selected_package = selected_package.filter(pt => pt.name != "Oversized Package" && pt.name != "Pallets" )
          } else {
            selected_package = selected_package.filter(pt => pt.name != "Oversized Package" )
          }

          if (selected_package.length > 0 && ((delivery_type != 'Project' && JSON.parse(this.customer_price_rate.standard_rate).is_location == true) || (delivery_type == 'Project' &&  JSON.parse(this.customer_price_rate.project_rate).is_location == true))) {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "per_location", period)
            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } else if (selected_package.filter(pt => pt.name == "Pallets").length > 0 && delivery_type != 'Project') {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "Pallets", period)
            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } else if (selected_package.filter(pt => pt.name == "L").length > 0) {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "L", period)
            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } else if (selected_package.filter(pt => pt.name == "M").length > 0) {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "M", period)
            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } else if (selected_package.filter(pt => pt.name == "S").length > 0) {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "S", period)

            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } else if (selected_package.filter(pt => pt.name == "XS").length > 0) {
            normal_charge = this.totalTalPriceCalculation(selected_package, delivery_type, "XS", period)

            client = normal_charge.price
            payout = normal_charge.driver
            subcon = normal_charge.subcon
            base_charge = normal_charge.base_charge
            surcharge = normal_charge.surcharge
          } 
          
        }

        other_surcharge = this.otherSurchargeCalculation(date, postal, delivery_type, additional_manpower)
      }

      // console.log('surcharge', surcharge, 'other_surcharge', other_surcharge)
      // Convert strings to floats, handle empty strings as 0, and add corresponding values
      const combinedSurcharge = {
        client_surcharge: this.toFloatOrZero(surcharge.client_surcharge) + this.toFloatOrZero(other_surcharge.charge),
        driver_surcharge: this.toFloatOrZero(surcharge.driver_surcharge) + this.toFloatOrZero(other_surcharge.driver),
        subcon_surcharge: this.toFloatOrZero(surcharge.subcon_surcharge) + this.toFloatOrZero(other_surcharge.subcon),
      };

      surcharge = combinedSurcharge
      // console.log('surcharge', surcharge, 'combinedSurcharge', combinedSurcharge, "")
      // driver_srucharge = this.driverSurchargeCalculation( date, postal )
      // client = client > 0 ? client + (isNaN(other_surcharge.charge) && selected_package.length > 0 ? 0 : other_surcharge.charge) : client
      // console.log('subcon', client, payout, subcon)
      // console.log('disable_collection_calculate',disable_collection_calculate,'skip_disable_collection_calculate',skip_disable_collection_calculate);
      client = check_address.length > 0 ? 0 : (disable_collection_calculate && skip_disable_collection_calculate == false ? 0 : client > 0 ? client + (isNaN(other_surcharge.charge) ? 0 : other_surcharge.charge) : client)
      client = client.toFixed(2)
      payout = check_address.length > 0 ? 0 : (disable_collection_calculate && skip_disable_collection_calculate == false ? 0 : payout > 0 ? payout + (isNaN(other_surcharge.driver) ? 0 : other_surcharge.driver) : payout)
      payout = payout.toFixed(2)
      subcon = check_address.length > 0 ? 0 : (subcon > 0 ? subcon + (isNaN(other_surcharge.subcon) ? 0 : other_surcharge.subcon) : subcon)
      subcon = subcon.toFixed(2)

      return {client, payout, subcon, base_charge, surcharge}

  }

  otherSurchargeCalculation( date, postal, d_type, additional_manpower) {
    let charge = 0
    let driver = 0
    let subcon = 0
    let two_digit = postal ? parseInt(postal.substring(0,2)): 0
    let drop_date = date ? new Date(date) : new Date()
    let location_rate = {}
    let day_surcharge = {}
    let driver_loaction_rate = {}
    let driver_day_surcharge = {}
    let subcon_loaction_rate = {}
    let subcon_day_surcharge = {}

    if (d_type == 'Project') {
        location_rate = this.customer_price_rate.project_location_surcharge ? JSON.parse(this.customer_price_rate.project_location_surcharge) : {}
        day_surcharge = this.customer_price_rate.project_day_surcharge ? JSON.parse(this.customer_price_rate.project_day_surcharge) : {}
        driver_loaction_rate = this.driver_payout_rate.project_location_surcharge ? JSON.parse(this.driver_payout_rate.project_location_surcharge) : {}
        driver_day_surcharge = this.driver_payout_rate.project_day_surcharge ? JSON.parse(this.driver_payout_rate.project_day_surcharge) : {}
        subcon_loaction_rate = this.subcon_price_rate.project_location_surcharge ? JSON.parse(this.subcon_price_rate.project_location_surcharge) : {}
        subcon_day_surcharge = this.subcon_price_rate.project_day_surcharge ? JSON.parse(this.subcon_price_rate.project_day_surcharge) : {}
    } else {
        location_rate = this.customer_price_rate.location_surcharge ? JSON.parse(this.customer_price_rate.location_surcharge) : {}
        day_surcharge = this.customer_price_rate.day_surcharge ? JSON.parse(this.customer_price_rate.day_surcharge) : {}
        driver_loaction_rate = this.driver_payout_rate.location_surcharge ? JSON.parse(this.driver_payout_rate.location_surcharge) : {}
        driver_day_surcharge = this.driver_payout_rate.day_surcharge ? JSON.parse(this.driver_payout_rate.day_surcharge) : {}
        subcon_loaction_rate = this.subcon_price_rate.location_surcharge ? JSON.parse(this.subcon_price_rate.location_surcharge) : {}
        subcon_day_surcharge = this.subcon_price_rate.day_surcharge ? JSON.parse(this.subcon_price_rate.day_surcharge) : {}
    }
    if ((two_digit >= 1 && two_digit <= 8) || (two_digit >= 17 && two_digit <= 19) || two_digit == 22 || two_digit == 23 ) {
      charge += location_rate['cbd'] ?  parseFloat(location_rate['cbd']) : 0
      driver += driver_loaction_rate['cbd'] ?  parseFloat(driver_loaction_rate['cbd']) : 0
      subcon += subcon_loaction_rate['cbd'] ?  parseFloat(subcon_loaction_rate['cbd']) : 0
    } else if (two_digit == 9 || two_digit == 81) {
      charge += location_rate['sentosa_changi_airport'] ? parseFloat(location_rate['sentosa_changi_airport']) : 0
      driver += driver_loaction_rate['sentosa_changi_airport'] ? parseFloat(driver_loaction_rate['sentosa_changi_airport']) : 0
      subcon += subcon_loaction_rate['sentosa_changi_airport'] ? parseFloat(subcon_loaction_rate['sentosa_changi_airport']) : 0
    } else {
      let three_digit = postal?  parseInt(postal.substring(0,3)): 0
      if (three_digit == 627 || three_digit == 628 || three_digit == 629 || three_digit == 636 || three_digit == 637 || three_digit == 638 || three_digit == 639) {
        charge += location_rate['tuas'] ?  parseFloat(location_rate['tuas']) : 0
        driver += driver_loaction_rate['tuas'] ?  parseFloat(driver_loaction_rate['tuas']) : 0
        subcon += subcon_loaction_rate['tuas'] ?  parseFloat(subcon_loaction_rate['tuas']) : 0
      }
    }
    // project_service_surcharge
   if (additional_manpower != null && additional_manpower > 0) {
      if (d_type == 'Project') {
        charge = this.customer_price_rate.project_service_surcharge ? charge + (JSON.parse(this.customer_price_rate.project_service_surcharge)["moving_service"] * additional_manpower): charge
        driver = this.driver_payout_rate.project_service_surcharge ? driver + (JSON.parse(this.driver_payout_rate.project_service_surcharge)["moving_service"] * additional_manpower): driver
        subcon = this.subcon_price_rate.project_service_surcharge ? subcon + (JSON.parse(this.subcon_price_rate.project_service_surcharge)["moving_service"] * additional_manpower): subcon
      } else {
        charge = this.customer_price_rate.service_surcharge ? charge + (JSON.parse(this.customer_price_rate.service_surcharge)["moving_service"] * additional_manpower): charge
        driver = this.driver_payout_rate.service_surcharge ? driver + (JSON.parse(this.driver_payout_rate.service_surcharge)["moving_service"] * additional_manpower): driver
        subcon = this.subcon_price_rate.service_surcharge ? subcon + (JSON.parse(this.subcon_price_rate.service_surcharge)["moving_service"] * additional_manpower): subcon
      }

   }
   if(drop_date.getDay() === 6) {

    charge += day_surcharge['saturday_or_eve_ph'] ? parseFloat(day_surcharge['saturday_or_eve_ph']) : 0
    driver += driver_day_surcharge['saturday_or_eve_ph'] ? parseFloat(driver_day_surcharge['saturday_or_eve_ph']) : 0
    subcon += subcon_day_surcharge['saturday_or_eve_ph'] ? parseFloat(subcon_day_surcharge['saturday_or_eve_ph']) : 0
   }

   return {charge, driver, subcon}
    
  }

  totalTalPriceCalculation(selected_package, delivery_type, package_name, period ) {
    
    
    let {client, driver, subcon} = this.deliveryTypePrice( delivery_type, package_name, period )
    let {surcharge_price, surcharge_driver , surcharge_subcon}  = this.surchangeCalculation(selected_package, package_name, delivery_type)

    let base_charge = {
      client_base_charge: client.toFixed(2),
      driver_base_charge: driver.toFixed(2),
      subcon_base_charge: subcon.toFixed(2)
    }

    let surcharge = {
      client_surcharge: surcharge_price.toFixed(2),
      driver_surcharge: surcharge_driver.toFixed(2),
      subcon_surcharge: surcharge_subcon.toFixed(2)
    }
    let price = surcharge_price + client
    driver = driver + surcharge_driver
    subcon = subcon + surcharge_subcon
    
    

    return {price, driver, subcon, base_charge, surcharge}

  }

  deliveryTypePrice (delivery_type, p_name, period) {
    let client = 0
    let driver = 0
    let subcon = 0
    let package_name = p_name.toString().toLowerCase()
    let eligible_rate = {}
    let driver_rate = {}
    let subcon_rate = {}
    if(delivery_type == "Project") {
      eligible_rate = this.customer_price_rate.project_rate ? JSON.parse(this.customer_price_rate.project_rate) : {}
      driver_rate = this.driver_payout_rate.project_rate ? JSON.parse(this.driver_payout_rate.project_rate) : {}
      subcon_rate = this.subcon_price_rate.project_rate ? JSON.parse(this.subcon_price_rate.project_rate) : {}
    }else {
      eligible_rate = this.customer_price_rate.standard_rate ? JSON.parse(this.customer_price_rate.standard_rate) : {}
      driver_rate = this.driver_payout_rate.standard_rate ? JSON.parse(this.driver_payout_rate.standard_rate) : {}
      subcon_rate = this.subcon_price_rate.standard_rate ? JSON.parse(this.subcon_price_rate.standard_rate) : {}
    }

    period = period && period.toString().toLowerCase().trim()
    if (period === "next day" || period === "next day (no time slot)") {
      client = eligible_rate[`${package_name}_next_day`] ? parseFloat(eligible_rate[`${package_name}_next_day`])  : 0 
      driver = driver_rate[`${package_name}_next_day`] ? parseFloat(driver_rate[`${package_name}_next_day`])  : 0
      subcon = subcon_rate[`${package_name}_next_day`] ? parseFloat(subcon_rate[`${package_name}_next_day`])  : 0
    }else if (period === "same day") {
      client = eligible_rate[`${package_name}_same_day`]? parseFloat(eligible_rate[`${package_name}_same_day`]) : 0
      driver = driver_rate[`${package_name}_same_day`]? parseFloat(driver_rate[`${package_name}_same_day`]) : 0
      subcon = subcon_rate[`${package_name}_same_day`]? parseFloat(subcon_rate[`${package_name}_same_day`]) : 0
    } else if (period === "next day (3h slot)" || period === "next day (10am-2pm)" || period === "next day (2pm-6pm)" || period === "next day (6pm-9pm)" ) {
      client = eligible_rate[`${package_name}_next_day_3h`] ? parseFloat(eligible_rate[`${package_name}_next_day_3h`])  : 0
      driver = driver_rate[`${package_name}_next_day_3h`] ? parseFloat(driver_rate[`${package_name}_next_day_3h`]) : 0
      subcon = subcon_rate[`${package_name}_next_day_3h`] ? parseFloat(subcon_rate[`${package_name}_next_day_3h`]) : 0
    } else if (period === "express (2h slot)") {
      client = eligible_rate[`${package_name}_express_2h`] ? parseFloat(eligible_rate[`${package_name}_express_2h`]) : 0
      driver = driver_rate[`${package_name}_express_2h`] ? parseFloat(driver_rate[`${package_name}_express_2h`])  : 0
      subcon = subcon_rate[`${package_name}_express_2h`] ? parseFloat(subcon_rate[`${package_name}_express_2h`])  : 0
    }
    


    return {client, driver, subcon}
  }
  
  surchangeCalculation(rest_packages, name, delivery_type) {
    let surcharge_price = 0
    let surcharge_driver = 0
    let surcharge_subcon = 0
    let eligible_rate = {}
    let driver_rate = {}
    let subcon_rate = {}

    let surcharge_qty = 1
    let driver_surcharge_qty = 1
    let base_qty = 1
    let driver_base_qty = 1

    let subcon_surcharge_qty = 1
    let subcon_base_qty = 1
    
    if(delivery_type == "Project") {
      eligible_rate = this.customer_price_rate.project_parckage_surcharge ? JSON.parse(this.customer_price_rate.project_parckage_surcharge) : {}
      driver_rate = this.driver_payout_rate.project_parckage_surcharge ? JSON.parse(this.driver_payout_rate.project_parckage_surcharge) : {}
      subcon_rate = this.subcon_price_rate.project_parckage_surcharge ? JSON.parse(this.subcon_price_rate.project_parckage_surcharge) : {}
      base_qty = eligible_rate['base_qty'] > 0 ? eligible_rate['base_qty'] : base_qty
      driver_base_qty = driver_rate['base_qty'] > 0 ? driver_rate['base_qty'] : driver_base_qty
      subcon_base_qty = subcon_rate['base_qty'] > 0 ? subcon_rate['base_qty'] : subcon_base_qty
      surcharge_qty = eligible_rate['surcharge_qty'] > 0 ? eligible_rate['surcharge_qty'] : surcharge_qty
      driver_surcharge_qty = driver_rate['surcharge_qty'] > 0 ? driver_rate['surcharge_qty'] : driver_surcharge_qty
      subcon_surcharge_qty = subcon_rate['surcharge_qty'] > 0 ? subcon_rate['surcharge_qty'] : subcon_surcharge_qty
    }else {
      eligible_rate = this.customer_price_rate.parckage_surcharge ? JSON.parse(this.customer_price_rate.parckage_surcharge) : {}
      driver_rate = this.driver_payout_rate.parckage_surcharge ? JSON.parse(this.driver_payout_rate.parckage_surcharge) : {}
      subcon_rate = this.subcon_price_rate.parckage_surcharge ? JSON.parse(this.subcon_price_rate.parckage_surcharge) : {}
      base_qty = eligible_rate['base_qty'] > 0 ? eligible_rate['base_qty'] : base_qty;
      driver_base_qty = driver_rate['base_qty'] > 0 ? driver_rate['base_qty'] : driver_base_qty;
      subcon_base_qty = subcon_rate['base_qty'] > 0 ? subcon_rate['base_qty'] : subcon_base_qty;
      surcharge_qty = eligible_rate['surcharge_qty'] > 0 ? eligible_rate['surcharge_qty'] : surcharge_qty;
      driver_surcharge_qty = driver_rate['surcharge_qty'] > 0 ? driver_rate['surcharge_qty'] : driver_surcharge_qty;
      subcon_surcharge_qty = subcon_rate['surcharge_qty'] > 0 ? subcon_rate['surcharge_qty'] : subcon_surcharge_qty;
    }
    let location_qty = 0;
    let location_driver_qty = 0;
    let location_subcon_qty = 0;
    rest_packages.forEach(pk => {
      let qty = pk.package_quantity
      let driver_qty = pk.package_quantity
      let subcon_qty = pk.package_quantity

      if(pk.name == name) {
        qty = qty - base_qty > 0 ? qty - base_qty : 0
        driver_qty = driver_qty - driver_base_qty > 0 ? driver_qty - driver_base_qty : 0
        subcon_qty = subcon_qty - subcon_base_qty > 0 ? subcon_qty - subcon_base_qty : 0
      }

      if (name == 'per_location') {
        location_qty += parseFloat(pk.package_quantity);
        location_driver_qty += parseFloat(pk.package_quantity);
        location_subcon_qty += parseFloat(pk.package_quantity);
      }
      let s_qty = Math.ceil( qty / surcharge_qty)
      let d_s_qty = Math.ceil( driver_qty / driver_surcharge_qty)
      let sub_qty = Math.ceil( subcon_qty / subcon_surcharge_qty)
      if (name === "per_location") {
        // don't calculate for other cond
      } else if (pk.name === "XS" || pk.name === "S") {
        surcharge_price += s_qty * (eligible_rate.xs_s ? parseFloat(eligible_rate.xs_s) : 0)
        surcharge_driver += d_s_qty * (driver_rate.xs_s ? parseFloat(driver_rate.xs_s) : 0)
        surcharge_subcon += sub_qty * (subcon_rate.xs_s ? parseFloat(subcon_rate.xs_s) : 0)
      } else if (pk.name === "M" || pk.name === "L") {
        surcharge_price += s_qty * (eligible_rate.m_l ? parseFloat(eligible_rate.m_l) : 0)
        surcharge_driver += d_s_qty * (driver_rate.m_l ? parseFloat(driver_rate.m_l) : 0)
        surcharge_subcon += sub_qty * (subcon_rate.m_l ? parseFloat(subcon_rate.m_l) : 0)
      } else if (pk.name === "Pallets") {
        surcharge_price += s_qty * (eligible_rate.pallets ? parseFloat(eligible_rate.pallets) : 0)
        surcharge_driver += d_s_qty * (driver_rate.pallets ? parseFloat(driver_rate.pallets) : 0)
        surcharge_subcon += sub_qty * (subcon_rate.pallets ? parseFloat(subcon_rate.pallets) : 0)
      }
    });
    if (name === "per_location") {
      
      location_qty = location_qty - base_qty > 0 ? location_qty - base_qty : 0
      location_driver_qty = location_driver_qty - driver_base_qty > 0 ? location_driver_qty - driver_base_qty : 0
      location_subcon_qty = location_subcon_qty - subcon_base_qty > 0 ? location_subcon_qty - subcon_base_qty : 0
      
      let s_qty = Math.ceil( location_qty / surcharge_qty)
      let d_s_qty = Math.ceil( location_driver_qty / driver_surcharge_qty)
      let sub_qty = Math.ceil( location_subcon_qty / subcon_surcharge_qty)
      surcharge_price = s_qty * (eligible_rate.per_location ? parseFloat(eligible_rate.per_location) : 0)
      surcharge_driver = d_s_qty * (driver_rate.per_location ? parseFloat(driver_rate.per_location) : 0)
      surcharge_subcon = sub_qty * (subcon_rate.per_location ? parseFloat(subcon_rate.per_location) : 0)
    }
    return {surcharge_price, surcharge_driver, surcharge_subcon}
  }

}
