import React, {useState} from "react";
import SingaporeMapW from "../../../assets/img/sgmap/sg-yellow.png"
import SingaporeMapN from "../../../assets/img/sgmap/sg-green.png"
import SingaporeMapE from "../../../assets/img/sgmap/sg-grey.png"
import SingaporeMapNE from "../../../assets/img/sgmap/sg-violent.png"
import SingaporeMapCT from "../../../assets/img/sgmap/sg-pink.png"
import { isArray } from "rxjs/internal-compatibility";
let is_show = false;
function SingaporeMap(props) {
    const { data } = props;
	const [isHovered, setIsHovered] = useState(false);
	const [isHoveredText, setIsHoveredText] = useState('false');
    const postal_list = [['01','02','03','04','05','06'],['07','08'],['14','15','16'],['09','10'],['11','12','13'],['17'],['18','19'],['20','21'],['22','23'],['24','25','26','27'],['28','29','30'],['31','32','33'],['34','35','36','37'],['38','39','40','41'],['42','43','44','45'],['46','47','48'],['49','50','81'],['51','52'],['53','54','55','82'],['56','57'],['58','59'],['60','61','62','63','64'],['65','66','67','68'],['69','70','71'],['72','73'],['77','78'],['75','76'],['79','80']]
   const returnValaue = (data, name) => {
        let value = data && data.filter(dd => dd.name == name)
        return value && value['0'].value
    }

	const handleMouseEnter = (name) => {
		setIsHovered(true);
		// let west_array = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
		// if (west_array.includes(name)) {
		// 	setIsHoveredText("Central : " + name)
		// } else if (name == '21' || name == '22' || name == '23' || name == '24') {
		// 	setIsHoveredText("West : " + name)
		// } else if (name == '19' || name == '20' || name == '28' ) {
		// 	setIsHoveredText("North East : " + name)
		// } else if (name == '16' || name == '17' || name == '18' ) {
		// 	setIsHoveredText("East : " + name)
		// } else if (name == '25' || name == '26' || name == '27' ) {
		// 	setIsHoveredText("North : " + name)
		// }
		setIsHoveredText("District " + name +" (Postal Code "+postal_list[parseInt(name) - 1].join(',')+")")
	  };
	
	  const handleMouseLeave = () => {
		setIsHovered(false);
	  };

  return (
	// <div className="map-posiiton-sg-container" style={{height:'300px', zIndex: 400}}>
    <div className="w-100 map-posiiton-sg">
		{isHovered && <div className="fs-3 fw-bold map-label">{isHoveredText}</div>}
	<svg version="1.1" id="Layer_1" x="0px" y="0px"
		viewBox="0 0 300 300" style={{enableBackground:"new 0 0 300 300"}}>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M97.6,185.6L97.6,185.6L97.6,185.6L97.6,185.6z M24.4,151.1L24.4,151.1L24.4,151.1L24.4,151.1z">
		<div className="text-hover">hello</div>
	</path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('22')} onMouseLeave={()=> {handleMouseLeave('22')}} d="M99.7,188.9c0.5,1.2,0.1,2.3-0.6,3.3c-1.4,0.4-2.9,0.6-4.2-0.4c-0.2-0.2-0.5-0.4-0.8-0.3
		c-3.5,1-5.3-1.6-7.5-3.3c-0.9-1.3-1.8-2.8-1.2-4.5c0.5-1.5,1.1-2.9,1.9-4.4c1.3-2.5,1.4-4.9-0.5-7.2c-0.4-0.5-0.7-1.1-1.1-1.6
		c-0.3-0.5-0.3-0.8,0.3-1.1c1.9-1,1.9-2.7,0.2-3.8c-1.2-0.8-2.1-1.7-2.9-2.8c-0.6-0.9-1.4-1.8-2.6-1.9c-0.4,0-0.7,0.1-0.9,0.4
		c-0.2,0.3-0.1,0.7,0.1,0.7c2.4,0.5,2.3,3.9,4.8,4.3c0.4,0,0.7,0.5,0.7,0.9c0,0.5-0.4,0.6-0.8,0.7c-2,0.5-2.3,1.4-0.9,2.9
		c1.1,1.1,2.1,2.2,1.9,4c-0.1,0.3-0.1,0.9,0.5,0.8c1.5-0.2,0.8,0.7,0.6,1.2c-1.1,2.2-2.5,4.4-3.2,7.4c-1.1-2.1-1.4-4-2.6-5.4
		c-1.2-1.5-2.1-1.4-2.9,0.3c-0.4,0.8-0.6,1.5-0.4,2.4c0.3,1.1,0.4,2.1,0.6,3.4c-1.3-0.3-1.9-1.3-2.7-1.9c-0.8-0.7-1.5-1.5-2.4-2l0,0
		c-0.1,0-0.2-0.1-0.3-0.2l-1.8-1h0c0,0-0.1-0.1-0.2-0.1l-0.1,0l0,0c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.5-1.4,0.8
		c-1.2,1.1-2.4,2.4-3.6,3.5c-0.8,0.7-1.6,1-2.3-0.3c-1.2-1.9-3.4-1.9-5.3-1.9c-1.9,0-1.6,2-1.9,3.3c-0.3,1.2-0.7,2-1.9,2.1
		c-1.4,0.1-2.9,0.3-4.2-0.8c-0.3-1.3-0.7-2.6,0-4c0.2-0.4,0.1-1-0.5-1.2c-0.3-0.2-0.5-0.4-0.8-0.6c-0.1,0-0.2,0-0.3,0.1
		c0,0.1-0.1,0.2-0.2,0.2c-0.4,1.1-0.5,2.1,0,3.2c0,1.4,0.7,3.2-2,3.2c-2.6-0.1-3.1-0.4-3.9-3.9c-0.2-0.9-0.3-2.1-1.5-1.8
		c-1.1,0.3-1.2,1.5-0.8,2.5c0.5,1.3,0.8,2.6,1.6,3.7c1.3,2,0.3,3.2-1.2,4.4c-1.5,1.1-3,0.8-4.5-0.8c-0.4-0.4-0.8-0.8-1.2-1.2
		c-0.7-1.1-1.5-2.1-2.9-2.2c-1.3-1.2-3.3-1-4.7-2l0-0.1c0.4-2.1,2.1-3.4,3-5.1l1.4-1.2l0,0c0.1-0.2,0.2-0.5,0-0.8
		c-0.1-0.3-0.5-0.5-0.6-0.3c-0.5,0.9-1.8,0.8-2,1.9c-1.3,0.5-2.6,1.6-4.2,1.2c0-0.5,0-0.9,0-1.3c0.3-0.6,0.7-1.3,0.8-1.9
		c0,0,0-0.1,0.1-0.1l0,0c0.3-0.3,0.5-0.5,0.7-0.9l0,0c0.2-0.4,0.1-0.8-0.2-1.2c-0.5-0.7-1.6-0.4-2.3,0c-0.5,0.2-1,0.5-1.4,0.8
		c-1,0.5-2,0.6-2.8-0.3c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.4l0,0c-0.1-0.5-0.3-0.7-0.9-0.5c-1.7,0.5-2.4,2.1-1.7,3.9
		l0,0v0.2c0,0.6,0,1.3,0.7,1.6c1,4,3.2,7.1,6.1,9.8c1.3,1.3,2.8,2.4,3.6,4.1c1.3,2.7-0.5,5.7-3.5,5.7c-3.6,0-7.1-0.5-10.7-0.9
		c-2.2-0.2-4.2-0.9-5.2-3.3c-0.3-0.7-1-1.3-1.6-1.6c-2.1-0.9-2-2.4-1.4-4.2c2.8-8.1,5.6-16.1,8.2-24.3c1.5-4.5,3.9-8.9,3.5-13.9
		c-0.1-1.3,1.5-2,2.7-1.2c2.3,1.7,4.6,3.4,6.1,6c-0.1,0.5,0.1,0.8,0.5,0.9c0,0,0,0.1,0.1,0.2c0.1,0.6,0.3,1.2,0.9,1.5
		c0,0.8,0.4,1.1,1,1.2c0.2,0,0.3,0,0.5,0c1.3-0.1,2.6-0.7,3.9-0.2c0.1,0,0.5-0.2,0.6-0.4c0.1-0.3-0.1-0.5-0.4-0.6
		c-1.3-0.5-2.2-1.7-3.6-2.1l0,0c-0.3-0.8-1.2-1.1-1.7-1.8c-0.3-0.4-0.7-0.8-0.3-1.3c0.3-0.4,0.8,0,1.1,0.1c0.4,0.1,0.8,0.5,1.1,0
		c0.3-0.4-0.1-0.8-0.5-1c-0.5-0.3-0.7-1.1-1.5-1l0,0c-0.5-0.8-1.1-1.5-2-1.7c-0.1-0.1-0.2-0.1-0.4-0.1c-0.3-1.1-1.3-1.4-2-2
		c-1.2-1-1.6-2-0.9-3.4c0.9-0.1,1.8-0.2,2.1-1.3l0,0c1.4-0.8,2.9-1.2,4.6-1c0.8,0.1,1.7,0.8,2.3,0c0.6-0.8-0.5-1.5-0.7-2.2
		c-0.1-0.3-0.3-0.6-0.5-0.9c-1.1-1.6-0.6-2.4,1.1-3.1c1.6-0.7,3.5-0.3,5-1.3c1.6,0.1,3.2-0.2,4.7,0.4v0c0,0.1,0,0.3,0,0.4
		c1,1,0,2.1,0.2,3.4c0.6-0.6,1.2-0.9,1.9-0.9c1.2,1.7,3.5,2.3,4.6,4.1c0.3,0.4,0.7,0.7,1.3,0.3c0.1,0,0.1-0.1,0.2-0.1l0,0l1.9-1.5
		c0.4,0.5,0.8,1.1,0.8,1.8c0.1,1,0.9,1.3,1.8,1.4c2.1,0.3,2-1.3,2.3-2.6c0.5-2.4,1.6-3,3.4-1.6c3.5,2.7,7.2,2.6,11.1,1.1
		c3.8-1.5,7.5-2.2,11.6-1.1c3.3,0.8,3.7,1.5,2.1,4.3c0,0-0.1,0.1-0.1,0.2c-1,1.6-0.6,2.5,1.3,2.3c2.1-0.3,3.5,0.4,4.4,2.2
		c0,0.3,0.1,0.5,0.2,0.7c0,0.8,0,1.5-0.3,2.3c-0.9,2.7-0.1,4,2.7,4.5c2.1,0.3,4,1.2,5.4,2.9c1,1.3-0.3,2.7-0.2,4
		c0,1.6-0.7,3.3,0.6,4.8c2,2.4,0.8,6.5-2.3,7.6c-1.7,0.6-1.7,1.1-0.3,2.4c1.6,1.4,3.2,2.7,4.6,4.2c0.8,0.8,2.1,2.2-0.3,2.9l0,0
		c-0.2-0.5-0.5-0.5-0.8-0.5l-0.3-0.2c-0.1-0.3-0.4-0.5-0.8-0.5c-0.8-0.7-1.9-1.5-2.7-0.3c-0.8,1.3,0.7,1.6,1.5,2.2
		c0.5,0.4,1.1,0.6,1.7,0.4C98.8,186.8,99.2,187.8,99.7,188.9z">
			<div className="text-hover">hello</div>
		</path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M25.5,158.4c0,0,0,0.1,0,0.1C25.4,158.5,25.4,158.4,25.5,158.4C25.4,158.4,25.4,158.4,25.5,158.4L25.5,158.4
		L25.5,158.4z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M24.8,181.8L24.8,181.8c-0.2-0.5-0.1-0.9,0-1.3C24.8,180.9,24.8,181.4,24.8,181.8z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M25.7,178.5c0,0-0.1,0.1-0.1,0.1C25.6,178.5,25.6,178.5,25.7,178.5C25.6,178.5,25.7,178.5,25.7,178.5z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M70.9,179.8C70.8,179.8,70.8,179.8,70.9,179.8L70.9,179.8L70.9,179.8z">
	<div className="text-hover">hello</div>
	</path>
	<path class="st1" onMouseEnter={() => handleMouseEnter('17')} onMouseLeave={()=> {handleMouseLeave('17')}} d="M293.3,172.9c-0.3,0.5-0.9,0.5-1.2,0c-1.6-2-3.8-1.3-5-0.3c-2,1.7-4.2,1.8-6.4,2.1c-2.2,0.4-4.2,1.1-5.8,2.5
		c-1.9,1.8-4.1,1.8-6.2,0.5c-1.9-1.1-3.6-2.6-6.1-1.4c-0.4,0.2-1.1-0.2-1.6-0.3l-0.1-0.1c-0.3-3.7-1-4.1-4.4-2.7
		c-1.3,0.5-2.4,1.5-3.5,2.2c-1.2,0.7-2.9,1-3.7,0.3c-1-0.9,0.6-1.6,1.2-2.4c1.4-1.9,2.1-5.2,0.9-6.1c-1.2-0.9-1.1-1.8-0.9-2.9
		c1.2-0.3,1.6-1.5,2.3-2.3c1.4-1.6,1.1-2.9-0.8-3.8c-1.9-0.9-3.4-2.3-4.2-4.2c-0.5-1.3-0.8-2.7-1.2-4c-0.4-4.1,0.4-8.2,0.6-12.3
		c0.2-3.5-0.1-4-3.5-3.9c-0.3,0-0.5,0.2-0.8,0.2c-0.5,0-1.3,0.3-1.4-0.4c-0.2-0.7-0.4-1.5,0.4-2.1c0.5-0.4,1-0.7,1.7-0.8
		c4.5-0.5,8.5-2.1,10-6.9c0.2-0.7,0.8-0.8,1.3-0.9c2.1-0.3,4.1-0.6,6.2-0.5c2.6,0.2,5.1,1,7.5,1.6c1.4,1.7,2.9,3.4,4.3,5.1
		c0.7,1.6,2,2.8,3,4.2c0.5,0.8,1.7,1.4,1.8,2.2c0.1,1.4-1.9,0.3-2.3,1.4l-0.1,0.1c-0.8,0.7,0.3,1.3,0.1,2c-0.7,1.9,0.4,2.5,2.1,2.7
		l0.1,0.1c1.4,1.6,3.4,1.8,5.1,2.4c1.4,0.5,3.1,0.8,2.1,3c0,0.1-0.1,0.3-0.1,0.4c-1.1,1-1.5,2.3-1.7,3.7l-0.1,0.1
		c-0.6,0.2-0.7,0.7-0.6,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-2.1,3.3-3.6,6.8-4.8,10.4c0,0.2-0.1,0.3-0.2,0.5c-1.1,1.1-2.1,2.6-1.8,4
		c0.4,1.7,0.1,2.5-1.8,2.5c1.4,1.3,5.6,1.7,7.1,0.6c3.4-2.4,7.3-2.6,11.2-2.6c0.9,0,1.9,0.3,2.2,1.3
		C294.1,170.9,293.8,171.9,293.3,172.9z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('05')} onMouseLeave={()=> {handleMouseLeave('05')}} d="M133.3,202.5c-0.3,1.6-0.5,3.2-0.8,4.8c-0.4,2.9-0.4,2.9-3.3,2.5l0,0c-2.7-2.3-5.7-3.9-8.8-5.4l-5.5-2.9
		l-0.3,0.5l-4.8,7.7l-12.7-8l3.3-3.6l9.3,5.9l2.3-3.5c0-0.4-0.2-0.8-0.7-1.1c-2-1.5-3.7-3.4-6.2-4.1c-0.3-0.6-0.7-1-1.3-1.3
		c0.1-2.9-0.3-5.6-2.4-7.7c-0.3-0.3-0.5-0.8-0.5-1.1c0.2-2-0.8-3.2-2.4-4.3c-0.8-0.5-1.4-1.3-2.1-2c-0.7-0.6-0.7-1.3,0.3-1.6
		c1.3-0.5,1.9-1.6,2.1-2.9c0.2-1.8,1-2.1,2.5-1.5c0.6,0.3,1.3,0.3,2,0.5c3.7,1.1,5.4-0.3,5.4-4.1c0-0.9-1.1-2.7,0.5-2.8
		c1.1,0,0.5,1.9,0.7,2.9c0.2,0.9-0.4,2.2,1.1,2.6c1.2,0.3,1.7,1.3,2.3,2.2c1.6,2.2,3.3,4.3,6.5,3.9c0.3,1.8,1.7,2.8,3.2,3.3
		c2,0.7,2.9,1.9,3.2,3.9c0.5,2.8,1.1,5.6,4,7.2c0.8,0.4,0.7,1.2,0.6,1.9c-0.3,2.4,0.3,4.4,1.9,6.2
		C133.2,200.9,133.5,201.6,133.3,202.5z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M184.7,199.7L184.7,199.7L184.7,199.7c0.1,0,0.1,0,0.1,0C184.8,199.6,184.8,199.6,184.7,199.7z M184.6,199.7
		L184.6,199.7L184.6,199.7L184.6,199.7L184.6,199.7z M177.6,206c0.1-0.2,0.3-0.3,0.4-0.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3
		C178,205.2,177.8,205.6,177.6,206z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('01')} onMouseLeave={()=> {handleMouseLeave('01')}} d="M192.3,192.9c-1.3,0.4-2.7,0-4.1,0c-0.8,0-1.6,0.1-1.3,1.1c0.5,1.5-0.2,2.9-0.3,4.3c-0.1,0-0.2,0.1-0.3,0.1l0,0
		c-0.1,0-0.9,0.8-1.6,1.1c0,0-0.1,0-0.1,0l0.1,0c-0.1,0-0.1,0-0.2,0l0,0h0h-0.5c0,0,0,0,0.1,0c-1.2,0.1-3.7,1-6,5.2
		c-0.2,0.3-0.4,0.7-0.5,1.1l0,0c-0.8,0.5-1.6,1.2-2.7,0.6c-1-0.5-0.6-1.5-0.7-2.2c-0.4-3.2,0.6-6.2,1-9.3c0.3-2.1,1.6-3.5,2.7-5.1
		c0.3-0.4,0.7-0.7,1.2-0.8c4.4-0.2,8.7,0,12.5,2.6C192.1,192.1,192.3,192.4,192.3,192.9z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('04')} onMouseLeave={()=> {handleMouseLeave('04')}} d="M153.1,207.3c-1.3,1.6-2.5,3.3-3.7,5c-2.4,0.8-4.8,0.4-7-0.8c-2.7-1.3-4-0.8-4.8,1.5c-0.1,0.3-0.2,0.6-0.3,0.9
		c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.6-0.3-1.1-0.9-1.2l0,0c-0.1-0.1-0.3-0.3-0.4-0.4l0,0
		c-2.3-2-1.4-4.4-0.9-6.7c0.3-1.3,0.1-3,0.9-3.7c0.8-0.8,2.5,0.1,3.8,0.3c2.3,0.4,4.6,0.8,6.9,1.4c2.4,0.7,4.8,1.2,7.2,1.5
		C154,205.7,153.8,206.3,153.1,207.3z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M106.5,200.5c-0.1-0.1-0.3-0.3-0.4-0.4C106.2,200.3,106.3,200.4,106.5,200.5z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M106.9,200.9c-0.1-0.1-0.3-0.3-0.4-0.4C106.6,200.6,106.8,200.8,106.9,200.9z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M177.6,206c0.1-0.2,0.3-0.3,0.4-0.5C177.9,205.7,177.8,205.8,177.6,206z"></path>
	<path class="st3" d="M87.9,107c-0.1,0.1-0.3,0.3-0.4,0.4C87.7,107.3,87.8,107.2,87.9,107z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M107.8,207.7c0.2,0.1,0.3,0.3,0.5,0.4C108.1,207.9,107.9,207.8,107.8,207.7z"></path>
	<path class="st1" onMouseEnter={() => handleMouseEnter('18')} onMouseLeave={()=> {handleMouseLeave('18')}} d="M244.3,145.3c-0.1,0.2,0,0.5,0.2,0.8c0.9,1.2,0.8,2.6,0.6,4c-3.2,0.1-3.5,0.5-2.5,3.5c0.1,0.3,0.3,0.6,0.4,1
		c0.6,1.5,0,2.4-1.6,2.3c-1.3,0-2.4,0.2-3.3,1.3c-0.5,0.7-1.5,1-2.2,0.3c-1.8-1.9-2.8-1-3.8,1.1c-1-1.9-2.2-2.3-4.2-1.8
		c-1.5,0.4-3.1-0.1-4.6-1c-2.8-1.6-5.6-3.2-9.1-3.2c-2.4,0-4.1-1.6-4.5-3.5c-0.5-2.4,0.2-4.5,2.2-5.9c0.8-0.5,1.7-1,2.4-1.7
		c1.2-1.1,3.2-1.6,3.2-3.8c0.2-0.8,0-1.3-0.7-1.9c-1.3-1.1-1.8-2.4-0.9-4c0.3-0.5,0.5-1,0.4-1.6c1.2,0,1.7-1.1,2.4-1.8
		c1.6-1.7,3-3.6,1.7-6.2c-0.3-1-0.1-2.1-0.8-2.9c0-0.4-0.1-0.8-0.2-1.1c2.3-2.4,3.7-2.4,5.6-0.3c0.6,0.6,0.7,1.4,0.8,2.1
		c0.4,2.5,1.8,4,4,5c0.9,0.5,2.3,0.8,2.7,1.6c1.1,2.2,3.2,2.7,5.1,3.1c2.2,0.4,2.7,1.5,2.1,3.4c-0.5,1.5,0.3,1.9,1.4,2.2
		c0.8,0.2,1.5,0,2.3-0.1c2-0.2,2.6,0.5,2.1,2.4C245,140.6,245.6,143.1,244.3,145.3z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('11')} onMouseLeave={()=> {handleMouseLeave('11')}} d="M170.8,172.2c-0.5,0.5-1.4,0.3-1.9,1l-0.1,0c-1.4,2.2-3.4,3.1-5.9,2.9c-0.1,0-0.3,0.3-0.4,0.4
		c-0.8,0.5-1.5,1.1-2.5,1.9c0-2.4-1.9-3.2-3.4-4.4c-0.8-1.4-2.1-2.1-3.7-2.4c-1.8-0.8-3.7-0.8-5.5-1c-4-0.3-7.5-1.8-10.8-4
		c-1.6-1.1-3.5-1.6-5.3-2.1c-0.5-0.1-0.8-0.6-1.2-0.7l0,0c0-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1l0,0c-0.1,0-0.1-0.1-0.2-0.1l0,0
		c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0H129l0,0l0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-2.6,0.1-5.2-0.5-7.8
		c-0.3-1.3,0-1.6,1.3-1.6c2.3-0.1,3.5-2.7,2.1-4.8c0,0,0,0,0,0l-1-1.6c-0.3-0.4-0.4-1-0.2-1.5c0-0.1,0-0.2,0.1-0.2
		c0.3-1.1,0.8-2.1,1.1-3.1c1.2-0.9,2.2-2.7,4.1-1.1c0,1.9-0.1,3.8-0.1,5.6c0,1.8,0.6,3.2,2.2,4.3c1.3,0.8,2.5,1.7,3.7,2.6
		c1.2,1.7,2.5,3.4,3.8,5.3c0.3-0.8,0.5-1.5,0.7-2c0.3-0.7,0.6-1.3,1.5-0.4c1.4,1.5,1.6,0.3,1.9-0.9c0.5,0.1,0.5-0.4,0.8-0.5
		c0.2,0,0.3,0.1,0.4,0.1c0,0.1,0,0.3,0.1,0.4c0,0.7,0.2,1.3,0.9,1.6l0,0.1c-0.1,0.3,0,0.4,0.3,0.5l0,0l0,0c0.2,0.8,0.8,1.5,1.6,1.4
		c1.2-0.1,1.5-1.3,1.9-2.2l0,0c2.4,0.5,4.6,1.6,6.8,2.6c0,0.8,0.3,2-0.5,2.3c-1.3,0.6-1.5,1.9-0.9,2.5c1.1,1.2,0.8,3.9,3.5,3.5
		c1.1,0.5,2.1,0.3,3.4,0.8l0.8,0.3c0.3,0.4,1.2,0.8,2.4,3L170.8,172.2z"></path>
	<path class="st5" onMouseEnter={() => handleMouseEnter('st5')} onMouseLeave={()=> {handleMouseLeave('st5')}} d="M129.1,163.4c0,0-0.1,0-0.1,0l0,0H129.1z"></path>
	<path class="st6" onMouseEnter={() => handleMouseEnter('st6')} onMouseLeave={()=> {handleMouseLeave('st6')}} d="M154.1,173.3l-0.5-0.3c0.2,0,0.3,0.1,0.5,0.1l0,0C154.1,173.2,154.1,173.2,154.1,173.3z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('03')} onMouseLeave={()=> {handleMouseLeave('03')}} d="M160.6,196.3c-2.1,1.3-2,3.4-1.9,5.3c0,1.1-0.4,1.5-1.3,2c-3.4,1.6-6.7,0.2-9.9-0.4c-3.2-0.5-6.3-1.5-9.5-2
		c-2.8-0.5-5.4-2.9-5-6.2c0.3-1.9,0-3.4-2.3-4.1c-1.6-0.5-2.1-2.1-2.3-3.7c-0.1-0.8,0-1.7-0.2-2.5c-0.2-1,0.3-1.4,1.1-1.7
		c0.3-0.1,0.6,0,0.8-0.1c2.4-1.3,3.6-0.3,4.3,2.1c0.6,1.9,2.7,2.4,4,3.8c0.3,0.8,1.3,0.8,1.3,1.7c0.4,2,1.5,2.8,3.4,2.2
		c0.9-0.3,1.9-0.7,2.8-0.9c1.5-0.3,3.1-0.7,3.6,1.6c0.3,1.2,1.3,0.9,2,0.5c3.1-1.4,5.9-0.3,8.8,0.8
		C161.4,195,161.4,195.8,160.6,196.3z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M144.6,196.1c0,0.1-0.1,0.2-0.2,0.3l-0.1-0.1C144.4,196.2,144.5,196.2,144.6,196.1z"></path>
	<path class="st1" onMouseEnter={() => handleMouseEnter('st1')} onMouseLeave={()=> {handleMouseLeave('st1')}} d="M195.5,191.9c0.1,0,0.2-0.1,0.3-0.2L195.5,191.9z"></path>
	<path class="st7" onMouseEnter={() => handleMouseEnter('15')} onMouseLeave={()=> {handleMouseLeave('15')}} d="M220.7,179.8c0,0.3-0.1,0.4-0.2,0.6l-9.1,4.1l-15.7,7.2l0,0l-0.3,0.2h0l0,0c-0.8,0.3-1.4,0.1-2.3-1.2
		c-1.6-2.4-4.7-2.3-7.4-2.8c-1.1-0.2-2.2-0.1-3.3-0.1c-0.5,0-1-0.1-1-0.8c0.3-0.1,0.5-0.2,0.8-0.3c1.7-0.3,3.5-0.4,5.1-0.7
		c3.2-0.6,6.2-1.6,8.5-4c0.5-0.6,1.8-1.3,0.5-2.3c0.4-2.7,2.9-3,4.8-3.6c3.6-1,7.3-1.4,10.9-2.2c4.8-1.1,5.2-0.2,7.8,3.8
		C220.1,178.4,220.8,179,220.7,179.8z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('12')} onMouseLeave={()=> {handleMouseLeave('12')}} d="M181,178.2c0,0.8,0.1,1.5,0.2,2.3c0.1,1.3-0.5,1.8-1.8,1.6l-0.1-0.1c0.1-0.5-0.1-0.9-0.7-1
		c-1.1-2.8-3.2-4.8-5.5-6.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.3-0.5-0.6-1-1.4-0.9c0-0.1,0-0.2-0.1-0.2c0.3-1-0.4-2.3-1-3.1
		c-0.2-0.3-0.4-0.5-0.6-0.7c-0.3-0.2-0.6-0.3-1-0.3c-0.3,0-0.6-0.1-0.9-0.3c-0.5-0.3-1.1-0.3-1.6-0.4c-3.4-0.9-4.3-3.2-2.1-5.9
		c0.7-0.9,0.5-1.3-0.3-1.8c-0.3-1.6,1-1.4,1.9-1.9c4.8-2.3,6.7-1.8,9.1,2.7c-1,4.8,2,6.8,5.8,6.7c0.1,0.1,0.2,0.1,0.3,0.1
		c0.2,0.2,0.3,0.3,0.4,0.4l0.1,1.2c-0.2,0.7,0.2,1.1,0.8,1.3v0c0,0.3,0.1,0.4,0.4,0.4c0.2,0.6,0.7,0.8,1.3,0.8
		c-0.6,1.3-1.8,2.2-2.6,3.3C180.1,176.2,179.8,177.2,181,178.2z"></path>
	<path class="st6" onMouseEnter={() => handleMouseEnter('09')} onMouseLeave={()=> {handleMouseLeave('09')}} d="M160.3,192.9c-1.9-0.5-4-1.1-6.2-1.8c-0.7-0.2-0.5-0.9-0.5-1.5c0.1-2.3,0.7-4.5-0.3-6.9c-1-2.2,1.1-3.1,2.7-3.9
		c1-0.5,2-1.5,2.7,0.5c0.4,1,1.4,1.3,2.4,0.2c0.3-0.4,0.7-0.8,1.1-1c0.8-0.4,1.8-1.4,2.4-0.5c0.6,1,2.5,1.6,1.5,3.4
		c-0.4,0.7-0.3,1.5,0.1,2.2c0.8,1.5,0.3,2.4-1.3,2.4c-1.4,0-1.6,0.8-1.5,1.9c0,0.8,0,1.6,0,2.3C163.4,192.2,162.5,193.1,160.3,192.9z
		"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('02')} onMouseLeave={()=> {handleMouseLeave('02')}} d="M167.6,216.2c-3.1,0.2-5.7-0.4-7.7-2.8c-0.9-1.1-2.3-0.9-3.5-1c-1.5-0.1-3.1,0-5,0c1.2-2,2.2-3.9,3.5-5.6
		c1.4-1.9,3.4-2.1,5.4-0.8c0.7,0.4,1.3,0.9,2.1,0.8c2.9-0.2,5.5,1.1,8.3,1.3c0.9,0.1,1.2,0.5,1.2,1.3c0,0.8,0,1.7,0,2.5
		C171.8,216,171.8,215.9,167.6,216.2z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('07')} onMouseLeave={()=> {handleMouseLeave('07')}} d="M180.2,186.5c-2.9,2.1-5.2,4.5-6.4,8c-0.5,1.4,0.1,3.8-1.5,4.1c-1.3,0.3-2.8-1.3-4.5-1.3
		c-0.4,0-0.8-0.3-1.1-0.6c-0.8-1-1.8-1.2-3-1.1c0-0.6,0.1-1.2,0.4-1.7c0.6-0.5,0.5-1.3,0.4-2c-0.2-1.5,0.3-2.6,1.7-3.2
		c0.5-0.3,1.2-0.5,1.1-1.1c0-2.3,1.4-2.1,3-2.1c1.4,0.1,2.7,0,3.9-1.1c0.9-0.8,5-0.2,6,0.6C180.9,185.4,181.1,185.8,180.2,186.5z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('06')} onMouseLeave={()=> {handleMouseLeave('06')}} d="M172.9,205.4c-0.2,1-0.7,1.6-1.9,1.2c-2.9-0.9-5.9-1.5-8.9-1.8c-1.1-0.1-1.7-0.6-2.1-1.5c-1.1-2.9,1-6.1,4-6.6
		c0.5-0.1,1.2-0.2,1.4,0.5c0.6,1.6,2.1,1.6,3.5,1.7c2.9,0.3,4.2,1.6,4.1,4.5C173,204.1,173.1,204.8,172.9,205.4z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('08')} onMouseLeave={()=> {handleMouseLeave('08')}} d="M177.4,181.9c-0.3,1.1-1.7,0.3-2.6,0.5c-0.5,0.1-1,0.1-1.3,0.6c-1.1,1.8-2.9,1.1-4.4,1c-1,0-1.8-1-1.5-1.9
		c0.4-1.6-0.4-2.7-1-3.9c-0.1-1.3,2.1-1,1.8-2.4c1.9-0.8,3.6-0.6,5.2,0.9c0.9,0.8,1.6,1.8,2.4,2.7
		C176.7,180.1,177.7,180.9,177.4,181.9z"></path>
	<path class="st5" onMouseEnter={() => handleMouseEnter('st5')} onMouseLeave={()=> {handleMouseLeave('st5')}} d="M130,163.9c-0.4,0-0.8,0.1-1-0.5l0,0h0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l0,0
		c0.1,0,0.2,0,0.2,0.1l0,0c0,0,0,0,0.1,0.1C129.9,163.8,129.9,163.8,130,163.9z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('23')} onMouseLeave={()=> {handleMouseLeave('23')}} d="M123.8,138.6c-0.1,0.5-0.3,0.8-1,0.7c-0.9-0.2-1.8-0.1-2.7-0.2c-1.1-0.1-2,0.5-2.2,1.5
		c-0.1,1.1,0.8,1.6,1.8,1.9c0.4,0.1,1,0,1.3,0.5c-0.8,0.6-1.6,0.3-2.4,0.3c-1,0.1-2.1-0.3-2.8,0.8l-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.8,0.7-1.2,1.7-2.2,2.1c-0.5,0-1.1,0-1.6,0c-1.1,0.2-2.5-0.6-3.1,0.5c-0.6,1.1,1.1,1.4,1.3,2.3c0,0.1,0,0.3,0.1,0.4
		c-1.3,0.2-1.9,0.6-0.8,1.9c0.4,0.5,1.5,0.6,1.2,1.5c-0.3,0.8-1.3,0.7-2,0.8c-2.4,0.5-2.9,1.6-1.6,3.6c0.6,0.8,1.3,1.7-0.2,2.3
		c-1.1-0.1-1.7,1.2-2.8,0.8c-1.7-0.7-3.5-1.1-4.9-2.6c-1.7-1.7-4-2.2-6.3-2.4c-2.6-0.2-3.2-1.1-2.7-3.5c0.2-0.8,0.4-1.6,0.3-2.5
		c-0.2-0.7-0.3-1.3-0.5-2c-0.1-1.6-1.5-1.4-2.5-1.6c-0.8-0.2-1.7-0.1-2.6-0.2c1.1-1.8,2.1-3.4,3-5.1c0.9-1.8,1.1-3.6-0.8-4.9
		c-2-1.4-1.8-1.6-0.5-3.6c0.6-0.9,2.4-2.1,0.5-3.6c-0.7-1.1-0.8-2,0.6-2.8c1.1-0.6,1.3-1.5,0.5-2.6c-1-1.4-1.3-2.7,0.5-3.9
		c1.3-0.8,0.9-2.4-1.1-3.2c-2-0.8-2.2-2.2-2.3-4.1c-0.1-2.6,2.1-3.2,3.5-4.5c0.3-0.3,0.8-0.3,0.7-0.8c0.3-0.1,0.7-0.2,1-0.4
		c1.1-0.9,1.7-0.7,1.9,0.8c0.2,1.3,0.6,2.6,0.7,3.9c0.1,1.4,0.7,2,2.1,2c2.4,0,4.4,0.6,4.4,3.7c0,0.6,0.6,0.9,1.2,1
		c0.3,0.5,0.7,0.3,1.1,0.4c2.1,0.5,4.3,0,6.4,0.7c3.2,1,6.3,2.4,9.3,3.9c0.4,0.2,0.3,0.6,0.4,0.9c0,0.5,0.2,1.1,0.8,1.3l0,0
		c-0.2,0.5,0,1,0.1,1.6c0.8,2.9,1.4,5.9,2.1,8.8c0,0.6-0.4,1.3,0.4,1.6l0.1,0c0.3,1.6,1.6,0.6,2.4,0.8l0,0
		C123.5,138.3,123.7,138.4,123.8,138.6L123.8,138.6z">
			<div className="text-hover">hello</div>
		</path>
	<path class="st9" onMouseEnter={() => handleMouseEnter('st9')} onMouseLeave={()=> {handleMouseLeave('st9')}} d="M197.9,159.3L197.9,159.3L197.9,159.3L197.9,159.3z M197.3,157.8c-0.3,0.7-0.3,1.2,0.6,1.4
		C197.7,158.8,198.1,158.1,197.3,157.8z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('26')} onMouseLeave={()=> {handleMouseLeave('26')}} d="M161.7,125.2c-0.9,1.3-1.9,2.4-2.6,3.8c-0.5,1.1-1.2,1.1-2.3,1.2c-2.9,0.3-5.8,0.9-8.6-0.3c0,0-0.1,0-0.2,0
		c-1.1,0.4-2.1,0.3-3.1-0.1c-1.6-0.6-2.4-0.5-2,1.6c0.2,0.9,0.6,2.5-1.5,2v0c-0.3-0.8-0.9-1.3-1.7-1c-0.8,0.4-0.1,1.1-0.1,1.6
		c0,0.7,0,1.3,0,2v0c-0.5,0.3-0.5,0.7-0.5,1.1c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0-0.5,0.1-0.7,0.1
		c-1.1-0.4-2.2-0.8-3.4-1.3l0,0c-1.1-1.1-1.8-2.9-4-1.9c-0.6,0.3-1.2,0-0.9-0.8c0.5-1.6-0.7-1.1-1.3-1.2c-0.1-0.1-0.3-0.3-0.4-0.4
		c0-0.4-0.1-0.6-0.5-0.8c-0.3-0.4-0.6-0.5-0.9-0.5c-0.3,0-0.6,0.3-0.9,0.9c-0.9,1.6-2.1,2.5-4.1,2.1c0.1-1.3-0.3-2.5-0.5-3.7
		c-0.4-2.4-1.3-4.7-0.3-7.1v0c0.7-0.3,1.2-0.8,1.6-1.4c0.6,0,1.1-0.1,1.1-0.8l0.2-0.1c1.6,0.7,2.4,0.2,2.4-1.6c0.1,0,0.2-0.1,0.3-0.1
		c2,0,3.9,0.1,5.9,0.2c0.7,0.6,1.3,0.5,2.1,0c0.3-0.2,0.7-0.3,0.8-0.5c0.9-1.5,1.8-3.1,3.7-3.7c0.6-0.2,0.3-0.7,0-1
		c-0.9-0.8-1.1-1.9-1.4-3c-0.3-1.2-1.3-1.8-2.5-1.8c-1.3,0-1.3,1.1-1.3,1.9c0,0.1-0.1,0.1-0.2,0.2l0,0c-0.3,0-0.5,0-0.8,0.1l-0.1-0.1
		c-0.4-2-2.3-0.8-3.3-1.6c0-0.2-0.1-0.3-0.2-0.5c0.2-0.6-0.1-1-0.6-1.1c-2.1-0.7-2.3-1.1-0.8-2.8c2.6-3,5.2-6,7.8-9
		c1.4-1.6,4-1.9,5.6-0.8c0.8,0.5,0.8,1.3-0.1,1.9c-2.1,1.5-3.7,3.5-4.9,5.9c-0.3,0.5-0.7,0.9-1.1,1.2c-0.5,0.4-1.8,0.3-1.6,1.1
		c0.3,1.1,1.3,0.1,2,0.3c0.2,0,0.4,0,0.6-0.1c1.2,0.2,2.1,1.1,3.5,0.9c2.2-0.4,3.7,1.3,5.5,2.1c1.3,0.7,0.5,2.2,0.8,3.3
		c0,0.1,0.2,0.2,0.3,0.3c1.1,2.2,1.9,2.4,3.3,0.5c0.6-0.8,1.2-1.3,2.2-1.1c0.2,1.1,0.1,2.2,0.9,3.1l0,0c0.2,0.5,0.4,0.5,0.6,0.1
		c0.2-0.3,0.3-0.6,0.5-0.9c2.3-1.3,2.7-1.2,2.5,1.4c-0.2,2,1.1,2.7,2.3,3.5C160.1,120.9,161.5,122.6,161.7,125.2z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('st10')} onMouseLeave={()=> {handleMouseLeave('st10')}} d="M139.2,137.3c-0.1,0.1-0.3,0.3-0.4,0.5l0,0C139,137.6,139.1,137.4,139.2,137.3
		C139.2,137.3,139.2,137.3,139.2,137.3z"></path>
	<path class="st11" onMouseEnter={() => handleMouseEnter('st11')} onMouseLeave={()=> {handleMouseLeave('st11')}} d="M181.8,98.9C181.8,98.9,181.8,98.9,181.8,98.9C181.8,98.9,181.8,98.9,181.8,98.9L181.8,98.9z"></path>
	<path class="st1" onMouseEnter={() => handleMouseEnter('16')} onMouseLeave={()=> {handleMouseLeave('16')}} d="M251.1,159.5c-2.7-1.8-5.1-3.7-5.2-7.3c-0.5-0.2-1.1-0.9-1.7-0.2c-0.4,0.5,0.1,1,0.3,1.5
		c0.9,2.2,0.2,4.2-1.6,4.3c-1.9,0-3.2,0.8-4.4,2.3c-1,1.2-2.2,1.8-3.3-0.1c-0.6-1.1-1.3-1.1-2.1-0.2c-0.6,0.6-1.5,1-2.2,0.2
		c-0.7-0.9-1.6-0.9-2.4-0.7c-2.5,0.4-4.8-0.3-6.9-1.6c-0.6-0.5-1.2-1.1-1.9-1.5c-0.8-0.5-1.9-1.2-2.8-0.8c-0.8,0.4,0.1,1.5-0.1,2.3
		c-0.3,1.7-1.4,1.9-2.8,1.4c-1.9,1.5-3.7,3.1-5.5,4.6c-0.1,3,2.1,1.1,3.3,1.6c0.5,0,1.1,0,1.7,0.1c0.9-0.2,1.4,0.1,1.3,1.1
		c0,0.2,0.1,0.3,0.1,0.5c0.5,0,0.7,0.3,0.6,0.8c0.7,2.4,1.2,4.8,3.6,6.4c1.5,1,2.7,2.7,3.2,4.5c0.5,1.8,1.4,2.1,3,1.7
		c3.3-0.6,6.7-1.1,10-1.9c3.8-0.8,7.7-1,11.4-2.4c1.1-1.5,2.2-2.9,3.3-4.5c1-1.4,1.7-2.8-0.5-4c-1.2-0.6-1.3-1.7-0.1-2.7
		c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.3,0.1-0.3c-0.1-1.2,1-1.6,1.6-2.3C252.1,161.1,252.2,160.2,251.1,159.5z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('14')} onMouseLeave={()=> {handleMouseLeave('14')}} d="M214.6,170.1c0,1,0,1.6-1.3,1.9c-2.9,0.8-5.7,1.3-8.6,1.8c-3.8,0.7-7.9,1.5-10.7,4.8c-0.4,0.1-0.5,0.4-0.5,0.8
		c-0.9,1.1-0.9,1.1,0.8,2c-2,2-4.8,2.5-7.2,3.8c-0.9,0.5-2.1-0.1-3.1-0.4c-0.8-0.2-1.5-0.5-2.3-0.4c-0.5-2.1,1.6-3.7,0.8-5.9
		c-1.4-1.7-0.2-2.7,0.9-3.9c0.6-0.6,1.5-1,1.7-2c0.3-0.3,0.6-0.5,0.8-0.8l0,0c0.1,0,0.8-0.2,1.3-0.2l0,0c0.3,0.2,0.8,0.2,1.3,0.2
		c0.6,0,0.9-0.3,0.9-0.9c0-0.7-0.1-1.4,0-2.1c0.3-1.1,0.6-2.5,2.3-1.8c3,1.3,3,1.3,3.5-1.9c0-0.3,0.2-0.7,0.3-1
		c2.3-1.7,4.8-3.3,6.2-5.9c0.2-0.3,0.5-0.6,0.8-0.6c2.4,0.3,2.8-0.9,3-2.9c0.2-1.9,1.7-2.4,3.5-1.6c1.6,0.8,3.2,1.7,4.8,2.6
		c-0.4,0.5,1.2,1.4-0.1,1.6c-2.1,0.3-3.1,2.3-4.7,3.4c-0.9,0.6-2.1,1.6-2.1,3.1c-0.8,1.6,0.2,2.7,1.1,3.7c0.8,1,1.8,0,2.6-0.4
		c0.8-0.4,1.4-0.4,2.2-0.2C213,168.2,214.6,168.6,214.6,170.1z"></path>
	<path class="st11" onMouseEnter={() => handleMouseEnter('st11')} onMouseLeave={()=> {handleMouseLeave('st11')}} d="M100.1,116L100.1,116c0.5,0.1,0.9,0.2,1.4,0.3C101.1,116.2,100.6,116.1,100.1,116z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('st10')} onMouseLeave={()=> {handleMouseLeave('st10')}} d="M119.7,85.5c0.1,0.1,0.2,0.3,0.3,0.3C119.9,85.8,119.8,85.7,119.7,85.5z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('04')} onMouseLeave={()=> {handleMouseLeave('04')}} d="M166.4,222.9c-0.6,0.3-1,0.8-1.3,1.3l0,0c-0.3,0.3-0.5,0.6-0.8,0.8l-3.9,4v-0.1c-0.5,0.3-1.1,0.2-1.9-0.5
		c-0.2-0.2-0.6-0.2-0.8-0.1c-2.5,1.8-4-0.5-5.5-1.6c-3.5-2.9-7-5.8-10.9-8.3c-0.1-1-1.1-1.4-1.7-2.1c-0.6-0.7-1.3-1.4-0.4-2.3
		c0.8-0.8,1.4-2.1,2.9-1.3c1.9,1.1,3.8,2.1,6.1,1.4c1.1-0.3,1.8,0.4,2.3,1.1c1.8,2.5,4.4,2.8,7.1,2.4c1.2-0.2,2.1-0.1,3,0.8
		c0.9,0.9,2,1.3,3.3,1.5C165.7,220,167.3,222.4,166.4,222.9z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('25')} onMouseLeave={()=> {handleMouseLeave('25')}} d="M140.4,83.7c-0.8,3.1-2,6.1-4.1,8.6c-3.7,4.5-7.5,9-11.5,13.3c-0.5,0.6-1.2,1.2-1,2.2c0.2,1.3-0.3,2.3-1.1,3.2
		c-0.3,0.3-0.8,0.7-0.3,1.2c0.3,0.4,0.9,0.8,1.3,0.5c1.6-1.2,3,0.6,4.5,0.1c0.3-0.1,0.7,0,0.8,0.4c0.1,0.5-0.3,0.8-0.6,1
		c-0.6,0.2-1.2,0.3-1.8,0.4c-0.7,0-1.6,0-1.8,0.7c-0.5,1.3-1.1,0.9-1.9,0.5c-1.1-0.5-2.1-1.2-3.1,0.1c0.3,0.9,1.9,0.5,1.6,1.7
		c-0.2,0.7-1,0.4-1.5,0.5h0c-0.3-0.8-0.9-1.1-1.6-0.5s-0.4,1.2,0.4,1.6l0,0.1c0.2,1.1-0.8,1.6-1,2.5c-1.3-0.2-2.4-0.8-3.5-1.5
		c-1.9-1.1-3.9-2.1-6.1-2.3c-1.3,0-2.5,0-3.7,0c-0.5-1.3-1.7-0.6-2.6-0.8c-0.8-0.1-1.8,0-1.7-1.2c0.3,0,0.5,0.1,0.7,0.2
		c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.6-0.4-1.2-0.5-1.8c-0.2-1.5-1-2.2-2.5-2c-2.2,0.3-3.2-0.7-3.4-2.9c-0.1-1.9-0.7-3.5-1.8-5
		c-0.8-1.2,0-1.8,1.1-2.1c1.3-0.3,2.4-0.5,2.9-2c1.6-2.1,0.9-4.3,0.3-6.4c-0.2-0.7-1.1-1.4-0.4-2.1c0.7-0.7,1.6-0.1,2.3,0.3
		c0.8,0.5,1.3,0.5,2-0.1c1.5-1.3,3.1-1.3,4.8-0.4c2.3,1.1,2.7,0.8,2.7-1.7c0-2.3,4-4.9,6.1-5.1c2.9-0.3,4.3,1.1,5.8,2.9
		c0,0.4,0.2,0.6,0.5,0.8c2.1,0.7,3.8-0.2,5.5-1.3c1.9-1.3,3.8-2.3,6.2-2c0.7,0.1,1.4,0.3,2-0.3l0.1-0.1c1.1-1.3-0.1-2.7,0.2-4.1
		c0.4-0.6,0.9-0.7,1.4-0.3c1.4,1.2,1.6,0.2,1.7-0.9c0.1-0.5,0.1-1.1,0.4-1.6c0.4-0.8,1-0.9,1.4-0.1C140.1,79.5,141,81.5,140.4,83.7z"
		></path>
	<path class="st6" onMouseEnter={() => handleMouseEnter('10')} onMouseLeave={()=> {handleMouseLeave('10')}} d="M154.4,177.5c-2.9,1.6-3.3,2.9-2.7,6.4c0.3,2,0.5,4,0.7,6c0.1,0.7-0.1,1.4-0.7,1.9c-0.4,0.3-0.8,0.3-0.9-0.3
		c-0.2-1.1,0.3-2.4-1-3c-1.2-0.6-2.3,0.4-3,0.8c-1.9,1.2-3.7,1.2-5.6,1c-1.6-0.8-2.6-1.8-2.7-1.9l0,0c0.8-1.7-0.8-3.2-1.5-3.6
		c-1.7-1.2-2.1-3-2.9-4.5c-0.6-1.2-1.1-1.3-2-0.6c-1.3,0.9-2.8,1.3-4.5,1.3c-2.3,0.1-3.6-0.9-4.8-2.6c-1-1.3-1.1-2.7-0.1-4
		c1.7-2.4,1.6-4.9,0.7-7.6c1-0.2,2.1-0.2,2.9-1c1.9-1.7,3.8,0,5.6,0.2c2.5,0.3,4.4,2,6.6,3.2c3.7,2.1,7.4,3.3,11.8,3.2
		c1.3,0,2.4,0.4,3.6,0.8l0,0l0,0l0.5,0.3l1.2,0.7C156.8,176.1,156.8,176.1,154.4,177.5z"></path>
	<path class="st9" onMouseEnter={() => handleMouseEnter('st9')} onMouseLeave={()=> {handleMouseLeave('st9')}} d="M180.6,167.7c0.1,0.1,0.3,0.3,0.4,0.4C180.8,168,180.7,167.8,180.6,167.7z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('st8')} onMouseLeave={()=> {handleMouseLeave('st8')}} d="M182.3,171c-0.3,0-0.4-0.2-0.4-0.4C182,170.8,182.2,170.9,182.3,171z"></path>
	<path class="st1" onMouseEnter={() => handleMouseEnter('st1')} onMouseLeave={()=> {handleMouseLeave('st1')}} d="M123.4,138.2c0.1,0.1,0.3,0.3,0.4,0.4C123.7,138.4,123.5,138.3,123.4,138.2z"></path>
	<path class="st12" onMouseEnter={() => handleMouseEnter('19')} onMouseLeave={()=> {handleMouseLeave('19')}} d="M217.9,123.3c-1-0.5-2.2-0.8-2.7,0.8c-0.3,0.8-0.8,1.3-1.6,1c-0.7-0.3-0.6-1.1-0.5-1.7
		c0.1-0.6,0.3-1.3,0.7-1.7c2.6-3.7,0.3-7.8-2.8-9.3c-1.1-0.5-1.3-1.1-1-2.1c0.2-1,0.5-2.1-0.3-3c-0.7-0.7-1.2-1.4-1.3-2.5
		c-0.1-1.1-1-1.1-1.6-0.4c-0.8,0.8-1.7,1.1-2.9,1.3c-2.4,0.3-3.8,1.9-3.8,4.4c0,1.2-0.4,1.9-1.2,2.7c-1.1,0.9-2.2,1.8-3.2,3
		c-1.6,2-1.8,4.3-2.4,6.5c-0.3,1-0.6,1.9-1.7,1.8c-2.2,0-2.4,1.5-2.6,3.1c-0.1,0.9,0,1.8,0,2.7c0,0.5,0.2,1.1-0.5,1.2
		c-0.4,0-0.8-0.1-1-0.4c-0.1-0.2-0.3-0.2-0.5-0.1c-0.3,0.2-0.5,0.4-0.8,0.5l0,0c-0.2,0.2-0.7,0.3-0.9,0.7c-0.8,1.5-1.8,2.8-2.3,4.4
		c0,0.2-0.3,0.5-0.4,0.4c-2.9-0.5-3.7,2.5-5.7,3.4c-1.6,0.7-2.2,1.9-2.2,3.6c0,0.5,0,1.1-0.4,1.6c-0.7,0.9-1.7,1.8,0.1,2.8
		c0.6,0.3,0.5,1.6-0.2,2.2c-0.7,0.7-0.8,1.6-0.8,2.5c0.3,0.1,0.2,0.1,0.5,0.2c0.8-1,1.6-1.3,2.7-0.5c0.5,0.4,1.1,0.5,1.8,0.4
		c1.2-0.1,1.8,0.5,2.2,1.5c0.5,1.2,0.9,2.1,2.5,1.2c1.1-0.6,2.7-0.4,3.4,0.5c1.1,1.6,1.6,2.1,1.8,2.2c0,0,0,0.1,0,0.1
		c0,0.9,1.1,0.9,1.5,1.6l3.4,2.6c2.7,1.5,3.3-0.7,4.3-2.2l0,0c1.5-0.8,1.6-2.4,1.9-4c1.2,1.5,2.9,0.4,4.2,1c0.3,0.2,0.5-0.3,0.4-0.7
		c-0.3-2.4,0.3-3.5,2.5-3.8c1-0.2,1.2-0.4,1.3-1.6c0-1.6-0.3-3.2,0.6-4.8c1.1-2.2,3.2-3.1,4.9-4.5c1.1-1,2.8-1.5,2.7-3.4
		c-2.5-0.9-1.4-3.2-1.6-4.8c-0.2-1.1,0.7-1.4,1.6-1.5c-0.1-1.2,0.3-2.2,1.3-2.9c1.1-0.7,1.4-1.9,1.9-2.9
		C219.7,125.1,219,123.8,217.9,123.3z M196.8,160.4C196.8,160.4,196.9,160.4,196.8,160.4c0.1-0.1,0.1-0.1,0.1,0
		C196.9,160.3,196.9,160.4,196.8,160.4z M197.4,160.3C197.4,160.1,197.3,160,197.4,160.3C197.4,160.3,197.4,160.3,197.4,160.3z
		M197.9,159.3L197.9,159.3c-0.8-0.2-0.8-0.8-0.6-1.4C198.1,158.1,197.7,158.8,197.9,159.3L197.9,159.3L197.9,159.3z"></path>
	<path class="st9" onMouseEnter={() => handleMouseEnter('st9')} onMouseLeave={()=> {handleMouseLeave('st9')}} d="M197.9,159.3c-0.8-0.2-0.8-0.7-0.6-1.4C198.1,158.1,197.7,158.8,197.9,159.3z"></path>
	<path class="st9" onMouseEnter={() => handleMouseEnter('st9')} onMouseLeave={()=> {handleMouseLeave('st9')}} d="M197.9,159.3L197.9,159.3L197.9,159.3z"></path>
	<path class="st9" onMouseEnter={() => handleMouseEnter('28')} onMouseLeave={()=> {handleMouseLeave('28')}} d="M196.5,111.9c-2.8,1.6-4.3,3.8-4.3,7c0,0.6,0,1.4-0.9,1.7c-3.1,1.1-3.8,3.4-3.6,6.3c0,0.2-0.1,0.4-0.1,0.6
		l-3.3,2.7c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.3c-0.3,0.6-0.5,1.3-1,1.9c-0.6,0.7-1.4,1.1-2.3,0.2
		c-1.7-1.8-3.8-2.1-6.1-0.7c-1,0.8-4.6,1.1-5.4,0.4c-0.6-0.5-0.4-1.1-0.4-1.8c0-2.5,0-5-0.1-7.5c0-0.1,0.1-0.3,0.1-0.4
		c-0.2-0.7-0.6-0.9-1.3-0.8l-0.1-0.1c-0.8-1.2-1.6-1-2.4,0.1l-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-2.1-2.2-4.3-4.4-6.4-6.7c-0.5-0.5-0.3-1.2-0.3-1.8c0-0.4,0.2-0.8,0.6-0.6
		c2.1,0.7,4.3-0.7,6.4,0.7c0.9,0.6,1.9,0,1.8-1.4c3.7-1.1,3.1-4.5,4-7.2c0.5,0,1,0,1.4,0c3.8,0,3.8,0,4-3.8c0.1,0,0.3-0.1,0.4-0.1
		c1.4,0.1,2.9,0.3,2.7-1.9c0-0.5,1-0.6,1.6-0.4c1.3,0.5,2.7,1.1,3.3,2.4c1.1,2.6,3.4,3.5,5.9,3.7c2.8,0.2,5.3,1.2,7.9,2
		C198.4,108.5,198.3,110.8,196.5,111.9z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('st10')} onMouseLeave={()=> {handleMouseLeave('st10')}} d="M141.4,89.3L141.4,89.3L141.4,89.3L141.4,89.3L141.4,89.3z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('st10')} onMouseLeave={()=> {handleMouseLeave('st10')}} d="M141.4,89.3L141.4,89.3L141.4,89.3L141.4,89.3L141.4,89.3z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('27')} onMouseLeave={()=> {handleMouseLeave('27')}} d="M175.5,91.3c0,1.1-0.3,2.2-0.2,3.3c0.1,1.7-1.3,1.5-2.2,2c-1.6,0.9-2.4,2.4-3.1,4c-0.1,0.2-0.3,0.3-0.4,0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5l0,0c-0.8,0.3-0.9,0.9-1,1.6c-0.7,0.2-0.9,0.7-0.7,1.3c0,0.3,0,0.5,0,0.8c-0.3,0-0.6,0-0.9,0.1l-0.1,0
		l-0.1,0c-0.3,0.5-0.7,1.1-1.1,1.6c-0.1,0-0.3,0-0.4,0l0,0c0,0.1,0,0.3,0,0.4c-1.5,0.9-0.2,2.7-1.3,3.7c-0.6-1.1-1.5-0.5-2.2-0.2
		c-1.1,0.4-2.1,0.8-2.7-0.8c-0.2-0.5-0.9-0.8-1.2-0.3c-0.9,1.5-2,0.6-3.1,0.4c-1.9-0.4-4-0.3-5.6,0.7c-1.9,1.1-1.6,0.2-1.5-1.1
		c0.6-0.5,1.2-1.1,0-1.6l-0.1-0.1c-1-0.7-2.5,0.1-3.3-1.2l0,0c0-0.5-0.3-0.8-0.8-0.8l0,0c-0.3-0.3-0.6-0.5-0.9-0.8
		c0,0,0.1-0.1,0.1-0.2c0,0-0.1,0.1-0.1,0.2c-0.3,0-0.5,0-0.8-0.1c-1.1,0.1-2.2-0.1-3.2,0.3c-0.9,0.3-1.5,0-1.4-1.1
		c1.9-2.2,2.9-5,5.9-6.4c1.1-0.5,0.5-2.7-0.8-3.5c-0.3-0.2-0.7-0.2-1-0.4c-0.9-0.6-3,0.3-2.9-1.2c0.2-1.7,0.9-3.7,3.3-3.9l0,0l0,0
		l0,0l0,0c0.4-2.4,0.5-4.8,1.1-7.1c0.3-1,0.2-2.3-1-2.9c-1.1-0.6-0.4-1.7-0.8-2.5c-0.4-0.4-0.8-0.8-1.1-1.1c-1,0.7-2.6-1-3.2,0.8
		c-0.5,1.3-1.5,1.8-2.7,2.1l0,0c-0.2,0.3-0.4,0.5-0.6,0.7c-0.1,0.5-0.2,1.1-0.2,1.7c-0.5,0.8-0.3,1.6-0.3,2.4c0,0-0.1,0.1-0.2,0.1
		c-1.8,0.1-3.8-0.7-5.4,0.6c-2.1,1.8-4.4,2.5-7,1.8l-0.1,0c-0.1-0.1-0.2-0.3-0.3-0.3c-0.3-1.4-1.1-2.1-2.5-2c-0.5,0-1.2,0-1.3-0.7
		c-0.2-0.7,0.6-0.8,0.9-1.2c0.5-0.7,1.9-0.1,1.9-1.5c2.3-0.5,3.8-2.5,5.9-3.4c0,0,0,0,0,0c0.6,0,0.8-0.3,0.8-0.8
		c0-0.1,0.1-0.1,0.2-0.1c4.8-5.1,10.2-8.9,17.9-6.8c0.3,0.1,0.5,0.1,0.8,0.1c2.8-0.5,3.7,1.2,4,3.5l0,0.2c1.1,0.2,1.5-0.4,1.6-1.4
		c0.4-2.4,0.5-2.6,2.7-1.3c4.9,2.9,9.8,5.7,14.5,8.8c1.9,1.2,2.4,3.5,3.2,5.6l0,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.9,2.1,1,4.9,4,5.6
		C175.3,90.4,175.5,90.8,175.5,91.3z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('13')} onMouseLeave={()=> {handleMouseLeave('13')}} d="M194.3,164.3c-0.6,1.2-1.3,1.9-2.9,1.4c-1.9-0.5-2.9,0.5-3,2.5c0,0.9-0.3,1.6-0.8,2.3c-0.2,0.2-0.4,0.3-0.5,0.5
		l0,0c-0.1,0-0.9-0.1-1.4-0.2l0,0c-0.1-0.1-0.3-0.2-0.5-0.3c-1.6-0.5-2.5-1.6-3.4-2.9c-0.3-0.3-0.5-0.5-0.8-0.8l0,0h0
		c-0.3-0.5-0.8-0.7-1.4-0.8c-3.6-0.6-3.5-0.7-3.6-4.3c0-1.1,0.7-2.5-0.8-3.4c-0.1-1.6-1.6-2.9-0.8-4.5c0.4-0.1,0.4-0.5,0.4-0.8
		c0.5,0,1.2-0.1,1.4,0.1c1.2,1.7,4,0.9,4.8,3.1c0.1,0.2,0.7,0.2,1.1,0.1c3.2-0.8,3.2-0.8,4.9,2c-0.1,0.8,0.4,1.6,0.8,1.9l4.8,2.4
		C192.6,163.5,194.1,163.3,194.3,164.3z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M144.6,196.1c0,0.1-0.1,0.2-0.2,0.3l-0.1-0.1C144.4,196.2,144.5,196.2,144.6,196.1z"></path>
	<path class="st10" onMouseEnter={() => handleMouseEnter('st10')} onMouseLeave={()=> {handleMouseLeave('st10')}} d="M133.2,111.9c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0.1-0.1,0.2-0.2l0,0C133.1,111.7,133.2,111.8,133.2,111.9z"></path>
	<path class="st12" onMouseEnter={() => handleMouseEnter('20')} onMouseLeave={()=> {handleMouseLeave('20')}} d="M178.2,134.8c-0.5,1.7-1.4,3-3.1,3.8c-1.1,0.5-1.8,1.6-1.6,2.9c0.2,1.4-0.4,2.7-1.6,3.5
		c-1.1,0.7-0.8,1.3-0.1,2c0.9,0.8,1.4,1.3,0.3,2.6c-0.8,1.1-0.5,2.9,0,4.5c0.5,1.2,0.1,1.7-1.2,1.5c-3.2-0.4-6.1,0.3-8.8,2.1
		c-0.3,0.2-0.5,0.4-0.5,0.8c-1.2,0.2-2.3-0.1-3.3-0.8c-0.8-1.4-1.9-2.6-3.4-3.4c0-0.6-0.4-0.8-1-0.8c-2.4-0.1-4.9-0.3-7.2,0.2
		c-1.1,0.2-1.9,0-2.7-0.7c0-0.1,0-0.3-0.1-0.4c-0.1-1.1-0.8-0.9-1.6-0.7c-2.4,0.7-5.1-1.1-5.1-3.7c0-1.2-0.9-2.1-0.9-3.4
		c0-1.9,0.6-3.2,2.1-4.1c0.1,0,0.3,0,0.4,0c1.5,0,0.6,1.1,0.8,1.7c0.1,0.4,0.2,0.9,0.8,0.7c0.5-0.1,0.5-0.6,0.5-1
		c-0.1-0.8-1-1.8,0.3-2.1c1.2-0.3,2.2,0.5,2.5,1.7c0.5,1.7,2,2,3.7,3c-0.7-2-0.9-3.3,1.3-4c0.8-0.3,1.4-1.8,1.9-2.9
		c0.4-1,0.1-1.6-0.7-1.9c-0.4-0.2-0.8-0.4-1.1-0.7c-0.1-0.1-0.3-0.2-0.6-0.2l0,0c-1-0.2-1.3-0.8-1.1-1.6c0.3-1,1.1-0.9,1.9-0.9
		c0.3,0,0.5,0,0.8,0c0.8,0.8,1.9,0.5,2.8,0.4c2.2-0.5,4.6-0.8,6.6-1.9c1.7-0.9,2.4-2.8,3-4.6c0.2-0.6,0.7-1.1,1.3-1.1
		c0.9-0.2,1.8-0.3,2.6-0.5c0.8-0.3,0.5,0.4,0.6,0.6c0.6,1.7,0.5,3.5,0.3,5.2c-0.3,3.3,0.1,3.9,3.2,3.8c2.2-0.1,4.5,0.6,6.7-0.8
		c0.3-0.2,1-0.2,1.3,0.3C178.6,133.9,178.3,134.3,178.2,134.8z"></path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('st8')} onMouseLeave={()=> {handleMouseLeave('st8')}} d="M166.4,222.9l-1.3,1.3C165.5,223.8,165.9,223.3,166.4,222.9z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M105.3,195l-1.3-0.9c0,0-0.1-1.4,0.1-1.4s2.1,0.5,2.1,0.5L105.3,195z"></path>
	<path class="st7" onMouseEnter={() => handleMouseEnter('st7')} onMouseLeave={()=> {handleMouseLeave('st7')}} d="M195.4,191.9L195.4,191.9L195.4,191.9z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M184.6,199.7L184.6,199.7L184.6,199.7c-0.1,0-0.3,0.1-0.4,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0L184.6,199.7
		L184.6,199.7L184.6,199.7z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M184.6,199.7c-0.1,0-0.3,0-0.4,0c0,0,0,0-0.1,0H184.6z"></path>
	<path class="st2" onMouseEnter={() => handleMouseEnter('st2')} onMouseLeave={()=> {handleMouseLeave('st2')}} d="M144.6,196.1c0,0.1-0.1,0.2-0.2,0.3l-0.1-0.1C144.4,196.2,144.5,196.2,144.6,196.1z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M17,181.2c-0.8-0.3-0.7-1-0.7-1.6L17,181.2z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M25.4,158.3c-0.4-0.2-0.6-0.5-0.5-0.9C24.9,157.4,25.1,157.8,25.4,158.3z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M27.4,161.2c-0.6,0-1.1-0.3-1-1.2c-0.6-0.3-0.8-0.8-0.9-1.5C26,159.3,26.7,160.5,27.4,161.2z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('24')} onMouseLeave={()=> {handleMouseLeave('24')}} d="M88.7,93.9c-0.5,0-0.8,0.3-0.8,0.8c-0.4,0-0.8,0.1-1.3,0.1c-1.4,0.1-2.9,0.2-4.3,0.4c-0.6,0.1-1.6,0.1-1.7,0.8
		c-0.1,0.7,0.7,1.1,1.4,1.3c2.2,0.8,2.5,2.6,2.6,4.5c0,0.6-0.1,1-0.8,1.3c-2.9,0.9-2.9,1-2.4,4.3l-0.1,0c-1.6-0.3-3.2-0.6-4.8-0.9
		l0,0c-1.7-1.7-5.1-1.2-6.1,0.8c-0.2,0.9-0.1,1.8,0.8,2.3c0.9,0.5,1.6,0,2.2-0.6c0.6-0.6,1.4-0.9,2.3-0.9l0,0c1-0.1,3.6,1.7,3.6,1.7
		l0,0c0.3,0,0.7,0,1.1,0c1.5,0.6,0.3,1.7,0.4,2.5c-0.6,1.4-0.5,2.7,0.6,4c0.8-2.3,1.8-0.4,2.7-0.3c0.6,0.6,1.3,1.1,2.1,1.5
		c1,0.5,1.9,1.3,0.5,2.4c-1.2,0.9-1.1,2.1-0.3,3.1c0.8,1.1,0.6,2-0.3,2.7c-1.3,1.1-1.1,2.2-0.3,3.5c0.4,1.1,0.9,2.1-0.5,3
		c-2.4,1.5-2.3,2.5,0.2,4.1c0.3,0.6,0.7,1.2,1,1.9c0.3,0.5,0.6,1.1,0.2,1.8c-0.5,0.7-1.1,0.8-1.9,0.7c-0.4,0-0.8-0.3-1.1-0.5
		c-4.2-1.2-8.2-2.6-12.5,0c-2.2,1.3-4.8,1.9-7.5,1.5c-2.1-0.3-3.4-1.8-5.3-2.3c-2.5-0.8-4.1-0.1-4.6,2.5c-0.1,0.6-0.1,1.4-0.8,1.6
		c-1,0.2-1.1-0.8-1.2-1.4c-0.4-1.3-1.1-1-2.1-0.6l-1.6,1.7c0-0.3,0.2-0.5,0-0.8c-0.4-1.2-1.1-1.9-2.4-2.1c-0.5-0.1-1.3,0-1.3-0.4
		c-0.2-2-2.4-2.5-2.9-4.2c0-0.7,0-1.5,0.1-2.2l0,0c1.5-0.2,3-0.2,4.5-0.5c0.9-0.2,1.6-0.8,1.8-1.8c0.1-0.5-0.3-1.2-0.8-0.6
		c-1.1,1.3-2.4,0.8-3.6,0.9c-0.1,0-0.1-0.1-0.2-0.2c-1-1.3-1.7-0.5-2.5,0.3l0,0c-0.2,0.1-0.3,0.2-0.5,0.3c-1.1,0.6-2.2,0.8-3.1-0.2
		c-0.5-0.6-1.6-1.3-1.3-2.1c0.3-1,1.6-0.3,2.4-0.6c0.5-0.2,1.3,0.1,1.3-0.6c0-0.5-0.7-0.5-1.1-0.8c-1.2-0.7-3-1-2.3-3.1l0,0
		c0.8-0.3,1.1-1,1.1-1.9c0-1.4-0.3-2.8-0.6-4.1c-0.5-1.9-0.3-3.7,1.1-5.1c0.8-0.8,1.7-1.6,2.4-2.6c0.5-0.9,1.6-1.1,2.4-0.6
		c1.1,0.6,0.5,1.5,0.1,2.1c-0.8,1.3-0.8,2.2,0.2,3.6c1,1.4,2.7,3.1,1,5.3c1.1-0.2,1.9-0.7,2.5-0.2c1.7,1.4,2.9,0,4.1-0.7
		c0.9-0.5,1.3-1.4-0.2-1.9c-1.3-0.4-1.5-1.1-0.5-2.1c0.3-0.3,1.1-0.2,0.9-0.9c-0.2-0.5-0.8-0.4-1.3-0.4c-0.5-0.1-1.1,0-1.2-0.5
		c-0.1-0.5,0.3-0.9,0.7-1.2c0.5-0.3,1.6-0.1,1.3-1c-0.3-0.8-1.2-0.5-1.9-0.5c-3.8,0.2-5.1-1-5-4.8c1.7-1.3,3.3-2.6,2.5-5.1
		c-0.3-1,0.4-1.9,1.1-2.6c0.7-0.7,1.2-0.9,1.6,0.3c0.8,2.5,2.9,3.8,4.6,5.6c0.4,0.5,1,0.6,1.5,0.3c0.7-0.4,0.3-1,0.1-1.5
		c-0.3-0.8-0.6-1.6-1-2.3c-0.3-0.6-0.6-1.2,0.1-1.7c0.5-0.4,1.1-0.2,1.5,0.2c0.5,0.5,1.1,0.6,1.6,0c0.3-0.3,0.8-0.7,0.6-1.3
		c-0.3-0.7-0.9-0.5-1.4-0.5c-0.8,0-1.6-0.3-1.7-1.2c-0.2-1,0.4-1.5,1.3-1.9c1.8-0.7,3.5-1.6,5.3-2.4l0,0c1.3-0.2,1.9-1.2,2.6-2.1
		c0.6-0.6,1.2-1.1,1.8-1.7c3.2-3,6.2-6.2,10.8-7.2c1.6-0.3,2.3,0,3.1,1.2c1.5,2.4,1.9,2.4,3.5,0.2c0.4-0.6,1-1.2,1.4-0.5
		c1.1,1.9,3,3.1,4.3,4.8c0.4,0.5,0.6,1.3-0.3,1.5c-1.5,0.3-1,0.9-0.2,1.4C89.3,91.5,89.3,92.6,88.7,93.9z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M88.8,93.6c0,0,0.3,0.9-0.9,1.1c-1.3,0.1-1.7-1.7-1.7-1.7L88.8,93.6z">
	<div className="text-hover">hello</div>
	</path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M70.9,179.8C70.8,179.8,70.8,179.8,70.9,179.8L70.9,179.8L70.9,179.8z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M25.6,178.6c0,0.7-0.5,1.3-0.8,1.9C25,179.8,25.3,179.1,25.6,178.6z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M26.4,177.6c-0.1,0.3-0.4,0.6-0.7,0.9C26.1,177.9,26.4,177.6,26.4,177.6z"></path>
	<path class="st0" onMouseEnter={() => handleMouseEnter('st0')} onMouseLeave={()=> {handleMouseLeave('st0')}} d="M99.1,185.9l-0.3,0.3l-0.8-0.5l-0.3-0.2l-0.8-0.5c0,0,0.2-0.8,0.3-0.8c0,0,1.4,0.3,1.5,0.5
		C98.6,184.7,99.1,185.9,99.1,185.9z">
			<div className="text-hover">hello</div>
		</path>
	<path class="st8" onMouseEnter={() => handleMouseEnter('st8')} onMouseLeave={()=> {handleMouseLeave('st8')}} d="M180.2,167.6c0,0,0.8,0.2,0.8,0.6s-0.8,0.4-0.8,0.4V167.6z"></path>
	<path class="st4" onMouseEnter={() => handleMouseEnter('21')} onMouseLeave={()=> {handleMouseLeave('21')}} d="M131,150.7c-0.3,1.2-0.6,2.4-2.7,1.2c-0.7-0.4-1.4,0.5-1.5,1.4c-0.1,0.8,0,1.7,0.1,2.5c0.3,2.4,0.8,4.8,0.7,7.2
		c-1.7,0.7-2.9,2.7-5.3,1.7c-0.7-0.3-0.6,0.8-0.4,1.3c0.4,1.4,0.3,2.8,0.3,4.2c0,2.3-0.6,4.3-2.6,5.6c-2.5,0-2.5,0-3.2-3.8
		c-0.4,0.2-0.7,0.5-1,0.5c-1.6-0.1-4.3-3.6-4.1-5.2c0-0.2,0.1-0.4,0.1-0.6c0.2-2.1-0.5-3.6-1.9-3.4c-1.8,0.3-1.6,1.8-1.7,3.1
		c0,5.6-0.3,5.8-5.6,4.1c-4-1.3-6.1-6-4.3-9.6c0.3-0.7,0.8-0.9,1.5-0.4c1.7,1.2,3.5,1.1,5.4,0.9c0.7,0.2,1.3,0,1.7-0.6
		c0.9,0,1.3-0.8,1.9-1.1c0.6-0.4,1-0.8,0.5-1.7c-1.6-2.4-1.5-2.5,1.1-3.5c0.4-0.1,0.8-0.3,1.1-0.5c1.1-0.5,1.7-1.1,0.5-2.1
		c-1-0.9,0.1-1.3,0.5-1.8c0.3-0.3,0.3-0.5-0.1-0.7l-0.1-0.1c0-0.5-1.1-0.4-0.8-1.2c0.7-0.4,1.5,0.2,2.1-0.4c1.9-0.5,2.8-2.5,4.6-3.2
		c0.8,0.1,1.6,0.1,2.5,0.2c0.4,0.3,0.9,0.4,1.4,0.5l1.9-1.1l0.3,2.1c0,0.1,0.1,0.2,0.1,0.3c0,0,0.5,0,0.8,0c1.2-0.6,2.4-0.2,3.6,0
		c0.4,0.4,0.8,0.7,1.1,0.7l1.3,2.7C130.9,150,130.9,150.3,131,150.7z">
			<div className="text-hover">hello 4</div>
		</path>
	</svg>
	
    </div>
	// </div>
  );
}

export default SingaporeMap;
