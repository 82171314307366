import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import LDSIcon from '../../assets/img/services-icon2.png';
import EVFYLogo from '../../assets/img/evfy-logo2.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import CSVfile from "../../assets/files/DeliveryTemplate.xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { 
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  InputLabel,
  Select,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import Settingimg from '../../assets/img/icons/setting-balck.svg';
import SettingWhite from '../../assets/img/icons/setting.svg';
import Filterimg from '../../assets/img/icons/filter.png';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';

import JobPreviewDialog from './Partial/JobPreviewDialog.jsx';
import BatchUpdateAssignForm from './Partial/BatchUpdateAssignForm.jsx';
import BatchUpdateJobStatusForm from './Partial/BatchUpdateJobStatusForm.jsx';
import {RoleAccessService} from '../../data/role-access';
import HtmlParser from 'react-html-parser';
import OrderDetailForm from '../../views/Dashboard/OrderDetailForm';

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}
 
export class AutoAssignList extends Component {
  static contextType = AuthContext;
  rowData =null;
  pages = 1;
  limits = 50;
  search = '';
  do_number = '';
  uploder_id = '';
  constructor(props){
    super(props); 
    this.pageName = 'jobList';
    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;


    let rangeDate = {
      startDate: moment(),
      endDate: moment().add(2,'days')
    };
    // let rowData = null;
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }
    // var selectRow = [];
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));
    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      current_status_name: "",
      total_current_status_job: 0,
      dateRangePickerIsOpen: false,
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,

      isLoading: false,
      grouping: true,
      data: [],
      total: 0,
      selectedRow:[],
      openDialogBatchUpdate:false,
      openDialog: false,
      openDialogMassDelete: false,
      openDialogItem: null,
      openDialogAutoAssign:false,
      invalidAddress:[],

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      openBatchUpdateisLoading: false,

      openSettingsDialog: false,
      autoAssign: false,
      defaultGroupOrder: -1,

      isTourOpen: false,
      openSkipDialog: false,

      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,

      tabValue: 0,
      uploaders: [],
      selected_uploader: '',
      copyDialogOpen: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,

        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true,
        drop_off_contact_name:true,
        order_number: true,
        do_number: true,
        drop_off_postal_code: true,
        drop_off_address: true,
        driver_notes: true,
        driver_payout: true,
        document: true,
        boxes: true,
        pallets: true,
        cartons:true
      }
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;
    // console.log("render");
    if(isOnBoarding){
      // console.log("render in if function");
      this.setState({
        isTourOpen: true,
      });
    }
    this.loadStatusApi(() => {
      // console.log("render in status api");
      this.callReadApi();
    }); 


  }

  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = data.map((item, i) => {
          return {
            id: item.status_details.id,
            status: item.status_details.status,
            text: item.status_details.status,
            total: item.total_job,
          };
        });
  
        let tableStatus = status;
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }





 
  
  callReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        page: this.pages,
        take: this.limits,
        start_date: startDate,
        end_date: endDate,
        pending_assign: true,
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              uploaders: (data.uploaders && data.uploaders.length > 0) ? data.uploaders : [],
              total: data.total,
              isLoading: false,
            });

            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              uploaders:[],
              isLoading: false,
            });
          });
      });
    });
  }


  unAssignDriverDialog = () => {
    return <CustomDialog 
      open={this.state.openDialogUnassign}
      title={'Reject Orders'}
      onClose={() => {
        this.setState({
          openDialogUnassign: false,
        });
      }}
      onOk={() => {
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "not assigned");
        let selectedRow = this.rowData;
        let ids = [];
        if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });
        
        let data = {
          data: [
            {
              id: ids,
              order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
              send_notification_to_customer: true,
              drop_off_worker_id: null,
              extra_worker_ids: null,
            }
          ]
        };
        this.callMassUpdateStatusApi(data, (newRows) => {
          if(newRows){
            let data = this.state.data
            newRows.map(row => {
              data = apiUtil.updateRow(data, row, 'id');
            })
            this.setState({
              data: data,
              openDialogUnassign: false
            }, () => {
              this.callReadApi()
            });
            this.Updatedtoast()
          }
        });
      }}
    >
      <Box>Are you sure you want to reject selected orders?</Box>
    </CustomDialog>
  }

  assignDriverDialog = () => {
    return <CustomDialog 
      open={this.state.openDialogAssign}
      title={'Approve Orders'}
      onClose={() => {
        this.setState({
          openDialogAssign: false,
        });
      }}
      onOk={() => {
        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "assigned");
        let selectedRow = this.rowData;
        let ids = [];
        if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });
        
        let data = {
          data: [
            {
              id: ids,
              order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
              send_notification_to_customer: true,
            }
          ]
        };
        this.callMassUpdateStatusApi(data, (newRows) => {
          if(newRows){
            this.setState({
              openDialogAssign: false
            },() =>{
              this.callReadApi()
            });
            this.Updatedtoast()
          }
        });
      }}
    >
      <Box>Are you sure you want to aprove selected orders?</Box>
    </CustomDialog>
  }
  
  Updatedtoast = () => {
    apiUtil.toast('Successfully Updated', 'check_circle');
  }

 

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */

  callMassUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;
    //console.log(row.data[0])
    if (!row.data[0].id.length > 0) {
      apiUtil.toast('You must select order!', 'warning', 'error');
      return
    }
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : null;
            
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
              openDialogAssign: false,
              openDialogUnassign: false
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

 

  

  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const {  isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */



  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    if(!this.state.isLoading) {

      return <Table 
      ref={this.refTable}
      className={'job-table-list table-last-child own-job' }  
      isLoading={this.state.isLoading}
      isDraggable={false}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      selection={true}
      onPageChange={(page,limit) => {
          // this.setState({
          //   limit:limit,
          //   page:page
          // }, () => {
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        this.rowData = rows;
      }}
      Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Job Number",
          field: "order_number",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },
          hidden: this.state.hiddenColumns.includes('order_number'),
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
              {row.order_sequence !== null ?
              <span className="sequence-circle">{row.order_sequence}</span>: ""}
              <div className={row.order_sequence !== null ? "float-right" : ""}>
                <Box pb={2}>
                  <CustomButton
                    className={'underline'}
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({
                        openPreviewDialog: true,
                        openPreviewDialogItem: row.id,
                        // openPreviewDialogItem: row.drop_off_worker_id,
                        // openPreviewDialogItem: row.drop_off_worker.first_name
                      });
                    }}
                  >
                    {(row && row.order_number) ? row.order_number : ''}
                  </CustomButton>
                </Box>
                  <Box>
                  <CustomButton
                    isShouldEnable={true}
                    className={'custom-status status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
                    text={(row && row.order_status && row.order_status.status !== '') ? row.order_status.status+'   ' : ''}
                    items={this.state.tableStatus}
                    
                  >  {row.order_status.status} </CustomButton>       
                </Box>
              </div>
            </Box>;
          } else {
              return row;
            }
          }
        },
        { 
          title: "DO Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('do_number'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.do_number && row.do_number !== '') ? HtmlParser(row.do_number) : '';
            } else {
              return row;
            }
          }
        },
        ... this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')? [{ 
          title: "Driver Payout", 
          field: "driver_payout",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('driver_payout'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_payout && row.driver_payout !== '') ? HtmlParser(row.driver_payout) : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Driver", 
          field: "drop_off_worker_name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('driver'),
          defaultGroupOrder: this.state.defaultGroupOrder,
          render: (row, type) => {
            if(type === 'row'){
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0){
                if(!row.drop_off_worker.last_name){
                  row.workerName = row.drop_off_worker.first_name;
                }else{
                  row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
                }
              }

              return <Box> {row.workerName} </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Manpower", 
          field: "extra_workers[0].first_name",
          hidden: this.state.hiddenColumns.includes('manpower'),
          render: (row, type) => {
            if(type === 'row'){
              let label = '';
              let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

              if(manpowerName){
                label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
              }

              row.manpowerName = label;

              return <Box> {row.manpowerName} </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "POD Time", 
          field: "drop_off_time",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('drop_off_time'),
          render: (row, type) => {
            if(type === 'row'){
              return (row.order_status && row.order_status.status && row.order_status.status.toString().toLowerCase().trim().replace(' ', '-') === 'completed') ? HtmlParser(row.drop_off_time) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Date", 
          field: "drop_off_date",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          hidden: this.state.hiddenColumns.includes('drop_off_date'),
          render: (row, type) => {
            if(type === 'row'){
              // console.log("yse", row.drop_off_date.split(" "))
              // console.log("yse", splitdate[0])
              if(row && row.drop_off_date && row.drop_off_date !== '') {
                var splitdate = row.drop_off_date.split(" ")
                return splitdate[0];
              } else {
                return '';
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Time Window", 
          field: "drop_off_time_planned",
          hidden: this.state.hiddenColumns.includes('drop_off_time_planned'),
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_time_planned && row.drop_off_time_planned !== '') ? HtmlParser(row.drop_off_time_planned) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: (<img src={Settingimg} width='30px' />),
          cellStyle: {
            minWidth: 180,
            maxWidth: 180,
            position: 'sticky',
            right:0,
            zIndex:11,
            overflow: 'visible',
            backgroundColor: '#FFFFFF',
            
          },
          // align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
              <Box clone>
                  <Grid item xs={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) &&<IconButton                 
                       onClick={(e) => {
                        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "assigned");
                        let ids = [row.id];
                        let data = {
                                data: [
                                  {
                                    id: ids,
                                    order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                                    send_notification_to_customer: true,
                                  }
                                ]
                              };
                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              this.Updatedtoast()
                              this.callReadApi()
                            }
                          });
                              
                        }}
                    >
                      <span  data-tooltip="Approve Job" data-tooltip-conf="danger">
                      <FontAwesomeIcon icon={faCircleCheck} component={'i'} style={{ fontSize: '25px', color: '#007CDE' }} className="tooltip"/>
                      {/* <Icon component={'i'} style={{ fontSize: '25px', color: '#007CDE' }} className="tooltip">check_circle</Icon> */}
                      </span>
                    </IconButton>
                  }
                  </Grid>
                  </Box>
                  <Box clone>
                  <Grid item xs={'auto'}>
                  {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) &&<IconButton                 
                       onClick={(e) => {
                        let orderStatusItem = apiUtil.customFilter(this.state.status, 'status', "not assigned");
                        let ids = [row.id];
                        
                        let data = {
                                data: [
                                  {
                                    id: ids,
                                    order_status_id: (orderStatusItem) ? orderStatusItem.id : '',
                                    send_notification_to_customer: true,
                                    drop_off_worker_id: null,
                                    extra_worker_ids: null,
                                  }
                                ]
                              };
                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              this.Updatedtoast()
                              this.callReadApi()
                            }
                          });
                        }}
                    >
                      <span  data-tooltip="Reject Job" data-tooltip-conf="danger">
                      <FontAwesomeIcon icon={faCircleCheck} component={'i'} style={{ fontSize: '25px', color: '#FF6600' }} color="primary" className="tooltip" />
                      {/* <Icon component={'i'} style={{ fontSize: '25px', color: '#FF6600' }} color="primary" className="tooltip">cancel</Icon> */}
                      </span>
                    </IconButton>
                  }
                  </Grid>
                  </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  handleTabChange = (event, newValue) => {
    this.setState({
        tabValue: newValue
    })
  }
  customToolbar = (e) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let currentRole = window.localStorage.getItem("current_role")

    let isOwner = false;
    if(user != null){
       isOwner = user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;
    }

    return <Box>
      <Grid container>
        <Grid container item  xs={12} md={12} justify='flex-end' style={{marginBottom: '2em'}}>
          <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}} style={{paddingLeft:'5px', paddingRight:'5px'}}>
              <Grid item xs={6} md={3} lg={2} xl={2}>
                  <DropdownMenu
                  text={'Actions'}
                  color={'primary'}
                  className={'job-list-action'}
                  leftIcon={<img src={SettingWhite} style={{width:20,marginTop:"3px"}}/>}
                  rightIcon={<ArrowDownIcon />}
                  items={this.getItemforAction()}
                  onClick={(item) => {
                    switch(item.id) {
                      case 1:
                        this.setState({
                          openDialogAssign: true
                        })
                        break;
                      case 2:
                        this.setState({
                          openDialogUnassign: true
                        })
                        break;
                      default:
                        break;
    
                  }
                  }}
                />
                
              </Grid>
          </Box>  
        </Grid>
      </Grid>
    </Box>
  }

  getItemforAction = () => {
  
    let item = [];

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete')){
      item.push({          
          id: 1,
          text: <Box display={'inline-block'} >Approved</Box>,          
      })
    }

    if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') && this.state.currentRole !== "Customer Template Role") {
      item.push({          
      id: 2,
      text: <Box display={'inline-block'} >Rejected</Box>,          
      })
    }
    return item;

  }


  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        onSave={() => {
          this.callReadApi();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
        if(this.props.onClose) {
          this.props.onClose();
        }
        
        }}
    /> 
    </CustomDialog>
    }
  }

  autoAssignDialog = () => {
    return  this.setState({
      autoAssign: true
    })
  }



  render() {
    return <Box className="job-list-page" >
    {  this.state.autoAssign? <Card>
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
      <CircularProgress size={35} />
    </Box>
  </Card>: <>
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>

      <Card className={'job-listing-step'}>
        {/* {this.state.isLoading ? "isloading ...":this.customTable() } */}
        {this.customTable()}
      </Card>
      
      {this.assignDriverDialog()}
      {this.unAssignDriverDialog()}
      {this.previewDialog()}
      </> }
	  <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>


    </Box>;
  }
}