import React, { useState } from 'react';
// import Geocode from "react-geocode";
import { compose, withProps, lifecycle } from 'recompose';
import { GoogleMap, Marker , InfoWindow, withScriptjs, withGoogleMap} from "react-google-maps"
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import CustomInput from '../CustomFields/CustomInput.jsx';
import CircleGreen from '../../assets/img/icons/circle-green.svg'
import CircleRed from '../../assets/img/icons/circle-red.svg'
// import { InfoWindow } from '@react-google-maps/api';
import { 
  Box,
  Grid,
} from '@material-ui/core';

import apiUtil from '../../api/apiUtil.jsx';


// Geocode.setApiKey(apiUtil.getGoogleMapApiKey());


const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };

const GOOGLE_STYLE = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#666666"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const CustomGoogleMap = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
          let center = (this.props.center) ? this.props.center : DEF_CENTER;

          this.setState({
            getRefs: () => {
              return refs;
            },
            getCenter: () => {
              return center;
            },
            onMapMounted: ref => {
              refs.map = ref;
            },
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onMarkerMounted: ref => {
              refs.marker = ref;
            },
            onDragEnd: ref => {
              let position = refs.marker.getPosition();
              let pos = {
                lat: position.lat(),
                lng: position.lng(),
              };

              // Geocode.fromLatLng(pos.lat, pos.lng).then(
              //   response => {
              //     const address = response.results[0].formatted_address;
              //     console.log("Geocode address", address);
              //   },
              //   error => {
              //     console.error(error);
              //   }
              // );

              if(this.props.onDragEnd){
                this.props.onDragEnd(pos);
              }
            },
            onPlacesChanged: () => {
              let places = refs.searchBox.getPlaces();
              
              center = ((places && places[0]) ? { lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() } : null);

              if(this.props.onPlacesChanged){
                this.props.onPlacesChanged((places && places.length > 0) ? places[0] : null);
              }
            },
          })
        },
      }),
    withScriptjs,
    withGoogleMap
  )(props => {
    const [className] = useState(props.className ? props.className : '');
    const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
    const [placeholder] = useState(props.placeholder ? props.placeholder : '');
    const [value, setValue] = useState(props.value ? props.value : '');
    const [startAdornment] = useState(props.startAdornment ? props.startAdornment : null);
    const [endAdornment] = useState(props.endAdornment ? props.endAdornment : null);
    const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 11);
    const [showSearch] = useState((props.showSearch === true || props.showSearch === false) ? props.showSearch : false);
    const [markers, setMarkers] = useState((props.data === null || props.data === '') ? [] : props.data);
    const [selectedMarker, setSelectedMarker] = useState("");

    React.useEffect(() => {
      setCenter(props.center ? (((props.center.lat !== 0) || (props.center.lng !== 0)) ? props.center : DEF_CENTER) : DEF_CENTER);
    }, [ props.center ]);

    const googleMap = () => {
      return <div>
        <GoogleMap
          ref={props.onMapMounted}
          onCenterChanged={props.onCenterChanged}
          defaultZoom={defaultZoom}
          defaultCenter={DEF_CENTER}
          defaultOptions={{
            disableDefaultUI: true,
            disableDoubleClickZoom: false,
            rotateControl: false,
            styles: GOOGLE_STYLE,
        }}
          center={center}
        >
          {searchBox()}
          {/* {(center) && <Marker 
            ref={props.onMarkerMounted}
            onDragEnd={props.onDragEnd}
            draggable={true}
            position={center}
          />} */}
          {markers && markers.length > 0 &&
          markers.map((marker) => 
          // let color = (marker.job_type == "Delivery") ? '#e94560' : '#4E79A7';
          marker.drop_off_latitude != null && marker.drop_off_longitude != null ?
          <Marker
          options={{
            icon: marker.job_type == "Delivery" ? CircleGreen : CircleRed,
            anchor: new window.google.maps.Point(5, 58),
            scaledSize: new window.google.maps.Size(37, 37)
          }}
          onClick={()=> {setSelectedMarker(marker)}}
          ref={props.onMarkerMounted}
          onDragEnd={props.onDragEnd}
          draggable={true}
          position={{lat: marker.drop_off_latitude, lng: marker.drop_off_longitude}}
        /> : []
          )}
            {selectedMarker != "" && <InfoWindow
                  position={{lat: selectedMarker.drop_off_latitude , lng: selectedMarker.drop_off_longitude}}
                  onCloseClick={() => {
                      setSelectedMarker("")
                  }}>
                    <div  style={{
                      width: '250px',
                      backgroundColor: 'white',
                      borderRadius: '6px',
                      overflow: 'hidden',
                      padding: '5px',
                  }}>{selectedMarker.drop_off_address}</div>
              </InfoWindow>}
        </GoogleMap>
      </div>
    }
    
    const searchBox = () => {
      if(showSearch){
        return <div>
          <SearchBox
            ref={props.onSearchBoxMounted}
            onPlacesChanged={props.onPlacesChanged}
            controlPosition={1}
          >
              <Box p={2}>
                <CustomInput
                  className={"custom-google-search " + className}
                  value={value}
                  placeholder={placeholder}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Box>
          </SearchBox>
        </div>
      }
    }

    return googleMap();
});


export default CustomGoogleMap;