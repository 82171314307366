import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";

import {
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as InformationIcon } from '../../../assets/img/icons/information.svg';

import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';
import { CodeOutlined } from '@material-ui/icons';


/**
 * @todo
//  * 1. create
//  * 2. update
 * 3. contact no. preset +65 
//  * 4. formValidatedation - show error. password hide text
   5. ask user to enter two password and display error message if the passwords doesn't match.
 * 6. ux enter key should move to next input
 */

export const LogisticForm = () => {
    const location = useLocation();
    const history = useHistory();
    let Lid = (location && location.state && location.state.id && location.state.id > 0) ? location.state.id : 0;

    const { accessToken } = useContext(AuthContext)
    const [id, setId] = useState(Lid);
    const [isLoading, setIsLoading] = useState(false);
    const [isLogisticLoading, setIsLogisticLoading] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [checkName, setCheckName] = useState(null);
    const [firstNameValidated, setFirstNameValidated] = useState(false)
    const [lastName, setLastName] = useState("")
    const [lastNameValidated, setLastNameValidated] = useState(false)
    const [contactNo, setContactNo] = useState("")
    const [contactNoValidated, setContactNoValidated] = useState(false)
    const [userName, setUserName] = useState("")
    const [disabled, setDisabled] = useState()
    const [userNameValidated, setUserNameValidated] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordValidated, setPasswordValidated] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    const [resultData, setResultData] = useState([]);

    useEffect(() => {
        callReadApi();
        if (id && id > 0) {
            callDetailApi(id)
        }
    }, [])
    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                <Box clone>
                    <Grid item xs={'auto'}>
                        <IconButton
                            onClick={() => {
                                history.push('/resources/logistic-assistance');
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={'auto'}>
                        <Divider orientation={'vertical'} />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={true}>
                        <CustomLabel
                            className={'no-margin'}
                            label={(id > 0) ? 'Edit Driver Assistant' : 'Add New Driver Assistant'}
                            weight={'bold'}
                            size={'md'}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={'auto'}>
                        <CustomButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={isLoading}
                        >
                            <Box pl={2} pr={2}>Submit</Box>
                        </CustomButton>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }

    const callReadApi = useCallback((search = '') => {
        let param = {
            search: search,
            is_man_power: true,
            require_worker_only: true
        };
        setIsLoading(true);
        const getData = Api.read('workers', param, accessToken)
            .then((results) => {
                apiUtil.parseResult(results, (data) => {

                    var json_data = results.data.data.result;
                    var result_data = [];
                    for (var i in json_data) {
                        result_data[i] = json_data[i].email;
                    }
                    setResultData(result_data);

                    setIsLoading(false);
                }, (error, type) => {
                    setIsLoading(false);
                });
            });
    }, [accessToken])


    const callDetailApi = (id = '', callback = null) => {
        setIsLogisticLoading(true);
        Api.details('workers', id, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;
                    if (result) {
                        setIsLogisticLoading(false);
                        setFirstName(result.first_name)
                        setLastName(result.last_name)
                        setCheckName(result.email);
                        setContactNo(result.contact_no)
                        setUserName(result.email)
                        setDisabled(result.disabled)
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        setIsLogisticLoading(false);
                        if (callback) {
                            callback(null);
                        }
                    }
                }, (error, type) => {
                    setIsLogisticLoading(false);
                    if (callback) {
                        callback(null);
                        apiUtil.toast(error, 'error');
                    }
                });
            })
    }
    const callUpdateApi = (id = '', row, callback = null) => {
        setIsLoading(true);
        Api.update('workers/' + id, row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/logistic-assistance');
                        apiUtil.toast('Successfully Updated', 'check_circle');
                    }
                }, (error, type) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        apiUtil.toast(error.email, 'error');
                    }
                });
            }).catch(
                error => console.log(error)
            )

    }
    const callCreateApi = (row, callback = null) => {
        setIsLoading(true);
        Api.create('workers', row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/logistic-assistance');
                        apiUtil.toast('Successfully Created', 'check_circle');
                    }
                }, (error, type) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        let err = error.email && error.email.length > 0 ? error.email[0] : "something wrong"
                        apiUtil.toast(err, 'error');
                    }
                });
            })
    }
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const form = e.currentTarget;

        if (firstName === '') {
            setFirstNameValidated(true)
        }
        if (lastName === '') {
            setLastNameValidated(true)
        }
        // if (contactNo === '') {
        //     setContactNoValidated(true)
        // }
        let phoneRegEx = /^[+]?\d+$/;
        if (!phoneRegEx.test(contactNo) || contactNo === '' || contactNo.length < 8 || contactNo.length > 13) {
            setContactNoValidated(true)
            setIsLoading(false)
            return
        } 
        if (userName === '') {
            setUserNameValidated(true)
        }
        if (password === '' && id === 0) {
            setPasswordValidated(true)
        }

        var isD = "false";
        resultData.filter(function (item, pos) {
            if (id && id > 0) {
                if (item == userName && item != checkName) {
                    isD = "true";
                }
            } else {
                if (item == userName) {
                    isD = "true";
                }
            }
        }); 

        if (form.checkValidity() === true && isD === "false") {
            // console.log("disabled" , disabled)
            // return
            let data = {
                contact_no: contactNo.length === 8 ? '+65' + contactNo : contactNo,
                disabled: id? disabled : false,
                email: userName,
                first_name: firstName,
                is_man_power: true,
                last_name: lastName,
                max_job_perday: 0,
                password: password,
                priority: 0
            }

            if (id && id > 0) {
                callUpdateApi(id, data, () => {
                    setIsLoading(false);
                })
            } else {
                callCreateApi(data, () => {
                    setIsLoading(false);
                })
            }
        } else {
            if (isD === "true") {
                apiUtil.toast('User Name already Exit', 'check_circle');
            }
            // else {
            //     apiUtil.toast('Field invalid', 'check_circle');
            // }
            setIsLoading(false)
        }
    }
    return <Box className="transport-form-page" >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>

            <Container maxWidth="lg">
                <Card>
                    {
                        !isLogisticLoading ?
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'Driver Assistant Information'}
                                            weight={'bold'}
                                            size={'md'}
                                        />
                                        <Divider light />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Container maxWidth={'sm'}>
                                            <Box clone p={5}>
                                                <Grid container>

                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'First Name*'}
                                                                placeholder={'Enter Driver first name'}
                                                                value={firstName}
                                                                error={firstNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={(e) => {
                                                                    setFirstName(e.target.value)
                                                                    setFirstNameValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Last Name*'}
                                                                placeholder={'Enter Driver last name'}
                                                                value={lastName}
                                                                error={lastNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={(e) => {
                                                                    setLastName(e.target.value)
                                                                    setLastNameValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Phone Number*'}
                                                                placeholder={'Enter phone number'}
                                                                value={contactNo}
                                                                required={true}
                                                                error={contactNoValidated}
                                                                errorText={'Phone number is invalid'}
                                                                autoFocus={true}
                                                                onChange={(e) => {
                                                                    setContactNo(e.target.value)
                                                                    setContactNoValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>

                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'Driver Assistant Account'}
                                            weight={'bold'}
                                            size={'md'}
                                        />
                                        <Divider light />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Container maxWidth={'sm'}>
                                            <Box clone p={5}>
                                                <Grid container>

                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Username*'}
                                                                placeholder={'Enter username'}
                                                                value={userName}
                                                                error={userNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={(e) => {
                                                                    setUserName(e.target.value)
                                                                    setUserNameValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Password*'}
                                                                className={'custom-password'}
                                                                placeholder={'Enter password'}
                                                                autoComplete={'new-password'}
                                                                required={id === 0 ? true : false}
                                                                value={password}
                                                                error={passwordValidated}
                                                                type={passwordType}
                                                                endAdornment={(obj) => {
                                                                    return <Box
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            setPasswordType((passwordType === 'password') ? 'text' : 'password')
                                                                        }}
                                                                    >
                                                                        {
                                                                            (passwordType === 'password')
                                                                                ?
                                                                                <i className={'far fa-eye-slash'}></i>
                                                                                :
                                                                                <i className={'far fa-eye'}></i>
                                                                        }
                                                                    </Box>
                                                                }}
                                                                onChange={(e) => {
                                                                    setPassword(e.target.value)
                                                                    setPasswordValidated((e.target.value !== '') ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    {/* <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Confirm Password'}
                                                        placeholder={'Enter password'}
                                                        value={''}
                                                        onChange={() => {
                                                            
                                                        }}
                                                    />
                                                </Grid>
                                            </Box> */}
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomAlert
                                                                icon={<InformationIcon />}
                                                            >
                                                                SMS will be sent to your driver assistant to notify about their new account
                                                            </CustomAlert>
                                                        </Grid>
                                                    </Box>

                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                                <CircularProgress size={35} />
                            </Box>
                    }
                </Card>
            </Container>

        </form>
    </Box>
}
