import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../api/apiUtil.jsx';
import authApi from "../../api/Authentication.jsx";
import PageLayout from './PageLayout.jsx';
import CustomerPageLayout from './CustomerPageLayout.jsx';
import {RoleAccessService} from '../../data/role-access';
import PageNotFound from '../../views/PageNotFound/PageNotFound';
const RouteLayout = ({ component: Component, menu_name: menu_name, layout: Layout, ...rest }) => {
  const { authenticated, setAuthenticated, currentRole, refreshToken, token, setToken, setAccessToken, setRefreshToken, setTokenType, clearStorage, isOnBoarding } = useContext(AuthContext)

  const grantedMenu = new RoleAccessService();
  const isShouldAccess = grantedMenu.isShouldShowMenu(currentRole, menu_name);
  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date(Date.now() + (3 * 60 * 1000))) {
        // Changing from false to true
        setAuthenticated(true);
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }


  React.useEffect(() => {
    document.body.className = "";
    document.body.classList.add(rest.path.replace("/", "").toLowerCase());
    console.log("triggger!!")
    checkToken();
  }, [currentRole]);

  {currentRole != 'Customer Template Role'?
  Layout = Layout || PageLayout
  :
  Layout = Layout || CustomerPageLayout
  }

  if(currentRole != 'Customer Template Role') {
  // if(authenticated && isShouldAccess){
    if(authenticated && isShouldAccess &&  menu_name !== "client"){
      return (
        <Route {...rest} render={matchProps => (
          <Layout isFluid={rest.isFluid} isOnBoarding={isOnBoarding}>
            <Component {...matchProps} isFluid={rest.isFluid} />
          </Layout>
        )} />
      )
    } else if(authenticated){
      return (
        <Route {...rest} render={matchProps => (
            <Layout isFluid={rest.isFluid} isOnBoarding={isOnBoarding}>
              <PageNotFound {...matchProps} isFluid={rest.isFluid} />
            </Layout>
        )} />
      )
    } else {
      return (
        <Redirect to="/sign-in" />
      )
    }
  } else {
    if(authenticated && (menu_name == "client" || menu_name == "settings")){
      return (
        <Route {...rest} render={matchProps => (
          <Layout isFluid={rest.isFluid} isOnBoarding={isOnBoarding}>
            <Component {...matchProps} isFluid={rest.isFluid} />
          </Layout>
        )} />
      )
    } else if(authenticated){
      return (
        <Route {...rest} render={matchProps => (
            <Layout isFluid={rest.isFluid} isOnBoarding={isOnBoarding}>
              <PageNotFound {...matchProps} isFluid={rest.isFluid} />
            </Layout>
        )} />
      )
    } else {
      return (
        <Redirect to="/sign-in" />
      )
    }
}
};

export default RouteLayout;