import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from "../Auth/AuthDataProvider.jsx";
import Footer from "../../views/Footer/Footer.jsx"

import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/logo_text_white.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket, faDownload, faFileLines, faGear, faMessage, faChartColumn, faFileCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import {RoleAccessService} from '../../data/role-access'; 
const CustomMenuItem = (props) => {
  let {text, icon, url, active, items, onDrawerToggle} = props;
  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      // onClick = {() => onDrawerToggle? onDrawerToggle(): ""}
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <Link to={(items && items.length > 0) ? "" : url}
        onClick={(e) => {
          // e.stopPropagation();

          if(items && items.length > 0){
            e.preventDefault();
            setOpen(!open);
            
            if(onDrawerToggle){
              onDrawerToggle()
            }
          }
        }}
        className="d-flex flex-column text-center"
      >
        {icon ? <div className="icon-style">{icon}</div> : <span className={'no-icon'}></span>}
        <div className="nav-text">{text}</div>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </Link>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;
  
  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  } 

  // get the the item list of resource for new role
  getResourceItemListForNewRole = (currentRole) => {
    let grantedMenu = new RoleAccessService();

    // 'Transport operator', 'Logistic assistance', 'Vehicle'
    let item_list = [] ;
    if( ! grantedMenu.admin_roles.includes(currentRole)) {
      if (grantedMenu.getUniqueRolePermission().includes('transport_operator')) {
          item_list.push(<CustomMenuItem
            onDrawerToggle={this.props.onDrawerToggle} 
            text={'Driver'}
            url={'/resources/transport-operator'}
            active={(this.props.location.pathname === "/resources/transport-operator" ? true : false)}
          />,
          <CustomMenuItem
            onDrawerToggle={this.props.onDrawerToggle} 
            text={'Driver Assistant'}
            url={'/resources/logistic-assistance'}
            active={(this.props.location.pathname === "/resources/logistic-assistance" ? true : false)}
          /> );
      }
      if (grantedMenu.getUniqueRolePermission().includes('resources_vehicle')) {
        item_list.push( <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle}   
          text={'Vehicle'}
          url={'/resources/vehicle'}
          active={(this.props.location.pathname === "/resources/vehicle" ? true : false)}
        /> );
      }
      return item_list;
    }
    return item_list = [
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle} 
        text={'Driver'}
        url={'/resources/transport-operator'}
        active={(this.props.location.pathname === "/resources/transport-operator" ? true : false)}
      />,
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle} 
        text={'Driver Assistant'}
        url={'/resources/logistic-assistance'}
        active={(this.props.location.pathname === "/resources/logistic-assistance" ? true : false)}
      />,
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle}   
        text={'Vehicle'}
        url={'/resources/vehicle'}
        active={(this.props.location.pathname === "/resources/vehicle" ? true : false)}
      />,
    ]
  }

  render() {
    const { isCustomerRequired, currentRole, appPermission } = this.context;
    let grantedMenu = new RoleAccessService();
    return <Box>
      <div style={{minHeight:"87vh", marginTop:'76px'}} className="d-flex flex-column justify-content-between"><List component="nav" className="customer-nav-style">
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Dashboard'}
          icon={<DashboardIcon />}
          url={'/customer-dashboard'}
          active={(this.props.location.pathname === "/customer-dashboard" || this.props.location.pathname == "/job-schedule-lists" || this.props.location.pathname == "/order-summary" || this.props.location.pathname == "/order-summary-list" || this.props.location.pathname.includes("/customer-recycle-bin") ? true : false)}
        />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Custom quotation'}
          icon={<FontAwesomeIcon icon={faFileCircleQuestion} />}
          url={'/request-form'}
          active={(this.props.location.pathname === "/request-form" ? true : false)}
        />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'My Orders'}
          icon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
          url={'/new-order'}
          active={(this.props.location.pathname === "/new-order" || this.props.location.pathname === "/order-schedule" || this.props.location.pathname === "/new-order-form" || this.props.location.pathname.includes("/my-order-recycle-bin") ? true : false)}
        />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Analytics'}
          icon={<FontAwesomeIcon icon={faChartColumn} />}
          url={'/analytics/client-overview'}
          active={(this.props.location.pathname.includes("/analytics") || this.props.location.pathname.includes("/job-schedule-list")? true : false)}
        />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Invoices'}
          icon={<FontAwesomeIcon icon={faFileLines} />}
          url={'/invoice'}
          active={(this.props.location.pathname === "/invoice"? true : false)}
        />
      </List>
      <List component="nav" className="customer-nav-style">
      <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Settings'}
          icon={<FontAwesomeIcon icon={faGear} />}
          url={'/settings'}
          active={(this.props.location.pathname === "/settings"? true : false)}
        />
         <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Help'}
          icon={<FontAwesomeIcon icon={faMessage} />}
          url={'/help-center'}
          active={(this.props.location.pathname === "/help-center"? true : false)}
        />
        </List>
      </div>
    </Box>
  }

  
}

export default withRouter(LeftMenu);
