import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
import CustomCollapse from '../../components/Collapse/CustomCollapse.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import { 
  Box,
  Grid,
  IconButton
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomSwitch from '../../components/CustomFields/CustomSwitch';
import FormLabel from '@material-ui/core/FormLabel';
import Alert from '@material-ui/lab/Alert';
import JobPreviewDialog from './Partial/JobPreviewDialog.jsx';

import RouteOptimizeMap from "../../components/GoogleMap/RouteOptimizeMap.jsx";
import polyline from "@mapbox/polyline";

const { 
  REACT_APP_ROUTIFIC_API,
  REACT_APP_ROUTIFIC_API_KEY
} = process.env;

export class RouteOptimize extends Component {
  static contextType = AuthContext;
   
  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    let total_current_status_job = (this.props.location && this.props.location.state && this.props.location.state.total_current_status_job && this.props.location.state.total_current_status_job !== 0) ? this.props.location.state.total_current_status_job : 50;
    let startDate = (this.props.location && this.props.location.state && this.props.location.state.rangeDate && this.props.location.state.rangeDate.startDate && this.props.location.state.rangeDate.startDate !== '') ? moment(this.props.location.state.rangeDate.startDate) : moment().startOf('month');
    let endDate = (this.props.location && this.props.location.state && this.props.location.state.rangeDate && this.props.location.state.rangeDate.endDate && this.props.location.state.rangeDate.endDate !== '') ? moment(this.props.location.state.rangeDate.endDate) : moment().endOf('month');
    this.state = {
      rangeDate: {
        startDate: startDate,
        endDate: endDate,
      },
      unassign_id: "",
      assign_id: "",
      number_list: Array(21).fill().map((element,index) => { return {label:index, value:index}}),
      enableBalance: false,
      min_visit_number: 0,
      time_interval: 10,
      time_interval_validated: false,
      available_driver_number: 20,
      error: "",
      startTime: moment(new Date().toJSON().slice(0,10).replace(/-/g,'-') + " " + "09:00:00").format(), //"Mon Dec 28 2020 09:00:00 GMT+0800 (Singapore Standard Time)",
      endTime: moment(new Date().toJSON().slice(0,10).replace(/-/g,'-') + " " + "20:00:00").format(),//"Mon Dec 28 2020 18:00:00 GMT+0800 (Singapore Standard Time)",
      orderList: [],
      driverList: [],
      driverSelectList: [],
      optimizeData: [],
      officeLat: "",
      officeLng: "",
      officeAddress: "",
      isLoadingStatus: false,
      status:[],
      tableStatus:[],
      total_in: 0,
      total_out: 0,
      isLoading: false,
      grouping: true,
      search: "",
      limit: total_current_status_job,
      data: [],
      total: 10,
      page: 1,

      openDialog: false,
      openDeleteDialog: false,
      openDialogItem: null,
      openPreviewDialog: false,
      openPreviewDialogItem: null,
      num_unserved: 0,
      unserved:null,

      showMap: false,
      map: {
        paths: [],
        filteredPaths: [],
        center: { lat: 1.352083, lng: 103.819836 }
      }
    }
  }
  

  componentDidMount() {
    const { accessToken } = this.context;
    this.companyProfileAPICall(accessToken);
    if(moment(this.state.rangeDate.startDate).format('YYYY-MM-DD') === moment(this.state.rangeDate.endDate).format('YYYY-MM-DD')){
        this.loadStatusApi();
    } else {
      this.props.history.push('/job-list');
    }
  }

  changeArrayToObj = array => {
    let objFormat = {}
    array.map(k=> {
      let {id, ...rest} = k
      objFormat[id]= rest;
    });
    return objFormat;
  }

  callOptimizeApi = () => {

    let driverFormat = this.state.driverList.length > 0 ? this.state.driverList.slice(0,this.state.available_driver_number).map((driverItem)=> {
      let driverObj =  {
        "id": driverItem.id,
        "start_location": {
          "id": "depot",
          "lat": this.state.officeLat || "1.3298414",
          "lng": this.state.officeLng || "103.8831561"
        },
        "min_visits": this.state.min_visit_number,

        // "end_location": {
        //   "id": "depot",
        //   "lat":  this.state.officeLat || "1.3298414",
        //   "lng":  this.state.officeLng || "103.8831561"
        // }
      }
      return driverObj;
    }): [];

    const { accessToken } = this.context;

    const promises = [];
    let apiResult = [];
    let filterEmptyLatLng = this.state.orderList.map((orderItem, index) => {
      if(orderItem.job_steps[0].latitude == null || orderItem.job_steps[0].longitude == null ||  orderItem.job_steps[0].location == null) {
        let promise = this.geoCodeAPICall(accessToken, orderItem.job_steps[0].location).then((responses)=>{
            
            apiUtil.parseResult(responses, (data) => {

              let newArray = [...this.state.orderList];
              newArray[index] = {...newArray[index], job_steps: newArray[index].job_steps.map(item=>{
                item.latitude = data.result.lat;
                item.longitude = data.result.lng;
                return item;
              })}
              this.setState({orderList: newArray});
              let visitObj =  {
                "id": orderItem.order_number,
                "location": {
                  "name": orderItem.order_number,
                  "lat": data.result.lat ,
                  "lng": data.result.lng
                },
                "start": orderItem.drop_off_time_planned != null && moment(orderItem.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) !== "Invalid date" ? moment(orderItem.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()): moment(this.state.startTime).format(apiUtil.getDefaultTimeFormat()),
                "end": moment(this.state.endTime).format(apiUtil.getDefaultTimeFormat()),//this.state.endTime,
                "duration": this.state.time_interval
              }
              return apiResult.push(visitObj);
            }, (error, type) => {
              console.log(error);
            });
        });
        promises.push(promise);
        
      } else {
        let visitObj =  {
          "id": orderItem.order_number,
          "location": {
            "name": orderItem.order_number,
            "lat": orderItem.job_steps[0].latitude ,
            "lng": orderItem.job_steps[0].longitude
          },
          "start": orderItem.drop_off_time_planned != null && moment(orderItem.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) !== "Invalid date" ? moment(orderItem.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()): moment(this.state.startTime).format(apiUtil.getDefaultTimeFormat()),
          "end": moment(this.state.endTime).format(apiUtil.getDefaultTimeFormat()),//this.state.endTime,
          "duration": this.state.time_interval
        }
        return visitObj;
      }
       
    }).filter(Boolean);
    
    Promise.all(promises).then((responses) =>{
      let visitFormat = [...apiResult, ...filterEmptyLatLng];
      if(visitFormat.length > 0 && driverFormat.length > 0) {
        
        let visitFormatObj = this.changeArrayToObj(visitFormat)
        let driverFormatObj = this.changeArrayToObj(driverFormat)
        let optimizeParam = {
          "visits": visitFormatObj,
          // "drivers": {...driverFormat},
          "fleet": driverFormatObj,
          // "min_visit_number": this.state.min_visit_number,
          "balance": this.state.enableBalance
        };
        // const optimizePath = "https://erp-api-dev.moovaz.com/api/routific";
        // api.apiCallPost(optimizePath, optimizeParam, "accessToken")
        const routificUrl = REACT_APP_ROUTIFIC_API;
        const routificApiKey = REACT_APP_ROUTIFIC_API_KEY;
         api.apiCallPostWithApiKey(routificUrl, optimizeParam, routificApiKey)
          .then((result) => {
            apiUtil.parseResult(result, (resultData) => {
              // const listData = resultData.data.solution;
              const listData = resultData.solution;
              let driverSelectList = [];
              let newData = this.state.driverList.filter((driver) => {
                if (listData[driver.id]) {
                  let newOrderList = [];

                  listData[driver.id].map( (visit, index) => {
                    this.state.orderList.filter((order) => {
                      if (visit.location_id == order.order_number) {
                        newOrderList.push(Object.assign(order, {arrival_time: visit.arrival_time, finish_time: visit.finish_time, result_sequence: index}));
                      }
                    });
                  });

                  if (listData[driver.id].length > 1) {
                    driverSelectList.push({
                      value: driver.id,
                      label: driver.first_name + " " + (driver.last_name === null ? "" : driver.last_name)
                    });
                  }

                  return Object.assign(driver, {solution: listData[driver.id]}, {data: newOrderList});
                }
              });

              let filterdata = newData.filter((data) => {
                if (data.data.length > 0) {
                  return data;
                }
              });

              let jobMarkers = [];

              filterdata.forEach((driver) => {
                if (driver.data !== undefined) {
                  if (jobMarkers[driver.id] === undefined) {
                    jobMarkers[driver.id] = [];
                  }

                  driver.data.forEach((steps, index) => {
                    if (steps.job_steps !== undefined) {
                      steps.job_steps.forEach(step => {
                        jobMarkers[driver.id].push({
                          lat: step.latitude,
                          lng: step.longitude,
                          label: driver.first_name + " : " + (index + 1)
                        });
                      });
                    }
                  });
                }
              });

              // const polylineData = resultData.data.polylines;
              const polylineData = resultData.solution;
              let paths = [];
              let center = {lat: null, lng: null};

              for (let driverId in polylineData) {
                // let polyData = polylineData[driverId][0];
                let polyData = polylineData[driverId][1];
                if(polyData != null) {
                  // let pathDecode = polyline.decode(polylineData[driverId][0], 6);
                  let pathDecode = this.getPolylineLatLng(polylineData[driverId], visitFormatObj);
                  if (pathDecode.length > 0) {
                    if (paths[driverId] === undefined) paths[driverId] = [];
                    
                    if (jobMarkers[driverId] !== undefined) {
                      paths[driverId]['jobs'] = jobMarkers[driverId];
                    }
                    
                    pathDecode.forEach((data, i) => {
                      if (data[0] !== undefined && data[1] !== undefined) {
                        if (center.lat === null || center.lng === null) {
                          center.lat = data[0];
                          center.lng = data[1];
                        }

                        paths[driverId].push({
                          lat: data[0],
                          lng: data[1],
                        });
                    
                      }
                    });
                  }
                }           
              }
              
              this.setState({
                num_unserved: resultData.num_unserved,
                unserved: resultData.unserved,
                optimizeData: filterdata,
                isLoading: false,
                driverSelectList: driverSelectList,
                map: {
                  paths: paths,
                  filteredPaths: paths,
                  center: center
                }
              });
            },(error, type) => {
              this.setState({
                error: {title: error},
                isLoading: false,
                optimizeData: []
              });
            });
          })
      } else {
        if(driverFormat.length === 0) {
          this.setState({error:{title: "Driver list is empty!"}, optimizeData:[], isLoading: false});
        } else {
          this.setState({error:{title: "Job is empty on this date!"}, optimizeData:[], isLoading: false});
        }
        this.forceUpdate()
      }
    })
  }
  // Get PolyLine Lat and Lng
  getPolylineLatLng = (solution, vistGroup) => {
    let latLng = []
    
    solution.forEach((data, i) => {
      let tempData = []
      if(vistGroup[data.location_id]) {
        tempData.push(vistGroup[data.location_id].location.lat)
        tempData.push(vistGroup[data.location_id].location.lng)
        latLng.push(tempData)
      }

    })
    return latLng

  }

  // Getting Unserved Order Lists
  getUnservedOrders = () => {
   const {num_unserved, unserved} = this.state;
    return num_unserved > 0 && unserved ?
       <div>
          <p>Number of Unserved Orders: {num_unserved} </p>      
          { 
            Object.keys(unserved).map((key) => <span>{key} {unserved[key]}.<br/></span>)     
          }
          <br/>
        </div>
      : ''

  }
  
  /* API */
  geoCodeAPICall = (accessToken, address) => {
    let param = {
      address: address 
    }
    return ldsApi.create("geo_functions/geocoder_address", param, accessToken);
  }


  companyProfileAPICall = (accessToken) => {

    let param = {}
    api.read('application_companies', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        this.setState({officeAddress: data.result[0]["address"] || "71, #01-09 Ayer Rajah Crescent, 139951"})   
      }, (error, type) => {
        console.log(error);
      });
    })
  }

  handleDateChange = (search = '') => {
  }

  callReadApi = (search = '') => {
    const { accessToken } = this.context;
    this.setState({
      showMap: false,
      isLoading: true,
      num_unserved: 0,
      unserved:null
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
        start_date: moment(this.state.rangeDate.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.state.rangeDate.endDate).format('YYYY-MM-DD'),
        order_status: this.state.unassign_id, // unassign
        search: ""
      };
      //get un assign orders
      let orderAPICall = ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            //remove from optimize list if job steps is more than 1 step
            let filterJobSteps = data.result.filter(order => order.job_steps.length < 2)
            this.setState({orderList: filterJobSteps})
          }, (error, type) => {
            console.log(error);
          });
      });

      let workerAPICall = api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let filterActiveDrivers = data.result.filter(drivers => {
              if (drivers.disabled === false) {
                return drivers;
              }
            });

            this.setState({
              driverList: filterActiveDrivers,
            });
          }, (error, type) => {
            console.log(error);
          });
      });

      Promise.all([
        orderAPICall,
        workerAPICall
      ])
      .then(() => {
        
        this.companyProfileAPICall(accessToken)        
      })
      .then(()=> {
        this.geoCodeAPICall(accessToken, this.state.officeAddress).then((result)=> {
          apiUtil.parseResult(result, (data) => {
            this.setState({officeLat: data.result.lat, officeLng: data.result.lng});
          }, (error, type) => {
            console.log(error);
          });
          
        })
        .then(()=> {
          this.callOptimizeApi();
        })
      })
      .catch((err)=> {
        console.log(err); // some coding error in handling happened
        this.setState({error:{title: "Something wrong with API calls!!"}, optimizeData:[], isLoading: false});
      });
    }); 
  }

  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  rangeDate: range,
                }, () => {
                  if(moment(this.state.rangeDate.startDate).format('YYYY-MM-DD') === moment(this.state.rangeDate.endDate).format('YYYY-MM-DD')){
                    this.callReadApi();
                  } else {
                    this.setState({error:{title: "Only allow single date! Please choose date again!"}, optimizeData:[], isLoading: false});
                  }
                });
              }}
            />
          
          </Grid>
        </Box>
        
        { this.state.order_status === 679 && this.state.rangeDate.startDate === this.state.rangeDate.endDate &&
          <Box clone>
            <Grid item xs={'auto'}>
              <CustomButton 
                color={'secondary'}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/route-optimize',
                  });
                }}
              >
              Route Optimize
              </CustomButton>
            </Grid>
          </Box>
        }
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={["Optimized List", "Map"]}
              onClick={(selected, btn) => {
                if (selected === 0) {
                  this.setState({
                    showMap: false
                  });
                }

                if (selected === 1) {
                  this.setState({
                    showMap: true
                  });
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */
  /* TABLE */
  customTable = (myData) => {

    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      limit={this.state.limit}
      isDraggable={false}
      page={this.state.page}
      paging= {false}
      // total={this.state.total}
      data={myData}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Result Sequence",
          width: '50px',
          field: "result_sequence",
        },
        {
          title: "Job Number", 
          width: '200px',
          field: "order_number",
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
                <CustomButton
                  className={'underline'}
                  color={'primary'}
                  href={'/'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.setState({
                      openPreviewDialog: true,
                      openPreviewDialogItem: row.id,
                    });
                  }}
                >
                  {(row && row.order_number) ? row.order_number : ''}
                </CustomButton>
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company", 
          width: '200px',
          field: "drop_off_contact_name",
        },
        { 
          title: "Date", 
          width: '200px',
          field: "drop_off_time_planned",
          render: (row, type) => {
            if(type === 'row'){
              let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
              let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
              
              let dateTime = '';
              if(date && time){
                dateTime = date + ' - ' + time;
              } else if (date){
                dateTime = date;
              } else if (time){
                dateTime = time;
              }
              
              return <div>
                <Box>
                  {dateTime}
                </Box>
                <p>{row.job_steps[0].location}</p>
                </div>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Expected ETA", 
          width: '200px',
          field: "arrival_time",
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
              <b>ETA:</b> {(row && row.arrival_time) ? row.arrival_time : ''}  
              </Box>;
            } else {
              return row;
            }
          }
        }, 
        { 
          title: "Expected Finish Time",
          width: '200px', 
          field: "finish_time",
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
              <b>Finish Time:</b> {(row && row.finish_time) ? row.finish_time : ''}  
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "ACTION", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/jobs-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openDeleteDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDeleteDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDeleteDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        let newOptimizeData = this.state.optimizeData.filter((item) => {
          let removeIndex = item.data.map(function(item) { return item.id; }).indexOf(row.id);
          if(removeIndex >= 0){
            //re sort after delete
            item.data.forEach((element,index)=> {
              if(index > removeIndex) {
                element.result_sequence = element.result_sequence - 1;
              }
            });
            return item.data.splice(removeIndex, 1);
          } else {
            return item;
          }         
        })
        this.setState({optimizeData: newOptimizeData})
        this.forceUpdate()
      }}
    >
      <Box>Are you sure you want to remove from route optimize?</Box>
    </CustomDialog>
  }
  /* END DIALOG */

   /* TABLE */
  defaultTable = (data) => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
        });
      }}
      // Toolbar={this.customToolbar}
      columns={[
        { 
          title: "No", 
          field: "no",
          width: '40px',
          maxWidth: '40px',
          render: (row, type) => {
            if(type === 'row'){
                return <Box>
                    
                        { row.no + 1 }
                   
                </Box>;
            } else {
                return row;
            }
        }
      }, 
      {
        title: "Reference No", 
      }, 
      {
        title: "Job Date", 
      },
      {
        title: "Description", 
      },
      {
        title: "Description", 
      },
      {
        title: "Start Time", 
      },
      {
        title: "Completion Time", 
      },
      {
        title: "Address", 
      },
      {
        title: "Steps", 
      },
       
      ]}
    />
  }

  previewDialog = () => {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
    </CustomDialog>
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi();
  }

  /* API */
  callStatusApi = () => {
    const { accessToken } = this.context;
    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let result = Object.keys(data.result).map((k) => data.result[k]);
              let status = result.map((item, i) => {
                if(item.status_details.status == "Not Assigned"){
                  this.setState({unassign_id: item.status_details.id})
                } else if(item.status_details.status == "Assigned") {
                  this.setState({assign_id: item.status_details.id})
                }
                return {
                  id: item.status_details.id,
                  status: item.status_details.status,
                  text: item.status_details.status,
                  total: item.total_job,
                };
              });
            }
          });
        }).then(() => {
          this.callReadApi();
        })

    });
  }
  
  handleSubmit =() => {
    // e.preventDefault();
    const { accessToken } = this.context;
    //{"data":[{"id":[240782],"drop_off_worker_id":200,"order_status_id":674,"send_notification_to_customer":true}]}
    this.setState({
      isLoadingStatus: false
    },() => {
    let data = this.state.optimizeData.map((item) => {

      if(item.data.length > 0){
        let data = {
          id: item.data.map(function (el) { return el.id; }),
          drop_off_worker_id: item.id,
          order_status_id: this.state.assign_id,
          send_notification_to_customer: true,
          order_sequence: item.data.map(function (el) { return el.result_sequence; })
       }
       return data;
      }
    }).filter(Boolean);
   
    let param = {
      data: data
    }

    ldsApi.create('orders/assign/order', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        this.setState({
          isLoading: false,
        });
        apiUtil.toast('Successfully Updated', 'check_circle');
        this.props.history.push('/job-list');
      }, (error, type) => {
        console.log(error);
      });
    });
  });

  }
  getOrderDetail (orderId) {
    let orderDetail = this.state.orderList.filter((order) => {
      if (order.id === orderId) {
        return order;
      }
    })
    return orderDetail;
  }

  setMap = () => {
    return <Box
      width={'100%'}
      height={'600px'}
    >
      {this.setDrivers()}
      <RouteOptimizeMap 
        zoom={16}
        center={this.state.map.center}
        paths={this.state.map.filteredPaths}
      />
    </Box>
  }

  setDrivers = () => {
    return <Box clone>
      <Grid item xs={12}>
        <CustomSelectOption
        label={'Driver'}
        placeholder={'Select Driver'}
        value={0}
        initFirstItem={true}
        items={this.state.driverSelectList}
        onChange={(value, item) => {
          let map = this.state.map;
          let paths = map.paths;
          map.filteredPaths = paths[value] !== undefined ? [paths[value]] : paths;
          this.setState({
            map: map
          });
        }}
        />
      </Grid>
    </Box>
  }
  render() {
    
    return <Box className="route-optimize">
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>

      <Card>
        <Box clone>
          <Grid container>     
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    style={{marginLeft: 10}}
                    label="Start Time"
                    value={this.state.startTime}
                    onChange={(date) => {
                      this.setState({
                        startTime: date
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    style={{marginLeft: 10}}
                    label="End Time"
                    value={this.state.endTime}
                    onChange={(date) => {
                      this.setState({
                        endTime: date
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Box clone pl={3} pt={1}>
              <Grid item xs={2}>
                <FormLabel component="legend" style={{marginBottom: 10}}>Min jobs per driver</FormLabel>
                <CustomSelectOption
                  placeholder={'Select Minimum numbers'}
                  value={this.state.min_visit_number}
                  items={this.state.number_list}
                  onChange={(value, item) => {
                    this.setState({
                      min_visit_number: value,
                    });
                  }}
                  />
              </Grid>
            </Box>

            <Box clone pl={3} pt={1}>
              <Grid item xs={2}>
                <FormLabel component="legend" style={{marginBottom: 10}}>Time Interval (minutes)</FormLabel>
                <CustomInput
                  value={this.state.time_interval}
                  type="number"
                  onChange={(e) => {
                    this.setState({
                      time_interval: e.target.value
                    });
                  }}
                />
              </Grid>
            </Box>

            <Box clone pl={5} pt={1} >
              <Grid item xs={2} >
                <FormLabel component="legend">Less Driver</FormLabel>
                <CustomSwitch  
                  checked={this.state.enableBalance}  
                  onChange={ () => 
                     this.setState({enableBalance: !this.state.enableBalance})
                  }
                  label="Enable Balance"
                />
              </Grid>
            </Box>

            <Grid item xs={1} style={{padding: "20px", paddingLeft: "0px"}}>
              <CustomButton 
                  color={'secondary'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.callReadApi();
                  }}
                >
                    Optimize
              </CustomButton>
            </Grid>

            <Grid item xs={1} style={{padding: "20px", paddingLeft: "0px"}}>
              <CustomButton 
                  color={'primary'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                    Save
              </CustomButton>
            </Grid>   
          </Grid>
        </Box>        
        {
          this.getUnservedOrders() 
        }

          {this.state.showMap ?
            this.setMap()

            :
                
              this.state.optimizeData.length > 0 ? 
              <div>
                {/* <Grid container> 
                
                  <Box clone pr={1} pb={{ xs: 2, md: 2 }}>   
                  <Grid container style={{display: "block"}}>
                   <div style={{float: "right"}}>
                     <CustomButton 
                       color={'secondary'}
                       onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                     >
                       Save
                     </CustomButton>
                     </div>
                  </Grid>
                 </Box> 
                 </Grid> */}
                 {
                   this.state.optimizeData.map((renderData, index) => (
                    <React.Fragment key={index}>
                      <CustomCollapse
                        className={'route-optimize-row'}
                        item={(obj, isOpen) => {
                            return <Box clone>
                                <Grid container alignItems={'center'}>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                          <IconButton 
                                            color={'primary'}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();

                                              obj.toggle();
                                            }}
                                          >
                                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                                          </IconButton>
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={true}>
                                          <h1 className="report-header" style={{margin: "20px",fontSize: "22px",fontWeight: 400}}><b>Driver: </b>{renderData.first_name}</h1>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        }}
                        details={<Box clone p={1}>
                          <div className="customtable-wrapper">
                            {this.customTable(renderData.data)}
                          </div>
                        </Box>}
                      />
                    </React.Fragment>
                  ))
                 }
              </div>
         
              :
              
                this.state.error && !this.state.isLoading ?
              
                <Box textAlign="left" mt={3} mb={3}>
                  <Alert severity="error">
                    {this.state.error.title && <b>{this.state.error.title}</b>}
                    {(this.state.error.errors && this.state.error.errors.length > 0) && this.state.error.errors.map((e, i) => {
                      return <div key={i}>{e}</div>
                    })}
                  </Alert>
                </Box>

                :

                // this.defaultTable()
                <div>
                 { this.state.isLoading &&
                  <p>Loading ...</p>
                 }
                </div>
          }
      </Card>
      {this.customDialog()}
      {this.previewDialog()}

    </Box>;
  }
}

export default withRouter(RouteOptimize);
