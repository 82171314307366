import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import LeftMenu from '../../components/Header/LeftMenu.jsx';
import Header from '../../layout/Header.jsx';
import BackToTop from '../../components/Header/BackToTop.jsx';
import apiUtil from '../../api/apiUtil.jsx';

const drawerWidth = 86;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflowX: 'hidden'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
  },
  drawerOpen: {
    width: drawerWidth + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%) - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    // height: '76px',
  },
  appBarClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${0}px)`,
    marginLeft: drawerWidth,  
    // height: '76px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  toolbarHeight: {
      minHeight: '60px',
  },
  notoolbarHeight: {
    minHeight: '0px',
  },
  drawerPaper: {
    maxWidth: drawerWidth,
    borderRight: '0px',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    height: '60px',
    width: '100%',
  },
  nocontent: {
    flexGrow: 1,
    padding: 0,
    height: '0px',
    width: '100%',
  },
}));



const PageLayout = ({ children, ...rest }) => {
  
  const isMobile = () => {
    const width  = document.documentElement.clientWidth || document.body.clientWidth;
    return width < 768;
  }

  const isWeb = () => {
    const width  = document.documentElement.clientWidth || document.body.clientWidth;
    return width > 768;
  }

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(isMobile() ? false : true);
  
  const handleDrawerToggle = () => {
    if(isMobile()) {
    setMobileOpen(!mobileOpen);
    }
  };

  
  React.useEffect(() => {
    const updateWindowDimensions = () => {
      if(isMobile()){
        setMobileOpen(false);
      }
      if(isWeb()){
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, []);


  
  return (
    <div className={classes.root + ' container-warpper'}>
    {(!rest.isOnBoarding) && <CssBaseline />}
    {(!rest.isOnBoarding) && <AppBar elevation={0} position="fixed" 
      className={clsx(classes.appBar, {
        [classes.appBarOpen]: mobileOpen,
        [classes.appBarClose]: !mobileOpen,
      })}
      // className={classes.appBarClose}
      style={{backgroundColor:'#FFFFFF', zIndex: 1200}}
    >
      <Toolbar className={classes.toolbarHeight} style={{padding: '0px'}}>
          <Header onDrawerToggle={handleDrawerToggle} />
      </Toolbar>
    </AppBar>}
    <nav className={classes.drawer} style={{backgroundColor:"#007CDE"}} aria-label="mailbox folders">
      <Drawer
        open={mobileOpen}
        onClose={handleDrawerToggle}
        variant={isMobile() ? 'temporary' : 'permanent'}
        className={'custom-menu-left-customer ' + clsx(classes.drawer, {
          [classes.drawerOpen]: mobileOpen,
          [classes.drawerClose]: !mobileOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: mobileOpen,
            [classes.drawerClose]: !mobileOpen,
          }),
        }}
      >
        <LeftMenu className={classes.toolbar} onDrawerToggle={handleDrawerToggle} />
      </Drawer>
    </nav>
    <main className={(!rest.isOnBoarding) ? classes.content + ' mt-2': classes.nocontent + ' mt-2'} style={{width:0}}>
      <div className={(!rest.isOnBoarding) ? classes.toolbarHeight : classes.notoolbarHeight} />
      {children}
    </main>
    <BackToTop />
  </div>
  )
}

export default PageLayout;
