import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import wmsApi from "../../../api/wmsApi.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';

export class CustomerForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let company_name = (item && item.company_name) ? item.company_name : '';
    let company_code = (item && item.company_code) ? item.company_code : '';
    let address_line1 = (item && item.address_line1) ? item.address_line1 : '';
    
    let first_name = (item && item.first_name) ? item.first_name : '';
    let contact_no = (item && item.contact_no) ? item.contact_no : '';
    let email = (item && item.email) ? item.email : '';

    this.state = {
        isLoading: isLoading,
        item: item,

        company_name: company_name,
        company_nameValidated: false,
        company_code: company_code,
        company_codeValidated: false,
        address_line1: address_line1,
        address_line1Validated: false,

        first_name: first_name,
        first_nameValidated: false,
        contact_no: contact_no,
        contact_noValidated: false,
        email: email,
        emailValidated: false,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* API */
  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('customers', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
            }

            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Customer Information'}
                        weight={'bold'}
                        size={'md'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Company Name'}
                        placeholder={'Enter company name'}
                        value={this.state.company_name}
                        error={this.state.company_nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                company_name: e.target.value,
                                company_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Company Code'}
                        placeholder={'Enter company code'}
                        value={this.state.company_code}
                        error={this.state.company_codeValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                company_code: e.target.value,
                                company_codeValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomGoogleMapSearch
                        label={'Full Address'}
                        placeholder={'Enter full address'}
                        value={this.state.address_line1}
                        error={this.state.address_line1Validated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                address_line1: e.target.value,
                                address_line1Validated: (e.target.value !== '') ? false : true,
                            });
                        }}
                        onPlacesChanged={(place) => {
                            if(place){
                                let address = apiUtil.getGmapAddress(place);
                      
                                this.setState({
                                    address_line1: address,
                                    address_line1Validated: (address !== '') ? false : true,
                                });
                            } else {
                                this.setState({
                                    address_line1: '',
                                    address_line1Validated: true,
                                });
                            }
                        }}
                    />
                </Grid>
            </Box>
            <Box clone pt={2}>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Person In Charge'}
                        weight={'bold'}
                        size={'md'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Name'}
                        placeholder={'Name'}
                        value={this.state.first_name}
                        error={this.state.first_nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                first_name: e.target.value,
                                first_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Phone Number'}
                        placeholder={'Phone Number'}
                        value={this.state.contact_no}
                        error={this.state.contact_noValidated}
                        errorText={'Phone number is invalid'}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                contact_no: e.target.value,
                                contact_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Email'}
                        placeholder={'Email'}
                        type={'email'}
                        value={this.state.email}
                        error={this.state.emailValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value,
                                emailValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Edit Customer' : 'Add Customer')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        company_nameValidated: false,
        company_codeValidated: false,
        address_line1Validated: false,

        first_nameValidated: false,
        contact_noValidated: false,
        emailValidated: false,
    });
    
    if(this.state.company_name === ''){
      this.setState({
        company_nameValidated: true
      });
    }
    if(this.state.company_code === ''){
      this.setState({
        company_codeValidated: true
      });
    }
    if(this.state.address_line1 === ''){
      this.setState({
        address_line1Validated: true
      });
    }

    if(this.state.first_name === ''){
        this.setState({
            first_nameValidated: true
        });
    }
    if(this.state.contact_no === '' || this.state.contact_no.length < 8 || this.state.contact_no.length > 13 ){
        this.setState({
            contact_noValidated: true
        });
    }
    if(this.state.email === ''){
        this.setState({
            emailValidated: true
        });
    }

    if(this.state.email !== '' && !apiUtil.emailValidation(this.state.email)){
        this.setState({
            emailValidated: true
        });
    }

    if (form.checkValidity() === true) {
        this.callCreateApi(this.getForm(), (data) => {
            if(data){
                if(this.props.onSave){
                    this.props.onSave((this.state.item ? true : false), data);
                }
            }
        });
        
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        is_active:true,
        addresses:[
            {
                is_main:true,
                name: "Main Office",
                address_line1: this.state.address_line1
            }
        ],
        departments:[
            {
                contact_persons:[
                    {
                        first_name: this.state.first_name,
                        email: this.state.email,
                        contact_no: this.state.contact_no.length === 8 ? '+65' + this.state.contact_no : this.state.contact_no ,
                        delete:false
                    }
                ],
                department_name: "Main Department"
            }
        ],
        company_name: this.state.company_name,
        company_code: this.state.company_code
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default CustomerForm;