import React, { useRef, useState } from 'react';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import apiUtil from '../../api/apiUtil.jsx';
import { useHistory } from "react-router-dom";
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
//import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
//import apiUtil from '../../api/apiUtil.jsx';
import sliderTemp1 from "../../assets/img/slider_bg/dashboard.png"
import sliderTemp2 from "../../assets/img/slider_bg/fleet.png"
import sliderTemp3 from "../../assets/img/slider_bg/byd.png"
import Carousel from 'react-bootstrap/Carousel';
import trackerLogo from '../../assets/img/EVFY_Logo.png';
import logo from '../../assets/img/logo_black.png';
import signup_image from '../../assets/img/sign_up.png';
import Footer from '../Footer/Footer.jsx'


const styles = {
  error: {
    color: 'red',
    fontSize: 'small'
  }
};
export default function SignUp() {
  //const { setAuthenticated, setToken, setUser } = useContext(AuthContext);
  let history = useHistory();


  const [acceptTerm, setAcceptTerm] = useState(false);
  const [validatedAcceptTerms, setValidatedAcceptTerms] = useState(false);
  const [errorAcceptTerm, setErrorAcceptTerm] = useState(null);

  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);

  const [validatedCompanyName, setValidatedCompanyName] = useState(false);
  const [errorCompanyName, setErrorCompanyName] = useState(null);

  const [validatedFullName, setValidatedFullName] = useState(false);
  const [errorFullName, setErrorFullName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('')


  const emailInput = useRef();
  const companyNameInput = useRef();
  const fullNameInput = useRef();
  // const acceptterms = useRef();

  const label = (
    <span className="text-muted">By signing up, you agree to our &nbsp;
      <a
        className="text-muted"
        href="/terms_and_conditions"
        target="_blank"
      >
        T&Cs
      </a>
      &nbsp;and&nbsp;
      <a
        className="text-muted"
        href="/privacy_policy"
        target="_blank"
      >
        Privacy Policy
      </a>
    </span>
  )

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);

    setValidatedAcceptTerms(false);
    setErrorAcceptTerm(null);

    setValidatedCompanyName(false);
    setErrorCompanyName(null);

    setValidatedFullName(false);
    setErrorFullName(null);
   
    let emailValidated = true
    if (email === '') {
      emailValidated = false
      setValidatedEmail(true)
      setErrorEmail('Please enter your email address.');
    } else if (!apiUtil.emailValidation(email)) {
      emailValidated = false
      setValidatedEmail(true);
      setErrorEmail('Your email is invalid.');
    }

    if (companyName === '') {
      setValidatedCompanyName(true);
      setErrorCompanyName('Please enter your CompanyName.');
    }
    if (fullName === '') {
      setValidatedFullName(true);
      setErrorFullName('Please enter your FullName.');
    }
    // if (acceptTerm == false) {
    //   setValidatedAcceptTerms(true);
    //   setErrorAcceptTerm('Please accept term and condition');
    // }
    
    if (form.checkValidity() === true && emailValidated == true) {

      setIsLoading(true);

      let data = {
        "admins": [
          {
            "email": email,
            "first_name": fullName,
            "password": ""
          }
        ],
        "company_name": companyName,
        "contact_no": "",
        "email": email,
        "is_default_password": true,
        "ref_admin_id": null,
        "ref_company_id": null
      };
      authApi.signup(data)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            history.push('/sign-up-success');
          }, (error, type) => {

            setError({ title: JSON.stringify(error) });
            setIsLoading(false);
          });
        });
    }
  };

  return (
    <div className="signin fluid">
      <div className="cover">
      <div className="cover__text">
          <Carousel controls={false}>
          <Carousel.Item>
            <div className="fs-4 text-center text-white">Easy to use driver management system</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Accept jobs from Evfy and allocate to your drivers</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Eco-friendly vehicle leasing and charging support</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      </div>
        {/* <div className="footer" style={{
          height: "8vh",
          backgroundColor: "transparent",
          textAlign: "center",
          padding: "15px 13px",
          justifyContent: "center",
          display: "flex",
          alignItem: "center"
        }} >
          <p style={{ color: "#A9A9A9", paddingBottom: "4px" }}>
            <i>powered by <a target="_blank" href="https://evfy.sg" alt="evfy singapore" style={{ color: "#005cb8" }} >
              evfy</a> © {new Date().getFullYear()}</i></p>
        </div> */}
      <div className="signup" component="main" >
      <div className="top-menu">
        <div className="logo"><img src={trackerLogo} alt="" height="50px" /></div>
      </div>
        {
          error && !isLoading
          &&
          <Box textAlign="left" mt={3} mb={3} className="position-absolute w-100 alert-style">
            <Alert severity="error">
              {error.title && <b>{error.title}</b>}
              {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
                return <div key={i}>{e}</div>
              })}
            </Alert>
          </Box>


        }
        <div className={error ? "form-wrapper mt-4" : "form-wrapper"}>
          <form noValidate onSubmit={handleSubmit}>

            <Box textAlign="center" mb={1}>
              <div className="form-title font-italic">
                <em style={{fontSize:'36px', fontWeight:"bolder"}}>Get Start for free</em>
              </div>
              <div className="form-description">             
              Try Evfy for free for 30 days on unlimited number of devices. Simply fill in your details below and we will contact you for your account set up.
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="fullname"
                label="Full name"
                name="fullname"
                value={fullName}
                onChange={e=>setFullName(e.target.value)}
                autoFocus
                error={validatedFullName}
                helperText={errorFullName}
                ref={fullNameInput}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={"email"}
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={e=> setEmail(e.target.value)}
                autoComplete="email"
                error={validatedEmail}
                helperText={errorEmail}
                ref={emailInput}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="companyname"
                label="Company Name"
                id="companyname"
                value={companyName}
                onChange={e=>setCompanyName(e.target.value)}
                ref={companyNameInput}
                error={validatedCompanyName}
                helperText={errorCompanyName}

              />
              {/* <FormControlLabel
                control={<Checkbox
                  checked={acceptTerm}
                  onChange={() => {
                    setAcceptTerm(!acceptTerm)
                  }}
                  color="primary" />}
                label={label}
              /> */}
              {label}
              <span style={styles.error}>{errorAcceptTerm}</span>
            </Box>

            <Button
             style={{borderRadius:"10px", textAlign:"center", display:"flex", margin:"auto"}}
             type="submit"
             // fullWidth
             className="sign-in-btn"
              disabled={isLoading}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN UP</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
            <Box mt={2} textAlign="center">
            <div className="signin-acc" >
            <div>Already have an account?
            <a variant="contained" color="primary" href="/Sign-in">
               Login
            </a></div>
          </div>
          </Box>
          </form>
          <div className='footer-position'><Footer /></div>
        </div>
      </div>
    </div>
  );
}