import React, { Component, forwardRef, useEffect, useState, useRef, useCallback } from 'react';

import apiUtil from '../../api/apiUtil.jsx';
import ReactDOM from 'react-dom';
import { 
    Box,
    Grid,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';
import moment from 'moment';
import MaterialTable, { MTableGroupbar, MTableBodyRow, MTableHeader } from "material-table";
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';
import DoubleScrollbar from 'react-double-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'rgba(35, 49, 86, 0.8)'
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const noResults = "No results!";

const Table = React.forwardRef(({ callFunction, ...props }, ref) => {
    const isDragging = useRef(false);
    let scrollSpeed = 10; // Adjust this value to control the scroll speed
    let scrollZoneHeight = 100; // Adjust this value to control the scroll zone height
  
    let scrollIntervalId = null;

    let isLoading       = (props.isLoading === false || props.isLoading === true) ? props.isLoading : false;
    let grouping        = (props.grouping === false || props.grouping === true) ? props.grouping : false;
    let paging          = (props.paging === false || props.paging === true) ? props.paging : true;
    let selection       = (props.selection === false || props.selection === true) ? props.selection : false;
    let defaultExpanded = (props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false;
    let limit           = props.limit ? props.limit : 20;
    let total           = props.total ? props.total : 0;
    let page            = props.page ? props.page : 1;
    let driver          = props.driver ? props.driver : false;
    let isDraggable     = props.isDraggable ? props.isDraggable : false;
    let data            = (props.data && props.data.length > 0)? props.data : [];
    let columns         = (props.columns && props.columns.length > 0)? props.columns : [];
    let emptyText       = props.emptyText? props.emptyText : noResults;
    let overflowY       = props.overflowY? props.overflowY : "visible";
    let maxBodyHeight   = props.maxBodyHeight ? props.maxBodyHeight : '';
    let minBodyHeight   = props.minBodyHeight ? props.minBodyHeight : '';
    let tableLayout     = props.tableLayout ? props.tableLayout : 'auto';
    let className       = props.className ? props.className : '';
    let type            = props.type ? props.type : '';
    let isPreview       = props.isPreview ? props.isPreview : false;
    let DrageState      = '';
    let collection_total = (props.data && props.data.length > 0)? props.data.filter(obj => obj.job_type === "Collection").length : [];
    let delivery_total = (props.data && props.data.length > 0)? props.data.filter(obj => obj.job_type === "Delivery").length : [];
    const changePageSize = (size) =>{ 
        limit = size;
        if(props.onPageChange){ 
            props.onPageChange(page,limit);
        }
    }

    const onDragStart = (e, rows) => {
        if(props.onDragStart){
            return props.onDragStart(e, rows);
        }
    }

    const handleRowClick = (event, rowData) => {
        // const open = true;
        if (props.handleRowClick) {
          props.handleRowClick(event, rowData);
        }
    };
      
    const isOutOfTime = (job) => {
    if(job.drop_off_worker_id != null && moment(job.drop_off_date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {

        if(moment(job.drop_off_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return true;
        }
    
        let currentTime = moment().format("H:mm").split(":");
        let currentTimeMinutes = (parseInt(currentTime[0]) * 60) + parseInt(currentTime[1]);
        let time_plan = job.drop_off_time_planned.split(" - ");
        let toTime = time_plan[1].split(":");
        let toTimeMinutes = (parseInt(toTime[0])* 60) + parseInt(toTime[1]);
        if((toTimeMinutes - currentTimeMinutes) < 0) {
            return true;
        }
    } else {
        return false;
    }          
    }
    
    return <Box className={'custom-table-component allocation-tb position-relative ' + className} id="scroll-container"
    style={{overflowY: 'auto' }}>
                <MaterialTable
                    tableRef={ref}
                    components={{
                        Container: (p) => {
                            return <Paper className={'custom-container'} elevation={0} square {...p} />
                        },
                        Toolbar: (p) => {
                            if(props.Toolbar){
                                return <Box>
                                    {props.Toolbar(p)}
                                </Box>
                            } else {
                                return null;
                            }
                        },
                        Header: (p) => {
                            
                            if(props.Header) {
                                return <React.Fragment>
                                    {props.Header(p)}
                                </React.Fragment>
                            } else {
                                return <MTableHeader className={'bg-ss'} {...p} /> 
                            }
                        },
                        Row: (p) => {
                            if(props.Row) {
                                return <React.Fragment>
                                    <MTableBodyRow {...p} />
                                    {props.Row(p, p.data)}
                                </React.Fragment>
                            } else {
                            if(p.data.hasOwnProperty('order_status')  ){
                                    if((p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'in-progress' || p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == "acknowledged" )&& isOutOfTime(p.data)){
                                        return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-alert bg-red-alert' : 'bg-alert'} {...p}
                                        draggable={false}
                                        onDoubleClick={(event) => {
                                          // Prevent dragging on double-click
                                          event.stopPropagation();
                                        }}
                                        onDragStart={(e) => {
                                          if (window.getSelection().toString()) {
                                              e.preventDefault();
                                          }        
                                        }}
                                        />
                                    } else if(p.data.job_type == "Collection" && type == "allocation"  && !isPreview)  {
                                        return <MTableBodyRow  className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-allocation-collection bg-red-alert' :  'bg-allocation-collection'} {...p}
                                        draggable={isDraggable && p.data.job_type == "Delivery" && !p.doubleclick? true : false}
                                        onDoubleClick={(event) => {
                                            // Prevent dragging on double-click
                                            event.stopPropagation();
                                          }}
                                        onDragStart={(e) => {
                                            if (window.getSelection().toString()) {
                                                e.preventDefault();
                                            }
                                            if( p.data.job_type == "Delivery") {
                                                onDragStart(e, p.data);
                                            }
                                        }}/>
                                    } else if((p.data.driver_payout == 0 || p.data.driver_payout == null ) && (p.data.amount == 0 || p.data.amount == null) && (p.data.vendor_payout == 0 || p.data.vendor_payout == null))  {
                                        return <MTableBodyRow  className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-alert bg-red-alert' : 'bg-alert'} {...p}
                                        draggable={isDraggable && p.data.job_type == "Delivery" && !p.doubleclick? true : false}
                                        onDoubleClick={(event) => {
                                            // Prevent dragging on double-click
                                            event.stopPropagation();
                                          }}
                                        onDragStart={(e) => {
                                            if (window.getSelection().toString()) {
                                              e.preventDefault();
                                            }
                                            if( p.data.job_type == "Delivery") {
                                                onDragStart(e, p.data);
                                            }
                                        }}/>
                                    } else if(p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') === 'completed'  && !isPreview) {
                                        return <MTableBodyRow  className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-complete bg-red-alert' : 'bg-complete'} {...p} 
                                        draggable={isDraggable && p.data.job_type == "Delivery" && !p.doubleclick? true : false}
                                        onDoubleClick={(event) => {
                                            // Prevent dragging on double-click
                                            event.stopPropagation();
                                          }}
                                        onDragStart={(e) => {
                                            if (window.getSelection().toString()) {
                                                e.preventDefault();
                                                }
                                            if( p.data.job_type == "Delivery") {
                                               onDragStart(e, p.data);
                                           }
                                        }}
                                        />
                                    }  else {
                                        return <MTableBodyRow {...p}  className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-red-alert' : ''}
                                        draggable={isDraggable && p.data.job_type == "Delivery" && !p.doubleclick? true : false}
                                        onDoubleClick={(event) => {
                                            // Prevent dragging on double-click
                                            event.stopPropagation();
                                          }}
                                        onDragStart={(e) => {
                                            if (window.getSelection().toString()) {
                                                e.preventDefault();
                                            }
                                            if( p.data.job_type == "Delivery") {
                                                onDragStart(e, p.data);
                                            }
                                        }}/>
                                    } 
                                    
                                } else {
                                    return <MTableBodyRow {...p}  className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-red-alert' : ''}
                                    draggable={isDraggable && p.data.job_type == "Delivery" && !p.doubleclick? true : false}
                                    onDoubleClick={(event) => {
                                        // Prevent dragging on double-click
                                        event.stopPropagation();
                                      }}
                                    onDragStart={(e) => {
                                        if (window.getSelection().toString()) {
                                            e.preventDefault();
                                          }
                                        if( p.data.job_type == "Delivery") {
                                            onDragStart(e, p.data);
                                        }

                                    }}
                                        />
                                }
                            }
                        },
                        Groupbar: (p) => {
                            return <Box className={'custom-groupbar'}>
                                <MTableGroupbar {...p} />
                            </Box>
                        },
                        
                    }}

                    localization={{
                        body: {
                            emptyDataSourceMessage:(
                                <div style={{position:'relative',padding:'15px'}} >
                                    {emptyText}
                                </div>
                            ), 
                        }
                    }}
                    onRowClick={handleRowClick}
                    options={{
                        grouping: grouping,
                        overflowY: 'auto',
                        paging: paging,
                        selection: selection,
                        maxBodyHeight: maxBodyHeight,
                        minBodyHeight: minBodyHeight,
                        tableLayout: tableLayout,
                        defaultExpanded: defaultExpanded,
                        pagination: true,
                        pageSize : 20,
                        pageSizeOptions: false,
                    }}

                    onSelectionChange={(rows) => {
                        if(props.onSelectionChange){
                            return props.onSelectionChange(rows);
                        }
                    }}
                    icons={{SortArrow: forwardRef((props, ref) => <FontAwesomeIcon color='#007CDE'  {...props} icon={faSort} fontSize='big' />)}}
                    isLoading={isLoading}
                    columns={columns}
                    data={data}
                    detailPanel={(props.detailPanel) ? [
                        {
                            render: (rowData) => {
                                if(props.detailPanel){
                                    return props.detailPanel(rowData);
                                }
                            }
                        }
                    ] : null}
                />
                <div className="py-2 d-flex justify-content-start align-items-center" style={{bottom: '10px', position:'absolute'}}>
                    <div style={{display:"inline"}} className="table-page-info">
                        {'No. of Pick Up: ' + collection_total + ' No. of Deliveries: '  + delivery_total}
                    </div> 
                </div>
            </Box>
    
})

export default Table;