import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import api from "../../api/api.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';
import OldTable from '../../components/Table/OldTable.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access'; 
import CustomPieChart from '../../components/Charts/PieCharts';
import CustomLineChart from '../../components/Charts/LineCharts';
import CustomVerticleBarChart from '../../components/Charts/VerticleBarCharts';
import CustomHorizontalBarChart from '../../components/Charts/HorizontalBarChart';
import CustomBarChart from '../../components/Charts/BarCharts';
import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMapWithMarkers';
import NormalPieChart from '../../components/Charts/NormalPieChart';
import Scales from '../../assets/img/analytic_icons/Scales.svg'
import NaturalFood from '../../assets/img/analytic_icons/NaturalFood.svg'
import FullVersion from '../../assets/img/analytic_icons/FullVersion.svg'
import Fragile from '../../assets/img/analytic_icons/Fragile.svg'
import EditDeliveryTerms from '../../assets/img/analytic_icons/EditDeliveryTerms.svg'
import Estimate from '../../assets/img/analytic_icons/Estimate.svg'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

class DriverCharts extends Component {
  static contextType = AuthContext;
  pages = 1;
  limits = 3;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(7, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      package_size_loading: false,
      isLoadingOrder:false,
      isLoadingTable:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingMap: false,
      data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_by_driver: [],
      order_by_size: [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
      overview_data : [],
      delivery_table_data : [],
      total_deliveries: 0,
      driver_id: null,
      vendor_id: null,
      search_driver_id: null,
      search_vendor_id: null,
      drop_driver_id: null,
      drop_vendor_id: null,
      cost_driver_id: null,
      cost_vendor_id: null,
      packages_size: [],
      workerItems: [],
      dropPerDayLoading: false,
      dropPerCostLoading: false,
      drop_per_day: [],
      drop_per_cost: [],
      workerOnly: [],
      highestCost: 0,
      lockCapture: false,
      delivery_damage_rate: 0,
      deliveryDamageRateLoading: false,
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.callDeliveryApi();
    this.callDeliveryTableApi();
    this.overviewDataApi();
    this.getDeliveryDamageRate();
    // this.callPackageSize();
    // this.callDropPerDay();
    // this.callDropPerCost();
    let driver = [];
    this.callManpowerDriverReadApi('', (arr) => {
      driver.push(arr);
      this.setState({
        workerOnly: arr
      });
      this.callVendorReadApi('', (arr) => {
        driver.push(arr);
        var result = [].concat.apply([],Object.values(driver));
        this.setState({
          workerItems: result.sort((a, b) => {
            const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
        });
      });
    });
  }
  
  getDeliveryDamageRate = () => {
    const { accessToken } = this.context;
    
    this.setState({
      deliveryDamageRateLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
        customer_id: this.state.customer_id,
      };
  
        ldsApi.read('/analytic/delivery_damage_rate/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              delivery_damage_rate: data.result,
              deliveryDamageRateLoading: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_damage_rate: 0,
              deliveryDamageRateLoading: false,
            });
          });
  
      });
    });      
  }
  
  callDropPerDay = () => {
    const { accessToken } = this.context;  
    this.setState({
      dropPerDayLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        start_date: startDate,
        end_date: endDate,
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

      ldsApi.read('analytic/driver_drop_per_day/', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.result.map((obj) => {
            obj.title = moment(obj.title).format("DD-MM");    
          });
          this.setState({
            drop_per_day: data.result,
            dropPerDayLoading: false,
          });    
        }, (error, type) => {
          this.setState({
            drop_per_day: [],
            dropPerDayLoading: false,
          });
        });        
      });
    });     
  }
  
  callDropPerCost = () => {
    const { accessToken } = this.context;  
    this.setState({
      dropPerCostLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        start_date: startDate,
        end_date: endDate,
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

      ldsApi.read('analytic/driver_drop_per_cost/', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let payout = 0;
          let cost_list = data.result.map((obj) => {
            obj.title = moment(obj.title).format("DD-MM");    
            obj.cost = parseFloat(obj.cost).toFixed(2);
            return obj.cost;
          });
          let highestCost = cost_list.length > 0 ? Math.max(...cost_list) : 0;
          this.setState({
            drop_per_cost: data.result,
            dropPerCostLoading: false,
            highestCost: parseInt(highestCost)
          });
          
        }, (error, type) => {
          this.setState({
            drop_per_cost: [],
            dropPerCostLoading: false,
          });
        });      
      });
    });     
  }
  
  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      disabled: false,
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      light_data: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              if(callback){
                callback(arr);
              }
            } else {
                if(callback){
                  callback([]);
                }
            }
          }, (error, type) => {
              if(callback){
                callback([]);
              }
          });
      });
  }
  
  callPackageSize = () => {
    const { accessToken } = this.context;  
    this.setState({
      package_size_loading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        start_date: startDate,
        end_date: endDate,
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

      ldsApi.read('analytic/packages_size_by_driver/', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          this.setState({
            packages_size: data.result,
            package_size_loading: false,
          });   
        }, (error, type) => {
          this.setState({
            packages_size: [],
            package_size_loading: false,
          });
        });        
      });
    });      
  }

// API START =================
  callDeliveryApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

        ldsApi.read('analytic/drivers/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            let dataArr = (data.result && data.result.length > 0) ? data.result : []

            dataArr.sort((a, b)=> {
              if (a.Collections === b.Collections){
                return a.Deliveries < b.Deliveries ? -1 : 1
              } else {
                return a.Collections < b.Collections ? -1 : 1
              }
            })
           
            this.setState({
              delivery_by_driver: dataArr,
              total_deliveries: dataArr.length,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_by_driver: [],
              isLoading: false,
            });
          });

      });
    });
  }

  callDeliveryTableApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingTable: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

        ldsApi.read('analytic/drivers/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            let dataArr = (data.result && data.result.length > 0) ? data.result : []
           
            this.setState({
              delivery_table_data: dataArr,
              isLoadingTable: false,
            });
            
          }, (error, type) => {
            this.setState({
              delivery_table_data: [],
              isLoadingTable: false,
            });
          });

      });
    });
  }

  overviewDataApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
        overview_type: 'driver',
        drop_off_worker_id : this.state.search_driver_id,
        vendor_id : this.state.search_vendor_id,
      };

        ldsApi.read('/analytic/overview/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              overview_data: data.result,
              do_per_size_total: data.result && data.result.do_per_size_total,
              job_profile_total: data.result && data.result.job_profile_total,
              isLoadingClient: false,
            });
          }, (error, type) => {
            this.setState({
              overview_data: [],
              do_per_size_total: '',
              job_profile_total: '',
              isLoadingClient: false,
            });
          });

      });
    });
  }

// API END ==================
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.active_driver ? parseFloat(this.state.overview_data.active_driver).toLocaleString() : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Active Drivers</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100  d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color"> {this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.average_drop_per_day && this.state.overview_data.average_drop_per_day != 0 ? parseFloat(this.state.overview_data.average_drop_per_day).toFixed(2).toLocaleString() : '0.00')}</div>
        <div className="fs-6 ps-2 font-medium">Avg. Jobs per Day</div>
      </Card>
    </Col>

    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.on_time_deliveries && this.state.overview_data.on_time_deliveries != 0  ? parseFloat(this.state.overview_data.on_time_deliveries).toFixed(2) : '0.00') + '%'}</div>
        <div className="fs-6 ps-2 font-medium">On-Time Deliveries</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : '$' + (this.state.overview_data.to_be_billed && this.state.overview_data.to_be_billed != 0  ? parseFloat(this.state.overview_data.to_be_billed).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')}</div>
        <div className="fs-6 ps-2 font-medium">Driver Job Value</div>
      </Card>
    </Col>
  </Row>
  }

  overviewTwoCard = () => {
    return <Row className="p-0 m-0 overflow-hidden" style={{rowGap: '2.5em'}}>
     <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">On-Time Deliveries</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.on_time_deliveries ? parseFloat(this.state.overview_data.on_time_deliveries).toFixed(2) : '0.00')+ "%"} </div>
        {/* <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={EditDeliveryTerms} style={{width:'40px', height:'40px'}}/></div> */}
      </Card>
    </Col>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Avg. Jobs per Day</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.average_drop_per_day && this.state.overview_data.average_drop_per_day != 0 ? parseFloat(this.state.overview_data.average_drop_per_day).toFixed(2) : '0.00')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={EditDeliveryTerms} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
  </Row>
  }

  overviewBottomCard = () => {
    return <div className='w-100 p-0 m-0'><Row className="p-0 m-0 overflow-hidden" style={{rowGap: '2.5em'}}>
     <Col xs={12} md={6} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Capacity Utilisation</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.avg_capacity_utalization ? parseFloat(this.state.overview_data.avg_capacity_utalization).toFixed(2) : '0.00')+'%'} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={FullVersion} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={6} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Avg. Job Value</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.average_job_value && this.state.overview_data.average_job_value != 0  ? '$' + parseFloat(this.state.overview_data.average_job_value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$0.00')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Estimate} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
  </Row>
  <Row className="pt-4 m-0 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={6} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Delivery Damage Rate</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.deliveryDamageRateLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.delivery_damage_rate > 0 ? parseFloat(this.state.delivery_damage_rate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%': '0.00'+ '%')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Fragile} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={6} className="p-0 m-0 d-flex justify-content-center">
      {/* <Card className="position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
       
      </Card> */}
    </Col>
  </Row></div>
  }

  orderSizeBarChart = () => {
    return <Card className="mx-3 analytic-chart-card-jobsize d-flex flex-column h-380px w-100 justify-content-start align-items-center">
            <div className="fs-5 font-medium ps-2 pb-2">Job Sizes</div>
            {this.state.isLoadingClient? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomPieChart showLable={this.state.lockCapture} data={this.state.overview_data.do_per_size} is_size={true} dataKey={'total'} key={'total'} value1={'name'} value2={'total'} status_total={this.state.do_per_size_total} vertical_align={'bottom'} align={'center'} class_name={'size-pie-chart'}/>
                }
            </Card>
  }

  deliveriesByDriverPartner = () => {
    return <Card className="mx-3 analytic-chart-card d-flex flex-column h-400px w-100">
            <div className="fs-5 font-medium ps-2 pb-2">Deliveries By Driver-Partner</div> 
              {this.state.isLoading? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomHorizontalBarChart title="average_drops_per_day" data={this.state.delivery_by_driver} barList={[{key:'Deliveries',color:'#88BF4D'},{key:'Pick up',color:'#98D9D9'}]}/>
                }
            </Card>
  }

customTable = () => {
  return <OldTable 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoadingTable}
      isDraggable={false}
      isDriverTable = {true}
      grouping={false}
      limit={3}
      page={this.pages}
      selection={false}
      driver={true}
      total={this.state.total_deliveries}
      data={this.state.delivery_table_data}
      showLable={this.state.lockCapture}
      defaultExpanded={true}
      onPageChange={(page,limit) => {
          this.pages  = page;
          this.limits =  limit;  
          this.callDeliveryTableApi();   
      }}
      onSelectionChange={(rows, column)=>{
        this.rowData = rows;
      }}
      columns={[
        { 
          title: "Name",
          field: "title",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
            if(type === 'row'){
              return row.title ? row.title : null;
          }
        }
        },
        { 
          title: "Pick ups Completed",
          field: "collection_count",
          cellStyle: {
            minWidth: 200,
            maxWidth: 28850            
          },
          render: (row, type) => {
            if(type === 'row'){
             return <div className="w-100 pe-2">
              <Row className="p-0 m-0">
                <Col xs={10} md={7} lg={8}>
                <div className={Number(row.Collections / row.collection_count) * 100 < 33 ? "progress progress-style-red" : Number(row.Collections / row.collection_count) * 100 == 100 ? "progress progress-style-green" : "progress progress-style-yellow"}>
                  <div className="progress-bar" role="progressbar" style={{width: row.Collections == 0 ? 0 + '%' : (row.Collections / Number(row.collection_count)) * 100 +'%'}} aria-valuenow={row.collection_count} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                </Col>
                <Col xs={10} md={3} lg={2}>
                  <span>{Number(row.Collections / row.collection_count) > 0 ? ((row.Collections / row.collection_count) * 100) == 100 ? parseFloat((row.Collections / row.collection_count) * 100).toFixed(0) + "%" : parseFloat((row.Collections / row.collection_count) * 100).toFixed(2) + "%" : "N/A"}</span>
                </Col>
              </Row>
              </div>
          }
        }
        },
        { 
          title: "Deliveries Completed",
          field: "delivery_count",
          cellStyle: {
            minWidth: 200,
            maxWidth: 200            
          },
          render: (row, type) => {
            if(type === 'row'){
              return <div className="w-100 pe-2">
              <Row className="p-0 m-0">
                <Col xs={10} md={7} lg={8}>
                <div className={Number(row.Deliveries / row.delivery_count) * 100 < 33 ? "progress progress-style-red" : Number(row.Deliveries / row.delivery_count) * 100 == 100 ? "progress progress-style-green" : "progress progress-style-yellow"}>
                  <div className="progress-bar" role="progressbar" style={{width: row.Deliveries == 0 ? 0 + '%' : (row.Deliveries/Number(row.delivery_count)) * 100 +'%'}} aria-valuenow={row.delivery_count} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                </Col>
                <Col xs={10} md={3} lg={2}>
                  <span>{Number(row.Deliveries / row.delivery_count) > 0 ? ((row.Deliveries / row.delivery_count) * 100) == 100 ? parseFloat((row.Deliveries / row.delivery_count) * 100).toFixed(0) + "%" : parseFloat((row.Deliveries / row.delivery_count) * 100).toFixed(2) + "%" : "N/A"}</span>
                </Col>
              </Row>
              </div>
          }
        }
        },
        { 
          title: "Paid Amount",
          field: "driver_payout",
          render: (row, type) => {
            if(type === 'row'){
              return row.driver_payout? "$" + parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
          }
        }
        },
        { 
          title: "Client Amount",
          field: "client_payout",
          render: (row, type) => {
            if(type === 'row'){
              return row.client_payout? "$" + parseFloat(row.client_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
          }
        }
        },
        { 
          title: "Profit / Lose",
          field: "client_payout",
          render: (row, type) => {
            if(type === 'row'){
              return (row.client_payout - row.driver_payout >= 0) ? <div style={{color: "#02AF95"}}>${parseFloat((row.client_payout? row.client_payout : 0 ) - (row.driver_payout? row.driver_payout : 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div> : <div style={{color: "#FF0000"}}>${parseFloat((row.client_payout? row.client_payout : 0) - (row.driver_payout? row.driver_payout : 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          }
        }
        },
        { 
          title: "Driver Type",
          field: "type",
          render: (row, type) => {
            if(type === 'row'){
              return row.type? row.type : null;
          }
        }
        }
      
        ]}
    />
  }
  
  handleDownload = () => {
      
    this.setState({
      lockCapture: true,     
    },() => {
      const input = document.getElementById('container-id');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      const btnElement = document.getElementById('download-pdf');
      const calenderElement = document.getElementById('calendar-element');
      
      if (btnElement) {
        btnElement.style.display = 'none';
      }
      
      if (calenderElement) {
        calenderElement.style.display = 'none';
      }
      
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;
      
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('Driver.pdf');
        
        if (btnElement) {
          btnElement.style.display = '';
        }
        
        if (calenderElement) {
          calenderElement.style.display = '';
        }
        
        this.setState({
          lockCapture: false 
        });
      });
    });
  };

  render() {
    return <Container fluid id="container-id">
        <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex align-items-center">
          <div className="fs-4 font-medium">Analytics for {moment(this.state.startDate).format(apiUtil.getImportDateFormat())} to {moment(this.state.endDate).format(apiUtil.getImportDateFormat())}</div>
        </Col>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center max-xs-width-control" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
        <div id="calendar-element">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // Define your two dates
                const date1 = moment(range.startDate);
                const date2 = moment(range.endDate);
                
                // Calculate the difference in years
                const differenceInYears = date2.diff(date1, 'years', true);

                if(differenceInYears > 1){
                  apiUtil.toast('Date range can not more than one year.',  'warning', 'error'); 
                  return;                  
                }
                this.pages = 1;
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate,
                  driver_id: '',
                  vendor_id: '',
                  drop_vendor_id: '',
                  drop_driver_id: '',
                  cost_driver_id: '',
                  cost_vendor_id: ''
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </div>
          <Button className="upload-style"
          id="download-pdf"
          onClick={() => {
            this.handleDownload();
          }}
          ><FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>
      {this.overviewHeader()}
      <div className="ps-4 mt-4 ms-2 max-xs-width-control-100" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
        <select className="custom-input-component " style={{border: "1px solid #8E8E8E", borderRadius: "8px",padding:"8px", backgroundColor: '#ffffff'}} aria-label="Default select example"
          value={this.state.search_driver_id ? this.state.search_driver_id : this.state.search_vendor_id}
          onChange={(e) => {
            let check_driver = this.state.workerItems.filter(dd => dd.value == e.target.value);
            if(check_driver.length > 0 && typeof(check_driver[0].item.application_company_id) != 'undefined') {
              this.setState({
                search_vendor_id: '',
                search_driver_id: e.target.value
              })
            } else {
              this.setState({
                search_driver_id: '',
                search_vendor_id: e.target.value
              })
            }
            this.callInitApi();                  
          }}>
            <option value="">-- Select Driver Partner --</option>
            {this.state.workerItems && this.state.workerItems.length > 0 && this.state.workerItems.map((worker) => (
              <option value={worker.value} key={worker.value}>{worker.label }</option>
            ))}
          </select>
      </div>
      <Row className="px-4 py-4 overflow-hidden" style={{rowGap: '1.5em'}}>
      <Col xs={12} md={12} lg={8} className="p-0 m-0 d-flex justify-content-center">
        {this.deliveriesByDriverPartner()}
      </Col>

      <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
        {this.overviewTwoCard()}
      </Col>

      <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
        <Card className={'mx-3 analytic-chart-card-wh w-100'}>
          {this.customTable()}
        </Card>
      </Col>
      <Col xs={12} md={12} lg={8} className="p-0 m-0 d-flex justify-content-center">
        {this.overviewBottomCard()}
      </Col>
      <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
        {this.orderSizeBarChart()}
      </Col>
      </Row>
    </Container>;
  }
}

export default DriverCharts;