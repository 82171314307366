import React, { useCallback, useEffect } from 'react';
import {BrowserRouter, Switch, Route, Redirect, browserHistory} from 'react-router-dom';

import './assets/scss/main.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthDataProvider from "./components/Auth/AuthDataProvider.jsx";
import RouteLayout from "./layout/Main/RouteLayout.jsx";
import CreatePageLayout from './layout/Main/CreatePageLayout.jsx';
import NotificationList from "./components/Header/NotificationList.jsx";
import PageNotFound from "./views/PageNotFound/PageNotFound.jsx";
import SignIn from "./views/Account/Signin.jsx";
import SignUp from "./views/Account/SignUp.jsx";
import PrivacyPolicy from "./views/TermsAndPolicy/PrivacyPolicy.jsx"
import TermsAndConditions from './views/TermsAndPolicy/TermsAndConditions.jsx';
import AutoAssignPreview from './views/Jobs/autoAssignPreview';
import Dashboard from "./views/Dashboard/Dashboard.jsx";
import TrashBin from "./views/Dashboard/TrashBin.jsx";
import CustomerTrashBin from "./views/Dashboard/CustomerTrashBin.jsx";
import {RoleAccessService} from "./data/role-access" 
import CustomerDashboard from './views/CustomerTemplate/dashboard/dashboard';
import RequestForm from './views/CustomerTemplate/requestForm/requestForm';
import CustomerOrderSchedule from './views/CustomerTemplate/dashboard/customerOrderSchedule';
import Allocation from './views/Dashboard/Allocation.jsx';
import Allocation1 from './views/Dashboard/Allocation_1.jsx';
import Allocation2 from './views/Dashboard/Allocation_2.jsx';
import JobScheduleList from './views/Jobs/JobScheduleList.jsx';
import { 
  JobList,
  JobVendorList,
  // AutoAssignPreview,
  JobSchedule,
  // JobScheduleList,
  JobsForm, 
  LiveTracking, 
  Vendor, 
  VendorForm,
  Customers, 
  CustomersInActive, 
  CustomersForm, 
  ReportTransportOperation,
  ReportTransportOperationDetails,
  ReportCustomers,
  ReportCustomersDetails,
  Logistic,
  Vehicle,
  VehicleForm, 
  LogisticForm, 
  // Transport,
  // TransportPending,
  // Subcons,
  Settings,
  SettingProfile,
  TransportForm,
  RouteOptimize,
  Onboarding,
  OnboardingComplete,
  Zones,
  JobBulkUpdateByDo
} from "./views/index";
import { ChangePassword } from './views/Settings/ChangePassword';
import {Transport} from './views/Resources/Transport/Transport'
import {TransportPending} from './views/Resources/Transport/TransportPending'
import {Subcons} from './views/Resources/Transport/subcons'
import { AutoAssignList } from './views/Jobs/AutoAssignList';
import NewOrder from './views/CustomerTemplate/order/NewOrder';
import OrderHistory from './views/CustomerTemplate/order/OrderHistory';
import Invoice from './views/CustomerTemplate/invoice/invoice';
import CustomerOrderScheduleList from './views/CustomerTemplate/dashboard/customerOrderScheduleList';
import CustomerSetting from './views/CustomerTemplate/general/setting';
import CustomerHelp from './views/CustomerTemplate/general/help';
import {Helmet} from "react-helmet";
import OrderSummary from './views/Analytic/orderSummary';
import Charts from './views/Analytic/charts';
import OverviewCharts from './views/Analytic/overview_chart';
import ClientOverview from './views/Analytic/client/client_overview';
import ClientCharts from './views/Analytic/client_chart';
import DriverCharts from './views/Analytic/driver_chart';
import { ClientInvoice } from './views/Invoice/clientInvoice';
import ClientInvoiceDetail from './views/Invoice/clientInvoiceDetail';
import { DriverPayout } from './views/Invoice/driverpayout';
import DriverPayoutDetail from './views/Invoice/driverpayoutDetail';
import { TransportDetail } from './views/Resources/Transport/TransportDetail';
import AdminNewOrder from './views/Dashboard/NewOrder';
import NewOrderForm from './views/Dashboard/NewOrderForm';
import { EditSubcons } from './views/Resources/Transport/editSubcons';
import { Subcon } from './views/Subcons/Subcon';
import { SubconForm } from './views/Subcons/SubconForm';
import { SubconPending } from './views/Subcons/SubconPending';
import { CustomersPending } from './views/Customers/CustomersPending';
import AdminNewOrderNewLogic from './views/Dashboard/NewOrder_newlogic';
import Sustainability from './views/Analytic/Sustainability';
// import ad from './assets/'
var isClicked = false;

// const pathArray =  ["/dashboard", 
//                     "/dashboards/allocation", 
//                     "/dashboards/allocation1", 
//                     "/dashboards/allocation2",
//                     "/dashboards/new-order",
//                     "/dashboards/new-order-form",
//                     "/analytics/overview",
//                     "/analytics/client",
//                     "/analytics/driver",
//                     "/general-settings",
//                     "/setting-profile",
//                     "/live-tracking/reload",
//                     "/live-tracking",
//                     "/notification-list",
//                     "/vendor",
//                     "/vendor-form",
//                     "/resources/vendor-pending",
//                     "/resources/vendor",
//                     "/resources/vendor-form",
//                     "/job-list",
//                     "/auto-assign-list",
//                     "/jobs/bulk-update",
//                     "/auto-assign-preview",
//                     "/jobs/route-optimize",
//                     "/job-vendor-list",
//                     "/job-schedule",
//                     "/job-schedule-list",
//                     "/analytics/order-summary-list",
//                     "/jobs-form",
//                     "/report-transport-operation",
//                     "/report-transport-operation-details",
//                     "/report-customers",
//                     "/report-customers-details",
//                     "/customers",
//                     "/customers-pending",
//                     "/customers-form",
//                     "/resources/logistic-assistance",
//                     "/resources/logistic-assistance-form",
//                     "/resources/transport-operator-form",
//                     "/resources/transport-operators",
//                     "/resources/transport-operator/detail", 
//                     "/resources/transport-operator-pending",
//                     "/resources/subcons",
//                     "/resources/subcons-edit",
//                     "/resources/vehicle",
//                     "/resources/vehicle-form",
//                     "/invoice/clients",
//                     "/invoice/client/details",
//                     "/invoice/drivers",
//                     "/invoice/driver/details",
//                     "/zones",
//                     "/password",
//                     "/onboarding",
//                     "/onboarding-complete",
//                     "/sign-in",
//                     "/sign-up",
//                     "/privacy_policy",
//                     "/terms_and_conditions",
//                     "/settings",
//                     "/help-center",
//                     "/customer-dashboard",
//                     "/order-summary",
//                     "/order-summary-list",
//                     "/analytics/client-overview",
//                     "/new-order",
//                     "/order-schedule",
//                     "/invoice"]
function App() {
    let grantedAccess = new RoleAccessService();
    let role = window.localStorage.getItem('uniqueRolePermissions');
    // const { currentRole } = this.context;
    let currentRole = window.localStorage.getItem('current_role');
    // console.log('qewrty',currentRole)
  var path = window.localStorage.getItem('access_token');
  if(path == null){
    if( ! (window.location.pathname == "/Sign-in"  || window.location.pathname == "/Sign-up"  || window.location.pathname == "/privacy_policy" ||  window.location.pathname == "/terms_and_conditions"  ||  window.location.pathname == ("/sign-up-success") || window.location.pathname.includes("/login")) ) {
      window.location.href ="/Sign-in";
    }
  }

  useEffect(() => {
    currentRole = window.localStorage.getItem('current_role');
    const currentURL = window.location.pathname 
    // if(pathArray.indexOf(currentURL) !== -1) {  
    //   console.log("URL exit !!")         
    // } else {  
    //   window.location.href ="/Sign-in";
    // } 
  }, [currentRole])

  const beforeinstallpromptHandler = useCallback(
    (deferredPrompt) => {
      // if(!isClicked){
      //   toast(
      //     <button 
      //       onClick={() => {
      //         deferredPrompt.prompt();
    
      //         deferredPrompt.userChoice
      //           .then((choiceResult) => {
      //             isClicked = true;
      //             // if (choiceResult.outcome === 'accepted') {
      //             //   console.log('User accepted the A2HS prompt');
      //             // } else {
      //             //   console.log('User dismissed the A2HS prompt');
      //             // }
      //         });
      //       }}  
      //     >Install</button>
      //   );
      // }
    }, []
  );
  window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  
  const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];
  
    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
  
    callback(true);
  };

  // let grantedMenu = new RoleAccessService;
  // let currentRole = window.localStorage.getItem('current_role');

  // if(!grantedMenu.admin_roles_with_customer.includes(currentRole)){
  //   var root_url = "/live-tracking";
  //   console.log(currentRole, "Current Role")
  // } else {
  //   var root_url="/dashboard";
  // }

  return (
    <AuthDataProvider>
      <div className="App">
        
            <BrowserRouter getUserConfirmation={getUserConfirmation}>
              <Switch>
              { currentRole != 'Customer Template Role'?
                <Route exact path="/">   
                {
                  grantedAccess.isShouldShowMenu(currentRole, 'dashboard')? <Redirect to="/dashboard" /> :(
                  grantedAccess.isShouldShowMenu(currentRole, 'jobs')? <Redirect to="/analytics/overview" /> :(
                  grantedAccess.isShouldShowMenu(currentRole, 'customers')? <Redirect to="/customers" /> :(
                  grantedAccess.isShouldShowMenu(currentRole, 'report')? <Redirect to="/report-transport-operation" /> : <Redirect to="/dashboard" /> )))
                }         
                  </Route>
                  : 
                  <Route exact path="/">   
                      <Redirect to="/customer-dashboard" />
                  </Route>
                }
                  <Route exact path="/resources" >
                  <Redirect to="/resources/transport-operators" />
                  </Route>
                  {/* <Route exact path="/dashboard-noti">   
                      <Redirect to="/dashboard" />
                  </Route> */}
                <RouteLayout exact path="/dashboard" component={Dashboard} menu_name={"dashboard"} replace={true}/>
                <RouteLayout exact path="/recycle-bin" component={TrashBin} menu_name={"dashboard"}/>
                <RouteLayout exact path="/dashboards/allocation" component={Allocation} menu_name={"dashboard"} />
                <RouteLayout exact path="/dashboards/allocation1" component={Allocation1} menu_name={"dashboard"} />
                <RouteLayout exact path="/dashboards/allocation2" component={Allocation2} menu_name={"dashboard"} />
                <RouteLayout exact path="/dashboards/new-order" component={AdminNewOrder} menu_name={"dashboard"} />
                <RouteLayout exact path="/dashboards/new-order-new-logic" component={AdminNewOrderNewLogic} menu_name={"dashboard"} />
                <RouteLayout exact path="/dashboards/new-order-form" component={NewOrderForm} menu_name={"dashboard"} />
                <RouteLayout exact path="/analytics/overview" component={OverviewCharts} menu_name={"jobs"} />
                <RouteLayout exact path="/analytics/client" component={ClientCharts} menu_name={"jobs"} />
                <RouteLayout exact path="/analytics/driver" component={DriverCharts} menu_name={"jobs"} />
                <RouteLayout exact path="/analytics/sustainability" component={Sustainability} menu_name={"jobs"} />
                <RouteLayout exact path="/general-settings" component={Settings}  menu_name={"settings"} />
                <RouteLayout exact path="/setting-profile" component={SettingProfile}  menu_name={"user_profile"} />
                <Redirect exact from="/live-tracking/reload" to="/live-tracking" />
                <RouteLayout exact path="/live-tracking" component={LiveTracking} menu_name={"live_tracking"} />
                <RouteLayout exact path="/notification-list" component={NotificationList} menu_name={"user_profile"} />
                <RouteLayout exact path="/vendor" component={Vendor} />
                <RouteLayout exact path="/vendor-form" component={VendorForm}  menu_name={"user_profile"} />
                <RouteLayout exact path="/resources/vendor" component={Subcon} menu_name={"vendor"}/>
                <RouteLayout exact path="/resources/vendor-pending" component={SubconPending} menu_name={"vendor"}/>
                <RouteLayout exact path="/resources/vendor-form" component={SubconForm} menu_name={"vendor"} />
                <RouteLayout exact path="/job-list" component={JobList} menu_name = {"jobs"} />
                <RouteLayout exact path="/auto-assign-list" component={AutoAssignList} menu_name = {"jobs"} />
                <RouteLayout exact path="/jobs/bulk-update"  component={JobBulkUpdateByDo} menu_name = {"jobs"}/>
                <RouteLayout exact path="/auto-assign-preview"  component={AutoAssignPreview} menu_name = {"jobs"}/>
                <RouteLayout exact path="/jobs/route-optimize" component={RouteOptimize}  menu_name={"route_optimize"} />
                <RouteLayout exact path="/job-vendor-list" component={JobVendorList} menu_name={"vendor_list"} />
                <RouteLayout exact path="/job-schedule" component={JobSchedule} menu_name={"job_schedule"} />
                <RouteLayout exact path="/job-schedule-list" component={JobScheduleList} menu_name={"job_schedule_list"} />
                <RouteLayout exact path="/analytics/order-summary-list" component={JobScheduleList} menu_name={"job_schedule_list"} />
                <RouteLayout exact path="/jobs-form" component={JobsForm}  menu_name={"job_form"} />
                <RouteLayout exact path="/report-transport-operation" component={ReportTransportOperation} menu_name={"report"} />
                <RouteLayout exact path="/report-transport-operation-details" component={ReportTransportOperationDetails}  menu_name={"report_transport_operation_detail"} />
                <RouteLayout exact path="/report-customers" component={ReportCustomers} menu_name={"report"} />
                <RouteLayout exact path="/report-customers-details" component={ReportCustomersDetails}  menu_name={"report_customer_detail"}/>
                <RouteLayout exact path="/customers" component={Customers} menu_name={"customers"}/>
                {/* <RouteLayout exact path="/customers-in-active" component={CustomersInActive} menu_name={"customers"}/> */}
                <RouteLayout exact path="/customers-pending" component={CustomersPending} menu_name={"customers"}/>
                <RouteLayout exact path="/customers-form" component={CustomersForm}  menu_name={"customer_form"}/>
                <RouteLayout exact path="/resources/logistic-assistance" component={Logistic} menu_name={"resources_logistic_assistance"}/>
                <RouteLayout exact path="/resources/logistic-assistance-form" component={LogisticForm}  menu_name={"resources_logistic_assistance_form"}/>
                <RouteLayout exact path="/resources/transport-operator-form" component={TransportForm} menu_name={"transport_operator_form"}/>
                <RouteLayout exact path="/resources/transport-operators" component={Transport} menu_name={"transport_operator"}/>
                <RouteLayout exact path="/resources/transport-operator/detail" component={TransportDetail} menu_name={"transport_operator"}/>
                <RouteLayout exact path="/resources/transport-operator-pending" component={TransportPending} menu_name={"transport_operator"}/>
                <RouteLayout exact path="/resources/subcons" component={Subcons} menu_name={"transport_operator"}/>
                <RouteLayout exact path="/resources/subcons-edit" component={EditSubcons} menu_name={"transport_operator"}/>
                <RouteLayout exact path="/resources/vehicle" component={Vehicle} menu_name={"resources_vehicle"} />
                <RouteLayout exact path="/resources/vehicle-form" component={VehicleForm}  menu_name={"resources_vehicle_form"}/>
                <RouteLayout exact path="/invoice/clients" component={ClientInvoice} menu_name={"invoice"}/>
                <RouteLayout exact path="/invoice/client/details" component={ClientInvoiceDetail} menu_name={"invoice"}/>
                <RouteLayout exact path="/invoice/drivers" component={DriverPayout} menu_name={"invoice"}/>
                <RouteLayout exact path="/invoice/driver/details" component={DriverPayoutDetail} menu_name={"invoice"}/>
                <RouteLayout exact path="/zones" component={Zones} menu_name = {"zones"} />              
                <RouteLayout exact path="/password" component={ChangePassword} menu_name = {"password"} />              
                <Route exact path="/onboarding" component={Onboarding} menu_name={"onboarding"}/>
                <Route exact path="/onboarding-complete" component={OnboardingComplete} menu_name={"onboarding_complete"}/>
                <Route exact path="/sign-in" component={SignIn} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/privacy_policy" component={PrivacyPolicy} />
                <Route exact path="/terms_and_conditions" component={TermsAndConditions} />

                <RouteLayout exact path="/settings" component={CustomerSetting} menu_name={"settings"}/> 
                <RouteLayout exact path="/help-center" component={CustomerHelp} menu_name={"settings"}/>
 
                
                  {/* custmer routes */}
                <RouteLayout exact path="/request-form" component={RequestForm} menu_name={"client"}/>
                <RouteLayout exact path="/customer-dashboard" component={CustomerDashboard}  menu_name={"client"} replace={true}/>
                <RouteLayout exact path="/order-summary" component={CustomerOrderSchedule} menu_name={"client"}/>
                <RouteLayout exact path="/order-summary-list" component={JobScheduleList} menu_name={"client"} />
                <RouteLayout exact path="/analytics/client-overview" component={ClientOverview} menu_name={"client"} />
                <RouteLayout exact path="/new-order" component={AdminNewOrder} menu_name={"client"}/>
                <RouteLayout exact path="/order-schedule" component={OrderHistory} menu_name={"client"}/>
                <RouteLayout exact path="/invoice" component={Invoice} menu_name={"client"}/>
                <RouteLayout exact path="/new-order-form" component={NewOrderForm} menu_name={"client"} />
                <RouteLayout exact path="/customer-recycle-bin" component={CustomerTrashBin} menu_name={"client"} />
                <RouteLayout exact path="/my-order-recycle-bin" component={CustomerTrashBin} menu_name={"client"} />
                <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer className="custom-toast" autoClose={false} />
      </div>
    </AuthDataProvider>
  );
}


export default App;
