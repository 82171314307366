import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';

export class OrderDiscountForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let discount_name = (item && item.discount_name) ? item.discount_name : '';
    let discount_type = (item && item.discount_type) ? item.discount_type : 'rate';
    let eligible_order_count = (item && item.eligible_order_count) ? item.eligible_order_count : '';
    let discount_value = (item && item.discount_value) ? item.discount_value : '';

    this.state = {
        isLoading: isLoading,
        item: item,

        discount_name: discount_name,
        discount_nameValidated: false,
        discount_type: discount_type,
        discount_typeValidated: false,
        eligible_order_count: eligible_order_count,
        eligible_order_countValidated: false,
        discount_value: discount_value,
        discount_valueValidated: false,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* FORM */
  setForm = () => {

    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Discount Name'}
                        placeholder={'Enter discount Name....'}
                        value={this.state.discount_name}
                        error={this.state.discount_nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                discount_name: e.target.value,
                                discount_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Order Type'}
                    />
                    <RadioGroup aria-label="weight" name="weight" style={{display: 'block'}} value={this.state.discount_type} 
                    onChange={ (e) => {
                        this.setState({
                            discount_type: e.target.value,
                        });
                    }}
                    >
                        <FormControlLabel style={{ display: 'inline-block', width: "80px" }}  value="rate" control={<Radio />} label="Rate" />
                        <FormControlLabel style={{ display: 'inline-block', width: "80px" }}  value="fixed" control={<Radio />} label="Fixed" />
                    
                    </RadioGroup>
                    
                </Grid>
            </Box>
            <Box clone mt={3}>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Order Count'}
                        placeholder={'Enter order count'}
                        value={this.state.eligible_order_count}
                        error={this.state.eligible_order_countValidated}
                        type={'number'}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                eligible_order_count: e.target.value,
                                eligible_order_countValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={"Discount Value"}
                        placeholder={'Enter discoumt Value'}
                        type={'number'}
                        value={this.state.discount_value}
                        error={this.state.discount_valueValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                discount_value: e.target.value,
                                discount_valueValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Save' : 'Save')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        discount_nameValidated: false,
        discount_typeValidated: false,
        eligible_order_countValidated: false,
        discount_valueValidated: false,
    });
    
    if(this.state.discount_name === ''){
      this.setState({
        discount_nameValidated: true
      });
    }
    if(this.state.discount_type === ''){
      this.setState({
        discount_typeValidated: true
      });
    }
    if(this.state.eligible_order_count === ''){
      this.setState({
        eligible_order_countValidated: true
      });
    }
    if(this.state.discount_value === ''){
      this.setState({
        discount_valueValidated: true
      });
    }


    if (form.checkValidity() === true) {
        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        discount_name: this.state.discount_name,
        discount_type: this.state.discount_type,
        eligible_order_count: this.state.eligible_order_count,
        discount_value: this.state.discount_value,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default OrderDiscountForm;