import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';
import wmsApi from '../../api/wmsApi.jsx';
import OldTable from '../../components/Table/OldTable.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access'; 
import CustomPieChart from '../../components/Charts/PieCharts';
import CustomLineChart from '../../components/Charts/LineCharts';
import CustomVerticleBarChart from '../../components/Charts/VerticleBarCharts';
import CustomBarChart from '../../components/Charts/BarCharts';
import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMapWithMarkers';
import MonthlyDeliveryVolumnBarChart from '../../components/Charts/MonthlyDeliveryVolumnBarChart.jsx';
import Scales from '../../assets/img/analytic_icons/Scales.svg'
import NaturalFood from '../../assets/img/analytic_icons/NaturalFood.svg'
import Checkmark from '../../assets/img/analytic_icons/Checkmark.svg'
import FullVersion from '../../assets/img/analytic_icons/FullVersion.svg'
import Fragile from '../../assets/img/analytic_icons/Fragile.svg'
import EditDeliveryTerms from '../../assets/img/analytic_icons/EditDeliveryTerms.svg'
import SingaporeMap from './partial/SingaporeMap.jsx'
import NormalPieChart from '../../components/Charts/NormalPieChart';
import NormalPieChartV2 from '../../components/Charts/NormalPieChartV2';
import NormalPieChartV3 from '../../components/Charts/NormalPieChartV3';
import CustomBarChartRegion from '../../components/Charts/BarChartsRegion.jsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;


class ClientCharts extends Component {
  static contextType = AuthContext;
  pages = 1;
  limits = 10;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(7, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      isLoadingOrder:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingOrderClient : false,
      isLoadingMap: false,
      isLoadingReason: false,
      data: [],
      order_data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_perday: [],
      order_by_size: [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
      overview_data : [],
      order_reasons : [],
      reason_total : [],
      customers: [],
      customer_id: '',
      isLoadingCusomer: false,
      failed_status_id: null,
      green_name: '',
      green_value: 0,
      isLoadingGreenScore: false,
      isLoadingMonthlyDeliveries: false,
      monthly_deliveries: [],
      grennerLoading: false,
      greener_count: 0,
      lockCapture: false,
      delivery_damage_rate: 0,
      deliveryDamageRateLoading: false,
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.callGreenScore()
    this.callCustomerApi();
    this.orderByClientApi();
    this.loadStatusApi(()=> {
      this.callReadApi();
    });  
    this.overviewDataApi();
    this.reasonApi();
    this.callMonthlyDeliveryApi();
    this.getGrennerChoice();
    this.getDeliveryDamageRate();
  }

// API START =================

getDeliveryDamageRate = () => {
  const { accessToken } = this.context;
  
  this.setState({
    deliveryDamageRateLoading: true
  }, () => {
    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    let param = {
      start_date: startDate,
      end_date: endDate,
      customer_id: this.state.customer_id,
    };

      ldsApi.read('/analytic/delivery_damage_rate/', param, accessToken)
      .then((result) => {
        // recent_activity
        apiUtil.parseResult(result, (data) => {
          this.setState({
            delivery_damage_rate: data.result,
            deliveryDamageRateLoading: false,
          });
        }, (error, type) => {
          this.setState({
            delivery_damage_rate: 0,
            deliveryDamageRateLoading: false,
          });
        });

    });
  });      
}

getGrennerChoice = () => {
  const { accessToken } = this.context;
  
  this.setState({
    grennerLoading: true,
  }, () => {    
    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    let param = {
      customer_id: this.state.customer_id,
      start_date: startDate,
      end_date: endDate,
    };
    
    ldsApi.read('/analytic/greener_choice/', param, accessToken)
    .then((result) => {
      // recent_activity
      apiUtil.parseResult(result, (data) => {
        this.setState({
          greener_count: data.result,
          grennerLoading: false,
        });
      }, (error, type) => {
        this.setState({
          driver_entity: [],
          grennerLoading: false,
        });
      });

    });
    
  });    
}

callGreenScore = () => {
  const { accessToken } = this.context;
  
  this.setState({
    isLoadingGreenScore: true
  }, () => {
    let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

    let param = {
      customer_id: this.state.customer_id,
      start_date: startDate,
      end_date: endDate,
    };

      ldsApi.read('/analytic/highest_green_score/', param, accessToken)
      .then((result) => {
        // recent_activity
        apiUtil.parseResult(result, (data) => {
          this.setState({
            green_value: data.value,
            green_name: data.name,
            isLoadingGreenScore: false,
          });
        }, (error, type) => {
          this.setState({
            green_value: 0,
            green_name: '',
            isLoadingGreenScore: false,
          });
        });

    });
  });     
}


callMonthlyDeliveryApi = () => {
  const { accessToken } = this.context;
  
  this.setState({
    isLoadingMonthlyDeliveries: true
  }, () => {

    let param = {
      customer_id: this.state.customer_id,
    };

      ldsApi.read('/analytic/monthly_delivery_volume/', param, accessToken)
      .then((result) => {
        // recent_activity
        apiUtil.parseResult(result, (data) => {
          this.setState({
            monthly_deliveries: data.data,
            isLoadingMonthlyDeliveries: false,
          });
        }, (error, type) => {
          this.setState({
            monthly_deliveries: [],
            isLoadingMonthlyDeliveries: false,
          });
        });

    });
  });     
}

  overviewDataApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
        overview_type: 'client'
      };

        ldsApi.read('/analytic/overview/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              overview_data: data.result,
              do_per_size_total: data.result && data.result.do_per_size_total,
              job_profile_total: data.result && data.result.job_profile_total,
              isLoadingClient: false,
            });
          }, (error, type) => {
            this.setState({
              overview_data: [],
              do_per_size_total: '',
              job_profile_total: '',
              isLoadingClient: false,
            });
          });

      });
    });
  }


  deliveryCollectionMap = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingMap: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/delivery_collection_map/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              delivery_collection_map: dataArr,
              isLoadingMap: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_collection_map: [],
              isLoadingMap: false,
            });
          });

      });
    });
  }

  callReadApi = (search = '', do_num = '', uploader = '', callback=null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        order_status: this.state.failed_status_id,
        start_date: startDate,
        customer_id: this.state.customer_id,
        end_date: endDate,
        take: this.limits,
        page: this.pages,
        sort: "latest"
      };

      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              order_data: (data.result && data.result.length > 0) ? data.result : [],
              isLoading: false,
            });
            if(callback){
              callback(data);
            }
          }, (error, type) => {
            if(callback) {
              callback([]);
            }
            this.setState({
              order_data: [],
              total: 0,
              uploaders: [],
              isLoading: false,
            });
          });
      });
    });
  }


  orderByClientApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingOrderClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/order_by_client/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            // const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              order_by_client: (data.result && data.result.length > 0) ? data.result : [],
              status_total: (data.result && data.result.length > 0) ? data.total : [],
              isLoadingOrderClient: false,
            });
          }, (error, type) => {
            this.setState({
              order_by_client: [],
              isLoadingOrderClient: false,
            });
          });

      });
    });
  }

   reasonApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingReason: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/reasons/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            let dataArr = (data.result && data.result.length > 0) ? data.result : []
            let other_value = 0;
            dataArr.map((dd, index)=> {
              if (index > 6) {
                other_value = other_value + dd.value
              }
            })
            dataArr = dataArr.slice(0,5)
            dataArr.push({
              name: 'others',
              value: other_value,
            })
            this.setState({
              order_reasons: dataArr,
              reason_total: data.total,
              isLoadingReason: false,
            });
          }, (error, type) => {
            this.setState({
              order_reasons: [],
              reason_total: [],
              isLoadingReason: false,
            });
          });

      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        let AckTep = {}
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            AckTep = item
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 0,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            this.setState({
              failed_status_id: item.status_details.id,
            })
            return status.splice(7, 0, {
              order: 1,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } 
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        // status.unshift({
        //   id: 0,
        //   status: 'Total Orders',
        //   total: total,
        // });
   
        status.forEach(st=>{
          if(st.status == "Assigned") {
            st.total = st.total + AckTep.total_job
          }
        })
        
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
          isLoadingStatus: false,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                  if(callback){
                    callback(result, total);
                  }
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }



  callCustomerApi = () => {
  const { accessToken } = this.context;

  this.setState({
    isLoadingCusomer: true,
  },()=> {
    let param = {
      is_active: true,
      invoice_light_data : true,
      sorting_array: ["company_name asc"]
      };

      wmsApi.read("customers", param, accessToken)
      .then( (results) => {
          apiUtil.parseResult(results, (data) => {
          let result = data.result.length > 0 ? data.result : []
          this.setState({
            customers: result.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            }),
            isLoadingCusomer: true,
          })
          }, (error, type) => {
            this.setState({
              isLoadingCusomer: false,
            })
          });
      });
    })
    
  }

// API END ==================
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingOrderClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingReason || this.state.isLoadingMap || this.state.isLoadingSize ? 'none': 'auto' }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.complete_delivery ? this.state.overview_data.complete_delivery : 0) + " / " + (this.state.overview_data.all_delivery ? this.state.overview_data.all_delivery : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Deliveries Completed</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100  d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.active_client ? this.state.overview_data.active_client : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Active Clients</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100  d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.delivery_location ? this.state.overview_data.delivery_location : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Delivery Locations</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : "$"+ (this.state.overview_data.to_be_billed && this.state.overview_data.to_be_billed != 0 ? parseFloat(this.state.overview_data.to_be_billed).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')}</div>
        <div className="fs-6 ps-2 font-medium">Client Job Value</div>
      </Card>
    </Col>
  </Row>
  }

  overviewBottomCard = () => {
    return <Row className="px-4 py-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Delivery Damage Rate</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color  mt-4">{this.state.deliveryDamageRateLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.delivery_damage_rate > 0 ? parseFloat(this.state.delivery_damage_rate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%' : '0.00%')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Fragile} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Job Profile</div>
            {this.state.isLoadingClient? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChartV2 showLable={this.state.lockCapture} legendMarginTop={8} height={100} cx={0} cy={0} data={this.state.overview_data.job_profile} />
            }
      </Card>
    </Col>
    <Col xs={12} lg={4} md={12} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium">Greener Choice <img src={Checkmark} style={{width:'35px', height:'35px'}}/></div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color mt-4">{this.state.grennerLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.greener_count > 0 ? this.state.greener_count : 0)} </div>
      </Card>
    </Col>
  </Row>
  }

  overviewTopCard = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">On-Time Deliveries </div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.on_time_deliveries && this.state.overview_data.on_time_deliveries != 0 ? parseFloat(this.state.overview_data.on_time_deliveries).toFixed(2) + '%': '0.00%')}</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Top 3 Client/User</div>
        {/* {this.state.isLoadingClient? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChart data={this.state.overview_data.top_client_user} layout={'vertical'} />
            } */}
            {this.state.isLoadingOrderClient? 
                <CircularProgress size={35} className="spinner-card" /> :
                <NormalPieChartV3 showLable={this.state.lockCapture} data={this.state.order_by_client} dataKey={'value'} />
              }
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium">Top Green Client <img src={NaturalFood} style={{width:'40px', height:'40px'}}/></div>
        {this.state.isLoadingGreenScore? <CircularProgress size={35} className="spinner-card" /> : 
        <div className='d-flex flex-column align-items-center h-100 justify-content-evenly'>
          <div className="fs-1 font-bold ps-2 secondary-color">{(this.state.green_value ? parseFloat(this.state.green_value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')}</div>
          <div className="fs-5 ps-2 font-medium">{this.state.green_name}</div>
        </div>}
      </Card>
    </Col>
  </Row>
  }

  reasonPie = () => {
    return <Card className="mx-3 analytic-chart-card d-flex flex-column h-400px w-100 align-items-center" style={{overflowY: 'auto'}}>
            <div className="fs-5 font-medium ps-2 pb-2">Reasons for Failure</div>
            {this.state.isLoadingReason? 
              <CircularProgress size={35} className="spinner-card" /> :
              <div className="h-400px w-100">
                <CustomPieChart 
                  showLable={this.state.lockCapture}
                  data={this.state.order_reasons} 
                  dataKey={'value'} 
                  key={'value'} 
                  value1={'name'} 
                  value2={'value'} 
                  vertical_align={"center"} 
                  align={"left"} 
                  status_total={this.state.reason_total} 
                  reason={true}
                />
              </div>
            }
            </Card>
  }

  orderSizeBarChart = () => {
    return <Card className="mx-3 analytic-chart-card d-flex flex-column h-400px w-100 align-items-center">
            <div className="fs-5 font-medium ps-2 pb-2">Job Sizes</div>
                {this.state.isLoadingClient? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomPieChart showLable={this.state.lockCapture} data={this.state.overview_data.do_per_size} is_size={true} dataKey={'total'} key={'total'} value1={'name'} value2={'total'} status_total={this.state.do_per_size_total} vertical_align={'bottom'} align={'center'} class_name={'size-pie-chart'}/>
                }
            </Card>
  }

  monthlyDeliveryVolumn = () => {
    return <Card className="analytic-chart-card h-400px w-100 overflow-auto-card">
            <div className="d-flex flex-column h-100">
            <div className="fs-5 font-medium ps-2 pb-2 text-center">Monthly Delivery Volume</div> 
            <div className="ps-2 pb-2 text-center d-flex justify-content-center align-items-center" style={{fontSize:'12px',marginBottom: 20,marginBottom: 50}}>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#C9C9C9', marginRight: '5px'}}></div> {moment().subtract(2, 'year').year()}</div>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#FCAC42', marginRight: '5px'}}></div> {moment().subtract(1, 'year').year()}</div>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#3151AA', marginRight: '5px'}}></div> {moment().year()}</div>
            </div> 
              {this.state.isLoadingMonthlyDeliveries? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <MonthlyDeliveryVolumnBarChart title="average_drops_per_day" data={this.state.monthly_deliveries} />
                }
            </div>
            </Card>
  }

  customTable = () => {
    return <OldTable 
      ref={this.refTable}
      className={'job-table-reason w-100 reason_for_failure'}
      isLoading={this.state.isLoading}
      paging={false}
      limit={this.limits}
      page={this.pages}
      onPageChange={(page,limit) => {
        this.pages  = page;
        this.limits =  limit;  
        this.callReadApi();   
    }}
      data={this.state.order_data}
      Toolbar={this.customToolbar}
      maxBodyHeight={270}
      Row={this.customRow}
      columns={[
        { 
          title: "Do Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row) => {
            return (row && row.do_number && row.do_number !== '') ? <span style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
          }
        },
        { 
          title: "Delivery Address", 
          field: "drop_off_address",
          width: "40%",
          render: (row) => {
            return row.drop_off_address ? row.drop_off_address : null;
          }
        },
        { 
          title: "Reason", 
          field: "reasons",
          customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[a.job_steps[0].order_attempts.length - 1].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[b.job_steps[0].order_attempts.length - 1].reason: '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
              return failed_reason;
              } 
            } else {
              return row;
            }
          }
        },
      ]}
    />
  }

  DeliveryByZone = () => {
    return <Card className="analytic-chart-card h-400px w-100 overflow-auto-card">
    <div className="d-flex flex-column h-100">
      <div className="fs-5 font-medium ps-2 pb-2 text-center">Deliveries by zones</div>
      {this.state.isLoadingClient? 
                <CircularProgress size={35} className="spinner-card" /> :
                <div className="d-flex flex-column position-relative h-100">
                  <SingaporeMap data={this.state.overview_data.job_region} />
                  <CustomBarChartRegion showLable={this.state.lockCapture} data={this.state.overview_data.job_region} label="name"/>
                </div>
      }
    </div>
    </Card>
  }
  
  handleDownload = () => {
      
    this.setState({
      lockCapture: true,     
    },() => {
      const input = document.getElementById('container-id');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      const btnElement = document.getElementById('download-pdf');
      const calenderElement = document.getElementById('calendar-element');
      
      if (btnElement) {
        btnElement.style.display = 'none';
      }
      
      if (calenderElement) {
        calenderElement.style.display = 'none';
      }
      
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;
      
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('Client.pdf');
        
        if (btnElement) {
          btnElement.style.display = '';
        }
        
        if (calenderElement) {
          calenderElement.style.display = '';
        }
        
        this.setState({
          lockCapture: false 
        });
      });
    });
  };

  render() {
    return <Container fluid id="container-id">
        <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
        <Col xs={12} md={6}  className="p-0 m-0 px-3 d-flex align-items-center">
          <div className="fs-4 font-medium">Analytics for {moment(this.state.startDate).format(apiUtil.getImportDateFormat())} to {moment(this.state.endDate).format(apiUtil.getImportDateFormat())}</div>
        </Col>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center max-xs-width-control" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingOrderClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingReason || this.state.isLoadingMap || this.state.isLoadingSize ? 'none': 'auto' }}>
        <div id="calendar-element">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // Define your two dates
                const date1 = moment(range.startDate);
                const date2 = moment(range.endDate);
                
                // Calculate the difference in years
                const differenceInYears = date2.diff(date1, 'years', true);

                if(differenceInYears > 1){
                  apiUtil.toast('Date range can not more than one year.',  'warning', 'error'); 
                  return;                  
                }
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </div>
          <Button className="upload-style"
          id="download-pdf"
          onClick={() => {
            this.handleDownload();
          }}
          ><FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>
      {this.overviewHeader()}
      <div className="ps-4 mt-4 ms-2 max-xs-width-control-100" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
        <select className="custom-input-component" style={{border: "1px solid #8E8E8E", borderRadius: "8px",padding:"8px", backgroundColor: '#ffffff'}}
          placeholder={'Select a Client'}
          onChange={(e) => {
            this.setState({
              customer_id : e.target.value
            },()=> {
              this.callInitApi();
            })
          }}
        >
 
          <option value="" > - Select a Client - </option>
          {this.state.customers.length > 0 && this.state.customers.map((cu)=> (
                <option value={cu.id}>{cu.company_name}</option>
            ))}
        </select>
      </div>
      {this.overviewTopCard()}
      <Row className="pt-4 px-4 overflow-hidden" style={{rowGap:'1.5em'}}>

        <Col xs={12} md={12} lg={8} className="p-0 m-0 px-3 d-flex justify-content-center">
            {this.DeliveryByZone()}
        </Col>
        
        <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
            {this.orderSizeBarChart()}
        </Col>

        <Col xs={12} md={12} lg={8} className="p-0 m-0 px-3 d-flex justify-content-center">
          {/* <Card className={'mx-3 recent-activities analytic-chart-card h-350px w-100'}> */}
            {this.monthlyDeliveryVolumn()}
          {/* </Card> */}
        </Col>

        <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
            {this.reasonPie()}
        </Col>
      </Row>
      {this.overviewBottomCard()}
    </Container>;
  }
}

export default ClientCharts;