import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import trackerLogo from '../../assets/img/tracker_logo.png';
import Footer from '../Footer/Footer.jsx'

class PrivacyPolicy extends Component {
  
    render() {
      return <div className="terms-condition">

      <div className="top-menu">
        <div className="logo"><a href="/" alt="home"><img src={trackerLogo} alt="" height="30px" /></a></div>
        <div className="signup-acc"> 
        <div>Don't have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-up">
          Sign Up
        </Button>
        </div>
      </div>
      <Container>
      
      <Typography variant="h5" gutterBottom><b>PRIVACY POLICY</b></Typography>
      <Typography variant="body1" gutterBottom>This policy sets out:
      <ul style={{listStyleType:"none"}}>
          <li><Typography variant="body1" gutterBottom>(A) the information we collect about you when you visit our website, or otherwise interact with us;</Typography></li>
          <li><Typography variant="body1" gutterBottom>(B) how we use, share, store, and secure the information; and</Typography></li>
          <li><Typography variant="body1" gutterBottom>(C) how you may access and control the information.</Typography></li>
        </ul>
        In this policy, “<b>Evfy</b>” or “we” refers to <b>Evfy Pte. Ltd.</b> of 84 Genting Ln, #02-02, Singapore 349584, and “<b>Platform</b>” means our website at  and our software, namely Evfy Tracker.
      </Typography>
      <Typography variant="body1" gutterBottom>In this policy, “<b>personal information</b>” refers to any data, information, or combination of data and information that is provided by you to us that relates to an identifiable individual.</Typography>
      <Typography variant="body1" gutterBottom>Evfy Tracker is a B2B cloud service offering. If you are a customer of a Evfy Tracker’s user, please direct your enquiries to them. The withdrawal, access and correction of personal data listed below is only applicable if Evfy Tracker collects your personal data.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>1 What information we collect about you</b></Typography>
      <Typography variant="body1" gutterBottom>1.1 We collect the following types of information about you:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) information you provide through our communication channels, for example when you send us a message with your name and organization email address via contact us or communicate with us via emails or written correspondences;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) information about your device or connection, for example your internet protocol (IP) address, browser type and version, time-zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website and information we collect through cookies and other data collection technologies (please read our Cookies Policy for details); and</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) Information about your use of or visit to our Platform, for example your clickstream to, through, and from our Platform, products you viewed, used, or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), or methods to browse away from the page.</Typography></li>
      </ul>
      <Typography variant="body1" gutterBottom>1.2 If you choose not to provide us with the information listed 1.1 (a), we will not be able to fully respond to or fulfil your enquiries.</Typography>
      <Typography variant="body1" gutterBottom>1.3 We do not collect sensitive data or special category data about you. This includes details about your race, ethnic origin, politics, religion, trade union membership, genetics, biometrics, health, or sexual orientation.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>2 How we use the information we collect</b></Typography>
      <Typography variant="body1" gutterBottom>2.1 We only use your personal information where the law allows us to. We use your personal information only where:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) we need to respond to your enquiries;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) it satisfies a legitimate interest which is not overridden by your fundamental rights or data protection interests, for example for research and development, and in order to protect our legal rights and interests; or</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) we need to comply with a legal or regulatory obligation.</Typography></li>
      </ul>
      <Typography variant="body1" gutterBottom>2.2 If you have given us consent to use your personal information for a specific purpose, you have the right to withdraw your consent any time by contacting us (please refer to Section 13 for contact information), but please note this will not affect any use of your information that has already taken place.</Typography>
      <Typography variant="body1" gutterBottom>2.3 We do not share your personal information with any company outside our group for marketing purposes unless with your express specific consent to do so.</Typography>
      <Typography variant="body1" gutterBottom>2.4 For visitors to our Platform who are located in the European Union, we have set out our legal bases for processing your information in the Legal Bases Table at the end of this policy.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>5 Your account and password</b></Typography>
      <Typography variant="body1" gutterBottom>3 How we share information we collect</Typography>
      <Typography variant="body1" gutterBottom>3.1 We only share anonymized information with third parties that help us operate, provide, support, improve, and market our products and services, for example third-party service providers who provide business analytics, and other relevant services in relation to marketing and advertising (No personal information will be shared).</Typography>
      <Typography variant="body1" gutterBottom>3.2 Our Platform may contain links to third-party websites over which we have no control. If you follow a link to any of these websites or submit information to them, your information will be governed by their policies. We encourage you to review the privacy policies of third-party websites before you submit information to them.</Typography>
      <Typography variant="body1" gutterBottom>3.3 We may share your information with government and law enforcement officials to comply with applicable laws or regulations, for example when we respond to claims, legal processes, law enforcement, or national security requests.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>4 How we store and secure information we collect</b></Typography>
      <Typography variant="body1" gutterBottom>4.1 We use data hosting service providers based in the US to host the information we collect.</Typography>
      <Typography variant="body1" gutterBottom>4.2 We have adopted the following measures to protect the security and integrity of your personal information:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) access to your personal information is restricted to personnel or service providers on a strictly need-to-know basis, who will only process your information on our instructions and who are subject to a duty of confidentiality; and</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) our information collection, storage, and processing practices are reviewed regularly</Typography></li>
      </ul>
      <Typography variant="body1" gutterBottom>4.3 We have put in place procedures to deal with any suspected privacy breach and will notify you and any applicable regulator of a breach where we are legally required to do so, no later than 72 hours after establishing that the data breach has happened.</Typography>
      <Typography variant="body1" gutterBottom>4.4 While we implement safeguards designed to protect your information, please note that no transmission of information on the Internet is completely secure. We cannot guarantee that your information, during transmission through the Internet or while stored on our systems or processed by us, is absolutely safe and secure.</Typography>
      <Typography variant="body1" gutterBottom>4.5 We only retain personal information for so long as it is reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. After such time, we will delete or anonymize your information, or if this is not possible, we will securely store your information and isolate it from further use. We periodically review the basis and appropriateness of our data retention policy.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>5 Your rights</b></Typography>
      <Typography variant="body1" gutterBottom>5.1 You have the right to:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) be informed of what we do with your personal information;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) request a copy of personal information we hold about you;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) require us to correct any inaccuracy or error in any personal information we hold about you;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(d) request erasure of your personal information (note, however, that we may not always be able to comply with your request of erasure for record keeping purposes, to complete transactions, or to comply with our legal obligations);</Typography></li>
        <li><Typography variant="body1" gutterBottom>(e) object to or restrict the processing by us of your personal information (including for marketing purposes);</Typography></li>
        <li><Typography variant="body1" gutterBottom>(f) request to receive some of your personal information in a structured, commonly used, and machine readable format, and request that we transfer such information to another party; and</Typography></li>
        <li><Typography variant="body1" gutterBottom>(g) withdraw your consent at any time where we are relying on consent to process your personal information (although this will not affect the lawfulness of any processing carried out before you withdraw your consent).</Typography></li>
      </ul>
      <Typography variant="body1" gutterBottom>5.2 You may opt out of receiving marketing materials from us by using the unsubscribe link in our communications, by updating your preferences within your account on our Platform, or by contacting us. Please note, however, that even if you opt out from receiving marketing materials from us, you will continue to receive notifications or information from us that are necessary for the use of our products or services.</Typography>
      <Typography variant="body1" gutterBottom>5.3 As a security measure, we may need specific information from you to help us confirm your identity when processing your privacy requests or when you exercise your rights.</Typography>
      <Typography variant="body1" gutterBottom>5.4 Any request under Section 5.1 will normally be addressed free of charge. However, we may charge a reasonable administration fee if your request is clearly unfounded, repetitive, or excessive.</Typography>
      <Typography variant="body1" gutterBottom>5.5 We will respond to all legitimate requests within three (3) working days. If we are unable to fulfil your request within fifteen (15) working days after receiving the request, we will inform you via email of the timeline by which we are able to fulfil the request.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>6 Withdrawing your consent</b></Typography>
      <Typography variant="body1" gutterBottom>6.1 The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop collecting, using and/or disclosing your personal data by submitting your request in writing to our Data Protection Officer at the contact details provided below.</Typography>
      <Typography variant="body1" gutterBottom>6.2 Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and liabilities to us. In general, we will respond to your request within three (3) working days of receiving it but actual processing of the data will take effect within fifteen (15) working days after receiving the request. If we are unable to fulfil your request within fifteen (15) working days after receiving the request, we will inform you via email of the timeline by which we are able to fulfil the withdrawal of consent request.</Typography>
      <Typography variant="body1" gutterBottom>6.3 Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of your request, we may not be in a position to continue our correspondence with you or to process your job application (as the case may be). We shall, in such circumstances, notify you before completing the processing of your request (as outlined above). Should you decide to cancel your withdrawal of consent, please inform us in writing in the manner described in Section 6.1 above.</Typography>
      <Typography variant="body1" gutterBottom>6.4 Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal data where such collection, use and disclose without consent is permitted or required under applicable laws.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>7 Accessing to and correction of personal data</b></Typography>
      <Typography variant="body1" gutterBottom>7.1 If you wish to make (a) an access request for access to a copy of the personal data which we hold about you or information about the ways in which we use or disclose your personal data, or (b) a correction request to correct or update any of your personal data which we hold about you, you may submit your request in writing to our Data Protection Officer at the contact details provided below.</Typography>
      <Typography variant="body1" gutterBottom>7.2 Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the fee before processing your request.</Typography>
      <Typography variant="body1" gutterBottom>7.3 We will respond to your request as soon as reasonably possible. In general, we will respond to your request within three (3) working days of receiving it but actual processing will take effect within fifteen (15) working days after receiving the request. Should we not be able to fulfil your access request within fifteen (15) working days after receiving your access request, we will inform you in writing within fifteen (15) working days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</Typography>
      <Typography variant="body1" gutterBottom>7.4 Please note that depending on the request that is being made, we will only need to provide you with access to the personal data contained in the documents requested, and not to the entire documents themselves. In those cases, it may be appropriate for us to simply provide you with confirmation of the personal data that our organization has on record, if the record of your personal data forms a negligible part of the document.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>8 Situations where we may collect personal data without consent</b></Typography>
      <Typography variant="body1" gutterBottom>8.1 Evfy may collect personal data about you (an individual) without your consent or from a source other than the individual in any of the following circumstances:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) the personal data is publicly available;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) the collection is necessary in the national interest;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) the collection is necessary for any investigation or proceedings, if it is reasonable to expect that seeking the consent of the individual would compromise the availability or the accuracy of the personal data; or</Typography></li>
        <li><Typography variant="body1" gutterBottom>(d) the collection is necessary for the provision of legal services by Evfy to another person or for Evfy to obtain legal services.</Typography></li>
      </ul>
      <Typography variant="subtitle1" gutterBottom><b>9 Situations where we may use personal data without consent</b></Typography>
      <Typography variant="body1" gutterBottom>9.1 Evfy may use personal data about you (an individual) without your consent in any of the following circumstances:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) the use is necessary to respond to an emergency that threatens the life, health or safety of the individual or another individual;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) the personal data is publicly available;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) the use is necessary in the national interest; or</Typography></li>
        <li><Typography variant="body1" gutterBottom>(d) the use is necessary for the provision of legal services by Evfy to another person or for Evfy to obtain legal services. </Typography></li>
       </ul>
      <Typography variant="subtitle1" gutterBottom><b>10 Situations we may disclose personal data without consent</b></Typography>
      <Typography variant="body1" gutterBottom>10.1 Evfy may disclose personal data about you (an individual) without the consent in any of the following circumstances:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) the disclosure is necessary to respond to an emergency that threatens the life, health or safety of the individual or another individual;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(b) there are reasonable grounds to believe that the health or safety of the individual or another individual will be seriously affected and consent for the disclosure of the data cannot be obtained in a timely way;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(c) the disclosure is necessary in the national interest;</Typography></li>
        <li><Typography variant="body1" gutterBottom>(d) the disclosure is necessary for the provision of legal services by Evfy to another person or for Evfy to obtain legal services; or</Typography></li>
        <li><Typography variant="body1" gutterBottom>(e) the personal data is disclosed to any officer of a prescribed law enforcement agency, upon production of written authorization signed by the head or director of that law enforcement agency or a person of a similar rank, certifying that the personal data is necessary for the purposes of the functions or duties of the officer.</Typography></li>
       </ul>
      <Typography variant="subtitle1" gutterBottom><b>11 Changes to this policy</b></Typography>
      <Typography variant="body1" gutterBottom>11.1 We may amend this policy from time to time by posting the updated policy on our Platform. By continuing to visit our Platform after the changes come into effect, you agree to be bound by the revised policy.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>12 Policy towards children</b></Typography>
      <Typography variant="body1" gutterBottom>12.1 Our products and services are not directed to individuals under 16. We do not knowingly collect personal information from individuals under 16. If we become aware that an individual under 16 has provided us with personal information, we will take steps to delete such information. Contact us if you believe that we have mistakenly or unintentionally collected information from an individual under 16.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>13 Contact us</b></Typography>
      <Typography variant="body1" gutterBottom>13.1 Please contact us at <a href="mailto:enquiry@evfy.sg">enquiry@evfy.sg</a></Typography>
      <Typography variant="body1" gutterBottom>13.2 By contacting us at <a href="mailto:enquiry@evfy.sg">enquiry@evfy.sg</a>, you have deemed to read and agree to the privacy policy.</Typography>
      <Typography variant="body1" gutterBottom>13.3 Please contact us in the first instance if you have any questions or concerns. If you have unresolved concerns, you have the right to file a complaint with a data protection authority in the country where you live or work or where you feel your rights have been infringed.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>14 COOKIES POLICY</b></Typography>
      <Typography variant="body1" gutterBottom>Cookies are small text files that are placed on your device by a web server when you access our Platform. We use cookies to identify your access and monitor usage and web traffic on our Platform to customize and improve our website user experience and for the purpose of business analytics.</Typography>
      <Typography variant="body1" gutterBottom>We use both persistent cookies and session cookies. A persistent cookie stays in your browser and will be read by us when you return to our Site or a partner site that uses our services. Session cookies only last for as long as the session lasts (usually the current visit to a website or a browser session). There will not be any personal information collected using cookies.</Typography>
      <Typography variant="body1" gutterBottom>We use the following types of cookies:</Typography>
      <ul style={{listStyleType:"none"}}>
        <li><Typography variant="body1" gutterBottom>(a) Analytical/performance cookies – these allow us to recognize and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our site works, for example, by ensuring that users are easily finding what they are looking for.</Typography></li>
      </ul>
      <Typography variant="body1" gutterBottom>You can block cookies by activating the setting on your browser that allows you to refuse the use of all or some cookies. However, if you do so, you may not be able to access all or parts of our site.</Typography>
      <Typography variant="subtitle1" gutterBottom><b>15 LEGAL BASES TABLE</b></Typography>
      <table style={{width:"60%"}}>
        <tr style={{height:"45px", backgroundColor:"#d3d2d2c7"}}>
          <th>Processing purpose</th>
          <th>Type of data processed</th>
          <th>Legal basis</th>
        </tr>
        <tr style={{height:"50px"}}>
          <td>To direct your enquiry to our support team<br/></td>
          <td>Name, email address</td>
          <td>To reply your enquiry</td>
        </tr><tr>
          <td>To improve visitors’ experience to our website and for business analytics</td>
          <td>Internet protocol (IP) address, browser type and version, time-zone setting, browser plug-in types and versions, operating system and platform</td>
          <td>To improve your experience to our website and better targeting of content relevant to you</td>
        </tr>
      </table>
     
      </Container>
      <div style={{backgroundColor: "#555555"}}><Footer /></div>
    </div>;
   }
}

export default PrivacyPolicy;