import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';
import MonthlyDeliveryVolumnBarChart from '../../components/Charts/MonthlyDeliveryVolumnBarChart.jsx';
import CustomLiveTrackingMap from '../../components/GoogleMap/CustomLiveTrackingMapWithMark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access'; 
import CustomPieChart from '../../components/Charts/PieCharts.jsx';
// import SizePieChart from '../../components/Charts/';
import NormalPieChart from '../../components/Charts/NormalPieChart';
import NormalPieChartV2 from '../../components/Charts/NormalPieChartV2';
import CustomLineChart from '../../components/Charts/LineCharts';
import CustomVerticleBarChart from '../../components/Charts/VerticleBarCharts';
import CustomBarChartRegion from '../../components/Charts/BarChartsRegion.jsx';
import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMapWithMarkers';
import CustomHorizontalBarChart from '../../components/Charts/HorizontalBarChart';

import MatrixGrid from './partial/MartixGrid.jsx'
import SingaporeMap from './partial/SingaporeMap.jsx'

import Scales from '../../assets/img/analytic_icons/Scales.svg'
import NaturalFood from '../../assets/img/analytic_icons/NaturalFood.svg'
import FullVersion from '../../assets/img/analytic_icons/FullVersion.svg'
import Fragile from '../../assets/img/analytic_icons/Fragile.svg'
import EditDeliveryTerms from '../../assets/img/analytic_icons/EditDeliveryTerms.svg'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;


class OverviewCharts extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(7, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      isLoadingOrder:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingMap: false,
      distanceLoading: false,
      data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_perday: [],
      order_by_size: [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
      overview_data : [],
      distance_travel: [],
      driver_entity: [],
      total_kilometer: 0,
      highestKilo: 0,
      co2EvLoading: false,
      co2_ev_saving: 0,
      isLoadingMonthlyDeliveries: false,
      monthly_deliveries: [],
      lockCapture: false,
      driverProfileLoading: false,
      driver_profile: [],
      delivery_damage_rate: 0,
      deliveryDamageRateLoading: false,
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.loadStatusApi();  
    this.overviewDataApi();
    this.getCo2Ev();
    this.callMonthlyDeliveryApi();
    this.getDriverProfile();
    this.getDeliveryDamageRate();
  }

// API START =================

  getDeliveryDamageRate = () => {
    const { accessToken } = this.context;
    
    this.setState({
      deliveryDamageRateLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
  
        ldsApi.read('/analytic/delivery_damage_rate/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              delivery_damage_rate: data.result,
              deliveryDamageRateLoading: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_damage_rate: 0,
              deliveryDamageRateLoading: false,
            });
          });
  
      });
    });      
  }

  getDriverProfile = () => {
    const { accessToken } = this.context;
    
    this.setState({
      driverProfileLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
  
        ldsApi.read('/analytic/driver_profile/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              driver_profile: data.result,
              driverProfileLoading: false,
            });
          }, (error, type) => {
            this.setState({
              driver_profile: [],
              driverProfileLoading: false,
            });
          });
  
      });
    });        
  }
  
  getCo2Ev = () => {
    const { accessToken } = this.context;
    
    this.setState({
      co2EvLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
  
        ldsApi.read('/analytic/co2_ev_saving/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              co2_ev_saving: data.result.co2e_saving ? data.result.co2e_saving : 0,
              co2EvLoading: false,
            });
          }, (error, type) => {
            this.setState({
              co2_ev_saving: 0,
              co2EvLoading: false,
            });
          });
  
      });
    });     
  }
  callMonthlyDeliveryApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingMonthlyDeliveries: true
    }, () => {
  
      let param = {
        customer_id: this.state.customer_id,
      };
  
        ldsApi.read('/analytic/monthly_delivery_volume/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              monthly_deliveries: data.data,
              isLoadingMonthlyDeliveries: false,
            });
          }, (error, type) => {
            this.setState({
              monthly_deliveries: [],
              isLoadingMonthlyDeliveries: false,
            });
          });
  
      });
    });     
  }

  
  overviewDataApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
        overview_type: 'overview'
      };

        ldsApi.read('/analytic/overview/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              overview_data: data.result,
              do_per_size_total: data.result && data.result.do_per_size_total,
              job_profile_total: data.result && data.result.job_profile_total,
              isLoadingClient: false,
            }, ()=> {
              // console.log("sss", this.state.overview_data)
            });
          }, (error, type) => {
            this.setState({
              overview_data: [],
              do_per_size_total: '',
              job_profile_total: '',
              isLoadingClient: false,
            });
          });

      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        let AckTep = {}
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 7,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 8,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 10,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
            return status.splice(10, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          }
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        // status.unshift({
        //   id: 0,
        //   status: 'Total Orders',
        //   total: total,
        // });
   
        // status.forEach(st=>{
        //   if(st.status == "Assigned") {
        //     st.total = st.total + AckTep.total_job
        //   }
        // })
        
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
          isLoadingStatus: false,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                  if(callback){
                    callback(result, total);
                  }
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
// API END ==================


  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    if (!this.state.isLoadingClient) {
      // console.log(this.state.overview_data.on_time_deliveries , "this.state.overview_data.on_time_deliveries ")
    } 
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.complete_delivery ? this.state.overview_data.complete_delivery : 0) + " / " + (this.state.overview_data.all_delivery ? this.state.overview_data.all_delivery : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Jobs Completed</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.on_time_deliveries && this.state.overview_data.on_time_deliveries != 0 ? parseFloat(this.state.overview_data.on_time_deliveries).toFixed(2) : '0.00')+'%'} </div>
        <div className="fs-6 ps-2 font-medium">On-Time Deliveries </div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.driver_engage ? this.state.overview_data.driver_engage : 0) +' : '+(this.state.overview_data.client_serve ? this.state.overview_data.client_serve : 0)} </div>
        <div className="fs-6 ps-2 font-medium">Driver : Client</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  "$"+ (this.state.overview_data.profit ? parseFloat(this.state.overview_data.profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')} </div>
        <div className="fs-6 ps-2 font-medium">Gross Profit</div>
      </Card>
    </Col>
  </Row>
  }

  overviewBottomCard = () => {
    return <Row className="px-4 py-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">CO2e Savings </div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color d-flex h-100 justify-content-center align-items-center">{this.state.co2EvLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.co2_ev_saving && this.state.co2_ev_saving != 0? parseFloat(this.state.co2_ev_saving).toFixed(2) : '0.00') + 'kg'}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={NaturalFood} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Capacity Utilisation</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color  d-flex h-100 justify-content-center align-items-center">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.avg_capacity_utalization && this.state.overview_data.avg_capacity_utalization != 0? parseFloat(this.state.overview_data.avg_capacity_utalization).toFixed(2) : '0.00')+'%'} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={FullVersion} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Avg. Jobs per Driver</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color  d-flex h-100 justify-content-center align-items-center">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.average_job_per_driver && this.state.overview_data.average_job_per_driver != 0? parseFloat(this.state.overview_data.average_job_per_driver).toFixed(2) : '0.00')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={EditDeliveryTerms} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Job Profile</div>
            {this.state.isLoadingClient? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChartV2 cx={0} cy={0} showLable={this.state.lockCapture} height={100} legendMarginTop={8} data={this.state.overview_data.job_profile}/>
            }
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px  w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Pick Up : Delivery</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color d-flex h-100 justify-content-center align-items-center">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.all_pick_up ? this.state.overview_data.all_pick_up : 0) +' : '+(this.state.overview_data.all_delivery ? this.state.overview_data.all_delivery : 0)} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Scales} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
      <Card className="position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Delivery Damage Rate</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color  d-flex h-100 justify-content-center align-items-center">{this.state.deliveryDamageRateLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.delivery_damage_rate > 0 ? parseFloat(this.state.delivery_damage_rate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Fragile} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={8} className="p-0 m-0 px-3 d-flex justify-content-center">
        {this.DeliveryByZone()}
    </Col>
  </Row>
  }

  monthlyDeliveryVolumn = () => {
    return <Card className="analytic-chart-card h-400px w-100 overflow-auto-card">
            <div className="card-overflow d-flex flex-column">
            <div className="fs-5 font-medium ps-2 pb-2 text-center">Monthly Delivery Volume</div> 
            <div className="ps-2 pb-2 text-center d-flex justify-content-center align-items-center" style={{fontSize:'12px',marginBottom: 50}}>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#C9C9C9', marginRight: '5px'}}></div> {moment().subtract(2, 'year').year()}</div>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#FCAC42', marginRight: '5px'}}></div> {moment().subtract(1, 'year').year()}</div>
                <div className='m-2 d-flex align-items-center justify-content-center'><div style={{width:'15px', height:'15px', backgroundColor: '#3151AA', marginRight: '5px'}}></div> {moment().year()}</div>
            </div> 
              {this.state.isLoadingMonthlyDeliveries? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <MonthlyDeliveryVolumnBarChart title="average_drops_per_day" data={this.state.monthly_deliveries} />
                }
            </div>
            </Card>
  }

  statusPie = () => {
    return <Card className="analytic-chart-card w-100 h-420px overflow-auto-card">
    <div className="card-overflow d-flex flex-column">
      <div className="fs-5 font-medium ps-2 pb-2 text-center">Job Status Summary</div>
      {this.state.isLoadingStatus? 
                <CircularProgress size={35} className="spinner-card h-100" /> :
                <MatrixGrid showLable={this.state.lockCapture} data={this.state.status} total={this.state.status_total}/>
      }
    </div>
    </Card>
  }
  
  jobCompleteProfile = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-200px w-100 justify-content-start align-items-center">
            <div className="fs-5 font-medium ps-2 pb-2">Vehicle Usage</div>
            {this.state.isLoadingClient? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChart showLable={this.state.lockCapture} data={this.state.overview_data.job_complete_driver} layout={'vertical'} />
            }
            </Card>      
  }
  
  driverProfile = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-200px w-100 justify-content-start align-items-center">
            <div className="fs-5 font-medium ps-2 pb-2">Driver Profile</div>
            {this.state.driverProfileLoading? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChart posRight={'-8%'} showLable={this.state.lockCapture} data={this.state.driver_profile} layout={'vertical'}/>
            }
            </Card>      
  }

  orderSizeBarChart = () => {
    return <Card className="analytic-chart-card-jobsize d-flex flex-column h-400px w-100 justify-content-start align-items-center">
            <div className="fs-5 font-medium ps-2 pb-2">Job Sizes</div>
                {this.state.isLoadingClient? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomPieChart showLable={this.state.lockCapture} data={this.state.overview_data.do_per_size} dataKey={'total'} is_size={true} key={'total'} value1={'name'} value2={'total'} status_total={this.state.do_per_size_total} vertical_align={'bottom'} align={'center'} class_name={'size-pie-chart'}/>
                }
            </Card>
  }

  clientDriverBillingBarChart = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-350px w-100 custom-card-none-margin-x">
            <div className="fs-5 font-medium ps-2 pb-2">Client Billing vs Driver Payout</div> 
              {this.state.isLoadingPayout? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomVerticleBarChart data={this.state.client_driver_billing} />
                }
            </Card>
  }

  DeliveryByZone = () => {
    return <Card className="analytic-chart-card h-400px w-100 overflow-auto-card">
    <div className="d-flex flex-column h-100">
      <div className="fs-5 font-medium ps-2 pb-2 text-center">Deliveries by zones</div>
      {this.state.isLoadingClient? 
                <CircularProgress size={35} className="spinner-card" /> :
                <div className="d-flex flex-column position-relative h-100">
                  <SingaporeMap data={this.state.overview_data.job_region} />
                  <CustomBarChartRegion showLable={this.state.lockCapture} data={this.state.overview_data.job_region} label="name"/>
                </div>
      }
    </div>
    </Card>
  }
  
  handleDownload = () => {
      
    this.setState({
      lockCapture: true,     
    },() => {
      const input = document.getElementById('container-id');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      const btnElement = document.getElementById('download-pdf');
      const calenderElement = document.getElementById('calendar-element');
      
      if (btnElement) {
        btnElement.style.display = 'none';
      }
      
      if (calenderElement) {
        calenderElement.style.display = 'none';
      }
      
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;
      
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('Overview.pdf');
        
        if (btnElement) {
          btnElement.style.display = '';
        }
        
        if (calenderElement) {
          calenderElement.style.display = '';
        }
        
        this.setState({
          lockCapture: false 
        });
      });
    });
  };
  
  render() {
    return <Container fluid id="container-id">
        <Row className="px-4 pt-4 overflow-hidden">
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex align-items-center">
          <div className="fs-4 font-medium">Analytics for {moment(this.state.startDate).format(apiUtil.getImportDateFormat())} to {moment(this.state.endDate).format(apiUtil.getImportDateFormat())}</div>
        </Col>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center max-xs-width-control" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.distanceLoading ? 'none': 'auto' }}>
        <div id="calendar-element">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // Define your two dates
                const date1 = moment(range.startDate);
                const date2 = moment(range.endDate);
                
                // Calculate the difference in years
                const differenceInYears = date2.diff(date1, 'years', true);

                if(differenceInYears > 1){
                  apiUtil.toast('Date range can not more than one year.',  'warning', 'error'); 
                  return;                  
                }
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </div>
          <Button className="upload-style"
          id="download-pdf"
          onClick={() => {
            this.handleDownload();
          }}
          ><FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>
      {this.overviewHeader()}
      <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
        
        <Col xs={12} md={12} lg={8} className="p-0 m-0 px-3 d-flex justify-content-center">
            {this.statusPie()}
        </Col>
        
        <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-between flex-column align-items-center">
            {this.jobCompleteProfile()}

            {this.driverProfile()}
        </Col>
        <Col xs={12} md={12} lg={8} className="p-0 m-0 px-3 d-flex justify-content-center">
            {/* {this.DeliveryByZone()} */}
            {this.monthlyDeliveryVolumn()}
        </Col>
        
        <Col xs={12} md={12} lg={4} className="p-0 m-0 px-3 d-flex justify-content-center">
            {this.orderSizeBarChart()}
        </Col>
      </Row>
      {this.overviewBottomCard()}
    </Container>;
  }
}

export default OverviewCharts;