import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';


const COLORS = ['#00D3B3', '#FFC106', '#E91D62', '#BBA9A3'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, total }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text fill="black" >
      {(percent * 100).toFixed(2) + '%'} 
    </text>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, name, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  // const textAnchor = cos >= 0 ? 'start' : 'end';
  // Set a fixed x-coordinate for the text
  const textX = cx + outerRadius + 20;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        <strong>Total</strong> <br />{payload.status_total}
      </text>
      <text x={textX} y={cy} dy={20} textAnchor={'start'} fill="#000000"  className="px-1 bg-white fw-bold" style={{zIndex: 4000}}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      /> */}
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text x={mx} y={ey} textAnchor={textAnchor} fill="#333"  className="px-1 bg-white" style={{zIndex: 4000}}>{`${name} ${value}`}</text> */}
    
    </g>
  );
};

const CustomTooltip = ({ active, payload, total  }) => {
  if (active && payload && payload.length) {
    const entry = payload[0].payload; // Assuming you have only one entry in the payload

    const value = entry.value ? entry.value : entry.total; // You need to get the total from your data
    const percent = (parseInt(value) / parseInt(total)) * 100;
    return (
      <div className="custom-tooltip">
        <p>{percent.toFixed(2) + '% ('+value+')'} </p>
      </div>
    );
  }

  return null;
};

export default class NormalPieChart extends PureComponent {

  
  constructor(props){
    super(props);
    let data = props.data? props.data : [];
    let cx = props.cx ? props.cx : 200;
    let cy = props.cy ? props.cy : 130;
    let layout = props.layout ? props.layout : 'vertical';
    let color = props.color ? props.color : COLORS;
    let height = props.height ? props.height : 200;
    let width = props.width ? props.width : 200;
    let legendAlign = props.legendAlign ? props.legendAlign : 'middle'
    let className = props.className ? props.className : 'normal-pie-chart'
    let textAlign = props.textAlign ? props.textAlign : 'right'
    let dataKey = props.dataKey ? props.dataKey : ''
    let legendMarginTop = props.legendMarginTop ? props.legendMarginTop : 0
    let total = 0;

    data.forEach(dd => {
      if (dd.value) {
        total += parseInt(dd.value) || 0;
      } else {
        total += parseInt(dd.total) || 0;
      }
    });

    this.state = {
      data : data,
      cx: cx,
      cy: cy,
      total:total,
      layout: layout,
      color: color,
      height: height,
      dataKey: dataKey,
      width: width,
      legendAlign: legendAlign,
      className: className,
      textAlign: textAlign,
      legendMarginTop: legendMarginTop,
    }
  }
  
  
  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };
  
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    if(this.props.showLable) {
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + 10; // Adjust the label distance from the outer radius
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      
      // Calculate the percentage
      const total = this.state.data.reduce((acc, entry) => acc + entry.value, 0);
      const percentage = ((this.state.data[index].value / total) * 100).toFixed(2);
      
      if (this.state.data[index].value === 0) {
        return null;
      }

      return (
        <text x={x} y={y} style={{fontSize: 12,fontWeight: 'bold'}} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${percentage}%`}
        </text>
      );
    }
  };

  render() {
    return (
    <div className='w-100' style={{height: '200px'}}>
      {this.state.data.length > 0 ?
       <ResponsiveContainer width='100%' height='100%'>
        <PieChart width={this.state.width} height={this.state.height} className={this.state.className + " overflow-ttt"}>
          <Legend 
          layout={this.state.layout}
          wrapperStyle={{marginTop: this.state.legendMarginTop,right: '-7%'}}
          payload={
            this.state.data.map(
              (item, index) => ({
                id: item.name,
                type: "circle",
                value: item.name,
                color: this.state.color[index % this.state.color.length]
              })
            )
          }
          verticalAlign={this.state.legendAlign} 
          align={this.state.textAlign} 
          iconType='circle'
          />
          <Pie
            data={this.state.data}
            cx={'60%'}
            cy={'50%'}
            innerRadius={0}
            outerRadius={45}
            fill="#8884d8"
            paddingAngle={0}
            dataKey={this.state.dataKey ? this.state.dataKey : "value"}
            // activeShape={renderActiveShape}
            onMouseEnter={this.onPieEnter}
            activeIndex={this.state.activeIndex}
            label={this.renderCustomizedLabel}
            labelLine={false}
          >
            {this.state.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={this.state.color[index % this.state.color.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip total={this.state.total}/>} />
        </PieChart>
      </ResponsiveContainer>
      :
      <div className="d-flex justify-content-center align-items-center">There is no status for date range !!</div>}      
    </div>      
    );
  }
}
