import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access'; 
import CustomPieChart from '../../components/Charts/PieCharts';
import CustomLineChart from '../../components/Charts/LineCharts';
import CustomVerticleBarChart from '../../components/Charts/VerticleBarCharts';
import CustomBarChart from '../../components/Charts/BarCharts';
import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMapWithMarkers';

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;


class Charts extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(3, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(3, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      isLoadingOrder:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingMap: false,
      data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_perday: [],
      order_by_size: [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.callDeliveryPerDayApi();
    this.loadStatusApi();  
    this.orderBySizeApi();
    this.clientDriverPayoutApi();
    this.orderByClientApi();
    this.deliveryCollectionMap();
  }

// API START =================
  callDeliveryPerDayApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('dashboard/delivery_perday/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              delivery_perday: dataArr,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_perday: [],
              isLoading: false,
            });
          });

      });
    });
  }

  orderBySizeApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingSize: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/order_by_size/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              order_by_size: dataArr,
              isLoadingSize: false,
            });
          }, (error, type) => {
            this.setState({
              order_by_size: [],
              isLoadingSize: false,
            });
          });

      });
    });
  }

  clientDriverPayoutApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingPayout: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/client_driver_billing_chart/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              client_driver_billing: dataArr,
              isLoadingPayout: false,
            });
          }, (error, type) => {
            this.setState({
              client_driver_billing: [],
              isLoadingPayout: false,
            });
          });

      });
    });
  }

  deliveryCollectionMap = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingMap: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/delivery_collection_map/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              delivery_collection_map: dataArr,
              isLoadingMap: false,
            });
          }, (error, type) => {
            this.setState({
              delivery_collection_map: [],
              isLoadingMap: false,
            });
          });

      });
    });
  }

  orderByClientApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/order_by_client/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            this.setState({
              order_by_client: dataArr,
              isLoadingClient: false,
            });
          }, (error, type) => {
            this.setState({
              order_by_client: [],
              isLoadingClient: false,
            });
          });

      });
    });
  }

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        let AckTep = {}
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            AckTep = item
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 0,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 1,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } 
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        // status.unshift({
        //   id: 0,
        //   status: 'Total Orders',
        //   total: total,
        // });
   
        status.forEach(st=>{
          if(st.status == "Assigned") {
            st.total = st.total + AckTep.total_job
          }
        })
        
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
          isLoadingStatus: false,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                  if(callback){
                    callback(result, total);
                  }
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
// API END ==================


  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    return <Row className="p-4 overflow-hidden">
    <Col xs={12} md={12}>
      <div className="fs-6 font-mediumer ps-2 pb-2">Overview</div>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="analytic-overview-card w-100">
        <div className="fs-4 font-mediumer ps-2 pb-2 primary-color">125 / 160</div>
        <div className="fs-6 ps-2">Deliveries Completed</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="analytic-overview-card  w-100">
        <div className="fs-4 font-mediumer ps-2 pb-2 primary-color">3</div>
        <div className="fs-6 ps-2">Collection Points </div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="analytic-overview-card  w-100">
        <div className="fs-4 font-mediumer ps-2 pb-2 primary-color">30 / 60</div>
        <div className="fs-6 ps-2">Collections completed</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="analytic-overview-card w-100">
        <div className="fs-4 font-mediumer ps-2 pb-2 primary-color">$ 100</div>
        <div className="fs-6 ps-2">Profit</div>
      </Card>
    </Col>
  </Row>
  }

  statusPie = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-350px w-100">
            <div className="fs-6 font-mediumer ps-2 pb-2">Status</div>
            {this.state.isLoadingStatus? 
              <CircularProgress size={35} className="spinner-card" /> :
              <CustomPieChart data={this.state.status} dataKey={'total'} key={'total'} value1={'status'} value2={'total'}/>
            }
            </Card>
  }

  clientOrderPie = () => {
    return  <Card className="analytic-chart-card d-flex flex-column h-800px">
              <div className="fs-6 font-mediumer ps-2 pb-2">Orders by Client</div>
              {this.state.isLoadingClient? 
                <CircularProgress size={35} className="spinner-card" /> :
                <CustomPieChart data={this.state.order_by_client} dataKey={'order_count'} key={'order_count'} value1={'name'} value2={'order_count'}/>
              }
              </Card> 
  }

  deliveryLineChart = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-400px w-100">
            <div className="fs-6 font-mediumer ps-2 pb-2">Delivery / Collection counts by Day</div>
            {this.state.isLoading? 
              <CircularProgress size={35} className="spinner-card" /> :
              <CustomLineChart data={this.state.delivery_perday} />
            }
            </Card>
  }

  clientDriverBillingBarChart = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-350px w-100">
            <div className="fs-6 font-mediumer ps-2 pb-2">Client Billing vs Driver Payout</div> 
              {this.state.isLoadingPayout? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomVerticleBarChart data={this.state.client_driver_billing} />
                }
            </Card>
  }

  orderSizeBarChart = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-450px w-100">
            <div className="fs-6 font-mediumer ps-2 pb-2">Orders by Sizes</div>
                {this.state.isLoadingSize? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomBarChart data={this.state.order_by_size} />
                }
            </Card>
  }

  collectionMap = () => {
    return <Card className="analytic-chart-card d-flex flex-column h-450px w-100">
            <div className="fs-6 font-mediumer ps-2 pb-2">Deliveries / collections  on map</div>
              {this.state.isLoadingMap? 
                  <CircularProgress size={35} className="spinner-card" /> :
                  <CustomGoogleMap data={this.state.delivery_collection_map} />
                }
            </Card>
  }

  render() {
    return <Container fluid>
        <Row className="p-4 overflow-hidden">
        <Col xs={12} md={6}>
          <div className="fs-4 font-mediumer ps-2 pb-2">Analytics</div>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end align-items-center pb-2 max-xs-width-control">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApi();
                });
              }}
            />
        </Col>
      </Row>
      {this.overviewHeader()}
      <Row>
        <Col xs={12} md={12}>
          <div className="fs-6 font-mediumer ps-2 pb-2">Overview Breakdown</div>
        </Col>
        <Col xs={12} md={12} className="d-flex justify-content-center">
            {this.deliveryLineChart()}
        </Col>

        {this.state.currentRole == "Customer Template Role" && 
        <><Col xs={12} md={4} p={3} className="d-flex justify-content-center">
          <label >Label</label>
          <select className="custom-input-component" style={{width:"100%", border: "1px solid #007cde", borderRadius: "8px",padding:"8px", backgroundColor: '#ffffff'}}
            placeholder={'Select a Client'}
            onChange={(e) => {
            }}
          >
            <option value="">Select a Client</option>
          </select>
        </Col>
        <Col xs={12} md={4} p={3} className="d-flex justify-content-center">
        <label >Label</label>
          <select className="custom-input-component" style={{width:"100%", border: "1px solid #007cde", borderRadius: "8px",padding:"8px", backgroundColor: '#ffffff'}}
            placeholder={'Collection or Delivery or both'}
            onChange={(e) => {
            }}
          >
            <option value="">Collection or Delivery or both</option>
          </select>
          </Col></> }

        <Col xs={12} md={6} className="d-flex justify-content-center">
            {this.statusPie()}
        </Col>

        <Col xs={12} md={6} className="d-flex justify-content-center">
            {this.clientDriverBillingBarChart()}
        </Col>

        {this.state.currentRole != "Customer Template Role" && <Col xs={12} md={6} className="d-flex justify-content-center flex-column">
                <Card className="analytic-chart-card">
                  <div className="fs-6 font-mediumer ps-2 pb-2">Reasons for failure</div>
                  <select className="custom-input-component mb-4" style={{width:"100%", border: "1px solid #007cde", borderRadius: "8px",padding:"8px", backgroundColor: '#ffffff'}}
                    placeholder={'<DO .Number>'}
                    onChange={(e) => {
                    }}
                  >
                    <option value="">{'<DO .Number>'}</option>
                  </select>
                </Card>
                {this.clientOrderPie()}
        </Col>}

        <Col xs={12} md={6} className="d-flex justify-content-center">
            {this.orderSizeBarChart()}
        </Col>

        {this.state.currentRole != "Customer Template Role" && <Col xs={12} md={12} className="d-flex justify-content-center">
            {this.collectionMap()}
        </Col>}
      </Row>
    </Container>;
  }
}

export default Charts;