import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import BreadCrumb from '../../../components/Navs/Breadcrumb';
import moment from 'moment';

import { 
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';
import { faCircle, faEye, faBarcode, faFile, faCopy, faPen, faTrashCan, faDownload, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as TimeIcon } from '../../../assets/img/icons/time.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/img/left_menu/arrow-down.svg';
import HistoryList from '../../Jobs/Partial/HistoryList';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import Table from '../../../components/Table/Table.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../../components/Status/StatusList.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../../components/Dropdown/DropdownMenu.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import CustomReadMore from '../../../components/Truncate/CustomReadMore.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import JobPreviewDialog from '../../Jobs/Partial/JobImportDialog';
import HtmlParser from 'react-html-parser';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OrderDetailForm from '../../Dashboard/OrderDetailForm';

const { 
  REACT_APP_LDS_API_URL,
} = process.env;


class CustomerOrderScheduleList extends Component {
  static contextType = AuthContext;
  rowData =null;
  pages = 1;
  limits = 50;
  search = '';
  do_number = '';
  uploder_id = '';
  constructor(props){
    super(props); 
    this.pageName = 'jobList';
    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    this.refTableDoSearch = React.createRef();
    this.refTableUploaderSearch = React.createRef();
    this.refTableAdvSearch = React.createRef();

    let rangeDate = {
      startDate: moment(),
      endDate: moment().add(2,'days')
    };
    // let rowData = null;
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }
    // var selectRow = [];
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));
    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      jobTab: 0,
      jobTabTable: 0,
      current_status_name: "",
      total_current_status_job: 0,
      dateRangePickerIsOpen: false,
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,

      isLoading: false,
      grouping: true,
      data: [],
      total: 0,
      selectedRow:[],
      openDialogBatchUpdate:false,
      openDialog: false,
      openDialogMassDelete: false,
      openDialogItem: null,
      openDialogAutoAssign:false,
      invalidAddress:[],

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      openBatchUpdateisLoading: false,

      openSettingsDialog: false,
      autoAssign: false,
      defaultGroupOrder: -1,

      isTourOpen: false,
      openSkipDialog: false,

      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,

      tabValue: 0,
      uploaders: [],
      selected_uploader: '',
      copyDialogOpen: false,
      copyData: {
        id: 0,

        drop_off_date: true,
        drop_off_time_planned: true,
        drop_off_description: true,
        
        drop_off_name: true,
        drop_off_contact_email: true,
        drop_off_contact_no: true,
        item_tracking_number: true,

        order_details: true,
        job_steps: true,
        drop_off_worker: true,
        amount: true,
        drop_off_contact_name:true,
        order_number: true,
        do_number: true,
        drop_off_postal_code: true,
        drop_off_address: true,
        driver_notes: true,
        driver_payout: true,
        document: true,
        boxes: true,
        pallets: true,
        cartons:true,
        package_type:true,
        package_quantity:true,
      }
    }
  }
  

  componentDidMount() {
    const { isCustomerRequired } = this.context;
    this.isCustomerRequired = isCustomerRequired;
    
    this.loadStatusApi(() => {
      this.callReadApi();
    });
  }


  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 7,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 8,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } 
        });
  
        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;

        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search ? search.trim() : search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        sort: "latest"
      };

      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('delete/orders/multiple?id=' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi();
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
              this.callStatusApi();
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      page: 1,
      take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      page: 1,
      take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */


  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
      let selectedRow = this.rowData;
      let ids = [];
      if(selectedRow != null && selectedRow != '') selectedRow.map((item, i) => { ids.push(item.id); });
      let param = {
        start_date: startDate,
        end_date: endDate,
        id: ids,
      };

      ldsApi.exportXLS('admin/api/orders/delivery/export', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */

  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>

      <Box clone>
        <Grid item xs={12} lg={true}>
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                text={<div style={{ width: 90, height: 90 }}>
                   <CircularProgressbar value={this.state.status_total == 0? 1 : item.total} maxValue={this.state.status_total == 0? 1 : this.state.status_total} strokeWidth={14} text={`${item.total}`}  
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    // rotation: 0.25,
                
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',

                    // Text size
                    textSize: '24px',
                
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                
                    // Colors
                    pathColor: `${
                      item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                    textColor:  `${
                      item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                    trailColor: '#c3c3c3',
                    backgroundColor: '#AE0264',
                  })}/>
                </div>}
                count={<span className={
                  'circle-progress-label ' + item.status.replace(' ', '-').trim().toLowerCase()}>{item.status}</span>}
              />
            })}
              onClick={(index) => {
                // console.log("this.state.status[index]", this.state.status[index]);
                
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }

                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  defaultGroupOrder: defaultGroupOrder,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */
  /* TABLE */
  customTable = () => {
    const { isCustomerRequired } = this.context;
    if(!this.state.isLoading) {

      return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      isDraggable={false}
      grouping={false}
      limit={this.limits}
      page={this.pages}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      selection={true}
      onPageChange={(page,limit) => {
          // this.setState({
          //   limit:limit,
          //   page:page
          // }, () => {
            this.pages  = page;
            this.limits =  limit;
            this.callReadApi();
          // });
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        this.rowData = rows;
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Tracker Number",
          field: "order_number",
          hidden: this.state.hiddenColumns.includes('delivery_number'),
          customSort: (a,b) => (a.order_number? a.order_number: '').localeCompare(b.order_number? b.order_number : ''),
          render: (row, type) => {
            if(type === 'row'){
              return  (row && row.order_number && row.order_number !== '') ?  
              <span 
              className={this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')? "linkable-place" : ""}
              style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}  
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                  this.setState({
                    openPreviewDialog: true,
                    openPreviewDialogItem: row.id,
                  });
                }
              }}> {row.order_number} </span>: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Trip Date",
          field: "drop_off_date",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          customSort: (a,b) => (a.drop_off_date? a.drop_off_date: '').localeCompare(b.drop_off_date? b.drop_off_date : ''),
          hidden: this.state.hiddenColumns.includes('date'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_date && row.drop_off_date !== '') ? 
              <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "D.O Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.do_number? a.do_number: '').localeCompare(b.do_number? b.do_number : ''),
          hidden: this.state.hiddenColumns.includes('do_number'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.do_number && row.do_number !== '') ? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Job Type", 
          field: "delivery_type",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.job_type? a.job_type: '').localeCompare(b.job_type? b.job_type : ''),
          hidden: this.state.hiddenColumns.includes('delivery_type'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.job_type && row.job_type !== '') ? <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Project", 
          field: "is_project",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.is_project !== null? a.is_project.toString(): '').localeCompare(b.is_project !== null? b.is_project.toString() : ''),
          hidden: this.state.hiddenColumns.includes('is_project'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
            } else {
              return row;
            }
          }
        },
        { 
          title: "Category", 
          field: "period_of_delivery",
          customSort: (a,b) => (a.period_of_delivery? a.period_of_delivery: '').localeCompare(b.period_of_delivery? b.period_of_delivery : ''),
          hidden: this.state.hiddenColumns.includes('period_of_delivery'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
            } else {
              return row;
            }
          }
        },
        { 
          title: "Pick Up Ref (D.O)",
          field: "collection_reference",
          customSort: (a,b) => (a.collection_reference? a.collection_reference: '').localeCompare(b.collection_reference? b.collection_reference : ''),
          hidden: this.state.hiddenColumns.includes('collection_reference'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.collection_reference && row.collection_reference !== '') ? <span style={{display:'inline-block'}}>{row.collection_reference}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Ordered By", 
          field: "order_by",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
          hidden: this.state.hiddenColumns.includes('order_by'),
          render: (row, type) => {
            if(type === 'row'){
              if(row.vendor_id && row.vendor_id == this.state.user.owner_info.application_company_id) {
                return (row && row.uploader && (row.uploader.application_company && row.uploader.application_company.company_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', display:'inline-block'}}>{row.uploader.application_company.company_name}</span> : '';
              } else {
                return (row && row.uploader && (row.uploader.first_name !== '' || row.uploader.last_name !== '')) ? <span style={{ width:'100%', overflowWrap: 'break-word', display:'inline-block'}}>{(row.uploader.first_name? row.uploader.first_name: '') + ' ' + (row.uploader.last_name? row.uploader.last_name: '')}</span> : '';
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Order Status",
          field: "order_status",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },
          customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('Order Status'),
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
                    <Box>
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold"+'   ' :  row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending"+'   ': row.order_status.status == "Assign Pending" ? "On Hold"+'   ' : row.order_status.status+'   ' : ''}
                      // rightIcon={<ArrowDownIcon />}
                      // items={this.state.tableStatus}
                      // onClick={(item) => {
                      //   if(row.drop_off_worker_id && row.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'pending'){
                      //     let data = {
                      //       data: [
                      //         {
                      //           id: [ row.id ],
                      //           job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                      //             step.job_step_id = step.id;
                      //             if (item.text.toString().toLowerCase().trim() === "completed") {
                      //               step.job_step_status_id = 3;
                      //             } else {
                      //               step.job_step_status_id = 1;
                      //             }
                      //             return step
                      //           }): row.job_steps,
                      //           order_status_id: item.id,
                      //           send_notification_to_customer: true,
                      //         }
                      //       ]
                      //     };

                      //     if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                      //       data.data[0]['drop_off_worker_id'] = null;
                      //       data.data[0]['extra_worker_ids'] = null;
                      //     }

                      //     this.callUpdateStatusApi(data, (newRow) => {
                      //       if(newRow){
                      //         let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                      //         this.setState({
                      //           data: newData,
                      //         });
                      //       }
                      //     });
                      //   } else {
                      //     apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                      //   }
                      // }}
                    />          
                  </Box>
              </Box>;
          } else {
              return row;
            }
          }
        },
        { 
          title: "POD", 
          field: "POD",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          hidden: this.state.hiddenColumns.includes('POD'),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let signature_img = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
              let proof_img =  row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : []
             
              return <div className='d-flex align-items-center justify-content-center'>
                      {proof_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                      <img src={proof_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                      <span>
                        <img src={proof_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                      </span> 
                      {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                        <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                            if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                              this.setState({
                                openPreviewDialog: true,
                                openPreviewDialogItem: row.id,
                              });
                            }
                          }
                        }}/> : ''} </div> : signature_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
                          <img src={signature_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
                        <span>
                          <img src={signature_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
                        </span>
                        {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
                          <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
                              if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                                this.setState({
                                  openPreviewDialog: true,
                                  openPreviewDialogItem: row.id,
                                });
                              }
                            }
                          }}/> : ""}
                  </div> : '' }
              </div>
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Reason for Failure", 
          field: "reason_for_failure",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[0].reason : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('reason_for_failure'),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
              return row && row.order_status && row.order_status.status == "Failed" ? failed_reason : '';
              } 
            } else {
              return row;
            }
          }
        },
        { 
          title: "Delivery Address", 
          field: "delivery_address",
          cellStyle: {
            minWidth: 190,
            maxWidth: 190
          },
          customSort: (a,b) => (a.drop_off_address? a.drop_off_address: '').localeCompare(b.drop_off_address? b.drop_off_address : ''),
          hidden: this.state.hiddenColumns.includes('delivery_address'),
          render: (row, type) => {
            if(type === 'row'){
              // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
              return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:180, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Postal Code", 
          field: "postal_code",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.drop_off_postal_code? a.drop_off_postal_code: '').localeCompare(b.drop_off_postal_code? b.drop_off_postal_code : ''),
          hidden: this.state.hiddenColumns.includes('postal_code'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Between", 
          field: "drop_off_time_planned",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[0]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[0]: ''),
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
            } else {
              return row;
            }
          }
        }, 
        { 
          title: "End", 
          field: "drop_off_time_planned",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.drop_off_time_planned && a.drop_off_time_planned !== '' ? a.drop_off_time_planned.split("-")[1]: '').localeCompare(b.drop_off_time_planned && b.drop_off_time_planned !== '' ? b.drop_off_time_planned.split("-")[1]: ''),
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Driver-Partner", 
          field: "driver_partner",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('driver_partner'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_worker)  ? `${row.drop_off_worker.first_name? row.drop_off_worker.first_name: '' } ${row.drop_off_worker.last_name? row.drop_off_worker.last_name: '' }` : '';
              
            } else {
              return row;
            }
          }
        },
        { 
          title: "Department", 
          field: "department",
          cellStyle: {
            minWidth: 130,
            maxWidth: 130,
         },
         customSort: (a,b) => (a.department && a.department.name? a.department.name: '').localeCompare(b.department && b.department.name? b.department.name : ''),
          hidden: this.state.hiddenColumns.includes('department'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Recipient's Name", 
          field: "recipient_name",
          cellStyle: {
            minWidth: 130,
            maxWidth: 130,
         },
         customSort: (a,b) => (a.drop_off_contact_name? a.drop_off_contact_name: '').localeCompare(b.drop_off_contact_name ? b.drop_off_contact_name : ''),
          hidden: this.state.hiddenColumns.includes('recipient_name'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company", 
          field: "company",
          cellStyle: {
            minWidth: 130,
            maxWidth: 130,
         },
         customSort: (a,b) => (a.drop_off_name? a.drop_off_name: '').localeCompare(b.drop_off_name ? b.drop_off_name : ''),
          hidden: this.state.hiddenColumns.includes('company'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Contact No.", 
          field: "recipient_contact",
          cellStyle: {
            minWidth: 130,
            maxWidth: 130,
         },
         customSort: (a,b) => (a.drop_off_contact_no? a.drop_off_contact_no: '').localeCompare(b.drop_off_contact_no ? b.drop_off_contact_no : ''),
          hidden: this.state.hiddenColumns.includes('recipient_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Alternative Contact", 
          field: "alternate_contact",
          cellStyle: {
            minWidth: 130,
            maxWidth: 130,
         },
         customSort: (a,b) => (a.drop_off_alternate_contact? a.drop_off_alternate_contact: '').localeCompare(b.drop_off_alternate_contact ? b.drop_off_alternate_contact : ''),
          hidden: this.state.hiddenColumns.includes('alternate_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Recipient Email", 
          field: "recipient_email",
          customSort: (a,b) => (a.notification_email? a.notification_email: '').localeCompare(b.notification_email ? b.notification_email : ''),
          hidden: this.state.hiddenColumns.includes('recipient_email'),
          cellStyle: {
            minWidth: 120,
            maxWidth: 120,
         },
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:110, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Item Description", 
          field: "item_description",
          cellStyle: {
            minWidth: 190,
            maxWidth: 190
          },
          customSort: (a,b) => (a.drop_off_description? a.drop_off_description: '').localeCompare(b.drop_off_description ? b.drop_off_description : ''),
          hidden: this.state.hiddenColumns.includes('item_description'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : '';
              // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "XS", 
          field: "xs_packages",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('xs_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "S", 
          field: "s_packages",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('s_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "M", 
          field: "m_packages",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('m_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "L", 
          field: "l_packages",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('l_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Oversized", 
          field: "oversized_packages",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('oversized_packages'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        // { 
        //   title: "Document", 
        //   field: "document",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('document'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.document) ? extra_data.document : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Boxes", 
        //   field: "boxes",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('boxes'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Cartons", 
        //   field: "cartons",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   // hidden: this.state.hiddenColumns.includes('cartons'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        { 
          title: "Pallets", 
          field: "pallets",
          customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
          hidden: this.state.hiddenColumns.includes('pallets'),
          render: (row, type) => {
            if(type === 'row'){
              let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
              return (pack.length > 0) ? pack[0].package_quantity: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Depalletization Request", 
          field: "depalletization_request",
          customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('depalletization_request'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Size in cm(L + W + H)", 
          field: "size_lwh",
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
          hidden: this.state.hiddenColumns.includes('size_lwh'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Weight (KG)", 
          field: "weight",
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
          hidden: this.state.hiddenColumns.includes('weight'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info &&  JSON.parse(row.package_info)
              return (package_info && package_info.weight) ? package_info.weight : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Waiting Time", 
          field: "waiting_time",
          customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).waiting_time ? JSON.parse(a.package_info).waiting_time : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).waiting_time ? JSON.parse(b.package_info).waiting_time : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('waiting_time'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Additional Manpower", 
          field: "no_of_movers",
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
          hidden: this.state.hiddenColumns.includes('no_of_movers'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = row && row.package_info && JSON.parse(row.package_info)
              return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Price", 
          field: "price",
          cellStyle: {
            minWidth: 100,
            maxWidth: 100
          },
          customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
          hidden: this.state.hiddenColumns.includes('price'),
          render: (row, type) => {
            if(type === 'row'){
              if ( row.vendor_id == this.state.user.owner_info.application_company_id) {
                return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
              } 
              return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
              
            } else {
              return row;
            }
          }
        },
        { 
          title: "Driver Payout", 
          field: "driver_payout",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
          hidden: this.state.hiddenColumns.includes('driver_payout'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_payout && row.driver_payout !== '') ? parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Notes to Driver-Partner", 
          field: "notes_to_driver",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          customSort: (a,b) => (a.driver_notes? a.driver_notes: '').localeCompare(b.driver_notes ? b.driver_notes : ''),
          hidden: this.state.hiddenColumns.includes('notes_to_driver'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_notes && row.driver_notes !== '') ? HtmlParser(row.driver_notes) : '';
             } else {
              return row;
            }
          }
        }
      ]}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }  
  }

  customRow = (e, row) => {
    // let desc = (row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : '';

    let date = (row && row.drop_off_date && row.order_status) ? moment(row.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '';
    let time = (date && date !== '') ? (row && row.drop_off_time_planned && row.drop_off_time_planned) ? moment(row.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '' : '';
    
    let dateTime = '';
    if(date && time){
      dateTime = date + ' - ' + time;
    } else if (date){
      dateTime = date;
    } else if (time){
      dateTime = time;
    }

    return <tr>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pb={1}>
          <DropdownMenu
            className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '')}
            text={(row && row.order_status && row.order_status.status) ? row.order_status.status : ''}
            rightIcon={<ArrowDownIcon />}
            items={this.state.tableStatus}
            onClick={(item) => {
              if(row.drop_off_worker_id && row.drop_off_worker_id > 0){
                let data = {
                  data: [
                    {
                      id: [ row.id ],
                      job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                        step.job_step_id = step.id;
                        if (item.text.toString().toLowerCase().trim() === "completed") {
                          step.job_step_status_id = 3;
                        } else {
                          step.job_step_status_id = 1;
                        }
                        return step
                      }): row.job_steps,
                      order_status_id: item.id,
                      send_notification_to_customer: true,
                    }
                  ]
                };

                if(item.text.toString().toLowerCase().trim() === 'not assigned'){
                  data.data[0]['drop_off_worker_id'] = null;
                  data.data[0]['extra_worker_ids'] = null;
                }

                this.callUpdateStatusApi(data, (newRow) => {
                  if(newRow){
                    let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                    this.setState({
                      data: newData,
                    });
                  }
                });
              } else {
                apiUtil.toast('You must first assign a driver!', 'warning', 'error');
              }
            }}
          />
        </Box>
      </td>
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        <Box pl={2}>
          <Box>
            {dateTime}
          </Box>
          <Box>{row.job_steps && row.job_steps.length > 0 ? row.job_steps[0].location : ''}</Box>
          {row.amount !== null && <Box>Price: ${Number.parseFloat(row.amount).toLocaleString()}</Box>}
        </Box>
      </td>
      <td 
        colSpan={6}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      {/* <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
      <td 
        colSpan={5}
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >
        {(desc !== '') && <Box>
          <Box pl={2} pb={2}>
            <CustomReadMore className={'lightGray'} maxLine={2} text={(row && row.drop_off_description && row.drop_off_description !== '') ? row.drop_off_description : ''} />
          </Box>  
        </Box>}
      </td> */}
      <td 
        style={{
          borderBottom: '1px solid #EAEDF2'
        }}
      >&nbsp;</td>
    </tr>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi();
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
       <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onSave={(data) => {
          if(data) {
            apiUtil.toast('Successfully Updated', 'check_circle');
            this.callInitApis()
          }
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        onSave={() => {
          this.callReadApi();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
        if(this.props.onClose) {
          this.props.onClose();
        }
        
        }}
    /> 
    </CustomDialog>
    }
  }
  /* END DIALOG */



  render() {
    return <Container fluid>
       <Row className="p-4">
        <Col xs={12} md={12} className="d-flex justify-content-end align-items-center">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, ()=> {
                  this.callInitApis();
                });
              }}
            />
            <Button className="upload-style" onClick={() => this.callExportXLSApi()}><FontAwesomeIcon icon={faDownload} /></Button>
        </Col>
      </Row>

      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.previewDialog()}
    </Container>;
  }
}

export default CustomerOrderScheduleList;