import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from 'recharts';

export default class CustomDeliveryPerDayChart extends PureComponent {

  constructor(props){
    super(props);
    let data = props.data? props.data : null;
    this.state = {
      data : data,
    }
  }

  render() {
    return (
      <div className='w-100' style={{overflowX: 'auto', overflowY: 'hidden', height: 'auto'}}>
        {this.state.data.length > 0 ?
        <BarChart
          width={this.state.data.length > 10 ? 1000 + (40 * this.state.data.length) : 350}
          height={250}
          data={this.state.data}
          margin={{
            top: 5,
            right: 20,
            left: 0,
            bottom: 100,
          }}
          className='legend-padding-bar overflow-hidden'
        >
          <XAxis dataKey="title" angle={-90} textAnchor="end" reversed type="category" interval={0}/>
          <YAxis type="number" domain={[0, 'dataMax+10']}/>
          <Tooltip />
 
          <Bar dataKey="Pick up" barSize={5} fill="#88BF4D" />
          <Bar dataKey="Deliveries" barSize={5} fill="#98D9D9" />
        </BarChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </div>
    );
  }
}
