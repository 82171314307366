import React, { Component } from 'react';

import { 
    Box,
    Grid,
    Dialog,
    IconButton,
    Divider,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import CustomButton from '../CustomFields/CustomButton.jsx';
import CustomLabel from '../CustomFields/CustomLabel.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faMagnifyingGlass, faPlus, faEye, faFilePdf, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';

class CustomDialog2 extends Component {

    constructor(props){
        super(props);
    
        let open = ((props.open === true || props.open === false) ? props.open : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let type = (props.type) ? props.type : '';
        let title = (props.title) ? props.title : '';
        let btnOkColor = (props.btnOkColor) ? props.btnOkColor : 'secondary';
        let zIndexNumber = (props.zIndexNumber) ? props.zIndexNumber: '';
        let btnCloseColor = (props.btnCloseColor) ? props.btnCloseColor : 'default';
        let btnOkVariant = (props.btnOkVariant) ? props.btnOkVariant : 'contained';
        let btnCloseVariant = (props.btnCloseVariant) ? props.btnCloseVariant : 'text';
        let btnCloseText = (props.btnCloseText) ? props.btnCloseText : 'Cancel';
        let btnOkText = (props.btnOkText) ? props.btnOkText : 'Yes';
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : true);
        let maxWidth = (props.maxWidth) ? props.maxWidth : 'xs';
        let hideButtons = ((props.hideButtons === true || props.hideButtons === false) ? props.hideButtons : false);
        let hideCancelButtons = ((props.hideCancelButtons === true || props.hideCancelButtons === false) ? props.hideCancelButtons : false);
        let hideOkButtons = ((props.hideOkButtons === true || props.hideOkButtons === false) ? props.hideOkButtons : false);
        let padding = (props.padding) ? props.padding : 2;

        this.state = {
            isLoading: isLoading,
            open: open,
            type: type,
            title: title,
            btnOkColor: btnOkColor,
            btnCloseColor: btnCloseColor,
            btnOkVariant: btnOkVariant,
            btnCloseVariant: btnCloseVariant,
            btnCloseText: btnCloseText,
            btnOkText: btnOkText,
            fullWidth: fullWidth,
            maxWidth: maxWidth,
            hideButtons: hideButtons,
            hideCancelButtons: hideCancelButtons,
            hideOkButtons: hideOkButtons,
            padding: padding,
            zIndexNumber: zIndexNumber
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
            let open = ((nextProps.open === true || nextProps.open === false) ? nextProps.open : false);
			this.setState({
				open: open
			});
		}
		if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
			this.setState({
				isLoading: isLoading
			});
		}

        if (nextProps.title !== this.state.title) {
			this.setState({
				title: nextProps.title
			});
		}
	}


    handleClose = () => {
        this.setState({
            open: false
        });

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    handleOk = () => {
        // this.setState({
        //     open: false
        // });

        if(this.props.onOk){
            this.props.onOk();
        }
    }


    render() {
        return <Dialog
            className={"custom-dialog-component " + this.state.zIndexNumber}
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
        >
            <Box>
                <Grid container alignItems={'center'}>
                    <Box clone>
                        <Grid item xs={true}>
                            <CustomLabel
                                label={this.state.title}
                                size={'xl'}
                            />
                        </Grid>
                    </Box>
                    <Box clone mb={1}>
                        <Grid item xs={'auto'}>
                            <IconButton aria-label="close" onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Divider />
            <Box pt={this.state.padding} pb={this.state.padding}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12}>
                            {this.props.children}
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            {!this.state.hideButtons && <Box>
                <Row>
                    <Col  xs={12} className="d-flex justify-content-center align-items-center">
                    {!this.state.hideCancelButtons && <Button className="upload-style-cancel"
                        onClick={this.handleClose} 
                    > {this.state.btnCloseText}</Button> }
                    {!this.state.hideOkButtons &&  <Button className="upload-style"
                        onClick={this.handleOk}
                        isLoading={this.state.isLoading}
                     >{this.state.btnOkText}</Button> }
                    </Col>
                </Row>
                {/* <Grid container alignItems={'center'} justifyContent={'center'}>
                    <Box clone textAlign={'center'} pr={2}>
                        <Grid item xs={true}>
                            <CustomButton 
                                onClick={this.handleClose} 
                                color={this.state.btnCloseColor} 
                                variant={this.state.btnCloseVariant}
                            >
                                {this.state.btnCloseText}
                            </CustomButton>
                        </Grid>
                    </Box>
                    <Box clone textAlign={'center'} >
                        <Grid item xs={'auto'}>
                            <CustomButton 
                                className={"ok-btn " + this.state.type}
                                onClick={this.handleOk} 
                                color={this.state.btnOkColor} 
                                variant={this.state.btnOkVariant}
                                isLoading={this.state.isLoading}
                            >
                                {this.state.btnOkText}
                            </CustomButton>
                        </Grid>
                    </Box>
                </Grid> */}
            </Box>}
        </Dialog>
    }
}


export default CustomDialog2;