import React, { Component } from 'react';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileLines } from '@fortawesome/free-solid-svg-icons'

class CustomerHelp extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 0,
      jobTabTable: 0,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },

      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isStatLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : [],
    }
  }
  componentDidMount() {

  }

  render() {
    return <Container fluid>
        <Row className="p-4">
            <Col>
            <Card className="cd-shadow">
                <div className="p-3">
                    <div className="fs-5 font-medium p-2">Useful Links:</div>
                    <ul>
                        <li className='p-2'><a href="#">How to place an order</a></li>
                    </ul>
                </div>
            </Card>
            </Col>
        </Row>
        </Container>
  }
}

export default CustomerHelp;
