import React, { useState } from 'react';

import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withScriptjs, withGoogleMap} from "react-google-maps"
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { InfoWindow } from '@react-google-maps/api';
import apiUtil from '../../api/apiUtil.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import defaultImage from '../../assets/img/icons/live_tracking_user.png';
import CircleGreen from '../../assets/img/icons/circle-green.svg'
import CircleRed from '../../assets/img/icons/circle-red.svg'

import { 
    Box,
    Grid,
} from '@material-ui/core';


const { 
    REACT_APP_CORS_ANYWHERE_URL,
} = process.env;

const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };

const GOOGLE_STYLE = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#666666"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
];


const CustomLiveTrackingMap = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => {
    const [defaultCenter] = useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
    const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
    const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 12);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : 12);
    const [selectedMarker, setSelectedMarker] = useState("");
    const [markers, setMarkers] = useState((props.markers && props.markers.length > 0) ? props.markers : []);


    React.useEffect(() => {
        setCenter(props.center ? props.center : DEF_CENTER);
        setZoom(props.zoom ? props.zoom : 12);
        // prepareAllMarkers((props.markers && props.markers.length > 0) ? props.markers : []);
    }, [props.center, props.zoom, props.markers]);

    // const prepareAllMarkers = async (markers) => {
    //     if(markers && markers.length > 0){
    //         for(let i = 0; i < markers.length; i++){
    //             if(markers[i].profile_image_url_small && markers[i].profile_image_url_small !== ''){
    //                 await urlToBase64(markers[i].profile_image_url_small).then(result => {
    //                     markers[i].base64Result = (result) ? result : '';
    //                     markers[i].isOpen = false;
    //                 });
    //             } else {
    //                 markers[i].base64Result = defaultImage;
    //                 markers[i].isOpen = false;
    //             }
    //         }
    //     }
        
    //     setMarkers(markers)
    // }

    // const urlToBase64 = async (url) => {
    //     return new Promise(result => {
    //         const xhr = new XMLHttpRequest();
    //         xhr.onload = () => {
    //             const reader = new FileReader();
    //             reader.onloadend = () => {
    //                 if(typeof reader.result == 'string'){
    //                     result(defaultImage);
    //                 } else {
    //                     result(reader.result);
    //                 }
    //             };
    //             reader.readAsDataURL(xhr.response);
    //         };
    //         xhr.open('GET', REACT_APP_CORS_ANYWHERE_URL + url);
    //         xhr.responseType = 'blob';
    //         xhr.send();
    //     }, (result) => {
    //         result(null);
    //     });
    // };

    
    const getPinIcon = (color = '') => {
      return <FontAwesomeIcon icon={faCircle} style={{color: color}} component={'i'} />
    }

    const showAllMarkers = () => {
        return markers.map((item, i) => {
            let position = (item.drop_off_latitude && item.drop_off_longitude) ? { lat: item.drop_off_latitude, lng: item.drop_off_longitude } : null;
            let color = (item.job_type == "Delivery") ? '#e94560' : '#4E79A7';
            if(position){
              return <Marker 
                  key={i}
                  position={position}
                  options={{
                    icon: item.job_type == "Delivery" ? CircleGreen : CircleRed,
                    anchor: new window.google.maps.Point(5, 58),
                    scaledSize: new window.google.maps.Size(37, 37)
                  }}
                  // icon={{
                  //     url:(item.job_type == "Delivery") ? getPinIcon("#4E79A7") : getPinIcon("#e94560"),
                  // }}
                  pinColor={'#4E79A7'}
                  draggable={false}
                  onClick={()=> setSelectedMarker(item)}
              >
              </Marker>
            }
           
        });
    }

    
    const googleMap = () => {
      return <div>
        <GoogleMap
            ref={props.onMapMounted}
            onCenterChanged={props.onCenterChanged}
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            center={center}
            zoom={zoom}
            defaultOptions={{
                disableDefaultUI: true,
                disableDoubleClickZoom: false,
                rotateControl: false,
                styles: GOOGLE_STYLE,
            }}
        >
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={90}
            maxZoom={10}
          >
            {showAllMarkers()}
          </MarkerClusterer>
          {selectedMarker && <InfoWindow
                  position={{lat: selectedMarker.drop_off_latitude , lng: selectedMarker.drop_off_longitude}}
                  options={{
                    maxWidth: 250,
                    pixelOffset: new window.google.maps.Size(-210, -125),
                  }}
                  zIndex={2000}
                  onCloseClick={() => {
                      setSelectedMarker("")
                  }}>
                     <Box
                          style={{
                              width: '250px',
                              backgroundColor: 'white',
                              borderRadius: '6px',
                              overflow: 'hidden',
                          }}
                      >
                          <Grid container>
                              <Box clone>
                                  <Grid item xs={true}>
                                      <Box p={2} fontWeight={'bold'}>{selectedMarker.job_type}</Box>
                                  </Grid>
                              </Box>
                          </Grid>
                      </Box>
              </InfoWindow>}
        </GoogleMap>
      </div>
    }
    
    return googleMap();
});


export default CustomLiveTrackingMap;