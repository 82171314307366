import React, { Component, forwardRef, useEffect, useState, useRef } from 'react';

import apiUtil from '../../api/apiUtil.jsx';
import moment from 'moment';

import { 
    Box,
    Grid,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';

import MaterialTable, { MTableGroupbar, MTableBodyRow, MTableHeader } from "material-table";
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'rgba(35, 49, 86, 0.8)'
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const noResults = "No results!";

const Table = React.forwardRef((props, ref) => {
    let isLoading        = (props.isLoading === false || props.isLoading === true) ? props.isLoading : false;
    let grouping         = (props.grouping === false || props.grouping === true) ? props.grouping : false;
    let paging           = (props.paging === false || props.paging === true) ? props.paging : false;
    let selection        = (props.selection === false || props.selection === true) ? props.selection : false;
    let defaultExpanded  = (props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false;
    let limit            = props.limit ? props.limit : 50;
    let total            = props.total ? props.total : 0;
    let page             = props.page ? props.page : 1;
    let driver           = props.driver ? props.driver : false;
    let isDraggable      = props.isDraggable ? props.isDraggable : false;
    let data             = (props.data && props.data.length > 0)? props.data : [];
    let columns          = (props.columns && props.columns.length > 0)? props.columns : [];
    let emptyText        = props.emptyText? props.emptyText : noResults;
    let overflowY        = props.overflowY? props.overflowY : "visible";
    let maxBodyHeight    = props.maxBodyHeight ? props.maxBodyHeight : '';
    let minBodyHeight    = props.minBodyHeight ? props.minBodyHeight : '200px';
    let tableLayout      = props.tableLayout ? props.tableLayout : 'auto';
    let className        = props.className ? props.className : '';
    let type             = props.type ? props.type : '';
    let isPreview        = props.isPreview ? props.isPreview : false;
    let duplicatePreview = props.duplicatePreview ? props.duplicatePreview : false;
    let DrageState       = '';
    let filteredColumns  = props.columns.filter(col => col && col.hidden == false)

    const [tableScrollTop, setTableScrollTop] = useState(0);
    // let [paging, setPaging] = React.useState((props.paging === false || props.paging === true) ? props.paging : true);
    // let [grouping, setGrouping] = React.useState((props.grouping === false || props.grouping === true) ? props.grouping : false);
    // let [isLoading, setIsLoading] = React.useState((props.isLoading === false || props.isLoading === true) ? props.isLoading : false);
    // let [selection, setSelection] = React.useState((props.selection === false || props.selection === true) ? props.selection : false);
    // let [defaultExpanded, setDefaultExpanded] = React.useState((props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false);
    // let [limit, setLimit] = React.useState(props.limit ? props.limit : 20);
    // let [total, setTotal] = React.useState(props.total ? props.total : 0);
    // let [page, setPage] = React.useState(props.page ? props.page : 1);
    // let [data, setData] = React.useState((props.data && props.data.length > 0)? props.data : []);
    // let [columns, setColumns] = React.useState((props.columns && props.columns.length > 0)? props.columns : []);
    // let [emptyText, setEmptyText] = React.useState(props.emptyText? props.emptyText : noResults);
    // let [overflowY, setOverflowY] = React.useState(props.overflowY? props.overflowY : "visible");
    // let [maxBodyHeight, setMaxBodyHeight] = React.useState(props.maxBodyHeight ? props.maxBodyHeight : '');
    // let [minBodyHeight, setMinBodyHeight] = React.useState(props.minBodyHeight ? props.minBodyHeight : '');
    // let [tableLayout, setTableLayout] = React.useState(props.tableLayout ? props.tableLayout : 'auto');
    // let [className] = React.useState(props.className ? props.className : '');
   
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const tableRef = useRef();

    const handleScroll = (scrollingDivRef, otherDivRef) => {
        otherDivRef.current.scrollLeft  = scrollingDivRef.current.scrollLeft;
    };

    const changePageSize = (size) =>{ 
        let limit = size;
        if(props.onPageChange){ 
            props.onPageChange(page,limit);
        }
    }

    const handleRowClick = (event, rowData) => {
        // const open = true;
        if (props.handleRowClick) {
          props.handleRowClick(event, rowData);
        }
      };
   
    const isOutOfTime = (job) => {
      if(job.drop_off_worker_id != null && moment(job.drop_off_date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
          
        if(moment(job.drop_off_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) 
          return true;
      
        let currentTime = moment().format("H:mm").split(":");
        let currentTimeMinutes = (parseInt(currentTime[0]) * 60) + parseInt(currentTime[1]);
        let time_plan = job.drop_off_time_planned.split(" - ");
        let toTime = time_plan[1].split(":");
        let toTimeMinutes = (parseInt(toTime[0])* 60) + parseInt(toTime[1]);
        if((toTimeMinutes - currentTimeMinutes) < 0) {
          return true;
        }
      } else {
        return false;
      }          
    }

    const isETA = (job) => {
        if (job.route_entity && job.route_entity.active && job.route_entity.arrival) {
            return false
        } else {
            return true  
        }      
            
      }
    // console.log('header', props.Header)
    return <Box className={'custom-table-component-2 ' + className}>
                <div id="scrollbar-dual" className="mb-2" style={{overflowX: 'scroll', overflowY: 'hidden'}} ref={div1Ref} onScroll={() => handleScroll(div1Ref, div2Ref)}>
                    <div style={duplicatePreview ? {width:'8000px'} : {width:`calc(${filteredColumns.length} * 150px)`, height:'20px'}}></div>
                </div>
                <div id="scrollbar-dual" style={{overflow: 'scroll',height:data.length > 18 && duplicatePreview == false  ? '1155px' : 'auto'}} ref={div2Ref} onScroll={() => handleScroll(div2Ref, div1Ref)}>
                  <div style={duplicatePreview ? {width:'8000px'} : {width:`calc(${filteredColumns.length} * 150px)`}} className="mb-2">
                      <MaterialTable
                          tableRef={ref}
                          components={{
                              Container: (p) => {
                                  return <Paper className={'custom-container'} elevation={0} square {...p} />
                              },
                              Toolbar: (p) => {
                                  if(props.Toolbar){
                                      return <Box>
                                          {props.Toolbar(p)}
                                      </Box>
                                  } else {
                                      return null;
                                  }
                              },
                              Header: (p) => {
                                  if(props.Header) {
                                      return <React.Fragment>
                                          {props.Header(p)}
                                      </React.Fragment>
                                  } else {
                                      return <MTableHeader className={'bg-ss header-width'} {...p}  
                                      onDoubleClick={(event) => {
                                        // Prevent dragging on double-click
                                        // console.log('double')
                                        event.stopPropagation();
                                    }}
                                    onDragStart={(e) => {
                                        //   console.log('drag')
                                        if (window.getSelection().toString()) {
                                            e.preventDefault();
                                        }        
                                      }}/> 
                                  }
                              },
                              Row: (p) => {
                                  if(props.Row) {
                                      
                                      return <React.Fragment>
                                          <MTableBodyRow {...p} />
                                          {props.Row(p, p.data)}
                                      </React.Fragment>
                                  } else {
                                  if(p.data.hasOwnProperty('order_status')  ){
 
                                          if((p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'in-progress' || p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'acknowledged' || p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'failed' || p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'completed') && isETA(p.data)){
                                            return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-alert bg-red-alert' : 'bg-alert'} {...p}
                                            draggable={false}
                                            onDoubleClick={(event) => {
                                              // Prevent dragging on double-click
                                              event.stopPropagation();
                                            }}
                                            onDragStart={(e) => {
                                              if (window.getSelection().toString()) {
                                                  e.preventDefault();
                                              }        
                                            }}
                                            />
                                          } else if((p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'in-progress' || p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') == 'acknowledged') && isOutOfTime(p.data)){
                                              return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-alert bg-red-alert' : 'bg-alert'} {...p}
                                              draggable={false}
                                              onDoubleClick={(event) => {
                                                // Prevent dragging on double-click
                                                event.stopPropagation();
                                              }}
                                              onDragStart={(e) => {
                                                if (window.getSelection().toString()) {
                                                    e.preventDefault();
                                                }        
                                              }}
                                              />
                                          } else if(p.data.job_type == "Collection" && type == "allocation"  && !isPreview)  {
                                              return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-allocation-collection bg-red-alert' :  'bg-allocation-collection'} {...p}
                                              draggable={false}
                                              onDoubleClick={(event) => {
                                                // Prevent dragging on double-click
                                                event.stopPropagation();
                                              }}
                                              onDragStart={(e) => {
                                                if (window.getSelection().toString()) {
                                                    e.preventDefault();
                                                }        
                                              }}
                                              />
                                          } else if((p.data.driver_payout == 0 || p.data.driver_payout == null ) && (p.data.amount == 0 || p.data.amount == null) && (p.data.vendor_payout == 0 || p.data.vendor_payout == null))  {
                                              return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-alert bg-red-alert' : 'bg-alert'} {...p}
                                              draggable={false}
                                              onDoubleClick={(event) => {
                                                // Prevent dragging on double-click
                                                event.stopPropagation();
                                              }}
                                              onDragStart={(e) => {
                                                if (window.getSelection().toString()) {
                                                    e.preventDefault();
                                                }        
                                              }}
                                              />
                                          } else if(p.data.order_status.status.toString().toLowerCase().trim().replace(' ', '-') === 'completed'  && !isPreview) {
                                              return <MTableBodyRow  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-complete bg-red-alert' : 'bg-complete'} {...p} 
                                              draggable={false}
                                              onDoubleClick={(event) => {
                                                // Prevent dragging on double-click
                                                event.stopPropagation();
                                              }}
                                              onDragStart={(e) => {
                                                if (window.getSelection().toString()) {
                                                    e.preventDefault();
                                                }        
                                              }}
                                              />
                                          }  else {
                                              return <MTableBodyRow {...p}  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-red-alert' : ''}
                                              draggable={false}
                                              onDoubleClick={(event) => {
                                                // Prevent dragging on double-click
                                                event.stopPropagation();
                                              }}
                                              onDragStart={(e) => {
                                                if (window.getSelection().toString()) {
                                                    e.preventDefault();
                                                }        
                                              }}
                                              />
                                          } 
                                          
                                      } else {
                                          return <MTableBodyRow {...p}  style={{ cursor: 'pointer !important' }} className={p.data && p.data.do_number && p.data.do_number.includes('-DULP') && type == "allocation" ? 'bg-red-alert' : ''}
                                          draggable={false}
                                          onDoubleClick={(event) => {
                                            // Prevent dragging on double-click
                                            event.stopPropagation();
                                          }}
                                          onDragStart={(e) => {
                                            if (window.getSelection().toString()) {
                                                e.preventDefault();
                                            }        
                                          }}
                                              />
                                      }
                                  }
                              },
                              Groupbar: (p) => {
                                  return <Box className={'custom-groupbar'}>
                                      <MTableGroupbar {...p} />
                                  </Box>
                              },
                              
                          }}
                          onColumnDragged={(sourceIndex, destinationIndex) => {
                            if(tableRef.current) {
                              setTableScrollTop(tableRef.current.scrollTop);
                            }
                            if (window.getSelection().toString()) {
                                return;
                            }   
                            if(props.onColumnDragged){  
                              props.onColumnDragged(sourceIndex, destinationIndex);
                            }                            
                          }}
                          localization={{
                              body: {
                                  emptyDataSourceMessage:(
                                      <div style={{position:'relative',padding:'15px'}} >
                                          {emptyText}
                                      </div>
                                  ), 
                              }
                          }}
                          onRowClick={handleRowClick}
                          options={{
                              pageSize: 100,
                              grouping: grouping,
                              overflowY: 'auto',
                              page: page,
                              paging: paging,
                              selection: selection,
                              maxBodyHeight: maxBodyHeight,
                              minBodyHeight: minBodyHeight,
                              tableLayout: tableLayout,
                              defaultExpanded: defaultExpanded,
                              headerStyle: {
                                  width: undefined,
                                },
                              rowStyle: {
                                wordWrap: 'break-word',
                              },
                              // selectionProps: rowData => {
                              //   return props.isCollectionFailed && props.isCollectionFailed(rowData) ? {checked: false} : ''
                              // }
                              // overflowY: overflowY,
                              
                          }}
                      
                          onSelectionChange={(rows) => {
                              if(props.onSelectionChange){
                                  return props.onSelectionChange(rows);
                              }
                          }}
                          icons={{SortArrow: forwardRef((props, ref) => <FontAwesomeIcon color='#007CDE'  {...props} icon={faSort} fontSize='big' />)}}
                          isLoading={isLoading}
                          columns={columns}
                          data={data}
                          detailPanel={(props.detailPanel) ? [
                              {
                                  render: (rowData) => {
                                      if(props.detailPanel){
                                          return props.detailPanel(rowData);
                                      }
                                  }
                              }
                          ] : null}
                      />
                  </div>
                </div>
                {isPreview == false ? <Box pt={3}>
                  <Grid container className="pagination-number">
                        <Box clone >
                            {driver == true ?
                            <Grid item xs={12} md={true} className="d-flex justify-content-start align-items-center">
                                <div style={{display:"inline"}} className="table-page-info">
                                    Showing {data.length} of {total} Drivers
                                </div> 
                        </Grid> :
                            <Grid item xs={12} md={true} className="d-flex justify-content-start align-items-center">
                                {type == "allocation" ? 
                                    <div style={{display:"inline"}} className="table-page-info">
                                        {'No. of Pick Up: 1' + ' No. of Deliveries: '  + (total - 1)}
                                    </div> :
                                    <div style={{display:"inline"}} className="table-page-info">
                                        {'Showing page ' + page + ' of ' + Math.ceil(total / limit) + ' of ' + total + ' entries'}
                                    </div> 
                                    }
                                
                                {/* <div className="d-flex px-3">
                                    <InputLabel id="row_per_page">
                                        <Select
                                            // className="table-pagination-row-per-page"
                                            defaultValue={limit}
                                            label="row_per_page" 
                                            >
                                            <MenuItem value={20} onClick={()=>{changePageSize(20);}} >20</MenuItem>
                                            <MenuItem value={30} onClick={()=>{changePageSize(30);}} >30</MenuItem>
                                            <MenuItem value={40} onClick={()=>{changePageSize(40);}} >40</MenuItem>
                                            <MenuItem value={50} onClick={()=>{changePageSize(50);}} >50</MenuItem>
                                            <MenuItem value={60} onClick={()=>{changePageSize(60);}} >60</MenuItem>
                                            <MenuItem value={100} onClick={()=>{changePageSize(100);}} >100</MenuItem>
                                        </Select>
                                        </InputLabel>
                                </div> */}
                            </Grid>}
                        </Box>
                        <Box clone>
                            <Grid item xs={12} md={'auto'}>
                                <Pagination
                                    count={Math.ceil(total / limit)}
                                    size="small"
                                    page={page}
                                    color={'primary'}
                                    onChange={(e, p) => {
                                        if(props.onPageChange){
                                            props.onPageChange(p,limit); 
                                        }
                                    }}
                                />
                            </Grid>
                        </Box>
                    </Grid> 
                </Box> :  <Grid container className="pagination-number">
                        <Box clone >
                            <Grid item xs={12} md={true} className="d-flex justify-content-start align-items-center">
                                <div style={{display:"inline"}} className="table-page-info">
                                    {'Showing ' + total + ' entries'}
                                </div> 
                            </Grid>
                        </Box>
                        
                    </Grid>  }
            </Box>
    
})

export default Table;