import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";

import {
    Box,
    Grid,
    Container,
    Divider,
    InputLabel,
    Select,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as InformationIcon } from '../../../assets/img/icons/information.svg';

import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import VehicleForm from '../../Jobs/Partial/VehicleForm.jsx';

import Tour from 'reactour'


/**
 * @todo
 *  contact no. preset +65 
// * formValidatedation - show error. password hide text
 * zone dropdown checkbox
 * priority section
 * ask user to enter two password and display error message if the passwords doesn't match.
 * focusing on page load fix
 * ux enter key should move to next input
 */

export const TransportForm = () => {
    const location = useLocation();
    const history = useHistory();
    let Tid = (location && location.state && location.state.id && location.state.id > 0) ? location.state.id : 0;
    const [id] = useState(Tid);
    const [isTransportLoading, setIsTransportLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { accessToken, isOnBoarding, onBoardingVehicle, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
    const [contactNo, setContactNo] = useState(null);
    const [contactNoValidated, setContactNoValidated] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userNameValidated, setUserNameValidated] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [checkName, setCheckName] = useState(null);
    const [firstNameValidated, setFirstNameValidated] = useState(false);
    const [lastName, setLastName] = useState(null);
    const [lastNameValidated, setLastNameValidated] = useState(false);
    const [password, setPassword] = useState(null);
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [maxJobPerDay, setMaxJobPerDay] = useState(0);
    const [priority, setPriority] = useState(0);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [zoneIdArr, setZoneArr] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [zone, setZone] = useState(null);

    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentMethodValidated, setPaymentMethodValidated] = useState(false)
    const [bankAccDetail, setBankAccDetail] = useState(null)
    const [bankAccDetailValidated, setBankAccDetailValidated] = useState(false)
    const [vehicleType, setVehicleType] = useState(null)
    const [vehicleTypeValidated, setVehicleTypeValidated] = useState(false)
    const [icNo, setIcNo] = useState(null)
    const [driverLicenseEmail, setDriverLicenseEmail] = useState(null)
    const [jobPreference, setJobPreference] = useState(null)
    const [load, setLoad] = useState(null)
    const [availablDay, setAvailablDay] = useState(null)
    const [driverLicenseEmailValidated, setDriverLicenseEmailValidated] = useState(false)

    const [isVehicleLoading, setIsVehicleLoading] = useState(false);
    const [vehicleItems, setVehicleItems] = useState([]);
    const [vehicleItem, setVehicleItem] = useState(null);
    const [vehicleName, setVehicleName] = useState('');
    const [vehicleId, setVehicleId] = useState('');

    const [orderStatusesIsLoading, setOrderStatusesIsLoading] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderStatusId, setOrderStatusId] = useState(0)

    const [openDialogVehicle, setOpenDialogVehicle] = useState(false)
    const [itemDialogVehicle, setItemDialogVehicle] = useState(null)
    const [disabled, setDisabled] = useState(null)

    const [isTourOpen, setIsTourOpen] = useState(false)
    const [openSkipDialog, setOpenSkipDialog] = useState(false)


    useEffect(() => {
        if (isOnBoarding) {
            setIsTourOpen(true);
        }
        callReadApi();
        callZoneListApi();
        // callVehicleListApi();
        if (id && id > 0) {
            callTransportOpDetailsApi(id)
        }
    }, [id])

    const callUpdateApi = (id = '', row, callback = null) => {
        setIsLoading(true);
        Api.update('workers/' + id, row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/transport-operators');
                        apiUtil.toast('Successfully Updated', 'check_circle');
                    }
                }, (error, type) => {
                    let errorMessage = error.email? error.email[0] : error
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        apiUtil.toast(errorMessage, 'error');
                    }
                });
            }).catch(
                error => console.log(error)
            )
    }
    const callCreateApi = (row, callback = null) => {
        setIsLoading(true);
        Api.create('workers', row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/transport-operators');
                        apiUtil.toast('Successfully Created', 'check_circle', 'success');
                    }
                }, (error, type) => {
                    let errmessage = error.email? error.email[0] : error
                    setIsLoading(false);
                    if (callback) {
                        callback();
                        apiUtil.toast(errmessage, 'error');
                    }
                });
            })
    }

    const callReadApi = useCallback((search = '') => {
        let param = {
            search: search ? search.trim() : search,
            is_truck_driver: true,
            require_worker_only: true
        };
        setIsLoading(true);
        const getData = Api.read('workers', param, accessToken)
            .then((results) => {
                apiUtil.parseResult(results, (data) => {

                    var json_data = results.data.data.result;
                    var result_data = [];
                    for (var i in json_data) {
                        result_data[i] = json_data[i].email;
                    }
                    setResultData(result_data);

                    setIsLoading(false);
                }, (error, type) => {
                    setIsLoading(false);
                });
            });
    }, [accessToken])

    const callTransportOpDetailsApi = (id = '', callback = null) => {
        setIsTransportLoading(true);
        Api.details('workers', id, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;

                    if (result) {
                        setIsTransportLoading(false);
                        setContactNo(result.contact_no);
                        setUserName(result.email);
                        setFirstName(result.first_name);
                        setCheckName(result.email);
                        setLastName(result.last_name);
                        setZoneArr(result.polygon_ids_ext);
                        if (result.vehicle && result.vehicle.id) {
                            setVehicleId(result.vehicle.id)
                            setVehicleName(result.vehicle.plate_no)
                            setVehicleItem(result.vehicle)
                        }
                        setPriority(result.priority)
                        setMaxJobPerDay(result.max_job_perday)
                        setZone(result.worker_polygons.length > 0 ? `${result.worker_polygons[0].id}` : null)
                        setBankAccDetail(result.bank_account_detail)
                        setPaymentMethod(result.payment_method)
                        setVehicleType(result.type_of_vehicle)
                        setIcNo(result.ic_no)
                        setDriverLicenseEmail(result.driver_license_email)
                        setJobPreference(result.job_preference)
                        setLoad(result.load_amount)
                        setAvailablDay(result.available_day)
                        setDisabled(result.disabled)
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        setIsTransportLoading(false);
                        if (callback) {
                            callback(null);
                        }
                    }
                }, (error, type) => {
                    setIsTransportLoading(false);
                    if (callback) {
                        callback(null);
                    }
                });
            })
    }

    const callVehicleReadApi = useCallback((search = '', callback = null) => {
        let param = {
            // page: 1,
            // take: apiUtil.getDefaultPageSize(),
            search: search ? search.trim() : search,
        };
        setIsVehicleLoading(true);
        Api.read('vehicles', param, accessToken).then((results) => {
            apiUtil.parseResult(results, (data) => {
                const result = (data.result && data.result.length > 0) ? data.result : []
                let arr = [];
                if (result) {
                    arr = result.map((item, i) => {
                        let value = item.id;
                        let label = item.plate_no;

                        if (arr.findIndex(x => x.value === value) === -1) {
                            return {
                                value: value,
                                label: label,
                                item: item
                            }
                        } else {
                            return null;
                        }
                    });
                    setIsVehicleLoading(false)
                    setVehicleItems(arr)
                    if (callback) {
                        callback(arr);
                    }
                } else {
                    setIsVehicleLoading(false)
                }
            }, (error, type) => {
                setIsVehicleLoading(false);
            });
        });

    }, [])

    //worker_polygons
    const callZoneListApi = (callback = null) => {
        let param = {}
        setIsLoading(true);
        Api.read('worker_polygons', param, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;

                    if (result) {
                        setIsLoading(false);
                        setZoneList(result)
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        setIsLoading(false);
                        if (callback) {
                            callback(null);
                        }
                    }
                }, (error, type) => {
                    setIsLoading(false);
                    if (callback) {
                        callback(null);
                    }
                });
            })
    }
    useEffect(() => {
        if (!isOnBoarding) {
            callVehicleReadApi(vehicleName);
        } else {
            let jsonVehicle = null;
            try {
                jsonVehicle = JSON.parse(onBoardingVehicle);
            } catch (e) { }

            let vehicle = null;
            if (jsonVehicle) {
                vehicle = {
                    value: jsonVehicle.id,
                    label: jsonVehicle.plate_no,
                    item: jsonVehicle
                }
            }

            let arr = [];
            if (vehicle) {
                arr.push(vehicle);
            }

            setVehicleItems(arr)
        }
    }, [vehicleName, callVehicleReadApi])

    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                {(!isOnBoarding) && <Box clone>
                    <Grid item xs={'auto'}>
                        <IconButton
                            onClick={() => {
                                history.push('/resources/transport-operators');
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Box>}
                {(!isOnBoarding) && <Box clone>
                    <Grid item xs={'auto'}>
                        <Divider orientation={'vertical'} />
                    </Grid>
                </Box>}
                <Box clone>
                    <Grid item xs={isOnBoarding ? 'auto' : true}>
                        <CustomLabel
                            className={'no-margin'}
                            label={(id > 0) ? 'Edit Driver' : 'Add New Driver'}
                            weight={'bold'}
                            size={'md'}
                        />
                    </Grid>
                </Box>
                {isOnBoarding && <Box clone pr={1}>
                    <Grid item xs={12} sm={true}>
                        {setStepper()}
                    </Grid>
                </Box>}
                <Box clone>
                    <Grid item xs={'auto'}>
                        <CustomButton
                            className={'create-driver-step'}
                            type={'submit'}
                            color={'secondary'}
                            isLoading={isLoading}
                        >
                            <Box pl={2} pr={2}>Submit</Box>
                        </CustomButton>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }

    const CreateVehicleDialog = () => {
        return <CustomFormDialog
            open={openDialogVehicle}
            title={'Create New Vehicle'}
            onClose={() => {
                setOpenDialogVehicle(false)
            }}
        >
            <VehicleForm
                item={itemDialogVehicle}
                onSave={(isEdit, data) => {
                    if (!isEdit) {
                        /* ADD NEW DRIVER */
                        let vehicleItems = [];
                        let vehicleItem = null;
                        if (data && data.result) {
                            let value = data.result.id;
                            let label = data.result.plate_no

                            vehicleItem = {
                                value: value,
                                label: label,
                                item: data.result,
                            };
                            vehicleItems.push(vehicleItem);
                        }

                        setVehicleItems(vehicleItems)
                        setVehicleName(vehicleItem.label)
                        setVehicleId(vehicleItem.value)
                        setVehicleItem(vehicleItem.item)

                        let notAssignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'not assigned');
                        let assignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'assigned');

                        if (assignedStatusItem && notAssignedStatusItem) {
                            if (orderStatusId === notAssignedStatusItem.value) {
                                setOrderStatus(assignedStatusItem.item)
                                setOrderStatusId(assignedStatusItem.value)
                            }
                        }
                        /* END ADD NEW DRIVER */
                        setOpenDialogVehicle(false);
                        setItemDialogVehicle(null);

                    }

                }}
                onClose={() => {
                    setOpenDialogVehicle(false);
                    setItemDialogVehicle(null);
                }}
            />
        </CustomFormDialog>
    }
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const form = e.currentTarget;

        if (firstName === null) {
            setFirstNameValidated(true)
        }
        if (lastName === null) {
            setLastNameValidated(true)
        }
        if (userName === null) {
            setUserNameValidated(true)
        }
        if (password === null && id === 0) {
            setPasswordValidated(true)
        }

        // if (vehicleType === null) {
        //     setVehicleTypeValidated(true)
        // }

        if (driverLicenseEmail !== null && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(driverLicenseEmail))) {
            setDriverLicenseEmailValidated(true)
        }

        if (bankAccDetail === null) {
            setBankAccDetailValidated(true)
        }
        if (paymentMethod === null) {
            setPaymentMethodValidated(true)
        }
        let phoneRegEx = /^[+]?\d+$/;
        if (!phoneRegEx.test(contactNo) || contactNo === null || contactNo.length < 8 || contactNo.length > 13) {
            setContactNoValidated(true)
            setIsLoading(false)
            return
        } 
        var isD = "false";
        resultData.filter(function (item, pos) {
            if (id && id > 0) {
                if (item == userName && item != checkName) {
                    isD = "true";
                }
            } else {
                if (item == userName) {
                    isD = "true";
                }
                }
        });
        if (form.checkValidity() === true && isD === "false") {
            let data = {
                contact_no: contactNo.length === 8 ? '+65' + contactNo : contactNo ,
                disabled: disabled,
                email: userName,
                first_name: firstName,
                is_truck_driver: true,
                last_name: lastName,
                max_job_perday: maxJobPerDay,
                password: password,
                polygon_ids: zone || "55",
                polygon_ids_ext: zone ? [zone]: [55],
                priority: priority,
                vehicle_id: vehicleId,
                vehicle: vehicleItem,
                bank_account_detail: bankAccDetail,
                payment_method: paymentMethod,
                type_of_vehicle: vehicleType,
                ic_no: icNo,
                driver_license_email: driverLicenseEmail,
                job_preference: jobPreference,
                load_amount: load,
                available_day: availablDay
            }

            if (id && id > 0) {
                callUpdateApi(id, data, () => {
                    setIsLoading(false)
                })
            } else {
                if (!isOnBoarding) {
                    callCreateApi(data, () => {
                        setIsLoading(false)
                    })
                } else {
                    setOnBoardingDriver(JSON.stringify(data));
                    history.push('/job-list');
                    apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
                }
            }
        } else {
            if (isD === "true") {
                apiUtil.toast('User Name already Exit', 'check_circle');
            }
            else {
                apiUtil.toast('Please check the compulsory fields', 'check_circle');
            }

            setIsLoading(false);
        }
    }


    /* STEPPER */
    const setStepper = () => {
        return <>
            <CustomGuideStepper
                activeStep={2}
                steps={[
                    {
                        label: 'Create Customer',
                    },
                    {
                        label: 'Create Vehicle',
                    },
                    {
                        label: 'Create Driver',
                    },
                    {
                        label: 'Create Job',
                    },
                ]}
                onClick={(index) => {
                    if (index === 0) {
                        history.push('/customers');
                    } else if (index === 1) {
                        history.push('/resources/vehicle');
                    } else if (index === 2) {
                        history.push('/resources/transport-operators');
                    } else if (index === 3) {
                        history.push('/job-list');
                    }
                }}
            />
            <Box textAlign={'center'}>Fill in the form and create a operator.</Box>
            <Box textAlign={'center'}>
                <CustomButton
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                        e.preventDefault();

                        setOpenSkipDialog(true);
                    }}
                >
                    Skip All
                </CustomButton>
            </Box>
        </>
    }
    /* END STEPPER */

    /* TOUR */
    const setTour = () => {
        return <>
            <Tour
                steps={[
                    {
                        selector: '.create-driver-step',
                        content: <Box>
                            <h2>Create Driver</h2>
                            <p>Fill in the required data to create a driver.</p>
                        </Box>,
                    },
                ]}
                isOpen={isTourOpen}
                rounded={10}
                accentColor={'#007CDE'}
                // accentColor={'#64CCC9'}
                badgeContent={'#007CDE'}
                // badgeContent={'#64CCC9'}
                showNumber={false}
                showNavigation={false}
                disableInteraction={true}
                prevButton={<></>}
                nextButton={<></>}
                lastStepNextButton={<></>}
                onRequestClose={() => {
                    setIsTourOpen(false);
                }} />
        </>
    }
    /* END TOUR */

    /* SKIP DIALOG */
    const skipDialog = () => {
        return <CustomDialog
            open={openSkipDialog}
            title={'End Tutorial'}
            btnCloseText={'No'}
            onClose={() => {
                setOpenSkipDialog(false);
            }}
            onOk={() => {
                apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
                    setOnBoardingCustomer(null);
                    setOnBoardingVehicle(null);
                    setOnBoardingDriver(null);
                    setOnBoardingJob(null);

                    setOpenSkipDialog(false);

                    apiUtil.toastOnBoarding('Done', 'check_circle');
                    history.push('/')
                });
            }}
        >
            <Box>Are you sure you want to end tutorial?</Box>
        </CustomDialog>
    }
    /* END SKIP DIALOG */


    return <Container fluid>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>

            <Container maxWidth="lg">
                <Card>
                    {
                        !isTransportLoading ?
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'Driver Information'}
                                            weight={'bold'}
                                            size={'md'}
                                        />
                                        <Divider light />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Container maxWidth={'sm'}>
                                            <Box clone p={5}>
                                                <Grid container>
                                                    {(id && id > 0) ? <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Driver ID*'}
                                                                value={id}
                                                                autoFocus={true}
                                                                readOnly={true}
                                                            />
                                                        </Grid>
                                                    </Box> : ""}
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'First Name*'}
                                                                placeholder={'Enter driver first name'}
                                                                value={firstName}
                                                                error={firstNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={e => {
                                                                    setFirstName(e.target.value)
                                                                    setFirstNameValidated((e.target.value !== '') ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Last Name*'}
                                                                placeholder={'Enter driver last name'}
                                                                value={lastName}
                                                                error={lastNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={e => {
                                                                    setLastName(e.target.value)
                                                                    setLastNameValidated((e.target.value !== '') ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Phone Number*'}
                                                                placeholder={'+65'}
                                                                value={contactNo}
                                                                error={contactNoValidated}
                                                                errorText={'Phone number is invalid'}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={e => {
                                                                    setContactNo(e.target.value)
                                                                    setContactNoValidated((e.target.value !== '') ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomAutosuggest
                                                                label={'Vehicle Number'}
                                                                placeholder={'Enter any keyword to search for vehicle plate no'}
                                                                helperText={<Box>
                                                                    <Grid container alignItems={'center'}>
                                                                        <Box clone>
                                                                            <Grid item xs={'auto'}>Vehicle not in list?</Grid>
                                                                        </Box>
                                                                        <Box clone>
                                                                            <Grid item xs={'auto'}>
                                                                                <CustomButton
                                                                                    className={'underline'}
                                                                                    href={'/'}
                                                                                    color={'primary'}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        setOpenDialogVehicle(true)

                                                                                    }}
                                                                                    z             >
                                                                                    Create new vehicle
                                                                                </CustomButton>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                </Box>}
                                                                items={vehicleItems}
                                                                isLoading={isVehicleLoading}
                                                                value={vehicleName}
                                                                onChange={(value) => {
                                                                    if (value) {
                                                                        setVehicleName(value.label)
                                                                        setVehicleId(value.value)
                                                                        setVehicleItem(value.item)
                                                                        
                                                                    } else {
                                                                        setVehicleName('')
                                                                        setVehicleId('')
                                                                        setVehicleItem(null)
                                                                    }
                                                                }}
                                                                onSearch={(value, e) => {
                                                                    setVehicleName(value);
                                                                    e.showAllSuggestions();
                                                                }}
                                                                onClick={(value, e) => {
                                                                    setVehicleName(value);
                                                                    e.showAllSuggestions();

                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone mb={2}>
                                                        <Grid item xs={12}>
                                                        <Box className="custom-label-component">
                                                        <InputLabel >Zone</InputLabel>
                                                        </Box>
                                                        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent', marginBottom: '0'}}
                                                        native
                                                        required={false}
                                                        disableUnderline
                                                        value={zone}
                                                        onChange={(e) => {
                                                            setZone(e.target.value)
                                                        }}
                                                        >
                                                        <option value=""> Select Zone </option>
                                                        {zoneList.length > 0 && zoneList.map((zone) =>
                                                            <option value={zone.id}>{zone.polygon_name}</option>
                                                        
                                                        )}
                                                        </Select>
                                                        <Grid container alignItems={'center'}>
                                                            <Box clone>
                                                                <Grid item xs={'auto'}>Zone not in list?</Grid>
                                                            </Box>
                                                            <Box clone>
                                                                <Grid item xs={'auto'}>
                                                                    <CustomButton
                                                                        className={'underline'}
                                                                        href={'/zones'}
                                                                        color={'primary'}
                                                                        >
                                                                        Create new zone
                                                                    </CustomButton>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Payment Method *'}
                                                                placeholder={'Payment Method'}
                                                                value={paymentMethod}
                                                                error={paymentMethodValidated}
                                                                autoFocus={true}
                                                                required={true}
                                                                onChange={e => {
                                                                    setPaymentMethod(e.target.value) 
                                                                    setPaymentMethodValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>

                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Bank Account Detail *'}
                                                                placeholder={'Enter bank account detail'}
                                                                autoFocus={true}
                                                                value={bankAccDetail}
                                                                error={bankAccDetailValidated}
                                                                required={true}
                                                                onChange={e => {
                                                                    setBankAccDetail(e.target.value)
                                                                    setBankAccDetailValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    {/* <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Type of Vehicle*'}
                                                                placeholder={'Enter type of vehicle'}
                                                                autoFocus={true}
                                                                required={true}
                                                                value={vehicleType}
                                                                error={vehicleTypeValidated}
                                                                onChange={e => {
                                                                    setVehicleType(e.target.value) 
                                                                    setVehicleTypeValidated(e.target.value !== '' ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box> */}
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'IC No.'}
                                                                placeholder={'Enter IC No'}
                                                                autoFocus={true}
                                                                value={icNo}
                                                                onChange={e => {
                                                                    setIcNo(e.target.value) 
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={"Driver License Email"}
                                                                placeholder={'Enter Driver License Email'}
                                                                autoFocus={true}
                                                                type={'email'}
                                                                value={driverLicenseEmail}
                                                                error={driverLicenseEmailValidated}
                                                                onChange={e => {
                                                                    setDriverLicenseEmail(e.target.value) 
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Job Preference'}
                                                                placeholder={'Enter Job Preference'}
                                                                value={jobPreference}
                                                                autoFocus={true}
                                                                onChange={e => {
                                                                    setJobPreference(e.target.value) 
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Load'}
                                                                placeholder={'Enter Load'}
                                                                autoFocus={true}
                                                                value={load}
                                                                type={"number"}
                                                                endAdornment={<span>Kg</span>}
                                                                onChange={e => {
                                                                    setLoad(e.target.value) 
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Available Days'}
                                                                placeholder={'Enter available days'}
                                                                type={"number"}
                                                                value={availablDay}
                                                                autoFocus={true}
                                                                onChange={e => {
                                                                    setAvailablDay(e.target.value) 
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>



                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Grid>
                                    {/*<Grid item xs={12}>
                                <CustomLabel
                                    label={`Job Priority`}
                                    weight={'bold'}
                                    size={'md'}
                                />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Container maxWidth={'sm'}>
                                    <Box clone p={5}>
                                        <Grid container>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Job Priority'}
                                                        placeholder={'Enter Job Priority'}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Maximum Job Perday'}
                                                        labelComponent={<CustomTooltip title={`Determine this worker's maximum job per day. This is required for auto-assign.`} />}
                                                        type={'number'}
                                                        value={maxJobPerDay}
                                                        onChange={(e) => {
                                                            setMaxJobPerDay(e.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Container>
                            </Grid>*/}
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={`Driver Account`}
                                            weight={'bold'}
                                            size={'md'}
                                        />
                                        <Divider light />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Container maxWidth={'sm'}>
                                            <Box clone p={5}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                label={'Username*'}
                                                                placeholder={'Enter username'}
                                                                value={userName}
                                                                error={userNameValidated}
                                                                required={true}
                                                                autoFocus={true}
                                                                onChange={(e) => {
                                                                    setUserName(e.target.value)
                                                                    setUserNameValidated((e.target.value !== '') ? false : true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomInput
                                                                className={'custom-password'}
                                                                label={'Password*'}
                                                                placeholder={'Enter driver password'}
                                                                autoComplete={'new-password'}
                                                                value={password}
                                                                error={passwordValidated}
                                                                type={passwordType}
                                                                autoFocus={true}
                                                                required={id === 0 ? true : false}
                                                                endAdornment={(obj) => {
                                                                    return <Box
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            setPasswordType((passwordType === 'password') ? 'text' : 'password')
                                                                        }}
                                                                    >
                                                                        {
                                                                            (passwordType === 'password')
                                                                                ?
                                                                                <i className={'far fa-eye-slash'}></i>
                                                                                :
                                                                                <i className={'far fa-eye'}></i>
                                                                        }
                                                                    </Box>
                                                                }}
                                                                // required={id > 0 ? true : false}
                                                                onChange={(e) => {
                                                                    setPassword(e.target.value)
                                                                    if (id && id === 0) {
                                                                        setPasswordValidated((e.target.value !== '') ? false : true)
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    {/* <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Confirm Password'}
                                                        placeholder={'Enter password'}
                                                        value={''}
                                                        onChange={() => {
                                                            
                                                        }}
                                                    />
                                                </Grid>
                                            </Box> */}
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            <CustomAlert
                                                                icon={<InformationIcon />}
                                                            >
                                                                SMS will be sent to your driver assistant to notify about their new account
                                                            </CustomAlert>
                                                        </Grid>
                                                    </Box>

                                                </Grid>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                                <CircularProgress size={35} />
                            </Box>
                    }
                </Card>
            </Container>

        </form>
        <CreateVehicleDialog />
        {setTour()}
        {skipDialog()}
    </Container>
}
