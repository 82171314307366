import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider.jsx";
import { NavLink, Link } from 'react-router-dom';
import { 
  Grid,
  IconButton,
} from '@material-ui/core';
import apiUtil from "../api/apiUtil.jsx";
import authApi from "../api/api.jsx";
import { withStyles } from '@material-ui/core/styles';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import MenuIcon from '@material-ui/icons/Menu';
import EvfyLogo from '../assets/img/evfy-logo2.png';
import CustomerProfileDropdown from "../components/Header/CustomerProfileDropdown.jsx";
import NotificationDropdown from "../components/Header/NotificationDropdown.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
});

class CustomerHeader extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);
    let showMenuIcon = ((props.showMenuIcon === true || props.showMenuIcon === false) ? props.showMenuIcon : true);

    this.state = {
      data :  [],
      isLoadingStatus: false,
      appcom_data:[],
      showHeader: showHeader,
      showMenuIcon: showMenuIcon,
      isLogedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
    };
  }


  componentDidMount() {
    this.checkIsUserLogedIn();
    this.callReadApi();
  }

  callReadApi = ( callback = null) => {
    const accessToken  = window.localStorage.getItem('access_token');
    let param = {
        is_paid: false,
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            
            this.setState({
              isLoading: false,
              data:data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
            // console.log("sad", data.result);
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }


  checkIsUserLogedIn = () => {
    const { authenticated } = this.context;
    this.setState({ 
      isLogedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();
    
    // window.location.reload();
    window.location.href = "/Sign-in";
  }

  alertShow = () => {
    let billDate = this.state.data[0] ? this.state.data[0].billing_date : new Date();
    let suspendedDate = new Date(billDate)
    let currnetDate = new Date()
    let appcom_status_id = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_status_id : null;
    let appcom_is_trial  = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_license.is_trial : false;
    let date_subscription_end = this.state.appcom_data[0] ? new Date(this.state.appcom_data[0].application_company_license.date_subscription_end) :new Date();
    suspendedDate.setDate(suspendedDate.getDate() + 14);
    if(suspendedDate < currnetDate ) {
     let timeDiff = Math.abs(suspendedDate.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', backgroundColor:'#f8d7da', padding:'10px'}}>
          Your Evfy Tracker services are suspended. 
          <NavLink to={{pathname: '/invoice'}} > Please pay </NavLink> within {total_days} days to avoid account removal.</div>
    } else if(
      appcom_status_id == null && appcom_is_trial) {
     let timeDiff = Math.abs(date_subscription_end.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', color: '#fff', paddingLeft:'25px'}}>
          You have {total_days} days left in your trial. Subscribe to evfy.sg by
          <span 
          style={{cursor:"pointer"}}
          onClick={()=>{
            this.setState({
              openDialog:true,
            })
           }} 
          
          ><u> setting your device quota</u></span> now.</div>
    } else {

    }
}

  getHeader = () => {
    let role = localStorage.getItem('current_role');
    return <Container fluid className="header-warpper">
      <Row className=' d-flex align-items-center'>
      <Col xs={3} sm={2} md={2} lg={1}>
      <div className="d-flex justify-content-end align-items-center header-toggle align-self-center pe-1 m-0" style={{minWidth:'86px', width:'86px', backgroundColor:'#007CDE'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="center"
            onClick={() => {
              if(this.props.onDrawerToggle){
                this.props.onDrawerToggle();
              }
            }}
            className={this.props.classes.menuButton}
          >
            <MenuIcon style={{
              color: 'white',
              fontSize:'24px'
            }} />
          </IconButton>
        </div>
      </Col>
      <Col  xs={9} sm={10} md={10} lg={11} className="pe-5">
      <Row className="d-flex align-items-center">
      <Col xs={4} sm={4} md={6} lg={6}> 
      <Row className="d-flex align-items-center not-mobile-view">
      <Col md={2} lg={2} className="hidden-sm-down hidden-xs-down img-hidden"><img src={EvfyLogo} alt="evfy logo" height={'auto'} width={'100%'}/></Col>
      <Col xs={12} sm={12} md={10} lg={10} className="header-tabs-cus d-flex justify-content-start">
        {window.location.pathname == "/customer-dashboard" || window.location.pathname == "/order-summary" || window.location.pathname == "/job-schedule-lists" || window.location.pathname == "/order-summary-list" || window.location.pathname.includes("customer-recycle-bin")?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center">
          <NavLink to="/customer-dashboard" className={window.location.pathname == "/customer-dashboard" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Dashboard</div></NavLink>
            <NavLink to="/order-summary" className={window.location.pathname == "/order-summary" || window.location.pathname == "/order-summary-list" || window.location.pathname == "/job-schedule-lists"? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
              <div>Order Summary</div></NavLink>
              <NavLink to="customer-recycle-bin" className={window.location.pathname.includes("customer-recycle-bin") ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
              <div>Recycle Bin</div></NavLink>
        </div> : ''}
        {window.location.pathname == "/my-order-recycle-bin" || window.location.pathname == "/new-order" || window.location.pathname == "/order-schedule" ?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center">
            <NavLink to="/new-order" className={window.location.pathname == "/new-order" ? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
              <div>New Orders</div></NavLink>
            <NavLink to="/order-schedule" className={window.location.pathname == "/order-schedule" ? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
              <div>Order Schedule</div></NavLink>
              <NavLink to="/my-order-recycle-bin" className={window.location.pathname == "/my-order-recycle-bin" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
              <div>Recycle Bin</div></NavLink>
        </div> : ''}
        {window.location.pathname.includes("/analytics") || window.location.pathname.includes("/job-schedule-list") ?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/analytics/client-overview" className={window.location.pathname == "/analytics/client-overview" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Overview</div></NavLink>
        </div> : ''}
        {window.location.pathname == "/invoice" ?
        <div className="d-flex justify-content-start font-medium h-100 align-items-center">
          <NavLink to="/invoice" className={window.location.pathname == "/invoice" ? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
            <div>Invoices</div></NavLink>
           </div>: ''}
           {window.location.pathname == "/settings" ?
        <div className="d-flex justify-content-start font-medium h-100 align-items-center">
          <NavLink to="/settings" className={window.location.pathname == "/settings" ? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
            <div>Account</div></NavLink>
           </div>: ''}
           {window.location.pathname == "/help-center" ?
        <div className="d-flex justify-content-start font-medium h-100 align-items-center">
          <NavLink to="/help-center" className={window.location.pathname == "/help-center" ? "active-header-style h-100 align-items-center d-flex  me-4" : "not-active-header-style  me-4"}>
            <div>FAQs</div></NavLink>
           </div>: ''}
      
      </Col>
      </Row>
      <Row className="d-flex align-items-center mobile-view ">
        <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <FontAwesomeIcon icon={faEllipsis} className="color-primary fs-4"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
        {window.location.pathname.includes("customer-recycle-bin") || window.location.pathname == "/customer-dashboard" || window.location.pathname == "/order-summary" || window.location.pathname == "/order-summary-list" || window.location.pathname.includes("/job-schedule-lists") ?
        <div className="d-flex flex-column  justify-content-evenly font-medium h-100 align-items-center">
          <Dropdown.Item href="/customer-dashboard" className={window.location.pathname == "/customer-dashboard" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>DashBoard</div></Dropdown.Item>
            <Dropdown.Item href="/order-summary" className={window.location.pathname == "/order-summary" || window.location.pathname == "/order-summary-list" || window.location.pathname == "/job-schedule-lists"? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
              <div>Order Summary</div></Dropdown.Item>
              <Dropdown.Item href="customer-recycle-bin" className={window.location.pathname == "customer-recycle-bin" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Recycle Bin</div></Dropdown.Item>
        </div> : ''}
        {window.location.pathname == "/my-order-recycle-bin" || window.location.pathname == "/new-order" || window.location.pathname == "/order-schedule" ?
        <div className="d-flex flex-column  justify-content-evenly font-medium h-100 align-items-center">
            <Dropdown.Item href="/new-order" className={window.location.pathname == "/new-order" ? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
              <div>New Orders</div></Dropdown.Item>
            <Dropdown.Item href="/order-schedule" className={window.location.pathname == "/order-schedule" ? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
              <div>Order Schedule</div></Dropdown.Item>
              <Dropdown.Item href="/my-order-recycle-bin" className={window.location.pathname == "/my-order-recycle-bin" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Recycle Bin</div></Dropdown.Item>
        </div> : ''}
        {window.location.pathname.includes("/analytics") || window.location.pathname.includes("/job-schedule-list") ?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <Dropdown.Item href="/analytics/client-overview" className={window.location.pathname == "/analytics/client-overview" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Overview</div></Dropdown.Item>
        </div> : ''}
        {window.location.pathname == "/invoice" ?
        <div className="d-flex flex-column justify-content-start font-medium h-100 align-items-center">
          <Dropdown.Item href="/invoice" className={window.location.pathname == "/invoice" ? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
            <div>Invoices</div></Dropdown.Item>
           </div>: ''}
           {window.location.pathname == "/settings" ?
        <div className="d-flex flex-column justify-content-start font-medium h-100 align-items-center">
          <Dropdown.Item href="/settings" className={window.location.pathname == "/settings" ? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
            <div>Account</div></Dropdown.Item>
           </div>: ''}
           {window.location.pathname == "/help-center" ?
        <div className="d-flex flex-column justify-content-start font-medium h-100 align-items-center">
          <Dropdown.Item href="/help-center" className={window.location.pathname == "/help-center" ? "active-header-style h-100 align-items-center d-flex " : "not-active-header-style "}>
            <div>FAQs</div></Dropdown.Item>
           </div>: ''}
        </Dropdown.Menu>
      </Dropdown>
      </Row>
      </Col>
      <Col xs={8} sm={8} md={6} lg={6} className="d-flex align-items-center justify-content-end">
      <Col xs={'auto'}>
        <CustomerProfileDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Col>
      </Col>
      </Row>
      </Col>
      </Row>
    </Container>
  }

  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withStyles(useStyles)(CustomerHeader);
 