import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class CustomVerticleBarChart extends PureComponent {
  // data = [{
  //         date : 12-7,
  //         'Client Billing' : 3,
  //         'Driver Payout' : 2,
  //       },
  //       {
  //         date : 12-8,
  //         'Client Billing' :5,
  //         'Driver Payout' : 22,
  //       },
  //       {
  //         date : 12-9,
  //         'Client Billing' : 2,
  //         'Driver Payout' : 55,
  //       },]
  constructor(props){
    super(props);
    let data = props.data? props.data : null;
    this.state = {
      data : data,
    }
  }

  render() {
    return (
      <div className='w-100' style={{overflowY: 'auto', height: 'auto'}}>
        {this.state.data.length > 0 ?
        <BarChart
          layout="vertical"
          width={500}
          height={250 + (40 * this.state.data.length)}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          className='legend-padding-bar overflow-hidden'
        >
          <XAxis hide domain={[0, 'dataMax+10']}/>
          <YAxis dataKey="date" reversed type="category" />
          <Tooltip />
          <Legend align='left' iconType='circle'/>
          <Bar dataKey="Client Billing" fill="#6200EE" barSize={Math.max(10, 20)}/>
          <Bar dataKey="Driver Payout" fill="#C8A1FF" barSize={Math.max(10, 20)}/>
        </BarChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </div>
    );
  }
}
