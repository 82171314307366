import React, { Component } from 'react';
import { read as readXsl, utils as xslUtils } from 'xlsx';
import uuid from 'uuid';
import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import wmsApi from "../../../api/wmsApi.jsx";
import Api from "../../../api/api.jsx";
import Geocode from "react-geocode";
import { Container, Row, Col} from 'react-bootstrap';
import { 
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';
import CustomDialog2 from '../../../components/Dialog/CustomDialog2.jsx';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ImportDetailForm } from '../../Dashboard/ImportDetailForm.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';
import Table from '../../../components/Table/Table.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDotCircle, faEdit, faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import Card from '../../../components/Card/Card.jsx';
import GreenTech from '../../../assets/img/icons/Greentech.png'
import GreenTechInActive from '../../../assets/img/icons/GreyTech.png'

const DataFieldType = {
    Job: 1,
    Step: 2,
    Item: 3,
}

const { 
    REACT_APP_GOOGLE_MAP_API_KEY,
  } = process.env;

  let customer_items = [];
  let setLatLngErr = [];
export class JobImportDialog extends Component {
  static contextType = AuthContext;
  
  constructor(props){
    super(props);
    
    let formatTime = localStorage.getItem('Time Format') ? localStorage.getItem('Time Format') : '12-Hour';
    let timeFormat = (formatTime === '12-Hour') ? 'hh:mm A' : 'HH:mm';
    this.refForm = React.createRef();
    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let file = (props.file) ? props.file : null;
    let collection_data = props.collection_data == false ? false : true
    let drop_off_date = props.drop_off_date? props.drop_off_date : null
    let drop_off_address = props.drop_off_address? props.drop_off_address : null
    let building_no = props.building_no? props.building_no : null
    let dataFields = [
        { type: DataFieldType.Job, label: 'Phone No', value: 'Contact No.', col: 'drop_off_contact_no', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Alternative Contact No.', value: 'Alternative Contact No.', col: 'drop_off_alternate_contact', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Job date', value: 'Trip Date(DD/MM/YYYY)', col: 'drop_off_date', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Between', value: 'Between', col: 'drop_off_from_time', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'And', value: 'And', col: 'drop_off_to_time', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Client Code', value: 'Client Code', col: 'customer_id', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Company', value: 'Company', col: 'drop_off_name', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Deliver To', value: "Recipient's name", col: 'drop_off_contact_name', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Job description', value: 'Item Description', col: 'drop_off_description', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Address', value: 'Address', col: 'drop_off_address', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Job Type', value: 'Job Type', col: 'job_type', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Company code', value: 'Company Code', col: 'company_code', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'DO number', value: 'D.O Number', col: 'do_number', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Project', value: 'Project', col: 'is_project', required: false, disabled: false,  dataType: 'checkbox' },
        { type: DataFieldType.Job, label: 'Pick Up Ref (D.O)', value: 'Pick Up Ref (D.O)', col: 'collection_reference', required: false, disabled: false,  dataType: 'string' },
        { type: DataFieldType.Job, label: 'Notification Email', value: 'Recipient Email', col: 'notification_email', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Department', value: 'Department', col: 'department_id', required: false, disabled: false, dataType: 'string' },
        
        { type: DataFieldType.Job, label: 'Driver Notes', value: 'Notes', col: 'driver_notes', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Boxes', value: 'Boxes', col: 'boxes', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Pallets', value: 'Pallets', col: 'pallets', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Cartons', value: 'Cartons', col: 'cartons', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Postal Code', value: 'Postal Code', col: 'drop_off_postal_code', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Zone', value: 'Zone', col: 'zone_id', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Document', value: 'Document', col: 'document', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Notify Customer', value: '8', col: 'is_notify_customer', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Job, label: 'Send Tracking link', value: '9', col: 'is_tracking_link_required', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Job, label: 'Driver Payout', value: 'Driver Payout', col: 'driver_payout', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Vendor Payout', value: 'Vendor Payout', col: 'vendor_payout', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Price', value: 'Price', col: 'amount', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'XS Packages', value: 'XS Packages', col: 'xs_packages', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'S Packages', value: 'S Packages', col: 's_packages', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'M Packages', value: 'M Packages', col: 'm_packages', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'L Packages', value: 'L Packages', col: 'l_packages', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Oversized Packages', value: 'Oversized Packages', col: 'oversized_packages', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Period of Delivery', value: 'Period of Delivery', col: 'period_of_delivery', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Category', value: 'Category', col: 'period_of_delivery', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Depalletization Request', value: 'Depalletization Request', col: 'depalletization_request', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Size in cm(L + W + H)', value: 'Size in cm(L + W + H)', col: 'size_l_w_h', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Weight (KG)', value: 'Weight (KG)', col: 'weight', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Waiting Time', value: 'Waiting Time', col: 'waiting_time', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Additional Manpower', value: 'Additional Manpower', col: 'no_of_movers', required: false, disabled: false, dataType: 'string' },

        { type: DataFieldType.Step, label: 'Step Address', value: 'Step Address', col: 'address', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Step Name', value: 'Step Name', col: 'name', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Step Person In Charge', value: 'Step Person In Charge', col: 'job_step_pic', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Step Phone Number', value: 'Step Phone Number', col: 'job_step_pic_contact', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Step Remark', value: 'Step Remark', col: 'description', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Require signature', value: '16', col: 'signature', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Step, label: 'Require scanning', value: '17', col: 'scanning', required: false, disabled: false, dataType: 'checkbox' },
        
        { type: DataFieldType.Item, label: 'Description', value: 'Item Name', col: 'desc', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Quantity', value: 'Quantity', col: 'qty', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'UOM', value: 'UOM', col: 'uom', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Weight (KG)', value: 'Weight (KG)', col: 'weight', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'SKU', value: 'SKU', col: 'sku', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Remarks', value: 'Remark', col: 'remarks', required: false, disabled: false, dataType: 'string' },
    ];
    const grantedAccess = new RoleAccessService();
    const currentRole = window.localStorage.getItem('current_role') || null;
    if(grantedAccess.isShouldShowJobAccess(currentRole, "job_assign")){
        dataFields.push({ type: DataFieldType.Job, label: 'Assigned Driver ID', value: '13', col: 'drop_off_worker_id', required: false, disabled: false, dataType: 'string' });
    }
    this.dataFieldsPom = JSON.parse(JSON.stringify(dataFields));

    this.state = {
        preview_data: [],
        preview_index: null,
        isLoading: isLoading,
        grantedAccess : grantedAccess,
        file: file,
        preview_detail: '',
        importedSheetData: [],
        headers: [],
        excelRows: [],
        customerItems: [],
        dataFields: dataFields,
        currentRole:currentRole,
        columns: [],
        rows: [],
        collection_data:collection_data,
        customerItemsIsLoading : false,
        isLoadingSteps: false,
        columnsSteps: [],
        rowsSteps: [],
        errLatlng: [],
        isLoadingItems: false,
        columnsItems: [],
        rowsItems: [],
        openPreviewDialog: false,
        preview_detail: null,
        status: [],
        isLoadingInitCalls: true,
        drop_off_address: drop_off_address,
        building_no:building_no,
        drop_off_date:drop_off_date,
        refresh: false,

        timeFormat: timeFormat,

        isLoadingAppSettings: false,
        rule: null,
        errMessages: [],
        isFirstTime: false,
        package_types: [],
        package_list: [],
        latLongErr:[],
        is_deli_use_saved_address: false
    }
  }
  

  componentDidMount() {
   
    if(this.state.file){
        this.callAddressApi();
        this.callCustomerReadApi(()=> {
            this.loadWorkSheet();
        });
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.collection_data !== this.state.collection_data) {
        let collection_data =  nextProps.collection_data ? nextProps.collection_data : [];
        this.setState({
            collection_data: collection_data
        });
    }
    if (nextProps.file !== this.state.file) {
        let file = (nextProps.file) ? nextProps.file : null;
        this.setState({
            file: file
        });
    }
  }


    /*===== ===== API ===== =====*/
    callCustomerReadApi = (callback = null) => {
        const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;
            let param = {
              is_active: true,
              light_data_customer_rate: true,
            };
            this.setState({
                customerItemsIsLoading : true
            })            
            wmsApi.read('customers', param, accessToken)
              .then((result) => {
                apiUtil.parseResult(result, (data) => {
                  let result = (data.result && data.result.length > 0) ? data.result : [];
      
                  let arr = [];
                  let customer_rate = []
                  if(result && result.length > 0){
                    
                    for(let i = 0; i < result.length; i++){
                      let item = result[i];
                      
                      let value = item.id;
                      let label = item.company_code;
                      let company_name = item.company_name;
    
                      if (item.company_code == this.state.customerName) {
                        customer_rate = item.customer_rates ? item.customer_rates : []
                      }
    
      
                      if(arr.findIndex(x => x.value === value) === -1){
                        arr.push({
                          value: value,
                          label: label,
                          company_name: company_name,
                          item: item
                        });
                      }
                    }
                    this.customer_items = arr;
                    this.setState({
                      customerItemsIsLoading: false,
                      customerItems: arr.sort((a, b) => {
                        const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                      
                        // names must be equal
                        return 0;
                      }),
                      customer_rate
                    }, () => {
                        if(callback){
                          callback();
                        }
                      });
    
                  } else {
                    this.setState({
                      customerItemsIsLoading: false,
                      customerItems: [],
                    }, () => {
                      if(callback){
                        callback([]);
                      }
                    });
                  }
                }, (error, type) => {
                  this.setState({
                    customerItemsIsLoading: false,
                    customerItems: [],
                  }, () => {
                    if(callback){
                      callback([]);
                    }
                  });
                });
            });
    }
    
    /* GEO FUNCTIONS API */
    callGeoCoderAddressApi = (address = "", callback = null) => {
      const { accessToken } = this.context;
    
      this.setState(
        {
          geocoderAddressIsLoading: true,
        },
        () => {
          let params = {
            address: address,
          };
    
          ldsApi
            .create("geo_functions/geocoder_address", params, accessToken)
            .then((result) => {
              apiUtil.parseResult(
                result,
                (data) => {
                  if (data && data.result) {
                    if (callback) {
                      callback(data.result);
                    }
                  } else {
                    if (callback) {
                      callback(null);
                    }
                  }
                },
                (error, type) => {
                  if (callback) {
                    callback(null);
                  }
                }
              );
            });
        }
      );
    };

    callGeoCoderAddressApi = (address = '', callback = null) => {
        const { accessToken } = this.context;

        this.setState({
        geocoderAddressIsLoading: true,
        }, () => {
        let params = {
            address: address,
        };

        ldsApi.read('geo_functions/geocoder_address', params, accessToken)
            .then((result) => {
            apiUtil.parseResult(result, (data) => {
                if(data && data.result){
                this.setState({
                    geocoderAddressIsLoading: false,
                });
                if(callback){
                    callback(data.result);
                }
                } else {
                this.setState({
                    geocoderAddressIsLoading: false,
                });
                if(callback){
                    callback(data.result);
                }
                }
            }, (error, type) => {
                this.setState({
                    geocoderAddressIsLoading: false,
                }, () => {
                if(callback){
                    callback(null);
                }
                });
            });
        });
        });
    }

    callAddressApi = (callback = null) => {
        const { accessToken } = this.context;
       
        this.setState({
            geocoderAddressIsLoading: true,
          }, () => {
            let param = {
              sort: 'latest'
            };
      
            wmsApi.read('warehouses', param, accessToken)
              .then((result) => {
                apiUtil.parseResult(result, (data) => {
                  this.setState({
                    address: (data.result && data.result.length > 0) ? data.result : [],
                    cloneAddress: _.cloneDeep(data.result),
                    total: data.total,
                    geocoderAddressIsLoading: false,
                  });
    
                  if(callback){
                    callback();
                  }
                }, (error, type) => {
                  this.setState({
                    data: [],
                    total: 0,
                    geocoderAddressIsLoading: false,
                  });
                });
            });
          });
    }

    /*===== ====== END API ===== =====*/

  /* FUNCTIONS */
  loadWorkSheet = () => {
    if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                let wb = readXsl(reader.result, {type: 'binary'});
                
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
               
                let data = xslUtils.sheet_to_json(ws, { defval: null, raw: false, header: 1, cellDates: false});
                data.splice(0, 13)

                let filteredData = data.filter(function (el) {
                  return el[0] !== null || el[1] !== null;
                });

                this.setState({
                    importedSheetData: data && data.length > 0 && data[0][0] == 'Trip Date(DD/MM/YYYY)' ? filteredData : [],
                }, () => {
                    this.initColumns();
                });
            };
            reader.readAsBinaryString(this.state.file);
        }
    } else {
        // console.log("This browser does not support HTML5.");
    }
  }
  
  initColumns = () => {
    let { isCustomerRequired } = this.context;
    let columns = [];
    let columnsSteps = [];
    let columnsItems = [];

    let headers = [];
    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = this.state.dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].value : head,
            }
        });
    }
    
    this.state.dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        
        if(headerIndex !== -1){
            feild.selected = feild.value;
            feild.isSelected = true;
        } else {
            feild.isSelected = false;
        }

          
        columns.push({ 
            field: feild.selected,
            realField: feild.value.trimEnd(),
            title: feild.label,
            required: feild.required , 
            dataType: feild.dataType,
            isSelected: feild.isSelected,
            colDB: feild.col,
            width: 200,
            sorting: false,
            searchable: false,
            grouping: false,
            filtering: false,
            render: (row, type) => {
                return this.customCell(row, feild, feild.colIndex !== undefined ? feild.colIndex : i);
            }
        });
        
    });


    columns.forEach((item, index) => {
      if (!isCustomerRequired) {
        if (item.title === 'Company code') {
          columns[index].required = false;
        }

        if (item.title === 'Person in charge tel') {
          columns[index].hidden = true;
        }
      } else {
        if (item.title === "Recipient's name") {
          columns[index].title = 'Client Code';
        }

        // if (item.title === 'Recipient Email' || item.title === 'Person in charge' || item.title === 'Person in charge tel') {
        if (item.title === 'Person in charge') {
          columns[index].hidden = true;
        }
      }
    });
    this.setState({
        columns: columns,
        columnsSteps: columnsSteps,
        columnsItems: columnsItems,
        headers: headers,
    }, () => {
        if(!this.state.isFirstTime){
            this.initRows(()=> {
                this.setState({
                    preview_data : this.getForm(),
                    isLoading: false
                }, ()=> {
                    if (this.state.collection_data == true) {
                        let filter_data = this.state.preview_data.filter(dd => dd.job_type == "Delivery")
                        filter_data.map((dd) => {
                            if (dd.drop_off_address?.trim()?.toLowerCase() == this.state.drop_off_address?.trim()?.toLowerCase() || (dd.drop_off_address?.trim()?.toLowerCase() == (this.state.drop_off_address?.trim()?.toLowerCase() + ', ' + this.state.building_no?.trim()?.toLowerCase())) || (dd.drop_off_address?.trim()?.toLowerCase() == (this.state.drop_off_address?.trim()?.toLowerCase() + ' ' + this.state.building_no?.trim()?.toLowerCase()))) {
                                dd.is_error = true
                                // console.log("error 1")
                                dd.drop_off_address_validate =  "Pick up and Delivery address can not be same"
                            }
                        })
                        
                        this.setState({
                            preview_data: filter_data
                        })
                    }
                })
            });
        } else {
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  initRows = (callback = null) => {
    let excelRows = [];
    this.setState({
        isLoadingInitCalls: true,
    });

    if (this.state.importedSheetData && this.state.importedSheetData.length > 1) {
        excelRows = this.state.importedSheetData.map((item, i) => {
            if(i > 0){
                let row = this.state.importedSheetData[0].reduce((sum, key, index) => Object.assign(sum, { [key]: item[index] }), {});
                return row;
            } else {
                return null;
            }
        });
    }

    // // console.log(this.state.importedSheetData, excelRows, "imported sheet excelrows")

    if(excelRows && excelRows.length > 0){
        excelRows.splice(0, 1);
    }

    let rows = this.initChangeColumns(excelRows, DataFieldType.Job);
    let errMessages = [] 
    let errLatLng = []
    let tempDate = ''
    let tempDO = ''
    
    this.setState({
        excelRows: excelRows,
        rows: rows,
        errMessages: errMessages,
    }, () => {
        this.setState({
            isLoadingInitCalls: false,
            isFirstTime: false,
        });

        if(callback){
            callback();
        }
    });
    
    setLatLngErr = errLatLng
    
  }

  formatDateField = (row, realField, dataType) => {
    let value = (row && realField && row[realField] && row[realField] !== '') ? row[realField] : '';

    if(realField == "Contact No." || realField == "Alternative Contact No.") {
        return value.replaceAll(" ","")
    }

    if(realField == "Trip Date(DD/MM/YYYY)") {
      let tripDate = (value.match(/\//g) || []).length;
      if(tripDate > 2) {
        value = ''; 
      }
      // console.log('value',tripDate);        
    }
    if(dataType && dataType === 'date'){
        try {
            let date = moment(value);
            return (date.isValid()) ? date.format(apiUtil.getDefaultDateFormat()) : value;
        } catch(e) {
            return value
        }
    } else if(dataType && dataType === 'datetime'){
        try {
            let datetime = moment(value);
            return (datetime.isValid()) ? datetime.format(apiUtil.getDefaultDateFormat() + ' ' + this.state.timeFormat) : value;
        } catch(e) {
            return value
        }
    } else {
        return value
    }
  }

  initChangeColumns = (excelRows = [], type = 1) => {
    if(type === DataFieldType.Job){
        let rows = [];
        if(excelRows && excelRows.length > 0){
            excelRows.map((row, i) => {
                let rowArray = [];
                let columns = JSON.parse(JSON.stringify(this.state.columns));

                let counter = 0;
                Object.entries(row).map(([ key, value ]) => {
                    let realKey = key;
                    if(this.state.dataFields && this.state.dataFields[counter] && this.state.dataFields[counter].value){
                        realKey = this.state.dataFields[counter].value;
                    };
                    let columnsIndex = columns.findIndex(x => x.realField === realKey);
                    if(columnsIndex !== -1){
                        if(columns[columnsIndex].realField && columns[columnsIndex].realField !== ''){
                            
                            let dataType = columns[columnsIndex].dataType;
                            let realField = columns[columnsIndex].realField;
                            rowArray[realField] = this.formatDateField(row, realField, dataType);
                            rowArray[realField + '_validated'] = false;

                            columns.splice(columnsIndex, 1);
                        } else {
                            rowArray[realKey] = '';
                            rowArray[realKey + '_validated'] = false;
                        }
                    } else {
                        rowArray[realKey] = '';
                        rowArray[realKey + '_validated'] = false;
                    }
                    
                    counter++;
                });
                rowArray["Job Type"] = row["Job Type"];
                rowArray["XS Packages"] = row["XS Packages"];
                rowArray["S Packages"] = row["S Packages"];
                rowArray["M Packages"] = row["M Packages"];
                rowArray["L Packages"] = row["L Packages"];
                rowArray["Oversized Packages"] = row["Oversized Packages"];
                rowArray["Pallets"] = row["Pallets"];
                rowArray["Period of Delivery"] = row["Period of Delivery"];
                rowArray["Period of Delivery"] = row["Period of Delivery"];
                rowArray["Category"] = row["Category"];

                if (row["Postal Code"]) {
                    rowArray["Postal Code"] = row["Postal Code"].toString().trim();
                }
                
                rowArray["Job Type_validated"] = false;
                rowArray["Driver Notes"] = row["Driver Notes"];
                if (row["Depalletization Request"] == 'Y' || row["Depalletization Request"] == 'N' ) {
                    rowArray["Depalletization Request"] = row["Depalletization Request"];
                } else if (row["Depalletization Request"]) {
                    rowArray["Depalletization Request"] = 'N'
                }
                
                rowArray["Waiting Time"] = row["Waiting Time"];
                rowArray["Vendor Payout"] = row["Vendor Payout"];
                rowArray["Weight (KG)"] = row["Weight (KG)"];
                rowArray["Additional Manpower"] = row["Additional Manpower"];
                rowArray["Size in cm(L + W + H)"] = row["Size in cm(L + W + H)"];
                rowArray["Driver Notes_validated"] = false;
                rows.push(Object.assign({}, rowArray));
            });
        }
        return rows;
    } else {
        let rows = [];
    
        let dataFields = [];
        if(this.state.dataFields && this.state.dataFields.length > 0){
            dataFields = this.state.dataFields.filter(x => x.type === type);
        };

        if(excelRows && excelRows.length > 0){
            let row = excelRows[0];

            let currentRow = Object.entries(row).map(([ key, value ]) => key);
                
            let columns = [];
            let data = {};
            dataFields.forEach(fields => {
                columns.push(fields.value);

                data[fields.value] = [];
                currentRow.forEach(x => {
                    const regex = new RegExp(fields.value + "/[0-9]*\.?[0-9]+/", "g");
                    if(x.match(regex)){
                        let index = x.replace(fields.value, '');
                        data[fields.value].push({ [index]: x });
                    }
                });
            });

            let counts = [];
            columns.forEach(column => {
                if(data[column]){
                    counts.push(data[column].length)
                }
            });
            let maxCount = (counts.length > 0) ? Math.max(...counts) : 0;

            if(maxCount > 0){
                for(let c = 0; c < maxCount; c++) {
                    let newObj = {};

                    columns.forEach(column => {
                        if(data[column] && data[column].length > 0 && data[column][c]){
                            Object.entries(data[column][c]).map(([ key, value ]) => {
                                newObj[column] = ((key-1) === c) ? value : '';
                            });
                        }
                    });

                    Object.assign(newObj, { uuid: uuid() });

                    rows.push(newObj);
                }
            }
        }
        
        return rows;
    }
  }

  countSpacesBetweenWords(str) {

        // Use a regular expression to match regular spaces (ASCII space character ' ')
        const spaceRegex = /\s/g;
        const spaceMatches = str.match(spaceRegex);
      
        // Count the matched spaces
        const spaceCount = spaceMatches ? spaceMatches.length : 0;
      
        return spaceCount;
      
  }

  getForm(){
    let columns = this.state.columns;
    let rows = this.state.rows;
    let excelRows = this.state.excelRows;
    let delivery_type = '';
    let collection_do_number = '';

    if(rows && rows.length > 0){
        rows = rows.map((objData, o) => {
            let excelRow = excelRows[o];
            let extra_data = {}
            let package_info = {}
            let data_row = {}
            let departments = [];
            if(columns && columns.length > 0){
                columns.forEach(c => {
                    if(c.title === 'Notify Customer'){
                        data_row[c.colDB] = c.checked ? c.checked : true ;//c.checked;
                    }else if(c.title === 'Phone No' || c.title === 'Alternative Contact No.'){
                        if(objData[c.realField] !== '' && !apiUtil.phoneValidationWithPlus(objData[c.realField])) {
                            data_row['is_error'] = true
                            // console.log("error 2")
                            data_row[c.colDB+"_validate"] = `Invalid ${c.title}` 
                        }
                        data_row[c.colDB] = objData[c.realField].length === 8 ? "+65" + objData[c.realField].toString() : objData[c.realField] ? objData[c.realField].toString() : null
                    } else if(c.title === 'Send Tracking link'){
                        data_row[c.colDB] = c.checked ? c.checked : true ; //c.checked;
                    } else if(c.title === 'Project'){
                        data_row[c.colDB] = objData[c.realField] == 'Yes' ? true : false;
                    } else if(c.title === 'Postal Code'){
                        data_row[c.colDB] = objData[c.realField] ? objData[c.realField].toString() : null ;
                        let postalType = parseInt(objData[c.realField]);
                        if (objData[c.realField] && (objData[c.realField].length > 6 || objData[c.realField].length < 2 || objData[c.realField] != postalType || objData[c.realField] < 0)) {
                            data_row['is_error'] = true
                            data_row['postal_code_validate'] = 'Invalid Postal Code'
                        }
                    } else if(c.title === 'Department'){
                        data_row['department_id'] = null
                        data_row['department_name'] = objData[c.realField] ? objData[c.realField].trim() : objData[c.realField]
                        departments && departments.map((dd) =>{
                            if (dd.name.trim().toLowerCase().normalize() == objData[c.realField] && objData[c.realField].trim().toLowerCase().normalize()) {
                                data_row['department_id'] = dd.id
                                data_row['departments'] = []
                                data_row['departments'] = departments
                            }  
                        })     
                        let department_exit = [];
                        department_exit = departments && departments.filter(dd => {
                            const departmentName = dd.name && dd.name.trim(); 
                            const excelValue = objData[c.realField] && objData[c.realField].trim(); 
                          
                            return departmentName === excelValue;
                          });
                        data_row['department_id'] = department_exit && department_exit[0] && department_exit[0].id
                        // console.log("department_exit", department_exit, departments,'ss', data_row['department_id'], data_row['departments'],'obj value',objData[c.realField])
                        if (objData[c.realField] && objData[c.realField].trim() != "" && objData[c.realField] != null && !department_exit.length > 1) {

                            data_row['is_error'] = true
                            // console.log("error 4")
                            data_row['department_validate'] = 'Invalid Department'
                        }                   
                    } else if(c.title === 'Category'){
                        data_row[c.colDB] = objData[c.realField] ;
                        if(objData[c.realField] !== 'Same Day' && objData[c.realField] !== "Next Day") {
                            data_row['is_error'] = true
                            // console.log("error 5")
                            data_row['period_of_delivery_validate'] = 'Wrong/missing category'
                        }
                    } else if(c.title === 'Job Type'){
                        data_row[c.colDB] = objData[c.realField] == 'Pick Up' ? 'Collection' : 'Delivery';
                        if (objData[c.realField] == 'Pick Up') {
                            delivery_type = 'collection'
                        } else {
                            delivery_type = 'delivery'
                        }
                        if (objData[c.realField] !== 'Pick Up' && objData[c.realField] !== "Delivery") {
                            data_row['is_error'] = true
                            // console.log("error 6")
                            data_row['job_type_validate'] = 'Invalid Job Type'
                        }
                    } else if(c.title === 'DO number'){
                        data_row[c.colDB] = objData[c.realField] ? objData[c.realField].trim() : objData[c.realField];
                        if (delivery_type == 'collection') {
                            collection_do_number = objData[c.realField]
                        }
                        if ((objData[c.realField] == null || objData[c.realField] == "") && this.state.collection_data == false) {
                            data_row['is_error'] = true
                            // console.log("error 7")
                            data_row['do_validate'] = 'Invalid DO Number'
                        }
                    } else if(c.title === 'Pick Up Ref (D.O)'){
                        if (delivery_type == 'Collection') {
                            data_row[c.colDB] = null;
                        } else {
                            data_row[c.colDB] = (objData[c.realField] && objData[c.realField].trim()) == (collection_do_number && collection_do_number.trim()) ? collection_do_number : objData[c.realField]
                        }
                    } else if(c.title === 'Job date') {

                          let splitDate = objData[c.realField].split('/');                          
                          if(splitDate.length != 3) {
                            splitDate = objData[c.realField].split('-');
                          } 
                          let day_true = false;
                          let month_true = false;
                          if(splitDate.length == 3) {
                            day_true = (splitDate[1] == 4 || splitDate[1] == 6|| splitDate[1] == 11 || splitDate[1] == 9 ) && (splitDate[0] > 0 && splitDate[0] <= 30) ? true :
                                            (splitDate[1] == 1 || splitDate[1] == 3|| splitDate[1] == 5 || splitDate[1] == 7 || splitDate[1] == 8 || splitDate[1] == 10 || splitDate[1] == 12 ) && (splitDate[0] > 0 && splitDate[0] <= 31) ? true :
                                            ((splitDate[1] == 2) && (splitDate[0] > 0 && splitDate[0] <= 28)) || ((splitDate[1] == 2) && (splitDate[2] % 4 == 0) && (splitDate[0] > 0 && splitDate[0] <= 29)) ? true : false;
                            month_true = splitDate[1] > 0 && splitDate[1] <= 12 ? true : false;
                          }
                          if (this.state.collection_data == true) {
                            data_row[c.colDB] = this.state.drop_off_date ? this.state.drop_off_date  : moment().format('YYYY-MM-DD')
                          } else {
                            if(splitDate.length == 3 && month_true && day_true) {                          
                                data_row[c.colDB] = (splitDate[2].length == 2 ? '20'+splitDate[2] : splitDate[2])+'-'+((splitDate[1].length == 1 && splitDate[1] < 10) ? '0'+splitDate[1] : splitDate[1])+'-'+((splitDate[0].length == 1 && splitDate[0] < 10) ? '0'+splitDate[0] : splitDate[0]);
                              } else {
                                data_row[c.colDB] = moment.parseZone(objData[c.realField]).format('YYYY-MM-DD');
                              }
                          }
                          if(this.state.collection_data == false && moment(objData[c.realField], 'DD/MM/YYYY', true).isValid() == false && moment(objData[c.realField], 'D/M/YYYY', true).isValid() == false) {
                            data_row['is_error'] = true
                            data_row[c.colDB] = '';                            
                          }
                    } else if(c.title === 'Client Code'){
                        let company_code = objData[c.realField] ? objData[c.realField].trim() : objData[c.realField]
                        data_row[c.colDB] = company_code;
                        let customerExit = [];
                        customerExit = this.customer_items && this.customer_items.length > 0 && this.state.currentRole != "Customer Template Role" ? this.customer_items.filter((dd) => dd.label && (dd.label.replace(/\s+/g, '').trim() === company_code.replace(/\s+/g, '') && (this.countSpacesBetweenWords(dd.label.trim()) == this.countSpacesBetweenWords(company_code)))) : this.customer_items
                        departments = customerExit && customerExit.length > 0 && customerExit[0].item && customerExit[0].item.departments
                        data_row['customer_id'] = customerExit && customerExit.length > 0 && customerExit[0].value ? customerExit[0].value : null;
                        data_row['company_name'] = customerExit && customerExit.length > 0 && customerExit[0].company_name ? customerExit[0].company_name : null;
                        data_row['customer_rate'] = customerExit && customerExit.length > 0 && customerExit[0].item && customerExit[0].item.customer_rates ? customerExit[0].item.customer_rates : [];
                        data_row['departments'] = departments

                    } else if(c.title === 'Notification Email'){
                        data_row[c.colDB] = objData[c.realField] ? objData[c.realField].trim() : objData[c.realField];
                        if(objData[c.realField] !== '' && !apiUtil.emailValidation(objData[c.realField])) {
                            data_row['is_error'] = true
                            // console.log("error 8")
                            data_row['email_validate'] = 'Invalid Email'
                        }
                    } else if(c.title === 'Address'){
                        if (objData[c.realField] === '' || objData[c.realField] === null || (typeof(objData[c.realField]) != 'undefined' && objData[c.realField].trim().length === 0)) {
                            data_row['is_error'] = true
                            // console.log("error 9")
                            data_row['drop_off_address_validate'] = 'Wrong/missing address'
                        }
                        data_row[c.colDB] = objData[c.realField] ? objData[c.realField].trim() : objData[c.realField];
                    } else if(c.title === 'Assigned Driver ID'){
                        data_row[c.colDB] = objData[c.realField];
                    } else if(c.title === 'Boxes'){
                        extra_data[c.colDB] = isNaN(objData[c.realField]) ? null :Math.abs(objData[c.realField]) == 0 ? null : Math.abs(objData[c.realField]) ;
                    } else if(c.title === 'Document'){
                        extra_data[c.colDB] = objData[c.realField];
                    } else if(c.title === 'Cartons'){
                        extra_data[c.colDB] = isNaN(objData[c.realField]) ? null : Math.abs(objData[c.realField]) == 0 ? null : Math.abs(objData[c.realField]);
                    } else if(c.title === 'Depalletization Request'){
                        package_info[c.colDB] = objData[c.realField];
                    } else if(c.title === 'Size in cm(L + W + H)'){
                        package_info[c.colDB] = objData[c.realField];
                    } else if(c.title === 'Weight (KG)'){
                        package_info[c.colDB] = objData[c.realField] ;
                    } else if(c.title === 'Waiting Time'){
                        package_info[c.colDB] = objData[c.realField] ;
                    } else if (c.title === 'Additional Manpower') {
                        package_info[c.colDB] = isNaN(objData[c.realField]) ? null : Math.abs(objData[c.realField]) == 0 ? null : Math.abs(objData[c.realField])  ;
                    } else if (c.title === 'XS Packages' || c.title === 'S Packages' || c.title === 'M Packages' || c.title === 'L Packages' || c.title === 'Pallets' ) {
                        data_row[c.colDB] = isNaN(objData[c.realField]) ? null : Math.abs(objData[c.realField]) == 0 ? null : Math.abs(objData[c.realField]) ;
                    } else {
                        data_row[c.colDB] = objData[c.realField];
                       
                        if (c.title == 'Between' || c.title == 'And' ) {

                            if((objData[c.realField] == '' || objData[c.realField] == undefined || objData[c.realField] == null) || objData[c.realField] == '' || objData[c.realField] == null || objData[c.realField] == undefined) {
                                data_row['is_timing_error'] = true
                                // console.log("error 10")
                                data_row['time_window_validate'] = 'Invalid time window'
                            }
                        }
                    }
                })
            }

            // data_row['extra_data'] = JSON.stringify(extra_data);
            data_row['package_info'] = JSON.stringify(package_info);
            data_row['is_calculate'] = true
            let items = [];
            let totalPackage = 0;
            let itemFields = this.state.rowsItems;
            let columnsItems = this.state.columnsItems;
            if(itemFields && itemFields.length > 0){
                items = itemFields.map((item, f) => {
                    let row = {};

                    if(columnsItems && columnsItems.length > 0){
                        columnsItems.forEach(c => {
                            if(c.colDB === 'desc'){
                                row['description'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'qty'){
                                row['quantity'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'uom'){
                                row['uom'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'Weight (KG)'){
                                row['Weight (KG)'] = excelRow[item[c.colDB]] || 0;
                            } else if(c.colDB === 'remarks'){
                                row['remarks'] = excelRow[item[c.colDB]] || '';
                            } else if(c.colDB === 'sku'){
                                row['sku'] = excelRow[item[c.colDB]];
                            } else {
                                row[c.colDB] = excelRow[item[c.colDB]];
                            }
                        })
                    }
                    if(!row['description'] || !row['Weight (KG)']) {
                        return
                    } else {
                        return row;
                    }
                    
                });
            }
            data_row.order_details = items.length > 0 ? items : [];
            data_row.total_package = totalPackage;

            let steps = [];
            let rowsSteps = this.state.rowsSteps;
            
            let columnsSteps = this.state.columnsSteps;
            if(rowsSteps && rowsSteps.length > 0){
                steps = rowsSteps.map((step, s) => {
                    let row = {};

                    if(columnsSteps && columnsSteps.length > 0){
                        columnsSteps.forEach(c => {
                            if(c.colDB === 'address'){
                                row['location'] = excelRow[step[c.field]] ? excelRow['Step Address']? excelRow['Step Address']: excelRow[step[c.field]] : excelRow[step['address']] ;
                            } else if(c.colDB === 'name'){
                                row['name'] = excelRow[step[c.colDB]] || 'Step ' + (s + 1);
                                row['job_step_name'] = excelRow[step[c.colDB]] || 'Step ' + (s + 1);
                            } else if(c.colDB === 'signature'){
                                row['is_signature_required'] = (step[c.colDB]) ? (step[c.colDB]): true; //true : false;
                            } else if(c.colDB === 'scanning'){
                                row['is_scan_required'] = (step[c.colDB]) ? true : false;
                            } else {
                                row[c.colDB] = excelRow[step[c.colDB]];
                            }
                        })
                    }
                    row['order_sequence'] = (s + 1);
                    row['job_step_status_id'] = 1;
                    // row['job_step_pic'] = step.drop_off_pic;
                    // row['job_step_pic_contact'] = step.drop_off_contact_no;
                    if(!row['location']) {
                        return
                    }
                    return row;
                });
            }
            
            data_row.job_steps = steps.length > 0 ? steps : [];

            // // console.log("objData", objData);
            return data_row;
        });
    } 
    
    return rows;
  }
  /*===== ===== END FUNCTIONS ===== =====*/

  bindWorkerVendor = (order) => {
    let filter_order = [] ;
    filter_order = this.state.preview_data;
    // // console.log("filter order", filter_order)
    if (order.job_type == "Delivery") {
        filter_order.map(item => {
            if (item.collection_reference == order.collection_reference || item.do_number == order.collection_reference) {
                item.drop_off_worker_id = order.drop_off_worker_id
                item.drop_off_worker_name = order.drop_off_worker_name
                item.vendor_id = order.vendor_id
                item.vendor_name = order.vendor_name
                item.order_status = order.order_status
                item.order_status_id = order.order_status_id
            }
        })
    } else if (order.job_type == "Collection") {
        filter_order.map(item => {
            if (item.collection_reference == order.do_number ) {
                item.drop_off_worker_id = order.drop_off_worker_id
                item.drop_off_worker_name = order.drop_off_worker_name
                item.vendor_id = order.vendor_id
                item.vendor_name = order.vendor_name
                item.order_status = order.order_status
                item.order_status_id = order.order_status_id
            } 
        })
    }
    this.setState({
        order: filter_order
    })
  }

  errorReCheck = (order) => {

    order.drop_off_contact_no = order.drop_off_contact_no?.length == 8 ?  "+65" + order.drop_off_contact_no : order.drop_off_contact_no
    order.drop_off_alternate_contact = order.drop_off_alternate_contact?.length == 8 ?  "+65" + order.drop_off_alternate_contact : order.drop_off_alternate_contact
    order.drop_off_address_validate = order.drop_off_address != "" && order.drop_off_address != null ? null : 'Wrong/missing address'
    order.drop_off_contact_no_validate = order.drop_off_contact_no != "" && order.drop_off_contact_no != null && !apiUtil.phoneValidationWithPlus(order.drop_off_contact_no) ?   'Invalid Contact No' : null
    order.drop_off_alternate_contact_validate = order.drop_off_alternate_contact != "" && order.drop_off_alternate_contact != null && !apiUtil.phoneValidationWithPlus(order.drop_off_alternate_contact) ? 'Invalid Alternate Contact No' : null
    order.do_validate = order.do_number != "" && order.do_number != null ? null : 'Wrong/missing DO Number'
    order.period_of_delivery_validate = order.period_of_delivery != "" && order.period_of_delivery != null ? null : 'Wrong/missing category'
    order.job_type_validate = order.job_type != "" && order.job_type != null ? null : 'Invalid Job Type'
    let postalType = parseInt(order.drop_off_postal_code)
    order.postal_code_validate = order.drop_off_postal_code && (order.drop_off_postal_code.length > 6 || order.drop_off_postal_code.length < 2 || order.drop_off_postal_code != postalType || order.drop_off_postal_code < 0) ? 'Invalid Postal Code' : null
    order.drop_off_date_validate = (order.drop_off_date != "" && order.drop_off_date != null) || this.state.collection_data ?  null : 'Invalid Date'
    if(this.state.collection_data) {
      order.drop_off_date = this.state.drop_off_date ? this.state.drop_off_date  : moment().format('YYYY-MM-DD')      
    }
    order.email_validate = order.notification_email != "" && !apiUtil.emailValidation(order.notification_email) ? 'Invalid Email' :  null
    order.time_window_validate = (order.drop_off_from_time != "" && order.drop_off_from_time != null ) && ( order.drop_off_to_time != "" && order.drop_off_to_time != null) ? null : 'Invalid time window'
    order.package_validate = order.xs_packages != "" || order.s_packages != "" || order.m_packages != "" || order.l_packages != "" || order.pallets != "" || order.oversized_packages != "" ? null : 'Missing Packages'

    order.department_validate = order.department_id != null && order.department_id != '' && order.department_name != null && order.department_name != '' && order.departments.filter(dd => dd.id == order.department_id).length <= 0 ? "invalid Department" : null
    order.package_info = typeof(order.package_info) == 'string' ? order.package_info : JSON.stringify(order.package_info)
    order.collection_reference_validate = null
    if ( order.drop_off_contact_no_validate == null &&
        order.drop_off_alternate_contact_validate == null &&
        order.do_validate == null &&
        order.period_of_delivery_validate == null &&
        order.job_type_validate == null &&
        order.postal_code_validate == null &&
        order.drop_off_date_validate == null &&
        order.email_validate == null &&
        order.drop_off_address_validate == null &&
        order.package_validate == null &&
        order.department_validate == null) {
        order.is_error = false
    } else {
        order.is_error = true
    }

    if (order.time_window_validate == null) {
      order.is_timing_error = false
    } else {
      order.is_timing_error = true
    }

    return order;
  }

  previewDialog = () => {
    return <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          preview_detail: null,
          preview_index: null,
          isLoading: false,
        });
      }}
      btnOkText={"Save Changes"}
      hideCancelButtons={true}
      hideOkButtons={true}
    >
      <ImportDetailForm 
        order={this.state.preview_detail}
        is_deli_use_saved_address={this.state.is_deli_use_saved_address}
        onSaveChange={(order) => {
            this.bindWorkerVendor(order);
            
            let preview_data = this.state.preview_data
            preview_data[this.state.preview_index] = this.errorReCheck(order)
                let package_info = {
                    depalletization_request: this.state.preview_data[this.state.preview_index].depalletization_request,
                    size_l_w_h: this.state.preview_data[this.state.preview_index].size_l_w_h,
                    weight: this.state.preview_data[this.state.preview_index].weight,
                    waiting_time: this.state.preview_data[this.state.preview_index].waiting_time,
                    no_of_movers: this.state.preview_data[this.state.preview_index].no_of_movers,
                  };
                preview_data[this.state.preview_index].package_info = package_info
            for (let i = 0; i < this.state.preview_data.length; i++) {
                preview_data[i] = this.errorReCheck(preview_data[i]);
            }

            this.setState({
                preview_data: preview_data,
                openPreviewDialog: false,
                isLoading: false,
                preview_detail: null,
                preview_index: null,
            });
          }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            isLoading: false,
            preview_detail: null,
            preview_index: null,
          });       
        }}
    /> 
    </CustomDialog2>
    
  }


  customCell = (row, feild, i) => {
    let { isCustomerRequired } = this.context;
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;
    isError = ((isError === false) || (isError === true)) ? isError : false;
    let isSelected = this.state.columns[i] !== undefined ? this.state.columns[i].isSelected : false;

    if(isSelected){
        return <Box>
            {
                (feild.label === 'Address')
                ?
                <CustomGoogleMapSearch
                    className={'no-margin'}
                    placeholder={feild.label}
                    value={row[feild.value]}
                    required={feild.required}
                    error={isError}
                    onChange={(e) => {
                        let value = (e.target.value && e.target.value !== '') ? e.target.value : '';
                        
                        row[feild.value] = value;
                        row[feild.value + '_validated'] = (value !== '') ? false : feild.required;

                        if(value === ''){
                            row['coord'] = null;

                            let rows = this.state.rows;
                            rows[row.tableData.id] = row;
                            this.setState({
                                rows: rows
                            });
                        }
                    }}
                    onPlacesChanged={(place) => {
                        if(place){
                            let address = apiUtil.getGmapAddress(place);
                            
                            row[feild.value] = address;
                            row[feild.value + '_validated'] = feild.required;
                            
                            this.callGeoCoderAddressApi(address, (data) => {
                                if(data){
                                    row['coord'] = {
                                        lat: data.lat,
                                        lng: data.lng,
                                    };
                                }
                            
                                let rows = this.state.rows;
                                rows[row.tableData.id] = row;
                                this.setState({
                                    rows: rows
                                });
                            });
                        } else {
                            row[feild.value] = '';
                            row[feild.value + '_validated'] = feild.required;
                            row['coord'] = null;

                            let rows = this.state.rows;
                            rows[row.tableData.id] = row;
                            this.setState({
                                rows: rows
                            });
                        }
                    }}
                />
                :
                (feild.dataType === 'checkbox')
                ?
                <CustomCheckbox
                    label={feild.label}
                    checked={row[feild.value]}
                    onChange={(checked) => {
                        row[feild.value] = checked;
                    }}
                />
                :
                <CustomInput
                    className={'no-margin'}
                    placeholder={feild.label}
                    value={row[feild.value]}
                    required={feild.required}
                    error={isError}
                    onChange={(e) => {
                        row[feild.value] = e.target.value;
                        row[feild.value + '_validated'] = (e.target.value !== '') ? false : feild.required;
                    }}
                />
            }
        </Box>;
    } else {
        return <Box></Box>
    }
  }

  deleteItemByIndex = (index) => {

    this.setState({ isLoading: true}, ()=> {
        const updatedData = [...this.state.preview_data]; // Create a copy of the original array
        updatedData.splice(index , 1); // Use splice to remove the item at the specified index
      
        for (let i = 0; i < updatedData.length; i++) {
            updatedData[i] = this.errorReCheck(updatedData[i]);
        }
        // Update the state with the new array
        this.setState({ isLoading: false, preview_data: updatedData });
    });
  };

  getTimeWindow = ( s_time = '', e_time = '' ) => {
    let start_time = moment(s_time,'hh:mm');
    let end_time = moment(e_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    let timeslot = '';
    if(diff_time > 0) {
      if(diff_time < 3){
        timeslot = 'express (2h slot)'; 
      } else if(diff_time >= 3 && diff_time <= 5) {
        timeslot = 'next day (3h slot)'; 
      }
    }      
    return timeslot;
  }
  
  transformData = (data) => {
    const transformedData = [];
    const groupedData = {};
    let inner = []
    data.forEach((item, index) => {
      if (item.job_type == 'Collection') {
        if (index != 0) {
            transformedData.push({ "orders": inner })
            inner = []
        }
        item.index_data = index
        inner.push(item)
      } else {
        item.index_data = index
        inner.push(item)
      }
      if(data.length - 1 == index) {
        transformedData.push({ "orders": inner })
      }
    });

    for (const key in groupedData) {
        transformedData.push({ "orders": groupedData[key] });
      }
  
    return transformedData;
  };

  previewOrders = () => {
    let tempDate = null;
    let tempDO = null;
    let group = []
    // console.log("preview_data", this.state.preview_data)
    const groupedData = this.transformData(this.state.preview_data)
    let is_deli_use_saved_address = false
    if(this.state.isLoadingInitCalls || this.state.geocoderAddressIsLoading ){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'35vh'} width={'100%'}>
            <CircularProgress size={55} />
        </Box>
      } else {
        return this.state.preview_data.length > 0 ? <Container className="mt-3">
            <div className="py-4 d-flex justify-content-between align-items-center">
                <div className='fw-bold'>Order Preview</div>
                <div onClick={()=> {
                    if(this.props.onClose){
                        this.props.onClose();
                    }
                }}><FontAwesomeIcon icon={faXmark} className='close-btn-circle'/></div>
            </div>
            {groupedData.map((card, ind) => (
            <Card key={ind} className="mb-4 preview-card-job">
                {card.orders.map((dd, index)=> {
                dd.is_error == true ? dd.is_error = dd.is_error : dd.is_error = false
                dd.is_clicked_timing == true ? dd.is_clicked_timing = dd.is_clicked_timing : dd.is_clicked_timing = false
                dd.original_from_time ? dd.original_from_time = dd.original_from_time : dd.original_from_time = ''
                dd.original_to_time ? dd.original_to_time = dd.original_to_time : dd.original_to_time = ''
                dd.do_number = dd.do_number ? dd.do_number.trim() : null
                dd.collection_reference = dd.collection_reference ? dd.collection_reference.trim() : null
                dd.do_validate = null
                dd.collection_reference_validate = null
                dd.drop_off_date_validate = null
                dd.pick_up_order_validate = null
                dd.delivery_order_validate = null
                // console.log('dd date',dd.drop_off_date);
                if((dd.drop_off_date === '' || dd.drop_off_date === null || (typeof(dd.drop_off_date) != 'undefined' && dd.drop_off_date.trim().length === 0)) && this.state.collection_data == false) {
                    dd.is_error = true
                    // console.log("error 17")
                    dd.drop_off_date_validate = 'Invalid Date'
                }

                let check_dup_dos = this.state.preview_data.filter(item => item.do_number && item.do_number.trim() == dd.do_number && dd.do_number.trim())
                if (check_dup_dos.length > 1 || dd.do_number == null || dd.do_number == '') {
                    dd.is_error = true
                    // console.log("error 18")
                    dd.do_validate = "Wrong/missing DO Number"
                }
                console.log('job type',this.state.collection_data);
                if(this.state.collection_data == false && dd.job_type == 'Collection'){
                  if(this.state.preview_data.filter((obj) => obj.job_type == 'Delivery' && obj.collection_reference == dd.do_number && (obj.drop_off_address?.trim()?.toLowerCase() == dd.drop_off_address?.trim()?.toLowerCase() || (dd.drop_off_address?.trim()?.toLowerCase() == (obj.drop_off_address?.trim()?.toLowerCase() + ', ' + obj.building_no?.trim()?.toLowerCase())) || (dd.drop_off_address?.trim()?.toLowerCase() == (obj.drop_off_address?.trim()?.toLowerCase() + ' ' + obj.building_no?.trim()?.toLowerCase())))).length > 0) {
                    dd.is_error = true 
                    // console.log("error 19")
                    dd.do_validate = "Pick up and Delivery address can not be same"                          
                  }                  
                } else if(this.state.collection_data) {
                  if(this.state.preview_data.filter((obj) => obj.job_type == 'Delivery' && (obj.drop_off_address?.trim()?.toLowerCase() == this.state.drop_off_address?.trim()?.toLowerCase() || obj.drop_off_address?.trim()?.toLowerCase() == (this.state.drop_off_address?.trim()?.toLowerCase() + ', ' + this.state.building_no?.trim()?.toLowerCase())) || (obj.drop_off_address?.trim()?.toLowerCase() == (this.state.drop_off_address?.trim()?.toLowerCase() + ' ' + this.state.building_no?.trim()?.toLowerCase()))).length > 0) {
                    dd.is_error = true 
                    // console.log("error 20")
                    dd.do_validate = "Pick up and Delivery address can not be same"                          
                  }                      
                }

                if (this.state.collection_data == false) {
                    if(dd.job_type == 'Collection')  {
                        if ( dd.drop_off_date !== '' || (typeof(dd.drop_off_date) != 'undefined' && dd.drop_off_date.trim().length === 0)) {
                            tempDate = dd.drop_off_date;
                        }
                        tempDO = dd.do_number;
                    } else {
                        let colDate = '';
                        let deliDate = '';
                        let colDateValidate = '';
                        let deliDateValidate = '';
                        if( moment.parseZone(tempDate).format('YYYY-MM-DD') != 'Invalid date' && moment.parseZone(dd.drop_off_date).format('YYYY-MM-DD') != 'Invalid datae') {
                          colDate = moment(moment.parseZone(tempDate).format('YYYY-MM-DD'),'DD-MM-YYYY');
                          colDateValidate = moment(tempDate);
                          deliDate = moment(moment.parseZone(dd.drop_off_date).format('YYYY-MM-DD'),'DD-MM-YYYY');
                          deliDateValidate = moment(dd.drop_off_date);
                        } else {
                          colDate = moment(tempDate,'DD-MM-YYYY');
                          colDateValidate = moment(tempDate);
                          deliDate = moment(dd.drop_off_date,'DD-MM-YYYY');        
                          deliDateValidate = moment(dd.drop_off_date);                     
                        }

                        if(deliDateValidate.isBefore(colDateValidate)) {
                        //if (deliDate.diff(colDate,'days') < 0) {
                            dd.is_error = true
                            // console.log("error 21", dd.is_error)
                            dd.drop_off_date_validate = "Delivery date can not be ealier than Pick Up date" 
                        }

                        if (dd.collection_reference != '' && dd.collection_reference != null && tempDO != dd.collection_reference) {
                            dd.is_error = true
                            // console.log("error 22", dd.is_error)
                            dd.collection_reference_validate = "Wrong/missing Pick Up Ref (D.O)"
                        }

                        if (dd.collection_reference == null || dd.collection_reference == '') {
                            dd.is_error = true
                            // console.log("error 23", dd.is_error)
                            dd.collection_reference_validate = "Wrong/missing Pick Up Ref (D.O)"
                        }

                    }

                    if (dd.job_type == "Delivery") {
                    if (tempDO && tempDO.replace(/\s/g, '\u00A0').trim() != (dd.collection_reference && dd.collection_reference.replace(/\s/g, '\u00A0').trim())) {
                            dd.is_error = true;
                            // console.log("error 24", dd.is_error)
                            dd.delivery_order_validate = "missing Pick Up Order"
                    }
                    }

                    if (dd.job_type == "Collection") {
                        const ref_exit_for_do = this.state.preview_data.filter(item => item.job_type == "Delivery" && item.collection_reference && dd.do_number && item.collection_reference.trim() == dd.do_number.trim() && dd.do_number.trim())                       
                        if (ref_exit_for_do.length <= 0 ) {
                            dd.is_error = true;
                            // console.log("error 25", dd.is_error)
                            dd.pick_up_order_validate = "missing Delivery Order"
                        }
                        

                    }

                    let day = null, month = null, year = null;
                    let dateStr = [];
                    if (dd.drop_off_date.includes('/')) {
                        dateStr = dd.drop_off_date;
                        [day, month, year] = dateStr.split('/');
                    } else {
                        dateStr = moment(dd.drop_off_date).format('DD/MM/YYYY');
                        [month, day, year] = dateStr.split('/');
                    }
        
                    let checkDate = dd.drop_off_date ? dd.drop_off_date.split('/') : [];
                    if(checkDate.length != 3) {
                    checkDate = dd.drop_off_date ? dd.drop_off_date.split('-') : [];  
                    }
                    
                    // // console.log('date',dd.drop_off_date);
                    //// console.log('check',moment.parseZone(dd.drop_off_date).format('YYYY-MM-DD'));
                    const day_true = (month == 4 || month == 6|| month == 11 || month == 9 ) && (day > 0 && day <= 30) ? true :
                                    (month == 1 || month == 3|| month == 5 || month == 7 || month == 8 || month == 10 || month == 12 ) && (day > 0 && day <= 31) ? true :
                                    ((month == 2) && (day > 0 && day <= 28)) || ((month == 2) && (year % 4 == 0) && (day > 0 && day <= 29)) ? true : false;
                    const month_true = month > 0 && month <= 12 ? true : false;
                    const year_true = year > 10 && year <= 9999 ? true : false;
                    const isValidDate = day_true && month_true && year_true ? true : false;
                    
                    if(dd.drop_off_date != '' && !isValidDate) {
                        if(moment.parseZone(dd.drop_off_date).format('YYYY-MM-DD') == 'Invalid date') {
                            dd.is_error = true
                             // console.log("error 26", dd.is_error)
                            dd.drop_off_date_validate = 'Invalid Date'
                        } else if(checkDate.length != 3){
                            dd.is_error = true
                             // console.log("error 27", dd.is_error)
                            dd.drop_off_date_validate = 'Invalid Date'
                        } else if(!isNaN(+day) == false || !isNaN(+month) == false || !isNaN(+year) == false) {
                            dd.is_error = true
                             // console.log("error 28", dd.is_error)
                            dd.drop_off_date_validate = 'Invalid Date' 
                        }
                    } else if (dd.drop_off_date == '' || dd.drop_off_date == null){
                        dd.is_error = true
                         // console.log("error 29", dd.is_error)
                        dd.drop_off_date_validate = 'Invalid Date' 
                    }                  
        
                }


                let timeslot = this.getTimeWindow(dd.drop_off_from_time, dd.drop_off_to_time);   
                dd.time_window = timeslot ? timeslot : '';

                if (dd.job_type == "Collection" && this.state.preview_data[index + 1] && this.state.preview_data[index + 1].job_type == "Collection") {
                    dd.is_error = true;
                    // console.log("error 31", dd.is_error)
                    dd.pick_up_order_validate = "missing Delivery Order"
                    // dd.delivery_need_validate = "Missing Delivery Order for Pick Up"
                }

                if (index == this.state.preview_data.length - 1 && dd.job_type == "Collection") {
                    dd.is_error = true;
                    // console.log("error 32", dd.is_error)
                    dd.pick_up_order_validate = "missing Delivery Order"
                }

                dd.selected_package = []
                if (dd.xs_packages != null && dd.xs_packages != '') {
                dd.selected_package.push({package_id: 1,
                                            name: 'XS',
                                            package_quantity: dd.xs_packages})
                }
                if (dd.s_packages != null && dd.s_packages != '') {
                dd.selected_package.push ({package_id: 2,
                                            name: 'S',
                                            package_quantity: dd.s_packages})
                }
                if (dd.m_packages != null && dd.m_packages != '') {
                dd.selected_package.push({package_id: 3,
                                            name: 'M',
                                            package_quantity: dd.m_packages})
                }
                if (dd.l_packages != null && dd.l_packages != '') {
                dd.selected_package.push({package_id: 4,
                                            name: 'L',
                                            package_quantity: dd.l_packages})
                }
                if (dd.pallets != null && dd.pallets != '') {
                dd.selected_package.push({package_id: 5,
                                            name: 'Pallets',
                                            package_quantity: dd.pallets})
                }
                if (dd.oversized_packages != null && dd.oversized_packages != '') {
                dd.selected_package.push({package_id: 6,
                                            name: 'Oversized Package',
                                            package_quantity: dd.oversized_packages})
                }

                if (dd.drop_off_from_time !== '' && dd.drop_off_to_time  !== '') {
                    let start_time = moment(dd.drop_off_from_time,'hh:mm');
                    let end_time = moment(dd.drop_off_to_time,'hh:mm');
                    let diff_time = end_time.diff(start_time, 'hours',true);
                    // // console.log(diff_time);
                    if (diff_time < 0 || dd.drop_off_from_time == dd.drop_off_to_time || isNaN(diff_time)) {
                        dd.is_timing_error = true
                        // console.log("error 33", dd.is_error)
                        dd.time_window_validate = "invalid time window"
                    }
                }

                if(dd.xs_packages < 0 || isNaN(dd.xs_packages)) {
                dd.xs_packages = ''        
                }
                
                if(dd.s_packages < 0 || isNaN(dd.s_packages)) {
                dd.s_packages = ''           
                }
                
                if(dd.m_packages < 0 || isNaN(dd.m_packages)) {
                dd.m_packages = ''           
                }
                
                if(dd.l_packages < 0 || isNaN(dd.l_packages)) {
                dd.l_packages = ''          
                }
                
                if(dd.oversized_packages < 0 || isNaN(dd.oversized_packages)) {
                dd.oversized_packages = ''           
                }

                if(dd.pallets < 0 || isNaN(dd.pallets)) {
                dd.pallets = ''           
                }

                if((dd.xs_packages == '' || dd.xs_packages == null || dd.xs_packages == 0) && (dd.s_packages == '' || dd.s_packages == null || dd.s_packages == 0) && (dd.m_packages == '' || dd.m_packages == null || dd.m_packages == 0) && (dd.l_packages == '' || dd.l_packages == null || dd.l_packages == 0) && (dd.oversized_packages == '' || dd.oversized_packages == null || dd.oversized_packages == 0) && (dd.pallets == '' || dd.pallets == null || dd.pallets == 0)) {            
                    dd.is_error = true
                    // console.log("error 34", dd.is_error)
                    dd.package_validate = 'Missing Packages'
                }

                if ((!dd.amount || parseInt(dd.amount) == 0) && (!dd.driver_payout || parseInt(dd.driver_payout) == 0) && (!dd.vendor_payout || parseInt(dd.vendor_payout) == 0) ) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(
                        dd.selected_package, 
                        dd.customer_rate, 
                        dd.is_project, 
                        dd.time_window != '' ? dd.time_window : dd.period_of_delivery, 
                        dd.drop_off_date, 
                        dd.drop_off_postal_code,
                        dd.job_type, 
                        dd.drop_off_address, 
                        this.state.address, 
                        dd.created_by,
                        false,
                        dd.drop_off_latitude,
                        dd.drop_off_longitude,
                        dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                        null,
                        false
                        )
                        
                        dd.amount = client
                        dd.driver_payout = payout
                        dd.vendor_payout = subcon
                        dd.base_charge = base_charge
                        dd.surcharge = surcharge
                }

                if (dd.job_type == "Collection") {
                  const ref_exit_for_do = this.state.preview_data.filter(item => item.job_type == "Delivery" && item.collection_reference && dd.do_number && item.collection_reference.trim() == dd.do_number.trim() && dd.do_number.trim())                       

                  let currentRole = window.localStorage.getItem('current_role')
                  let user = JSON.parse(window.localStorage.getItem('user'))
                  let check_address = [];
                  let uploader_id = dd.created_by; 
                  let addr = [];   
                  let lat = null;
                  let longi = null;                  
                  ref_exit_for_do.map((job) => {
                    if (currentRole == "Super Admin Role") {

                      addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && (uploader_id == obj.customer_id || obj.customer_id == null))
                    } else {
                      addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && obj.customer_id == null)
                    
                    }
                    if(addr.length > 0) {
                      check_address.push(addr[0]);   
                    }
                  });

                  if(check_address.length > 0) {
                    if (dd.customer_id > 0) {
                      let {
                        client,
                        payout,
                        subcon,
                        base_charge,
                        surcharge,
                      } = this.state.grantedAccess.getPriceAndDriverPayout(
                        dd.selected_package,
                        dd.customer_rate,
                        dd.is_project,
                        dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                        dd.drop_off_date,
                        dd.drop_off_postal_code,
                        'Collection',
                        dd.drop_off_address,
                        this.state.address,
                        null,
                        true,
                        dd.drop_off_latitude,
                        dd.drop_off_longitude,
                        dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                        null,
                        false
                      );
                      dd.amount = client;
                      dd.driver_payout = payout;
                      dd.vendor_payout = subcon;
                      dd.base_charge = base_charge;
                      dd.surcharge = surcharge;
                      
                    }   
                    is_deli_use_saved_address = true                   
                  } else {
                    if (dd.customer_id > 0) {
                      let {
                        client,
                        payout,
                        subcon,
                        base_charge,
                        surcharge,
                      } = this.state.grantedAccess.getPriceAndDriverPayout(
                        dd.selected_package,
                        dd.customer_rate,
                        dd.is_project,
                        dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                        dd.drop_off_date,
                        dd.drop_off_postal_code,
                        'Collection',
                        dd.drop_off_address,
                        this.state.address,
                        null,
                        false,
                        dd.drop_off_latitude,
                        dd.drop_off_longitude,
                        dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                        null,
                        false
                      );
                      dd.amount = client;
                      dd.driver_payout = payout;
                      dd.vendor_payout = subcon;
                      dd.base_charge = base_charge;
                      dd.surcharge = surcharge;
                      
                    }                  
                  }                      
                } 
                // console.log("preview_data dd", dd)
                //else {
                //  let currentRole = window.localStorage.getItem('current_role')
                //  let user = JSON.parse(window.localStorage.getItem('user'))
                //  let check_address = [];
                //  let uploader_id = dd.customer_id;      
                //  let collection_index = this.state.preview_data.findIndex((obj) => obj.do_number ==  preview_data[this.state.preview_index].collection_reference);            
                //  if (currentRole == "Super Admin Role") {
                //    check_address = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == dd.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == dd.drop_off_address?.trim()?.toLowerCase())) && (uploader_id == obj.customer_id || obj.customer_id == null))
                //  } else {
                //    check_address = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == dd.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == dd.drop_off_address?.trim()?.toLowerCase())) && (uploader_id == obj.customer_id || obj.customer_id == user?.owner_info?.customers[0]?.id || obj.customer_id == null))
                //  
                //  }
                //  // console.log('this.state.address',this.state.address);
                //  // console.log('collection_index',collection_index);
                //  // console.log('check_address',check_address);
                //  if(check_address.length > 0) {
                //    if (preview_data[collection_index].customer_id > 0) {
                //      let {
                //        client,
                //        payout,
                //        subcon,
                //        base_charge,
                //        surcharge,
                //      } = this.state.grantedAccess.getPriceAndDriverPayout(
                //        preview_data[collection_index].selected_package,
                //        preview_data[collection_index].customer_rate,
                //        preview_data[collection_index].job_type,
                //        preview_data[collection_index].time_window != '' ? preview_data[collection_index].time_window : preview_data[collection_index].period_of_delivery,
                //        preview_data[collection_index].drop_off_date,
                //        preview_data[collection_index].drop_off_postal_code,
                //        'Collection',
                //        preview_data[collection_index].drop_off_address,
                //        this.state.address,
                //        null,
                //        true
                //      );
                //      preview_data[collection_index].amount = client;
                //      preview_data[collection_index].driver_payout = payout;
                //      preview_data[collection_index].vendor_payout = subcon;
                //      preview_data[collection_index].base_charge = base_charge;
                //      preview_data[collection_index].surcharge = surcharge;
                //      
                //    }                          
                //  } else {
                //    if (preview_data[collection_index].customer_id > 0) {
                //      let {
                //        client,
                //        payout,
                //        subcon,
                //        base_charge,
                //        surcharge,
                //      } = this.state.grantedAccess.getPriceAndDriverPayout(
                //        preview_data[collection_index].selected_package,
                //        preview_data[collection_index].customer_rate,
                //        preview_data[collection_index].job_type,
                //        preview_data[collection_index].time_window != '' ? preview_data[collection_index].time_window : preview_data[collection_index].period_of_delivery,
                //        preview_data[collection_index].drop_off_date,
                //        preview_data[collection_index].drop_off_postal_code,
                //        'Collection',
                //        preview_data[collection_index].drop_off_address,
                //        this.state.address
                //      );
                //      // console.log('client',client);
                //      preview_data[collection_index].amount = client;
                //      preview_data[collection_index].driver_payout = payout;
                //      preview_data[collection_index].vendor_payout = subcon;
                //      preview_data[collection_index].base_charge = base_charge;
                //      preview_data[collection_index].surcharge = surcharge;
                //      
                //    }                   
                //  }                   
                //}


                return <Row key={index} className={`mb-5 p-2 w-100 entrance-animation`}>
                    <Col className="col-lg-1 col-md-1 col-12"></Col>
                    <Col className="col-lg-9 col-md-9 col-xs-12 col-12">
                        <div className={`fw-bold d-flex justify-content-between align-items-center ${dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"}`}>
                        <div>{dd.job_type == 'Collection' ? "Pick Up Point" : dd.job_type == 'Delivery' ? "Delivery Point" : "invalid job type"}</div> 
                        <div>
                                <FontAwesomeIcon icon={faPen} className='icon-style-edit' 
                                onClick={(e)=> {
                                    this.setState({
                                        preview_detail:  { ...this.state.preview_data[dd.index_data] },
                                        preview_index: dd.index_data,
                                        openPreviewDialog: true,
                                        is_deli_use_saved_address: is_deli_use_saved_address
                                    }, ()=> {
                                        // // console.log('preview_data data', this.state.preview_detail)
                                    })
                                }}/>
                                <FontAwesomeIcon icon={faTrash} onClick={(e)=> {this.deleteItemByIndex(dd.index_data)}} className='icon-style-trash'/>
                        </div>
                        </div>
                        <Row>
                            <Col className="col-12 col-xs-12 col-md-12 col-lg-4 col-xs-12 d-flex align-items-center justify-content-evenly">
                                <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>Trip Date  </div>
                                <div className={dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"}>{dd.drop_off_date}</div>
                            </Col>
                            <Col className="col-12 col-xs-12 col-md-12 col-lg-4 col-xs-12 d-flex align-items-center justify-content-evenly">
                                <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>Recipient  </div>
                                <div className={dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"}>{dd.drop_off_contact_name}</div>
                            </Col>
                            <Col className="col-12 col-xs-12 col-md-12 col-lg-4 col-xs-12 d-flex align-items-center justify-content-evenly">
                                <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>Packages  </div>
                                <div className={dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"}>
                                    {dd.xs_packages ? dd.xs_packages + " XS": "" }
                                    { dd.s_packages ? (dd.xs_packages ? ", " : '') + dd.s_packages + " S": "" }
                                    { dd.m_packages ? (dd.xs_packages || dd.s_packages ? ", " : '') + dd.m_packages + " M": "" }
                                    { dd.l_packages ? (dd.xs_packages || dd.s_packages || dd.m_packages? ", " : '') + dd.l_packages + " L": "" }
                                    { dd.oversized_packages ? (dd.xs_packages || dd.s_packages || dd.m_packages || dd.l_packages? ", " : '') + dd.oversized_packages + " Oversize": "" } 
                                    { dd.pallets ? (dd.xs_packages || dd.s_packages || dd.m_packages || dd.l_packages || dd.oversized_packages ? ", " : '') + dd.pallets + " Pallets": ""}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-xs-12 col-md-12 col-lg-4 col-xs-12 d-flex align-items-center justify-content-evenly">
                                <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>Timing </div>
                                <div className={dd.is_error || dd.is_timing_error ? "view-error p-0 d-flex justify-content-between align-items-center px-2" : "view-normal p-0 d-flex justify-content-between align-items-center px-2"}>
                                   <div>{dd.drop_off_from_time}
                                    {dd.drop_off_to_time ? " - " + dd.drop_off_to_time : ""}</div> 
                                    <div>
                                    {dd.is_clicked_timing != true ? <span data-tooltip="Click here to change the Timing to 0900 - 1800"><img src={GreenTechInActive} style={{height: '38px' }} 
                                        onClick={(e) => {      
                                        dd.original_from_time = dd.drop_off_from_time
                                        dd.original_to_time = dd.drop_off_to_time
                                        dd.drop_off_from_time =  "9:00"
                                        dd.drop_off_to_time =  "18:00"
                                        dd.time_window = this.getTimeWindow("9:00" , "18:00")
                                        dd.is_timing_error = false
                                        dd.time_window_validate = null
                                        if (dd.job_type == "Collection") {
                                            const ref_exit_for_do = this.state.preview_data.filter(item => item.job_type == "Delivery" && item.collection_reference && item.collection_reference.trim() == dd.do_number.trim() && dd.do_number.trim())                       
                          
                                            let currentRole = window.localStorage.getItem('current_role')
                                            let user = JSON.parse(window.localStorage.getItem('user'))
                                            let check_address = [];
                                            let uploader_id = dd.created_by; 
                                            let addr = [];   
                                            let lat = null;
                                            let longi = null;                  
                                            ref_exit_for_do.map((job) => {
                                              if (currentRole == "Super Admin Role") {
                          
                                                addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && (uploader_id == obj.customer_id || obj.customer_id == null))
                                              } else {
                                                addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && obj.customer_id == null)
                                              
                                              }
                                              if(addr.length > 0) {
                                                check_address.push(addr[0]);   
                                              }
                                            });
                          
                                            if(check_address.length > 0) {
                                              if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Collection',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  true,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false                                                  
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              }   
                                              is_deli_use_saved_address = true                   
                                            } else {
                                              if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Collection',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  false,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              }                  
                                            }                      
                                        } else {
                                            if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Delivery',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  false,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              } 
                                        }

                                        dd.is_clicked_timing = !dd.is_clicked_timing
                                        this.setState({
                                            preview_data: this.state.preview_data
                                        })
                                    }}/></span> :
                                    <span data-tooltip="Click here to change the Timing to 0900 - 1800"><img src={GreenTech} style={{height: '38px' }} 
                                        onClick={(e) => {
                                        dd.drop_off_from_time = dd.original_from_time
                                        dd.drop_off_to_time = dd.original_to_time
                                        dd.is_clicked_timing = !dd.is_clicked_timing
                                        dd.time_window = this.getTimeWindow(dd.original_from_time , dd.original_to_time)
                                        
                                        if (dd.drop_off_from_time !== '' && dd.drop_off_to_time  !== '') {
                                          let start_time = moment(dd.drop_off_from_time,'hh:mm');
                                          let end_time = moment(dd.drop_off_to_time,'hh:mm');
                                          let diff_time = end_time.diff(start_time, 'hours',true);
                                          if (diff_time < 0 || dd.drop_off_from_time == dd.drop_off_to_time || isNaN(diff_time)) {
                                              dd.is_timing_error = true
                                              dd.time_window_validate = "invalid time window"
                                          } 
                                        } else if (dd.drop_off_from_time == '' || dd.drop_off_to_time  == '' || dd.drop_off_from_time == null || dd.drop_off_to_time  == null || dd.drop_off_from_time == undefined || dd.drop_off_to_time  == undefined) {
                                          dd.is_timing_error = true
                                          dd.time_window_validate = "invalid time window"
                                        }

                                        if (dd.job_type == "Collection") {
                                            const ref_exit_for_do = this.state.preview_data.filter(item => item.job_type == "Delivery" && item.collection_reference && item.collection_reference.trim() == dd.do_number.trim() && dd.do_number.trim())                       
                          
                                            let currentRole = window.localStorage.getItem('current_role')
                                            let user = JSON.parse(window.localStorage.getItem('user'))
                                            let check_address = [];
                                            let uploader_id = dd.created_by; 
                                            let addr = [];   
                                            let lat = null;
                                            let longi = null;                  
                                            ref_exit_for_do.map((job) => {
                                              if (currentRole == "Super Admin Role") {
                          
                                                addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && (uploader_id == obj.customer_id || obj.customer_id == null))
                                              } else {
                                                addr = this.state.address.filter(obj => (obj.address?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == job.drop_off_address?.trim()?.toLowerCase()) || job.drop_off_postal_code == obj.postal_code) && obj.customer_id == null)
                                              
                                              }
                                              if(addr.length > 0) {
                                                check_address.push(addr[0]);   
                                              }
                                            });
                          
                                            if(check_address.length > 0) {
                                              if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Collection',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  true,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              }   
                                              is_deli_use_saved_address = true                   
                                            } else {
                                              if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Collection',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  false,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              }                  
                                            }                      
                                        } else {
                                            if (dd.customer_id > 0) {
                                                let {
                                                  client,
                                                  payout,
                                                  subcon,
                                                  base_charge,
                                                  surcharge,
                                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                                  dd.selected_package,
                                                  dd.customer_rate,
                                                  dd.is_project,
                                                  dd.time_window != '' ? dd.time_window : dd.period_of_delivery,
                                                  dd.drop_off_date,
                                                  dd.drop_off_postal_code,
                                                  'Delivery',
                                                  dd.drop_off_address,
                                                  this.state.address,
                                                  null,
                                                  false,
                                                  dd.drop_off_latitude,
                                                  dd.drop_off_longitude,
                                                  dd.package_info ? JSON.parse(dd.package_info).no_of_movers : null,
                                                  null,
                                                  false
                                                );
                                                dd.amount = client;
                                                dd.driver_payout = payout;
                                                dd.vendor_payout = subcon;
                                                dd.base_charge = base_charge;
                                                dd.surcharge = surcharge;
                                                
                                              } 
                                        }
                                        
                                        this.setState({
                                            preview_data: this.state.preview_data
                                        })
                                    }}/></span>}
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12 col-xs-12 col-md-12 col-lg-8 col-xs-12 d-flex align-items-center justify-content-evenly">
                                <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>D.O No. </div>
                                <div className={dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"} style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{dd.do_number && dd.do_number.replace(/\s/g, '\u00A0')}</div>
                            </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-evenly">
                            <div className="pe-2" style={{minWidth: '100px', marginTop: '10px'}}>Address </div>
                            <div className={dd.is_error || dd.is_timing_error ? "view-error" : "view-normal"}>{dd.drop_off_address}</div>
                        </div>
                    </Col>
                    <Col className={`col-lg-2 col-md-2 col-xs-12 col-12 ${dd.is_error || dd.is_timing_error ? ' view-border-error ': ' view-border ' } d-flex flex-column align-items-center justify-content-between pt-2`}>
                        <div>Price</div>
                        <div>{dd.amount ? "S$ " + parseFloat(dd.amount).toFixed(2): ''}</div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <img src={GreenTech} style={{height: '38px' }} />
                            <div className='text-center'> Save <span className='fw-bold'>$3.20</span> by changing your timing!</div>
                        </div>
                    </Col>
                    <Col className="col-lg-1 col-md-1 col-12"></Col>
                    <Col className="col-lg-11 col-md-11 col-xs-12  col-12">
                        {(dd.is_error || dd.is_timing_error) && <div>
                            {dd.delivery_order_validate != null && dd.delivery_order_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red' /> {dd.delivery_order_validate}</div> : ''}
                            {dd.pick_up_order_validate != null && dd.pick_up_order_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red' /> {dd.pick_up_order_validate}</div> : ''}
                            {(dd.do_validate != null && dd.do_validate != '' && this.state.collection_data == false) ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red' /> {dd.do_validate}</div> : ''}
                            {dd.period_of_delivery_validate != null && dd.period_of_delivery_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.period_of_delivery_validate}</div> : ''}
                            {dd.job_type_validate != null && dd.job_type_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.job_type_validate}</div> : ''}
                            {dd.drop_off_date_validate != null && dd.drop_off_date_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.drop_off_date_validate}</div> : ''}
                            {dd.drop_off_contact_no_validate != null && dd.drop_off_contact_no_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.drop_off_contact_no_validate}</div> : ''}
                            {dd.drop_off_alternate_contact_validate != null && dd.drop_off_alternate_contact_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.drop_off_alternate_contact_validate}</div> : ''}
                            {dd.postal_code_validate != null && dd.postal_code_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.postal_code_validate}</div> : ''}
                            {dd.email_validate != null && dd.email_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.email_validate}</div> : ''}
                            {dd.drop_off_address_validate != null && dd.drop_off_address_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.drop_off_address_validate}</div> : ''}
                            {dd.time_window_validate != null && dd.time_window_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.time_window_validate}</div> : ''}
                            {dd.package_validate != null && dd.package_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.package_validate}</div> : ''}
                            {dd.collection_reference_validate != null && dd.collection_reference_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.collection_reference_validate}</div> : ''}
                            {dd.delivery_need_validate != null && dd.delivery_need_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.delivery_need_validate}</div> : ''}
                            {dd.department_validate != null && dd.department_validate != '' ? <div className="text-danger"><FontAwesomeIcon icon={faDotCircle} color='red'/> {dd.department_validate}</div> : ''}
                        </div>
                    
                        }
                    </Col>
                </Row>
            })}
            </Card>
            ))}
             
            </Container> :  <Container>
            <Row>
                <div className="py-4 d-flex justify-content-between align-items-center">
                    <div className='fw-bold'>Order Preview</div>
                    <div onClick={()=> {
                        if(this.props.onClose){
                            this.props.onClose();
                        }
                    }}><FontAwesomeIcon icon={faXmark} className='close-btn-circle'/></div>
                </div>
                <Col className="text-danger text-center p-4">
                    Job is empty!
                </Col>
            </Row>
        </Container>
      }
  }

  setButtons = () => {
    const check_err = this.state.preview_data.filter(dd => dd.is_error == true || dd.is_timing_error == true)
    return this.state.preview_data.length > 0 && <Container>
            <div className="d-flex justify-content-center align-items-center mb-4">
                <CustomButton 
                    className="text-dark-cancel pe-2"
                    onClick={() => {
                        if(this.props.onClose){
                            this.props.onClose();
                        }
                    }}
                >
                    Cancel
                </CustomButton>
                <CustomButton 
                    type={'submit'}
                    color={'primary'}
                    disabled={check_err.length > 0 || this.state.isLoading? true : false}
                    isLoading={this.state.isLoading ? true : false}
                >
                    Confirm
                </CustomButton>
            </div>
        </Container>
  }
  
  setPreview = () => {
        return <React.Fragment>
            {this.previewOrders()}
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </React.Fragment>
      
  }

  /* ===== ===== SUBMIT ===== =====*/
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if(this.props.onImport){
        this.setState({
            isLoading : true,
        },()=> {
            this.props.onImport(this.state.preview_data);
        })
    }
  }
  /*===== ===== END SUBMIT ===== =====*/


  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit} ref={f => (this.refForm = f)}>
            <Grid container>
                {this.setPreview()}
            </Grid>
        </form>
        {this.previewDialog()}
    </Box>
  }
}

export default JobImportDialog;