import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Hidden,
  CircularProgress
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';
import ldsApi from '../../../api/ldsApi.jsx';
import apiUtil from '../../../api/apiUtil.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import OrderDiscountForm from './OrderDiscountForm.jsx';


export class OrderDiscountList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      isLoading: false,
      openDialogEdit: false,
      itemDialogEdit: null,

      openDialogDelete: false,
      itemDialogDelete: null,
      
      items: items,
      preview: preview,
    }
  }
  
  componentDidMount () {
    this.callReadApi()
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.items !== this.state.items) {
  //       let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
  //       this.setState({
  //           items: items
  //       });
  //   }
  // }

  callReadApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: this.pages,
        take: this.limits,
        sort: "latest"
      };
      
      ldsApi.read('order_discounts', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              items: (data.result && data.result.length > 0) ? data.result : [],
              isLoading: false,
            });

          }, (error, type) => {
            this.setState({
              items: [],
              isLoading: false,
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('order_discounts', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('order_discounts/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      ldsApi.delete('order_discounts/' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }



  /* DIALOG */
  editDialog = () => {
      return <CustomFormDialog 
          open={this.state.openDialogEdit}
          title={((this.state.itemDialogEdit !== null) ? 'Edit Order Discount' : 'Add Discount')}
          onClose={() => {
            this.setState({
                openDialogEdit: false,
                itemDialogEdit: null,
            });
          }}
      >
          <OrderDiscountForm
            item={this.state.itemDialogEdit}
            onSave={(isEdit, form) => {
                let item = this.state.itemDialogEdit;

                if(isEdit) {
                    this.callUpdateApi(item.id, form, () => {
                        this.callReadApi()
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                } else {
                    this.callCreateApi(form, (data) => {
                        this.callReadApi()
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                }
                
            }}
            onClose={() => {
                this.setState({
                    openDialogEdit: false,
                    itemDialogEdit: null,
                });
            }}
          />
      </CustomFormDialog>
  }

  deleteDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDelete}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDelete: false,
                itemDialogDelete: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDelete;
              this.callDeleteApi(item, () => {
                this.callReadApi()
                this.setState({
                    openDialogDelete: false,
                    itemDialogDelete: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }
  /* END DIALOG */


  setHeader = () => {

    return <Box pt={1} pb={1} className={'custom-grid-header'}>
        <Grid container alignItems={'center'}>
            <Box clone pl={{ xs: 0, md: 2}}>
                <Grid item xs={4} md={!this.state.preview ? 4 : true}>
                    <CustomLabel
                        label={'Discount Name'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={2} md={2}>
                    <CustomLabel
                        label={'Order Type'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={2} md={2}>
                    <CustomLabel
                        label={'Order Count'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={2} md={2}>
                    <CustomLabel
                        label={'Discount Value'}
                    />
                </Grid>
            </Box>
            {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}} textAlign={'right'}>
                <Grid item xs={2} md={2}>
                    <CustomLabel
                        label={'Actions'}
                    />
                </Grid>
            </Box>}
        </Grid>
    </Box>
  }


  render() {
    return <Box pt={!this.state.preview ? 5 : 0} pb={5}>
        <Grid container>
            {!this.state.preview && <Box clone pb={1} style={{width: "100%"}}>
                <Grid item xs={12} style={{minWidth: "450px", overflow: "auto"}}>
                    <CustomLabel
                        label={'Order Discount List'}
                        weight={'bold'}
                        size={'md'}
                        labelComponent={<CustomTooltip title={'discount List for this company.'} />}
                    />
                    <Divider light />
                </Grid>
            </Box>}
            <Grid item xs={12}>
                <Hidden xsDown implementation="css">
                    {this.setHeader()}
                </Hidden>
              {this.state.isLoading ? <Box pt={1}>
                        <CircularProgress size={20} />
                    </Box> :
                <Box>
                    
                    {
                        (this.state.items && this.state.items.length > 0 )
                        ?
                        this.state.items.map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={'center'}>
                                    <Box clone pl={{ xs: 0, md: 2}}>
                                        <Grid item xs={4} md={!this.state.preview ? 4 : true}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Discount Name'} placement={'top'}>
                                                        <Box>{item.discount_name}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={2} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Order Type'} placement={'top'}>
                                                        <Box>{item.discount_type}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={2} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Order Count'} placement={'top'}>
                                                        <Box>{item.eligible_order_count}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={2} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Discount Value'} placement={'top'}>
                                                        <Box>{item.discount_value}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}}>
                                        <Grid item xs={2} md={2}>
                                            <Grid container justify={'flex-end'}>
                                            {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Update Application Setting')) && <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogEdit: true,
                                                                    itemDialogEdit: item,
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>}
                                              {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Update Application Setting')) && <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogDelete: true,
                                                                    itemDialogDelete: item,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>}
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                </Grid>
                                <Divider light />
                            </Box>
                        })
                        :
                        <Grid container>
                            <Box clone pt={3} textAlign={'center'}>
                                <Grid item xs={12}>
                                    <CustomLabel
                                        label={'No Results!'}
                                        weight={'bold'}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    } 
                

                    {!this.state.preview && <Grid container>
                        <Box clone>
                           <Grid item xs={12}>
                                {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Create Application Setting')) && <CustomButton 
                                    color={'secondary'}
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                            openDialogEdit: true,
                                            itemDialogEdit: null,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} component={'i'}/>
                                    {/* <Icon component={'i'}>add</Icon> */}
                                    <Box display={'inline-block'} pl={1}>Add New Discount</Box>
                                </CustomButton> }
                            </Grid>
                        </Box>
                    </Grid>}
                </Box>
                }
            </Grid>
        </Grid>
        {this.editDialog()}
        {this.deleteDialog()}
    </Box>
  }
}

export default OrderDiscountList;