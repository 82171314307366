import React, { Component } from 'react';

import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";

import { 
  Box,
  Chip,
} from '@material-ui/core';
import {RoleAccessService} from '../../../data/role-access'; 
import Table from '../../../components/Table/SettingTable.jsx';


export class HistoryList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let data = (props.items && props.items.length > 0) ? props.items : [];

    let driverName = (props.driverName != "") ? props.driverName : "null__"; //driverName
    let vendorId = (props.vendorId != "") ? props.vendorId : null; //vendorName
    this.state = {
        grantedAccess : new RoleAccessService(),
        currentRole: window.localStorage.getItem('current_role') || null,
        user: JSON.parse(localStorage.getItem('user')),
        preview: preview,
        driverName: driverName,
        vendorId: vendorId,
        isLoading: false,
        grouping: false,
        paging: false,
        limit: apiUtil.getDefaultPageSize(),
        data: data,
        total: 0,
        page: 1,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : []; 
        let driverName = (nextProps.driverName != "") ? nextProps.driverName : "null__"; //driverName
        let vendorId = (nextProps.vendorId != "") ? nextProps.vendorId : null; //vendorName
   
        this.setState({
            data: items,
            driverName: driverName,
            vendorId : vendorId
        });
    }
  }


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      className={'job-table-list'}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      paging={this.state.paging}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          // this.callReadApi();
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
        // { 
        //   title: "Details", 
        //   field: "details",
        // },
        { 
          title: "Status", 
          field: "current_status.status",
          render: (row) => {
            let status = '';
            (this.state.currentRole == 'Customer Template Role')?
            (status = (row && row.current_status && row.current_status.status) ? row.current_status.status == "Not Assigned" ? "Pending"+'   ': row.current_status.status == "Assign Pending" ? "On Hold"+'   ' : row.current_status.status == "Acknowledged" ? "In Progress"+'   ' : row.current_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : row.current_status.status+'   ' : '') :
            (status = (row && row.current_status && row.current_status.status) ? row.current_status.status == "Not Assigned" ? "Pending"+'   ': row.current_status.status == "Assign Pending" ? "On Hold"+'   ' : row.current_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : row.current_status.status+'   ' : '')

              // let status = (row && row.current_status && row.current_status.status && row.current_status.status !== '') ? row.current_status.status : '';
              return <Box textAlign={'center'}>
                  {(status !== '') && <Chip className={'status-label-history ' + apiUtil.statusToClassName(status)} label={status} />}
              </Box>;
          }
      },
      { 
        title: "Date/Time", 
        field: "date",
        render: (row) => {
          let date = (row && row.updated_at && row.updated_at !== '') ? moment.parseZone(row.updated_at).format('DD-MM-YYYY h:mm A') : '';
          return date;
        }
    },
        { 
          title: "Driver", 
          field: "worker.first_name",
          render: (row) => {
              if(row.current_status != null){ 
                if(row.current_status.status.toString().toLowerCase().trim().replace(' ', '-') =="not-assigned" ){ 
                  return "";
                }else{
                  if (row.vendor_id == this.state.user.owner_info.application_company_id) {
                    return (( row && row.worker && row.worker.first_name)? row.worker.first_name : '') + ' ' + (( row && row.worker && row.worker.last_name)? row.worker.last_name : '');
                  } else {
                    return (row && row.vendor && row.vendor.company_name ? row.vendor.company_name : (( row && row.worker && row.worker.first_name)? row.worker.first_name : '') + ' ' + (( row && row.worker && row.worker.last_name)? row.worker.last_name : ''));
                  }
                }
               }else{
                 return "";
               }
          }
      },
      { 
        title: "Edited By", 
        field: "edited_by",
        render: (row) => {
          if (row.admin !== null) {
            if (this.state.vendorId) {
              if (this.state.vendorId == this.state.user.owner_info.application_company_id) {
                return row.worker_id == row.admin_id ? `${row.worker.first_name ? row.worker.first_name : '' } ${row.worker.last_name ? row.worker.last_name : '' }` :
                (row.admin.application_company_id == row.application_company_id ? row.admin.application_company.company_name : `${row.admin.first_name ? row.admin.first_name : '' } ${row.admin.last_name ? row.admin.last_name : '' }`)
              } else {
                return row.worker_id == row.admin_id ? row.vendor && row.vendor.company_name :
                (row.admin.application_company_id != row.application_company_id ? row.admin.application_company.company_name : `${row.admin.first_name ? row.admin.first_name : '' } ${row.admin.last_name ? row.admin.last_name : '' }`)
              }
            } else {
              return row.worker_id == row.admin_id ? `${row.worker && row.worker.first_name ? row.worker.first_name : '' } ${row.worker && row.worker.last_name ? row.worker.last_name : '' }` :
              row.worker_id != row.admin_id ? `${row.admin && row.admin.first_name ? row.admin.first_name : '' } ${row.admin && row.admin.last_name ? row.admin.last_name : '' }` : ""
            }
          }
            // return this.state.vendorId?
            // (this.state.vendorId == this.state.user.owner_info.application_company_id ?
            // (row.worker_id == row.admin_id ? `${row.worker.first_name ? row.worker.first_name : '' } ${row.worker.last_name ? row.worker.last_name : '' }` :
            // (row.admin.application_company_id == row.application_company_id ? row.admin.application_company.company_name : `${row.admin.first_name ? row.admin.first_name : '' } ${row.admin.last_name ? row.admin.last_name : '' }`)) :
            // row.worker_id != row.admin_id ? `${row.admin.first_name ? row.admin.first_name : '' } ${row.admin.last_name ? row.admin.last_name : '' }` : '' )
            // : 
            // return this.state.vendorId? this.state.vendorId == this.state.user.owner_info.application_company_id && row.worker_id  : this.state.vendorId == this.state.user.owner_info.application_company_id && row.worker_id != row.admin_id? `${row.admin.first_name ? row.admin.first_name : '' }  ${row.admin.last_name ? row.admin.last_name : '' } ` : this.state.vendorId != this.state.user.owner_info.application_company_id ? row.admin.application_company.company_name : '' :  row.admin ? `${row.admin.first_name ? row.admin.first_name : '' }  ${row.admin.last_name ? row.admin.last_name : '' } ` : ""
            // return this.state.vendorId == this.state.user.owner_info.application_company_id ? row.admin.application_company.company_name : row.worker_id == row.admin_id && row.worker ? `${row.worker.first_name ? row.worker.first_name : '' } ${row.worker.last_name ? row.worker.last_name : '' }` : row.admin ? `${row.admin.first_name ? row.admin.first_name : '' }  ${row.admin.last_name ? row.admin.last_name : '' } `: "";
        }
    },
  //   { 
  //     title: "Proof", 
  //     field: "proof",
  //     render: (row) => {
  //         let date = (row && row.updated_at && row.updated_at !== '') ? moment.utc(row.updated_at).local().format('MMMM D, YYYY HH:mm A') : '';
  //         return date;
  //     }
  // },
  //        { 
  //         title: "Map", 
  //         field: "map",
  //       },
        // { 
        //     title: "Last Status", 
        //     field: "order_status.status",
        //     render: (row) => {
        //         let status = (row && row.order_status && row.order_status.status && row.order_status.status !== '') ? row.order_status.status : '';
        //         return <Box textAlign={'center'}>
        //             {(status !== '') && <Chip className={'status-label ' + apiUtil.statusToClassName(status)} label={status} />}
        //         </Box>;
        //     }
        // },
        // { 
        //     title: "Current Status", 
        //     field: "current_status.status",
        //     render: (row) => {
        //         let status = (row && row.current_status && row.current_status.status && row.current_status.status !== '') ? row.current_status.status : '';
        //         return <Box textAlign={'center'}>
        //             {(status !== '') && <Chip className={'status-label ' + apiUtil.statusToClassName(status)} label={status} />}
        //         </Box>;
        //     }
        // },
           
      ]}
    />
  }
  /* END TABLE */


  render() {
    return <Box pt={3} pb={5}>
        {this.customTable()}
    </Box>
  }
}

export default HistoryList;