import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";

import { 
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as InformationIcon } from '../../assets/img/icons/information.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomAlert from '../../components/Alert/CustomAlert.jsx';


export class VendorForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;
    let is_temp = (this.props.location && this.props.location.state && this.props.location.state.is_temp) ? this.props.location.state.is_temp : 'no';

    this.state = {
      id: id,

      isLoading: false,

      company_name: '',
      company_nameValidated: false,
      name: '',
      nameValidated: false,
      email: '',
      is_temp:is_temp,
      emailValidated: false,
    }
  }
  

  componentDidMount() {
    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data) => {
        this.setState({
          company_name: (data && data.company_name) ? data.company_name : '',
          name: (data && data.name) ? data.name : '',
          email: (data && data.email) ? data.email : '',
        });
      });
    }
  }

  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.read('vendors/show/' + id + '/' + this.state.is_temp, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.create('create_vendor', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'check_circle', 'error');
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      row['is_temp'] =this.state.is_temp;
      row['id'] = id;
      row['is_temp_id'] = id;
      
      api.create('vendor/update/' + id + '/' + this.state.is_temp, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        this.props.history.push('/vendor');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={(this.state.id > 0) ? 'Edit Vendor' : 'Add New Vendor'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* FORM */
  customForm = () => {
    return <Box>
        <Grid container>
            <Grid item xs={12}>
                <CustomLabel
                    label={(this.state.id > 0) ? 'Edit Vendor' : 'Add New Vendor'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                <Container maxWidth={'xs'}>
                    <Box clone p={5}>
                        <Grid container>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Company Name'}
                                        placeholder={'Enter company name'}
                                        value={this.state.company_name}
                                        error={this.state.company_nameValidated}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                              company_name: e.target.value,
                                              company_nameValidated: (e.target.value !== '') ? false : true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Person In Charge'}
                                        placeholder={'Name of person in charge of company'}
                                        value={this.state.name}
                                        error={this.state.nameValidated}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                              name: e.target.value,
                                              nameValidated: (e.target.value !== '') ? false : true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                            
                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={'Email Address'}
                                        placeholder={'Enter company email address'}
                                        type={'email'}
                                        value={this.state.email}
                                        error={this.state.emailValidated}
                                        disabled={this.state.id > 0 ? true : false}
                                        required={true}
                                        onChange={(e) => {
                                            this.setState({
                                              email: e.target.value,
                                              emailValidated: (e.target.value !== '') ? false : true,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                            
                            <Box clone>
                                <Grid item xs={12}>
                                    <CustomAlert
                                        icon={<InformationIcon />}
                                    >
                                        Email confirmation will be sent to this email to accept a vendor
                                    </CustomAlert>
                                </Grid>
                            </Box>

                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      company_nameValidated: false,
      nameValidated: false,
      emailValidated: false,
    });
    

    if(this.state.company_name === ''){
      this.setState({
        company_nameValidated: true
      });
    }
    if(this.state.name === ''){
      this.setState({
        nameValidated: true
      });
    }
    if(this.state.email === ''){
      this.setState({
        emailValidated: true
      });
    }
    
    let validatedEamil = true
    if(this.state.email !== '' && !apiUtil.emailValidation(this.state.email)){
      validatedEamil = false
      this.setState({
          emailValidated: true
      });
    }

    if (form.checkValidity() === true && validatedEamil) {
      let data = {
        company_name: this.state.company_name,
        name: this.state.name,
        email: this.state.email,
      };

      if(this.state.id && this.state.id > 0){
        this.callUpdateApi(this.state.id, data, () => {
          this.setState({
            isLoading: false
          }, () => {
            this.props.history.push('/vendor');
            apiUtil.toast('Successfully Updated', 'check_circle');
          });
        });
      } else {
        this.callCreateApi(data, (dd) => {
          this.setState({
            isLoading: false
          }, () => {
            if(dd) {
              this.props.history.push('/vendor');
              apiUtil.toast(dd.result, 'check_circle');
            }
          });
        });
      }
    } else {
      this.setState({
        isLoading: false
      });
    }
  }
  /* END SUBMIT */


  render() {
    return <Box className="vendor-form-page" >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <StickyPageHeader isSmall={true}>
                {this.customStickyPageHeader()}
            </StickyPageHeader>
            
            <Container maxWidth="lg">
                {
                  (!this.state.isLoading)
                  ?
                  <Card className="custom-card-margin-x">
                    {this.customForm()}
                  </Card>
                  :
                  <Card className="custom-card-margin-x">
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
            </Container>
            
        </form>
    </Box>
  }
}
