import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { faCheck, faXmark, faMagnifyingGlass, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import OldTable from '../../components/Table/OldTable.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import TextField from '@material-ui/core/TextField';
import CustomSwitch from '../../components/CustomFields/CustomSwitch.jsx';
import { RedoTwoTone } from '@material-ui/icons';
import {RoleAccessService} from '../../data/role-access'; 

export class Subcon extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      vendorTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      isLoadingStatus: false,
      total_in: 0,
      total_out: 0,
      searchVal: '',

      isLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,

      openDialog: false,
      openDialogItem: null,
    }
  }
  

  componentDidMount() {
    this.callReadApi();
  }


  /* API */
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: search ? 1 : this.state.page,
        take: this.state.limit,
        search: search ? search.trim() : search,
        sorting_array: ["id desc"],
      };

      api.read('vendors/list', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(this.state.vendorTab === 1){
              this.setState({
                data: (data.result.out && data.result.out.length > 0) ? data.result.out : [],
                total: data.total_out,
                total_in: data.total_in,
                total_out: data.total_out,
                isLoading: false,
              });
            } else {
              this.setState({
                data: (data.result.in && data.result.in.length > 0) ? data.result.in : [],
                total: data.total_in,
                total_in: data.total_in,
                total_out: data.total_out,
                isLoading: false,
              });
            }
            if(search != '') {
              this.refTableSearch.current.state.value = search;
            }
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              total_in: 0,
              total_out: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };
      api.delete('vendors/' + row.id + '/'+ row.is_temp, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */

  callUpdateApi = (id, is_temp, status, email, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
     

      let data = {
        is_temp : is_temp,
        status: status,
        id: id,
        email: email
      }
      
      api.create('vendor/update/' + id + '/' + is_temp, data, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            apiUtil.toast("Successfully Updated!", 'check_circle');
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        {/* <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click");
                }}
              >
                <ImportIcon />
              </CustomButton>
          </Grid>
        </Box> */}
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/vendor-form');
              }}
            >
              + New Vendor
            </CustomButton>
          </Grid>
        </Box>
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.vendorTab}
              buttons={[ "Companies Vendor", "Our Vendors" ]}
              onClick={(selected, btn) => {
                this.setState({
                  vendorTab: selected
                }, () => {
                  this.callReadApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <div className="row border-0 m-0">
      <div className="col-xs-12 col-md-7 d-flex justify-content-start align-items-center mb-2">
      <div className="row d-flex max-xs-width-control justify-content-start align-items-center mb-2">
          <div className="col-xs-12 col-md-6 my-2">
      <CustomInput
      className="input-custom-style"
       ref={this.refTableSearch}
        placeholder={'Search Vendor'}
        onPressEnter={(e) => {
          this.callReadApi(e.target.value)
          this.setState({
            searchVal: e.target.value 
          });
        }}
        onChange={(e) => {
          this.refTableSearch.current.state.value = e.target.value
          this.setState({
            searchVal: e.target.value 
          });
        }}
        value={this.state.searchVal}
      />
      </div>
      <div className="col-xs-12 col-md-6 my-2 d-flex justify-content-center align-items-center">
      <Button className="upload-style w-100 max-height-btn" onClick={(e) => {
          this.callReadApi(this.refTableSearch.current.state.value);  }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Search
      </Button>
      </div>
      </div>
      </div>
      <div className="col-xs-12 col-md-5 d-flex justify-content-end align-items-center mb-2">
        {/* <Button className="justify-self-end d-flex ms-auto upload-style-border cd-shadow">Import Customer</Button> */}
        { (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor Add')) || (this.state.currentRole == 'Ops Admin') ? <Button className="justify-self-end upload-style" 
         onClick={()=>{
          this.props.history.push({
            pathname: '/resources/vendor-form',
            state: {
              id: null,
            }
          });
        }}
        ><FontAwesomeIcon icon={faPlus}/> New Vendor</Button> : ''}
      </div>
    </div>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    return <OldTable 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      // pageSize= {this.state.pageSize}
      pageSizeOptions ={this.state.pageSizeOptions}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      defaultExpanded={true}
      onPageChange={(page,size) => {
        
        this.state.limit = size;
        this.setState({
          page: page,
        }, () => { 
          this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      // Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Vendor Name", 
          field: "company_name",
          cellStyle: {
            minWidth: 150,          
          },
          render: (row, type) => {
            if(type === 'row'){
              return <span  className={ this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor Details') || this.state.currentRole == "Ops Admin" ? "linkable-place-2" : ''}
              style={{textDecoration: "none" }}
              
              onClick={()=>{
                if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor Details')  || this.state.currentRole == "Ops Admin") {
                this.props.history.push({
                  pathname: '/resources/vendor-form',
                  state: {
                    id: row.id,
                    is_temp: row.is_temp
                  }
                });
                }
              }}
              >{row.company_name? row.company_name.replace(/\s/g, '\u00A0') : ''}</span>
            } else {
              return row;
            }
          }
        },
        { 
          title: "Person In Charge", 
          field: "name",
          cellStyle: {
            minWidth: 150,          
          },
        },
        { 
          title: "Contact Number", 
          field: "phone_number",
          cellStyle: {
            minWidth: 150,          
          },
          customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).phone_number && !isNaN(JSON.parse(a.extra_data).phone_number) ? JSON.parse(a.extra_data).phone_number : '') - Number(b.extra_data && JSON.parse(b.extra_data).phone_number && !isNaN(JSON.parse(b.extra_data).phone_number) ? JSON.parse(b.extra_data).phone_number : ''),
          render: (row, type) => {
            if( type == 'row') {
              let extra_data = row.extra_data ? JSON.parse(row.extra_data) : ''
            
              return extra_data && extra_data['phone_number'] ? extra_data['phone_number'] : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Email Address", 
          field: "email",
          cellStyle: {
            minWidth: 150,          
          },
        },
        { 
          title: "Assigned Zones", 
          field: "assigned_zone",
          cellStyle: {
            minWidth: 150,          
          },
          render: (row, type) => {
            if( type == 'row') {
            
              return row.assigned_zone ? row.assigned_zone  : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Postal Codes of Interest", 
          field: "desired_postal_code",
          cellStyle: {
            minWidth: 200,          
          },
        },
        { 
          title: "Postal Codes to Avoid",
          field: "avoid_postal_code",
          cellStyle: {
            minWidth: 200,          
          },
        },
        { 
          title: "Payment Method", 
          field: "payment_method",
          cellStyle: {
            minWidth: 150,          
          },
          customSort: (a,b) => (a.extra_data && JSON.parse(a.extra_data).payment_method ? JSON.parse(a.extra_data).payment_method : '').toString().toLowerCase().trim().localeCompare((b.extra_data && JSON.parse(b.extra_data).payment_method ? JSON.parse(b.extra_data).payment_method : '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if( type == 'row') {
              let extra_data = row.extra_data ? JSON.parse(row.extra_data) : ''
            
              return extra_data && extra_data['payment_method'] ? extra_data['payment_method'] : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Name of Bank", 
          field: "name_of_bank",
          cellStyle: {
            minWidth: 150,          
          },
          customSort: (a,b) => (a.extra_data && JSON.parse(a.extra_data).name_of_bank ? JSON.parse(a.extra_data).name_of_bank : '').toString().toLowerCase().trim().localeCompare((b.extra_data && JSON.parse(b.extra_data).name_of_bank ? JSON.parse(b.extra_data).name_of_bank : '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if( type == 'row') {
              let extra_data = row.extra_data ? JSON.parse(row.extra_data) : ''
            
              return extra_data && extra_data['name_of_bank'] ? extra_data['name_of_bank'] : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Payment Method Number", 
          field: "account_number",
          cellStyle: {
            minWidth: 200,          
          },
          customSort: (a,b) => (a.extra_data && JSON.parse(a.extra_data).account_number ? JSON.parse(a.extra_data).account_number : '').toString().toLowerCase().trim().localeCompare((b.extra_data && JSON.parse(b.extra_data).account_number ? JSON.parse(b.extra_data).account_number : '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if( type == 'row') {
              let extra_data = row.extra_data ? JSON.parse(row.extra_data) : ''
            
              return extra_data && extra_data['account_number'] ? extra_data['account_number'] : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Payment Frequency", 
          field: "payout_frequency",
          cellStyle: {
            minWidth: 200,          
          },
          customSort: (a,b) => (a.extra_data && JSON.parse(a.extra_data).payout_frequency ? JSON.parse(a.extra_data).payout_frequency : '').toString().toLowerCase().trim().localeCompare((b.extra_data && JSON.parse(b.extra_data).payout_frequency ? JSON.parse(b.extra_data).payout_frequency : '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if( type == 'row') {
            
              let extra_data = row.extra_data ? JSON.parse(row.extra_data) : ''
            
              return extra_data && extra_data['payout_frequency'] ? extra_data['payout_frequency'] == '1' ? 'Weekly' : extra_data['payout_frequency'] == '2' ? 'Every 2 Weeks' :  'Monthly'   : ''
            } else {
              return row
            }

          }
        },
        { 
          title: "Priority", 
          field: "email",
          cellStyle: {
            minWidth: 150,          
          },
          render: (row, type) => {
            let value = row.priority !== null ? row.priority : 0;
            if(type === 'row'){
              return <TextField
                    id="outlined-number"
                    type="number"
                    disabled={this.state.currentRole == 'Finance Admin' && true}
                    defaultValue={value}
                    InputProps={{
                      inputProps: { min: 0, max: 5}
                    }}
                    onChange={()=> console.log("mouse up")}
                    variant="outlined"
                    onPointerLeave={()=> console.log("leave up")}
                    onLostPointerCapture={()=> console.log("capture")}
                  />
              
            } else {
              return row;
            }
          }
        },
        { 
          title: "Fleet Strength", 
          field: "fleet_strength",
          cellStyle: {
            minWidth: 150,          
          },
        },
        { 
          title: "Status", 
          field: "status",
          render: (row, type) => {
            return <Box>
            <Grid container justify={'center'}>
              <Box clone>
                <Grid item xs={'auto'}>
                <CustomSwitch 
                    checked={row.status == 'pending' ? false : true}
                    disabled={this.state.currentRole == "Super Admin Role" || this.state.currentRole == "Ops Admin" ? false : true}
                    onChange={(e)=> {
                      let change_status = row.status == 'pending' ? 'active' : 'pending'
                      this.callUpdateApi(row.id, row.is_temp, change_status, row.email, ()=> {
                        this.callReadApi(this.refTableSearch.current.state.value)
                      })
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
          }
        },

        // { 
        //   title: "ACTION", 
        //   width: '200px',
        //   align: 'center',
        //   sorting: false,
        //   render: (row) => {
        //     return <Box>
        //       <Grid container justify={'center'}>
        //         {row.status != 'active' ?
        //         <Box clone>
        //           <Grid item xs={'auto'}>
        //             <IconButton
        //               onClick={() => {
        //                 this.props.history.push({
        //                   pathname: '/vendor-form',
        //                   state: {
        //                     id: row.id,
        //                     is_temp: row.is_temp
        //                   }
        //                 });
        //               }}
        //             >
        //               <EditIcon />
        //             </IconButton>
        //           </Grid>
        //         </Box> : '' }
        //         <Box clone>
        //           <Grid item xs={'auto'}>
        //             <IconButton
        //               onClick={() => {
        //                 this.setState({
        //                   openDialog: true,
        //                   openDialogItem: row,
        //                 });
        //               }}
        //             >
        //               <DeleteIcon />
        //             </IconButton>
        //           </Grid>
        //         </Box>
        //       </Grid>
        //     </Box>
        //   }
        // },
      ]}
    />
  }

  customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={true}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Vendor'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 0, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            
          </Grid>
        </Box>
        {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
          </Grid>
        </Box> */}
      </Grid>
    </Box>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="vendor-page" >
      {/* <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader> */}
      
      <Card className="cd-shadow custom-card-margin-x" >
        {this.customStatus()}
      </Card> 

      {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor List')) && <Card className="custom-card-margin-x">
        {this.customTable()}
      </Card>}
      
      {this.customDialog()}
    </Box>;
  }
}

