import React,{createRef} from "react";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faXmark } from "@fortawesome/free-solid-svg-icons";
/** Notification released*/
class NotiBox extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      toggleNotification: false,
      listItems: []
    };
  }
  componentDidMount() {
    this.setState({ listItems: this.props.listItems });
  }
  componentDidUpdate(previousProps) {
    if (previousProps.listItems !== this.props.listItems) {
      this.setState({ listItems: this.props.listItems });
    }
  }
  toggleNotification = () => {
    const { toggleNotification } = this.state;
    this.setState({ toggleNotification: !toggleNotification });
  };
  clearAllMessage = () => {
    this.props.onClearAll && this.props.onClearAll();
  };
  
  onScroll = () => {
    if (this.containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.containerRef.current;
      if (Math.ceil(scrollTop) + parseInt(clientHeight) === scrollHeight && this.props.notiLoading == false) {
        if(this.props.fetchNoti) {
          this.props.fetchNoti();  
        }
      }
    }
  }

  generateDate = timeStamp => {
    const d = new Date(timeStamp * 1000);
    const n = d.getDate();
    const m = d.getMonth();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC"
    ];
    return { date: `${n} ${monthNames[m]}`, time: timeStamp };
  };

  render() {
    const { listItems } = this.state;
    let totalCount = 0;
    const allTimestamp = [];
    listItems.map((i, k) => {
      const test = allTimestamp.filter(
        item => item.UTC === i.UTC
      );
      if (test.length === 0) {
        const itemObj = {
          UTC: i.UTC,
          list: []
        };
        allTimestamp.push(itemObj);
      }
      totalCount = totalCount + i.list.length;
    });
    listItems.map((i, j) => {
      const iUTC = i.UTC;
      const sameData = allTimestamp.filter(function(k) {
        return k.UTC === iUTC;
      });
      const key = sameData.length && sameData[0].UTC;
      allTimestamp.map(item => {
        if (item.UTC === key) {
          i.list.map(p => {
            p.timeStamp = i.UTC;
          });
          item.list.push(i.list);
        }
      });
    });

  
    return (
      <div className={"notification"} style={{ position: "relative" }}>
        <div className={"iconSection"}>
          <img
            alt={"Notification"}
            src={require("./../../assets/img/icons/notificationIcon.svg")}
            onClick={() => this.toggleNotification()}
            style={{ cursor: "pointer" }}
          ></img>
         {this.state.listItems && this.state.listItems.length > 0 ? <span className={"iconBadge"}>
            {totalCount}
          </span> : ''}
        </div>
        {this.state.toggleNotification && (
          <div
            style={{
              position: "absolute",
              width: "300px",
              border: "0.5px solid #8080803d",
              minHeight: "100px",            
              top: "30px"
            }}
            className={"notificationBar"}
          >
            <div className="d-flex justify-content-end">
              {/* <img
                alt={"close"}
                onClick={() => this.toggleNotification()}
                style={{ width: "5%", cursor: "pointer" }}
                src={require("./../../assets/img/icons/Close.svg")}
              ></img> */}
            </div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p style={{ fontSize: "20px", textAlign: "left", width: "93%" }}>
                Notifications
              </p>
            </div>
            <div 
            className="innerNotiContainer"
            style={{
              height: "400px",
              overflowY: "auto",
            }}
            onScroll={this.onScroll} 
            ref={this.containerRef}
            >
              {allTimestamp.map((i, k) => {
              return (
                <div key={k} style={{position: 'relative'}}>
                  <p
                    style={{
                      margin: "5px 0",
                      textAlign: "left",
                      color: "#747474",
                      display: "initial",
                      position: 'sticky',
                      top: '0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginRight: '10px',
                      backgroundColor: '#f4f4f4'
                    }}
                  >
                    <span style={{ fontSize: "12px"}}>
                      {i.UTC}
                    </span>
                    <span style={{ fontSize: "12px", cursor: "pointer"}}
                        onClick={() => {
                          if(this.props.callDelete) {
                            this.props.callDelete(i.UTC)
                          } 
                        }}> 
                        Clear
                    </span>
                  </p>
                  {i.list.map(l => {
                    return l.map((k,sub) => {
                      const d = new Date(k.timeStamp * 1000);
                      const min = d.getUTCMinutes();
                      const hours = d.getUTCHours() % 12 || 12;
                      const amOrpm = hours >= 12 ? "pm" : "am";
                      return (
                        <div
                          style={{ background: "#fff", padding: "5px" }}
                          className={"lineItmes"}
                          key={sub}
                          onClick={(e) => {
                            if (k.type == "New Orders") {
                              this.toggleNotification();
                            }
                            if(this.props.onClick) {
                              this.props.onClick(k)
                            }                                
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                          <span
                            style={{ fontSize: "12px", fontWeight: 'bold' }}
                          >{`${k.type}`}</span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#747474",
                              float: "right"
                            }}
                          >
                            {`${k.timestamp}`}
                          </span></div>
                          <div style={{ display:"flex",alignItems: "center" }}><span style={{ fontSize: "12px", width: "90%", marginRight: "5px",wordWrap: 'break-word'}}>{k.content}</span>{ k.is_seen == false && <div style={{backgroundColor: k.type == "Late Order" || k.type == "Order running late" ? "#ff0000" : k.type == "Unassigned Orders" || k.type == "Failed Order" ? "#FFC83A" : "#00FC37",float:"right",width: "10px",height: "10px",borderRadius: "50%"}}></div>}</div>
                        </div>
                      );
                    });
                  })}
                </div>
              );
            })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NotiBox;