import React, { Component } from 'react';
 
import { 
    List,
    ListItem,
    ListItemText,
    Collapse,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import defaultImage from '../../assets/img/default_image.jpg';


class CustomListZoneItem extends Component {
    constructor(props) {
        super(props);
        
        let className = (props.className) ? props.className : '';
        let text = (props.text) ? props.text : '';
        let desc = (props.desc) ? props.desc : 'No Description';
        let color = (props.color) ? props.color : null;
        let active = (props.active) ? props.active : null;
        let data = (props.data) ? props.data : null;
        let open = ((props.open === false) || (props.open === true)) ? props.open : false;

        this.state = {
            className: className,
            text: text,
            desc: desc,
            color: color,
            active: active,
            data: data,
            open: open,
        };
    }
 

    componentWillReceiveProps(nextProps) {
        if (nextProps.text !== this.state.text) {
            let text = (nextProps.text) ? nextProps.text : '';
            this.setState({
                text: text
            });
        }
        if (nextProps.desc !== this.state.desc) {
            let desc = (nextProps.desc) ? nextProps.desc : '';
            this.setState({
                desc: desc
            });
        }
        if (nextProps.color !== this.state.color) {
            let color = (nextProps.color) ? nextProps.color : null;
            this.setState({
                color: color
            });
        }
        if (nextProps.active !== this.state.active) {
            let active = (nextProps.active) ? nextProps.active : null;
            this.setState({
                active: active
            });
        }
        if (nextProps.data !== this.state.data) {
            let data = (nextProps.data) ? nextProps.data : null;
            this.setState({
                data: data
            });
        }
        if (nextProps.open !== this.state.open) {
            let open = ((nextProps.open === false) || (nextProps.open === true)) ? nextProps.open : false;
            this.setState({
                open: open
            });
        }
    }
    

    deactive = () => {
        this.setState({
            active: false
        });
    }

 
    render() {
        return <React.Fragment>
            <ListItem
                button
                className={'custom-list-item ' + (this.props.children ? 'sub-item' : 'item') + ' ' + ((this.state.className && this.state.className !== '') ? this.state.className : '') + ' ' + ((this.state.active !== null) ? ((typeof this.state.active === "function") ? this.state.active() : (this.state.active ? "active" : "")) : "")}
                onClick={(e) => {
                    if(this.props.children){
                        e.preventDefault();
                        e.stopPropagation();
            
                        this.setState({
                            open: !this.state.open,
                            active: !this.state.open,
                        }, () => {
                            if(this.props.onClick){
                                this.props.onClick(this);
                            }
                        });
                    } else {
                        if(this.props.onClick){
                            this.props.onClick(this);
                        }
                    }
                }}
            >
                 {/* <FontAwesomeIcon icon={faXmark} component={'i'} className={"custom-autosuggest-close no-propagation"}/> */}
                <Icon component={'i'} style={{ position: 'relative', top: '1px', left: '-8px',padding:'18px',background:this.state.color }}></Icon>

                <ListItemText primary={this.state.text} secondary={this.state.desc} />
                {(this.props.children) ? (this.state.open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
            {(this.props.children) && <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List className={'sub-nav'} component="div" disablePadding>
                    {this.props.children}
                </List>
            </Collapse>}
        </React.Fragment>
    }
}

export default CustomListZoneItem;