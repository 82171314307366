import React, { Component } from 'react';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import { Container, Row, Col, Card } from 'react-bootstrap';
import CustomInput from "../../../components/CustomFields/CustomInput.jsx";
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import { ReactComponent as CalendarIcon } from "../../../assets/img/icons/calendar.svg";
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import apiUtil from "../../../api/apiUtil.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import Api from "../../../api/api.jsx";
import dataUrlToBlob from 'dataurl-to-blob'
import moment from 'moment';

import {
  Box,
  Grid,
  Divider,
  InputLabel,
  Select,
  Input,
  IconButton,
  CircularProgress,
  Button,
  TextField,
  Link
} from "@material-ui/core";

class RequestForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    
    this.state = {
      date_of_service: '',
      date_of_service_validate: false,
      percel_delivery: false,
      carton_delivery: false,
      pallet_delivery: false,
      packaging_and_laber: false,
      vehicle_rental: false,
      type_of_service_other: false, 
      type_of_service_other_text: '',
      type_of_service_validate: false,
      item_description: '',
      item_description_validate: false,
      waiting_time: false,
      manpower: false,
      premium_delivery: false,
      customer_service_support: false,
      gift_card: false,
      kitting_by_driver: false,
      express_delivery: false,
      multiple_delivery_location: false,
      multiple_delivery_recipient: false,
      climbing_of_stair: false,
      special_location: false,
      service_add_on_none: false,
      service_add_on_other: false,
      service_add_on_other_text: '',
      service_add_on_validate: false,
      service_description: '',
      service_description_validate: false,
      drop_off_location: '',
      drop_off_location_validate: false,
      upload_file: null,
      type_of_service_focus: false,    
      files: [],
      openDialog: false,
      uploadFile: [],
      isLoading: false,
      tab: 1,
      range: ''
    }
    
    this.typeOfServiceRef = React.createRef();
    this.serviceAddOnRef = React.createRef();
    this.fileInputRef = React.createRef();
    
    this.handleFileChange = this.handleFileChange.bind(this);
  }
  
  componentDidMount() {
      
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let has_error = false;
    if(this.state.date_of_service.trim() == '') {
      this.setState({
        date_of_service_validate: true
      });
      has_error = true;      
    } else if(this.validateTypeOfService()) {
      has_error = true;
    } else if (this.state.item_description.trim() == '') {
      this.setState({
        item_description_validate: true
      }); 
      has_error = true;      
    }else if(this.validateServiceAddOn()) {
      has_error = true;
    } else if(this.state.service_description.trim() == '') {
      this.setState({
        service_description_validate: true
      });
      has_error = true;      
    } else if(this.state.drop_off_location.trim() == '') {
      this.setState({
        drop_off_location_validate: true
      }); 
      has_error = true;      
    }
    
    if(has_error == false) {
      const { accessToken } = this.context;
      this.setState({
        isLoading: true   
      },() => {
        const row = {
          date_of_service: this.state.date_of_service,
          type_of_service: [this.state.percel_delivery,this.state.carton_delivery,this.state.pallet_delivery,this.state.packaging_and_laber,this.state.vehicle_rental,this.state.type_of_service_other],
          type_of_service_other_text: this.state.type_of_service_other_text,
          item_description: this.state.item_description,
          service_add_on: [this.state.waiting_time,this.state.manpower,this.state.premium_delivery,this.state.customer_service_support,this.state.gift_card,this.state.kitting_by_driver,this.state.express_delivery,this.state.multiple_delivery_location,this.state.multiple_delivery_recipient,this.state.climbing_of_stair,this.state.special_location,this.state.service_add_on_none,this.state.service_add_on_other],
          service_add_on_other_text: this.state.service_add_on_other_text,
          service_description: this.state.service_description,
          drop_off_location: this.state.drop_off_location,
          files: this.state.uploadFile       
        }
        
        
        ldsApi.create('send_mail', row, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
        
            }, (error, type) => {
        
            }); 
            this.setState({
              isLoading: false,
              tab: 2              
            });
        });
      });      
    }
  }
  
  callUploadFilesApi = async (file, fName) => {
      const { accessToken } = this.context;
      let result = "";
      this.setState({
        isLoading: true,   
      });
      if(file){
          let fileExt = fName.split('.').pop();
          let filename = fName.substr(0, fName.lastIndexOf("."))
          
          let getResult = await Api.uploadFile(
              'upload',
              file, 
              'AUTH',
              'client/file', 
              filename,
              fileExt,
              accessToken, 
              (progress) => {
      
              }
          );
      
          apiUtil.parseResult(getResult, (data) => {
            if(data.status) {
              this.setState({
                uploadFile: [...this.state.uploadFile,data.result.image_medium_url]   
              });
            }
          }, (error) => {
              apiUtil.toast(error, 'check_circle', 'error');
          });
          if(this.state.files.length == this.state.uploadFile.length) {
            this.setState({
              isLoading: false 
            });
          }
      } else {
          apiUtil.toast('Please select a file!', 'check_circle', 'error');
      }
      return result;
  }
  
  handleFileChange = (event) => {
    if((this.state.files.length + event.target.files.length) > 5) {
      apiUtil.toast('Uploads of 5 Items are allowed', 'check_circle', 'error');   
      return;
    }
    if(event && event.target && event.target.files) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const f = files[i];
        let file_name = f.name
        let reader = new FileReader();
        reader.onload = (e) => {
          let result = e.target.result
          let blob = dataUrlToBlob(result)
          this.callUploadFilesApi(blob, file_name)
        };
        reader.readAsDataURL(f); 
      }
    }
    this.setState({
      files: [...this.state.files,...event.target.files]
    });        
  }
  
  validateTypeOfService = () => {
    let isInValid = !this.state.percel_delivery && !this.state.carton_delivery && !this.state.pallet_delivery && !this.state.packaging_and_laber && !this.state.vehicle_rental && !this.state.type_of_service_other
    
    if((!this.state.percel_delivery && !this.state.carton_delivery && !this.state.pallet_delivery && !this.state.packaging_and_laber && !this.state.vehicle_rental) && this.state.type_of_service_other && this.state.type_of_service_other_text.trim() == '') {
      isInValid = true    
    }
    if(isInValid) {
      this.setState({
        type_of_service_validate: true 
      });
    } else {
      this.setState({
        type_of_service_validate: false 
      });
    }
    return isInValid;    
  }
  
  validateServiceAddOn = () => {
    let isInValid = !this.state.waiting_time && !this.state.manpower && !this.state.premium_delivery && !this.state.customer_service_support && !this.state.gift_card && !this.state.kitting_by_driver && !this.state.express_delivery && !this.state.multiple_delivery_location && !this.state.multiple_delivery_recipient && !this.state.climbing_of_stair && !this.state.special_location && !this.state.service_add_on_none && !this.state.service_add_on_other
    
    if((!this.state.waiting_time && !this.state.manpower && !this.state.premium_delivery && !this.state.customer_service_support && !this.state.gift_card && !this.state.kitting_by_driver && !this.state.express_delivery && !this.state.multiple_delivery_location && !this.state.multiple_delivery_recipient && !this.state.climbing_of_stair && !this.state.special_location && !this.state.service_add_on_none) && this.state.service_add_on_other && this.state.service_add_on_other_text.trim() == '') {
      isInValid = true;    
    }
    
    if(isInValid) {
      this.setState({
        service_add_on_validate: true 
      });
    } else {
      this.setState({
        service_add_on_validate: false 
      });
    }   
    return isInValid;    
  }
  
  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Clear form?'}
      onClose={() => {
        this.setState({
          openDialog: false,
        });
      }}
      onOk={() => {
        this.setState({
          openDialog: false,
        }, () => {
          window.location.reload();
        });
      }}
    >
      <Box>This will remove your answers from all questions and cannot be undone.
      </Box>
    </CustomDialog>
  }

  render() {
    return <Container fluid className="overflow-hidden">
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
      <Row>
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          <div style={{width:"42.7rem"}} className="my-5">
            <Card>
              <Card.Header style={{backgroundColor: "rgb(66, 133, 244)"}}></Card.Header>
              <Card.Body>
                <h1 className="fs-3">Custom Logistics Quote Request</h1>
                {this.state.tab == 1 ? <p>For all project inquiries or special requests, please use this form to request a custom quote. Our team will respond within 1 working day. To expedite the process, please ensure all required information is complete. Thank you for your patience and cooperation!</p> : <p className="my-4">Thank you for submitting your request. Our team will review the information provided and get back to you as soon as possible. We appreciate your interest in our services.</p>}
                
                {this.state.tab == 2 && <Link onClick={() => window.location.reload()}>Submit another response</Link>}
              </Card.Body>
              {this.state.tab == 1 && <Card.Body style={{height: "52px",borderTopStyle: "solid",borderTopWidth: 1,borderTopColor: "#d7cfcf"}} className="align-items-center justify-content-center">
                <p style={{color: "rgb(217,48,37)"}}>* Indicates required question</p>
              </Card.Body>}
            </Card>
            { this.state.tab == 1 && <><Card className="mt-3">
              <Card.Body>
                <h2 className="fs-5">Date of service (date range)<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <label className="fs-6 mb-3 mt-2">Date</label>
                <Col xs={6}>
                  <CustomDateRangePicker
                    id={'select-dates-step-cus w-50'}
                    range={this.state.range}
                    onChange={(range) => {
                      // localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                      let startDate = moment(range.startDate).format('DD/MM/YYYY');
                      let endDate = moment(range.endDate).format('DD/MM/YYYY')
                      this.setState({
                        range: range,
                        date_of_service: startDate+' - '+endDate
                      },() =>{
                        this.setState({
                          date_of_service_validate: this.state.date_of_service == '' ? true : false   
                        })                           
                      });
                    }}
                  />

                </Col>
                {this.state.date_of_service_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
              </Card.Body>
            </Card> 
            <Card className="mt-3">
              <Card.Body>
                <h2 className="fs-5">Type of service<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <label className="fs-6 mb-3 mt-2">Select all that is relevant</label>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.percel_delivery}
                  onChange={e => {
                    this.setState({
                      percel_delivery: !this.state.percel_delivery    
                    },() => {
                      this.validateTypeOfService();   
                    });
                  }}
                  label="Parcels delivery"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.carton_delivery}
                  onChange={e => {
                    this.setState({
                      carton_delivery: !this.state.carton_delivery    
                    },() => {
                      this.validateTypeOfService();    
                    });
                  }}
                  label="Cartons delivery"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.pallet_delivery}
                  onChange={e => {
                    this.setState({
                      pallet_delivery: !this.state.pallet_delivery    
                    },() => {
                      this.validateTypeOfService();    
                    });
                  }}
                  label="Pallets / oversized items delivery"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.packaging_and_laber}
                  onChange={e => {
                    this.setState({
                      packaging_and_laber: !this.state.packaging_and_laber   
                    },() => {
                      this.validateTypeOfService();    
                    });
                  }}
                  label="Packaging and labour"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.vehicle_rental}
                  onChange={e => {
                    this.setState({
                      vehicle_rental: !this.state.vehicle_rental   
                    },() => {
                      this.validateTypeOfService();   
                    });
                  }}
                  label="Vehicle rental"
                  />
                </Col>
                <Row className="d-flex align-items-center">
                  <div className="col-lg-2 col-sm-2 col-md-2" style={{paddingRight: 0}}>                 
                    <CustomCheckbox
                    alignCenter={true}                    
                    checked={this.state.type_of_service_other}
                    onChange={e => {
                      this.setState({
                        type_of_service_other: !this.state.type_of_service_other   
                      },() => {
                        this.validateTypeOfService();  
                        if(this.state.type_of_service_other) {
                          this.typeOfServiceRef.current.focus();
                        }                            
                      });                  
                    }}
                    label="Other:"
                    /> 
                  </div>
                  <div className="col-lg-10 col-sm-10 col-md-10">                 
                    <TextField
                      value={this.state.type_of_service_other_text}
                      style={{borderBottomStyle: "solid",borderBottomWidth: this.state.type_of_service_other_text == '' ? 1 : 3,borderBottomColor: this.state.type_of_service_other_text == '' ? "rgb(218,220,224)" : "rgb(0, 116, 237)",marginTop: 5}}
                      fullWidth
                      inputRef={this.typeOfServiceRef}
                      onChange={(e) => {
                        this.setState({
                          type_of_service_other_text: e.target.value,
                          type_of_service_other: e.target.value == '' ? false : true                          
                        },() => {
                          this.validateTypeOfService()
                        });
                      }}
                    />
                  </div>
                  {this.state.type_of_service_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
                </Row>
              </Card.Body>
            </Card> 
            <Card className="mt-3 py-3">
              <Card.Body>
                <h2 className="fs-5">Item description<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <label className="fs-6 mb-3 mt-2">What do you need to be delivered or packed?</label>
                <Col xs={12}>
                  <TextField
                    style={{borderBottomStyle: "solid",borderBottomWidth: this.state.item_description == '' ? 1 : 3,borderBottomColor: this.state.item_description == '' ? "rgb(218,220,224)" : "rgb(0, 116, 237)"}}
                    fullWidth
                    placeholder="Your answer"
                    value={this.state.item_description}
                    onBlur={ () => {
                      if(this.state.item_description.trim() == '') {
                        this.setState({
                          item_description_validate: true
                        });                            
                      }
                    }}
                    onChange={(e) => {
                      this.setState({
                        item_description: e.target.value,
                        item_description_validate: e.target.value != '' ? false : true
                      });
                    }}
                  />
                </Col>
                {this.state.item_description_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <h2 className="fs-5">Service Add Ons:<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <label className="fs-6 mb-3 mt-2">Select all that is relevant</label>
                <Col xs={12}>
                  <CustomCheckbox
                  alignCenter={true}                  
                  checked={this.state.waiting_time}
                  onChange={e => {
                    this.setState({
                      waiting_time: !this.state.waiting_time    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Waiting time (Pls specify estimated waiting time below)"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.manpower}
                  onChange={e => {
                    this.setState({
                      manpower: !this.state.manpower    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Manpower (Pls specify estimated man-hours required below)"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.premium_delivery}
                  onChange={e => {
                    this.setState({
                      premium_delivery: !this.state.premium_delivery    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Premium delivery for high value items"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.customer_service_support}
                  onChange={e => {
                    this.setState({
                      customer_service_support: !this.state.customer_service_support    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Customer Service Support"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.gift_card}
                  onChange={e => {
                    this.setState({
                      gift_card: !this.state.gift_card    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Personalised name on item or gift card"
                  />
                </Col>
                <Col xs={12}>                 
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.kitting_by_driver}
                  onChange={e => {
                    this.setState({
                      kitting_by_driver: !this.state.kitting_by_driver    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Kitting by driver (e.g. place item in paper bag before delivery)"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.express_delivery}
                  onChange={e => {
                    this.setState({
                      express_delivery: !this.state.express_delivery    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Express deliveries or time windows (Pls specify below)"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.multiple_delivery_location}
                  onChange={e => {
                    this.setState({
                      multiple_delivery_location: !this.state.multiple_delivery_location    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Multiple delivery locations in the same building"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.multiple_delivery_recipient}
                  onChange={e => {
                    this.setState({
                      multiple_delivery_recipient: !this.state.multiple_delivery_recipient    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Multiple delivery recipients in the same unit"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.climbing_of_stair}
                  onChange={e => {
                    this.setState({
                      climbing_of_stair: !this.state.climbing_of_stair    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Climbing of stairs or no lift access"
                  /> 
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  checked={this.state.special_location}
                  onChange={e => {
                    this.setState({
                      special_location: !this.state.special_location    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="Special location access e.g. Prison"
                  />
                </Col>
                <Col xs={12}>
                  <CustomCheckbox 
                  alignCenter={true}
                  checked={this.state.service_add_on_none}
                  onChange={e => {
                    this.setState({
                      service_add_on_none: !this.state.service_add_on_none    
                    },() => {
                      this.validateServiceAddOn();    
                    });
                  }}
                  label="None"
                  />
                </Col>
                <Row className="d-flex align-items-center">
                  <div className="col-lg-2 col-sm-2 col-md-2">                 
                    <CustomCheckbox 
                    alignCenter={true}
                    checked={this.state.service_add_on_other}
                    onChange={e => {
                      this.setState({
                        service_add_on_other: !this.state.service_add_on_other    
                      },() => {
                        this.validateServiceAddOn();   
                        if(this.state.service_add_on_other) {
                          this.serviceAddOnRef.current.focus();
                        }                        
                      });                  
                    }}
                    label="Other:"
                    /> 
                  </div>
                  <div className="col-lg-10 col-sm-10 col-md-10">                 
                    <TextField
                      style={{borderBottomStyle: "solid",borderBottomWidth: this.state.service_add_on_other_text == '' ? 1 : 3,borderBottomColor: this.state.service_add_on_other_text == '' ? "rgb(218,220,224)" : "rgb(0, 116, 237)",marginTop: 5}}
                      fullWidth
                      inputRef={this.serviceAddOnRef}
                      value={this.state.service_add_on_other_text}
                      onChange={(e) => {
                        this.setState({
                          service_add_on_other_text: e.target.value,
                          service_add_on_other: e.target.value == '' ? false : true                          
                        },() => {
                          this.validateServiceAddOn()
                        });
                      }}
                    />
                  </div>
                </Row>
                {this.state.service_add_on_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
              </Card.Body>
            </Card>  
            <Card className="mt-3 py-3">
              <Card.Body>
                <h2 className="fs-5 mb-4">Service Description<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <Col xs={12}>
                  <TextField
                    style={{borderBottomStyle: "solid",borderBottomWidth: this.state.service_description == '' ? 1 : 3,borderBottomColor: this.state.service_description == '' ? "rgb(218,220,224)" : "rgb(0, 116, 237)"}}
                    fullWidth
                    placeholder="Your answer"
                    value={this.state.service_description}
                    onBlur={ () => {
                      if(this.state.service_description.trim() == '') {
                        this.setState({
                          service_description_validate: true
                        });                            
                      }
                    }}
                    onChange={(e) => {
                      this.setState({
                        service_description: e.target.value,
                        service_description_validate: e.target.value != '' ? false : true
                      });
                    }}
                  />
                </Col>
                {this.state.service_description_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
              </Card.Body>
            </Card>     
            <Card className="mt-3 py-3">
              <Card.Body>
                <h2 className="fs-5">Pick-up and drop-off locations<span style={{color: "rgb(217,48,37)"}}>*</span></h2>
                <label className="fs-6 mb-3 mt-2">Put NA if not relevant</label>
                <Col xs={12}>
                  <TextField
                    style={{borderBottomStyle: "solid",borderBottomWidth: this.state.drop_off_location == '' ? 1 : 3,borderBottomColor: this.state.drop_off_location == '' ? "rgb(218,220,224)" : "rgb(0, 116, 237)"}}
                    fullWidth
                    placeholder="Your answer"
                    value={this.state.drop_off_location}
                    onBlur={ () => {
                      if(this.state.drop_off_location.trim() == '') {
                        this.setState({
                          drop_off_location_validate: true
                        });                            
                      }
                    }}
                    onChange={(e) => {
                      this.setState({
                        drop_off_location: e.target.value,
                        drop_off_location_validate: e.target.value != '' ? false : true
                      });
                    }}
                  />
                </Col>
                {this.state.drop_off_location_validate && <p style={{color: "rgb(217,48,37)",fontSize: 12}} className="mt-3 mb-0"><ErrorOutlineIcon style={{marginRight: 10}}/>This is a required question</p>}
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <h2 className="fs-5">Upload address, photos of items, locations, etc.</h2>
                <Col xs={12}>
                  <input 
                    type="file" 
                    style={{display: 'none'}}
                    onChange={this.handleFileChange}
                    id="fileInput"
                    multiple
                    ref={this.fileInputRef}
                    accept=".pdf, .doc, .docx, .pptx, .csv, .xls, .xlsx, .jpeg, .jpg, .png"
                  />
                  <div className="d-flex" style={{flexWrap: 'wrap'}}>
                  {this.state.files.length > 0 && this.state.files.map((file,index) => {
                    return <div style={{border: '1px solid #000',width: 150,display: 'flex',alignItems: 'center',padding: 5,marginRight: 5,marginTop: 5}}><span style={{width: 120,display: 'block',overflow: 'hidden',wordWrap:'normal',height: 25}}>{file.name}</span>
                    <CloseIcon 
                      onClick={() => {
                        this.setState({
                          files: this.state.files.filter((f,i) => i != index),
                          uploadFile: this.state.uploadFile.filter((f,i) => i != index)
                        });                            
                      }}
                    />
                    </div>
                  })}
                  </div>
                  <Button
                    variant="outlined"
                    startIcon={<CloudUploadOutlined style={{color: '#1a73e8'}} />}
                    className="mt-3"
                    onClick={() => this.fileInputRef.current.click()}
                  >
                    <span style={{color: '#1a73e8'}}>Add File</span>
                  </Button>
                </Col>
              </Card.Body>
            </Card>  
            <Row className="mt-3">
              <Col xs={6} className="d-flex justify-content-start">
                <Button
                  variant="contained"
                  color="primary"
                  type='submit'
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ?  <CircularProgress size={15} style={{ color: 'white' }} /> : <span style={{color: '#fff'}}>Submit</span>}
                </Button>               
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Button color="primary" onClick={() => {
                  this.setState({
                    openDialog: true 
                  });
                }}    
                ><span style={{color: '#1a73e8'}}>Clear form</span></Button>            
              </Col>
            </Row></>}              
          </div>
        </Col>
      </Row>
      </form>
      {this.customDialog()}
    </Container>    
  }
}

export default RequestForm;