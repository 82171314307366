import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

export default class CustomBarChartRegion extends PureComponent {

  constructor(props){
    super(props);
    
    let data = props.data? props.data : [];
    let label = props.label? props.label : [];
    let type = props.type? props.type : 'full';
    this.state = {
      data : data,
      label : label,
      type : type,
    }
  }

  render() {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{overflowX: 'auto', overflowY: 'hidden', height: 'auto', position: 'absolute', left: '500px', top: '10px'}}>
      {this.state.data.length > 0 ?
        <BarChart
          width={300}
          height={300}
          data={this.state.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        className='legend-padding-bar-packages'
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={this.state.label}/>
          <YAxis domain={[0, 'dataMax+10']}/>
          <Tooltip
              itemSorter={(item) => {
                switch (item.dataKey) {
                  case "W":
                    return 1;
                  case "N":
                    return 2;
                  case "NE":
                    return 3;
                  case "E":
                    return 4;
                  case "CTR":
                    return 5;
                  default:
                    return 0;
                }
              }}
            />
          <Bar dataKey="value" stackId="a">
              {this.props.showLable && <LabelList dataKey="value" position="top" style={{ fontWeight: 'bold', fontSize: 12, fontWeight: 'bold' }}/>}
              {this.state.data.map((entry, index) => (
                  <Cell key={index} name={entry.name} fill={entry.color} />
              ))}
          </Bar>
        </BarChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </div>
    );
  }
}
