import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import apiUtil from "../../api/apiUtil.jsx";
import { withRouter, Link } from 'react-router-dom';
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import { Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faDownload, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import JobImportDialog from '../Jobs/Partial/JobImportDialog.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import OrderTemplate from "../../assets/files/OrderFormforAdmin.xlsx"
import {RoleAccessService} from '../../data/role-access'; 
import { saveAs } from "file-saver";

class AdminNewOrderNewLogic extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      openImportDialog : false,
      openImportDialogItem: null,
      data_form : []
    }
  }
  componentDidMount() {
  
  }

  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            });
            if(callback){
              callback(data);
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(
                error,
                "check_circle"
              );
            });
          });
      });
    });
  }
  /* END IMPORT API */

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'md'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
          excle_file: '',
          data_form: []
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
            openImportDialogIsLoading: false,
            data_form: [],
            excle_file: '',
          });
        }}
        onImport={(form) => {
          this.setState({
            data_form: form,
            openImportDialog: false,
            openImportDialogItem: null,
            openImportDialogIsLoading: false
          });
        }}
      />
    </CustomDialog>
  }

  render() {
    return <Container fluid>
        <Row className="p-4">
            <Col>
                <Card className="cd-shadow">
                    <div className="p-3 font-medium fs-5">Order multiple locations at one go!</div>
                    <hr className="p-0 m-0"/>
                    <div className="p-3">
                    <div>Steps:</div>
                    <ol>
                        <li>Liaise with our Customer Success team to get a <button onClick={()=> saveAs(
      OrderTemplate,
      "Client_Order_Form_Template.xlsx"
    )} className="download-order-template">customised CSV template order form <FontAwesomeIcon icon={faDownload} /></button></li>
                        <li>Fill up the relevant information needed (e.g. date, time, address)</li>
                        <li>Upload your completed form below!</li>
                    </ol>
                    </div>
                    <div className="p-3">
                    <div>Notes:</div>
                    <ul>
                        <li>Make sure the date format filled is DD/MM/YYYY</li>
                        <li>Any special instructions directed for Driver-Partners should be put in the ‘Notes to Driver’ column</li>
                    </ul>
                    </div>
                </Card>
            </Col>
        </Row>
        <Row className="px-4 py-3">
            <Col>
            <Card className="cd-shadow">
                  <div className="row d-flex flex-row justify-content-center align-items-center w-75 max-xs-width-control-100 p-3">
                    <div className="col-12 col-xs-12 col-md-4 col-lg-3 text-end">Upload CSV or Excel file:</div>
                    <div className="col-12 col-xs-12 col-md-8 col-lg-9"> 
                    <input type="file" 
                    accept=".xls, .xlsx"
                    className="form-control flip-form"
                    value={this.state.excle_file}
                    disabled = {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add') ? false : true}
                    onChange={(e) => {
                    
                      let files = e.target.files
                      if (files && files.length > 0){
                        this.setState({
                          openImportDialog: true,
                          openImportDialogItem: files[0],
                          excle_file: e.target.value
                        });
                      }
                    }}
                    />
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center p-3">
                    <Button className="upload-style"
                    isLoading={this.state.isLoading}
                    disabled = {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add') ? false : true}
                    onClick={()=>{
                      this.setState({
                        isLoading: true 
                      });
                      let form = this.state.data_form
                      // console.log("this.state.data_form", this.state.data_form)
                      if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')) {
                        this.state.data_form.length > 0 ?
                        this.callImportApi(form, (data) => {
                          apiUtil.toast('Jobs Successfully Imported', 'check_circle');
              
                          this.setState({
                            openImportDialog: false,
                            openImportDialogItem: null,
                            openImportDialogIsLoading: false,
                            data_form: [],
                            excle_file: '',
                          });
                        }) :
                        apiUtil.toast("Please select file first", "check_circle") 
                      }
                    }}
                    >{this.state.isLoading? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <><FontAwesomeIcon icon={faArrowUpFromBracket} className="text-white" />&nbsp; Upload New Order</>}</Button>
                    {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Add')?
                    <NavLink to="/dashboards/new-order-form" className="text-decoration-none"> Or create an individual order here</NavLink>
                    :<div className="text-decoration-none"> Or create an individual order here</div>
                    }

                </div>
            </Card>
            </Col>
        </Row>
        {this.importDialog()}
        </Container>
  }
}

export default AdminNewOrderNewLogic;
