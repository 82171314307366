import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import logo from '../../assets/img/logo_black.png';
import signup_image from '../../assets/img/sign_up.png';
import sliderTemp1 from "../../assets/img/slider_bg/dashboard.png"
import sliderTemp2 from "../../assets/img/slider_bg/fleet.png"
import sliderTemp3 from "../../assets/img/slider_bg/byd.png"
import Carousel from 'react-bootstrap/Carousel';
import trackerLogo from '../../assets/img/tracker_logo.png';
import Footer from '../Footer/Footer.jsx'

export default function SignUp() {

  // let history = useHistory();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
  };

  return (
    <div className="signin">
    <div className="cover">
      <div className="cover__text">
          <Carousel controls={false}>
          <Carousel.Item>
            <div className="fs-4 text-center text-white">Easy to use driver management system</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Multiple EVs at your disposal</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Eco-friendly vehicle leasing</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      </div>
    <div className="signup" component="main" >
      <div className="top-menu">
        <div className="signup-acc d-flex"> 
        <div>Already have an account?</div>
        <Button className="text-white primary-background" href="/Sign-in">
          <span className="text-white">Login</span>
        </Button>
        </div>
      </div>
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={3} mb={3}>
          <Alert severity="error">
            {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })}
          </Alert>
        </Box>

        
      }
         <div className="h-100 d-flex justify-content-center align-items-center">
          <Box color="success.main"> A confirmation mail has been sent to you. Please check your email.</Box> 
          </div>
      {/* <Box mt={6}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="/">TaskkMe</Link>
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box> */}
    </div>
    </div>
  );
}