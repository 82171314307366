import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";

import { 
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress,
  Select,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomAlert from '../../components/Alert/CustomAlert.jsx';
import defaultImg from "../../assets/img/default_image.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faMagnifyingGlass, faPlus, faPen, faTrashCan, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, Card, Modal, Form } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access'; 

export class SubconForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;
    let is_temp = (this.props.location && this.props.location.state && this.props.location.state.is_temp) ? this.props.location.state.is_temp : 'no';

    this.state = {
      id: id,

      isLoading: false,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      company_name: '',
      company_nameValidated: false,
      name: '',
      prev_name: '',
      prev_company_name: '',
      nameValidated: false,
      email: '',
      is_temp:is_temp,
      emailValidated: false,
      uen: '',
      address: '',
      payment_method: '',
      name_of_bank: '',
      account_number: '',
      payout_frequency: '',
      phone_number: '',
    }
  }
  

  componentDidMount() {
    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data) => {
        let extra_data = data.extra_data ? JSON.parse(data.extra_data) : {}
        this.setState({
          company_name: (data && data.company_name) ? data.company_name : '',
          name: (data && data.name) ? data.name : '',
          prev_name: (data && data.name) ? data.name : '',
          prev_company_name: (data && data.company_name) ? data.company_name : '',
          email: (data && data.email) ? data.email : '',
          uen: extra_data.uen ? extra_data.uen : '',
          address: extra_data.address ? extra_data.address : '',
          payment_method: extra_data.payment_method ? extra_data.payment_method : '',
          name_of_bank: extra_data.name_of_bank ? extra_data.name_of_bank : '',
          account_number: extra_data.account_number ? extra_data.account_number : '',
          payout_frequency: extra_data.payout_frequency ? extra_data.payout_frequency : '',
          phone_number: extra_data.phone_number ? extra_data.phone_number : '',
        });
      });
    }
  }

  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.read('vendors/show/' + id + '/' + this.state.is_temp, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.create('create_vendor', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'check_circle', 'error');
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      row['is_temp'] =this.state.is_temp;
      row['id'] = id;
      row['is_temp_id'] = id;
      
      api.create('vendor/update/' + id + '/' + this.state.is_temp, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              this.props.history.push('/resources/vendor');
            if (((data && data.result && data.result.person_name == this.state.prev_name) && row.name != this.state.prev_name) || (row.name != this.state.prev_name && this.state.is_temp == 'no')) {
              apiUtil.toast('Successfully Updated, PIC Name not effected', 'check_circle');
            } else if (((data && data.result && data.result.company_name == this.state.prev_company_name) && row.company_name != this.state.prev_company_name) || (row.company_name != this.state.prev_company_name && this.state.is_temp == 'no')) {
              apiUtil.toast('Successfully Updated, Company Name not effected', 'check_circle');
            } else {
              apiUtil.toast('Successfully Updated', 'check_circle');
            }
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        this.props.history.push('/vendor');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={(this.state.id > 0) ? 'Edit Vendor' : 'Add New Vendor'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* FORM */
  customForm = () => {
    return <Card className="cd-shadow"><div className="p-4">
    <div className="fs-5 font-medium w-100">Person-in-Charge Details</div>
    <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
        <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Name<span className='text-danger'>*</span></div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
              <CustomInput
                  placeholder={'Name of person in charge of company'}
                  value={this.state.name}
                  error={this.state.nameValidated}
                  required={true}
                  onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                        nameValidated: (e.target.value !== '') ? false : true,
                      });
                  }}
              />
          </div>
      </div>
      <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
      <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Phone Number<span className='text-danger'>*</span></div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
              <CustomInput
                  placeholder={'Phone Number'}
                  value={this.state.phone_number}
                  error={this.state.phone_numberValidated}
                  required={true}
                  onChange={(e) => {
                      this.setState({
                        phone_number: e.target.value.trim(),
                        phone_numberValidated: (e.target.value !== '') ? false : true,
                      });
                  }}
              />
          </div>
      </div>
      <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
      <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Email Address<span className='text-danger'>*</span></div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
        <CustomInput
              placeholder={'Enter company email address'}
              type={'email'}
              value={this.state.email}
              error={this.state.emailValidated}
              disabled={this.state.id > 0 ? true : false}
              required={true}
              onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    emailValidated: (e.target.value !== '') ? false : true,
                  });
              }}
          />
          </div>
        </div>
        </div>
        </Card>
  }

  CapacityAndAllocationForm = () => {
    return <Card className="cd-shadow"><div className="p-4">
    <div className="fs-5 font-medium w-100">Capacity And Allocation</div>
    <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
        <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Delivery Zones</div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
              <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                    
                  value={this.state.delivery_zones}
                  native
                  disableUnderline
                  onChange={(e) => {
                    this.setState({
                      delivery_zones: e.target.value,
                    })

                  } }
                >
                <option value= ''> - Select - </option>
              </Select>
          </div>
      </div>
      <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
      <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Desired Postal Codes</div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                    
                    value={this.state.desired_postal_code}
                    native
                    disableUnderline
                    onChange={(e) => {
                      this.setState({
                        desired_postal_code: e.target.value,
                      })

                    } }
                  >
                  <option value= ''> - Select - </option>
                </Select>
          </div>
      </div>
      <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
      <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Postal Codes to Avoid</div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
              <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                    
                    value={this.state.avoid_postal_code}
                    native
                    disableUnderline
                    onChange={(e) => {
                      this.setState({
                        avoid_postal_code: e.target.value,
                      })

                    } }
                  >
                  <option value= ''> - Select - </option>
                </Select>
          </div>
      </div>
      <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
      <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Fleet Size</div>
        <div className="w-50 ms-2 driver-respond-between-md-xs">
                                    
              <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                    
                    value={this.state.avoid_postal_code}
                    native
                    disableUnderline
                    onChange={(e) => {
                      this.setState({
                        avoid_postal_code: e.target.value,
                      })

                    } }
                  >
                  <option value= ''> - Select - </option>
                </Select>
          </div>
      </div>
      </div>
      </Card>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      company_nameValidated: false,
      nameValidated: false,
      emailValidated: false,
      uenValidated: false,
      addressValidated: false,
      payment_methodValidated: false,
      account_numberValidated: false,
      payout_frequencyValidated: false,
      phone_numberValidated: false,
    });
    

    if(this.state.company_name === ''){
      this.setState({
        company_nameValidated: true
      });
    }
    if(this.state.name === ''){
      this.setState({
        nameValidated: true
      });
    }

    if(this.state.uen === ''){
      this.setState({
        uenValidated: true
      });
    }
    if(this.state.address === ''){
      this.setState({
        addressValidated: true
      });
    }
    if(this.state.payment_method === ''){
      this.setState({
        payment_methodValidated: true
      });
    }
    if(this.state.account_number === ''){
      this.setState({
        account_numberValidated: true
      });
    }
    if(this.state.payout_frequency === ''){
      this.setState({
        payout_frequencyValidated: true
      });
    }

    let phoneValidated = true
    if(!apiUtil.phoneValidationWithPlus(this.state.phone_number) ){
      phoneValidated = false
      this.setState({
        phone_numberValidated: true
      });
    }

    if(this.state.email === ''){
      this.setState({
        emailValidated: true
      });
    }
    
    let validatedEamil = true
    if(this.state.email !== '' && !apiUtil.emailValidation(this.state.email)){
      validatedEamil = false
      this.setState({
          emailValidated: true
      });
    }

    if (form.checkValidity() === true && validatedEamil && phoneValidated) {
      let extra_data  = {
        uen: this.state.uen,
        address: this.state.address,
        payment_method: this.state.payment_method,
        name_of_bank: this.state.name_of_bank,
        account_number: this.state.account_number,
        payout_frequency: this.state.payout_frequency,
        phone_number: this.state.phone_number,
      }
      let data = {
        company_name: this.state.company_name,
        name: this.state.name,
        email: this.state.email,
        extra_data: JSON.stringify(extra_data)
      };

      if(this.state.id && this.state.id > 0){
        this.callUpdateApi(this.state.id, data, () => {
          this.setState({
            isLoading: false
          });
        });
      } else {
        this.callCreateApi(data, (dd) => {
          this.setState({
            isLoading: false
          }, () => {
            if(dd) {
              this.props.history.push('/resources/vendor');
              apiUtil.toast(dd.result, 'check_circle');
            }
          });
        });
      }
    } else {
      this.setState({
        isLoading: false
      });
    }
  }
  /* END SUBMIT */

  companyInformation = () => {
    return <Card className="cd-shadow"><div className="p-4">
        <div className="fs-5 font-medium w-100">Company Information</div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Name<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'Company Name'}
                value={this.state.company_name}
                error={this.state.company_nameValidated}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    company_name: e.target.value,
                    company_nameValidated: (e.target.value !== '') ? false : true
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">UEN<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'UEN'}
                value={this.state.uen}
                error={this.state.uenValidated}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    uen: e.target.value,
                    uenValidated: (e.target.value !== '') ? false : true
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Address<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'Address'}
                value={this.state.address}
                error={this.state.addressValidated}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    address: e.target.value,
                    addressValidated: (e.target.value !== '') ? false : true
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Payment Method<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'Payment Method'}
                value={this.state.payment_method}
                error={this.state.payment_methodValidated}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    payment_method: e.target.value,
                    payment_methodValidated: (e.target.value !== '') ? false : true
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Name of Bank</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'Name of Bank'}
                value={this.state.name_of_bank}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    name_of_bank: e.target.value,
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Payment Account Number<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <CustomInput
                placeholder={'Payment Account Number'}
                value={this.state.account_number}
                error={this.state.account_numberValidated}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                  this.setState({
                    account_number: e.target.value,
                    account_numberValidated: (e.target.value !== '') ? false : true
                  })
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Payout Frequency<span className='text-danger'>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
              placeholder={'Enter Job Type here...'}
              value={this.state.payout_frequency}
              native
              required={true}
              error={this.state.payout_frequencyValidated}
              disableUnderline
              onChange={(e) => {
                this.setState({
                  payout_frequency: e.target.value,
                  payout_frequencyValidated: (e.target.value !== '') ? false : true
                })

              } }
             >
            <option value= ''> - Select - </option>
            <option value={1}>Weekly</option>
            <option value={2}>Every 2 weeks</option>
            <option value={3}>Monthly</option>
          </Select>
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Coporate Logo</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                
            <div className="row d-flex align-items-center py-3">
              <Col xs={6} md={4} lg={4} className="text-center">
              <img src={defaultImg} width="80px" height="auto"/>
              <div className="d-flex justify-content-center p-2">
                  <FontAwesomeIcon icon={faPen} className="primary-color px-2"/>
                  <FontAwesomeIcon icon={faTrashCan} className="danger-color px-2"/>
              </div>
              </Col>
            </div>
            </div>
        </div>
        
    </div>
    </Card>
}

adminstraticeMatter = () => {
  return <Card className="cd-shadow px-4 py-4">
       <div className="fs-6 font-medium w-100 ">Administrative Matters</div>
       <Row>
          <Col xs={12} >
            <Row>
              <Col xs={12} md={12} lg={4} xl={4}>
              <div className="d-flex align-items-center py-3 px-4">
              <div className="w-50 text-end px-2 font-medium driver-respond-between-md-xs remove-p">Account Status</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                    
                    value={this.state.account_status}
                    native
                    disableUnderline
                    onChange={(e) => {
                      this.setState({
                        account_status: e.target.value,
                      })

                    } }
                  >
                  <option value= ''> - Select - </option>
                  <option value={'Active'}>Active</option>
                  <option value={'Inactive'}>Inactive</option>
                  <option value={'Blacklisted'}>Blacklisted</option>
                </Select>
                </div>
              </div>
              </Col>
              <Col xs={12} md={12} lg={4} xl={4}>
              <div className="d-flex align-items-center py-3 px-4">
              <div className="w-50 text-end px-2 font-medium driver-respond-between-md-xs remove-p">Username</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <CustomInput
                    placeholder={'UserName'}
                    value={this.state.username}
                    autoFocus={true}
                    onChange={(e) => {
                      this.setState({
                        username: e.target.value,
                      })
                    }}
                />
                </div>
              </div>
              </Col>
              <Col xs={12} md={12} lg={4} xl={4}>
              <div className="d-flex align-items-center py-3 px-4">
              <div className="w-50 text-end px-2 font-medium driver-respond-between-md-xs remove-p">Password</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <CustomInput
                    placeholder={'Password'}
                    value={this.state.password}
                    autoFocus={true}
                    onChange={(e) => {
                      this.setState({
                        password: e.target.value,
                      })
                    }}
                />
                </div>
              </div>
              </Col>

            </Row>
          
          </Col>
          <Col xs={12}>
          <div className="align-items-center pe-4">
              <div className="w-100 text-start py-3">Reason for suspending account (if applicable):</div>
              <div className="w-100"><textarea className="form-control" rows="7"></textarea></div>
          </div>
          </Col>
       </Row>
  </Card>
}


  render() {
    return <Box  >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <Row className="px-4 pt-4">
                    <div className="fs-5 font-medium w-100 py-3"> {this.state.id ? "Edit Vendor Details" : "Create Vendor"  }</div>
                      <Col xs={12} md={6}>
                        {this.companyInformation()}
                        {/* {vehicleInformation()} */}
                      </Col>  
                      <Col xs={12} md={6}>
                      {this.customForm()}
                      <Box mt={1}>
                      {this.CapacityAndAllocationForm()}
                      </Box>
                      
                      </Col>  
                    </Row>
                    <Row className="p-4"> 
                        <Col>
                        {this.adminstraticeMatter()}
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col className="d-flex">
                      <div className="justify-self-end ms-auto"><Button className="upload-style-border"
                      onClick={() => {
                        this.props.history.push("/resources/vendor")
                      }}
                      >Cancel</Button></div>
                      {this.state.id ?
                      ((this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor Edit')) || this.state.currentRole == "Ops Admin") && <div><Button className="upload-style" type='submit' disabled={this.state.isLoading}>Save Changes</Button></div> 
                      :
                      ((this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Vendor Add')) || this.state.currentRole == "Ops Admin") && <div><Button className="upload-style" type='submit' disabled={this.state.isLoading}>Save Changes</Button></div>}
                        </Col>
                    </Row>
                </form>
    </Box>
  }
}
