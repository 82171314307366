import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb.js';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';

import CustomLiveTrackingMap from '../../components/GoogleMap/CustomLiveTrackingMapWithMark.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../data/role-access.js'; 
import CustomPieChart from '../../components/Charts/PieCharts.jsx';
// import SizePieChart from '../../components/Charts/';
import NormalPieChart from '../../components/Charts/NormalPieChart.jsx';
import NormalPieChartV2 from '../../components/Charts/NormalPieChartV2.jsx';
import CustomLineChart from '../../components/Charts/LineCharts.jsx';
import CustomVerticleBarChart from '../../components/Charts/VerticleBarCharts.jsx';
import CustomBarChartRegion from '../../components/Charts/BarChartsRegion.jsx';
import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMapWithMarkers.jsx';
import CustomHorizontalBarChart from '../../components/Charts/HorizontalBarChart.jsx';

import MatrixGrid from './partial/MartixGrid.jsx'
import SingaporeMap from './partial/SingaporeMap.jsx'

import Scales from '../../assets/img/analytic_icons/Scales.svg'
import NaturalFood from '../../assets/img/analytic_icons/NaturalFood.svg'
import Checkmark from '../../assets/img/analytic_icons/Checkmark.svg'
import FullVersion from '../../assets/img/analytic_icons/FullVersion.svg'
import Fragile from '../../assets/img/analytic_icons/Fragile.svg'
import EditDeliveryTerms from '../../assets/img/analytic_icons/EditDeliveryTerms.svg'
import BoxImportant from '../../assets/img/analytic_icons/BoxImportant.svg'
import CO2 from '../../assets/img/analytic_icons/CO2.svg'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;


class Sustainability extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(7, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      isLoadingOrder:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingMap: false,
      distanceLoading: false,
      data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_perday: [],
      order_by_size: [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
      overview_data : [],
      distance_travel: [],
      total_kilometer: 0,
      highestKilo: 0,
      driver_entity: [],
      driverEntityLoading: false,
      co2EvLoading: false,
      co2_ev_saving: 0,
      co2_emission: 0,
      grennerLoading: false,
      greener_count: 0,
      lockCapture: false,
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.getDriverEntity(); 
    this.overviewDataApi();
    this.getCo2Ev();
    this.getGrennerChoice();

  }

// API START =================

  getGrennerChoice = () => {
    const { accessToken } = this.context;
    
    this.setState({
      grennerLoading: true,
    }, () => {    
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };
      
      ldsApi.read('/analytic/greener_choice/', param, accessToken)
      .then((result) => {
        // recent_activity
        apiUtil.parseResult(result, (data) => {
          this.setState({
            greener_count: data.result,
            grennerLoading: false,
          });
        }, (error, type) => {
          this.setState({
            driver_entity: [],
            grennerLoading: false,
          });
        });

      });
      
    });    
  }
  
  getDriverEntity = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      driverEntityLoading: true,
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/driver_entity/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              driver_entity: data.result,
              driverEntityLoading: false,
            },() => {
              if(callback) {
                callback();
              }   
            });
          }, (error, type) => {
            this.setState({
              driver_entity: [],
              driverEntityLoading: false,
            },() => {
              if(callback) {
                callback();
              }
            });
          });

      });
    });     
  }
  
  getCo2Ev = () => {
    const { accessToken } = this.context;
    
    this.setState({
      co2EvLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
  
        ldsApi.read('/analytic/co2_ev_saving/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              co2_ev_saving: data.result.co2e_saving ? data.result.co2e_saving : 0,
              co2_emission: data.result.co2_emission ? data.result.co2_emission : 0,
              co2EvLoading: false,
            });
          }, (error, type) => {
            this.setState({
              co2_ev_saving: 0,
              co2EvLoading: false,
            });
          });
  
      });
    });     
  }

  overviewDataApi = (callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
        overview_type: 'overview'
      };

        ldsApi.read('/analytic/overview/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              overview_data: data.result,
              do_per_size_total: data.result && data.result.do_per_size_total,
              job_profile_total: data.result && data.result.job_profile_total,
              isLoadingClient: false,
            }, ()=> {
              if(callback) {
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              overview_data: [],
              do_per_size_total: '',
              job_profile_total: '',
              isLoadingClient: false,
            },() => {
              if(callback) {
                callback();
              }   
            });
          });

      });
    });
  }
// API END ==================


  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.co2EvLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.co2_emission && this.state.co2_emission != 0? parseFloat(this.state.co2_emission).toFixed(2) : '0.00')+'kg'}</div>
        <div className="fs-6 ps-2 font-medium">CO2 Emissions</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.avg_capacity_utalization && this.state.overview_data.avg_capacity_utalization != 0 ? parseFloat(this.state.overview_data.avg_capacity_utalization).toFixed(2) : '0.00')+'%'} </div>
        <div className="fs-6 ps-2 font-medium">Capacity utilisation</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.driverEntityLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.average_distanec_kilometer && this.state.driver_entity.average_distanec_kilometer != 0 ? parseFloat(this.state.driver_entity.average_distanec_kilometer).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')+'km'}</div>
        <div className="fs-6 ps-2 font-medium">Avg. distance per delivery</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.driverEntityLoading? <CircularProgress size={35} className="spinner-card" /> :  (this.state.driver_entity.job_optimised && this.state.driver_entity.job_optimised != 0 ? parseFloat(this.state.driver_entity.job_optimised).toFixed(2) : '0.00')+'%'}</div>
        <div className="fs-6 ps-2 font-medium">Jobs Optimised</div>
      </Card>
    </Col>
  </Row>
  }

  VehicleEfficiency = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 analytic-overview-card h-unset w-100 d-flex justify-content-start align-items-center">
        <div className="fs-6 ps-2 font-medium">Vehicle Efficiency</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">CO2e by EV Usage <img src={CO2} style={{width:'40px', height:'40px'}}/></div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.co2EvLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.co2_emission ? parseFloat(this.state.co2_emission).toFixed(2) : '0.00')+'kg'}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><span data-tooltip="Grid Emission Factor of 0.4057kg CO2/kWh"><img src={BoxImportant} style={{width:'20px', height:'20px'}}/></span></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">CO2e Savings <img src={NaturalFood} style={{width:'40px', height:'40px'}}/></div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color mt-4">{this.state.co2EvLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.co2_ev_saving && this.state.co2_ev_saving != 0? parseFloat(this.state.co2_ev_saving).toFixed(2) : '0.00')+ 'kg'} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><span data-tooltip="In comparison to ICE vehicles"><img src={BoxImportant} style={{width:'20px', height:'20px'}}/></span></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Capacity Utilisation</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.avg_capacity_utalization ? parseFloat(this.state.overview_data.avg_capacity_utalization).toFixed(2) : '0.00')+'%'}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={FullVersion} style={{width:'45px', height:'45px'}}/></div>
      </Card>
    </Col>
  </Row>
  }

  RouteOptimization = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 analytic-overview-card h-unset w-100 d-flex justify-content-start align-items-center">
        <div className="fs-6 ps-2 font-medium">Route Optimisation</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Jobs Optimised</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.driverEntityLoading ? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.job_optimised && this.state.driver_entity.job_optimised != 0 ? parseFloat(this.state.driver_entity.job_optimised).toFixed(2) : '0.00')+'%'}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={FullVersion} style={{width:'45px', height:'45px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Avg. Dist per Job</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.driverEntityLoading ? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.average_distanec_kilometer && this.state.driver_entity.average_distanec_kilometer != 0 ? parseFloat(this.state.driver_entity.average_distanec_kilometer).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')+'km'} </div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Optimisation Accuracy</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.driverEntityLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.optimisation_accuracy ? parseFloat(this.state.driver_entity.optimisation_accuracy).toFixed(2) : '0.00')+'%'}</div>
      </Card>
    </Col>
  </Row>
  }

  DriverEfficiency = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 analytic-overview-card h-unset w-100 d-flex justify-content-start align-items-center">
        <div className="fs-6 ps-2 font-medium">Driver Efficiency</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative  analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Avg. Jobs per Day</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.job_per_driver ? parseFloat(this.state.overview_data.job_per_day).toFixed(2) : '0.00')}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={EditDeliveryTerms} style={{width:'45px', height:'45px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Avg. Daily Working Hour Per Driver</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.driverEntityLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.daily_working_duration ? this.state.driver_entity.daily_working_duration : '0.00')+'hrs'} </div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative  analytic-overview-card h-180px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Avg. Jobs per Driver</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.driverEntityLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.driver_entity.job_per_driver ? parseFloat(this.state.driver_entity.job_per_driver).toFixed(2) : '0.00')}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={EditDeliveryTerms} style={{width:'45px', height:'45px'}}/></div>
      </Card>
    </Col>
  </Row>
  }

  JobClient = () => {
    return <Row className="p-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
    <Card className="mx-3 analytic-overview-card h-unset w-100 d-flex justify-content-start align-items-center">
        <div className="fs-6 ps-2 font-medium">Jobs/ Clients</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-200px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Job Profile</div>
            {this.state.isLoadingClient? 
              <CircularProgress size={35} className="spinner-card" /> :
              <NormalPieChartV2 showLable={this.state.lockCapture} legendMarginTop={8} height={100} cx={0} cy={0} data={this.state.overview_data.job_profile}/>
            }
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card h-200px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 ps-2 font-medium">Greener Choice <img src={Checkmark} style={{width:'35px', height:'35px'}}/></div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color mt-4">{this.state.grennerLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.greener_count > 0 ? this.state.greener_count : 0)} </div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card h-200px w-100 d-flex flex-column align-items-center">
        <div className="fs-5 font-medium ps-2 pb-2">Vehicle Usage</div>
        {this.state.isLoadingClient? 
          <CircularProgress size={35} className="spinner-card" /> :
          <NormalPieChart showLable={this.state.lockCapture} data={this.state.overview_data.job_complete_driver} layout={'vertical'} />
        }
      </Card>
    </Col>
  </Row>
  }
  
  handleDownload = () => {
      
    this.setState({
      lockCapture: true,     
    },() => {
      const input = document.getElementById('container-id');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      const btnElement = document.getElementById('download-pdf');
      const calenderElement = document.getElementById('calendar-element');
      
      if (btnElement) {
        btnElement.style.display = 'none';
      }
      
      if (calenderElement) {
        calenderElement.style.display = 'none';
      }
      
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;
      
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('Sustainability.pdf');
        
        if (btnElement) {
          btnElement.style.display = '';
        }
        
        if (calenderElement) {
          calenderElement.style.display = '';
        }
        
        this.setState({
          lockCapture: false 
        });
      });
    });
  };

  render() {
    return <Container fluid id="container-id">
        <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex align-items-center">
          <div className="fs-4 font-medium">Analytics for {moment(this.state.startDate).format(apiUtil.getImportDateFormat())} to {moment(this.state.endDate).format(apiUtil.getImportDateFormat())}</div>
        </Col>
        <Col xs={12} md={6} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center max-xs-width-control" style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.distanceLoading ? 'none': 'auto' }}>
        <div id="calendar-element">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                // Define your two dates
                const date1 = moment(range.startDate);
                const date2 = moment(range.endDate);
                
                // Calculate the difference in years
                const differenceInYears = date2.diff(date1, 'years', true);

                if(differenceInYears > 1){
                  apiUtil.toast('Date range can not more than one year.',  'warning', 'error'); 
                  return;                  
                }
                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </div>
          <Button className="upload-style"
          id="download-pdf"
          onClick={() => {
            this.handleDownload();
          }}
          ><FontAwesomeIcon icon={faDownload} />
          </Button>
        </Col>
      </Row>
      {this.overviewHeader()}
      {this.VehicleEfficiency()}
      {this.RouteOptimization()}
      {this.DriverEfficiency()}
      {this.JobClient()}
    </Container>;
  }
}

export default Sustainability;