import React, { Component } from 'react';

import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import wmsApi from "../../../api/wmsApi.jsx";
import Api from "../../../api/api.jsx";
import { 
  Box,
  Grid,
  Chip,
  Divider,
  Input,
  CircularProgress,
} from '@material-ui/core';
import CustomGoogleMapSearch from "../../../components/GoogleMap/CustomGoogleMapSearch.jsx";
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomTabs from '../../../components/Tabs/CustomTabs.jsx'
import CustomCheckbox from "../../../components/CustomFields/CustomCheckbox.jsx";
import CompanyAddressList from './CompanyAddressList.jsx';
import CompanyDepartmentList from './CompanyDepartmentListDetailUi.jsx';
import CustomerAdminAccountList from './CustomerAdminAccountList.jsx';
import { Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomListItem from '../../../components/List/CustomListItem.jsx';
import {RoleAccessService} from '../../../data/role-access'; 
import CustomSwitch from '../../../components/CustomFields/CustomSwitch.jsx';

const exceptThisSymbols = ["e", "E", "+", "-"];
export class CustomerPreviewDialog extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (props.id && props.id > 0) ? props.id : 0;
    let tab = (props.tab && props.tab > 0) ? props.tab : 0;

    this.state = {
        tabsValue: tab,

        isLoading: true,

        id: id,

        company_name: '',
        company_nameValidated: false,
        company_code: '',
        billing_email: '',
        contact_no1: '',
        billing_frequency: '',
        company_email: '',
        company_codeValidated: false,
        billing_emailValidated: false,
        contact_no1Validated: false,
        is_active: true,
        addresses: [],
        billing_emails: [],
        departments: [],
        admin: [],

        standard_rate: {},
        location_surcharge: {},
        parckage_surcharge: {},
        day_surcharge: {},
        service_surcharge: {},
        driver_standard_rate: {},
        driver_location_surcharge: {},
        driver_parckage_surcharge: {},
        driver_day_surcharge: {},
        driver_service_surcharge: {},
        subcon_standard_rate: {},
        subcon_location_surcharge: {},
        subcon_parckage_surcharge: {},
        subcon_day_surcharge: {},
        subcon_service_surcharge: {},

        project_rate: {},
        project_location_surcharge: {},
        project_parckage_surcharge: {},
        project_day_surcharge: {},
        project_service_surcharge: {},
        driver_project_rate: {},
        driver_project_location_surcharge: {},
        driver_project_parckage_surcharge: {},
        driver_project_day_surcharge: {},
        driver_project_service_surcharge: {},
        subcon_project_rate: {},
        subcon_project_location_surcharge: {},
        subcon_project_parckage_surcharge: {},
        subcon_project_day_surcharge: {},
        subcon_project_service_surcharge: {},
        openStndClient: false,
        openStndDriver: false,
        openStndSubcon: false,
        openPrjClient: false,
        openPrjDriver: false,
        openPrjSubcon: false,

        standand_per_location: {},
        driver_standand_per_location: {},
        subcon_standand_per_location: {},
        project_per_location: {},
        driver_project_per_location: {},
        subcon_project_per_location: {},
    }
  }
  

  componentDidMount() {
    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data) => {
          let addresses = (data.customer_addresses && data.customer_addresses.length > 0) ? data.customer_addresses : [];
          if(addresses && addresses.length > 0){
            addresses = addresses.map((item, i) => {
              item['uuid'] = uuid();
              item['address_line1'] = item.address_line1;
              item['postal_code'] = item.postal_code;
              item['remove'] = false;
              item['is_main'] = item.is_main;
              return item;
            });
          }

          addresses.push({
            uuid: uuid(),
            address_line1: '',
            postal_code: '',
            is_main : false,
            remove: false
          })
          
          let billing_emails = data.company_email? data.company_email.split(","): []
          if(billing_emails && billing_emails.length > 0){
            billing_emails = billing_emails.map((email, i) => {
              let obj = {
                email: email,
                invalid: false                
              }
              return obj;
            });
          }
          
          billing_emails.push({
            email: '',
            invalid : false,
          })
  
          let departments = (data.departments && data.departments.length > 0) ? data.departments : [];
          if(departments && departments.length > 0){
            departments = departments.map((item, i) => {
              item['uuid'] = uuid();
              item['department_name'] = item.name;
              item['department_id'] = item.id;
              item['delete'] = false;
  
              if(item.contact_people && item.contact_people.length > 0){
                item.contact_people = item.contact_people.map((subItem, j) => {
                  subItem['uuid'] = uuid();
                  subItem['delete'] = false;
                  return subItem;
                });
              }
  
              return item;
            });
          }
          // departments.push({
          //   uuid: uuid(),
          //   department_name: '',
          //   department_id: '',
          //   delete : false,
          //   contact_people: {}
          // })

          let customer_rates = (data.customer_rates && data.customer_rates.length > 0) ? data.customer_rates : [];
             
            if (customer_rates.length > 0) {
              let standard_rate = {}
              let location_surcharge = {}
              let parckage_surcharge = {}
              let day_surcharge = {}
              let service_surcharge = {}
              let driver_standard_rate = {}
              let driver_location_surcharge = {}
              let driver_parckage_surcharge = {}
              let driver_day_surcharge = {}
              let driver_service_surcharge = {}
              let subcon_standard_rate = {}
              let subcon_location_surcharge = {}
              let subcon_parckage_surcharge = {}
              let subcon_day_surcharge = {}
              let subcon_service_surcharge = {}

              let project_rate = {}
              let project_location_surcharge = {}
              let project_parckage_surcharge = {}
              let project_day_surcharge = {}
              let project_service_surcharge = {}
              let driver_project_rate = {}
              let driver_project_location_surcharge = {}
              let driver_project_parckage_surcharge = {}
              let driver_project_day_surcharge = {}
              let driver_project_service_surcharge = {}
              let customer_rate_id = null
              let driver_rate_id = null
              let subcon_project_rate = {}
              let subcon_project_location_surcharge = {}
              let subcon_project_parckage_surcharge = {}
              let subcon_project_day_surcharge = {}
              let subcon_project_service_surcharge = {}
              let subcon_rate_id = null
              customer_rates.forEach(c_rate=> {
                Object.keys(c_rate).forEach(function(pkey) {
                  if(pkey != 'application_company_id' && pkey != 'created_at' && pkey != 'customer_id' && pkey != 'id' && pkey != 'rate_type' && pkey != 'updated_at' && pkey != 'parckage_surcharge' && pkey != 'project_parckage_surcharge') {
                    c_rate[pkey] = c_rate[pkey] ? JSON.parse(c_rate[pkey]) : {}
                    Object.keys(c_rate[pkey]).forEach(function(key) {
                      if (!isNaN(parseFloat(c_rate[pkey][key]))) {
                        c_rate[pkey][key] = parseFloat(c_rate[pkey][key]);
                        c_rate[pkey][key] = c_rate[pkey][key].toFixed(2);
                      }
                    });
                  }
                });

                if (c_rate.rate_type == 'customer') {
                  standard_rate = c_rate.standard_rate ? c_rate.standard_rate : {}
                  parckage_surcharge = c_rate.parckage_surcharge ? JSON.parse(c_rate.parckage_surcharge) : {}
                  location_surcharge = c_rate.location_surcharge ? c_rate.location_surcharge : {}
                  service_surcharge = c_rate.service_surcharge ? c_rate.service_surcharge : {}
                  day_surcharge = c_rate.day_surcharge ? c_rate.day_surcharge : {}
                  project_rate = c_rate.project_rate ? c_rate.project_rate : {}
                  project_parckage_surcharge = c_rate.project_parckage_surcharge ? JSON.parse(c_rate.project_parckage_surcharge) : {}
                  project_location_surcharge = c_rate.project_location_surcharge ? c_rate.project_location_surcharge : {}
                  project_service_surcharge = c_rate.project_service_surcharge ? c_rate.project_service_surcharge : {}
                  project_day_surcharge = c_rate.project_day_surcharge ? c_rate.project_day_surcharge : {}
                  customer_rate_id = c_rate.id 
                } else if (c_rate.rate_type == 'driver') {
                  driver_standard_rate = c_rate.standard_rate ? c_rate.standard_rate : {}
                  driver_parckage_surcharge = c_rate.parckage_surcharge ? JSON.parse(c_rate.parckage_surcharge) : {}
                  driver_location_surcharge = c_rate.location_surcharge ? c_rate.location_surcharge : {}
                  driver_service_surcharge = c_rate.service_surcharge ? c_rate.service_surcharge : {}
                  driver_day_surcharge = c_rate.day_surcharge ? c_rate.day_surcharge : {}
                  driver_project_rate = c_rate.project_rate ? c_rate.project_rate : {}
                  driver_project_parckage_surcharge = c_rate.project_parckage_surcharge ? JSON.parse(c_rate.project_parckage_surcharge) : {}
                  driver_project_location_surcharge = c_rate.project_location_surcharge ? c_rate.project_location_surcharge : {}
                  driver_project_service_surcharge = c_rate.project_service_surcharge ? c_rate.project_service_surcharge : {}
                  driver_project_day_surcharge = c_rate.project_day_surcharge ? c_rate.project_day_surcharge : {}
                  driver_rate_id = c_rate.id
                } else if (c_rate.rate_type == 'subcon') {
                  subcon_standard_rate = c_rate.standard_rate ? c_rate.standard_rate : {}
                  subcon_parckage_surcharge = c_rate.parckage_surcharge ? JSON.parse(c_rate.parckage_surcharge) : {}
                  subcon_location_surcharge = c_rate.location_surcharge ? c_rate.location_surcharge : {}
                  subcon_service_surcharge = c_rate.service_surcharge ? c_rate.service_surcharge : {}
                  subcon_day_surcharge = c_rate.day_surcharge ? c_rate.day_surcharge : {}
                  subcon_project_rate = c_rate.project_rate ? c_rate.project_rate : {}
                  subcon_project_parckage_surcharge = c_rate.project_parckage_surcharge ? JSON.parse(c_rate.project_parckage_surcharge) : {}
                  subcon_project_location_surcharge = c_rate.project_location_surcharge ? c_rate.project_location_surcharge : {}
                  subcon_project_service_surcharge = c_rate.project_service_surcharge ? c_rate.project_service_surcharge : {}
                  subcon_project_day_surcharge = c_rate.project_day_surcharge ? c_rate.project_day_surcharge : {}
                  subcon_rate_id = c_rate.id
                }
              })
              
              this.setState ({
                standard_rate,
                project_rate,
                parckage_surcharge,
                location_surcharge,
                service_surcharge,
                day_surcharge,
                project_parckage_surcharge,
                project_location_surcharge,
                project_service_surcharge,
                project_day_surcharge,
                driver_standard_rate,
                driver_project_rate,
                driver_parckage_surcharge,
                driver_location_surcharge,
                driver_service_surcharge,
                driver_day_surcharge,
                driver_project_parckage_surcharge,
                driver_project_location_surcharge,
                driver_project_service_surcharge,
                driver_project_day_surcharge,
                subcon_standard_rate,
                subcon_parckage_surcharge ,
                subcon_location_surcharge,
                subcon_service_surcharge,
                subcon_day_surcharge,
                subcon_project_rate,
                subcon_project_parckage_surcharge,
                subcon_project_location_surcharge,
                subcon_project_service_surcharge,
                subcon_project_day_surcharge,
                customer_rate_id,
                driver_rate_id,
                subcon_rate_id,
              })
        }

          let admin = (data.admins && data.admins.length > 0) ? data.admins : [];
          if(admin && admin.length > 0) {
            admin = admin.map((item, i) => {
              item['uuid'] = uuid();
              item['first_name'] = item.first_name ;
              item['last_name'] = item.last_name;
              item['contact_no'] = item.contact_no && item.contact_no.length == 8 ? "+65" + item.contact_no: item.contact_no;
              item['email'] = item.email;
              item['is_not_allowed'] = item.is_not_allowed;
              item['remove'] = false;
              item['isNew'] = false;
              return item;
            });
          }
          admin.push({
            uuid: uuid(),
            first_name: '',
            last_name: '',
            contact_no: '',
            email : '',
            is_not_allowed : false,
            remove: false,
            isNew: true
          })

          this.setState({
            company_name: data.company_name,
            company_code: data.company_code,
            contact_no1: data.contact_no1 && data.contact_no1.length == 8 ? "+65" + data.contact_no1 : data.contact_no1,
            billing_email: data.company_email? data.company_email: '' ,
            is_active: data.is_active,
            billing_frequency: data.billing_frequency? data.billing_frequency: '',
            addresses: addresses,
            billing_emails: billing_emails,
            departments: departments,
            admin: admin,
          });
        });
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.read('customers/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.customer) ? data.customer : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */

  /* API */
  callUpdateApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

      let customer_rate = [{
        standard_rate : JSON.stringify(this.state.standard_rate),
        parckage_surcharge : JSON.stringify(this.state.parckage_surcharge),
        location_surcharge : JSON.stringify(this.state.location_surcharge),
        day_surcharge : JSON.stringify(this.state.day_surcharge),
        service_surcharge : JSON.stringify(this.state.service_surcharge),
        project_rate : JSON.stringify(this.state.project_rate),
        project_parckage_surcharge : JSON.stringify(this.state.project_parckage_surcharge),
        project_location_surcharge : JSON.stringify(this.state.project_location_surcharge),
        project_day_surcharge : JSON.stringify(this.state.project_day_surcharge),
        project_service_surcharge : JSON.stringify(this.state.project_service_surcharge),
        id: this.state.customer_rate_id,
        rate_type: 'customer'
      },
      {
        standard_rate : JSON.stringify(this.state.driver_standard_rate),
        parckage_surcharge : JSON.stringify(this.state.driver_parckage_surcharge),
        location_surcharge : JSON.stringify(this.state.driver_location_surcharge),
        day_surcharge : JSON.stringify(this.state.driver_day_surcharge),
        service_surcharge : JSON.stringify(this.state.driver_service_surcharge),
        project_rate : JSON.stringify(this.state.driver_project_rate),
        project_parckage_surcharge : JSON.stringify(this.state.driver_project_parckage_surcharge),
        project_location_surcharge : JSON.stringify(this.state.driver_project_location_surcharge),
        project_day_surcharge : JSON.stringify(this.state.driver_project_day_surcharge),
        project_service_surcharge : JSON.stringify(this.state.driver_project_service_surcharge),
        rate_type: 'driver',
        id: this.state.driver_rate_id,
      },
      {
        standard_rate : JSON.stringify(this.state.subcon_standard_rate),
        parckage_surcharge : JSON.stringify(this.state.subcon_parckage_surcharge),
        location_surcharge : JSON.stringify(this.state.subcon_location_surcharge),
        day_surcharge : JSON.stringify(this.state.subcon_day_surcharge),
        service_surcharge : JSON.stringify(this.state.subcon_service_surcharge),
        project_rate : JSON.stringify(this.state.subcon_project_rate),
        project_parckage_surcharge : JSON.stringify(this.state.subcon_project_parckage_surcharge),
        project_location_surcharge : JSON.stringify(this.state.subcon_project_location_surcharge),
        project_day_surcharge : JSON.stringify(this.state.subcon_project_day_surcharge),
        project_service_surcharge : JSON.stringify(this.state.subcon_project_service_surcharge),
        rate_type: 'subcon',
        id: this.state.subcon_rate_id,
      }
    ]
      let data = {
        company_name: this.state.company_name ? this.state.company_name.trim() : this.state.company_name,
        company_code:  this.state.company_code ? this.state.company_code.trim() : this.state.company_code ,
        is_active: this.state.is_active,
        addresses: this.state.addresses,
        departments: this.state.departments,
        contact_no1: this.state.contact_no1 && this.state.contact_no1.length == 8 ? "+65" + this.state.contact_no1.trim() : this.state.contact_no1 ? this.state.contact_no1.trim() : this.state.contact_no1,
        company_email: this.state.billing_emails.filter((e) => e.email != '').length > 0 ? this.state.billing_emails.filter((e) => e.email != '').map((obj) => obj.email).join(',') : '',
        customer_rate: customer_rate,
        billing_frequency: this.state.billing_frequency
      };
    this.setState({
      isLoading: true
    }, () => {
      wmsApi.update('customers/' + id, data, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
            apiUtil.toast('Successfully Updated', 'check_circle');
            let filter_customer = this.state.admin.filter(x => x.id);
            
            if(filter_customer.length > 0){
              let is_update_data = filter_customer.filter(x=> !x.remove);
              if(is_update_data.length > 0 ) {
                is_update_data.map((cus_info)=> {
                  this.updateCustomerAccount(cus_info, (data) => {
                    if(data.status == false) {
                      delete data.status;
                      let error = JSON.stringify(data);
                      apiUtil.toast(error, 'check_circle', 'error');
                    } else {
                      this.setState({
                        isLoading: false
                      }, () => {
                        apiUtil.toast('Customer Admin Successfully updated', 'check_circle');
                      });
                    }
                  })
                })
                
               
              } 
                let is_delete_data = filter_customer.filter(x=> x.remove);
                if(is_delete_data.length > 0 ) {
                  is_delete_data.map((cus_info)=> {
                  this.deleteCustomerAccount(cus_info, (data) => {
                    apiUtil.toast(cus_info.email + 'has been successfully deleted', 'check_circle', 'error');
                  })
                })
              }
              
            } 
            let new_customer = this.state.admin.filter(x => !x.id  && !x.remove);
            if (new_customer.length > 0) {
              new_customer.map((cus_info) => {
                let account_data = {...cus_info,
                  "is_default_password": true, 
                  "password": "password", 
                  "is_lds_only": true, 
                  "is_own_data_only": true, 
                  "is_system_role": true,
                  "is_customer_admin": true,
                  "customer_id": this.state.id.toString()
                  };
                  let email = cus_info.email
                this.createCustomerAccount(account_data, (data) => {
                  if(data.status == false) {
                    apiUtil.toast(email + " exist. Please use another email.", 'check_circle', 'error');
                  } else {
                      apiUtil.toast(email + "'s Account has been successfully created", 'check_circle');
                  }
                  
                })
              })
              this.setState({
                isLoading: false
              });
            }
            });
              this.props.onClose();
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(
                error, 
                'check_circle'
              )
             
            });
            if(callback){
              callback(null);
            }
          });
      });
    });
  }
  /* END API */
  
  handleAddBillingEmail = ( ) => {
    let billing_emails = this.state.billing_emails;
    billing_emails.push({
      email: '',
      invalid: false      
    })
    this.setState({
      billing_emails: billing_emails
    })
  }
  
  handleRemoveBillingEmail = (index) => {
    let billing_emails = this.state.billing_emails;

    billing_emails.splice(index, 1)

    this.setState({
      billing_emails: billing_emails
    })
  }
  
  handleInputBillingEmailChange = (e, index) => {
    const { name, value } = e.target;
    let billing_emails = this.state.billing_emails
    billing_emails[index].email = value
    billing_emails[index].invalid = false
    this.setState({
      billing_emails: billing_emails
    })
  };

  handleAddAddress = ( ) => {
    let address = this.state.addresses;
    address.push({
      uuid:   uuid(),
      address_line1: '',
      postal_code: '',
      is_main: false,
      remove: false
  })
  this.setState({
    addresses: address
  })
  }

  handleDepartment = ( ) => {
    let departments = this.state.departments;
    departments.push({
      uuid: uuid(),
      department_name: '',
      department_id: '',
      delete : false,
      contact_people: {}
    })
  this.setState({
    departments: departments
  })
  }

  handleAddAdmin = ( ) => {
    let admin = this.state.admin;
    admin.push({
      uuid: uuid(),
      first_name: '',
      last_name: '',
      contact_no: '',
      email : '',
      is_not_allowed : false,
      remove: false,
      isNew: true
    })
  this.setState({
    admin: admin
  })
  }

  handleRemoveAddress = (id) => {
    let address = this.state.addresses;

    address.forEach((data,index) => {
      if(data.uuid == id) {
        if (data.id) {
          data.remove = true
        } else {
          address.splice(index, 1)
        }
        
      }
    })
    // let objIndex = address.findIndex(obj => obj.uuid == id);
    // address[objIndex].remove = true

  this.setState({
    addresses: address
  })
  }

  handleRemoveDepartment = (id) => {
    let departments = this.state.departments;

    departments.forEach((data,index) => {
      if(data.uuid == id) {
        if (data.id) {
          data.delete = true
        } else {
          departments.splice(index, 1)
        }
        
      }
    })

    // let objIndex = departments.findIndex( obj => obj.uuid == id);
    // departments[objIndex].delete = true

  this.setState({
    departments
  })
  }

  handleRemoveAdmin = (id) => {
    let admin = this.state.admin;
    admin.forEach((data,index) => {
      if(data.uuid == id) {
        if (data.id) {
          data.remove = true
        } else {
          admin.splice(index, 1)
        }
        
      }
    })

  this.setState({
    admin: admin
  })
  }

  handleInputAdminChange = (e, index) => {
    const { name, value } = e.target;
    let admins = this.state.admin
    admins.forEach(ad => {
      if(ad.uuid == index) {
        ad[name] = value.trim().length > 0 ? value : ''
      }
    })
    this.setState({
      admin: admins
    })
  };

  handleInputAddressChange = (e, index) => {
    const { name, value } = e.target;
    let address = this.state.addresses
    address.forEach(ad => {
      if(ad.uuid == index) {
        ad[name] = value
      }
    })
    this.setState({
      addresses: address
    })
  };

  handleInputPlaceChange = (place, index) => {
    const value = place;
    let address = this.state.addresses
    address.forEach(ad => {
      if(ad.uuid == index) {
        ad["address_line1"] = value
      }
    })
    this.setState({
      addresses: address
    })
  };

  handleInputDepartmentChange = (e, index) => {
    const { name, value } = e.target;
    let departments = this.state.departments
    departments.forEach(ad => {
      if(ad.uuid == index) {
        ad[name] = value
      }
    })
    this.setState({
      departments: departments
    })
  };

  mainAddressCheck = (id, checked) => {
    let address =  [...this.state.addresses];
    address.map(dd => {
      if(dd.uuid == id) {
         dd.is_main = true;
      } else {
        dd.is_main = false;
      }
    })
    this.setState({
      addresses: address
    })
  }

  checkIsMainExists = (addresses) => {
    let addressArr = addresses;
    if(addressArr && addressArr.length > 0){
        let isMain = addressArr.filter(x => x.remove != true ).filter(d => d.is_main == true);

        return (isMain && isMain.length > 0) ? true : false;
    }

    return false;
  }

  handleSubmit = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      company_nameValidated: false,
      company_codeValidated: false,
      contact_no1Validated: false,
      billing_emailValidated: false,
    });

    if(this.state.company_name === ''){
      this.setState({
        company_nameValidated: true
      });
    }
    if(this.state.company_code === ''){
      this.setState({
        company_codeValidated: true
      });
    }

    if( this.state.contact_no1 != '' && this.state.contact_no1 != '+' && this.state.contact_no1 != null && !apiUtil.phoneValidation(this.state.contact_no1)) {
      this.setState({
        contact_no1Validated: true
      })
      return
    }

    //if (this.state.billing_email !== '' && !apiUtil.emailValidation(this.state.billing_email)) {
    // this.setState({
    //  billing_emailValidated: true
    // })
    // return
    //}
    
    if(this.state.company_name === '' || this.state.company_code === '') {
      return;
    }

    if(!this.checkIsMainExists(this.state.addresses)){
      this.setState({
        isLoading: false
      }, () => {
        apiUtil.toast('Please choose a primary address for the client!');
      });
      return
    }

    let checkAddress = false; 
    let checkAdmin = false; 
    let checkEmail = false;
    
    this.state.billing_emails.map((dd, index)=> {
      if(dd.email !== '' && !apiUtil.emailValidation(dd.email)) {
        dd.invalid = true;
        checkEmail = true;
      }          
    });
    // let checkDepartment = false; 
    this.state.addresses.filter(ad_data => ad_data.remove != true).map((dd, index)=> {
      if((dd.address_line1 == null || dd.address_line1 == "") && dd.is_main == false && (this.state.addresses.filter(ad_data => ad_data.remove != true).length - 1) == index) {

      } else {
        if(!apiUtil.postalCodeValidation(dd.postal_code)) {
          apiUtil.toast("Address " + ++index + " has invalid postal Code", 'check_circle', 'error');
          return checkAddress = true; 
        } else if (dd.postal_code == null || dd.postal_code == "") {
          apiUtil.toast("Address " + ++index+ "'s postal code cannot be empty", 'check_circle', 'error');
          return checkAddress = true; 
        }

        if(dd.address_line1 == null || dd.address_line1 == "" || dd.address_line1.trim().length == 0) {
          apiUtil.toast("Address " + ++index+ "'s company address is empty", 'check_circle', 'error');
          return checkAddress = true; 
        }
      }
     } 
     ) 

    //  this.state.departments.filter(ad_data => ad_data.delete != true).map((dd, index)=> {
    //   if((dd.department_name == null || dd.department_name == "") && (this.state.departments.filter(ad_data => ad_data.delete != true).length - 1) == index) {

    //   } else {
    //   if(dd.department_name == null || dd.department_name == "") {
    //       apiUtil.toast("Department " + ++index + " name is empty", 'check_circle', 'error');
    //       return checkDepartment = true; 
    //     }
    //   }
    //  } 
    //  ) 

     this.state.admin.filter(ad_data => ad_data.remove != true).map((dd, index)=> {
        if((dd.first_name == null || dd.first_name == "") && (dd.email == null || dd.email == "") && (dd.contact_no == null || dd.contact_no == "") && (this.state.admin.filter(ad_data => ad_data.remove != true).length - 1) == index) {
        
        } else {
          if(dd.first_name == null || dd.first_name == "") {
            apiUtil.toast("Admin " + ++index + " needs a first name", 'check_circle', 'error');
            return checkAdmin = true; 
          } else if(dd.last_name == null || dd.last_name == "") {
            apiUtil.toast("Admin " + ++index + " needs a last name", 'check_circle', 'error');
            return checkAdmin = true; 
          } else if(!apiUtil.phoneValidation(dd.contact_no)) {
            apiUtil.toast("Admin " + ++index + " has an invalid phone number", 'check_circle', 'error');
            return checkAdmin = true; 
          } else if(dd.email == null || dd.email == "") {
            apiUtil.toast("Admin " + ++index + " needs an email", 'check_circle', 'error');
            return checkAdmin = true;
          } else if(!apiUtil.emailValidation(dd.email)) {
            apiUtil.toast("Admin " + ++index+ "'s email format is invalid", 'check_circle', 'error');
            return checkAdmin = true;
          }
        }
     } 
     ) 

      if(checkAdmin == true || checkAddress == true || checkEmail == true) {
          return
      } else {

        let addressesArr = this.state.addresses;
        let lastAddressElement = addressesArr.slice(-1);
        if(lastAddressElement[0]['address_line1'] == null || lastAddressElement[0]['address_line1'] == '') {
          addressesArr.splice(-1,1);
        }
    
        // let departmentsArr = this.state.departments;
        // let lastDepartmentElement = departmentsArr.slice(-1);
        // if(lastDepartmentElement[0]['department_name'] == null || lastDepartmentElement[0]['department_name'] == '') { 
        //   departmentsArr.splice(-1,1);
        // } 
    
        let adminArr = this.state.admin;
        let lastAdminElement = adminArr.slice(-1);
        if(lastAdminElement[0]['email'] == null || lastAdminElement[0]['email'] == '') { 
          adminArr.splice(-1,1);
        }
        
        this.setState({
          addresses: addressesArr,
          departments: this.state.departments,
          admin : adminArr
        }, ()=> {
          this.callUpdateApi(id, ()=> {
            let admin = []
            if( this.state.admin.length == 0) {
              admin.push({
                 uuid: uuid(),
                 first_name: '',
                 last_name: '',
                 contact_no: '',
                 email : '',
                 remove: false,
                 isNew: true
               }) 

               this.setState({
                admin : admin
              })
            }
           
           
          });
        })
    }
  }

  createCustomerAccount = ( row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.createCustomerAccount('admins', row, accessToken)
    .then((result) => {
        apiUtil.parseResult(result, (data) => {
            // this.state.isLoading(false);       
            if(callback) {
                callback(data.result);
            }
        }, (error, type) => {
            // this.state.isLoading(false);
            if(callback) {
              // console.log(error);
                callback({status: false, ...error});
            }
        });
    }).catch(
        error => {
          // console.log(error)
        }
    )
  }
  updateCustomerAccount = ( row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.updateCustomerAccount('admins/'+ row.id, row, accessToken)
    .then((result) => {
        apiUtil.parseResult(result, (data) => {
            if(callback) {
                callback(data.result);
            }
        }, (error, type) => {
            // console.log("error", error)
            if(callback) {
                callback({status: false, ...error});
            }
        });
    }).catch(
        error => console.log(error)
    )
  }
  deleteCustomerAccount = ( row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.deleteCustomerAccount('admins/'+ row.id, {}, accessToken)
    .then((result) => {
      if(callback) {
        callback(result);
      }
    }).catch(
        error => {
          console.log(error);
          callback({status: false, ...error});
        }
       
    )
  }

  /* FORM */
  inlineRowTemplate = (component1, component2) => {
    return <Box>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12} md={3}>{component1}</Grid>
            </Box>
            <Box clone pb={1}>
                <Grid item xs={12} md={true}>{component2()}</Grid>
            </Box>
        </Grid>
    </Box>
  }

  setOverview = () => {

    const {currentRole} = this.context
    
    return <Container fluid className="overflow-auto"  style={{minWidth:'800px'}}>
          <hr className="hr_style_width"/>
          <Box style={{pointerEvents: (currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Row className="py-3">
                <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Company Name'} className="w-50 text-end px-2"/>
                  <CustomInput className="w-100" 
                      value={this.state.company_name}
                      error={this.state.company_nameValidated}
                      onChange={(e) => {
                        this.setState({
                          company_name: e.target.value
                        })
                      }}
                        />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Code'} className="w-50 text-end px-2"/>
                  <CustomInput className="w-100" 
                      value={this.state.company_code}
                      error={this.state.company_codeValidated}
                      onChange={(e) => {
                        this.setState({
                          company_code: e.target.value
                        })
                      }}
                       />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Status'} className="w-25 text-end px-2"/>
                  <div className={this.state.is_active ?"p-2 rounded primary-background text-white" : "p-2 rounded danger-background text-white"}>{this.state.is_active ? 'active' : 'inactive'}</div>
                </Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Contact Number'} className="w-50 text-end px-2"/>
                  <CustomInput className="w-100" 
                  type="text"
                  value={this.state.contact_no1}
                  error={this.state.contact_no1Validated}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                      this.setState({
                        contact_no1: e.target.value
                      })
                  }}
                  />
                </Col>
                <Col xs={6} className="d-flex align-items-center">
                  <CustomLabel label={'Billing Frequency'} className="w-50 text-end px-2"/>
                    <select class="form-select w-100" aria-label="Default select example"
                      value={this.state.billing_frequency}
                      onChange={(e) => {
                        this.setState({
                          billing_frequency: e.target.value
                        })
                      }}
                    >
                        <option selected> </option>
                        <option value="1">Weekly</option>
                        <option value="2">Every 2 weeks</option>
                        <option value="3">Monthly</option>
                    </select>
                </Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="align-items-center">
                {this.state.billing_emails.length > 0 && this.state.billing_emails.map((obj, index) => (
                  <Row className="align-items-center mt-2">
                    <Col xs={12} className="d-flex align-items-center position-relative">
                      <CustomLabel label={'Billing Email'} className="w-50 text-end px-2"/>
                      <CustomInput className="w-100" 
                      value={obj.email}
                      error={obj.invalid}
                      onChange={(e) => this.handleInputBillingEmailChange(e, index)}
                       />
                      {index == (this.state.billing_emails.length - 1) ?
                      <span onClick={()=> this.handleAddBillingEmail()} style={{cursor:'default',color:'#007CDE'}} className="increase-icon"><FontAwesomeIcon icon={faPlusCircle}/> Add new</span>
                      : 
                      <span onClick={()=> this.handleRemoveBillingEmail(index)} style={{cursor:'default'}} className="decrease-icon"><FontAwesomeIcon icon={faXmarkCircle} style={{color:'#ff0000'}}/></span>}
                    </Col>
                  </Row>
                  ))}
                </Col>
                <Col lg={7} md={8} className="d-flex align-items-center">
                  <CustomLabel label={'Disable Pick Up Client Payout'} className="w-50 text-end px-2"/>
                  <CustomSwitch 
                  checked={this.state.service_surcharge.disable_client_payout ? this.state.service_surcharge.disable_client_payout : false}
                  onChange={(isChecked) => {
                    let service_surcharge = this.state.service_surcharge
                    let project_service_surcharge = this.state.project_service_surcharge
                    service_surcharge.disable_client_payout = isChecked
                    project_service_surcharge.disable_client_payout = isChecked
                    this.setState({
                      service_surcharge: service_surcharge,
                      project_service_surcharge: project_service_surcharge
                    })
                  }}
                  />      
                </Col>
            </Row>
            <hr className="hr_style_width"/>
            {this.state.addresses.length > 0 && this.state.addresses.filter(ad_data => ad_data.remove != true).map((address, index) => (
              <Row className="py-3 d-flex align-items-center">
              <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Company Address'} className="w-50 text-end px-2"/>
                  {/* <CustomGoogleMapSearch
                  placeholder={"Delivery Address"}
                  value={address.address_line1} 
                  name="address_line1"
                  // required={true}
                  onKeyDown={(e) => {
                    e.target.value = e.target.value.trim().length === 0 ? '' : e.target.value;
                  }} 
                  onChange={(e) => this.handleInputAddressChange(e, address.uuid)}
                  onPlacesChanged={(place, e) => {
                    let address = apiUtil.getGmapAddress(place);
                    this.handleInputPlaceChange(address, address.uuid)
                  }}
                /> */}
                  <input className="form-control w-100" value={address.address_line1} name="address_line1" onKeyDown={(e) => {
                    e.target.value = e.target.value.trim().length === 0 ? '' : e.target.value;
                  }} 
                  onChange={(e) => this.handleInputAddressChange(e, address.uuid)}/>
              </Col>
              <Col xs={3} className="d-flex align-items-center">
                  <CustomLabel label={'Postal Code'} className="w-75 text-end px-2"/>
                  <input className="form-control w-100" type="number" value={address.postal_code} name="postal_code" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e) => this.handleInputAddressChange(e, address.uuid)}/>
              </Col>
              {index == this.state.addresses.filter(data => data.remove != true).length - 1 ?
              <Col xs={3} className="d-flex align-items-center">
                  <input type="checkBox" checked={address.is_main} onChange={(checked)=> this.mainAddressCheck(address.uuid, checked)}/>
                  <span onClick={()=> this.handleAddAddress()} style={{cursor:'default'}}  className="ps-3 d-flex align-items-center"><FontAwesomeIcon icon={faPlusCircle} style={{color:'#007CDE'}}/> <span className="ps-3" style={{color:'#007CDE'}}>Add new</span></span>
              </Col>
              : 
              <Col xs={3} className="d-flex align-items-center">
                  <input type="checkBox" checked={address.is_main} onChange={(checked)=> this.mainAddressCheck(address.uuid, checked)}/>
                  <span onClick={()=> this.handleRemoveAddress(address.uuid)} style={{cursor:'default'}} className="ps-3"><FontAwesomeIcon icon={faXmarkCircle} style={{color:'#ff0000'}}/></span>
              </Col> 
              }
            </Row>
            ))}
             <hr className="hr_style_width"/>
             <CompanyDepartmentList 
                items={this.state.departments}
                onAddNew={(data) => {
                    this.setState({
                      departments: data,
                    });
                }}
                onEdit={(data) => {
                    this.setState({
                      departments: data,
                    });
                }}
                onRemove={(data) => {
                    this.setState({
                      departments: data,
                    });
                }}
              />
            {/* {this.state.departments.filter(filter => filter.delete != true).map((dd, index) => 
              <Row className="py-3 d-flex align-items-center">
              <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Department'} className="w-50 text-end px-2"/>
                  <input className="form-control w-100" value={dd.department_name} name="department_name" onChange={(e) => this.handleInputDepartmentChange(e, dd.uuid)}/>
              </Col>
              {index == this.state.departments.filter(filter => filter.delete != true).length - 1 ?
              <Col xs={3} className="d-flex align-items-center">
                  <span onClick={()=> this.handleDepartment()} style={{cursor:'default'}}><FontAwesomeIcon icon={faPlusCircle} style={{color:'#007CDE'}}/> <span className="ps-3" style={{color:'#007CDE'}}>Add new</span></span>
              </Col>
              :
              <Col xs={3} className="d-flex align-items-center">
                  <span onClick={()=> this.handleRemoveDepartment(dd.uuid)} style={{cursor:'default'}}><FontAwesomeIcon icon={faXmarkCircle} style={{color:'#ff0000'}}/></span>
              </Col> }
            </Row>
            )} */}
               <hr className="hr_style_width"/>
            {this.state.admin.length > 0 && this.state.admin.filter(ad => ad.remove != true).map((add, index) => (
              <Row className="py-3 d-flex align-items-center">
              <Col xs={4} className="d-flex align-items-center">
                  <CustomLabel label={'Admin Name'} className="w-100 text-end px-2"/>
                  <input className="form-control w-100" value={add.first_name} name="first_name" onChange={(e) => this.handleInputAdminChange(e, add.uuid)}/>
                  <input className="form-control w-100" value={add.last_name} name="last_name" onChange={(e) => this.handleInputAdminChange(e, add.uuid)}/>
              </Col>
              <Col xs={3} className="d-flex align-items-center">
                  <CustomLabel label={'Phone Number'} className="w-50 text-end px-2"/>
                  <input className="form-control w-100" type="text" value={add.contact_no} name="contact_no" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}  onChange={(e) => this.handleInputAdminChange(e, add.uuid)}/>
              </Col>
              <Col xs={3} className="d-flex align-items-center">
                  <CustomLabel label={'Email'} className="w-75 text-end px-2"/>
                  <input className="form-control w-100" disabled={add.isNew == false} value={add.email} name="email" onChange={(e) => this.handleInputAdminChange(e, add.uuid)}/>

              </Col>
              {index == this.state.admin.filter(data => data.remove !== true).length - 1 ?
              <Col xs={2} className="d-flex align-items-center">
                  <span onClick={()=> this.handleAddAdmin()} style={{cursor:'default'}}><FontAwesomeIcon icon={faPlusCircle} style={{color:'#007CDE'}}/> <span className="ps-3" style={{color:'#007CDE'}}>Add new</span></span>
              </Col>
              :
              <Col xs={2} className="d-flex align-items-center">
                  <span onClick={()=> this.handleRemoveAdmin(add.uuid)} style={{cursor:'default'}}><FontAwesomeIcon icon={faXmarkCircle} style={{color:'#ff0000'}}/></span>
                  <span>
                  <CustomCheckbox
                      label={"own order only"}
                      checked={add.is_not_allowed}
                      onChange={(checked) => {
                        let admin = this.state.admin
                        admin.forEach(ad => {
                          if(ad.uuid == add.uuid) {
                            ad['is_not_allowed'] =checked ? true : false
                          }
                        })
                        this.setState(
                          {
                            admin: admin,
                          }
                        );
                      }}
                    />
                  </span>
              </Col> }
            </Row>
            
            ))}
          </Box>
            <Box clone >
                <Grid item xs={12} >
                    <CustomTabs
                        selected={this.state.tabsValue}
                        items={[
                            {
                                text: <CustomLabel label={'Standard Rate'} weight={'bold'} />,
                                children: this.setStandardRate(),
                            },
                            {
                                text: <CustomLabel label={'Project Rate'} weight={'bold'} />,
                                children: this.setProjectRate(),
                            },
                        ]}
                        onChange={(val) => {
                            this.setState({
                                tabsValue: val
                            });
                        }}
                    />
                </Grid>
            </Box>
            
        </Container>
  }
  
  setAddresses = () => {
    return <Box>
        <CompanyAddressList 
            preview={true}
            items={this.state.addresses}
        />
    </Box>
  }

  setStandardRate = () => {
    const { currentRole} = this.context;
    return <>
      <CustomListItem
        text={"For Clients"}
        open={this.state.openStndClient}
        onClick={() => {
          this.setState({
            openStndClient:!this.state.openStndClient,
            openStndDriver: false,
            openStndSubcon: false,
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Box pb={3}>
            <Row className="py-3">
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div className="remove-switch-bottom"><CustomSwitch 
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /> </div>           
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /></div>          
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.standard_rate.xs_next_day ? this.state.standard_rate.xs_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.xs_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.xs_same_day ? this.state.standard_rate.xs_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.xs_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.xs_next_day_3h ? this.state.standard_rate.xs_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.xs_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.xs_express_2h ? this.state.standard_rate.xs_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.xs_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.s_next_day ? this.state.standard_rate.s_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.s_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.s_same_day ? this.state.standard_rate.s_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.s_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.s_next_day_3h ? this.state.standard_rate.s_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.s_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.s_express_2h ? this.state.standard_rate.s_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.s_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.m_next_day ? this.state.standard_rate.m_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.m_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.m_same_day ? this.state.standard_rate.m_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.m_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.m_next_day_3h ? this.state.standard_rate.m_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.m_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.m_express_2h ? this.state.standard_rate.m_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.m_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.l_next_day ? this.state.standard_rate.l_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.l_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.l_same_day ? this.state.standard_rate.l_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.l_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid" 
                value={this.state.standard_rate.l_next_day_3h ? this.state.standard_rate.l_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.l_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.l_express_2h ? this.state.standard_rate.l_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.l_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.standard_rate.pallets_next_day ? this.state.standard_rate.pallets_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.pallets_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.pallets_same_day ? this.state.standard_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.pallets_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.pallets_next_day_3h ? this.state.standard_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.pallets_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.pallets_express_2h ? this.state.standard_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.pallets_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'auto': 'none' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.standard_rate.per_location_next_day ? this.state.standard_rate.per_location_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.per_location_next_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.per_location_same_day ? this.state.standard_rate.per_location_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.per_location_same_day = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.per_location_next_day_3h ? this.state.standard_rate.per_location_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.per_location_next_day_3h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.standard_rate.per_location_express_2h ? this.state.standard_rate.per_location_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let standard_rate = this.state.standard_rate
                  standard_rate.per_location_express_2h = e.target.value
                  this.setState({
                    standard_rate: standard_rate
                  })

                }}
                /></div></Col>
              </Row>
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8 justify-content-center'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid text-middle-pos"
                        value={this.state.parckage_surcharge.base_qty >= 0 ? this.state.parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                        onChange={(e) => {
                          let parckage_surcharge = this.state.parckage_surcharge
                          parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            parckage_surcharge: parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.parckage_surcharge.surcharge_qty >= 0 ? this.state.parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                        onChange={(e) => {
                          let parckage_surcharge = this.state.parckage_surcharge
                          parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            parckage_surcharge: parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid container justifyContent="center" item xs={12} >
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.parckage_surcharge.per_location ? this.state.parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let parckage_surcharge = this.state.parckage_surcharge
                  parckage_surcharge.per_location = e.target.value
                  this.setState({
                    parckage_surcharge: parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.parckage_surcharge.xs_s ? this.state.parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let parckage_surcharge = this.state.parckage_surcharge
                  parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    parckage_surcharge: parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.parckage_surcharge.m_l ? this.state.parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let parckage_surcharge = this.state.parckage_surcharge
                  parckage_surcharge.m_l = e.target.value
                  this.setState({
                    parckage_surcharge: parckage_surcharge
                  })

                }}
                /></div></Col>
               <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}> <div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.parckage_surcharge.pallets ? this.state.parckage_surcharge.pallets : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let parckage_surcharge = this.state.parckage_surcharge
                  parckage_surcharge.pallets = e.target.value
                  this.setState({
                    parckage_surcharge: parckage_surcharge
                  })

                }}
                /></div>
              </Col>
              </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid container justifyContent="center"  item xs={12}>
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                  {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid"  type="number"
                  value={this.state.location_surcharge.sentosa_changi_airport ? this.state.location_surcharge.sentosa_changi_airport : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let location_surcharge = this.state.location_surcharge
                    location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                      location_surcharge: location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                  {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.location_surcharge.tuas ? this.state.location_surcharge.tuas :''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let location_surcharge = this.state.location_surcharge
                    location_surcharge.tuas = e.target.value
                    this.setState({
                      location_surcharge: location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                  {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number" 
                  value={this.state.location_surcharge.cbd ? this.state.location_surcharge.cbd : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let location_surcharge = this.state.location_surcharge
                    location_surcharge.cbd = e.target.value
                    this.setState({
                      location_surcharge: location_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
        
            <Box pb={3}>
            <Grid container justifyContent="center"  item xs={12}>
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row className="justify-content-center d-flex">
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Waiting Time <br />(every 10min)</div>
                  <CustomInput className="w-25 num-box-min-wid num-box-min-wid" type="number"
                  value={this.state.service_surcharge.waiting_time_10 ? this.state.service_surcharge.waiting_time_10 : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let service_surcharge = this.state.service_surcharge
                    service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                      service_surcharge: service_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.service_surcharge.secured_area ? this.state.service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let service_surcharge = this.state.service_surcharge
                    service_surcharge.secured_area = e.target.value
                    this.setState({
                      service_surcharge: service_surcharge
                    })
                  }}
                  />
                </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                  <CustomInput className="w-25 num-box-min-wid num-box-min-wid" type="number"
                  value={this.state.service_surcharge.moving_service ?this.state.service_surcharge.moving_service : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let service_surcharge = this.state.service_surcharge
                    service_surcharge.moving_service = e.target.value
                    this.setState({
                      service_surcharge: service_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid container justifyContent="center"  item xs={12}>
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={8} className="d-flex align-items-center justify-content-between">
                  <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                  <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                  value={this.state.day_surcharge.saturday_or_eve_ph ? this.state.day_surcharge.saturday_or_eve_ph : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                  onChange={(e) => {
                    let day_surcharge = this.state.day_surcharge
                    day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                      day_surcharge: day_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
          </Box>
      </CustomListItem>
      <Divider  />
      <CustomListItem
        text={"For Drivers"}
        open={this.state.openStndDriver}
        onClick={() => {
          this.setState({
            openStndDriver:!this.state.openStndDriver,
            openStndClient: false,
            openStndSubcon: false,
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Box pb={3}>  
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /></div>           
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /></div>         
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.xs_next_day ? this.state.driver_standard_rate.xs_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.xs_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.xs_same_day ? this.state.driver_standard_rate.xs_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.xs_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.xs_next_day_3h ? this.state.driver_standard_rate.xs_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.xs_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.xs_express_2h ? this.state.driver_standard_rate.xs_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.xs_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.s_next_day ? this.state.driver_standard_rate.s_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.s_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.s_same_day ? this.state.driver_standard_rate.s_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.s_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.s_next_day_3h ? this.state.driver_standard_rate.s_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.s_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.s_express_2h ? this.state.driver_standard_rate.s_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.s_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.m_next_day ? this.state.driver_standard_rate.m_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.m_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.m_same_day ? this.state.driver_standard_rate.m_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.m_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.m_next_day_3h ? this.state.driver_standard_rate.m_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.m_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.m_express_2h ? this.state.driver_standard_rate.m_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.m_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.l_next_day ? this.state.driver_standard_rate.l_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.l_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.l_same_day ? this.state.driver_standard_rate.l_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.l_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.l_next_day_3h ? this.state.driver_standard_rate.l_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.l_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.l_express_2h ? this.state.driver_standard_rate.l_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.l_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.pallets_next_day ? this.state.driver_standard_rate.pallets_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.pallets_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.pallets_same_day ? this.state.driver_standard_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.pallets_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.pallets_next_day_3h ? this.state.driver_standard_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.pallets_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.pallets_express_2h ? this.state.driver_standard_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.pallets_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'auto': 'none' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_standard_rate.per_location_next_day ? this.state.driver_standard_rate.per_location_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.per_location_next_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.per_location_same_day ? this.state.driver_standard_rate.per_location_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.per_location_same_day = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.per_location_next_day_3h ? this.state.driver_standard_rate.per_location_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.per_location_next_day_3h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_standard_rate.per_location_express_2h ? this.state.driver_standard_rate.per_location_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_standard_rate = this.state.driver_standard_rate
                  driver_standard_rate.per_location_express_2h = e.target.value
                  this.setState({
                    driver_standard_rate: driver_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.driver_parckage_surcharge.base_qty >= 0 ? this.state.driver_parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                          driver_parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            driver_parckage_surcharge: driver_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.driver_parckage_surcharge.surcharge_qty >= 0 ? this.state.driver_parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                          driver_parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            driver_parckage_surcharge: driver_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
            <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.driver_parckage_surcharge.per_location ? this.state.driver_parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                  driver_parckage_surcharge.per_location = e.target.value
                  this.setState({
                    driver_parckage_surcharge: driver_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.driver_parckage_surcharge.xs_s ? this.state.driver_parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                  driver_parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    driver_parckage_surcharge: driver_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.driver_parckage_surcharge.m_l ? this.state.driver_parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                  driver_parckage_surcharge.m_l = e.target.value
                  this.setState({
                    driver_parckage_surcharge: driver_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.driver_parckage_surcharge.pallets ? this.state.driver_parckage_surcharge.pallets : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_parckage_surcharge = this.state.driver_parckage_surcharge
                  driver_parckage_surcharge.pallets = e.target.value
                  this.setState({
                    driver_parckage_surcharge: driver_parckage_surcharge
                  })

                }}
                /></div></Col>  
              </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                  {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid"  type="number"
                  value={this.state.driver_location_surcharge.sentosa_changi_airport ? this.state.driver_location_surcharge.sentosa_changi_airport : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_location_surcharge = this.state.driver_location_surcharge
                    driver_location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                      driver_location_surcharge: driver_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                  {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.driver_location_surcharge.tuas ? this.state.driver_location_surcharge.tuas :''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_location_surcharge = this.state.driver_location_surcharge
                    driver_location_surcharge.tuas = e.target.value
                    this.setState({
                      driver_location_surcharge: driver_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                  {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number" 
                  value={this.state.driver_location_surcharge.cbd ? this.state.driver_location_surcharge.cbd : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_location_surcharge = this.state.driver_location_surcharge
                    driver_location_surcharge.cbd = e.target.value
                    this.setState({
                      driver_location_surcharge: driver_location_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
            
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row className="justify-content-center d-flex">
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Waiting Time <br />(every 10min)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.driver_service_surcharge.waiting_time_10 ? this.state.driver_service_surcharge.waiting_time_10 : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_service_surcharge = this.state.driver_service_surcharge
                    driver_service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                      driver_service_surcharge: driver_service_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.driver_service_surcharge.secured_area ? this.state.driver_service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_service_surcharge = this.state.driver_service_surcharge
                    driver_service_surcharge.secured_area = e.target.value
                    this.setState({
                      driver_service_surcharge: driver_service_surcharge
                    })
                  }}
                  />
                </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.driver_service_surcharge.moving_service ? this.state.driver_service_surcharge.moving_service : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_service_surcharge = this.state.driver_service_surcharge
                    driver_service_surcharge.moving_service = e.target.value
                    this.setState({
                      driver_service_surcharge: driver_service_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={8} className="d-flex align-items-center justify-content-between">
                  <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                  <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                  value={this.state.driver_day_surcharge.saturday_or_eve_ph ? this.state.driver_day_surcharge.saturday_or_eve_ph : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_day_surcharge = this.state.driver_day_surcharge
                    driver_day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                      driver_day_surcharge: driver_day_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
          </Box>
      </CustomListItem>
      <Divider  />
      <CustomListItem
        text={"For Subcons"}
        open={this.state.openStndSubcon}
        onClick={() => {
          this.setState({
            openStndSubcon:!this.state.openStndSubcon,
            openStndClient: false,
            openStndDriver: false
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Box pb={3}>  
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /> </div>           
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.standard_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let standard_rate = this.state.standard_rate
                      standard_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        standard_rate: standard_rate,
                      })
                    }}
                    /> </div>           
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.xs_next_day ? this.state.subcon_standard_rate.xs_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.xs_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.xs_same_day ? this.state.subcon_standard_rate.xs_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.xs_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.xs_next_day_3h ? this.state.subcon_standard_rate.xs_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.xs_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.xs_express_2h ? this.state.subcon_standard_rate.xs_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.xs_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.s_next_day ? this.state.subcon_standard_rate.s_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.s_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.s_same_day ? this.state.subcon_standard_rate.s_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.s_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.s_next_day_3h ? this.state.subcon_standard_rate.s_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.s_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.s_express_2h ? this.state.subcon_standard_rate.s_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.s_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.m_next_day ? this.state.subcon_standard_rate.m_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.m_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.m_same_day ? this.state.subcon_standard_rate.m_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.m_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.m_next_day_3h ? this.state.subcon_standard_rate.m_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.m_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.m_express_2h ? this.state.subcon_standard_rate.m_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.m_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.l_next_day ? this.state.subcon_standard_rate.l_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.l_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.l_same_day ? this.state.subcon_standard_rate.l_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.l_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.l_next_day_3h ? this.state.subcon_standard_rate.l_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.l_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.l_express_2h ? this.state.subcon_standard_rate.l_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.l_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.pallets_next_day ? this.state.subcon_standard_rate.pallets_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.pallets_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.pallets_same_day ? this.state.subcon_standard_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.pallets_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.pallets_next_day_3h ? this.state.subcon_standard_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.pallets_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.pallets_express_2h ? this.state.subcon_standard_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.pallets_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'auto': 'none' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.per_location_next_day ? this.state.subcon_standard_rate.per_location_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.per_location_next_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.per_location_same_day ? this.state.subcon_standard_rate.per_location_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.per_location_same_day = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.per_location_next_day_3h ? this.state.subcon_standard_rate.per_location_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.per_location_next_day_3h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_standard_rate.per_location_express_2h ? this.state.subcon_standard_rate.per_location_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_standard_rate = this.state.subcon_standard_rate
                  subcon_standard_rate.per_location_express_2h = e.target.value
                  this.setState({
                    subcon_standard_rate: subcon_standard_rate
                  })

                }}
                /></div></Col>
              </Row>
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.subcon_parckage_surcharge.base_qty >= 0 ? this.state.subcon_parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                          subcon_parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            subcon_parckage_surcharge: subcon_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.subcon_parckage_surcharge.surcharge_qty >= 0 ? this.state.subcon_parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                          subcon_parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            subcon_parckage_surcharge: subcon_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
            <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.standard_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.standard_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.subcon_parckage_surcharge.per_location ? this.state.subcon_parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                  subcon_parckage_surcharge.per_location = e.target.value
                  this.setState({
                    subcon_parckage_surcharge: subcon_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.subcon_parckage_surcharge.xs_s ? this.state.subcon_parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                  subcon_parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    subcon_parckage_surcharge: subcon_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.subcon_parckage_surcharge.m_l ? this.state.subcon_parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                  subcon_parckage_surcharge.m_l = e.target.value
                  this.setState({
                    subcon_parckage_surcharge: subcon_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.standard_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.standard_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.standard_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.subcon_parckage_surcharge.pallets ? this.state.subcon_parckage_surcharge.pallets : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_parckage_surcharge = this.state.subcon_parckage_surcharge
                  subcon_parckage_surcharge.pallets = e.target.value
                  this.setState({
                    subcon_parckage_surcharge: subcon_parckage_surcharge
                  })

                }}
                /></div></Col>  
              </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                  {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid"  type="number"
                  value={this.state.subcon_location_surcharge.sentosa_changi_airport ? this.state.subcon_location_surcharge.sentosa_changi_airport : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_location_surcharge = this.state.subcon_location_surcharge
                    subcon_location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                      subcon_location_surcharge: subcon_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                  {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.subcon_location_surcharge.tuas ? this.state.subcon_location_surcharge.tuas :''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_location_surcharge = this.state.subcon_location_surcharge
                    subcon_location_surcharge.tuas = e.target.value
                    this.setState({
                      subcon_location_surcharge: subcon_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                  {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid" type="number" 
                  value={this.state.subcon_location_surcharge.cbd ? this.state.subcon_location_surcharge.cbd : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_location_surcharge = this.state.subcon_location_surcharge
                    subcon_location_surcharge.cbd = e.target.value
                    this.setState({
                      subcon_location_surcharge: subcon_location_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
          
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row className="justify-content-center d-flex">
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Waiting Time <br />(every 10min)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.subcon_service_surcharge.waiting_time_10 ? this.state.subcon_service_surcharge.waiting_time_10 : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_service_surcharge = this.state.subcon_service_surcharge
                    subcon_service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                      subcon_service_surcharge: subcon_service_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.subcon_service_surcharge.secured_area ? this.state.subcon_service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_service_surcharge = this.state.subcon_service_surcharge
                    subcon_service_surcharge.secured_area = e.target.value
                    this.setState({
                      subcon_service_surcharge: subcon_service_surcharge
                    })
                  }}
                  />
                </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.subcon_service_surcharge.moving_service ? this.state.subcon_service_surcharge.moving_service : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_service_surcharge = this.state.subcon_service_surcharge
                    subcon_service_surcharge.moving_service = e.target.value
                    this.setState({
                      subcon_service_surcharge: subcon_service_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={8} className="d-flex align-items-center justify-content-between">
                  <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                  <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                  value={this.state.subcon_day_surcharge.saturday_or_eve_ph ? this.state.subcon_day_surcharge.saturday_or_eve_ph : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_day_surcharge = this.state.subcon_day_surcharge
                    subcon_day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                      subcon_day_surcharge: subcon_day_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
          </Box>
      </CustomListItem>
      <Divider  />
      
    </>
  }

  setProjectRate = () => {
    const { currentRole} = this.context;
    return <>
      <CustomListItem
        text={"For Clients"}
        open={this.state.openPrjClient}
        onClick={() => {
          this.setState({
            openPrjClient:!this.state.openPrjClient,
            openPrjDriver: false,
            openPrjSubcon: false,
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
      
            <Box pb={3}>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>            
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>            
                  </div>
                </Col>
              </Row>
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
              <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.xs_next_day ? this.state.project_rate.xs_next_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.xs_next_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.xs_same_day ? this.state.project_rate.xs_same_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.xs_same_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.xs_next_day_3h ? this.state.project_rate.xs_next_day_3h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.xs_next_day_3h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.xs_express_2h ? this.state.project_rate.xs_express_2h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.xs_express_2h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
              <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.s_next_day ? this.state.project_rate.s_next_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.s_next_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.s_same_day ? this.state.project_rate.s_same_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.s_same_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.s_next_day_3h ? this.state.project_rate.s_next_day_3h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.s_next_day_3h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.s_express_2h ? this.state.project_rate.s_express_2h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.s_express_2h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
              <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.m_next_day ? this.state.project_rate.m_next_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.m_next_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.m_same_day ? this.state.project_rate.m_same_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.m_same_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.m_next_day_3h ? this.state.project_rate.m_next_day_3h : '' }
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.m_next_day_3h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.m_express_2h ? this.state.project_rate.m_express_2h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.m_express_2h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
              <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.l_next_day ? this.state.project_rate.l_next_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.l_next_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.l_same_day ? this.state.project_rate.l_same_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.l_same_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.l_next_day_3h ? this.state.project_rate.l_next_day_3h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.l_next_day_3h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.l_express_2h ? this.state.project_rate.l_express_2h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.l_express_2h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'auto': 'none' }}>
              <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.per_location_next_day ? this.state.project_rate.per_location_next_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.per_location_next_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.per_location_same_day ? this.state.project_rate.per_location_same_day : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.per_location_same_day = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.per_location_next_day_3h ? this.state.project_rate.per_location_next_day_3h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.per_location_next_day_3h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
              <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
              value={this.state.project_rate.per_location_express_2h ? this.state.project_rate.per_location_express_2h : ''}
              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                let project_rate = this.state.project_rate
                project_rate.per_location_express_2h = e.target.value
                this.setState({
                  project_rate: project_rate
                })

              }}
              /></div></Col>
            </Row>
            {/* <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.project_rate.pallets_next_day ? this.state.project_rate.pallets_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let project_rate = this.state.project_rate
                  project_rate.pallets_next_day = e.target.value
                  this.setState({
                    project_rate: project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.project_rate.pallets_same_day ? this.state.project_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let project_rate = this.state.project_rate
                  project_rate.pallets_same_day = e.target.value
                  this.setState({
                    project_rate: project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.project_rate.pallets_next_day_3h ? this.state.project_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let project_rate = this.state.project_rate
                  project_rate.pallets_next_day_3h = e.target.value
                  this.setState({
                    project_rate: project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.project_rate.pallets_express_2h ? this.state.project_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let project_rate = this.state.project_rate
                  project_rate.pallets_express_2h = e.target.value
                  this.setState({
                    project_rate: project_rate
                  })

                }}
                /></div></Col>
              </Row> */}
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.project_parckage_surcharge.base_qty >= 0 ? this.state.project_parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let project_parckage_surcharge = this.state.project_parckage_surcharge
                          project_parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            project_parckage_surcharge: project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.project_parckage_surcharge.surcharge_qty >= 0 ? this.state.project_parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let project_parckage_surcharge = this.state.project_parckage_surcharge
                          project_parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            project_parckage_surcharge: project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
            <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.project_parckage_surcharge.per_location ? this.state.project_parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let project_parckage_surcharge = this.state.project_parckage_surcharge
                  project_parckage_surcharge.per_location = e.target.value
                  this.setState({
                    project_parckage_surcharge: project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.project_parckage_surcharge.xs_s ? this.state.project_parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let project_parckage_surcharge = this.state.project_parckage_surcharge
                  project_parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    project_parckage_surcharge: project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.project_parckage_surcharge.m_l ? this.state.project_parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let project_parckage_surcharge = this.state.project_parckage_surcharge
                  project_parckage_surcharge.m_l = e.target.value
                  this.setState({
                    project_parckage_surcharge: project_parckage_surcharge
                  })

                }}
                /></div></Col>
                {/* <Col xs={2} className="d-flex align-items-center justify-content-center" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.project_parckage_surcharge.pallets ? this.state.project_parckage_surcharge.pallets : '' }
                onChange={(e) => {
                  let project_parckage_surcharge = this.state.project_parckage_surcharge
                  project_parckage_surcharge.pallets = e.target.value
                  this.setState({
                    project_parckage_surcharge: project_parckage_surcharge
                  })

                }}
                /></Col> */}
              </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                  {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                  <CustomInput className="w-25 num-box-min-wid"  type="number"
                  value={this.state.project_location_surcharge.sentosa_changi_airport ? this.state.project_location_surcharge.sentosa_changi_airport : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_location_surcharge = this.state.project_location_surcharge
                    project_location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                      project_location_surcharge: project_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                  {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                  <CustomInput className="w-50" type="number"
                  value={this.state.project_location_surcharge.tuas ? this.state.project_location_surcharge.tuas :''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_location_surcharge = this.state.project_location_surcharge
                    project_location_surcharge.tuas = e.target.value
                    this.setState({
                      project_location_surcharge: project_location_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                  {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                  <CustomInput className="w-50" type="number" 
                  value={this.state.project_location_surcharge.cbd ? this.state.project_location_surcharge.cbd : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_location_surcharge = this.state.project_location_surcharge
                    project_location_surcharge.cbd = e.target.value
                    this.setState({
                      project_location_surcharge: project_location_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
         
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row className="justify-content-center d-flex">
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Waiting Time <br />(every 10min)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.project_service_surcharge.waiting_time_10 ? this.state.project_service_surcharge.waiting_time_10 : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_service_surcharge = this.state.project_service_surcharge
                    project_service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                      project_service_surcharge: project_service_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.project_service_surcharge.secured_area ? this.state.project_service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_service_surcharge = this.state.project_service_surcharge
                    project_service_surcharge.secured_area = e.target.value
                    this.setState({
                      project_service_surcharge: project_service_surcharge
                    })

                  }}
                  />
              </Col>
              <Col xs={4} className="d-flex align-items-center">
              <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.project_service_surcharge.moving_service ? this.state.project_service_surcharge.moving_service : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_service_surcharge = this.state.project_service_surcharge
                    project_service_surcharge.moving_service = e.target.value
                    this.setState({
                      project_service_surcharge: project_service_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent="center">
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
              <Row>
              <Col xs={8} className="d-flex align-items-center justify-content-between">
                  <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                  <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                  value={this.state.project_day_surcharge.saturday_or_eve_ph ? this.state.project_day_surcharge.saturday_or_eve_ph : ''}
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let project_day_surcharge = this.state.project_day_surcharge
                    project_day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                      project_day_surcharge: project_day_surcharge
                    })

                  }}
                  />
              </Col>
              </Row>
            </Row>
            </Box>
          </Box>
          
        </CustomListItem>
      
      <Divider  />
      <CustomListItem
        text={"For Drivers"}
        open={this.state.openPrjDriver}
        onClick={() => {
          this.setState({
            openPrjDriver:!this.state.openPrjDriver,
            openPrjClient: false,
            openPrjSubcon: false,
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Box pb={3}>  
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>            
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>            
                  </div>
                </Col>
              </Row>
                <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.xs_next_day ? this.state.driver_project_rate.xs_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.xs_next_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.xs_same_day ? this.state.driver_project_rate.xs_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.xs_same_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.xs_next_day_3h ? this.state.driver_project_rate.xs_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.xs_next_day_3h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.xs_express_2h ? this.state.driver_project_rate.xs_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.xs_express_2h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.s_next_day ? this.state.driver_project_rate.s_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.s_next_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.s_same_day ? this.state.driver_project_rate.s_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.s_same_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.s_next_day_3h ? this.state.driver_project_rate.s_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.s_next_day_3h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.s_express_2h ? this.state.driver_project_rate.s_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.s_express_2h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.m_next_day ? this.state.driver_project_rate.m_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.m_next_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.m_same_day ? this.state.driver_project_rate.m_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.m_same_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.m_next_day_3h ? this.state.driver_project_rate.m_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.m_next_day_3h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.m_express_2h ? this.state.driver_project_rate.m_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.m_express_2h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.l_next_day ? this.state.driver_project_rate.l_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.l_next_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.l_same_day ? this.state.driver_project_rate.l_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.l_same_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.l_next_day_3h ? this.state.driver_project_rate.l_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.l_next_day_3h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.l_express_2h ? this.state.driver_project_rate.l_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.l_express_2h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'auto': 'none' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.per_location_next_day ? this.state.driver_project_rate.per_location_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.per_location_next_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.per_location_same_day ? this.state.driver_project_rate.per_location_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.per_location_same_day = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.per_location_next_day_3h ? this.state.driver_project_rate.per_location_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.per_location_next_day_3h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.per_location_express_2h ? this.state.driver_project_rate.per_location_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_rate = this.state.driver_project_rate
                    driver_project_rate.per_location_express_2h = e.target.value
                    this.setState({
                    driver_project_rate: driver_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                {/* <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_rate.pallets_next_day ? this.state.driver_project_rate.pallets_next_day: ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_project_rate = this.state.driver_project_rate
                  driver_project_rate.pallets_next_day = e.target.value
                  this.setState({
                    driver_project_rate: driver_project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_project_rate.pallets_same_day ? this.state.driver_project_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_project_rate = this.state.driver_project_rate
                  driver_project_rate.pallets_same_day = e.target.value
                  this.setState({
                    driver_project_rate: driver_project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_project_rate.pallets_next_day_3h ? this.state.driver_project_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_project_rate = this.state.driver_project_rate
                  driver_project_rate.pallets_next_day_3h = e.target.value
                  this.setState({
                    driver_project_rate: driver_project_rate
                  })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 num-box-min-wid"
                value={this.state.driver_project_rate.pallets_express_2h ? this.state.driver_project_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_project_rate = this.state.driver_project_rate
                  driver_project_rate.pallets_express_2h = e.target.value
                  this.setState({
                    driver_project_rate: driver_project_rate
                  })

                }}
                /></div></Col>
              </Row> */}
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.driver_project_parckage_surcharge.base_qty >= 0 ? this.state.driver_project_parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                          driver_project_parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            driver_project_parckage_surcharge: driver_project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.driver_project_parckage_surcharge.surcharge_qty >= 0 ? this.state.driver_project_parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                          driver_project_parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            driver_project_parckage_surcharge: driver_project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
            <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.driver_project_parckage_surcharge.per_location ? this.state.driver_project_parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                  driver_project_parckage_surcharge.per_location = e.target.value
                  this.setState({
                    driver_project_parckage_surcharge: driver_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.driver_project_parckage_surcharge.xs_s ? this.state.driver_project_parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                  driver_project_parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    driver_project_parckage_surcharge: driver_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.driver_project_parckage_surcharge.m_l ? this.state.driver_project_parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                  driver_project_parckage_surcharge.m_l = e.target.value
                  this.setState({
                    driver_project_parckage_surcharge: driver_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                {/* <Col xs={2} className="d-flex align-items-center justify-content-center" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.driver_project_parckage_surcharge.pallets ? this.state.driver_project_parckage_surcharge.pallets : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let driver_project_parckage_surcharge = this.state.driver_project_parckage_surcharge
                    driver_project_parckage_surcharge.pallets = e.target.value
                    this.setState({
                    driver_project_parckage_surcharge: driver_project_parckage_surcharge
                    })

                }}
                /></Col> */}
                </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
                <Row>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                    {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid"  type="number"
                    value={this.state.driver_project_location_surcharge.sentosa_changi_airport ? this.state.driver_project_location_surcharge.sentosa_changi_airport : '' }
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_location_surcharge = this.state.driver_project_location_surcharge
                    driver_project_location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                        driver_project_location_surcharge: driver_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                    {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.driver_project_location_surcharge.tuas ? this.state.driver_project_location_surcharge.tuas :''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_location_surcharge = this.state.driver_project_location_surcharge
                    driver_project_location_surcharge.tuas = e.target.value
                    this.setState({
                        driver_project_location_surcharge: driver_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                    {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid" type="number" 
                    value={this.state.driver_project_location_surcharge.cbd ? this.state.driver_project_location_surcharge.cbd : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_location_surcharge = this.state.driver_project_location_surcharge
                    driver_project_location_surcharge.cbd = e.target.value
                    this.setState({
                        driver_project_location_surcharge: driver_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
                <Row className="justify-content-center d-flex">
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75">Waiting Time <br />(every 10min)</div>
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.driver_project_service_surcharge.waiting_time_10 ? this.state.driver_project_service_surcharge.waiting_time_10 : '' }
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_service_surcharge = this.state.driver_project_service_surcharge
                    driver_project_service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                        driver_project_service_surcharge: driver_project_service_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.driver_project_service_surcharge.secured_area ? this.state.driver_project_service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let driver_project_service_surcharge = this.state.driver_project_service_surcharge
                    driver_project_service_surcharge.secured_area = e.target.value
                    this.setState({
                      driver_project_service_surcharge: driver_project_service_surcharge
                    })

                  }}
                  />
              </Col>
                <Col xs={4} className="d-flex align-items-center">
                <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.driver_project_service_surcharge.moving_service ? this.state.driver_project_service_surcharge.moving_service : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_service_surcharge = this.state.driver_project_service_surcharge
                    driver_project_service_surcharge.moving_service = e.target.value
                    this.setState({
                        driver_project_service_surcharge: driver_project_service_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
                <Row>
                <Col xs={8} className="d-flex align-items-center justify-content-between">
                    <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                    <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                    value={this.state.driver_project_day_surcharge.saturday_or_eve_ph ? this.state.driver_project_day_surcharge.saturday_or_eve_ph : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let driver_project_day_surcharge = this.state.driver_project_day_surcharge
                    driver_project_day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                        driver_project_day_surcharge: driver_project_day_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>
            </Row>
            </Box>
          </Box>
      </CustomListItem>
      <Divider />
      <CustomListItem
        text={"For Subcons"}
        open={this.state.openPrjSubcon}
        onClick={() => {
          this.setState({
            openPrjSubcon:!this.state.openPrjSubcon,
            openPrjClient: false,
            openPrjDriver: false,
          })
        }}
        >
          <Box style={{pointerEvents: (currentRole == 'Ops Admin' || currentRole == 'Finance Admin') ? 'none': 'auto' }}>
            <Box pb={3}>  
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={6} lg={6} className="d-flex justify-content-between align-items-center mb-4" style={{border:'1px solid #c3c3c3', borderRadius: '7px', marginLeft: '5em', marginRight:'5em'}}>
                  <div className="d-flex align-items-center justify-content-center font-medium ps-3">Charging Model</div>
                  <div className="d-flex align-items-center">
                    <CustomLabel label={'Package Size'} className="px-2 remove-lable-bottom"  style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? false : true}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? false : true;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>           
                  </div>
                  <div className="d-flex align-items-center pe-3">
                    <CustomLabel label={'Location'} className="px-2 remove-lable-bottom" style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}/>
                    <div  className="remove-switch-bottom"><CustomSwitch 
                    className="remove-switch-bottom"
                     style={{marginBottom: '0px !important', paddingBottom: '0px  !important'}}
                    checked={this.state.project_rate.is_location == true ? true : false}
                    onChange={(isChecked) => {
                      let project_rate = this.state.project_rate
                      project_rate.is_location = isChecked == true ? true : false;
                      this.setState({
                        project_rate: project_rate,
                      })
                    }}
                    /></div>            
                  </div>
                </Col>
              </Row>
                <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center"></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Same Day</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Next Day (3H Slot)</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center report-header">Express 2H</Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.xs_next_day ? this.state.subcon_project_rate.xs_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.xs_next_day = e.target.value
                    this.setState({
                      subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.xs_same_day ? this.state.subcon_project_rate.xs_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.xs_same_day = e.target.value
                    this.setState({
                      subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.xs_next_day_3h ? this.state.subcon_project_rate.xs_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.xs_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.xs_express_2h ? this.state.subcon_project_rate.xs_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.xs_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>S</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.s_next_day ? this.state.subcon_project_rate.s_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.s_next_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.s_same_day ? this.state.subcon_project_rate.s_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.s_same_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.s_next_day_3h ? this.state.subcon_project_rate.s_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.s_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.s_express_2h ? this.state.subcon_project_rate.s_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.s_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.m_next_day ? this.state.subcon_project_rate.m_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.m_next_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.m_same_day ? this.state.subcon_project_rate.m_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.m_same_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.m_next_day_3h ? this.state.subcon_project_rate.m_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.m_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.m_express_2h ? this.state.subcon_project_rate.m_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.m_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>L</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.l_next_day ? this.state.subcon_project_rate.l_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.l_next_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.l_same_day ? this.state.subcon_project_rate.l_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.l_same_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.l_next_day_3h ? this.state.subcon_project_rate.l_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.l_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.l_express_2h ? this.state.subcon_project_rate.l_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.l_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'auto': 'none' }}>
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.per_location_next_day ? this.state.subcon_project_rate.per_location_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.per_location_next_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.per_location_same_day ? this.state.subcon_project_rate.per_location_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.per_location_same_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.per_location_next_day_3h ? this.state.subcon_project_rate.per_location_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.per_location_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number" disabled={this.state.project_rate.is_location != true ? true : false} style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.per_location_express_2h ? this.state.subcon_project_rate.per_location_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.per_location_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row>
                {/* <Row className="d-flex justify-content-center align-items-center">
                <Col xs={2} className="d-flex align-items-center justify-content-center  rounded" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.pallets_next_day ? this.state.subcon_project_rate.pallets_next_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.pallets_next_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.pallets_same_day ? this.state.subcon_project_rate.pallets_same_day : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.pallets_same_day = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.pallets_next_day_3h ? this.state.subcon_project_rate.pallets_next_day_3h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.pallets_next_day_3h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center "><div className="p-4"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_rate.pallets_express_2h ? this.state.subcon_project_rate.pallets_express_2h : ''}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_rate = this.state.subcon_project_rate
                    subcon_project_rate.pallets_express_2h = e.target.value
                    this.setState({
                    subcon_project_rate: subcon_project_rate
                    })

                }}
                /></div></Col>
                </Row> */}
            </Row>

            </Box>
            <Box pb={3}>
                <Row className="justify-content-center">
                  <Col className='d-flex col-8'>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Base Charge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.subcon_project_parckage_surcharge.base_qty >= 0 ? this.state.subcon_project_parckage_surcharge.base_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                          subcon_project_parckage_surcharge.base_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                     <Col className='col-6'>
                      <Row className='justify-content-center'>
                        <Col className='col-6 font-medium text-center d-flex justify-content-center'>Surcharge Quantity</Col>
                      </Row>
                      <Row className='justify-content-center'>
                        <Col className='col-6'>
                        <Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100 text-middle-pos"
                        value={this.state.subcon_project_parckage_surcharge.surcharge_qty >= 0 ? this.state.subcon_project_parckage_surcharge.surcharge_qty : 1 }
                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e) => {
                          let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                          subcon_project_parckage_surcharge.surcharge_qty = e.target.value >= 0 ? e.target.value : 1
                          this.setState({
                            subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                          })

                        }}
                        />
                        </Col>
                      
                      </Row>
                     </Col>
                    
                  </Col>
                </Row>
              
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Package Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
            <Row className="d-flex justify-content-center align-items-center">
            <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location != true) ? 'none': 'auto' }}><div className="d-flex align-items-center justify-content-center  w-50" style={{color: (this.state.project_rate.is_location != true) && 'rgb(180 180 180)' }}>Per Location</div>
                <div className="d-flex align-items-center justify-content-center  w-50"><Input  type="number" style={{borderColor: (this.state.project_rate.is_location != true) && 'rgb(180 180 180) !important' }} disabled={this.state.project_rate.is_location != true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50 num-box-min-wid"
                value={this.state.subcon_project_parckage_surcharge.per_location ? this.state.subcon_project_parckage_surcharge.per_location : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} 
                onChange={(e) => {
                  let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                  subcon_project_parckage_surcharge.per_location = e.target.value
                  this.setState({
                    subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>XS / S</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.subcon_project_parckage_surcharge.xs_s ? this.state.subcon_project_parckage_surcharge.xs_s : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                  subcon_project_parckage_surcharge.xs_s = e.target.value
                  this.setState({
                    subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                 <Col xs={3} className="d-flex" style={{pointerEvents: (this.state.project_rate.is_location == true) ? 'none': 'auto' }}><div xs={2} className="d-flex align-items-center justify-content-center w-50" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>M / L</div>
                <div xs={2} className="d-flex align-items-center justify-content-center w-50"><Input  type="number" disabled={this.state.project_rate.is_location == true ? true : false} className="form-control d-flex align-items-center p-2 input-new-order-form w-50"
                value={this.state.subcon_project_parckage_surcharge.m_l ? this.state.subcon_project_parckage_surcharge.m_l : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                  subcon_project_parckage_surcharge.m_l = e.target.value
                  this.setState({
                    subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                  })

                }}
                /></div></Col>
                {/* <Col xs={2} className="d-flex align-items-center justify-content-center" style={{color: (this.state.project_rate.is_location == true) && 'rgb(180 180 180)' }}>Pallets</Col>
                <Col xs={2} className="d-flex align-items-center justify-content-center"><Input  type="number"  className="form-control d-flex align-items-center p-2 input-new-order-form w-100"
                value={this.state.subcon_project_parckage_surcharge.pallets ? this.state.subcon_project_parckage_surcharge.pallets : '' }
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                    let subcon_project_parckage_surcharge = this.state.subcon_project_parckage_surcharge
                    subcon_project_parckage_surcharge.pallets = e.target.value
                    this.setState({
                    subcon_project_parckage_surcharge: subcon_project_parckage_surcharge
                    })

                }}
                /></Col> */}
                </Row>
            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Location Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
                <Row>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75">Airport/Sentosa <br/> (81 & 09)</div>
                    {/* <CustomLabel label={'Airport/Sentosa <br/> (81 & 09)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid"  type="number"
                    value={this.state.subcon_project_location_surcharge.sentosa_changi_airport ? this.state.subcon_project_location_surcharge.sentosa_changi_airport : '' }
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_location_surcharge = this.state.subcon_project_location_surcharge
                    subcon_project_location_surcharge.sentosa_changi_airport = e.target.value
                    this.setState({
                        subcon_project_location_surcharge: subcon_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75">Tuas <br/> (627-629,636-639)</div>
                    {/* <CustomLabel label={'Tuas (627,629,638,639)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.subcon_project_location_surcharge.tuas ? this.state.subcon_project_location_surcharge.tuas :''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_location_surcharge = this.state.subcon_project_location_surcharge
                    subcon_project_location_surcharge.tuas = e.target.value
                    this.setState({
                        subcon_project_location_surcharge: subcon_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                <div className="text-center px-2 w-75">CBD <br /> (01-08, 17-19, 22-23)</div>
                    {/* <CustomLabel label={'CBD (01-08, 17-19, 22-23)'} className="w-50"/> */}
                    <CustomInput className="w-25 num-box-min-wid" type="number" 
                    value={this.state.subcon_project_location_surcharge.cbd ? this.state.subcon_project_location_surcharge.cbd : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_location_surcharge = this.state.subcon_project_location_surcharge
                    subcon_project_location_surcharge.cbd = e.target.value
                    this.setState({
                        subcon_project_location_surcharge: subcon_project_location_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>
            </Row>
            </Box>
           
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Service Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            <Row className="py-3">
                <Row className="justify-content-center d-flex">
                <Col xs={4} className="d-flex align-items-center">
                    <div className="text-center px-2 w-75 ">Waiting Time <br />(every 10min)</div>
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.subcon_project_service_surcharge.waiting_time_10 ? this.state.subcon_project_service_surcharge.waiting_time_10 : '' }
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_service_surcharge = this.state.subcon_project_service_surcharge
                    subcon_project_service_surcharge.waiting_time_10 = e.target.value
                    this.setState({
                        subcon_project_service_surcharge: subcon_project_service_surcharge
                    })

                    }}
                    />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                  <div className="text-center px-2 w-75 ">Secured Area <br/>(gate pass required)</div>
                  <CustomInput className="w-25 num-box-min-wid" type="number"
                  value={this.state.subcon_project_service_surcharge.secured_area ? this.state.subcon_project_service_surcharge.secured_area : '' }
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    let subcon_project_service_surcharge = this.state.subcon_project_service_surcharge
                    subcon_project_service_surcharge.secured_area = e.target.value
                    this.setState({
                      subcon_project_service_surcharge: subcon_project_service_surcharge
                    })
                  }}
                  />
                </Col>
                <Col xs={4} className="d-flex align-items-center">
                <div className="text-center px-2 w-75 ">Moving service <br /> (per headcount)</div>
                    <CustomInput className="w-25 num-box-min-wid" type="number"
                    value={this.state.subcon_project_service_surcharge.moving_service ? this.state.subcon_project_service_surcharge.moving_service : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_service_surcharge = this.state.subcon_project_service_surcharge
                    subcon_project_service_surcharge.moving_service = e.target.value
                    this.setState({
                        subcon_project_service_surcharge: subcon_project_service_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>

            </Row>
            </Box>
            <Box pb={3}>
            <Grid item xs={12} container justifyContent='center'>
                <CustomLabel
                    label={'Day Surcharge'}
                    weight={'bold'}
                    size={'md'}
                />
            </Grid>
            
            <Row className="py-3">
                <Row>
                <Col xs={8} className="d-flex align-items-center justify-content-between">
                    <CustomLabel label={'Saturday or Eve of PH'} className="w-75 px-2 text-end"/>
                    <CustomInput className="w-25 num-box-min-wid max-width-85" type="number"
                    value={this.state.subcon_project_day_surcharge.saturday_or_eve_ph ? this.state.subcon_project_day_surcharge.saturday_or_eve_ph : ''}
                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                    let subcon_project_day_surcharge = this.state.subcon_project_day_surcharge
                    subcon_project_day_surcharge.saturday_or_eve_ph = e.target.value
                    this.setState({
                        subcon_project_day_surcharge: subcon_project_day_surcharge
                    })

                    }}
                    />
                </Col>
                </Row>
            </Row>
            </Box>
          </Box>
      </CustomListItem>
      
    </>
  }

  setDepartments = () => {
    return <Box>
        <CompanyDepartmentList 
            preview={true}
            items={this.state.departments}
        />
    </Box>
  }

  setAdmin = () => {
    return <Box>
        <CustomerAdminAccountList 
            preview={true}
            items={this.state.admin}
        />
    </Box>
  }

  setForm = () => {
      if(this.state.isLoading){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'300px'}>
            <CircularProgress size={35} />
        </Box>
      } else {
        return <Box>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setOverview()}
                    </Grid>
                </Box>
                {/* <Box clone>
                    <Grid item xs={12}>
                        <CustomTabs
                            selected={this.state.tabsValue}
                            items={[
                                {
                                    text: <CustomLabel label={'Addresses'} weight={'bold'} />,
                                    children: this.setAddresses(),
                                },
                                {
                                    text: <CustomLabel label={'Departments'} weight={'bold'} />,
                                    children: this.setDepartments(),
                                },
                                {
                                  text: <CustomLabel label={'Admin'} weight={'bold'} />,
                                  children: this.setAdmin(),
                              },
                            ]}
                            onChange={(val) => {
                                this.setState({
                                    tabsValue: val
                                });
                            }}
                        />
                    </Grid>
                </Box> */}
            </Grid>
        </Box>
      }
  }
  
  setButtons = () => {
     const { isOnBoarding , currentRole} = this.context;
     let grantedMenu = new RoleAccessService();
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'center'}>
                <Grid item xs={true} className="d-flex justify-content-center align-items-center">
                    <CustomButton 
                    className="cancel_btn mx-3"
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                    { (grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Edit') && currentRole != "Ops Admin") && <CustomButton 
                    className="save_change_btn"
                        onClick={(e) => {
                            this.handleSubmit(this.state.id, e);
                        }}
                    >
                        Save Changes
                    </CustomButton> }
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */

      
  render() {
    return <Box>
        <Grid container>
            <Box clone pt={1} pb={2} className="min-width-preview-popup">
                <Grid item xs={12}>
                    {this.setForm()}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
}

export default CustomerPreviewDialog;