import React, { useState } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs } from "react-google-maps"
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";

import CustomInput from '../CustomFields/CustomInput.jsx';

import apiUtil from '../../api/apiUtil.jsx';


const CustomGoogleMapSearch = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height:'100%' }} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
    
          this.setState({
            places: [],
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onPlacesChanged: () => {
              const places = refs.searchBox.getPlaces();
              
              this.setState({
                places,
              });

              if(this.props.onPlacesChanged){
                this.props.onPlacesChanged((places && places.length > 0) ? places[0] : null);
              }
            },
          })
        },
      }),
    withScriptjs
  )(props => {
    const [className] = useState(props.className ? props.className : '');
    const [label] = useState(props.label ? props.label : '');
    const [placeholder] = useState(props.placeholder ? props.placeholder : '');
    const [value, setValue] = useState((props.value && props.value !== '') ? props.value : '');
    const [readOnly, setReadOnly] = useState((props.readOnly && props.readOnly === true) ? props.readOnly : false);
    const [startAdornment] = useState(props.startAdornment ? props.startAdornment : null);
    const [endAdornment] = useState(props.endAdornment ? props.endAdornment : null);
    const [required] = useState(props.required ? props.required : false);
    const [error, setError] = useState(props.error ? props.error : false);


    React.useEffect(() => {
      setValue((props.value && props.value !== '') ? props.value : '');
      setError(props.error ? props.error : false);
    }, [props.value, props.error]);


    const searchBox = () => {
        return <div>
            <StandaloneSearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                onPlacesChanged={props.onPlacesChanged}
            >
                <CustomInput
                  readOnly={readOnly}
                  className={"custom-google-map-search-component " + className}
                  label={label}
                  value={value}
                  placeholder={placeholder}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  required={required}
                  error={error}
                  onPaste={(e) => {
                    // setValue(e);
                    if(props.onPaste){
                      props.onPaste(e);
                    }   
                  }} 
                  onChange={(e) => {
                    // setValue(e);
                    if(props.onChange){
                      props.onChange(e);
                    }                    
                  }}
                  onBlur={(e) => {
                    if(props.onBlur){
                      props.onBlur(e);
                    }                    
                  }}
                  onClick={(e) => {
                    if(props.onClick){
                      props.onClick(e);
                    }
                    
                  }}
                />
            </StandaloneSearchBox>
            
        </div>
    }
    
    return searchBox();
});


export default CustomGoogleMapSearch;