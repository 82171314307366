import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";

import CustomToolbar from './CustomToolbar.jsx';
import CustomToolbarDisabled from './CustomToolbarDisabled.jsx';

import moment from 'moment'
const localizer = momentLocalizer(moment);


class CustomCalendar extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let events = (props.events && props.events.length > 0) ? props.events : [];
        let date = (props.date) ? props.date : new Date();
        let view = (props.view) ? props.view : 'month';
        let isLoading = (props.isLoading) ? props.isLoading : false;
        this.state = {
            className: className,
            events: events,
            date: date,
            view: view,
            isLoading: isLoading
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.view !== this.state.view) {
			this.setState({
				view: nextProps.view
			});
		}
		if (nextProps.events !== this.state.events) {
            let events = (nextProps.events && nextProps.events.length > 0) ? nextProps.events : [];
			this.setState({
				events: events
			});
        }
        // if (nextProps.date !== this.state.date) {
        //     let date = (nextProps.date) ? nextProps.date : new Date();
		// 	this.setState({
		// 		date: date
		// 	});
		// }
        if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
	}

    // Custom cell style function
    getCellStyles = (date, selected) => {
        // Add your custom styles here based on the date and selected state
        return {
        style: {
            backgroundColor: selected ? 'blue' : 'transparent',
            // Add any other styles you want
        },
        };
    };
    
    render() {
        return <Box className={'custom-calendar-component ' + this.state.className}>
             <Calendar
                localizer={localizer}
                events={this.state.events}
                defaultDate={this.state.date}
                view={this.state.view}
                views={['day', 'week', 'month']}
                isLoading= {this.state.isLoading}
                components = {{
                    // toolbar : !this.state.isLoading? CustomToolbar : CustomToolbarDisabled
                    toolbar : CustomToolbar,
                    week: {
                        // Apply custom styles to the cells
                    eventContainerWrapper: ({ children, date, range, selected }) => {
                        const { style } = this.getCellStyles(date, selected);
                        return (
                        <div style={style}>
                            {children}
                        </div>
                        );
                    },
                    },
                }}
                onSelectEvent={(item) => {
                    if(this.props.onClick){
                        this.props.onClick(item);
                    }
                }}
                onNavigate={(date, type) => {
                    if(this.props.onNavigate){
                        this.props.onNavigate(date, type);
                    }
                }}
                onView={(type) => {
                    if(this.props.onView){
                        this.props.onView(this.state.date, type);
                    }
                }}
                style={{ height: 650 }}
            />
        </Box>
    }
}


export default CustomCalendar;