import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import LDSIcon from '../../assets/img/services-icon2.png';
import ReactDOM from 'react-dom';
import { 
  Box,
  Grid,
  Container,
  Divider,
  InputLabel,
  Select,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as PreviewIcon } from '../../assets/img/icons/view_list_black.svg';
import { ReactComponent as TimeIcon } from '../../assets/img/icons/time.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import RTextEditor from '../../components/RichTextEditor/RTextEditor.jsx';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import CustomTooltip from '../../components/Tooltip/CustomTooltip.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../components/Status/StatusList.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import {RoleAccessService} from '../../data/role-access'; 

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;
 
export class JobBulkUpdateByDo extends Component {
  static contextType = AuthContext;
  constructor(props){
    super(props); 
    this.pageName = 'Bulk Update By DO Number';
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    
    
    // const grantedAccess = new RoleAccessService();
    // const currentRole = window.localStorage.getItem('current_role') || null;
    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      selected_do_number: '',
      selected_job_date: '',
      selected_job_type: '',
      order_status_id: '',
      order_status : '',
      drop_off_date:'',
      drop_off_worker_id: '',
      drop_off_worker: '',
      drop_off_description: '',
      driver_notes:'',
      driver_payout: '',
      drop_off_time_planned: '',
      job_type:'',
      do_number: '',
      drop_off_postal_code: '',
      selected_job_dateValidated: false,
      selected_do_numberValidated: false,
      isLoading: false,
    }
  }
  

  componentDidMount() {
    this.callOrderStatusesApi()
  }

  callWorkerDriverReadApi = (search = '', callback = null) => {

    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  //Call Order Status Api
  callOrderStatusesApi = (search = '', callback = null) => {

    const { accessToken } = this.context;

    ldsApi.read('order_statuses', null, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data && data.length > 0) ? data : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = item.status;

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: arr,
            }, () => {
              if(callback){
                callback(arr);
              }
            });
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  /*START BATCH UPDATE*/
  batchUpdateApi = (data, callback = null) =>{

    const { accessToken } = this.context;
    let row = {}
    row.data = []


    row.data.push(data)

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : null;

            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            // console.log("error", error)
            apiUtil.toast(error, 'warning', 'error');
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
    
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    
    if(this.state.selected_job_date === '' ) {
      this.setState({
        selected_job_dateValidated: true,
      })
      apiUtil.toast('Please select job date!', 'warning', 'error');
    }

    if(this.state.selected_do_number === '' ) {
      this.setState({
        selected_do_numberValidated: true,
      })
      apiUtil.toast('Empty D.O Numbers', 'warning', 'error');
      return
    }
    if( this.state.order_status === ''  && 
        this.state.drop_off_date === '' &&
        this.state.do_number === '' &&
        this.state.drop_off_description === '' &&
        this.state.drop_off_worker === '' &&
        this.state.job_type === '' &&
        this.state.drop_off_time_planned === '' &&
        this.state.driver_payout === '' &&
        this.state.driver_notes === '' &&
        this.state.drop_off_postal_code === '' ) {
      apiUtil.toast('Please select at least one value to update', 'warning', 'error');
      return
    }
    if( this.state.order_status == 'Assigned' && this.state.drop_off_worker_id == '') {
      apiUtil.toast('please add assigned driver', 'warning', 'error');
      return
    }
    this.setState({
      selected_job_dateValidated: false,
      selected_job_typeValidated: false,
  });

    // console.log("first")
    if (form.checkValidity() === true) {
      let data = {};
      let selected_do_number = this.state.selected_do_number.replace(/\n|\r|,/g, ",")
      if(this.state.drop_off_worker_id != '') {
        data = {
          selected_do_number: selected_do_number,
          selected_job_date: this.state.selected_job_date,
          selected_job_type: this.state.selected_job_type,
          order_status_id: this.state.order_status_id,
          drop_off_worker_id: this.state.drop_off_worker_id,
          drop_off_date: this.state.drop_off_date,
          do_number: this.state.do_number,
          drop_off_description: this.state.drop_off_description,
          drop_off_time_planned: this.state.drop_off_time_planned,
          driver_payout: this.state.driver_payout,
          driver_notes: this.state.driver_notes,
          drop_off_postal_code: this.state.drop_off_postal_code,
          job_type: this.state.job_type,
        }
      } else if (this.state.order_status == 'Not Assigned') {
        data = {
          selected_do_number: selected_do_number,
          selected_job_date: this.state.selected_job_date,
          selected_job_type: this.state.selected_job_type,
          order_status_id: this.state.order_status_id,
          drop_off_worker_id: this.state.drop_off_worker_id,
          drop_off_date: this.state.drop_off_date,
          do_number: this.state.do_number,
          drop_off_description: this.state.drop_off_description,
          drop_off_time_planned: this.state.drop_off_time_planned,
          driver_payout: this.state.driver_payout,
          driver_notes: this.state.driver_notes,
          drop_off_postal_code: this.state.drop_off_postal_code,
          job_type: this.state.job_type,
        }
      } else {
        data = {
          selected_do_number: selected_do_number,
          selected_job_date: this.state.selected_job_date,
          selected_job_type: this.state.selected_job_type,
          order_status_id: this.state.order_status_id,
          // drop_off_worker_id: this.state.drop_off_worker_id,
          drop_off_date: this.state.drop_off_date,
          do_number: this.state.do_number,
          drop_off_description: this.state.drop_off_description,
          drop_off_time_planned: this.state.drop_off_time_planned,
          driver_payout: this.state.driver_payout,
          driver_notes: this.state.driver_notes,
          drop_off_postal_code: this.state.drop_off_postal_code,
          job_type: this.state.job_type,
        }
      }
     

      // console.log("second")
      this.batchUpdateApi(data, (result) => {
        if(result) {
          this.props.history.push({
            pathname: '/job-list',
          });
          apiUtil.toast(result.length +' orders is successfully Updated', 'check_circle');
        } 
      })

    } else {
      this.setState({
        isLoading: false
      })
    }

  }

   /* STICKY PAGE HEADER */
   customStickyPageHeader = () => {
    const {  isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */
  bulkUpdateForm = () => {
    return <Box >
        <Grid container>
            <Grid item xs={12}>
                <CustomLabel
                    label={'Bulk Update'}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
                <Box mt={3} mb={5}>
                  <Grid container spacing={10}>
                    <Box clone  pr={{ xs: 0, md: 2 }} pb={2} style={{width:'50%'}}>
                      <Grid item xs={12} md={6}>
                      <CustomInput
                      helperText={<Box>
                        <Grid container alignItems={'center'}>
                            <span style={{fontSize:"13px", fontFamily: "hk_groteskmedium"}}>Copy and paste / enter / DO number into the box. One D.O. No. per line.</span>
                        </Grid> 
                        </Box>
                      }  
                      label={<>D.O Number<span style={{color:"red", size:"20px"}}>*</span></>}
                      multiline={true}
                      rows={7}
                      value={this.state.selected_do_number}
                      error={this.state.selected_do_numberValidated}
                      require={true}
                      onChange={(e) => {
                        this.setState({
                            selected_do_number: e.target.value,
                            selected_do_numberValidated: (e.target.value !== '') ? false : true,
                        });
                      }}
                      />
                      
                        {/* <Box>
                          <CustomLabel label="Do Numbers"/>
                        </Box>
                      <textarea  onChange={e => this.setState({selected_do_number: e.target.value}) } cols="50" rows="10"></textarea> */}
                      </Grid>
                    </Box>
                    <Box clone  pr={{ xs: 0, md: 2 }} pb={2}>
                      <Grid item xs={12} md={6}>
                      <Grid container>
                        <Box clone  sx={{ width: 0.5 }}>
                          <Grid item xs={12} md={12}>
                          <CustomInput
                          label={<>Select Job Date<span style={{color:"red", size:"20px"}}>*</span></>}
                          placeholder={'DD/MM/YYYY'}
                          color={'secondary'}
                          type={"date"}
                          endAdornment={(obj) => {
                            return <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <CalendarIcon/>
                            </Box>
                          }}
                          value={this.state.selected_job_date}
                          error={this.state.selected_job_dateValidated}
                          required={true}
                          onChange={(e) => {
                              this.setState({
                                selected_job_date: e.target.value,
                                selected_job_dateValidated: (e.target.value !== '') ? false : true,
                              });
                          }}
                      />
                          </Grid>
                        </Box>
                        <Box clone  pr={{ xs: 0, md: 0 }} >
                          <Grid item xs={12} md={12}>
                            <Box className="custom-label-component">
                              <InputLabel >Job Type</InputLabel>
                            </Box>
                            <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                              placeholder={'Enter job type here...'}
                              value={this.state.selected_job_type}
                              native
                              disableUnderline
                              onChange={(e) => {
                                this.setState({
                                  selected_job_type: e.target.value
                                });
                            }}
                            >
                              <option value=""> Select job type </option>
                              <option value={'Standard Delivery'}>Standard Delivery</option>
                              <option value={'Next Day Delivery'}>Next Day Delivery</option>
                              <option value={'Same day Delivery'}>Same day Delivery</option>
                              <option value={'Exchange'}>Exchange</option>
                              <option value={'Collection'}>Pick Up</option>
                            </Select>
                          </Grid>
                        </Box>
                      </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>

                <CustomLabel
                    label={<><PreviewIcon style={{marginBottom:"-6px"}} />Bulk Update Preview</>}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
                <Box mt={3} mb={5}>
                  <Grid container spacing={10}>
                    <Box clone  pr={{ xs: 0, md: 2 }} pb={2} style={{width:'50%'}}>
                      <Grid item xs={12} md={6}>
                      
                      <Grid container>
                      <Box clone>
                        <Grid item xs={12}>
                            <CustomAutosuggest 
                              className={'no-margin'}
                              placeholder={'Job Status'}
                              label={'Job Status'}
                              disabled={this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, "job_assign") && (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) ? false :true}
                              value={this.state.order_status}
                              onChange={(value) => {
                                this.setState({
                                  order_status_id: (value && value.value)? value.value : null,
                                  order_status   : (value && value.label)? value.label : null
                                });
                              }}
                              onSearch={(value, e) => {
                                this.callOrderStatusesApi(value, (arr) => {
                                  e.showNewSuggestions(arr);
                                });
                              }}
                              onClick={(value, e) => {
                                this.callOrderStatusesApi(value, (arr) => {
                                  e.showNewSuggestions(arr);
                                });
                              }}
                            />
                        </Grid>
                      </Box>
                        
                        <Box clone mt={2} >
                          <Grid item xs={12}>
                          <CustomInput
                          label={"Job Date To"}
                          placeholder={'DD/MM/YYYY'}
                          color={'secondary'}
                          type={"date"}
                          endAdornment={(obj) => {
                            return <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <CalendarIcon/>
                            </Box>
                          }}
                          value={this.state.drop_off_date}
                          onChange={(e) => {
                            this.setState({
                              drop_off_date: e.target.value
                            })
                           }}
                          />
                         </Grid>
                        </Box>
                        <Box clone  pr={{ xs: 0, md: 0 }} >
                          <Grid item xs={12} md={12}>
                          <CustomInput
                          label={"Do Number To"}
                          placeholder={'Do Number'}
                          value={this.state.do_number}
                          onChange={(e) => {
                            this.setState({
                              do_number: e.target.value,
                            })
                           }}
                          />
                         </Grid>
                        </Box>
                        <Box clone  pr={{ xs: 0, md: 0 }} >
                          <Grid item xs={12} md={12}>
                            <CustomLabel
                              label={'Job Description To'}
                              size={'ms'}
                            />
                            <RTextEditor
                              value={this.state.drop_off_description}
                              error={this.state.drop_off_descriptionValidated}
                              placeholder={'Enter your description here...'}
                              onChange={(editorState) => {
                                this.setState({
                                    drop_off_description: editorState
                                });
                              }}
                            />
                         </Grid>
                        </Box>
                      </Grid>
                      </Grid>
                    </Box>
                    <Box clone  pr={{ xs: 0, md: 2 }} pb={2}>
                      <Grid item xs={12} md={6}>
                      <Grid container>
                      {this.state.grantedAccess.isShouldShowJobAccess(this.state.currentRole, "job_assign") && (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) && <Box clone mb={2} >
                          <Grid item xs={12} >
                          <CustomAutosuggest 
                            className={'no-margin'}
                            placeholder={'Driver'}
                            label={'Driver'}
                            value={this.state.drop_off_worker}
                            onChange={(value) => {
                              if(value) {
                                  let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                                this.setState({
                                    drop_off_worker_id: value.value,
                                    drop_off_worker: value.label,
                                    order_status_id: orderStatusItem.value,
                                    order_status: orderStatusItem.label
                                });
                              } else {
                                this.setState({
                                  drop_off_worker_id: '',
                                  drop_off_worker: '',
                                  order_status_id: '',
                                  order_status: ''
                              });
                              }
                              
                            }}
                            onSearch={(value, e) => {      
                              this.callWorkerDriverReadApi(value, (arr) => {
                                e.showNewSuggestions(arr);
                              });
                            }}
                            onClick={(value, e) => {
                              this.callWorkerDriverReadApi(value, (arr) => {
                                e.showNewSuggestions(arr);
                              });
                            }}
                          /> 
                          </Grid>
                        </Box> }
                        <Box clone   >
                          <Grid item xs={12} >
                            <Box className="custom-label-component">
                              <InputLabel >Job Type To</InputLabel>
                            </Box>
                            <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                              placeholder={'Enter job type here...'}
                              value={this.state.job_type}
                              native
                              disableUnderline
                              onChange={(e) => {
                                this.setState({
                                    job_type: e.target.value,
                                });
                            }}
                            >
                              <option value=""> Select job type </option>
                              <option value={'Standard Delivery'}>Standard Delivery</option>
                              <option value={'Next Day Delivery'}>Next Day Delivery</option>
                              <option value={'Same day Delivery'}>Same day Delivery</option>
                              <option value={'Exchange'}>Exchange</option>
                              <option value={'Collection'}>Pick Up</option>
                            </Select>
                          </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomInput
                                    label={'Time Window'}
                                    placeholder={'1PM - 6PM'}
                                    type={"text"}
                                    color={'secondary'}
                                    endAdornment={(obj) => {
                                      return <Box
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                      >
                                          <TimeIcon />
                                      </Box>
                                    }}
                                    labelComponent={<CustomTooltip title={'The scheduled date & time of this job.'} />}
                                    value={this.state.drop_off_time_planned}
                                    onChange={(e) => {
                                        this.setState({
                                            drop_off_time_planned: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                        {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) && <Box clone>
                            <Grid item xs={12}>
                            <CustomInput
                                label={'Driver Payout To'}
                                placeholder={'Enter driver payout here...'}
                                value={this.state.driver_payout}
                                type={'number'}
                                startAdornment={<p>$</p>}
                                endAdornment={<span>SGD</span>}
                                onChange={(e) => {
                                    this.setState({
                                        driver_payout: e.target.value,
                                    });
                                }}
                            /> 
                            </Grid>
                        </Box>}
                        <Box clone>
                          <Grid item xs={12}>
                              <CustomInput
                                  label={'Driver Notes To'}
                                  placeholder={'Enter driver notes here...'}
                                  value={this.state.driver_notes}
                                  multiline={true}
                                  rows={5}
                                  onChange={(e) => {
                                      this.setState({
                                          driver_notes: e.target.value,
                                      });
                                  }}
                              />
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12}>
                              <span></span>
                              <CustomInput
                                  label={'Postal Code'}
                                  placeholder={'Postal Code'}
                                  value ={this.state.drop_off_postal_code}
                                  type={'number'}
                                  labelComponent={<CustomTooltip title={'Allow only 6 digits number'} />}
                                  onChange={(e) => {
                                      this.setState({
                                          drop_off_postal_code: Math.max(0, parseInt(e.target.value) ).toString().slice(0,5),
                                          isFormChanged: true,
                                      });
                                  }}
                              />
                          </Grid>
                      </Box>
                      </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
              
                    <CustomButton 
                      className={'create-job-step'}
                      type={'submit'}
                      color={'secondary'}
                      isLoading={this.state.isLoading}
                    ><Box pl={2} pr={2}>Submit</Box>
                    </CustomButton>
                  
              
                  </Grid>
                </Box>
            </Grid>
            
        </Grid>
    </Box>
  }

  render() {
    return <Box className="job-list-page" >
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
        </StickyPageHeader>
        <Container maxWidth="lg" >
          <Card className="overflow-hidden custom-card-margin-x">
            {this.bulkUpdateForm()}
          </Card>
        </Container>

      </form>
    
    </Box>;
  }
}