import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class CustomBarChart extends PureComponent {

  constructor(props){
    super(props);
    
    let data = props.data? props.data : [];
    let label = props.label? props.label : [];
    let type = props.type? props.type : 'full';
    this.state = {
      data : data,
      label : label,
      type : type,
    }
  }

  render() {
    return (
      <div className='w-100' style={{overflowX: 'auto', overflowY: 'hidden', height: 'auto'}}>
      {this.state.data.length > 0 ?
        <BarChart
          width={600}
          height={300}
          data={this.state.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        className='legend-padding-bar-packages'
        >
          <XAxis dataKey={this.state.label}/>
          <YAxis domain={[0, 'dataMax+100']}/>
          <Tooltip
              itemSorter={(item) => {
                switch (item.dataKey) {
                  case "XS":
                    return 1;
                  case "S":
                    return 2;
                  case "M":
                    return 3;
                  case "L":
                    return 4;
                  case "Pallet":
                    return 5;
                  default:
                    return 0;
                }
              }}
            />
          <Legend verticalAlign="bottom" align='left' iconType='circle'/>
          <Bar dataKey="Pallet" stackId="a" fill="#2D68FE" />
          <Bar dataKey="L" stackId="a" fill="#F34D57" />
          <Bar dataKey="M" stackId="a" fill="#914DF3" />
          <Bar dataKey="S" stackId="a" fill="#3CAFA4" />
          <Bar dataKey="XS" stackId="a" fill="#F5A067" />
        </BarChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </div>
    );
  }
}
