import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';


class StatusList extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let items = (props.items && props.items.length > 0) ? props.items : [];
        let selected = (props.selected || props.selected === 0) ? props.selected : null;

        this.state = {
            className: className,
            items: items,
            selected: selected,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.items !== this.state.items) {
            let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
			this.setState({
				items: items
			});
		}
		if (nextProps.selected !== this.state.selected) {
            let selected = (nextProps.selected || nextProps.selected === 0) ? nextProps.selected : null;
			this.setState({
				selected: selected
			});
		}
	}


    render() {
        return <Box className={'custom-status-list-component ' + this.state.className}>
            <ul className="justify-content-between">
                {this.state.items.map((item, i) => {
                    return <li 
                        key={i} 
                        className={(this.state.selected === i) ? 'active' : ''}
                        onClick={() => {
                            if(this.props.onClick){
                                this.props.onClick(i);
                            }
                        }}
                    >
                        {item}
                    </li>
                })}
            </ul>
        </Box>
    }
}


export default StatusList;