import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from 'recharts';

export default class CustomHorizontalBarChart extends PureComponent {

  constructor(props){
    super(props);
    let data = props.data? props.data : null;
    let title = props.title? props.title : null;
    let barList = props.barList ? props.barList : [];
    let axisType = props.axisType ? props.axisType : 'number';
    let dataMax = props.dataMax ? props.dataMax : [0, 'dataMax+40'];
    let prefixValue = props.prefixValue ? props.prefixValue : '';
    let subfixValue = props.subfixValue ? props.subfixValue : '';
    let leftMargin = props.leftMargin ? props.leftMargin : 20;
    
    this.state = {
      data : data,
      title: title,
      barList: barList,
      dataMax: dataMax,
      axisType: axisType,
      prefixValue: prefixValue,
      subfixValue: subfixValue,
      leftMargin: leftMargin
    }
  }
  
  formatYAxis = (value) => {
    value = this.state.title == 'average_drops_per_day' ? parseInt(value).toLocaleString() : parseFloat(value).toFixed(2).toLocaleString(undefined, { useGrouping: true });
    if(this.state.prefixValue != '' || this.state.subfixValue != '') {
      return this.state.prefixValue+''+parseFloat(value).toLocaleString()+''+this.state.subfixValue;
    } else {
      return parseFloat(value).toLocaleString();
    }        
  }

  render() {
    return (
      <div className='w-100' style={{overflowX: 'auto', overflowY: 'hidden', height: 'auto'}}>
        {this.state.data && this.state.data.length > 0 ?
        <BarChart
          width={this.state.data.length > 10 ? 1000 + (40 * this.state.data.length) : 720}
          height={350}
          data={this.state.data}
          margin={{
            top: 5,
            right: 20,
            left: this.state.leftMargin,
            bottom: 100,
          }}
          className='legend-padding-bar overflow-hidden'
        >
          <CartesianGrid strokeDasharray="3 3" />
          { this.state.axisType == 'number' ? <XAxis dataKey="title" angle={-45} textAnchor="end" reversed type="category" interval={0} /> : <XAxis dataKey="title"  type="category" interval={0} />}
          <YAxis type="number" domain={this.state.dataMax} tickFormatter={this.formatYAxis}/>
          <Tooltip />
          { this.state.axisType == 'number' && <Legend align='left' verticalAlign='top' iconType="circle"
           wrapperStyle={{
              paddingBottom: "25px"
          }}/>}
          {this.state.barList.map((obj) => (
            <Bar dataKey={obj.key} barSize={20} fill={obj.color} />
          ))}
        </BarChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </div>
    );
  }
}
