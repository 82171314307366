import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';


export class CompanyDepartmentForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let first_name = (item && item.first_name) ? item.first_name : '';
    let email = (item && item.email) ? item.email : '';
    let contact_no = (item && item.contact_no) ? item.contact_no : '';

    this.state = {
        isLoading: isLoading,
        item: item,

        first_name: first_name,
        first_nameValidated: false,
        email: email,
        emailValidated: false,
        contact_no: contact_no,
        contact_noValidated: false,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Full Name'}
                        placeholder={'Full Name'}
                        value={this.state.first_name}
                        error={this.state.first_nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                first_name: e.target.value,
                                first_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Email Address'}
                        placeholder={'Email Address'}
                        type={'email'}
                        value={this.state.email}
                        error={this.state.emailValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value,
                                emailValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Phone Number'}
                        placeholder={'+65'}
                        value={this.state.contact_no}
                        error={this.state.contact_noValidated}
                        errorText={'Phone number is invalid'}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                contact_no: e.target.value,
                                contact_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        className="close-btn-color"
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Edit Person in charge' : 'Add Person in charge')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
        first_nameValidated: false,
        emailValidated: false,
        contact_noValidated: false,
    });
    
    if(this.state.first_name === ''){
      this.setState({
        first_nameValidated: true
      });
    }
    if(this.state.email === ''){
      this.setState({
        emailValidated: true
      });
    }

    /*let phoneRegEx = /^[+]?\d+$/;
    if(!phoneRegEx.test(this.state.contact_no) || this.state.contact_no === '' || this.state.contact_no.length < 8 || this.state.contact_no.length > 13){
      this.setState({
        contact_noValidated: true,
        isLoading: false
      });
    }*/

    if(!apiUtil.phoneValidationWithPlus(this.state.contact_no) ){
      this.setState({
        contact_noValidated: true,
        isLoading: false
      });
      return
    }
    
    if(this.state.email !== '' && !apiUtil.emailValidation(this.state.email)){
        this.setState({
            emailValidated: true,
            isLoading: false
        });
        return
    }

    if (form.checkValidity() === true) {
        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        first_name: this.state.first_name,
        email: this.state.email,
        contact_no: this.state.contact_no.length === 8 ? '+65' + this.state.contact_no : this.state.contact_no ,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default CompanyDepartmentForm;