import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import Card from '../../../components/Card/Card.jsx';
import { useHistory, Link } from 'react-router-dom';
import OldTable from '../../../components/Table/OldTable.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSwitch from '../../../components/CustomFields/CustomSwitch.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CloseIcon from '@material-ui/icons/Close';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';
import DropdownMenu from '../../../components/Dropdown/DropdownMenu.jsx';
import { ReactComponent as ArrowUpIcon } from '../../../assets/img/icons/arrow-up.svg';
import apiUtil from "../../../api/apiUtil.jsx";
import SettingWhite from '../../../assets/img/icons/setting.svg';
import FilterIcon from '../../../assets/img/filter.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/img/left_menu/arrow-down.svg';
import Api from "../../../api/api.jsx";
import {RoleAccessService} from '../../../data/role-access'; 
import axios from 'axios';
import Tour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faMagnifyingGlass, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import moment from "moment";
import CustomUploadButton from '../../../components/CustomFields/CustomUploadButton.jsx';
import DriverTemplate from "../../../assets/files/Driver_Import_Template.xlsx"
import DriverImportDialog from './Partial/DriverImportDialog.jsx';

/**
 * 
 * update on input type number change. 
 * dedicated component, so the whole table won't be refreshed
 */
let activeInactiveGlobal = 0
export const Transport = props => {
  const history = useHistory();
  const refTableSearch = useRef();
  const refTable = useRef();
  const previousItem = useRef();
  const previousAllowItem = useRef();

  let grantedAccess = new RoleAccessService();
  let role = window.localStorage.getItem('uniqueRolePermissions');
  let currentRole_ = window.localStorage.getItem('current_role');

  const { currentRole, accessToken, isOnBoarding, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
  const [statusBar, setStatusBar] = useState({
    total: 0,
    active: 0,
    disabled: 0
  })
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [total, setTotal] = useState(0);
  const [rowData, setRowData] = useState(null);
  const [activeInactiveItem, setActiveInactiveItem] = useState(activeInactiveGlobal)
  const [allowItem, setAllowItem] = useState(0)
  const [limit, setLimit] = useState(20);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogItem, setOpenDialogItem] = useState(null);
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [openSkipDialog, setOpenSkipDialog] = useState(false)
  const [openImportDialog,setOpenImportDialog] = useState(false)
  const [openImportDialogItem,setOpenImportDialogItem] = useState(null)
  const [openImportDialogIsLoading,setOpenImportDialogIsLoading] = useState(false)
  let grantedMenu = new RoleAccessService();

  useEffect(() => {
    if(isOnBoarding){
      setIsTourOpen(true);
    }
    callReadStatusApi()
  }, [])


  /* STICKY PAGE HEADER */
  const CustomStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <CustomButton 
              className={'new-driver-step'}
              color={'secondary'}
              onClick={() => {
                history.push('/resources/transport-operator-form')
              }}
            >
              + New Driver
            </CustomButton>
          }
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* API */
  const callReadApi = useCallback((search = '' ) => {
    // console.log(search)

    let param ='';
    if ((previousItem.current !== activeInactiveItem) || (previousAllowItem.current !== allowItem) || search !== '' ){
      setPage(1)
    }

     param = {
        page: page,
        take: limit,
        disabled: (activeInactiveItem === 0) ? false : true,
        search: search ? search.trim() : search,
        is_truck_driver: true,
        require_worker_only: true,
        sorting_array: ["driver_profile_id asc","id desc"]
      };
    
      setIsLoading(true);
        let getData = '';
      if (activeInactiveItem === 0) {
        getData = Api.read('workers', param, accessToken)
      } else {
        getData = Api.read('workers', param, accessToken)
      }
      
      setSearchVal(search);
      
      Promise.all([getData]).then( (results) => {
          apiUtil.parseResult(results[0], (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            setData(dataArr);
            setTotal(data.total);
            setIsLoading(false);
          }, (error, type) => {
            setData([]);
            setTotal(0);
            setIsLoading(false);
          });
      });
  }, [accessToken, activeInactiveItem, limit, page, allowItem])

  const callReadStatusApi = () => {
    let param = {
      is_truck_driver: true,
      require_worker_only: true
    };
  
    setIsLoading(true);
    const totalinActivedata = Api.read('workers', {...param, disabled : true}, accessToken)
    const totalActivedata = Api.read('workers', {...param, disabled : false}, accessToken)
    Promise.all([totalinActivedata, totalActivedata]).then( (results) => {
      apiUtil.parseResult(results[0], (data) => {
        const disabledTotal = results[0].data.data.total
        const activeTotal = results[1].data.data.total
        setStatusBar({
          total: activeTotal + disabledTotal,
          active: activeTotal,
          disabled: disabledTotal 
        })
        setIsLoading(false);
      }, (error, type) => {
        setIsLoading(false);
      });
  });

  }
  const callUpdateIsActiveApi = (row, callback = null) => {
    Api.update('workers/' + row.id, row, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        if(callback){
          callback();
        }
      }, (error, type) => {

      });
  });
  }

  const callDeleteApi = (row, callback = null) => {
    // setIsLoading(true);
    let param = {
      destroy_all: true,
    };
    Api.delete('customers/' + row.id, param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        // setIsLoading(false);
        if(callback){
          callback();
        }
      }, (error, type) => {
        // setIsLoading(false);
        if(callback){
          callback();
        }
      });
  });
  }
  /* End API */
  useEffect(() => {
    callReadApi(searchVal);
    previousItem.current = activeInactiveItem
    previousAllowItem.current = allowItem
    return () => {

    }
  }, [page, allowItem, activeInactiveItem])
  /* STATUS */
  const CustomStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <Box className={'custom-circle-icon-component'}>
            <i className="fas fa-truck"></i>
          </Box>
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Driver Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Drivers'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{statusBar.total}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="primary" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Active Driver</span>
            </Badge>}
            count={statusBar.active}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="error" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Disabled Driver</span>
            </Badge>}
            count={statusBar.disabled}
          />
        </Grid>
      </Box>
    </Grid>
  }

  const getItemforAction = () => {
  
    let item = [];

      item.push({          
          id: 1,
          text: <Box display={'inline-block'} >Allow Allocation</Box>,          
      } , {          
        id: 2,
        text: <Box display={'inline-block'} >Disable Allocation</Box>,          
        })

     

    return item;

  }

  const allowAllocation = () => {
    let selectedRow = rowData;
    let ids = '';
    if(selectedRow == '' || selectedRow == null ) {
      apiUtil.toast('You must select driver!', 'warning', 'error');
      return
    } else {
      selectedRow.map((item, i) => { ids += item.id +','; });
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "batch_update/workers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let data = {
      worker_ids: ids,
      is_allow_auto_assign: true
    }
    setIsLoading(true);
      axios.post(
            URL,
            data,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                      if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data.error;
                        apiUtil.toast(errorMessage, 'warning', "error");
                      } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                      }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
               setIsLoading(false);
            }).then((response) => {
              setIsLoading(false);
              setRowData(null)
              callReadApi();
              apiUtil.toast('Successfully allowed driver', 'check_circle', 'success');
            });

  }

  const disableAllocation = () => {
    let selectedRow = rowData;
    let ids = '';

    // if(selectedRow) selectedRow.map((item, i) => { ids.push(item.id); });
    if(selectedRow == '' || selectedRow == null ) {
      apiUtil.toast('You must select driver!', 'warning', 'error');
      return
    } else {
      selectedRow.map((item, i) => { ids += item.id +','; });
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "batch_update/workers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let data = {
      worker_ids: ids,
      is_allow_auto_assign: false,
    }
    setIsLoading(true);
      axios.post(
            URL,
            data,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                      if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data.error;
                        apiUtil.toast(errorMessage, 'warning', "error");
                      } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                      }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
               setIsLoading(false);
            }).then((response) => {
              setIsLoading(false);
              setRowData(null)
              callReadApi();
              apiUtil.toast('Successfully disabled driver', 'check_circle', 'success');

            });
  }

  const customToolbar = () => {
    return <Box>
      <Grid container>
     
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true} pb={2}>
            <GroupButton
              className="driver-tag-style"
              selected={activeInactiveItem}
              buttons={[ "Active Drivers", "Inactive Drivers"]}
              onClick={(selected, btn) => {
                setActiveInactiveItem(selected)
                activeInactiveGlobal = selected
              }}
            />
          </Grid>
        </Box>

      </Grid>
    </Box>
  }

 /* TABLE */
 const CustomTable = () => {
  return <OldTable 
    ref={refTable}
    isLoading={isLoading}
    className={'job-table-list' }  
    limit={limit}
    // grouping={true}
    page={page}
    total={total}
    defaultExpanded={true}
    selection={activeInactiveItem != 1? true : false}
    data={data}
    onPageChange={(page, size) => {
      setLimit(size);
      setPage(page);
   
        callReadApi(refTableSearch.current.state.value);
    
      // useEffect  will call the api again when it load
    }}
    onSelectionChange={(rows, column)=>{
      // console.log("columns", column);
      setRowData(rows);
    }}
    Toolbar={customToolbar}
    columns={[
      { 
        title: "Full Name", 
        field: "first_name",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150            
        },
        render: (row, type) => {
          if(type === 'row'){
            if (currentRole == 'Finance Admin' || currentRole == "BD Admin") {
              return <span>{row.first_name ? row.first_name : ''} &nbsp; {row.last_name ? row.last_name : ''}</span>
            }
            return <span  className={grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Details')  ? "linkable-place-2" : ""}
            style={{textDecoration: "none", overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}
            onClick={()=>{
              if(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Details')) {
                props.history.push({
                  pathname: '/resources/transport-operator/detail',
                  state: {
                    id: row.id,
                  }
                });
              }
            }}
            >{row.first_name ? row.first_name.replace(/\s/g, '\u00A0') : ''} {row.last_name ? row.last_name.replace(/\s/g, '\u00A0') : ''}</span>
          } else {
            return row;
          }
        }
      },  
      { 
        title: "Vehicle Type", 
        field: "type_of_vehicle",
        cellStyle: {
          minWidth: 180,
          maxWidth: 180            
        },
        render: (row, type) => {
          if(type === 'row'){
            let vehicle_type_id =  row.vehicle && row.vehicle.vehicle_type_id ? row.vehicle.vehicle_type_id : ''
            return vehicle_type_id == 1 ? "Car" : vehicle_type_id == 2 ? "MPV" : vehicle_type_id == 3 ? "1.7m Van" : vehicle_type_id == 4 ? "2.4m Van" : vehicle_type_id == 5 ? "10 Ft Truck" : vehicle_type_id == 6 ? "14 Ft Truck" : vehicle_type_id == 7 ? "Walker" : ''
          } else {
            return row;
          }
        }
      },
      { 
        title: "Vehicle Model", 
        field: "vehicle_model",
        cellStyle: {
          minWidth: 180,
          maxWidth: 180            
        },
        customSort: (a,b) => (a.vehicle && a.vehicle.model ? a.vehicle.model : '').toString().toLowerCase().trim().localeCompare((b.vehicle && b.vehicle.model? b.vehicle.model : '').toString().toLowerCase().trim()),
        render: (row, type) => {
          if(type === 'row'){
            return row.vehicle && row.vehicle.model ? row.vehicle.model : ''
          } else {
            return row;
          }
        }
      },
      { 
        title: "Driver Profile", 
        field: "driver_profile",
        cellStyle: {
          minWidth: 180,
          maxWidth: 180            
        },
        customSort: (a,b) => (a.driver_profile && a.driver_profile.name ? a.driver_profile.name : '').toString().toLowerCase().trim().localeCompare((b.driver_profile && b.driver_profile.name? b.driver_profile.name : '').toString().toLowerCase().trim()),
        render: (row, type) => {
          if(type === 'row'){
            return row.driver_profile && row.driver_profile.name ? row.driver_profile.name : ''
          } else {
            return row;
          }
        }
      },
      { 
        title: "Available Hours", 
        field: "start_time",
        cellStyle: {
          minWidth: 300,
          maxWidth: 300            
        },
        sorting: false,
        render: (row, type) => {
          if(type === 'row'){
            let startTime = '9:30';
            let endTime = '20:00';
            
            if(row.available_time) {
              const available_hour = row.available_time.split("-");
              startTime = available_hour[0].trim();
              endTime = available_hour[1].trim();
            }

            return <>
              <Select
                disabled={currentRole == 'BD Admin' || currentRole == 'Finance Admin' ? true : false}
                className="select-width-control-100 custom-input-component"
                style={{
                  width: "50%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={startTime}
                native
                disableUnderline
                onChange={(e) => {
                  let start_moment_time = moment(e.target.value,'hh:mm');
                  let end_moment_time = moment(endTime,'hh:mm');
                  let diff_time = end_moment_time.diff(start_moment_time, 'hours',true);
                  if (start_moment_time.format('HH:mm') == end_moment_time.format('HH:mm') || diff_time < 0) {
                    apiUtil.toast('Start time can not late than end time.', 'check_circle');
                    return;
                  }
                  row.available_time = e.target.value+' - '+endTime
                  callUpdateIsActiveApi(row, () => {
                    callReadApi(refTableSearch.current.state.value);
                    callReadStatusApi()
                    apiUtil.toast('Successfully Updated!', 'check_circle');
                  });
                }}
              >
                <option value="0:00"> 0:00 </option>
                 <option value="0:30"> 0:30 </option>
                 <option value="1:00"> 1:00 </option>
                 <option value="1:30"> 1:30 </option>
                 <option value="2:00"> 2:00 </option>
                 <option value="2:30"> 2:30 </option>
                 <option value="3:00"> 3:00 </option>
                 <option value="3:30"> 3:30 </option>
                 <option value="4:00"> 4:00 </option>
                 <option value="4:30"> 4:30 </option>
                 <option value="5:00"> 5:00 </option>
                 <option value="5:30"> 5:30 </option>
                 <option value="6:00"> 6:00 </option>
                 <option value="6:30"> 6:30 </option>
                 <option value="7:00"> 7:00 </option>
                 <option value="7:30"> 7:30 </option>
                 <option value="8:00"> 8:00 </option>
                 <option value="8:30"> 8:30 </option>
                 <option value="9:00"> 9:00 </option>
                 <option value="9:30"> 9:30</option>
                 <option value="10:00"> 10:00 </option>
                 <option value="10:30"> 10:30 </option>
                 <option value="11:00"> 11:00 </option>
                 <option value="11:30"> 11:30 </option>
                 <option value="12:00"> 12:00 </option>
                 <option value="12:30"> 12:30 </option>
                 <option value="13:00"> 13:00 </option>
                 <option value="13:30"> 13:30 </option>
                 <option value="14:00"> 14:00 </option>
                 <option value="14:30"> 14:30 </option>
                 <option value="15:00"> 15:00 </option>
                 <option value="15:30"> 15:30 </option>
                 <option value="16:00"> 16:00 </option>
                 <option value="16:30"> 16:30 </option>
                 <option value="17:00"> 17:00 </option>
                 <option value="17:30"> 17:30 </option>
                 <option value="18:00"> 18:00 </option>
                 <option value="18:30"> 18:30 </option>
                 <option value="19:00"> 19:00 </option>
                 <option value="19:30"> 19:30 </option>
                 <option value="20:00"> 20:00 </option>
                 <option value="20:30"> 20:30 </option>
                 <option value="21:00"> 21:00 </option>
                 <option value="21:30"> 21:30 </option>
                 <option value="22:00"> 22:00 </option>
                 <option value="22:30"> 22:30 </option>
                 <option value="23:00"> 23:00 </option>
              </Select>       
              <Select
                className="select-width-control-100 custom-input-component"
                disabled={currentRole == 'BD Admin' || currentRole == 'Finance Admin' ? true : false}
                style={{
                  width: "50%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={endTime}
                native
                disableUnderline
                onChange={(e) => {
                  let start_moment_time = moment(startTime,'hh:mm');
                  let end_moment_time = moment(e.target.value,'hh:mm');
                  let diff_time = end_moment_time.diff(start_moment_time, 'hours',true);
                  if (start_moment_time.format('HH:mm') == end_moment_time.format('HH:mm') || diff_time < 0) {
                    apiUtil.toast('End time can not eariier than start time.', 'check_circle');
                    return;
                  }
                  row.available_time = startTime+' - '+e.target.value;
                  callUpdateIsActiveApi(row, () => {
                    callReadApi(refTableSearch.current.state.value);
                    callReadStatusApi()
                    apiUtil.toast('Successfully Updated!', 'check_circle');
                  });
                }}
              >
                <option value="0:00"> 0:00 </option>
                 <option value="0:30"> 0:30 </option>
                 <option value="1:00"> 1:00 </option>
                 <option value="1:30"> 1:30 </option>
                 <option value="2:00"> 2:00 </option>
                 <option value="2:30"> 2:30 </option>
                 <option value="3:00"> 3:00 </option>
                 <option value="3:30"> 3:30 </option>
                 <option value="4:00"> 4:00 </option>
                 <option value="4:30"> 4:30 </option>
                 <option value="5:00"> 5:00 </option>
                 <option value="5:30"> 5:30 </option>
                 <option value="6:00"> 6:00 </option>
                 <option value="6:30"> 6:30 </option>
                 <option value="7:00"> 7:00 </option>
                 <option value="7:30"> 7:30 </option>
                 <option value="8:00"> 8:00 </option>
                 <option value="8:30"> 8:30 </option>
                 <option value="9:00"> 9:00 </option>
                 <option value="9:30"> 9:30</option>
                 <option value="10:00"> 10:00 </option>
                 <option value="10:30"> 10:30 </option>
                 <option value="11:00"> 11:00 </option>
                 <option value="11:30"> 11:30 </option>
                 <option value="12:00"> 12:00 </option>
                 <option value="12:30"> 12:30 </option>
                 <option value="13:00"> 13:00 </option>
                 <option value="13:30"> 13:30 </option>
                 <option value="14:00"> 14:00 </option>
                 <option value="14:30"> 14:30 </option>
                 <option value="15:00"> 15:00 </option>
                 <option value="15:30"> 15:30 </option>
                 <option value="16:00"> 16:00 </option>
                 <option value="16:30"> 16:30 </option>
                 <option value="17:00"> 17:00 </option>
                 <option value="17:30"> 17:30 </option>
                 <option value="18:00"> 18:00 </option>
                 <option value="18:30"> 18:30 </option>
                 <option value="19:00"> 19:00 </option>
                 <option value="19:30"> 19:30 </option>
                 <option value="20:00"> 20:00 </option>
                 <option value="20:30"> 20:30 </option>
                 <option value="21:00"> 21:00 </option>
                 <option value="21:30"> 21:30 </option>
                 <option value="22:00"> 22:00 </option>
                 <option value="22:30"> 22:30 </option>
                 <option value="23:00"> 23:00 </option>
              </Select>               
            </>
          } else {
            return row;
          }
        }
      },
      { 
        title: "Priority", 
        field: "priority",
        cellStyle: {
          minWidth: 150,
          maxWidth: 150            
        },
        sorting: false,
        hidden: false,
        render: (row, type) => {
          let value = row.priority !== null ? row.priority : 0;
          if(type === 'row'){
            return <TextField
                  id="outlined-number"
                  disabled={(currentRole == 'Finance Admin' || currentRole == 'BD Admin') && true}
                  type="number"
                  defaultValue={value}
                  // InputProps={{
                  //   inputProps: { min: 0, max: 5}
                  // }}
                  onChange={(e)=> {
                    row.priority = e.target.value;
                      callUpdateIsActiveApi(row, () => {
                        callReadApi(refTableSearch.current.state.value);
                        callReadStatusApi()
                        apiUtil.toast('Successfully Updated!', 'check_circle');
                      });
                  }}
                  variant="outlined"
                  onPointerLeave={()=> console.log("leave up")}
                  onLostPointerCapture={()=> console.log("capture")}
                />
            
          } else {
            return row;
          }
        }
      },
      { 
        title: "Allocation Status", 
        align: 'status',
        cellStyle: {
          minWidth: 150,
          maxWidth: 150            
        },
        sorting: false,
        render: (row) => {
          return <Box>
            <Grid container justify={'center'}>
              <Box clone>
                <Grid item xs={'auto'}>
                <CustomSwitch 
                    checked={!row.disabled}
                    disabled={grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Edit') && currentRole != 'BD Admin'? false : true}
                    onChange={(isChecked) => {
                      row.disabled = !isChecked;
                      callUpdateIsActiveApi(row, () => {
                        callReadApi(refTableSearch.current.state.value);
                        callReadStatusApi()
                        apiUtil.toast('Successfully Updated!', 'check_circle');
                      });
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        }
      },
    ]}
  />
}

  const CustomOnboardingTable = () => {
    return <OldTable 
      ref={refTable}
      isLoading={isLoading}
      className={'job-table-list' }  
      limit={limit}
      // grouping={true}
      page={page}
      total={total}
      defaultExpanded={true}
      selection={true}
      data={data}
      onPageChange={(page, size) => {
        setLimit(size);
        setPage(page);
     
          callReadApi(refTableSearch.current.state.value);
      
        // useEffect  will call the api again when it load
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        setRowData(rows);
      }}
      Toolbar={customToolbar}
      columns={[
        { 
          title: "Driver", 
          field: "email",
        },  
        { 
          title: "First Name", 
          field: "first_name",
        },
        { 
          title: "Last Name", 
          field: "last_name"
        },
        { 
          title: "Vehicle Number", 
          render: (row) => {
            let vehicleNo = '';
            if (row.vehicle && row.vehicle.plate_no) {
              vehicleNo = row.vehicle.plate_no
            }
            return vehicleNo;
          }
        },
        { 
          title: "Phone Number", 
          field: "contact_no"
        },
        { 
          title: "Driver ID", 
          field: "id"
        },
        { 
          title: "ACTION", 
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <div style={{padding:"1em", color:'#28a745'}} data-tooltip="Approve Driver" data-tooltip-conf="danger">
                  <FontAwesomeIcon icon={faCheck}  className="tooltip"/>
                </div>
                <div style={{padding:"1em", color:'#CF3E3E'}} data-tooltip="Reject Driver" data-tooltip-conf="danger">
                  <FontAwesomeIcon icon={faXmark}  className="tooltip"/>
                </div>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }
  /* DIALOG */
  const CustomDialogPopup = () => {
    // console.log(openDialogItem)
    return <CustomDialog 
      open={openDialog}
      title={'Delete'}
      onClose={() => {
        setOpenDialog(false);
        setOpenDialogItem(null);
      }}
      onOk={() => {
        let row = openDialogItem; 
        callDeleteApi(row, () => {
          setOpenDialog(false);
          setOpenDialogItem(null);
          callReadApi(searchVal);
          callReadStatusApi()
          apiUtil.toast('Successfully Deleted', 'check_circle');
          // });
        });
      }}
    >
      {/* add openDialog item worker name */}
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */

  
  /* STEPPER */
  const setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={2}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            history.push('/customers');
          } else if(index === 1){
            history.push('/resources/vehicle');
          } else if(index === 2){
            // history.push('/resources/transport-operator');
          } else if(index === 3){
            history.push('/job-list');
          }
        }}
      />
      <Box textAlign={'center'}>This is the driver list page. Click <b className={'txt-second'}>New Driver</b> to begin</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              setOpenSkipDialog(true);
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */
  
  
  const callImportApi = useCallback((row, callback = null) => {
    setIsLoading(true);
    Api.create('create/drivers/multiple', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          setIsLoading(false);
          if(callback){
            callback(data);
          }
        }, (error, type) => {
          setIsLoading(false);
          if(callback){
            callback(null);
          }
        });
    });
  }, [accessToken])
  
  const importDialog = () => {
    return <CustomDialog 
      open={openImportDialog}
      isLoading={openImportDialogIsLoading}
      title={'Import Drivers'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
          setOpenImportDialog(false);
          setOpenImportDialogItem(null);
      }}
    >
      <DriverImportDialog
        file={openImportDialogItem}
        onClose={() => {
          setOpenImportDialog(false);
          setOpenImportDialogItem(null);
        }}
        onImport={(form) => {
          setOpenImportDialogIsLoading(true);
          callImportApi(form, (data) => {
            let all_customers = (form && form.length > 0) ? form.length : 0;
            let failed_driver = (data && data.failed_driver && data.failed_driver.length > 0) ? data.failed_driver.length : 0;
            let saved_driver = (data && data.saved_driver && data.saved_driver.length > 0) ? data.saved_driver.length : 0;
            
            if(failed_driver > 0){
              apiUtil.toast(failed_driver + ' of ' + all_customers + ' has not been successfully imported, Username already exist', 'check_circle', 'error');
            }

            if(saved_driver > 0){
              apiUtil.toast(saved_driver + ' of ' + all_customers + ' driver(s) has been successfully imported', 'check_circle', 'success');
            }

            callReadApi();
            setOpenImportDialog(false);
            setOpenImportDialogItem(null);
            setOpenImportDialogIsLoading(false);
          });
        }}
      />
    </CustomDialog>
  }

  /* TOUR */
  const setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.new-driver-step',
            content: <Box>
              <h2>Create Driver</h2>
              <p>Click here to create a new driver</p>
            </Box>,
          },
        ]}
        isOpen={isTourOpen}
        rounded={10}
        // accentColor={'#64CCC9'}
        accentColor={'#007CDE'}
        // badgeContent={'#64CCC9'}
        badgeContent={'#007CDE'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={false}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          setIsTourOpen(false);
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  const skipDialog = () => {
    return <CustomDialog 
      open={openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        setOpenSkipDialog(false);
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          setOnBoardingCustomer(null);
          setOnBoardingVehicle(null);
          setOnBoardingDriver(null);
          setOnBoardingJob(null);

          setOpenSkipDialog(false);

          apiUtil.toastOnBoarding('Done', 'check_circle');
          history.push('/')
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  return <Container fluid>
    {/* <StickyPageHeader>
        <CustomStickyPageHeader />
    </StickyPageHeader> */}
     <Card className="overflow-hidden">
          <div className="row border-0 m-0 ">
            <div className="col-xs-12 col-md-12 col-lg-6 d-flex justify-content-start align-items-center mb-2">
            {/* <div className="row d-flex max-xs-width-control justify-content-start align-items-center mb-2"> */}
            <div className="d-flex justify-content-start align-items-center">
            <CustomInput
            className="input-custom-style"
             ref={refTableSearch}
              placeholder={'Search Driver'}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              value={searchVal}
              onPressEnter={(e) => {
                callReadApi(searchVal);
              }}
            />
            {/* </div>
            <div className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center"> */}
            <Button className="upload-style w-100 max-height-btn" onClick={(e) => {
                    callReadApi(searchVal);  
                  }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
            <a className="text-decoration-none ps-2" onClick={()=> {
              setSearchVal('');
              callReadApi();
            }}><img src={FilterIcon} width="25px" className="d-flex justify-content-center align-items-center svg-primary"/></a>
            </div>
            {/* </div> */}
            </div>
            <div className="col-xs-12 col-md-12 col-lg-6 d-flex justify-content-end align-items-center mb-2">
             <div className=" position-relative mt-4">
               {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) &&
                 <CustomUploadButton 
                   className={currentRole=="Ops Admin" ? "customer-upload-xls-disabled" : "customer-upload-xls"}
                   color={'primary'}
                   accept={'.xls, .xlsx'}
                   disabled={currentRole=="Ops Admin" ? true : false}
                   onChange={(files) => {
                     if(currentRole != "Ops Admin" && files && files.length > 0){
                       setOpenImportDialog(true);
                       setOpenImportDialogItem(files[0]);
                     }
                   }}
                 >
                   {/* <ImportIcon /> */}
                   Import Driver
                 </CustomUploadButton>}
              {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <Link to={DriverTemplate} target="_blank" download style={{ textDecoration: 'none',fontSize: 10 }}>download driver template</Link>}
              </div>
              {grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')  && <Button className="justify-self-end force-width" 
               onClick={()=>{
                props.history.push({
                  pathname: '/resources/transport-operator/detail',
                  state: {
                    id: null,
                  }
                });
              }}
              ><FontAwesomeIcon icon={faPlus}/> New Driver-Partner</Button>}
            </div>
          </div>
      </Card> 

    <Card>
      {activeInactiveItem != 2 ?
      <CustomTable /> :
      <CustomOnboardingTable />
      }
    </Card>
    <CustomDialogPopup />
    {setTour()}
    {skipDialog()}
    {importDialog()}
  </Container>;
}