import React from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
    Box,
} from '@material-ui/core';


const RTextEditor = (props) => {
    const [className] = React.useState(props.className ? props.className : '')
    const [disabled] = React.useState(props.disabled ? props.disabled : '')
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : '')

    const initValue = (value) => {
        if (value && value !== '') {
            const contentBlock = htmlToDraft(props.editorState);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState
        } else {
            return EditorState.createEmpty();
        }
    }

    const [editorState, setEditorState] = React.useState(initValue(props.editorState))


    React.useEffect(() => {
        setEditorState(initValue(props.editorState));
    }, []);

      
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)

        let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (props.onChange) {
            props.onChange(value);
        }
    }
    
    return <Box className={"rich-text-editor-component " + className}>
        <Editor
            editorState={editorState}
            placeholder={placeholder}
            disable={disabled}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
                options: ['inline', 'list', 'textAlign', 'link'],
                inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                },
                list: {
                    options: ['unordered', 'ordered'],
                },
                textAlign: {
                    options: ['left', 'center', 'right', 'justify'],
                }
            }}
        />
    </Box>
}


export default RTextEditor;