import React, { Component } from 'react';

import {
  Box
} from '@material-ui/core';

import Switch from "react-switch";


class CustomSwitch extends Component {
  
  constructor(props){
    super(props);

    let checked = ((this.props.checked === true || this.props.checked === false) ? this.props.checked : false);
    let checkedIcon = (this.props.checkedIcon) ? this.props.checkedIcon : null;
    let uncheckedIcon = (this.props.uncheckedIcon) ? this.props.uncheckedIcon : null;
    // let onColor = (this.props.onColor) ? this.props.onColor : "#2e86aa";
    let onColor = (this.props.onColor) ? this.props.onColor : "#007CDE";
    // let onColor = (this.props.onColor) ? this.props.onColor : "#2EC1AC";
    let offColor = (this.props.offColor) ? this.props.offColor : "#7D7D7D";
    let width = (this.props.width) ? this.props.width : 35;
    let height = (this.props.height) ? this.props.height : 20;
    let disabled = (this.props.disabled) ? true : false;

    this.state = {
        checked: checked,
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        onColor: onColor,
        offColor: offColor,
        width: width,
        height: height,
        disabled: disabled
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.checked
      });
    }
    if (nextProps.disabled !== this.state.disabled) {
      this.setState({
        disabled: nextProps.disabled
      });
    }
  }


  handleChange = (checked)  => {
    this.setState({
        checked: checked
    });

    if(this.props.onChange)
      this.props.onChange(checked);
  };


  render() {
    return <Box className="custom-swich-component">
      <Switch
        width={this.state.width}
        height={this.state.height}
        checked={this.state.checked}
        onChange={this.handleChange}
        checkedIcon={this.state.checkedIcon}
        uncheckedIcon={this.state.uncheckedIcon}
        onColor={this.state.onColor}
        offColor={this.state.offColor}
        disabled={this.state.disabled}
      />
    </Box>;
  }
}

export default CustomSwitch;
