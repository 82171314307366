import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from "../Auth/AuthDataProvider.jsx";
import Footer from "../../views/Footer/Footer.jsx"

import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/logo_text_white.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as LiveTrackingIcon } from '../../assets/img/left_menu/live-tracking.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';
import { ReactComponent as VendorIcon } from '../../assets/img/left_menu/vendor.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/img/left_menu/resources.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faChartColumn, faFileLines, faGear, faLocationDot, faMessage, faTruck, faUsers } from '@fortawesome/free-solid-svg-icons'
import {RoleAccessService} from '../../data/role-access'; 
const CustomMenuItem = (props) => {
  let {text, icon, url, active, items, onDrawerToggle} = props;
  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      // onClick = {() => onDrawerToggle? onDrawerToggle(): ""}
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <Link to={(items && items.length > 0) ? "" : url}
        onClick={(e) => {
          // e.stopPropagation();

          if(items && items.length > 0){
            e.preventDefault();
            setOpen(!open);
            
            if(onDrawerToggle){
              onDrawerToggle()
            }
          }
        }}
        className="d-flex flex-column"
      >
        {icon ? <div className="icon-style">{icon}</div> : <span className={'no-icon'}></span>}
        <span className="nav-text">{text}</span>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </Link>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;
  
  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  } 

  // get the the item list of resource for new role
  getResourceItemListForNewRole = (currentRole) => {
    let grantedMenu = new RoleAccessService();

    // 'Transport operator', 'Logistic assistance', 'Vehicle'
    let item_list = [] ;
    if( ! grantedMenu.admin_roles.includes(currentRole)) {
      if (grantedMenu.getUniqueRolePermission().includes('transport_operator')) {
          item_list.push(<CustomMenuItem
            onDrawerToggle={this.props.onDrawerToggle} 
            text={'Driver'}
            url={'/resources/transport-operators'}
            active={(this.props.location.pathname === "/resources/transport-operators" ? true : false)}
          />,
          <CustomMenuItem
            onDrawerToggle={this.props.onDrawerToggle} 
            text={'Driver Assistant'}
            url={'/resources/logistic-assistance'}
            active={(this.props.location.pathname === "/resources/logistic-assistance" ? true : false)}
          /> );
      }
      if (grantedMenu.getUniqueRolePermission().includes('resources_vehicle')) {
        item_list.push( <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle}   
          text={'Vehicle'}
          url={'/resources/vehicle'}
          active={(this.props.location.pathname === "/resources/vehicle" ? true : false)}
        /> );
      }
      return item_list;
    }
    return item_list = [
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle} 
        text={'Driver'}
        url={'/resources/transport-operators'}
        active={(this.props.location.pathname === "/resources/transport-operators" ? true : false)}
      />,
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle} 
        text={'Driver Assistant'}
        url={'/resources/logistic-assistance'}
        active={(this.props.location.pathname === "/resources/logistic-assistance" ? true : false)}
      />,
      <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle}   
        text={'Vehicle'}
        url={'/resources/vehicle'}
        active={(this.props.location.pathname === "/resources/vehicle" ? true : false)}
      />,
    ]
  }

  render() {
    const { isCustomerRequired, currentRole, appPermission } = this.context;
    let grantedMenu = new RoleAccessService();
    
    let wms_link = ""
    if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
      wms_link = "https://staging-wms.evfy.sg/"
    } else {
      wms_link = "https://wms.evfy.sg/"
    }
   
    return <Box>
      <div style={{minHeight:"87vh", marginTop:'76px', overflow:'hidden'}} className="d-flex flex-column justify-content-between">   <List component="nav" className="customer-nav-style">
      {grantedMenu.getUniqueRolePermission().includes('dashboard') && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Dashboard'}
          icon={<DashboardIcon />}
          url={'/dashboard'}
          active={(this.props.location.pathname.includes("/dashboard") || this.props.location.pathname == "job-schedule-lists" || this.props.location.pathname.includes("/recycle-bin") ? true : false)}
        />
        }
       {grantedMenu.getUniqueRolePermission().includes('jobs') && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Analytics'}
          icon={<FontAwesomeIcon icon={faChartColumn} />}
          url={'/analytics/overview'}
          active={(this.props.location.pathname.includes("/analytics") || this.props.location.pathname.includes("/job-schedule-list")? true : false)}
        />}
        {grantedMenu.getUniqueRolePermission().includes('live_tracking') && currentRole != 'Finance Admin' && 
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Location'}
          icon={<FontAwesomeIcon icon={faLocationDot} />}
          url={'/live-tracking/reload'}
          active={(this.props.location.pathname === "/live-tracking" || this.props.location.pathname === "/zones" ? true : false)}
        />
        }

        {grantedMenu.getUniqueRolePermission().includes('customers') && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Clients'}
          icon={<FontAwesomeIcon icon={faUsers} />}
          url={'/customers'}
          active={(this.props.location.pathname.includes("/customers") ? true : false)}
        />}
        {grantedMenu.getUniqueRolePermission().includes('transport_operator') ?
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Drivers'}
          icon={<FontAwesomeIcon icon={faTruck} />}
          url={'/resources/transport-operators'}
          active={(this.props.location.pathname.includes("/resources") ? true : false)}
        /> : 
        grantedMenu.getUniqueRolePermission().includes('vendor') ? 
        <CustomMenuItem
        onDrawerToggle={this.props.onDrawerToggle} 
        text={'Drivers'}
        icon={<FontAwesomeIcon icon={faTruck} />}
        url={'/resources/vendor'}
        active={(this.props.location.pathname.includes("/resources") ? true : false)}
      /> : ''}
         {grantedMenu.getUniqueRolePermission().includes('invoice') && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Invoices'}
          icon={<FontAwesomeIcon icon={faFileLines} />}
          url={'/invoice/clients'}
          active={(this.props.location.pathname.includes("/invoice") ? true : false)}
        />}

        {/* {(grantedMenu.isShouldShowMenu(currentRole, "vendor")) && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Vendor'}
          icon={<VendorIcon />}
          url={'/vendor'}
          active={(this.props.location.pathname === "/vendor" ? true : false)}
        />
        } */}
        {/* {(grantedMenu.isShouldShowMenu(currentRole, "Resources")) &&
        <CustomMenuItem
          // onDrawerToggle={this.props.onDrawerToggle} 
          text={'Resources'}
          icon={<ResourcesIcon />}
          active={() => {
            if(this.props.location.pathname === "/resources/transport-operator") {
              return true
            } else if(this.props.location.pathname === "/resources/logistic-assistance") {
              return true
            } else if(this.props.location.pathname === "/resources/vehicle") {
              return true
            } else {
              return false;
            }
          }}
          //items list of resource of new enhancement role
          items={this.getResourceItemListForNewRole(currentRole)}          
        />
        } */}

      </List>
      <List component="nav" className="customer-nav-style">
      {grantedMenu.isShouldShowMenu(currentRole, "settings") && <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Settings'}
          icon={<FontAwesomeIcon icon={faGear} />}
          url={'/settings'}
          active={(this.props.location.pathname === "/settings"? true : false)}
        /> }
         <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Help'}
          icon={<FontAwesomeIcon icon={faMessage} />}
          url={'/help-center'}
          active={(this.props.location.pathname === "/help-center"? true : false)}
        />
        </List>
      </div>
    </Box>
  }

  
}

export default withRouter(LeftMenu);