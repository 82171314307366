import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { 
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress,
  InputLabel,
  Select
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import DriverForm from '../../Jobs/Partial/DriverForm.jsx';

import Tour from 'reactour'


/**
 * @todo
//  * 1. create
//  * 2. update
//  * 3. formvalidation - show error.
//  * 4.1 drop down for transport operator
 * use memo to avoid ui lagging
 * 4.2 check if the toperator already have vechicle assigned, if yes remove from the older vechicle
 * 5. ux enter key should move to next input
 */


export const VehicleForm = () => {
    const location = useLocation();
    const history = useHistory();
    let Vid = (location && location.state && location.state.id && location.state.id > 0) ? location.state.id : 0;

        // colour: "blue"
        // engine_capacity: 4444
        // load_capacity: 2000
        // manufacturer: "volvo"
        // model: "truck"
        // plate_no: "x3dd33321"
        // worker_id: 464
        // worker_ids: "464"
        // workers: []
    const [ id, setId ] = useState(Vid);
    const { accessToken, isOnBoarding, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isVehicleLoading, setIsVehicleLoading ] = useState(false);
    const [ plateNo, setPlateNo ] = useState("");
    const [ plateNoValidated, setPlateNoValidated ] = useState("");
    const [ manufacturer, setManufacturer ] = useState("");
    const [ manufacturerValidated, setManufacturerValidated ] = useState("");
    const [ model, setModel ] = useState("");
    const [ modelValidated, setModelValidated ] = useState("");
    const [ vehicleType, setVehicleType ] = useState("");
    const [ vehicleTypeVlidated, setVehicleTypeVlidated ] = useState("");
    const [ vehicleColor, setVehicleColor ] = useState("");
    const [ vehicleColorValidated, setVehicleColorValidated ] = useState("");
    const [ loadCapicity, setLoadCapicity ] = useState("");
    const [ checkPlateNo, setCheckPlateNo ] = useState("");
    const [ engineCapacity, setEngineCapacity ] = useState("");

    const [ workerItemsIsLoading, setWorkerItemsIsLoading] = useState(false);
    const [ workerItems, setWorkerItems ] = useState([]);
    const [ workerItem, setWorkerItem ] = useState(null);
    const [ workerName, setWorkerName ] = useState('');
    // const [ workerNameValidated, setWorkerNameValidated ] = useState('');
    const [ workerId, setWorkerId ] = useState('');

    const [orderStatusesIsLoading, setOrderStatusesIsLoading ] = useState([]);
    const [orderStatuses, setOrderStatuses ] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderStatusId, setOrderStatusId] = useState(0);
    const [ resultData, setResultData] = useState([]);


    const [openDialogDriver, setOpenDialogDriver] = useState(false)
    const [itemDialogDriver, setItemDialogDriver] = useState(null)

    const [isTourOpen, setIsTourOpen] = useState(false)
    const [openSkipDialog, setOpenSkipDialog] = useState(false)

    useEffect(() => {
        if(isOnBoarding){
          setIsTourOpen(true);
        }

        callReadApi();

    }, [isOnBoarding])

    
    const callReadApi = useCallback((search = '') => {
        let param = {
          search: search ? search.trim() : search,
        };
        setIsLoading(true);
        const getData = Api.read('vehicles', param, accessToken)
        .then( (results) => {
            apiUtil.parseResult(results, (data) => {

                var json_data = results.data.data.result;
                var result_data = [];
                var ss = results;
                for (var i in json_data) {
                    result_data[i] = json_data[i].plate_no;
                }

                setResultData(result_data);
             
              setIsLoading(false);
            }, (error, type) => {
              setIsLoading(false);
            });
        });
    }, [accessToken])

    const DriverDialog = () => {
        return <CustomFormDialog 
        open={openDialogDriver}
        title={'Create New Driver'}
        onClose={() => {
            setOpenDialogDriver(false)
        }}
    >
        <DriverForm
            item={itemDialogDriver}
            onSave={(isEdit, data) => {
                if(!isEdit) {
                    /* ADD NEW DRIVER */
                    let workerItems = [];
                    let workerItem = null;
                    if(data && data.result){
                    let value = data.result.id;
                    let label = ((data.result.first_name && data.result.first_name !== '') ? data.result.first_name : '') + ' ' + ((data.result.last_name && data.result.last_name !== '') ? data.result.last_name : '');

                    workerItem = {
                        value: value,
                        label: label,
                        item: data.result,
                    };
                    workerItems.push(workerItem);
                    }

                    setWorkerItems(workerItems)
                    setWorkerName(workerItem.label)
                    setWorkerId(workerItem.value)
                    setWorkerItem(workerItem.item)

                    let notAssignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'not assigned');
                    let assignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'assigned');

                    if(assignedStatusItem && notAssignedStatusItem){
                        if(orderStatusId === notAssignedStatusItem.value){
                            setOrderStatus(assignedStatusItem.item)
                            setOrderStatusId(assignedStatusItem.value)
                        }
                    }
                    /* END ADD NEW DRIVER */
                    setOpenDialogDriver(false);
                    setItemDialogDriver(null);

                }
                
            }}
            onClose={() => {
                setOpenDialogDriver(false);
                setItemDialogDriver(null);
            }}
        />
    </CustomFormDialog>
    }
    const callVechicleDetailsApi = ( id = '', callback = null) => {
        setIsVehicleLoading(true);
        Api.details('vehicles', id, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;
                    
                    if(result){
                        setIsVehicleLoading(false);
                        setPlateNo(result.plate_no);
                        setCheckPlateNo(result.plate_no);
                        setManufacturer(result.manufacturer)
                        setModel(result.model)
                        setVehicleColor(result.colour)
                        setEngineCapacity(result.engine_capacity)
                        setVehicleType(result.vehicle_type_id)
                        setLoadCapicity(result.load_capacity)
                        if(result.workers && result.workers.length) {
                            setWorkerId(result.workers[0].id)
                            setWorkerName(`${result.workers[0].first_name} ${result.workers[0].last_name}`)
                            setWorkerItem(result.workers[0])
                        }
                        if(callback){
                          callback(result);
                        }
                    } else {
                        setIsVehicleLoading(false);
                        if(callback){
                          callback(null);
                        }
                    }
                  }, (error, type) => {
                    setIsVehicleLoading(false);
                      if(callback){
                        callback(null);
                      }
                  });
            })
    }
    const callCreateApi = (row, callback = null) => {
        setIsLoading(true);
        Api.create('vehicles', row, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                if(callback) {
                    callback();
                    history.push('/resources/vehicle');
                    apiUtil.toast('Successfully Created', 'check_circle');
                }
            }, (error, type) => {
                console.log("error", error)
                if(callback) {
                    callback();
                    apiUtil.toast(error, 'error');
                }
            });
        })
    }
    const callUpdateApi = (id, row, callback = null) => {
        setIsLoading(true);
        Api.update('vehicles/' + id, row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsLoading(false);
                    if(callback) {
                        callback();
                        history.push('/resources/vehicle');
                        apiUtil.toast('Successfully Updated', 'check_circle');
                    }
                }, (error, type) => {
                    console.log("error", error)
                    setIsLoading(false);
                    if(callback) {
                        callback();
                        apiUtil.toast(error, 'error');
                    }
                });
            })
    }
    const callWorkerDriverReadApi = (search = '',  callback = null) => {
          setWorkerItemsIsLoading(true);
          let param = {
            page: 1,
            take: apiUtil.getDefaultPageSize(),
            is_active: true,
            search: search ? search.trim() : search,
            is_truck_driver: true,
            require_worker_only: true,
          };
    
          Api.read('workers', param, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                let result = (data.result && data.result.length > 0) ? data.result : [];
    
                let arr = [];
                if(result && result.length > 0){
                    arr = result.map((item, i) => {
                        let value = item.id;
                        let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');
        
                        if(arr.findIndex(x => x.value === value) === -1){
                        return {
                            value: value,
                            label: label,
                            item: item
                        }
                        } else {
                        return null;
                        }
                    });
                    setWorkerItemsIsLoading(false)
                    setWorkerItems(arr)
                    if(callback){
                        callback(arr);
                    }
                } else {
                    setWorkerItemsIsLoading(false)
                    if(callback){
                        callback([]);
                    }
                }
              }, (error, type) => {
                setWorkerItemsIsLoading(false)
                if(callback){
                callback([]);
                }
              });
          });

    }
      /* ORDER STATUSES API */
    const callOrderStatusesApi = (callback = null) => {
        setOrderStatusesIsLoading(true)
        ldsApi.read('order_statuses', null, accessToken)
            .then((result) => {
            apiUtil.parseResult(result, (data) => {
                let arr = [];
                if(data && data.length > 0){
                arr = data.map((item, i) => {
                    let value = item.id;
                    let label = item.status;

                    if(arr.findIndex(x => x.value === value) === -1){
                    return {
                        value: value,
                        label: label,
                        item: item
                    }
                    } else {
                    return null;
                    }
                });
        
                    setOrderStatusesIsLoading(false)
                    setOrderStatuses(arr)
                    if(callback){
                        callback(arr);
                    }

                } else {
                    setOrderStatusesIsLoading(false)
                    if(callback){
                        callback([]);
                    }
                }
            }, (error, type) => {
                setOrderStatusesIsLoading(false)
                if(callback){
                    callback([]);
                }
            });
        });
    }
    /* END ORDER STATUSES API */
    useEffect(() => {
        if(id && id > 0) {
            callVechicleDetailsApi(id)
        }
    },[id])
    useEffect(() => {
        callOrderStatusesApi((data) => {
            let orderStatusItem = apiUtil.customFilter(data, 'label', 'not assigned');
            if(orderStatusItem){
              setOrderStatusId(orderStatusItem.item)
              setOrderStatus(orderStatusItem.value)
            }
        })
    },[orderStatus])
    useEffect(() => {
        callWorkerDriverReadApi(workerName);
    },[workerName])
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const form = e.currentTarget;

        var isD = "false";
        resultData.filter(function (item, pos) {
            if (id && id > 0) {
                if (item == plateNo && item != checkPlateNo) {
                    isD = "true";
                }
            } else {
                if (item == plateNo) {
                    isD = "true";
                }
            }
        }); 

    //     resultData.filter(function(item, pos) {  
    //     if(item == plateNo){
    //         isD = "true"; 
    //     } 
    // }) ; 

        
        if (id != " ") {
            if( plateNo === '' && isD === "true") {
                setPlateNoValidated(true)
            }
        } else {
            if( plateNo === '' ) {
                setPlateNoValidated(true)
            } 
        }
        if( manufacturer === '') {
            setManufacturerValidated(true)
        }
        if( model === '') {
            setModelValidated(true)
        }
        if( vehicleColor === '') {
            setVehicleColorValidated(true)
        }
        if( vehicleType === '') {
            setVehicleTypeVlidated(true)
        }
        
        if (form.checkValidity() === true && isD === "false") {
            let data = {
                colour : vehicleColor,
                engine_capacity: parseInt(engineCapacity),
                load_capacity: parseInt(loadCapicity),
                manufacturer: manufacturer,
                model: model,
                plate_no: plateNo,
                worker_id: parseInt(workerId),
                worker_ids: workerId.toString(),
                workers: workerItem,
                vehicle_type_id: vehicleType
            }
            if(id && id > 0) {
                callUpdateApi(id, data, () => {
                    setIsLoading(false);
                    
                })
            } else {
                if(!isOnBoarding){
                    callCreateApi(data, () => {
                        setIsLoading(false)
                        
                    })
                } else {
                    setOnBoardingVehicle(JSON.stringify(data));
                    history.push('/resources/transport-operator');
                    apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
                }
            }
        } else {
            if (isD === "true") {
                apiUtil.toast('plate Number already Exit', 'check_circle');
            }
            else {
                apiUtil.toast('Please check the compulsory fields', 'check_circle');
            }
            setIsLoading(false);
        }
    }
    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
        <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push('/resources/vehicle');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={isOnBoarding ? 'auto' : true}>
            <CustomLabel
                className={'no-margin'}
                label={(id > 0) ? 'Edit Vehicle' : 'Add New Vehicle'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              className={'create-vehicle-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }


    
  /* STEPPER */
  const setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={1}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            history.push('/customers');
          } else if(index === 1){
            history.push('/resources/vehicle');
          } else if(index === 2){
            history.push('/resources/transport-operator');
          } else if(index === 3){
            history.push('/job-list');
          }
        }}
      />
      <Box textAlign={'center'}>Fill in the form and create a vehicle.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              setOpenSkipDialog(true);
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  const setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.create-vehicle-step',
            content: <Box>
              <h2>Create Vehicle</h2>
              <p>Fill in the required data to create a vehicle.</p>
            </Box>,
          },
        ]}
        isOpen={isTourOpen}
        rounded={10}
        accentColor={'#007CDE'}
        // accentColor={'#64CCC9'}
        badgeContent={'#007CDE'}
        // badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          setIsTourOpen(false);
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  const skipDialog = () => {
    return <CustomDialog 
      open={openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        setOpenSkipDialog(false);
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            setOpenSkipDialog(false);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            history.push('/')
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


    return <Box className="vehicle-form-page" >
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <StickyPageHeader isSmall={true}>
            <CustomStickyPageHeader />
        </StickyPageHeader>
        
        <Container maxWidth="lg">
            <Card>
                {
                    !isVehicleLoading ? 
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <CustomLabel
                                    label={`Vehicle's information`}
                                    weight={'bold'}
                                    size={'md'}
                                />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Container maxWidth={'sm'}>
                                    <Box clone p={5}>
                                        <Grid container>

                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Plate Number*'}
                                                        placeholder={'Enter vehicle plate number'}
                                                        value={plateNo}
                                                        required={true}
                                                        error={plateNoValidated}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setPlateNo(e.target.value)
                                                            setPlateNoValidated((e.target.value !== '') ? false : true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Manufacturer*'}
                                                        placeholder={'Enter vehicle Manufacturer name'}
                                                        value={manufacturer}
                                                        required={true}
                                                        error={manufacturerValidated}
                                                        autoFocus={true}
                                                        onChange={e => {
                                                            setManufacturer(e.target.value)
                                                            setManufacturerValidated((e.target.value !== '') ? false : true) 
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Model*'}
                                                        placeholder={'Enter vehicle model name'}
                                                        value={model}
                                                        error={modelValidated}
                                                        required={true}
                                                        onChange={e => {
                                                            setModel(e.target.value)
                                                            setModelValidated((e.target.value !== '') ? false : true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Vehicle Color*'}
                                                        placeholder={'Enter vehicle color'}
                                                        value={vehicleColor}
                                                        error={vehicleColorValidated}
                                                        required={true}
                                                        autoFocus={true}
                                                        onChange={e => {
                                                            setVehicleColor(e.target.value)
                                                            setVehicleColorValidated((e.target.value !== '') ? false : true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <Box className="custom-label-component">
                                                        <InputLabel error={vehicleTypeVlidated}>Vehicle Type *</InputLabel>
                                                    </Box>
                                                    <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                                                    placeholder={'Select Vehicle Type here...'}
                                                    value={vehicleType}
                                                    error={vehicleTypeVlidated}
                                                    native
                                                    required={true}
                                                    disableUnderline
                                                    onChange={(e) => {
                                                        setVehicleType(e.target.value)
                                                        setVehicleTypeVlidated((e.target.value !== '') ? false : true)
                                                    }}
                                                    >
                                                    <option value=""> Select Vehicle type </option>
                                                    <option value={1}>Car</option>
                                                    <option value={2}>MPV</option>
                                                    <option value={3}>1.7m Van</option>
                                                    <option value={4}>2.4m Van</option>
                                                    <option value={5}>10 Ft Lorry</option>
                                                    <option value={6}>14 Ft Lorry</option>
                                                    <option value={7}>Walker</option>
                                                    </Select>
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={<>Load Capacity <small className={'muted'}>Optional</small></>}
                                                        placeholder={'Enter the load capacity of the vehicle'}
                                                        value={loadCapicity}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setLoadCapicity(e.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={<>Engine Capacity<small className={'muted'}>Optional</small></>}
                                                        placeholder={'Enter the engine capacity of the vehicle'}
                                                        value={engineCapacity}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setEngineCapacity(e.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>

                                        </Grid>
                                    </Box>
                                </Container>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomLabel
                                    label={'Assign Driver'}
                                    weight={'bold'}
                                    size={'md'}
                                />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Container maxWidth={'sm'}>
                                    <Box clone p={5}>
                                        <Grid container>

                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomAutosuggest 
                                                        label={'Driver'}
                                                        placeholder={'Enter any keyword to search for Driver'}
                                                        helperText={<Box>
                                                            <Grid container alignItems={'center'}>
                                                                <Box clone>
                                                                    <Grid item xs={'auto'}>Driver not in list?</Grid>
                                                                </Box>
                                                                <Box clone>
                                                                    <Grid item xs={'auto'}>
                                                                        <CustomButton 
                                                                            className={'underline'}
                                                                            href={'/'}
                                                                            color={'primary'}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                setOpenDialogDriver(true)

                                                                            }}
                                                                        >
                                                                            Create new Driver
                                                                        </CustomButton>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Box>}
                                                        items={workerItems}
                                                        isLoading={workerItemsIsLoading}
                                                        value={workerName}
                                                        onChange={(value) => {
                                                            if(value){
                                                                setWorkerName(value.label)
                                                                setWorkerId(value.value)
                                                                setWorkerItem(value.item)
                                                                let orderStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'assigned');
                                                                // if(orderStatusItem){
                                                                //     setOrder
                                                                // this.setState({
                                                                //     order_status: orderStatusItem.item,
                                                                //     order_status_id: orderStatusItem.value,
                                                                // });
                                                                // }
                                                            } else {
                                                            // this.setState({
                                                            //     workerName: '',
                                                            //     workerId: '',
                                                            //     workerItem: null,
                                                            // }, () => {
                                                            //     let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                                                            //     if(orderStatusItem){
                                                            //     this.setState({
                                                            //         order_status: orderStatusItem.item,
                                                            //         order_status_id: orderStatusItem.value,
                                                            //     });
                                                            //     }
                                                            // });
                                                            }
                                                        }}
                                                        onSearch={(value, e) => {
                                                            setWorkerName(value);
                                                            e.showAllSuggestions();
                                                        }}
                                                        onClick={(value, e) => {
                                                            setWorkerName(value);
                                                            e.showAllSuggestions();
                                                            // callWorkerDriverReadApi( value, (arr) => {
                                                            //     setWorkerItems(arr)
                                                            //     e.showAllSuggestions();
                                                            // });
                                                            
                                                        }}
                                                        onInit={() => {
                                                            // already loaded with useEffect via workerName
                                                            // callWorkerDriverReadApi();
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>


                                        </Grid>
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box> 
                    : 
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                        <CircularProgress size={35} />
                    </Box>
                }
            </Card>
        </Container>
        
    </form>
    <DriverDialog/>
    {setTour()}
    {skipDialog()}
</Box>
}
