import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: '12/1',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: '12/2',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: '12/3',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: '12/4',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: '12/5',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: '12/6',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: '12/7',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

export default class CustomLineChart extends Component {

  constructor(props){
    super(props);
    let data = props.data? props.data : null;
    this.state = {
      data : data,
    }
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        {this.state.data.length > 0 ?
        <LineChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="title"/>
          <YAxis domain={[0, 'dataMax+10']}/>
          <Tooltip />
          <Line type="monotone" dataKey="Deliveries" stroke="#EE6002" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Pick up" stroke="#6200EE" activeDot={{ r: 8 }} />
        </LineChart> :
        <div className="d-flex justify-content-center align-items-center">There is no Data for date range !!</div>}
      </ResponsiveContainer>
    );
  }
}
