import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";

import { 
  Box,
  Grid,
  Container,
  Divider,
} from '@material-ui/core';

import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';


export class OnboardingComplete extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      isFormChanged: false,
      
      rules: [],
    }
  }
  

  componentDidMount() {
    this.callAppSettingsApi();
  }


  /* API */
  callAppSettingsApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.readSettings('application_company_settings', {}, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result && data.result.length > 0){
                let ruleItem = data.result.filter(x => x.rule === 'is_status_need_manpower' && x.view_name !== '');
                if(ruleItem && ruleItem.length > 0){
                    this.setState({
                        rules: ruleItem,
                        isLoading: false,
                    }, () => {
                      if(callback){
                        callback(ruleItem);
                      }
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    }, () => {
                      if(callback){
                        callback([]);
                      }
                    });
                }
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                isLoading: false,
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }

  callCreateAppSettingsApi = (data, callback = null) => {
    const { accessToken } = this.context;

    let params = {
        application_settings_data: data
    };

    this.setState({
        isLoading: true
    }, () => {
        api.create('batch_update/application_company_settings', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
                isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
        });
    });
  }

  callCreateCustomerApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('customers', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callCreateVehicleApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.create('vehicles', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callCreateDriverApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      api.create('workers', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callCreateJobApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateAdminProfileApi = (callback = null) => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let params = {
        "is_onboarding": false,
      };

      api.updateAdminProfile('profile/admins', params, accessToken).then((result) => {
        apiUtil.parseResult(result, (data) => {
          this.setState({
            isLoading: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            if(callback){
              callback(data.result);
            }
          });
        }, (error, type) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(callback){
              callback(null);
            }
          });
        });
      });
    });
  }
  /* END API */


  render() {
    const { 
        onBoardingCustomer,
        onBoardingVehicle,
        onBoardingDriver,
        onBoardingJob
    } = this.context;

    let jsonCustomer = null;
    try {
        jsonCustomer = JSON.parse(onBoardingCustomer);
    } catch(e){}

    let jsonVehicle = null;
    try {
        jsonVehicle = JSON.parse(onBoardingVehicle);
    } catch(e){}

    let jsonDriver = null;
    try {
        jsonDriver = JSON.parse(onBoardingDriver);
    } catch(e){}

    let jsonJob = null;
    try {
        jsonJob = JSON.parse(onBoardingJob);
    } catch(e){}

    return <Box className="onboarding-page" >
        <Container 
            className={'custom-container'}
            maxWidth="md"
        >
            <Grid container>
                <Box clone pb={2} textAlign={'center'}>
                    <Grid item xs={12}>
                        <CustomLabel
                            className={'muted'}
                            label={'You\'re all set!'}
                            weight={'bold'}
                            size={'xl'}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <Card className={'no-margin'}>
                            <Grid container>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'SAVE PERMANENTLY'}
                                            weight={'bold'}
                                        />
                                        <Divider light />
                                    </Grid>
                                </Box>

                                <Box clone pt={2} pb={2}>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'Information you\'ve keyed in just now was temporarily saved.Would you like to save it permanently? you can edit the details later.'}
                                        />
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={'center'}>
                                            <Box clone>
                                                <Grid item xs={true}>
                                                    <b>{(jsonCustomer && jsonCustomer.company_name) ? jsonCustomer.company_name : 'Customer'}</b>
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={'auto'}>
                                                    <CustomButton 
                                                        onClick={() => {
                                                            const { setOnBoardingCustomer } = this.context;

                                                            this.callCreateCustomerApi(jsonCustomer, () => {
                                                                this.setState({
                                                                    isLoading: false
                                                                }, () => {
                                                                    setOnBoardingCustomer(null);
                                                                    apiUtil.toastOnBoarding('Successfully Created Customer', 'check_circle');
                                                                });
                                                            });
                                                        }} 
                                                        variant={'contained'} 
                                                        color={'secondary'}
                                                        disabled={(jsonCustomer) ? false : true}
                                                        isLoading={(jsonCustomer) ? this.state.isLoading : false}
                                                    >
                                                        Save Customer
                                                    </CustomButton>
                                                </Grid>
                                            </Box>
                                            <Box clone pt={1} pb={1}>
                                                <Grid item xs={12}>
                                                    <Divider light />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={'center'}>
                                            <Box clone>
                                                <Grid item xs={true}>
                                                    <b>{(jsonVehicle && jsonVehicle.plate_no) ? jsonVehicle.plate_no : 'Vehicle'}</b>
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={'auto'}>
                                                    <CustomButton 
                                                        onClick={() => {
                                                            const { setOnBoardingVehicle } = this.context;

                                                            this.callCreateVehicleApi(jsonVehicle, () => {
                                                                this.setState({
                                                                    isLoading: false
                                                                }, () => {
                                                                    setOnBoardingVehicle(null);
                                                                    apiUtil.toastOnBoarding('Successfully Created Vehicle', 'check_circle');
                                                                });
                                                            });
                                                        }} 
                                                        variant={'contained'} 
                                                        color={'secondary'}
                                                        disabled={(jsonVehicle) ? false : true}
                                                        isLoading={(jsonVehicle) ? this.state.isLoading : false}
                                                    >
                                                        Save Vehicle
                                                    </CustomButton>
                                                </Grid>
                                            </Box>
                                            <Box clone pt={1} pb={1}>
                                                <Grid item xs={12}>
                                                    <Divider light />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={'center'}>
                                            <Box clone>
                                                <Grid item xs={true}>
                                                    <b>{(jsonDriver && jsonDriver.first_name) ? jsonDriver.first_name : 'Driver'}</b>
                                                </Grid>
                                            </Box>
                                            <Box clone pr={2}>
                                                <Grid item xs={'auto'}>
                                                    <small>An email will be sent to this driver about their new account</small>
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={'auto'}>
                                                    <CustomButton 
                                                        onClick={() => {
                                                            const { setOnBoardingDriver } = this.context;

                                                            this.callCreateDriverApi(jsonDriver, () => {
                                                                this.setState({
                                                                    isLoading: false
                                                                }, () => {
                                                                    setOnBoardingDriver(null);
                                                                    apiUtil.toastOnBoarding('Successfully Created Driver', 'check_circle');
                                                                });
                                                            });
                                                        }} 
                                                        variant={'contained'} 
                                                        color={'secondary'}
                                                        disabled={(jsonDriver) ? false : true}
                                                        isLoading={(jsonDriver) ? this.state.isLoading : false}
                                                    >
                                                        Save Driver
                                                    </CustomButton>
                                                </Grid>
                                            </Box>
                                            <Box clone pt={1} pb={1}>
                                                <Grid item xs={12}>
                                                    <Divider light />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={'center'}>
                                            <Box clone>
                                                <Grid item xs={true}>
                                                    <b>{(jsonJob && jsonJob.drop_off_description) ? jsonJob.drop_off_description : 'Job'}</b>
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={'auto'}>
                                                    <CustomButton 
                                                        onClick={() => {
                                                            const { setOnBoardingJob } = this.context;

                                                            this.callCreateJobApi(jsonJob, () => {
                                                                this.setState({
                                                                    isLoading: false
                                                                }, () => {
                                                                    setOnBoardingJob(null);
                                                                    apiUtil.toastOnBoarding('Successfully Created Job', 'check_circle');
                                                                });
                                                            });
                                                        }} 
                                                        variant={'contained'} 
                                                        color={'secondary'}
                                                        disabled={(jsonJob) ? false : true}
                                                        isLoading={(jsonJob) ? this.state.isLoading : false}
                                                    >
                                                        Save Job
                                                    </CustomButton>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Card>
                    </Grid>
                </Box>

                <Box clone pt={3}>
                    <Grid item xs={12}>
                        <Card className={'no-margin'}>
                            <Grid container>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'MANPOWER JOB STEP STATUS CHANGE (FROM MOBILE APP)'}
                                            weight={'bold'}
                                        />
                                        <Divider light />
                                    </Grid>
                                </Box>

                                <Box clone pt={2}>
                                    <Grid item xs={12}>
                                        <CustomLabel
                                            label={'We noticed that you required manpower to assist the driver. Would you want to grant them the privilege to update the job status like the driver does ?'}
                                        />
                                    </Grid>
                                </Box>

                                {this.state.rules.map((item, i) => {
                                    return <Box key={i} clone>
                                        <Grid item xs={12} md={6}>
                                            <CustomCheckbox
                                                label={<span className={'status ' + apiUtil.statusToClassName(item.view_name)}>{item.view_name}</span>}
                                                checked={item.enable}
                                                onChange={(checked) => {
                                                    let rules = this.state.rules;
                                                    rules[i].enable = checked;

                                                    this.setState({
                                                        rules: rules,
                                                        isFormChanged: true,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                })}
                                
                                <Box clone pt={2} textAlign={'right'}>
                                    <Grid item xs={12}>
                                        <CustomButton 
                                            onClick={() => {
                                                this.callCreateAppSettingsApi(this.state.rules, () => {
                                                    this.setState({
                                                        isLoading: false
                                                    }, () => {
                                                        apiUtil.toastOnBoarding('Successfully Saved Settings', 'check_circle');
                                                    });
                                                });
                                                
                                            }} 
                                            variant={'contained'} 
                                            color={'secondary'}
                                            disabled={!this.state.isFormChanged}
                                            isLoading={(this.state.isFormChanged) ? this.state.isLoading : false}
                                        >
                                            Save
                                        </CustomButton>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Card>
                    </Grid>
                </Box>

                <Box clone textAlign={'right'}>
                    <Grid item xs={12}>
                        <Box pt={3}>
                            <CustomButton
                                onClick={() => {
                                    this.callUpdateAdminProfileApi((data) => {
                                        apiUtil.toastOnBoarding('Done', 'check_circle');
                                        this.props.history.push('/');
                                    });
                                }} 
                                variant={'contained'} 
                                color={'primary'}
                                isLoading={this.state.isLoading}
                            >
                                End Tutorial
                            </CustomButton>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Container>
    </Box>;
  }
}

