import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";

import {
    Box,
    Grid,
    Divider,
    InputLabel,
    Select,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CustomSwitch from '../../../components/CustomFields/CustomSwitch.jsx';
import { ReactComponent as InformationIcon } from '../../../assets/img/icons/information.svg';
import defaultImg from "../../../assets/img/default_image.jpg"
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
// import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import VehicleForm from '../../Jobs/Partial/VehicleForm.jsx';

import Tour from 'reactour'
import {RoleAccessService} from '../../../data/role-access'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faMagnifyingGlass, faPlus, faPen, faTrashCan, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Modal, Card, Form } from 'react-bootstrap';
import moment from "moment";


/**
 * @todo
 *  contact no. preset +65 
// * formValidatedation - show error. password hide text
 * zone dropdown checkbox
 * priority section
 * ask user to enter two password and display error message if the passwords doesn't match.
 * focusing on page load fix
 * ux enter key should move to next input
 */

export const TransportDetail = (props) => {
    const location = useLocation();
    const history = useHistory();

    let grantedAccess = new RoleAccessService();
    let role = window.localStorage.getItem('uniqueRolePermissions');
    // const { currentRole } = this.context;
    let currentRole = window.localStorage.getItem('current_role');

    let Tid = (location && location.state && location.state.id && location.state.id > 0) ? location.state.id : 0;
    const [id] = useState(Tid);
    const [isTransportLoading, setIsTransportLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { accessToken, isOnBoarding, onBoardingVehicle, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
    const [contactNo, setContactNo] = useState(null);
    const [contactNoValidated, setContactNoValidated] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userNameValidated, setUserNameValidated] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [checkName, setCheckName] = useState(null);
    const [firstNameValidated, setFirstNameValidated] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameValidated, setLastNameValidated] = useState(false);
    const [password, setPassword] = useState(null);
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [maxJobPerDay, setMaxJobPerDay] = useState(0);
    const [priority, setPriority] = useState(0);
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [zoneIdArr, setZoneArr] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [zone, setZone] = useState(null);

    const [isPayoutShow, setIsPayoutShow] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentMethodValidated, setPaymentMethodValidated] = useState(false)
    const [bankAccDetail, setBankAccDetail] = useState(null)
    const [bankAccDetailValidated, setBankAccDetailValidated] = useState(false)
    const [vehicleType, setVehicleType] = useState(null)
    const [vehicleTypeValidated, setVehicleTypeValidated] = useState(false)
    
    const [icNo, setIcNo] = useState(null)
    const [dateOfBirth, setDateOfBirth] = useState(null)
    const [address, setAddress] = useState(null)
    
    const [idCardNO, setIdCardNo] = useState('')
    const [driverLicenseEmail, setDriverLicenseEmail] = useState(null)
    const [jobPreference, setJobPreference] = useState(null)
    const [load, setLoad] = useState(null)
    const [availablDay, setAvailablDay] = useState(null)
    const [driverLicenseEmailValidated, setDriverLicenseEmailValidated] = useState(false)
    const [isReadingLoading, setIsReadingLoading] = useState(false)

    const [isVehicleLoading, setIsVehicleLoading] = useState(false);
    const [vehicleItems, setVehicleItems] = useState([]);
    const [vehicleItem, setVehicleItem] = useState(null);
    const [vehicleName, setVehicleName] = useState('');
    const [vehicleNameValidated, setVehicleNameValidated] = useState(false);
    
    const [vehicleId, setVehicleId] = useState('');
    const [vehicleTypeID, setVehicleTypeID] = useState('')
    const [vehicleTypeIdValidate,setVehicleTypeIdValidate] = useState(false);
    const [vehicleModel, setVehicleModel] = useState('')
    const [vehicleMake, setVehicleMake] = useState('')
    const [registrationNo, setRegistrationNo] = useState('')

    const [orderStatusesIsLoading, setOrderStatusesIsLoading] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderStatusId, setOrderStatusId] = useState(0)

    const [openDialogVehicle, setOpenDialogVehicle] = useState(false)
    const [itemDialogVehicle, setItemDialogVehicle] = useState(null)
    const [disabled, setDisabled] = useState(null)

    const [isTourOpen, setIsTourOpen] = useState(false)
    const [openSkipDialog, setOpenSkipDialog] = useState(false)
    const [vehicleKind, setVehicleKind] = useState(null)
    const [vehicleKindValidate,setVehicleKindValidate] = useState(false);
    const [driverProfileId, setDriverProfileId] = useState(null)
    const [driverProfileIdValidate,setDriverProfileIdValidate] = useState(false);
    const [startTime, setStartTime] = useState('9:30')
    const [endTime, setEndTime] = useState('20:00')
    const [timeValidated,setTimeValidated] = useState(false)


    useEffect(() => {
        if (isOnBoarding) {
            setIsTourOpen(true);
        }
        if (id && id > 0) {
            callTransportOpDetailsApi(id,(result) => {
              callReadApi();
              callZoneListApi();                
            })
        } else {
            callReadApi();
            callZoneListApi();
        }
    }, [id])

    const callUpdateApi = (id = '', row, callback = null) => {
        setIsReadingLoading(true);
        Api.update('workers/' + id, row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsReadingLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/transport-operators');
                        apiUtil.toast('Successfully Updated', 'check_circle');
                    }
                }, (error, type) => {
                    let errorMessage = error.email? error.email[0] : error
                    setIsReadingLoading(false);
                    if (callback) {
                        callback();
                        apiUtil.toast(errorMessage, 'error');
                    }
                });
            }).catch(
                error => console.log(error)
            )
    }
    const callCreateApi = (row, callback = null) => {
        setIsReadingLoading(true);
        Api.create('workers', row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    setIsReadingLoading(false);
                    if (callback) {
                        callback();
                        history.push('/resources/transport-operators');
                        apiUtil.toast('Successfully Created', 'check_circle', 'success');
                    }
                }, (error, type) => {
                    let errmessage = error.email? error.email[0] : error
                    setIsReadingLoading(false);
                    if (callback) {
                        callback();
                        apiUtil.toast(errmessage, 'error');
                    }
                });
            })
    }

    const callReadApi = useCallback((search = '') => {
        let param = {
            search: search ? search.trim() : search,
            is_truck_driver: true,
            require_worker_only: true
        };
        setIsReadingLoading(true);
        const getData = Api.read('workers', param, accessToken)
            .then((results) => {
                apiUtil.parseResult(results, (data) => {

                    var json_data = results.data.data.result;
                    var result_data = [];
                    for (var i in json_data) {
                        result_data[i] = json_data[i].email;
                    }
                    setResultData(result_data);

                    setIsReadingLoading(false);
                }, (error, type) => {
                    setIsReadingLoading(false);
                });
            });
    }, [accessToken])

    const callTransportOpDetailsApi = (id = '', callback = null) => {
        setIsReadingLoading(true);
        Api.details('workers', id, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;

                    if (result) {
                        setIsTransportLoading(false);
                        setContactNo(result.contact_no);
                        setUserName(result.email);
                        let full_name = `${result.first_name}`
                        setFirstName(full_name);
                        setCheckName(result.email);
                        setLastName(result.last_name);
                        setZoneArr(result.polygon_ids_ext);
                        if (result.vehicle && result.vehicle.id) {
                            setVehicleId(result.vehicle.id)
                            setVehicleName(result.vehicle.plate_no)
                            setVehicleItem(result.vehicle)
                            setVehicleTypeID(result.vehicle.vehicle_type_id)
                            setVehicleModel(result.vehicle.model)
                            setVehicleMake(result.vehicle.manufacturer)
                            setRegistrationNo(result.vehicle.registration_no)
                        }
                        setPriority(result.priority)
                        setIsPayoutShow(result.is_disabled_driver_payout)
                        setMaxJobPerDay(result.max_job_perday)
                        setZone(result.worker_polygons.length > 0 ? `${result.worker_polygons[0].id}` : null)
                        setBankAccDetail(result.bank_account_detail)
                        setPaymentMethod(result.payment_method)
                        setVehicleKind(result.type_of_vehicle)
                        setDriverProfileId(result.driver_profile_id)
                        setIcNo(result.ic_no)
                        setIdCardNo(result.id_card_no)
                        setDateOfBirth(result.dob)
                        setAddress(result.official_address)
                        setDriverLicenseEmail(result.driver_license_email)
                        setJobPreference(result.job_preference)
                        setLoad(result.load_amount)
                        setAvailablDay(result.available_day)
                        setDisabled(result.disabled)
                        if(result.available_time) {
                          let available_time = result.available_time.split('-');
                          setStartTime(available_time[0].trim());
                          setEndTime(available_time[1].trim());                          
                        }
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        setIsReadingLoading(false);
                        if (callback) {
                            callback(null);
                        }
                    }
                }, (error, type) => {
                    setIsReadingLoading(false);
                    if (callback) {
                        callback(null);
                    }
                });
            })
    }

    const callDeleteApi = (row, callback = null) => {
        // setIsLoading(true);
        let param = {
          destroy_all: true,
          id: row
        };
        Api.delete('wrokers/' + row, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            // setIsLoading(false);
            if(callback){
              callback();
            }
          }, (error, type) => {
            // setIsLoading(false);
            if(callback){
              callback();
            }
          });
      });
      }
    const callVehicleReadApi = useCallback((search = '', callback = null) => {
        let param = {
            // page: 1,
            // take: apiUtil.getDefaultPageSize(),
            search: search ? search.trim() : search,
        };
        setIsVehicleLoading(true);
        Api.read('vehicles', param, accessToken).then((results) => {
            apiUtil.parseResult(results, (data) => {
                const result = (data.result && data.result.length > 0) ? data.result : []
                let arr = [];
                if (result) {
                    arr = result.map((item, i) => {
                        let value = item.id;
                        let label = item.plate_no;

                        if (arr.findIndex(x => x.value === value) === -1) {
                            return {
                                value: value,
                                label: label,
                                item: item
                            }
                        } else {
                            return null;
                        }
                    });
                    setIsVehicleLoading(false)
                    setVehicleItems(arr)
                    if (callback) {
                        callback(arr);
                    }
                } else {
                    setIsVehicleLoading(false)
                }
            }, (error, type) => {
                setIsVehicleLoading(false);
            });
        });

    }, [])

    //worker_polygons
    const callZoneListApi = (callback = null) => {
        let param = {}
        setIsLoading(true);
        Api.read('worker_polygons', param, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, (data) => {
                    let result = (data && data.result) ? data.result : null;

                    if (result) {
                        setIsLoading(false);
                        setZoneList(result)
                        if (callback) {
                            callback(result);
                        }
                    } else {
                        setIsLoading(false);
                        if (callback) {
                            callback(null);
                        }
                    }
                }, (error, type) => {
                    setIsLoading(false);
                    if (callback) {
                        callback(null);
                    }
                });
            })
    }

    useEffect(() => {
        if (!isOnBoarding) {
            callVehicleReadApi(vehicleName);
        } else {
            let jsonVehicle = null;
            try {
                jsonVehicle = JSON.parse(onBoardingVehicle);
            } catch (e) { }

            let vehicle = null;
            if (jsonVehicle) {
                vehicle = {
                    value: jsonVehicle.id,
                    label: jsonVehicle.plate_no,
                    item: jsonVehicle
                }
            }

            let arr = [];
            if (vehicle) {
                arr.push(vehicle);
            }

            setVehicleItems(arr)
        }
    }, [])

    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                {(!isOnBoarding) && <Box clone>
                    <Grid item xs={'auto'}>
                        <IconButton
                            onClick={() => {
                                history.push('/resources/transport-operator');
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Box>}
                {(!isOnBoarding) && <Box clone>
                    <Grid item xs={'auto'}>
                        <Divider orientation={'vertical'} />
                    </Grid>
                </Box>}
                <Box clone>
                    <Grid item xs={isOnBoarding ? 'auto' : true}>
                        <CustomLabel
                            className={'no-margin'}
                            label={(id > 0) ? 'Edit Driver' : 'Add New Driver'}
                            weight={'bold'}
                            size={'md'}
                        />
                    </Grid>
                </Box>
                {isOnBoarding && <Box clone pr={1}>
                    <Grid item xs={12} sm={true}>
                        {setStepper()}
                    </Grid>
                </Box>}
                <Box clone>
                    <Grid item xs={'auto'}>
                        <CustomButton
                            className={'create-driver-step'}
                            type={'submit'}
                            color={'secondary'}
                            isLoading={isLoading}
                        >
                            <Box pl={2} pr={2}>Submit</Box>
                        </CustomButton>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }

    const CreateVehicleDialog = () => {
        return <CustomFormDialog
            open={openDialogVehicle}
            title={'Create New Vehicle'}
            onClose={() => {
                setOpenDialogVehicle(false)
            }}
        >
            <VehicleForm
                item={itemDialogVehicle}
                onSave={(isEdit, data) => {
                    if (!isEdit) {
                        /* ADD NEW DRIVER */
                        let vehicleItems = [];
                        let vehicleItem = null;
                        if (data && data.result) {
                            let value = data.result.id;
                            let label = data.result.plate_no

                            vehicleItem = {
                                value: value,
                                label: label,
                                item: data.result,
                            };
                            vehicleItems.push(vehicleItem);
                        }

                        setVehicleItems(vehicleItems)
                        setVehicleName(vehicleItem.label)
                        setVehicleId(vehicleItem.value)
                        setVehicleItem(vehicleItem.item)

                        let notAssignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'not assigned');
                        let assignedStatusItem = apiUtil.customFilter(orderStatuses, 'label', 'assigned');

                        if (assignedStatusItem && notAssignedStatusItem) {
                            if (orderStatusId === notAssignedStatusItem.value) {
                                setOrderStatus(assignedStatusItem.item)
                                setOrderStatusId(assignedStatusItem.value)
                            }
                        }
                        /* END ADD NEW DRIVER */
                        setOpenDialogVehicle(false);
                        setItemDialogVehicle(null);

                    }

                }}
                onClose={() => {
                    setOpenDialogVehicle(false);
                    setItemDialogVehicle(null);
                }}
            />
        </CustomFormDialog>
    }
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const form = e.currentTarget;

        if (firstName === null || firstName == '') {
            setFirstNameValidated(true)
        }
        // if (lastName === null) {
        //     setLastNameValidated(true)
        // }
        if (userName === null || userName === "") {
            setUserNameValidated(true)
        }
        if (!password  && id === 0) {
            setPasswordValidated(true)
        }
        
        if(vehicleTypeID == null || vehicleTypeID == "") {
          setVehicleTypeIdValidate(true);   
          setIsLoading(false)
          return
        }
        
        if(driverProfileId == null || driverProfileId == "") {
          setDriverProfileIdValidate(true);
          setIsLoading(false)
          return          
        }

        if (vehicleType === null || vehicleType === "") {
            setVehicleTypeValidated(true)
        }

        if (vehicleName === null || vehicleName == "") {
            setVehicleNameValidated(true)
        } 
        if (!bankAccDetail) {
            setBankAccDetailValidated(true)
        }
        if (!paymentMethod) {
            setPaymentMethodValidated(true)
        }
        
        if (!vehicleKind) {
            setVehicleKindValidate(true)
            setIsLoading(false)
            return 
        }

        let start_time = moment(startTime,'hh:mm');
        let end_time = moment(endTime,'hh:mm');
        let diff_time = end_time.diff(start_time, 'hours',true);
        if (start_time.format('HH:mm') == end_time.format('HH:mm') || diff_time < 0) {
          setTimeValidated(true);
          setIsLoading(false)
          return
        }
        // else {
        //     let vehicle_exit = vehicleItems.filter(dd => dd.label == vehicleName)
        //     console.log("vehicle_exit", vehicle_exit, vehicleName)
        //     if(!(vehicle_exit.length > 0)) {
        //         if (!(grantedAccess.isPermissionActionAllowed( currentRole, 'Create Vehicle'))) {
        //             apiUtil.toast("Your account doesn't have to create new vehicle", "check_circle");
        //             return
        //         }
        //     } 
            
        // }

        // if (driverLicenseEmail !== null && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(driverLicenseEmail))) {
        //     setDriverLicenseEmailValidated(true)
        // }

        /*let phoneRegEx = /^[+]?\d+$/;
        if (!phoneRegEx.test(contactNo) || contactNo === null || contactNo.length < 8 || contactNo.length > 13) {
            setContactNoValidated(true)
            setIsLoading(false)
            return
        }*/
        if (contactNo) {
            if(!apiUtil.phoneValidationWithPlus(contactNo) ){
                setContactNoValidated(true)
                setIsLoading(false)
                return
              }
        } else {
            setContactNoValidated(true)
            setIsLoading(false)
            return
        }

        var isD = "false";
        resultData.filter(function (item, pos) {
            if (id && id > 0) {
                if (item == userName && item != checkName) {
                    isD = "true";
                }
            } else {
                if (item == userName) {
                    isD = "true";
                }
                }
        });

        if (form.checkValidity() === true && isD === "false") {

            let vehicle = '' 
            if(vehicleName) {
                vehicle = {
                    plate_no: vehicleName,
                    vehicle_type_id: vehicleTypeID,
                    model: vehicleModel,
                    id: vehicleId,
                    manufacturer: vehicleMake,
                    registration_no: registrationNo
                }
            }

            let data = {
                contact_no: contactNo.length === 8 ? '+65' + contactNo : contactNo ,
                disabled: disabled,
                email: userName ? userName.trim() : userName,
                first_name: firstName ? firstName.trim() : firstName,
                is_truck_driver: true,
                last_name: lastName ? lastName.trim() : lastName,
                max_job_perday: maxJobPerDay,
                password: password,
                polygon_ids: zone || "55",
                polygon_ids_ext: zone ? [zone]: [55],
                priority: priority,
                vehicle_id: vehicleId,
                vehicle: vehicleItem,
                is_disabled_driver_payout: isPayoutShow,
                bank_account_detail: bankAccDetail,
                payment_method: paymentMethod,
                type_of_vehicle: vehicleKind,
                ic_no: icNo,
                driver_license_email: driverLicenseEmail,
                job_preference: jobPreference,
                load_amount: load,
                available_day: availablDay,
                official_address: address,
                vehicle_data: vehicle,
                id_card_no: idCardNO,
                dob: dateOfBirth,
                driver_profile_id: driverProfileId,
                available_time: startTime+' - '+endTime
            }

            if (id && id > 0) {
                callUpdateApi(id, data, () => {
                    setIsLoading(false)
                })
            } else {
                if (!isOnBoarding) {
                    callCreateApi(data, () => {
                        setIsLoading(false)
                    })
                } else {
                    setOnBoardingDriver(JSON.stringify(data));
                    history.push('/job-list');
                    apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
                }
            }
        } else {
            if (isD === "true") {
                apiUtil.toast('User Name already Exit', 'check_circle');
            }
            else {
                apiUtil.toast('Please check the compulsory fields', 'check_circle');
            }

            setIsLoading(false);
        }
    }


    /* STEPPER */
    const setStepper = () => {
        return <>
            <CustomGuideStepper
                activeStep={2}
                steps={[
                    {
                        label: 'Create Customer',
                    },
                    {
                        label: 'Create Vehicle',
                    },
                    {
                        label: 'Create Driver',
                    },
                    {
                        label: 'Create Job',
                    },
                ]}
                onClick={(index) => {
                    if (index === 0) {
                        history.push('/customers');
                    } else if (index === 1) {
                        history.push('/resources/vehicle');
                    } else if (index === 2) {
                        history.push('/resources/transport-operator');
                    } else if (index === 3) {
                        history.push('/job-list');
                    }
                }}
            />
            <Box textAlign={'center'}>Fill in the form and create a operator.</Box>
            <Box textAlign={'center'}>
                <CustomButton
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                        e.preventDefault();

                        setOpenSkipDialog(true);
                    }}
                >
                    Skip All
                </CustomButton>
            </Box>
        </>
    }
    /* END STEPPER */

    /* TOUR */
    const setTour = () => {
        return <>
            <Tour
                steps={[
                    {
                        selector: '.create-driver-step',
                        content: <Box>
                            <h2>Create Driver</h2>
                            <p>Fill in the required data to create a driver.</p>
                        </Box>,
                    },
                ]}
                isOpen={isTourOpen}
                rounded={10}
                accentColor={'#007CDE'}
                // accentColor={'#64CCC9'}
                badgeContent={'#007CDE'}
                // badgeContent={'#64CCC9'}
                showNumber={false}
                showNavigation={false}
                disableInteraction={true}
                prevButton={<></>}
                nextButton={<></>}
                lastStepNextButton={<></>}
                onRequestClose={() => {
                    setIsTourOpen(false);
                }} />
        </>
    }
    /* END TOUR */

    /* SKIP DIALOG */
    const skipDialog = () => {
        return <CustomDialog
            open={openSkipDialog}
            title={'End Tutorial'}
            btnCloseText={'No'}
            onClose={() => {
                setOpenSkipDialog(false);
            }}
            onOk={() => {
                apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
                    setOnBoardingCustomer(null);
                    setOnBoardingVehicle(null);
                    setOnBoardingDriver(null);
                    setOnBoardingJob(null);

                    setOpenSkipDialog(false);

                    apiUtil.toastOnBoarding('Done', 'check_circle');
                    history.push('/')
                });
            }}
        >
            <Box>Are you sure you want to end tutorial?</Box>
        </CustomDialog>
    }
    /* END SKIP DIALOG */
    
    const personalInformation = () => {
        return <Card className="cd-shadow"><div className="p-4">
            <div className="fs-5 font-medium w-100">Personal Information</div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">First Name <span style={{color:'red'}}>*</span></div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <CustomInput
                    placeholder={'Full Name'}
                    value={firstName}
                    error={firstNameValidated}
                    required={true}
                    autoFocus={true}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                        setFirstNameValidated((e.target.value !== '') ? false : true)
                    }}
                />
                </div>
            </div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Last Name</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <CustomInput
                    placeholder={'Last Name'}
                    value={lastName}
                    autoFocus={true}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
                </div>
            </div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">NRIC</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                   
                <CustomInput
                    placeholder={'NIRC'}
                    value={idCardNO}
                    autoFocus={true}
                    onChange={(e) => {
                        setIdCardNo(e.target.value)
                    }}
                />
                </div>
            </div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Date of Birth</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    
                <CustomInput
                    placeholder={'Date of Birth'}
                    value={dateOfBirth}
                    type={'date'}
                    autoFocus={true}
                    onChange={(e) => {
                        setDateOfBirth(e.target.value)
                    }}
                />
                </div>
            </div>
            {/*<div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Residential Status</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    <CustomInput
                    placeholder={'Residential Status'}
                    // value={dateOfBirth}
                    autoFocus={true}
                    onChange={(e) => {
                        setDateOfBirth(e.target.value)
                    }}
                />
                </div>
            </div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Qualified Driving Licence Class</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                    <CustomInput
                    placeholder={'Qualified Driving Licence Class'}
                    // value={dateOfBirth}
                    autoFocus={true}
                    onChange={(e) => {
                        setDateOfBirth(e.target.value)
                    }}
                />
                </div>
            </div>
            <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Driving Licence Issue Date</div>
                <div className="w-50 ms-2 driver-respond-between-md-xs">
                <CustomInput
                    placeholder={'Driving Licence Issue Date'}
                    // value={}
                    type={'date'}
                    autoFocus={true}
                    onChange={(e) => {
                    }}
                />
                </div>
            </div>*/}
        </div>
        </Card>
    }

    const contactInformation = () => {
        return <Card className="cd-shadow h-100" ><div className="p-4" >
        <div className="fs-5 font-medium w-100">Contact Information</div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Mobile Number<span style={{color:'red'}}>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                {/* <input type="text" value={contactNo} className="form-control" /> */}
            <CustomInput
                placeholder={'+65'}
                value={contactNo}
                error={contactNoValidated}
                required={true}
                autoFocus={true}
                onChange={e => {
                    setContactNo(e.target.value.trim())
                    setContactNoValidated((e.target.value !== '') ? false : true)
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Home Address</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                {/* <input type="text" value={address} className="form-control" /> */}
            <CustomInput
                placeholder={'Address'}
                value={address}
                autoFocus={true}
                onChange={e => {
                    setAddress(e.target.value)
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Preferred Payment Method <span style={{color:'red'}}>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                {/* <input type="text" value={paymentMethod} className="form-control" /> */}
                <CustomInput
                    placeholder={'Payment Method'}
                    value={paymentMethod}
                    error={paymentMethodValidated}
                    autoFocus={true}
                    required={true}
                    onChange={e => {
                        setPaymentMethod(e.target.value) 
                        setPaymentMethodValidated(e.target.value != '' ? false : true)
                    }}
                />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Name of Bank</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"><CustomInput type="text" /></div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">PayNow / PayLah / Bank Account Number<span style={{color:'red'}}>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
                {/* <input type="text" className="form-control" value={bankAccDetail}/> */}
                <CustomInput
                    placeholder={'Enter bank account detail'}
                    autoFocus={true}
                    value={bankAccDetail}
                    error={bankAccDetailValidated}
                    required={true}
                    onChange={e => {
                        setBankAccDetail(e.target.value)
                        setBankAccDetailValidated(e.target.value !== '' ? false : true)
                    }}
                />
            </div>
        </div>
        <Box mt={3} mb={3}>
            <Divider light />
        </Box>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">User Name</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
            <CustomInput
                placeholder={'Enter UserName'}
                value={userName}
                error={userNameValidated}
                disabled={id? true : false}
                type={"text"}
                required={true}
                autoFocus={true}
                onChange={(e) => {
                    setUserName(e.target.value)
                    setUserNameValidated((e.target.value !== '') ? false : true)
                }}
            />
            
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Password</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
            <CustomInput
                className={'custom-password'}
                placeholder={'Enter driver password'}
                autoComplete={'new-password'}
                value={password}
                error={passwordValidated}
                type={passwordType}
                required={id === 0 ? true : false}
                endAdornment={(obj) => {
                    return <Box
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setPasswordType((passwordType === 'password') ? 'text' : 'password')
                        }}
                    >
                        {
                            (passwordType === 'password')
                                ?
                                <i className={'far fa-eye-slash'}></i>
                                :
                                <i className={'far fa-eye'}></i>
                        }
                    </Box>
                }}
                onChange={(e) => {
                    setPassword(e.target.value)
                    setPasswordValidated((e.target.value !== '') ? false : true)
                    
                }}
            />
            
            </div>
        </div>
        {/* <div className="row d-flex align-items-center py-3">
            <Col xs={6} md={4} lg={4} className="text-center">
            <div className="mb-2">Profile Picture</div>
            <img src={defaultImg} width="80px" height="auto"/>
            <div className="d-flex justify-content-center p-2">
                <FontAwesomeIcon icon={faPen} className="primary-color px-2"/>
                <FontAwesomeIcon icon={faTrashCan} className="danger-color px-2"/>
            </div>
            </Col>
            <Col xs={6} md={4} lg={4} className="text-center">
            <div className="mb-2">Vehicle Image</div>
            <img src={defaultImg} width="80px" height="auto"/>
            <div className="d-flex justify-content-center p-2">
                <FontAwesomeIcon icon={faPen} className="primary-color px-2"/>
                <FontAwesomeIcon icon={faTrashCan} className="danger-color px-2"/>
            </div>
            </Col>
            <Col xs={6} md={4} lg={4} className="text-center">
            <div className="mb-2">Vehicle Log Card</div>
            <img src={defaultImg} width="80px" height="auto"/>
            <div className="d-flex justify-content-center p-2">
                <FontAwesomeIcon icon={faPen} className="primary-color px-2"/>
                <FontAwesomeIcon icon={faTrashCan} className="danger-color px-2"/>
            </div>
            </Col>
            <Col xs={6} md={4} lg={4} className="text-center mt-2">
            <div className="mb-2">NRIC</div>
            <img src={defaultImg} width="80px" height="auto"/>
            <div className="d-flex justify-content-center p-2">
                <FontAwesomeIcon icon={faPen} className="primary-color px-2"/>
                <FontAwesomeIcon icon={faTrashCan} className="danger-color px-2"/>
            </div>
            </Col>
            <Col xs={6} md={4} lg={4} className="text-center mt-2">
            <div className="mb-2">Driver’s Licence</div>
            <img src={defaultImg} width="80px" height="auto"/>
            <div className="d-flex justify-content-center p-2">
                <FontAwesomeIcon icon={faCirclePlus} className="primary-color px-2"/>
            </div>
            </Col>
        </div> */}
    </div>
    </Card>
    }

    const vehicleInformation = () => {
        return <Card className="cd-shadow mt-4 mb-4"><div className="p-4">
        <div className="fs-5 font-medium w-100">Vehicle Information</div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Vehicle Number <span style={{color:'red'}}>*</span></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"> 
            <CustomAutosuggest
                // label={'Vehicle Number'}
                // disabled={grantedAccess.isPermissionActionAllowed( currentRole, 'Update Vehicle') || grantedAccess.isPermissionActionAllowed( currentRole, 'Create Vehicle') ? false : true}
                placeholder={'Enter any keyword to search for vehicle plate no'}
                items={vehicleItems}
                isLoading={isVehicleLoading}
                value={vehicleName}
                error={vehicleNameValidated}
                required={true}
                onChange={(value) => {
                    if (value) {
                        console.log('work on change')
                        setVehicleName(value.label)
                        setVehicleId(value.value)
                        setVehicleItem(value.item)
                        setVehicleTypeID(value.item && value.item.vehicle_type_id ? value.item.vehicle_type_id : '')
                        setVehicleModel(value.item && value.item.model ? value.item.model : '')
                        setVehicleMake(value.item && value.item.manufacturer ? value.item.manufacturer : '')
                        setRegistrationNo(value.item && value.item.registration_no ? value.item.registration_no : '')
                        setVehicleNameValidated(false)
                    } else {
                        setVehicleName('')
                        setVehicleId('')
                        setVehicleItem(null)
                        setVehicleTypeID('')
                        setVehicleModel('')
                        setVehicleMake('')
                        setRegistrationNo('')
                    }
                }}
                onSearch={(value, e) => {
                    setVehicleName(value);
                    setVehicleNameValidated(false)
                    e.showNewSuggestions(vehicleItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                }}
                onClick={(value, e) => {  
                  e.showAllSuggestions();
                }}
            />
            </div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Vehicle Type</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"> 
            <Select
            className="select-width-control-100 custom-input-component"
            error={vehicleTypeIdValidate}
            style={{
              width: "100%",
              border: "1px solid #E0E1E4",
              borderRadius: "4px",
              padding: "",
              backgroundColor: "transparent",
            }}
            placeholder={"From"}
            value={vehicleTypeID}
            native
            disableUnderline
            onChange={e=>{
                setVehicleTypeID(e.target.value)
                setVehicleTypeIdValidate(false)
            } }
            // disabled={grantedAccess.isPermissionActionAllowed( currentRole, 'Update Vehicle') || grantedAccess.isPermissionActionAllowed( currentRole, 'Create Vehicle')? false : true}
             >
            <option value= ''> Select Vehicle type </option>
            <option value={1}>Car</option>
            <option value={2}>MPV</option>
            <option value={3}>1.7m Van</option>
            <option value={4}>2.4m Van</option>
            <option value={5}>10 Ft Truck</option>
            <option value={6}>14 Ft Truck</option>
            <option value={7}>Walker</option>
          </Select></div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Vehicle Make</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"><CustomInput type="text" 
            value={vehicleMake}
            onChange={e=>{
                setVehicleMake(e.target.value)
            } }
            disabled={grantedAccess.isPermissionActionAllowed( currentRole, 'Update Vehicle') || grantedAccess.isPermissionActionAllowed( currentRole, 'Create Vehicle')? false : true}
            /></div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Vehicle Model</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs">
            <CustomInput type="text" value={vehicleModel}
            onChange={e=>{
                setVehicleModel(e.target.value)
            } }
            // disabled={grantedAccess.isPermissionActionAllowed( currentRole, 'Update Vehicle') || grantedAccess.isPermissionActionAllowed( currentRole, 'Create Vehicle')? false : true}
            /></div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p">Year of Registration</div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"><CustomInput type="text" 
            value={registrationNo}
            onChange={e=>{
                setRegistrationNo(e.target.value)
            } }
            disabled={grantedAccess.isPermissionActionAllowed( currentRole, 'Update Vehicle')? false : true}/></div>
        </div>
        <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
            <div className="w-50 text-end px-5 font-medium driver-respond-between-md-xs remove-p"></div>
            <div className="w-50 ms-2 driver-respond-between-md-xs"> 
            <input type="radio" checked={vehicleKind == 'EV' ? true : false} value="EV" name="vehicle_kind" onChange={(e) => setVehicleKind(e.target.value)}/><span className={vehicleKindValidate == true ? "me-3 ms-1 text-danger" :  "me-3 ms-1"}>EV</span><input type="radio" value="HEV" checked={vehicleKind == 'HEV' ? true : false} name="vehicle_kind" onChange={(e) => setVehicleKind(e.target.value)}/><span className={vehicleKindValidate == true ? "me-3 ms-1 text-danger" :  "me-3 ms-1"}>HEV</span><input type="radio" checked={vehicleKind == 'ICE' ? true : false} value="ICE" name="vehicle_kind" onChange={(e) => setVehicleKind(e.target.value)}/><span className={vehicleKindValidate == true ? "me-3 ms-1 text-danger" :  "me-3 ms-1"}>ICE</span>
            </div>
        </div>
    </div>
    </Card>
    } 

    const adminstraticeMatter = () => {
        return <Card className="cd-shadow px-4 py-4">
             <div className="fs-5 font-medium w-100 mb-3">Administrative Matters</div>
             <Row>
                <Col xs={12} md={6}>
                  <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                    <div className="text-start font-medium driver-respond-between-md-xs remove-p">Account Status</div>
                      <div className="w-50 ms-5"> 
                      <Form.Select aria-label="Default select example" value={disabled}
                      onChange={e=>{
                          setDisabled(e.target.value)
                      }}                     
                      >
                        <option value={false}>Active</option>
                        <option value={true}>Inactive</option>
                      </Form.Select></div>
                  </div>
                  <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                    <div className="text-start me-3 font-medium driver-respond-between-md-xs remove-p">Driver Profile</div>
                    <div className="w-50 ms-5"> 
                      <Select
                      className="select-width-control-100 custom-input-component"
                      error={driverProfileIdValidate}
                      style={driverProfileIdValidate ? {
                        width: "100%",
                        border: "1px solid #ff0000",
                        borderRadius: "4px",
                        padding: "",
                        backgroundColor: "transparent",
                      } : {
                        width: "100%",
                        border: "1px solid #E0E1E4",
                        borderRadius: "4px",
                        padding: "",
                        backgroundColor: "transparent",
                      }}
                      native
                      disableUnderline
                      value={driverProfileId} 
                      onChange={(e) => {
                        setDriverProfileId(e.target.value)
                        setDriverProfileIdValidate(false)
                      }}
                      >
                      <option value={''}>-- pick a group --</option>
                      <option value={1}>Inhouse</option>
                      <option value={2}>Vendor</option>
                      <option value={3}>Ad-hoc</option>
                      </Select>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                      <div className="text-start font-medium driver-respond-between-md-xs remove-p">Available Hours</div>
                      <div className="w-50 ms-5">
                        <div className="max-xs-width-control-900 ps-2 d-flex">
                          <Select
                            className="select-width-control-100 custom-input-component"
                            error={timeValidated}
                            style={{
                              width: "100%",
                              border: "1px solid #E0E1E4",
                              borderRadius: "4px",
                              padding: "",
                              backgroundColor: "transparent",
                            }}
                            placeholder={"From"}
                            value={startTime}
                            native
                            disableUnderline
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                          >
                            <option value="0:00"> 0:00 </option>
                             <option value="0:30"> 0:30 </option>
                             <option value="1:00"> 1:00 </option>
                             <option value="1:30"> 1:30 </option>
                             <option value="2:00"> 2:00 </option>
                             <option value="2:30"> 2:30 </option>
                             <option value="3:00"> 3:00 </option>
                             <option value="3:30"> 3:30 </option>
                             <option value="4:00"> 4:00 </option>
                             <option value="4:30"> 4:30 </option>
                             <option value="5:00"> 5:00 </option>
                             <option value="5:30"> 5:30 </option>
                             <option value="6:00"> 6:00 </option>
                             <option value="6:30"> 6:30 </option>
                             <option value="7:00"> 7:00 </option>
                             <option value="7:30"> 7:30 </option>
                             <option value="8:00"> 8:00 </option>
                             <option value="8:30"> 8:30 </option>
                             <option value="9:00"> 9:00 </option>
                             <option value="9:30"> 9:30</option>
                             <option value="10:00"> 10:00 </option>
                             <option value="10:30"> 10:30 </option>
                             <option value="11:00"> 11:00 </option>
                             <option value="11:30"> 11:30 </option>
                             <option value="12:00"> 12:00 </option>
                             <option value="12:30"> 12:30 </option>
                             <option value="13:00"> 13:00 </option>
                             <option value="13:30"> 13:30 </option>
                             <option value="14:00"> 14:00 </option>
                             <option value="14:30"> 14:30 </option>
                             <option value="15:00"> 15:00 </option>
                             <option value="15:30"> 15:30 </option>
                             <option value="16:00"> 16:00 </option>
                             <option value="16:30"> 16:30 </option>
                             <option value="17:00"> 17:00 </option>
                             <option value="17:30"> 17:30 </option>
                             <option value="18:00"> 18:00 </option>
                             <option value="18:30"> 18:30 </option>
                             <option value="19:00"> 19:00 </option>
                             <option value="19:30"> 19:30 </option>
                             <option value="20:00"> 20:00 </option>
                             <option value="20:30"> 20:30 </option>
                             <option value="21:00"> 21:00 </option>
                             <option value="21:30"> 21:30 </option>
                             <option value="22:00"> 22:00 </option>
                             <option value="22:30"> 22:30 </option>
                             <option value="23:00"> 23:00 </option>
                          </Select>
                          <div className="text-end-style m-2 px-4">To</div>
                          <Select
                            className="select-width-control-100 custom-input-component"
                            error={timeValidated}
                            style={{
                              width: "100%",
                              border: "1px solid #E0E1E4",
                              borderRadius: "4px",
                              padding: "",
                              backgroundColor: "transparent",
                            }}
                            placeholder={"From"}
                            value={endTime}
                            native
                            disableUnderline
                            onChange={(e) => {
                              setEndTime(e.target.value);
                            }}
                          >
                             <option value="0:00"> 0:00 </option>
                             <option value="0:30"> 0:30 </option>
                             <option value="1:00"> 1:00 </option>
                             <option value="1:30"> 1:30 </option>
                             <option value="2:00"> 2:00 </option>
                             <option value="2:30"> 2:30 </option>
                             <option value="3:00"> 3:00 </option>
                             <option value="3:30"> 3:30 </option>
                             <option value="4:00"> 4:00 </option>
                             <option value="4:30"> 4:30 </option>
                             <option value="5:00"> 5:00 </option>
                             <option value="5:30"> 5:30 </option>
                             <option value="6:00"> 6:00 </option>
                             <option value="6:30"> 6:30 </option>
                             <option value="7:00"> 7:00 </option>
                             <option value="7:30"> 7:30 </option>
                             <option value="8:00"> 8:00 </option>
                             <option value="8:30"> 8:30 </option>
                             <option value="9:00"> 9:00 </option>
                             <option value="9:30"> 9:30</option>
                             <option value="10:00"> 10:00 </option>
                             <option value="10:30"> 10:30 </option>
                             <option value="11:00"> 11:00 </option>
                             <option value="11:30"> 11:30 </option>
                             <option value="12:00"> 12:00 </option>
                             <option value="12:30"> 12:30 </option>
                             <option value="13:00"> 13:00 </option>
                             <option value="13:30"> 13:30 </option>
                             <option value="14:00"> 14:00 </option>
                             <option value="14:30"> 14:30 </option>
                             <option value="15:00"> 15:00 </option>
                             <option value="15:30"> 15:30 </option>
                             <option value="16:00"> 16:00 </option>
                             <option value="16:30"> 16:30 </option>
                             <option value="17:00"> 17:00 </option>
                             <option value="17:30"> 17:30 </option>
                             <option value="18:00"> 18:00 </option>
                             <option value="18:30"> 18:30 </option>
                             <option value="19:00"> 19:00 </option>
                             <option value="19:30"> 19:30 </option>
                             <option value="20:00"> 20:00 </option>
                             <option value="20:30"> 20:30 </option>
                             <option value="21:00"> 21:00 </option>
                             <option value="21:30"> 21:30 </option>
                             <option value="22:00"> 22:00 </option>
                             <option value="22:30"> 22:30 </option>
                             <option value="23:00"> 23:00 </option>
                          </Select>
                        </div>    
                      </div>
                  </div>
                  <div className="d-flex align-items-center py-2 driver-respond-between-md-xs">
                      <div className="text-start font-medium driver-respond-between-md-xs remove-p">Disable Driver Payout</div>
                      <div className="w-50 ms-5">
                          <CustomSwitch 
                              checked={isPayoutShow}
                              onChange={(isChecked) => {
                                  setIsPayoutShow(isChecked)
                              }}
                          />     
                      </div>
                  </div>                   
                </Col>
             </Row>
        </Card>

    }

    return <Container fluid>
        {isReadingLoading ? <Box className="loadingScreen">
            <CircularProgress size={35} className="circular_progress"/>
        </Box> : <><form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Row className="px-4 pt-4">
            <div className="fs-5 font-medium w-100 py-3"> {id ? "Edit Driver-Partner Details" : "Create Driver-Partner"  }</div>
              <Col xs={12} md={6}>
                {personalInformation()}
                {vehicleInformation()}
              </Col>  
              <Col xs={12} md={6}>
              {contactInformation()}
              </Col>  
            </Row>
            <Row className="p-4"> 
                <Col>
                {adminstraticeMatter()}
                </Col>
            </Row>
            <Row className="p-4">
                <Col className="d-flex">
                {/* <div className="justify-self-start"><Button className="delete-btn-style"
                disabled={true}
                onClick={() => {
                    callDeleteApi(id,()=>{
                        apiUtil.toast('Successfully Deleted', 'check_circle');
                        props.history.push("/resources/transport-operators")
                    }
                    )
                  }}
                >Delete</Button></div> */}
              <div className="justify-self-end ms-auto"><Button className="upload-style-border"
              onClick={() => {
                props.history.push("/resources/transport-operators")
              }}
              >Cancel</Button></div>
              <div><Button className="upload-style" type='submit'>Save Changes</Button></div>
                </Col>
            </Row>
        </form>
        <CreateVehicleDialog />
        {setTour()}
        {skipDialog()}
        </>
        }
    </Container>
}
