import React, {useState, useEffect, useRef, useCallback, useContext} from 'react'
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import Card from '../../../components/Card/Card.jsx';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/Table/Table.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSwitch from '../../../components/CustomFields/CustomSwitch.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CloseIcon from '@material-ui/icons/Close';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';
import DropdownMenu from '../../../components/Dropdown/DropdownMenu.jsx';
import { ReactComponent as ArrowUpIcon } from '../../../assets/img/icons/arrow-up.svg';
import apiUtil from "../../../api/apiUtil.jsx";
import SettingWhite from '../../../assets/img/icons/setting.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/img/left_menu/arrow-down.svg';
import Api from "../../../api/api.jsx";
import {RoleAccessService} from '../../../data/role-access'; 
import axios from 'axios';
import Tour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

/**
 * 
 * update on input type number change. 
 * dedicated component, so the whole table won't be refreshed
 */
export const Subcons = props => {
  const history = useHistory();
  const refTableSearch = useRef();
  const refTable = useRef();
  const previousItem = useRef();
  const previousAllowItem = useRef();

  let grantedAccess = new RoleAccessService();
  let role = window.localStorage.getItem('uniqueRolePermissions');
  let currentRole_ = window.localStorage.getItem('current_role');

  const { currentRole, accessToken, isOnBoarding, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
  const [statusBar, setStatusBar] = useState({
    total: 0,
    active: 0,
    disabled: 0
  })
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [total, setTotal] = useState(0);
  const [rowData, setRowData] = useState(null);
  const [activeInactiveItem, setActiveInactiveItem] = useState(0)
  const [allowItem, setAllowItem] = useState(0)
  const [limit, setLimit] = useState(20);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogItem, setOpenDialogItem] = useState(null);
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [openSkipDialog, setOpenSkipDialog] = useState(false)
  let grantedMenu = new RoleAccessService();

  useEffect(() => {
    if(isOnBoarding){
      setIsTourOpen(true);
    }
    callReadStatusApi()
  }, [])


  /* STICKY PAGE HEADER */
  const CustomStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <CustomButton 
              className={'new-driver-step'}
              color={'secondary'}
              onClick={() => {
                history.push('/resources/transport-operator-form')
              }}
            >
              + New Driver
            </CustomButton>
          }
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* API */
  const callReadApi = useCallback((search = '' ) => {

    let param ='';
    if ((previousItem.current !== activeInactiveItem) || (previousAllowItem.current !== allowItem) || search !== '' ){
      setPage(1)
    }

     param = {
        page: page,
        take: limit,
        disabled: (activeInactiveItem === 0) ? false : true,
        search: search ? search.trim() : search,
        is_truck_driver: true,
        require_worker_only: true
      };
    
      setIsLoading(true);
        let getData = '';
      if (activeInactiveItem === 0) {
        getData = Api.read('workers', {...param, is_allow_auto_assign : allowItem ==0? true: false}, accessToken)
      } else {
        getData = Api.read('workers', param, accessToken)
      }
      
      Promise.all([getData]).then( (results) => {
          apiUtil.parseResult(results[0], (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            setData(dataArr);
            setTotal(data.total);
            setIsLoading(false);
          }, (error, type) => {
            setData([]);
            setTotal(0);
            setIsLoading(false);
          });
      });
  }, [accessToken, activeInactiveItem, limit, page, allowItem])

  const callReadStatusApi = () => {
    let param = {
      is_truck_driver: true,
      require_worker_only: true
    };
  
    setIsLoading(true);
    const totalinActivedata = Api.read('workers', {...param, disabled : true}, accessToken)
    const totalActivedata = Api.read('workers', {...param, disabled : false}, accessToken)
    Promise.all([totalinActivedata, totalActivedata]).then( (results) => {
      apiUtil.parseResult(results[0], (data) => {
        const disabledTotal = results[0].data.data.total
        const activeTotal = results[1].data.data.total
        setStatusBar({
          total: activeTotal + disabledTotal,
          active: activeTotal,
          disabled: disabledTotal 
        })
        setIsLoading(false);
      }, (error, type) => {
        setIsLoading(false);
      });
  });

  }
  const callUpdateIsActiveApi = (row, callback = null) => {
    Api.update('workers/' + row.id, row, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        if(callback){
          callback();
        }
      }, (error, type) => {

      });
  });
  }

  const callDeleteApi = (row, callback = null) => {
    // setIsLoading(true);
    let param = {
      destroy_all: true,
    };
    Api.delete('customers/' + row.id, param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        // setIsLoading(false);
        if(callback){
          callback();
        }
      }, (error, type) => {
        // setIsLoading(false);
        if(callback){
          callback();
        }
      });
  });
  }
  /* End API */
  useEffect(() => {
    callReadApi(searchVal);
    previousItem.current = activeInactiveItem
    previousAllowItem.current = allowItem
    return () => {

    }
  }, [page, searchVal, allowItem, activeInactiveItem])
  /* STATUS */
  const CustomStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <Box className={'custom-circle-icon-component'}>
            <i className="fas fa-truck"></i>
          </Box>
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Driver Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Drivers'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{statusBar.total}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="primary" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Active Driver</span>
            </Badge>}
            count={statusBar.active}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="error" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Disabled Driver</span>
            </Badge>}
            count={statusBar.disabled}
          />
        </Grid>
      </Box>
    </Grid>
  }

  const getItemforAction = () => {
  
    let item = [];

      item.push({          
          id: 1,
          text: <Box display={'inline-block'} >Allow Allocation</Box>,          
      } , {          
        id: 2,
        text: <Box display={'inline-block'} >Disable Allocation</Box>,          
        })

     

    return item;

  }

  const allowAllocation = () => {
    let selectedRow = rowData;
    let ids = '';
    if(selectedRow == '' || selectedRow == null ) {
      apiUtil.toast('You must select driver!', 'warning', 'error');
      return
    } else {
      selectedRow.map((item, i) => { ids += item.id +','; });
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "batch_update/workers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let data = {
      worker_ids: ids,
      is_allow_auto_assign: true
    }
    setIsLoading(true);
      axios.post(
            URL,
            data,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                      if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data.error;
                        apiUtil.toast(errorMessage, 'warning', "error");
                      } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                      }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
               setIsLoading(false);
            }).then((response) => {
              setIsLoading(false);
              setRowData(null)
              callReadApi();
              apiUtil.toast('Successfully allowed driver', 'check_circle', 'success');
            });

  }

  const disableAllocation = () => {
    let selectedRow = rowData;
    let ids = '';

    // if(selectedRow) selectedRow.map((item, i) => { ids.push(item.id); });
    if(selectedRow == '' || selectedRow == null ) {
      apiUtil.toast('You must select driver!', 'warning', 'error');
      return
    } else {
      selectedRow.map((item, i) => { ids += item.id +','; });
    }

    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "batch_update/workers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let data = {
      worker_ids: ids,
      is_allow_auto_assign: false,
    }
    setIsLoading(true);
      axios.post(
            URL,
            data,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                      if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data.error;
                        apiUtil.toast(errorMessage, 'warning', "error");
                      } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                      }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
               setIsLoading(false);
            }).then((response) => {
              setIsLoading(false);
              setRowData(null)
              callReadApi();
              apiUtil.toast('Successfully disabled driver', 'check_circle', 'success');

            });
  }

  const customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={refTableSearch}
              placeholder={'Search Driver'}
              value={searchVal}
              endAdornment={
                searchVal.length < 1 ? <IconButton
                  onClick={(e) => {
                    setSearchVal(refTableSearch.current.state.value);  
                  }}
                >
                  <SearchIcon />
                </IconButton> : <IconButton>
                  <CloseIcon 
                  fontSize="small" 
                  onClick={(e) => {
                    setSearchVal("");  
                  }}/></IconButton>
              }
              onPressEnter={(e) => {
                setSearchVal(refTableSearch.current.state.value);
              }}
            />
          </Grid>
        </Box>
     
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={activeInactiveItem}
              buttons={[ "Active Driver", "Disable Driver", "OnBoarding Driver"]}
              onClick={(selected, btn) => {
                setActiveInactiveItem(selected)
              }}
            />
          </Grid>
        </Box>
      
        {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <CustomButton 
                color={'primary'}
                onClick={() => {
                  // console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
            }
          </Grid>
        </Box> */}
      </Grid>
      {activeInactiveItem == 0?
      <Grid style={{display:'flex'}}>
      {/* <Box xs={12} md={12} display={'flex'}> */}
          <Grid item xs={12} md={4} >
            <GroupButton
              color={'secondary'}
              selected={allowItem}
              buttons={["Allow Allocation", "Disable Allocation" ]}
              onClick={(selected, btn) => {
                setAllowItem(selected)
              }}
            />
          </Grid>
          {grantedAccess.isPermissionActionAllowed(currentRole, 'Allow Auto Assign') &&
          <Grid item xs={12} md={4} style={{marginBottom:'5px'}}>
                  <DropdownMenu
                  text={'Actions'}
                  color={'primary'}
                  className={'job-list-action'}
                  leftIcon={<img src={SettingWhite} style={{width:20,marginTop:"3px"}}/>}
                  rightIcon={<ArrowDownIcon />}
                  items={getItemforAction()}
                  onClick={(item) => {
                    switch(item.id) {
                      case 1:
                        allowAllocation();
                        break;
                      case 2:
                        disableAllocation();
                        break;
                      default:
                        break;
    
                  }
                  }}
                />
                
              </Grid>
               }
        {/* </Box> */}
      {/* {grantedAccess.isPermissionActionAllowed(currentRole, 'Allow Auto Assign') &&
        
        } */}
      </Grid> : '' }

    </Box>
  }
  /* TABLE */
  const CustomTable = () => {
    return <Table 
      ref={refTable}
      isLoading={isLoading}
      className={'job-table-list' }  
      limit={limit}
      // grouping={true}
      page={page}
      total={total}
      defaultExpanded={true}
      selection={activeInactiveItem != 1? true : false}
      data={data}
      onPageChange={(page, size) => {
        setLimit(size);
        setPage(page);
     
          callReadApi(refTableSearch.current.state.value);
      
        // useEffect  will call the api again when it load
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        setRowData(rows);
      }}
      // Toolbar={customToolbar}
      columns={[
        { 
          title: "Subcon Name", 
          field: "Subcon_name",
        },  
        { 
          title: "Person in Charge", 
          field: "person_in_charge",
        },
        { 
          title: "Contact Number", 
          field: "number"
        },
        { 
          title: "Email Address", 
          field: "email"
        },
        { 
          title: "Postal Codes of Interest", 
          field: "postal_code"
        },
        { 
          title: "Fleet Strength", 
          field: "fleet_strength"
        },
        { 
          title: "Status", 
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                <CustomSwitch 
                      checked={!row.disabled}
                      onChange={(isChecked) => {
                        row.disabled = !isChecked;
                        callUpdateIsActiveApi(row, () => {
                          callReadApi(refTableSearch.current.state.value);
                          callReadStatusApi()
                          apiUtil.toast('Successfully Changed', 'check_circle');
                        });
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  const CustomOnboardingTable = () => {
    return <Table 
      ref={refTable}
      isLoading={isLoading}
      className={'job-table-list' }  
      limit={limit}
      // grouping={true}
      page={page}
      total={total}
      defaultExpanded={true}
      selection={true}
      data={data}
      onPageChange={(page, size) => {
        setLimit(size);
        setPage(page);
     
          callReadApi(refTableSearch.current.state.value);
      
        // useEffect  will call the api again when it load
      }}
      onSelectionChange={(rows, column)=>{
        // console.log("columns", column);
        setRowData(rows);
      }}
      Toolbar={customToolbar}
      columns={[
        { 
          title: "Driver", 
          field: "email",
        },  
        { 
          title: "First Name", 
          field: "first_name",
        },
        { 
          title: "Last Name", 
          field: "last_name"
        },
        { 
          title: "Vehicle Number", 
          render: (row) => {
            let vehicleNo = '';
            if (row.vehicle && row.vehicle.plate_no) {
              vehicleNo = row.vehicle.plate_no
            }
            return vehicleNo;
          }
        },
        { 
          title: "Phone Number", 
          field: "contact_no"
        },
        { 
          title: "Driver ID", 
          field: "id"
        },
        { 
          title: "ACTION", 
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <div style={{padding:"1em", color:'#28a745'}} data-tooltip="Approve Driver" data-tooltip-conf="danger">
                  <FontAwesomeIcon icon={faCheck}  className="tooltip"/>
                </div>
                <div style={{padding:"1em", color:'#CF3E3E'}} data-tooltip="Reject Driver" data-tooltip-conf="danger">
                  <FontAwesomeIcon icon={faXmark}  className="tooltip"/>
                </div>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }
  /* DIALOG */
  const CustomDialogPopup = () => {
    // console.log(openDialogItem)
    return <CustomDialog 
      open={openDialog}
      title={'Delete'}
      onClose={() => {
        setOpenDialog(false);
        setOpenDialogItem(null);
      }}
      onOk={() => {
        let row = openDialogItem; 
        callDeleteApi(row, () => {
          setOpenDialog(false);
          setOpenDialogItem(null);
          callReadApi(searchVal);
          callReadStatusApi()
          apiUtil.toast('Successfully Deleted', 'check_circle');
          // });
        });
      }}
    >
      {/* add openDialog item worker name */}
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */

  
  /* STEPPER */
  const setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={2}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            history.push('/customers');
          } else if(index === 1){
            history.push('/resources/vehicle');
          } else if(index === 2){
            // history.push('/resources/transport-operator');
          } else if(index === 3){
            history.push('/job-list');
          }
        }}
      />
      <Box textAlign={'center'}>This is the driver list page. Click <b className={'txt-second'}>New Driver</b> to begin</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              setOpenSkipDialog(true);
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  const setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.new-driver-step',
            content: <Box>
              <h2>Create Driver</h2>
              <p>Click here to create a new driver</p>
            </Box>,
          },
        ]}
        isOpen={isTourOpen}
        rounded={10}
        // accentColor={'#64CCC9'}
        accentColor={'#007CDE'}
        // badgeContent={'#64CCC9'}
        badgeContent={'#007CDE'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={false}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          setIsTourOpen(false);
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  const skipDialog = () => {
    return <CustomDialog 
      open={openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        setOpenSkipDialog(false);
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          setOnBoardingCustomer(null);
          setOnBoardingVehicle(null);
          setOnBoardingDriver(null);
          setOnBoardingJob(null);

          setOpenSkipDialog(false);

          apiUtil.toastOnBoarding('Done', 'check_circle');
          history.push('/')
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  return <Container fluid>
    {/* <StickyPageHeader>
        <CustomStickyPageHeader />
    </StickyPageHeader> */}
     <Card className="overflow-hidden">
          <div className="row border-0 m-0">
            <div className="col-xs-12 col-md-6 d-flex justify-content-start align-items-center mb-2">
            <CustomInput
            className="input-custom-style"
             ref={refTableSearch}
              placeholder={'Search Subcon'}
              value={searchVal}
              endAdornment={
                  <Button className="upload-style max-height-btn w-100" onClick={(e) => {
                    setSearchVal(refTableSearch.current.state.value);  
                  }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
              }
              onPressEnter={(e) => {
                setSearchVal(refTableSearch.current.state.value);
              }}
            />
            </div>
            <div className="col-xs-12 col-md-6 d-flex justify-content-end align-items-center mb-2">
              {/* <Button className="justify-self-end d-flex ms-auto upload-style-border cd-shadow">Import Customer</Button> */}
              <Button className="justify-self-end upload-style" onClick={() => {
                // props.history.push('/resources/transport-operator-form');
              }}><FontAwesomeIcon icon={faPlus} /> New Subcon</Button>
            </div>
          </div>
      </Card> 

    <Card>
      {activeInactiveItem != 2 ?
      <CustomTable /> :
      <CustomOnboardingTable />
      }
    </Card>
    <CustomDialogPopup />
    {setTour()}
    {skipDialog()}
  </Container>;
}