import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';

export class DriverForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let first_name = (item && item.first_name) ? item.first_name : '';
    let last_name = (item && item.last_name) ? item.last_name : '';
    let contact_no = (item && item.contact_no) ? item.contact_no : '';
    
    let email = (item && item.email) ? item.email : '';
    let password = (item && item.password) ? item.password : '';

    this.state = {
        isLoading: isLoading,
        item: item,

        first_name: first_name,
        first_nameValidated: false,
        last_name: last_name,
        last_nameValidated: false,
        contact_no: contact_no,
        contact_noValidated: false,

        email: email,
        emailValidated: false,
        password: password,
        passwordValidated: false,
        passwordType: 'password',
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* API */
  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
        api.create('workers', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
                apiUtil.toast(error.email[0], 'warning', 'error');
            } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
            }
            
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Driver Information'}
                        weight={'bold'}
                        size={'md'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'First Name'}
                        placeholder={'Enter driver first name'}
                        value={this.state.first_name}
                        error={this.state.first_nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                first_name: e.target.value,
                                first_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Last Name'}
                        placeholder={'Enter driver last name'}
                        value={this.state.last_name}
                        error={this.state.last_nameValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                last_name: e.target.value,
                                last_nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Contact Number'}
                        placeholder={'Contact Number'}
                        value={this.state.contact_no}
                        error={this.state.contact_noValidated}
                        type="number"
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                contact_no: e.target.value,
                                contact_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone pt={2}>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Driver Account'}
                        weight={'bold'}
                        size={'md'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Username'}
                        placeholder={'Enter driver username'}
                        value={this.state.email}
                        error={this.state.emailValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                email: e.target.value,
                                emailValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        className={'custom-password'}
                        label={'Password'}
                        placeholder={'Enter driver password'}
                        autoComplete={'new-password'}
                        value={this.state.password}
                        error={this.state.passwordValidated}
                        type={this.state.passwordType}
                        endAdornment={(obj) => {
                            return <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    this.setState({
                                        passwordType: ((this.state.passwordType === 'password') ? 'text' : 'password')
                                    });
                                }}
                            >
                                {
                                    (this.state.passwordType === 'password')
                                    ?
                                    <i className={'far fa-eye-slash'}></i>
                                    :
                                    <i className={'far fa-eye'}></i>
                                }
                            </Box>
                        }}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                password: e.target.value,
                                passwordValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Edit Driver' : 'Add Driver')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        first_nameValidated: false,
        last_nameValidated: false,
        contact_noValidated: false,

        emailValidated: false,
        passwordValidated: false,
    });
    
    if(this.state.first_name === ''){
      this.setState({
        first_nameValidated: true
      });
    }
    if(this.state.last_name === ''){
      this.setState({
        last_nameValidated: true
      });
    }
    let phoneValidated = true
    if(!apiUtil.phoneValidation(this.state.contact_no)){
      phoneValidated = false
      this.setState({
        contact_noValidated: true
      });
    }

    if(this.state.email === ''){
        this.setState({
            emailValidated: true
        });
    }
    if(this.state.password === ''){
        this.setState({
            passwordValidated: true
        });
    }


    if (form.checkValidity() === true && phoneValidated) {
        this.callCreateApi(this.getForm(), (data) => {
            if(data){
                if(this.props.onSave){
                    this.props.onSave((this.state.item ? true : false), data);
                }
            }
        });
        
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        contact_no: this.state.contact_no,
        email: this.state.email,
        password: this.state.password,
        is_truck_driver: true
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default DriverForm;