import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return <div >
            <footer className="footer" 
            style={{
                height:"8vh",
                backgroundColor:"transparent", 
                textAlign:"center", 
                padding:"15px 13px", 
                justifyContent:"center",
                display:"flex",
                alignItem: "center"
                }} >
            <p style={{color:"#cccccc", paddingBottom: "4px"}}>powered by <a target="_blank" href="https://evfy.sg" alt="evfy singapore" style={{color: "#add8e6"}} >evfy</a> © {new Date().getFullYear()}</p>
      </footer>
      </div>;
    }
}

export default Footer;