import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';

export class BatchUpdateAssignForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);

    this.state = {
        isLoading: isLoading,
        order_status:null,
        order_status_id:null,
        orderStatuses: this.props.status ? this.props.status : [],
        drop_off_worker: null,
        drop_off_worker_id: null,
        workers: [],
        extra_worker:null,
        extra_worker_ids:null,
        workerValidated: false,
        vendor_id: null,
        vendor: null,
        temp: null,
        workerItems: this.props.workerItems ? this.props.workerItems : [],
        manPowerItems: this.props.manPowerItems ? this.props.manPowerItems : []
    }
  }

  componentDidMount() {
    /*this.callOrderStatusesApi();
    let driver = [];
    this.callWorkerDriverReadApi('', (arr) => {
      driver.push(arr);
      this.callVendorReadApi('', (arr) => {
        driver.push(arr);
        var result = [].concat.apply([],Object.values(driver));
        this.setState({
          workerItems: result
        });
      });
    });*/
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
  }

  /* WORKER API */

  callWorkerDriverReadApi = (search = '', callback = null) => {

    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search,
      is_truck_driver: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
            this.setState({
              workers: arr.sort((a, b) => {
                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                // names must be equal
                return 0;
              })
            })
            if(callback){
              callback(arr);
            }
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }

  callManpowerDriverReadApi = (search = '', callback = null) => {

    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }

  callOrderStatusesApi = (search = '', callback = null) => {

    const { accessToken } = this.context;

    ldsApi.read('order_statuses', null, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data && data.length > 0) ? data : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = item.status;

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
    
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: arr,
            }, () => {
              if(callback){
                callback(arr);
              }
            });
          } else {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          }
        }, (error, type) => {
          this.setState({
            orderStatusesIsLoading: false,
            orderStatuses: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        });
    });
  }
  /* END WORKER API */

  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              // this.setState({
              //   vendors: arr,
              // }, () => {
                if(callback){
                  callback(arr);
                }
              // });
            } else {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
  }
  /* END VENDOR API */

  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            {/* <Box clone>
                <Grid item xs={12}>
                    <CustomAutosuggest 
                      className={'no-margin'}
                      placeholder={'Job Status'}
                      label={'Job Status'}
                      value={this.state.order_status}
                      onChange={(value) => {
                        this.setState({
                          order_status_id: (value && value.value)? value.value : null,
                          order_status   : (value && value.label)? value.label : null
                        });
                      }}
                      onSearch={(value, e) => {
                        this.callOrderStatusesApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                      onClick={(value, e) => {
                        this.callOrderStatusesApi(value, (arr) => {
                          e.showNewSuggestions(arr);
                        });
                      }}
                    />
                </Grid>
            </Box> */}
            <Box clone>
                <Grid item xs={12}>
                    
                    <CustomAutosuggest 
                      // disabled={this.state.extra_worker_ids? true : false}
                      className={'no-margin'}
                      placeholder={'Driver'}
                      label={'Driver'}
                      value={this.state.drop_off_worker ? this.state.drop_off_worker : this.state.vendor ? this.state.vendor : ''}
                      error={this.state.workerValidated}
                      isLoading={false}
                      onChange={(value) => {
                        if(value) {
                          //let check_driver = this.state.workerItems.filter(dd => dd.value == value.value && dd.label == value.label);
                          if(typeof(value.item.application_company_id) != 'undefined') {
                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'status', 'assigned');
                            this.setState({
                                drop_off_worker_id: (value && value.value) ? value.value:'',
                                drop_off_worker: (value && value.label)? value.label: '',
                                vendor_id: '',
                                vendor: '', 
                              
                            });
                          } else {
                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'status', 'assigned to vendor');
                            this.setState({
                              vendor_id: (value && value.value) ? value.value:'',
                              vendor: (value && value.label)? value.label: '',
                              drop_off_worker_id: '',
                              drop_off_worker: '',
                            });
                          } 
                        } else {
                          this.setState({
                            drop_off_worker_id: '',
                            drop_off_worker: '',
                            order_status_id: '',
                            vendor_id: '',
                            vendor: '', 
                            // temp : value,
                        });
                        }
                      }}
                      onSearch={(value, e) => {  
                        e.showNewSuggestions(this.state.workerItems && this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                      }}
                      onClick={(value, e) => {
                        e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                      }}
                    />
                </Grid>
            </Box>
            {/* <Box clone>
                <Grid item xs={12}>
                    <CustomAutosuggest 
                      disabled={this.state.vendor_id? true : false}
                      className={'no-margin'}
                      placeholder={'Driver Assistant'}
                      label={'Driver Assistant'}
                      value={this.state.extra_worker}
                      items={this.state.manPowerItems}
                      isLoading={false}
                      onChange={(value) => {
                        this.setState({
                            extra_worker_ids:(value && value.value)? value.value:'',
                            extra_worker:(value && value.label)? value.label:'',
                        });
                        
                      }}
                      onSearch={(value, e) => {
                        let searchItems = [];
                        e.state.items.find( obj => {
                          let name = obj.label != null ? obj.label : '';
                          if(name.includes(value)) {
                            searchItems.push(obj)
                          }
                        });
                        searchItems = searchItems.length > 0 && value != '' ? searchItems : this.state.manPowerItems;
                        e.showNewSuggestions(searchItems);
                      }}
                      onClick={(value, e) => {
                        e.showNewSuggestions(this.state.manPowerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                      }}
                    />
                </Grid>
            </Box> */}
            
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'right'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Submit
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      workerValidated: false
    })

    if(!this.state.drop_off_worker_id && !this.state.vendor_id) {
      this.setState({
        workerValidated: true
      })
      return
    }

    this.setState({
      isLoading: true,
    });
  
    if(this.props.onSave){
      this.props.onSave(this.getForm());
    }
  }

  
  getForm = () => {
    let data = {
        order_status_id: this.state.order_status_id,
        extra_worker_ids: this.state.extra_worker_ids,
    }

    if(this.state.drop_off_worker_id != null && this.state.drop_off_worker_id != '') {
        data.drop_off_worker_id = this.state.drop_off_worker_id;
    } else if(this.state.vendor_id != null && this.state.vendor_id != '') {
      data.assign_type = "vendor"
      data.vendor_id = this.state.vendor_id;
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default BatchUpdateAssignForm;