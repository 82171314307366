import React, {useState, useEffect, useRef, useContext} from 'react'
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import Card from '../../../components/Card/Card.jsx';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/Table/Table.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSwitch from '../../../components/CustomFields/CustomSwitch.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CloseIcon from '@material-ui/icons/Close';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';

import StatusItem from '../../../components/Status/StatusItem.jsx';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';

import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';

import { ReactComponent as ArrowUpIcon } from '../../../assets/img/icons/arrow-up.svg';
import apiUtil from "../../../api/apiUtil.jsx";
// import Api from "../../../api/Api.jsx";
import Api from "../../../api/api.jsx";
import {RoleAccessService} from '../../../data/role-access'; 


export const Logistic = props => {
  const history = useHistory();
  const refTableSearch = useRef();
  const refTable = useRef();
  const { currentRole, accessToken } = useContext(AuthContext)
  let grantedMenu = new RoleAccessService();

  const [statusBar, setStatusBar] = useState({
    total: 0,
    active: 0,
    disabled: 0
  })
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [total, setTotal] = useState(0);
  const [activeInactiveCustomers, setActiveInactiveCustomers] = useState(0)
  const [limit, setLimit] = useState(apiUtil.getDefaultPageSize());
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogItem, setOpenDialogItem] = useState(null);
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    callReadApi(searchVal);
    return () => {
    }
  }, [page, searchVal, activeInactiveCustomers])

  /* STICKY PAGE HEADER */
  const CustomStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone>
          <Grid item xs={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <CustomButton 
              color={'secondary'}
              onClick={() => {
                history.push('/resources/logistic-assistance-form')
              }}
            >
              + New Driver Assistant
            </CustomButton>
          }
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* API */
  const callReadApi = (search = '') => {
      let param = {
        page: page,
        take: limit,
        disabled: (activeInactiveCustomers === 0) ? false : true,
        search: search,
        is_man_power: true,
        require_worker_only: true
      };
      setIsLoading(true);
      const getData = Api.read('workers', param, accessToken)
      const totalinActivedata = Api.read('workers', {...param, disabled : true}, accessToken)
      const totalActivedata = Api.read('workers', {...param, disabled : false}, accessToken)
      Promise.all([getData,totalinActivedata,totalActivedata]).then( (results) => {
        
          apiUtil.parseResult(results[0], (data) => {
            const dataArr = (data.result && data.result.length > 0) ? data.result : []
            setData(dataArr);
            setTotal(data.total);
            const disabledTotal = results[1].data.data.total
            const activeTotal = results[2].data.data.total
            setStatusBar({
              total: activeTotal + disabledTotal,
              active: activeTotal,
              disabled: disabledTotal 
            })
            setIsLoading(false);
          }, (error, type) => {
            setData([]);
            setTotal(0);
            setIsLoading(false);
          });
      });
  }
  const callUpdateIsActiveApi = (row, callback = null) => {
    Api.update('workers/' + row.id, row, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        if(callback){
          callback();
        }
      }, (error, type) => {

      });
  });
  }

  const callDeleteApi = (row, callback = null) => {
    // setIsLoading(true);
    let param = {
      destroy_all: true,
    };
    
    Api.delete('customers/' + row.id, param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        
        // setIsLoading(false);
        if(callback){
          callback();
        }
      }, (error, type) => {
        // setIsLoading(false);
        if(callback){
          callback();
        }
      });
  });
  }
  /* End API */
  /* STATUS */
  const CustomStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
        <Box className={'custom-circle-icon-component'}>
          <i className="fas fa-carrot"></i>
        </Box>
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Driver Assistant Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Driver Assistant'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{statusBar.total}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="primary" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Active Driver Assistant</span>
            </Badge>}
            count={statusBar.active}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="error" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Disabled Driver Assistant</span>
            </Badge>}
            count={statusBar.disabled}
          />
        </Grid>
      </Box>
    </Grid>
  }

  const customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={refTableSearch}
              placeholder={'Search Logistic Assitant'}
              value={searchVal}
              endAdornment={
                searchVal.length < 1 ? <IconButton
                  onClick={(e) => {
                    setSearchVal(refTableSearch.current.state.value);  
                  }}
                >
                  <SearchIcon />
                </IconButton> : <IconButton>
                  <CloseIcon 
                  fontSize="small" 
                  onClick={(e) => {
                    setSearchVal("");  
                  }}/></IconButton>
              }
              onPressEnter={(e) => {
                setSearchVal(refTableSearch.current.state.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={activeInactiveCustomers}
              buttons={[ "Active Driver Assistant", "Disabled Driver Assistant" ]}
              onClick={(selected, btn) => {
                setActiveInactiveCustomers(selected)
              }}
            />
          </Grid>
        </Box>
        {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Add')) && <CustomButton 
                color={'primary'}
                onClick={() => {
                  // console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
            }
          </Grid>
        </Box> */}
      </Grid>
    </Box>
  }
  /* TABLE */
  const CustomTable = () => {
    return <Table 
    ref={refTable}
    isLoading={isLoading}
    className={'job-table-list' }  
    limit={limit}
    grouping={true}
    page={page}
    total={total}
    defaultExpanded={true}
    // selection={true}
    data={data}
    onPageChange={(page, size) => {
      setLimit(size);
      setPage(page);
   
        callReadApi(refTableSearch.current.state.value);
    
      // useEffect  will call the api again when it load
    }}
      Toolbar={customToolbar}
      columns={[
        { 
          title: "Username", 
          field: "email",
        },
        { 
          title: "First Name", 
          field: "first_name",
        },
        { 
          title: "Last Name", 
          field: "last_name"
        },
        { 
          title: "Phone Number", 
          field: "contact_no"
        },
        { 
          title: "ACTION", 
          width: '230px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                  {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Edit')) && <CustomSwitch 
                      checked={!row.disabled}
                      onChange={(isChecked) => {
                        row.disabled = !isChecked;
                        callUpdateIsActiveApi(row, () => {
                          callReadApi(refTableSearch.current.state.value);
                          apiUtil.toast('Successfully Changed', 'check_circle');
                        });
                      }}
                    />}
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                  {(grantedMenu.isPermissionActionAllowed( currentRole, 'Driver & Manpower Edit')) && <IconButton
                      onClick={() => {
                        history.push({
                          pathname: '/resources/logistic-assistance-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  }
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }
  /* DIALOG */
  const CustomDialogPopup = () => {
    // console.log(openDialogItem)
    return <CustomDialog 
      open={openDialog}
      title={'Delete'}
      onClose={() => {
        setOpenDialog(false);
        setOpenDialogItem(null);
      }}
      onOk={() => {
        let row = openDialogItem; 
        callDeleteApi(row, () => {
          setOpenDialog(false);
          setOpenDialogItem(null);
          callReadApi(searchVal);
          apiUtil.toast('Successfully Deleted', 'check_circle');
          // });
        });
      }}
    >
      {/* add openDialog item worker name */}
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */
  return <Box className="logistic-page">
    <StickyPageHeader>
        <CustomStickyPageHeader />
    </StickyPageHeader>
    <Card>
      <CustomStatus />
    </Card>
    <Card>
      <CustomTable />
    </Card>
    <CustomDialogPopup />
  </Box>;
}