import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';


class StatusItem extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let text = (props.text) ? props.text : '';
        let count = (props.count) ? props.count : 0;

        this.state = {
            className: className,
            text: text,
            count: count,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.text !== this.state.text) {
			this.setState({
				text: nextProps.text
			});
		}
		if (nextProps.count !== this.state.count) {
			this.setState({
				count: nextProps.count
			});
		}
	}


    render() {
        return <Box className={'custom-status-item-component ' + this.state.className}>
            <Box className={'custom-text'}>{this.state.text}</Box>
            <Box className={'custom-count my-2'}>{this.state.count}</Box>
        </Box>
    }
}


export default StatusItem;