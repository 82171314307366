import React from 'react';

import apiUtil from '../../api/apiUtil.jsx';

import { 
    Box,
    Grid,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';

import MaterialTable, { MTableGroupbar, MTableBodyRow, MTableHeader } from "material-table";
import Pagination from '@material-ui/lab/Pagination';


const noResults = "No results!";

const SettingTable = React.forwardRef((props, ref) => {
    let isLoading       = (props.isLoading === false || props.isLoading === true) ? props.isLoading : false;
    let grouping        = (props.grouping === false || props.grouping === true) ? props.grouping : false;
    let paging          = (props.paging === false || props.paging === true) ? props.paging : true;
    let selection       = (props.selection === false || props.selection === true) ? props.selection : false;
    let defaultExpanded = (props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false;
    let limit           = props.limit ? props.limit : 50;
    let total           = props.total ? props.total : 0;
    let page            = props.page ? props.page : 1;
    let data            = (props.data && props.data.length > 0)? props.data : [];
    let columns         = (props.columns && props.columns.length > 0)? props.columns : [];
    let emptyText       = props.emptyText? props.emptyText : noResults;
    let overflowY       = props.overflowY? props.overflowY : "visible";
    let maxBodyHeight   = props.maxBodyHeight ? props.maxBodyHeight : '';
    let minBodyHeight   = props.minBodyHeight ? props.minBodyHeight : '';
    let tableLayout     = props.tableLayout ? props.tableLayout : 'auto';
    let className       = props.className ? props.className : '';

    // let [paging, setPaging] = React.useState((props.paging === false || props.paging === true) ? props.paging : true);
    // let [grouping, setGrouping] = React.useState((props.grouping === false || props.grouping === true) ? props.grouping : false);
    // let [isLoading, setIsLoading] = React.useState((props.isLoading === false || props.isLoading === true) ? props.isLoading : false);
    // let [selection, setSelection] = React.useState((props.selection === false || props.selection === true) ? props.selection : false);
    // let [defaultExpanded, setDefaultExpanded] = React.useState((props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false);
    // let [limit, setLimit] = React.useState(props.limit ? props.limit : 20);
    // let [total, setTotal] = React.useState(props.total ? props.total : 0);
    // let [page, setPage] = React.useState(props.page ? props.page : 1);
    // let [data, setData] = React.useState((props.data && props.data.length > 0)? props.data : []);
    // let [columns, setColumns] = React.useState((props.columns && props.columns.length > 0)? props.columns : []);
    // let [emptyText, setEmptyText] = React.useState(props.emptyText? props.emptyText : noResults);
    // let [overflowY, setOverflowY] = React.useState(props.overflowY? props.overflowY : "visible");
    // let [maxBodyHeight, setMaxBodyHeight] = React.useState(props.maxBodyHeight ? props.maxBodyHeight : '');
    // let [minBodyHeight, setMinBodyHeight] = React.useState(props.minBodyHeight ? props.minBodyHeight : '');
    // let [tableLayout, setTableLayout] = React.useState(props.tableLayout ? props.tableLayout : 'auto');
    // let [className] = React.useState(props.className ? props.className : '');
   
    const changePageSize = (size) =>{ 
        limit = size;
        if(props.onPageChange){ 
            props.onPageChange(page,limit);
        }
    }

    return <Box className={'custom-table-component ' + className}>
        
            <MaterialTable
                tableRef={ref}
                components={{
                    Container: (p) => {
                        return <Paper className={'custom-container'} elevation={0} square {...p} />
                    },
                    Toolbar: (p) => {
                        if(props.Toolbar){
                            return <Box>
                                {props.Toolbar(p)}
                            </Box>
                        } else {
                            return null;
                        }
                    },
                    Header: (p) => {
                        
                        if(props.Header) {
                            return <React.Fragment>
                                {props.Header(p)}
                            </React.Fragment>
                        } else {
                            return <MTableHeader className={'bg-ss'} {...p} />
                        }
                    },
                    Row: (p) => {
                        if(props.Row) {
                            
                            return <React.Fragment>
                                <MTableBodyRow {...p} />
                                {props.Row(p, p.data)}
                            </React.Fragment>
                        } else {
                                return <MTableBodyRow {...p} />
                            }
                    },
                    Groupbar: (p) => {
                        return <Box className={'custom-groupbar'}>
                            <MTableGroupbar {...p} />
                        </Box>
                    },
                    
                    Pagination: (p) => {
                        return <td style={{ display: 'block' }}>
                            <Box pt={3}>
                                <Grid container>
                                    <Box clone >
                                        <Grid item xs={12} md={true} style={{display:"flex", position:"relative"}}>
                                             <div style={{display:"inline"}}>
                                                {'Showing page ' + page + ' of ' + Math.ceil(total / limit) + ' of ' + total + ' entries'}
                                             </div>
                                            {/* <div style={{display:"inline", position:'absolute', top:'4px', left: '80px'}}>
                                                <InputLabel id="row_per_page" style={{width:"180px", display:"flex", borderBottom: 'none'}}>Rows Per Page
                                                    <Select
                                                        style={{borderBottom: 'none', marginLeft:"20px", position:'absolute', top:'-7px', left: '90px'}}
                                                        defaultValue={limit}
                                                        label="row_per_page" 
                                                        >
                                                        <MenuItem value={20} onClick={()=>{changePageSize(20);}} >20</MenuItem>
                                                        <MenuItem value={30} onClick={()=>{changePageSize(30);}} >30</MenuItem>
                                                        <MenuItem value={40} onClick={()=>{changePageSize(40);}} >40</MenuItem>
                                                        <MenuItem value={50} onClick={()=>{changePageSize(50);}} >50</MenuItem>
                                                        <MenuItem value={60} onClick={()=>{changePageSize(60);}} >60</MenuItem>
                                                    </Select>
                                                    </InputLabel>
                                            </div> */}
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={'auto'}>
                                            <Pagination
                                                count={Math.ceil(total / limit)}
                                                size="small"
                                                page={page}
                                                color={'primary'}
                                                onChange={(e, p) => {
                                                    if(props.onPageChange){
                                                        props.onPageChange(p,limit); 
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </td>
                    }
                }}

                localization={{
                    body: {
                        emptyDataSourceMessage:(
                            <div style={{position:'relative',padding:'15px'}} >
                                {emptyText}
                            </div>
                        ), 
                    }
                }}

                options={{
                    pageSize: 60,
                    grouping: grouping,
                    page: page,
                    paging: paging,
                    selection: selection,
                    maxBodyHeight: maxBodyHeight,
                    minBodyHeight: minBodyHeight,
                    tableLayout: tableLayout,
                    defaultExpanded: defaultExpanded,
                    overflowY: overflowY
                }}

                onSelectionChange={(rows) => {
                    if(props.onSelectionChange){
                        return props.onSelectionChange(rows);
                    }
                }}

                isLoading={isLoading}
                columns={columns}
                data={data}
                detailPanel={(props.detailPanel) ? [
                    {
                        render: (rowData) => {
                            if(props.detailPanel){
                                return props.detailPanel(rowData);
                            }
                        }
                    }
                ] : null}
            />
        
        </Box>
    
})

export default SettingTable;