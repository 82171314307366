import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Hidden,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomFormDialog from '../../../components/Dialog/CustomFormDialog.jsx';

import ItemsInJobForm from './ItemsInJobForm.jsx';


export class ItemsInJobList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let preview = ((props.preview === true) || (props.preview === false)) ? props.preview : false;
    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      openDialogEdit: false,
      itemDialogEdit: null,

      openDialogDelete: false,
      itemDialogDelete: null,

      items: items,
      preview: preview,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            items: items
        });
    }
  }


  /* FUNCTIONS */
  addNew = (form = null, callback = null) => {
    let items = this.state.items;

    if(form) {
        form['uuid'] = uuid();
        form['remove'] = false;
        items.push(form);
    } else {
        items.push({
            uuid: uuid(),
            description: '',
            quantity: '',
            uom: '',
            weight: '',
            remarks: '',
            remove: false,
        });
    }
    
    this.setState({
        items: items
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onAddNew){
            this.props.onAddNew(this.state.items);
        }
    });
  }

  edit = (item = null, form = null, callback = null) => {
    let items = this.state.items;

    let editIndex = items.findIndex(e => e.uuid === item.uuid);
    if(editIndex !== -1){
        let oldForm = items[editIndex];

        form['uuid'] = item.uuid;
        form['remove'] = false;
        items[editIndex] = form;

        oldForm['remove'] = true;
        items.push(oldForm);
    }
    
    this.setState({
        items: items
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onEdit){
            this.props.onEdit(this.state.items);
        }
    });
  }

  remove = (uuid = '', callback = null) => {
    let items = this.state.items;

    let index = items.findIndex(e => e.uuid === uuid);
    if(index !== -1){
        items[index]['remove'] = true;
    }
    
    this.setState({
        items: items
    }, () => {
        if(callback){
            callback();
        }

        if(this.props.onRemove){
            this.props.onRemove(this.state.items);
        }
    });
  }
  /* END FUNCTIONS */


  /* DIALOG */
  editDialog = () => {
      return <CustomFormDialog 
          open={this.state.openDialogEdit}
          title={((this.state.itemDialogEdit !== null) ? 'Edit Item To Job' : 'Add Item To Job')}
          onClose={() => {
            this.setState({
                openDialogEdit: false,
                itemDialogEdit: null,
            });
          }}
      >
          <ItemsInJobForm
            item={this.state.itemDialogEdit}
            onSave={(isEdit, form) => {
                let item = this.state.itemDialogEdit;

                if(isEdit) {
                    this.edit(item, form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                } else {
                    this.addNew(form, () => {
                        this.setState({
                            openDialogEdit: false,
                            itemDialogEdit: null,
                        });
                    });
                }
                
            }}
            onClose={() => {
                this.setState({
                    openDialogEdit: false,
                    itemDialogEdit: null,
                });
            }}
          />
      </CustomFormDialog>
  }

  deleteDialog = () => {
      return <CustomDialog 
          open={this.state.openDialogDelete}
          title={'Delete'}
          onClose={() => {
            this.setState({
                openDialogDelete: false,
                itemDialogDelete: null,
            });
          }}
          onOk={() => {
              let item = this.state.itemDialogDelete;
              this.remove(item.uuid, () => {
                this.setState({
                    openDialogDelete: false,
                    itemDialogDelete: null,
                });
              });
          }}
      >
          <Box>Are you sure you want to delete?</Box>
      </CustomDialog>
  }
  /* END DIALOG */


  setHeader = () => {
    let weightLabel = localStorage.getItem('Weight') !== null ? localStorage.getItem('Weight') : 'Weight (lb)';
    if (weightLabel === 'kilogram (kg)') weightLabel = 'Weight (kg)';
    else if (weightLabel === 'pound (lb)') weightLabel = 'Weight (lb)';

    return <Box pt={1} pb={1} className={'custom-grid-header'}>
        <Grid container alignItems={'center'}>
            <Box clone pl={{ xs: 0, md: 2}}>
                <Grid item xs={12} md={!this.state.preview ? 2 : true}>
                    <CustomLabel
                        label={'Description'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={2}>
                    <CustomLabel
                        label={'Quantity'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={2}>
                    <CustomLabel
                        label={'UOM'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={2}>
                    <CustomLabel
                        label={weightLabel}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} md={2}>
                    <CustomLabel
                        label={'Remarks'}
                    />
                </Grid>
            </Box>
            {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}} textAlign={'right'}>
                <Grid item xs={12} md={2}>
                    <CustomLabel
                        label={'Actions'}
                    />
                </Grid>
            </Box>}
        </Grid>
    </Box>
  }


  render() {
    return <Box pt={!this.state.preview ? 5 : 0} pb={5}>
        <Grid container>
            {!this.state.preview && <Box clone pb={1}>
                <Grid item xs={12}>
                    <CustomLabel
                        label={'Items in the job (If applicable)'}
                        weight={'bold'}
                        size={'md'}
                        labelComponent={<CustomTooltip title={'Payload or goods involved in this job.'} />}
                    />
                    <Divider light />
                </Grid>
            </Box>}
            <Grid item xs={12}>
                <Hidden xsDown implementation="css">
                    {this.setHeader()}
                </Hidden>
                <Box>

                    {
                        (this.state.items && this.state.items.length > 0 && this.state.items.filter(x => !x.remove).length > 0)
                        ?
                        this.state.items.filter(x => !x.remove).map((item, i) => {
                            return <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={'center'}>
                                    <Box clone pl={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={!this.state.preview ? 2 : true}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Description'} placement={'top'}>
                                                        <Box>{item.description}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Quantity'} placement={'top'}>
                                                        <Box>{item.quantity}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'UOM'} placement={'top'}>
                                                        <Box>{item.uom}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Weight (lb)'} placement={'top'}>
                                                        <Box>{item.weight}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12} md={2}>
                                            <CustomLabel
                                                label={<Box>
                                                    <Tooltip title={'Remarks'} placement={'top'}>
                                                        <Box>{item.remarks}</Box>
                                                    </Tooltip>
                                                </Box>}
                                            />
                                        </Grid>
                                    </Box>
                                    {!this.state.preview && <Box clone pr={{ xs: 0, md: 2}}>
                                        <Grid item xs={12} md={2}>
                                            <Grid container justify={'flex-end'}>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogEdit: true,
                                                                    itemDialogEdit: item,
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                                <Box clone>
                                                    <Grid item xs={'auto'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    openDialogDelete: true,
                                                                    itemDialogDelete: item,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                </Grid>
                                <Divider light />
                            </Box>
                        })
                        :
                        <Grid container>
                            <Box clone pt={3} textAlign={'center'}>
                                <Grid item xs={12}>
                                    <CustomLabel
                                        label={'No Results!'}
                                        weight={'bold'}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    }

                    {!this.state.preview && <Grid container>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomButton 
                                    color={'secondary'}
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                            openDialogEdit: true,
                                            itemDialogEdit: null,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} component={'i'}/>
                                    {/* <Icon component={'i'}>add</Icon> */}
                                    <Box display={'inline-block'} pl={1}>Add New Item</Box>
                                </CustomButton>
                            </Grid>
                        </Box>
                    </Grid>}

                </Box>
            </Grid>
        </Grid>
        {this.editDialog()}
        {this.deleteDialog()}
    </Box>
  }
}

export default ItemsInJobList;