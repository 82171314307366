import React, {useState, useCallback, useEffect, useContext} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomCropper from '../../components/Cropper/CustomCropper.jsx';

import { 
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    CircularProgress,
    Button,
    Dialog,
  } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import {RoleAccessService} from '../../data/role-access'; 


export const ChangePassword = () => {
    let grantedMenu = new RoleAccessService();
    let currentRole = window.localStorage.getItem('current_role');

    const history = useHistory();
    const location = useLocation();
    const { accessToken, user, setUser } = useContext(AuthContext)
    const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
    const [value, setValue] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingRead, setIsLoadingRead ] = useState(false);

    // general section
    const [personalName, setPersonalName] = useState("")
    const [personalEmail, setPersonalEmail] = useState("")
    const [personalPassword, setPersonalPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [retryPassword, setRetryPassword] = useState("")
    const [alertDialog, setAlertDialog] = useState(false)
    const [newPasswordValidated, setNewPasswordValidated] = useState(false)
    const [retryPasswordValidated, setRetryPasswordValidated] = useState(false)
    const [personalProfileImageURL, setPersonalProfileImageURL] = useState("")
    const [personalProfileOldData, setPersonalProfileOldData] = useState("")
    

    const CustomStickyPageHeader = () => {
     return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push(pathName);
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={'Change Password'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Save Changes</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }
    const settingsAccountProfile = () => {
        return <Box><Grid container p={1} >
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Current Password'}
                            placeholder={'Current Password'}
                            className="input-label--horizontal"
                            value={personalPassword}
                            type="password"
                            // error={lastNameValidated}
                             required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setPersonalPassword(e.target.value) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'New Password'}
                             placeholder={'New Password'}
                            className="input-label--horizontal"
                            value={newPassword}
                            error={newPasswordValidated}
                            type="password"
                            required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setNewPassword(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Retype New Password'}
                            placeholder={'Retype New Password'}
                            className="input-label--horizontal"
                            type="password"
                            value={retryPassword}
                            error={retryPasswordValidated}
                            required={true}
                            // autoFocus={true}
                            onChange={e => {
                                setRetryPassword(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>;
    } 
    
    const handleClose = () => {
        setAlertDialog(false);
    };

    const popUpDialog = () => {
        return <CustomDialog 
        open={alertDialog}
        title={'Warning !'}
        btnOkText={'OK'}
        onClose={() => {
            handleClose();
        }}
        onOk={() => {
            handleClose();
        }}
      >
        <Box>
            <p>Wrong current password</p></Box>
      </CustomDialog>
    }



    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();
    
        let currentPassword = window.localStorage.getItem('password');

        if(window.btoa(personalPassword) != currentPassword) {
            setAlertDialog(true);
            return
        }

        if (newPassword === '') {
          setNewPasswordValidated(true)
          apiUtil.toast('New password required', 'check_circle', 'error');
          return
        }

        if (retryPassword === '') {
            setRetryPasswordValidated(true)
            apiUtil.toast('retry password required', 'check_circle', 'error');
            return
          }

        if (newPassword !== retryPassword) {
          setNewPasswordValidated(true)
          setRetryPasswordValidated(true)
          apiUtil.toast('Password doesn\'t match', 'check_circle', 'error');
          return
        }

          let row = {
            "password": newPassword,
          }
          if(Object.keys(row).length > 0) {
            Object.assign(row, {appName:"AUTH"}); 
        }
          Api.update('profile/admins', row, accessToken)
          .then((result) => {
              apiUtil.parseResult(result, (data) => {
                  setIsLoading(false);
                  window.localStorage.setItem('password', window.btoa(newPassword));
                  if(grantedMenu.isShouldShowMenu(currentRole, "jobs")) {
                    history.push("/job-list");
                  } else if(grantedMenu.isShouldShowMenu(currentRole, "dashboard")) {
                    history.push("/dashboard");
                  } else {
                    history.push("/");
                  }
                  apiUtil.toast('Successfully Updated Password', 'check_circle');
                 
              }, (error, type) => {
                  setIsLoading(false);
                 
              });
          }).catch(
            //   error => console.log(error)
          )
       
      }
    return (
    <Box className="settings--form" >
        <form autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>
            <Container maxWidth="lg">
                <Grid container>

                    {/* <Grid item xs={3}>
                        <Box clone marginTop={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Settinsg Menus"
                        >
                            <Tab label="Account Profile" {...a11yProps(0)} />
                        </Tabs>
                        </Box>
                    </Grid> */}

                    {/* <Grid item xs={9}> */}
                    <Grid item xs={12}>
                        <Card className="custom-card-margin-x">
                            {
                                isLoadingRead
                                ?
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                                    <CircularProgress size={35} />
                                </Box>
                                : 
                                <>
                                {settingsAccountProfile()}
                                {popUpDialog()}
                                
                                </>
                            }
                        </Card>
                    </Grid>
                    
                </Grid>

            </Container>
        </form>
    </Box>
    )
}

